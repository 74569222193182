/**
 * InviteModeSelector
 *
 * Offer the option to send a voice or text-only LiveCall invite

*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Button } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { Send as SendIcon, Mic as MicIcon } from '@material-ui/icons';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  closeInviteSelector: {
    position: 'absolute',
    right: 0,
    bottom: -50,
    color: theme.palette.grey[500],
  },
}));

const InviteModeSelector = ({ onClick }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        width: '300px',
        textAlign: 'center',
        marginBottom: 20,
        position: 'relative',
      }}
    >
      <Button
        style={{ width: 200, marginTop: 20 }}
        disabled={false}
        variant="contained"
        color="secondary"
        startIcon={<SendIcon />}
        onClick={() => onClick('text')}
      >
        {' '}
        {t('Send_Text_Invitation')}
      </Button>
      <Button
        style={{ width: 200, marginTop: 20 }}
        disabled={false}
        variant="contained"
        color="primary"
        startIcon={<MicIcon />}
        onClick={() => onClick('voice')}
      >
        {' '}
        {t('Send_Voice_Invitation')}
      </Button>

      <IconButton
        className={classes.closeInviteSelector}
        onClick={() => onClick('close')}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default InviteModeSelector;
