/**
 * Utilities to support CORS tests
 */

import { makeArray } from 'lib/utils/utils'
import { fetcher } from 'lib/utils/fetcher'
import { WEB_ROOT } from 'configs/config-hvn'


export default function ( {
   logCallback  // optional callback function to support log messages
}){

    // capture a log message consisting of a text string and optional object
    const log = (text, optionalObj) => {
        let args = [text]
        optionalObj && args.push(optionalObj)
        logCallback && logCallback(previous => [...previous, text]);
        console.log(...args)
      }

      
    const audioElInit = (audioEl, label) => {
        audioEl.crossOrigin = 'anonymous' // required for Firefox CORS simulator
        audioEl.preload = 'none'
        audioEl.addEventListener('canplaythrough', onCanplaythrough)
        audioEl.addEventListener('loadedmetadata', onLoadedMetaData)
        audioEl.addEventListener('error', e => onError(e, label))
    }

    const audioElCleanup = (audioEl) => {
        if (audioEl){ 
            audioEl.pause()
            audioEl.removeAttribute('src'); // empty source
            audioEl.load();
            audioEl.removeEventListener('canplaythrough', onCanplaythrough)
            audioEl.removeEventListener('loadedmetadata', onLoadedMetaData)
            audioEl.removeEventListener('error', e => onError(e))
        }
    }

    // event handlers
    const onCanplaythrough = e => log("onCanplaythrough:", e)
    const onLoadedMetaData = e => log("onLoadedMetaData:", e)
    const onError = (e, label) => logPlayError({ label, code: makeArray(e?.path)[0]?.error?.code, message: makeArray(e?.path)[0]?.error?.message })
    const logPlayError = ({ label, code, message }) =>
        log(`playError: ${label} -- ${message}` + (code ? `-- code ${code}` : ''))


    // fetch the most recently created voice_message node
    const fetchLatestFileUrl = () => {
        const url = `jsonapi/node/voice_message?include=field_file_public&sort=-created&page[limit]=1`
        return fetcher('jsonapi', 'GET', url)
            .then(data => {
                const fileUrl = `${WEB_ROOT}/${data?.included[0]?.attributes?.uri?.url}`
                log(`fetchLatestFileUrl success: fileUrl= ${fileUrl}`)
                return fileUrl
            })
    }

    // fetch the audio file and save as a blob
    // ref.: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
    // ref.: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetchv
    const fetchBlobUrl = (fileUrl) => {
        const headers= new Headers({ 
            //ContentType: "application/json" force pre-flight
        });

        const myRequest = new Request(fileUrl, {
            method: 'GET',
            headers: headers,
            mode: 'cors',
            cache: 'default',
        });

        return fetch(myRequest)
            .then(response => response.blob())
            .then(myBlob => URL.createObjectURL(myBlob))
    }

    return {
        audioElInit,
        audioElCleanup,
        fetchLatestFileUrl,
        fetchBlobUrl,
        log,
    }

}




