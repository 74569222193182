/**
 *  Render RegistrationManager in a dialog
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import Dialog2 from 'components/Modals/Dialog2';
import ButtonStd from 'components/utils/ButtonStd';
import { t } from 'lib/translation/trans';

// Display a dialog instructing that Cookies must be accepted
const CookieConsentReminderDialog = ({ open, onClose }) => {
  const cookiesAccepted = Boolean(
    useSelector(state => state.ui.pSession.cookiesAcceptedTime)
  );

  return (
    <Dialog2
      open={open && !cookiesAccepted}
      onClose={onClose}
      heading={t('Cookie Approval')}
      actions={
        <ButtonStd
          color="secondary"
          onClick={onClose}
          variant="text"
          label={t('Cancel')}
        />
      }
      content={<h2>{t('Please_Accept_Cookies_Before_Continuing')}</h2>}
    />
  );
};

export default CookieConsentReminderDialog;
