/**
 * ControlsSpeedDial
 * Render the controls as a MUI SpeedDial
 * props provide direction control
 *
 */

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

const useStyles = makeStyles(theme => ({
  root: {
    height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function ControlsSpeedDial({ buttons, noteOpen, direction }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const toggleOpen = () => setOpen(open => !open);
  const directionDown = direction === 'down';

  useEffect(() => {
    noteOpen && noteOpen(open);
  }, [open]);

  const handleMainClick = ev => {
    //prevent clicks from propagating to parent elements
    ev.cancelBubble = true;
    ev.stopPropagation && ev.stopPropagation();
    toggleOpen();
  };

  // prevent event propagation and forward the action event to parent
  const handleActionClick = (ev, btn) => {
    ev.cancelBubble = true;
    ev.stopPropagation && ev.stopPropagation();
    btn.onClick(); // process the selected action
    handleClose(); // close the SpeedDial
  };

  return (
    <SpeedDial
      style={{ ...(directionDown ? { top: 12 } : { bottom: 12 }) }}
      ariaLabel={
        'SpeedDial tooltip' /* do not change, element id is derived from this */
      }
      className={classes.speedDial}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      open={open}
      FabProps={{
        size: 'small',
        onClick: ev => handleMainClick(ev), // toggle the SpeedDial
      }}
      direction={direction}
    >
      {buttons
        .filter(b => b.status !== 'hidden')
        .map(btn => (
          <SpeedDialAction
            key={btn.name}
            icon={btn.icon}
            tooltipTitle={btn.label}
            onClick={ev => handleActionClick(ev, btn)}
          />
        ))}
    </SpeedDial>
  );
}
