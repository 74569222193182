/**
 * PaypalButton
 *
 * Using Paypal hosted button.
 *    https://www.paypal.com/buttons/
 *    Avoids the need for back end processing.
 *    Deprecated for new Paypal accounts.
 *    Available on Hotlynx Paypal,
 *    but not for a newly created Paypal account
 *
 *
 */

import { Typography } from '@material-ui/core';

// see: https://www.paypal.com/buttons/
// redirect managed at: https://www.paypal.com/businessmanage/preferences/website
//@@ currently: https://dev.oksayit.com/paypal-success

const configs = [
  {
    sku: '2200',
    price: 4.99,
    btnId: 'JWWMVZNHT2VYG',
    btnName: 'OkSayit Super Month',
  },
  {
    sku: '2300',
    price: 99,
    btnId: 'KYWRVNZ3RZAVC',
    btnName: 'OkSayit Professional Month',
  },
];

const PaypalButton = ({ sku }) => {
  const config = configs.find(c => c.sku === sku);
  console.log('PaypalButton sku:', sku);
  return config ? (
    <form
      action="https://www.paypal.com/cgi-bin/webscr"
      method="post"
      target="_top"
    >
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value={config.btnId} />
      <input type="hidden" name="currency_code" value="USD" />
      <input
        type="image"
        src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif"
        border="0"
        name="submit"
        title="PayPal - The safer, easier way to pay online!"
        alt="Buy Now"
      />
    </form>
  ) : (
    <Typography variant="h4">ERROR bad sku</Typography>
  );
};

export default PaypalButton;
