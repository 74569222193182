/**
 * Responsive rendering of a member's identification, attributes, and available actions
 *
 * Actions can be rendered as a button list or MUI SpeedDial
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  ListItem,
  ListItemText,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';
import { makeArray } from 'lib/utils/utils';
import { userIsSelf } from 'lib/api/userApi';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { t } from 'lib/translation/trans';
import ProfileMessageManager from 'components/Settings/components/ProfileMessageManager';
import useLiveCallMonitor from 'hooks/useLiveCallMonitor';
import LiveCallInitiatorButton from 'components/LiveCall/components/LiveCallInitiatorButton';

import { getCallManager } from 'lib/api/webRTCApi';
const callProc = getCallManager();

const useStyles = makeStyles(theme => ({
  icon: {
    width: '.6em',
    height: '.6em',
  },
  red: {
    color: 'red',
  },
  name: {
    display: 'inline-block',
    fontWeight: 'bolder',
    marginRight: 5,
  },
  byLine: {
    display: 'inline-block',
    marginLeft: 0,
    fontSize: '.8em',
  },
  nameHighlight: {
    color: '#0096ef', //light blue
  },
  standardAttributes: {
    fontSize: '.6rem',
  },
  isMeHighlight: {
    color: theme.palette.secondary.light,
  },
  actionsUpward: {
    textAlign: 'center',
    backgroundColor: 'rgba(81, 81, 81, 1)',
    position: 'absolute',
    right: -1,
    top: -61,
    border: '1px solid #0096ef',
    borderBottom: 'none',
  },
  actionsDownward: {
    textAlign: 'center',
    backgroundColor: 'rgba(81, 81, 81, 1)',
    position: 'absolute',
    right: -1,
    bottom: -61,
    border: '1px solid #0096ef',
    borderTop: 'none',
  },
}));

const Member = ({
  user,
  selected,
  onSelect,
  onPlayerEvent,
  isPlaying,
  fields,
  bookmarked,
  containerRef,
}) => {
  const [expandActions, setExpandActions] = useState(false);
  const [speedDialDirection, setSpeedDialDirection] = useState('speedDialDown');

  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const fieldList = makeArray(fields).map(f => f.key.replace('Pref', ''));
  const isUserCallable = callProc.isUserCallable(user?.id);
  const isMe = userIsSelf(user.id);
  const isMeWithoutProfile = isMe && !Boolean(user.profile);
  const byLine = user?.profile?.title;
  const itemRef = useRef();
  const { isCallable, canCallNow, isRinging } = useLiveCallMonitor({
    remoteUserId: user.id,
  });
  const enPlayPauseButton = true;
  const enSpeedDialActions = true; // disable to use inline or tab based actions
  const message = user.profile || {
    userId: user.id,
    screenName: user.screen_name,
    type: 'profile',
    bookmarked,
  };

  // used for dynamic layout calculation
  const listItemRef = useRef();
  const playPauseRef = useRef();
  const actionsRef = useRef();
  const speedDialRef = useRef();

  useEffect(() => {
    // clear expanded actions when this is de-selected
    !selected && expandActions && setExpandActions(false);
  }, [selected]);

  // dynamic calculation of main content width.
  // consider space taken by play/pause button and other actions rendered
  const getMainMaxWidth = () => {
    const minActionsWidth = 56; // measured width of speed dial actions
    const getWidth = ref =>
      ref.current && ref.current.getBoundingClientRect().width;

    const listItemWidth = getWidth(listItemRef);
    const playPauseWidth = getWidth(playPauseRef);
    let actionsWidth = getWidth(actionsRef);
    actionsWidth =
      actionsWidth > minActionsWidth ? actionsWidth : minActionsWidth;
    const mainMaxWidth =
      listItemWidth && playPauseWidth && actionsWidth
        ? listItemWidth - (playPauseWidth + actionsWidth + 20)
        : '100%';
    return mainMaxWidth;
  };

  // actions menu guidelines:
  //     can place call to callable member
  //     can send directed message to all members except self
  //     can play greeting from all members who have created greeting
  const btnLists = {
    std: {
      selected: [
        ...['info', 'bookmark', 'unMark'],
        ...['call'], // show phone if member is callable
        ...(isMe ? ['profile'] : ['send']), // show mic
        ...(enPlayPauseButton
          ? ['spacer']
          : Boolean(user.profile)
          ? ['play', 'pause']
          : ['spacer']), // show play/pause iff profile exists
        ...['spacer'],
      ],
      unSelected: [
        ...['call'],
        ...(isMe ? ['profile'] : ['send']),
        ...(enPlayPauseButton
          ? ['spacer']
          : Boolean(user.profile)
          ? ['play', 'pause']
          : ['spacer']),
        'spacer',
      ],
    },
    xs: {
      selected: [
        ...['call'], // show phone if member is callable
        ...(isMe ? ['profile'] : ['send']), // show mic
        ...['bookmark', 'unMark', 'info'],
      ],
      unSelected: [],
    },
  };
  const btnList =
    btnLists[xs ? 'xs' : 'std'][selected ? 'selected' : 'unSelected'];

  // event handlers ---------------------------------------------------------------------
  const handleActionEvent = evClick => {
    if (['playClick', 'pauseClick'].includes(evClick)) {
      onPlayerEvent && onPlayerEvent(user.id, evClick);
    } else {
      switch (evClick) {
        case 'moreClick':
          setExpandActions(true);
          break;
        case 'cancelClick':
          setExpandActions(false);
          break;
      }
    }
  };

  const handleSpeedDialOpen = open => {
    const dimReport = label => {
      const containerBottom =
        containerRef.current &&
        containerRef.current.getBoundingClientRect().bottom;
      const itemBottom =
        itemRef.current && itemRef.current.getBoundingClientRect().bottom;
      const speedDialTop =
        speedDialRef.current &&
        speedDialRef.current.getBoundingClientRect().top;

      const el = document.getElementById('SpeedDialtooltip-actions');
      const speedDialHeight = el && el.getBoundingClientRect().height;
      const windowHeight = window.innerHeight;
      const boundingHeight = Math.min(windowHeight, containerBottom);

      const direction =
        speedDialTop + speedDialHeight > boundingHeight
          ? 'speedDialUp'
          : 'speedDialDown';
      setSpeedDialDirection(direction);
    };

    open && dimReport('open');
  };

  // rendering helpers ------------------------------------------------------------------

  const userClasses = `${classes.name} ${classes.nameHighlight} ${
    isMe ? classes.isMeHighlight : null
  }`;
  const byLineClasses = `${classes.byLine}  ${isMe ? classes.byline : null}`;
  const attributeClasses = isMe
    ? `${classes.standardAttributes} ${classes.isMeHighlight}`
    : classes.standardAttributes;

  // compose string of existing attributes
  // place delimiter between attributes
  const renderAttributes = () => {
    return fieldList.reduce((out, attr) => {
      if (user?.params) {
        const attrString = user.params[attr]
          ? `${out.length ? ' -- ' : ''}${t(user.params[attr])}`
          : '';
        return `${out}${attrString ? attrString : ''}`;
      } else {
        return out;
      }
    }, '');
  };

  // invoke <PlayerActions /> with specified set of props
  const renderPlayerActions = config => {
    let actionsProps = {
      message: { ...message, bookmarked, selected },
      onEvent: handleActionEvent,
      disableFab: true,
      isPlaying: isPlaying,
      actionDescriptionOverrides: { bookmark: `${t('Bookmark_this_person')}.` },
      btnList: btnList,
      legends: true,
      btnStyleOverride: { transform: 'scale(.8)', marginLeft: 0 },
      respondTypeOverride: isMe ? 'profile' : 'vmail',
    };

    let overrides = {};

    switch (config) {
      // single button
      case 'playPause':
        actionsProps = {
          ...actionsProps,
          btnList: Boolean(user.profile) ? ['play', 'pause'] : ['spacer'],
          legends: false,
          btnStyleOverride: { marginLeft: 0 },
          test: true,
        };
        break;
      // standard horizontal configuration of buttons
      case 'inline':
        actionsProps = {
          ...actionsProps,
          legends: false,
          btnStyleOverride: {
            transform: 'scale(.8)',
            marginLeft: xs ? '1rem' : '2rem',
          },
        };
        break;
      // for use in vertical SpeedDial (modal)
      case 'speedDial':
        actionsProps = {
          ...actionsProps,
          btnLayout: speedDialDirection,
          noteSpeedDialOpen: handleSpeedDialOpen,
          btnList: btnList.filter(b => b !== 'spacer'), // no spacers for SpeedDial
        };
        break;
      // for use in tab with buttons (occlude neighbor ListItem )
      case 'tab':
      default:
        break;
    }
    return <PlayerActions {...actionsProps} />;
  };

  // SpeedDial presentation
  const renderActionsSpeedDial = () => (
    <div ref={speedDialRef}>{renderPlayerActions('speedDial')}</div>
  );

  // Inline presentation
  const renderActionsInline = () => renderPlayerActions('inline');

  // render actions within an adjacent tab placed above or below the current item
  const renderActionsTab = () => {
    // dynamically determine direction for tab
    // if too close to top, render action buttons in downward direction
    const containerTop =
      containerRef.current && containerRef.current.getBoundingClientRect().top;
    const rect = itemRef.current && itemRef.current.getBoundingClientRect();
    const renderActionsDownward = rect?.top - containerTop < rect?.height;
    return (
      <Box
        classes={{
          root: renderActionsDownward
            ? classes.actionsDownward
            : classes.actionsUpward,
        }}
      >
        {renderPlayerActions('tab')}
      </Box>
    );
  };

  // render the user's screen name, annotations, profile title, and attributes
  const renderMainContent = () => (
    <ListItemText
      primary={
        <Box>
          <div className={userClasses}>
            {user.screen_name}
            <div style={{ display: 'inline-block', marginLeft: '.4em' }}>
              {bookmarked && (
                <FavoriteIcon className={`${classes.icon} ${classes.red}`} />
              )}
              {/* disabled  <LiveAnnotation message={{ userId: user.id }} /> */}
            </div>
          </div>
          {byLine && <div className={byLineClasses}>{`"${byLine}"`}</div>}
        </Box>
      }
      secondary={
        <Box classes={{ root: attributeClasses }}> {renderAttributes()}</Box>
      }
    />
  );

  // launch the Recorder, configured to create a profile message
  const renderProfileCreator = () => (
    <div style={{ marginRight: xs ? -6 : 25 }}>
      <ProfileMessageManager actionsStyle={{ color: 'red' }} />
    </div>
  );

  // render primary actions in the designated format
  const renderActions = () =>
    enSpeedDialActions ? renderActionsSpeedDial() : renderActionsTab(); // unused alternatives: renderActionsInline()
  // two sections, justified "space-between"
  // left section contains optional left-justified button and main content
  return (
    <ListItem
      ref={listItemRef}
      style={{
        paddingLeft: 0,
        position: 'relative',
        border: selected ? '1px solid #0096ef' : 'none',
      }}
      button
      selected={selected}
      onClick={() => onSelect && onSelect(user.id)}
    >
      <Grid ref={itemRef} container justify="space-between" alignItems="center">
        <Grid item>
          <Grid item>
            <Grid container justify="flex-start" alignItems="center">
              <Grid item style={{ width: 30 }}>
                {<LiveCallInitiatorButton message={message} />}
              </Grid>
              <Grid ref={playPauseRef} item>
                {enPlayPauseButton && renderPlayerActions('playPause')}
              </Grid>
              <Grid item style={{ maxWidth: getMainMaxWidth() }}>
                {renderMainContent()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid ref={actionsRef} item>
          {isMeWithoutProfile
            ? renderProfileCreator()
            : selected && renderActions()}
        </Grid>
      </Grid>
    </ListItem>
  );
};

Member.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    screen_name: PropTypes.string,
    profile: PropTypes.object,
  }),
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onPlayerEvent: PropTypes.func,
  isPlaying: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  bookmarked: PropTypes.bool, // this member is on user's bookmark list
};
export default Member;
