/**
 * useDialogs custom hook
 * Fetch dialogs if necessary and return dialogs state.
 *
 */

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDialogs } from 'lib/api/messageApi';

const useDialogs = () => {
  const dispatch = useDispatch();
  const dialog = useSelector(state => state.dialog);

  // the dialogs array contains an ordered list of posts, each containing an array of its respective responses
  // messageMap provides key:value lookup for id:message
  const { dialogsFetchedFlg } = dialog;

  useEffect(() => {
    // load dialogs ONLY if they have not already been loaded
    if (!dialogsFetchedFlg) {
      fetchDialogs();
    }
  }, [dispatch, dialogsFetchedFlg]);

  // return the entire dialog slice from redux store
  return dialog;
};

export default useDialogs;
