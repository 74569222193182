/**
 * Render a message title input field
 */

import React from 'react';
import RecorderTextField from 'components/VoiceRecorder/components/RecorderTextField';
import { maxTitleLength } from 'configs/config-hvn';
import { t } from 'lib/translation/trans';

const MessageTitleInput = props => {
  const { label, value, placeholder, onChange } = props;

  const handleChange = ev => onChange && onChange(ev.target.value);

  const messageTitleProps = () => ({
    label,
    placeholder: placeholder || `${t('Please_enter_a')} ${label}`,
    value,
    onChange: handleChange,
    error: value.length >= maxTitleLength, //underlying RecorderTextField limits at maxTitleLength so we render error AT the limit
  });

  return <RecorderTextField {...messageTitleProps()} />;
};

export default MessageTitleInput;
