/**
 * Render a checkbox to disable future rendering of MessageRecorderSuccess form
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { t } from 'lib/translation/trans';
import CheckIcon from '@material-ui/icons/Check';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ClipboardButton from 'components/ClipboardDialog/components/ClipboardButton';
import { clipboardCapture } from 'lib/utils/utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    marginTop: '1rem',
  },
  red: {
    color: 'red',
  },
  label: {
    transform: 'scale(.8)',
  },
}));

const SuccessReportActions = ({ actions, contentForClipboard, onExit }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleHide = () => {
    dispatch(uiActions.bypassMessageRecorderSuccessForm(true));
    onExit(true);
  };

  const handleCopy = () =>
    clipboardCapture(contentForClipboard).then(() => onExit());

  const buttons = () => [
    {
      key: 'hide',
      label: (
        <div style={{ textAlign: 'center' }}>
          Don't Show
          <br /> Again
        </div>
      ),
      visible: actions.includes('hide'),
      onClick: handleHide,
      classes: { label: classes.label },
      icon: <VisibilityOffIcon />,
    },
    {
      key: 'cancel',
      label: 'Cancel',
      visible: actions.includes('cancel'),
      onClick: onExit,
      classes: { icon: classes.red },
      icon: <DeleteForeverIcon />,
    },
    {
      key: 'copy',
      label: 'Copy Link',
      visible: actions.includes('copy'),
      color: 'secondary',
      onClick: handleCopy,
      classes: {},
      icon: <FileCopyIcon style={{ transform: 'scale(.8)' }} />,
      alternateComponent: xs ? (
        <ClipboardButton text={contentForClipboard} onClick={onExit} />
      ) : null,
    },
    {
      key: 'ok',
      label: 'OK',
      visible: actions.includes('ok'),
      color: 'secondary',
      onClick: onExit,
      classes: {},
      icon: <CheckIcon />,
    },
  ];

  const visibleButtons = buttons().filter(b => b.visible);
  const multipleButtons = visibleButtons.length > 1;

  return (
    <Grid
      classes={{ root: classes.outerContainer }}
      container
      justifyContent={multipleButtons ? 'space-between' : 'flex-end'}
      alignItems="center"
    >
      {visibleButtons.map(button => (
        <Grid key={button.key} item>
          {button.alternateComponent || (
            <Chip variant="outlined" clickable {...button} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

SuccessReportActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string),
  contentForClipboard: PropTypes.string,
  onExit: PropTypes.func,
};
SuccessReportActions.defaultProps = {
  actions: [],
  contentForClipboard: '',
  onExit: () => {},
};

export default SuccessReportActions;
