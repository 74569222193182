/**
 * VadRms
 *
 * Detects voice on audiotrack in the specified audioStream
 * Employs timeout retriggered by RMS peaks
 *
 *
 */

import { SoundMeter } from './soundmeter';
import { makeArray } from 'lib/utils/utils';

class VadRms {
  talking = false;
  options = {};

  static defaultOptions = {
    amplitudeThreshold: 0.2,
    timeThreshold: 500, //ms.
    sampleInterval: 200,

    onVoiceStart: function () {
      console.log('vadI talking');
    },
    onVoiceStop: function () {
      console.log('vadI quiet');
    },
    onUpdate: function (val) {},
  };

  constructor({ type, context, audioStream, options = {}, key }) {
    this.key = key;
    this.type = type;
    this.options = { ...VadRms.defaultOptions, ...options };
    if (audioStream) {
      try {
        this.soundmeter = new SoundMeter(context);
        this.soundmeter.connectToSource(
          audioStream,
          e => e && console.log('SoundMeter connection failed:', e)
        );
        // commence sampling of the SoundMeter
        this.startSampling();
      } catch (e) {
        alert('Web Audio API not supported.');
      }
    }
  }

  startSampling = () => {
    this.sampleTimer = setInterval(() => {
      this.sample = {
        instant: this.soundmeter.instant.toFixed(2),
        slow: this.soundmeter.slow.toFixed(2),
      };
      this.options.onUpdate(this.sample.instant); // report sample
      this.updateSubscription && this.updateSubscription(this.sample.instant); //report to subscriber
      this.processSample();
    }, this.options.sampleInterval);
  };

  // detect start/stop of talking
  // use timer, retriggered on each onset of RMS energy
  processSample = () => {
    // discriminate instantaneous talk
    const detect = () => {
      // use slow value as estimate of base noise level
      //return ( this.sample.instant - this.sample.slow) > this.options.amplitudeThreshold;
      return this.sample.instant > this.options.amplitudeThreshold;
    };

    const noteTalkingOnset = () => {
      this.talking = true;
      this.options.onVoiceStart();
    };
    const noteTalkingCeased = () => {
      this.talking = false;
      this.options.onVoiceStop();
    };

    // mark the onset of talking and retrigger the sensory timer
    if (detect()) {
      // bypass if already talking
      this.talking || noteTalkingOnset(true);

      // retrigger while talking continues
      if (this.detectionTimer) {
        clearTimeout(this.detectionTimer);
        this.detectionTimer = null;
      }
      //if timer expires without retrigger, note the termination of talking
      this.detectionTimer = setTimeout(
        noteTalkingCeased,
        this.options.timeThreshold
      );
    }
  };

  // return instant, slow samples as requested
  // (arr contains one or more: 'instant', 'slow')
  getSample = (type = 'instant') => {
    if (!this.soundmeter) {
      return 0;
    }
    const sample =
      type === 'instant' ? this.soundmeter?.instant : this.soundmeter?.slow;
    return sample ? sample.toFixed(2) : 0;
  };

  resume = () => {
    this.startSampling();
  };
  suspend = () => {
    clearInterval(this.sampleTimer);
    clearTimeout(this.detectionTimer);
    this.options.onVoiceStop();
  };

  destroy = () => {
    this.suspend();
    if (this.soundmeter) {
      this.soundmeter.stop();
      delete this.soundmeter;
    }
    this.suspend();
  };
}

export default VadRms;
