/**
 * commerce.api
 *
 * Posting of transactions to back end
 */

/*
drupal hvn_transaction fields
"title": "0",
"created": "2024-01-12T16:43:40+00:00",
"field_auth_code": "none",
"field_gross": 0,
"field_item_name": "test transaction",
"field_processor": "none",
"field_quantity": 1,
"field_status": "test",
"field_unit_price": 0,
"field_user_id": "admin"

"drupal_internal__nid": 6041,
"drupal_internal__vid": 6234,
"langcode": "en",
"revision_timestamp": "2024-01-12T16:45:07+00:00",
"revision_log": null,
"status": false,
"changed": "2024-01-12T16:45:07+00:00",
"promote": false,
"sticky": false,
"default_langcode": true,
"revision_translation_affected": true,
"path": {
  "alias": null,
  "pid": null,
  "langcode": "en"
},
*/

import { fetcher } from 'lib/utils/fetcher';
import { makeArray } from 'lib/utils/utils';

class Sale {
  // settable fields of transaction node (does not include Drupal generated fields id, created, etc.)
  sku = null;
  product = null;
  price = null;
  qty = null;
  gross = null;
  processor = null;
  authCode = null;
  status = null;
  userId = null;

  flip = obj1 =>
    Object.keys(obj1).reduce((obj, key) => ({ ...obj, [obj1[key]]: key }), {});

  keyMap = {
    timestamp: 'created',
    id: 'id',
    sku: 'title',
    product: 'field_item_name',
    price: 'field_unit_price',
    qty: 'field_quantity',
    gross: 'field_gross',
    processor: 'field_processor',
    authCode: 'field_auth_code',
    status: 'field_status',
    userId: 'field_user_id',
  };

  // initializeonly only the keys that are provided
  // {sku, product, price, qty, gross, processor, authCode, status, userId}
  constructor(obj = {}) {
    for (const [key, value] of Object.entries(obj)) {
      if (key in this) {
        this[key] = value;
      }
    }
    this.keyMapRev = this.flip(this.keyMap);
  }

  template = () => ({
    data: {
      type: 'node--hvn_transaction',
      attributes: {
        title: this.sku,
        field_item_name: this.product,
        field_unit_price: this.price,
        field_quantity: this.qty,
        field_gross: this.gross,
        field_processor: this.processor,
        field_auth_code: this.authCode,
        field_status: this.status,
        field_user_id: this.userId,
      },
    },
  });

  post = () =>
    fetcher('jsonapi', 'POST', 'node/hvn_transaction', this.template());

  // generate filter for fetching transactions
  // optionally restrict result set by userId, or sku
  filter = ({ userId, sku }) => {
    const isDef = x => typeof x !== 'undefined';
    const baseFilter = `&filter[root][group][conjunction]=AND
`;

    const idFilter = isDef(userId)
      ? `&filter[id][condition][path]=field_user_id
&filter[id][condition][value]=${userId}
&filter[id][condition][memberOf]=root
`
      : '';
    const skuFilter = isDef(sku)
      ? `&filter[partition][condition][path]=title
&filter[partition][condition][value]=${sku}
&filter[partition][condition][memberOf]=root
`
      : '';
    return isDef(userId) || isDef(sku)
      ? `${baseFilter}${idFilter}${skuFilter}`
      : '';
  };

  fields = () =>
    Object.entries(this.keyMapRev).reduce(
      (acc, [key, val]) => `${acc}${acc.length ? ',' : ''}${key}`,
      ''
    );
  getUrl = ({ userId, sku }) =>
    `/jsonapi/node/hvn_transaction?fields[node--hvn_transaction]=${this.fields()}${this.filter(
      { userId, sku }
    )}&sort=-created`;

  flatten = d => d.data.map(d => ({ id: d.id, ...d.attributes }));
  parse = arr =>
    arr.map(obj =>
      Object.entries(obj).reduce(
        (acc, [key, val]) => ({ ...acc, [this.keyMapRev[key]]: val }),
        {}
      )
    );

  showUrl = ({ userId, sku } = { userId: undefined, sku: undefined }) =>
    console.log(this.getUrl({ userId, sku }));
  getTransactions = ({ userId, sku } = { userId: undefined, sku: undefined }) =>
    fetcher('jsonapi', 'GET', this.getUrl({ userId, sku }))
      .then(data => this.flatten(data))
      .then(fd => this.parse(fd))
      .then(parsed => console.log('transactions:', parsed))
      .catch(e => console.log('error:', e));

  static staticProperty = 'staticProperty';
  static staticMethod1 = () => {
    console.log('staticMethod1 ');
    return 'staticMethod1';
  };
  static staticMethod2 = () =>
    console.log(`staticMethod2 + ${this.staticMethod1()} `);
}

export default Sale;
