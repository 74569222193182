/**
 * Render a button and toggle playof an Audio() object
 */


import React, { useState, useEffect } from 'react';
import {
    Button,
} from '@material-ui/core'

import AudioObj from 'lib/classes/AudioObj'

export default function AudioObjectPlayer({ src, logCallback }) {
    const [playing, setPlaying] = useState(false)
    const [audioObj, setAudioObj] = useState(null)
    const usingBlob = src && src.includes('blob:')
    const usingFile = src && src.includes('.mp3')
    const label = usingBlob ? 'blob' : (usingFile ? 'file' : '')

    useEffect(() => {
        const audioObjTemp = new AudioObj({ onEvent: handleAudioEvent })
        setAudioObj(audioObjTemp)
        return(() => {audioObjTemp && audioObjTemp.destroy()})
    }, [])

        
    // apply new src attribute
    useEffect(() => {
        src && audioObj && audioObj.setSrc(src);
    }, [src])

    //toggle play/pause
    const handleClick = () => playing ? audioObj.pause() : audioObj.play()

    function handleAudioEvent(e) {
        switch (e) {
            case "play":
                setPlaying(true)
                break;
            case "pause":
                setPlaying(false)
                break;

        }
    }

    return <Button disabled={!src} variant="contained" onClick={handleClick}>{`PLAY Audio(${label})`}</Button>
}
