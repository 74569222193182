/**
 * Render the heading for Login/Registration view
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  bannerContainer: {
    padding: '0px 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.5rem',
    },
  },
  titleContainer: {
    //margin: "1rem 0",
    [theme.breakpoints.down('xs')]: {
      // margin: ".5rem 0",
    },
  },
  subHeading: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  instructions: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const LogRegBanner = ({ config }) => {
  const classes = useStyles();
  const { title, subHeading, instructions } = config ? config : {};
  return (
    <div className={classes.bannerContainer}>
      {false && <Typography variant="h6">Login / Registration</Typography>}
      {false && <Divider />}
      <div className={classes.titleContainer}>
        {title && (
          <Typography variant="h6" color="secondary">
            {title}
          </Typography>
        )}
        {subHeading && (
          <Typography classes={{ root: classes.subHeading }} variant="body1">
            {subHeading}
          </Typography>
        )}
        {instructions && (
          <Typography
            classes={{ root: classes.instructions }}
            variant="subtitle1"
            color="error"
          >
            {instructions}
          </Typography>
        )}
      </div>
    </div>
  );
};

LogRegBanner.propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    subHeading: PropTypes.string,
    instructions: PropTypes.string,
  }),
};

export default LogRegBanner;
