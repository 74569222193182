/**
 * VolumeControl
 *
 * Render a Slider with directional indicators to control media volume.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Slider } from '@material-ui/core';

//icons
import { MicrophonePlus } from 'mdi-material-ui';
import { MicrophoneMinus } from 'mdi-material-ui';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const VolumeControl = ({ mediaItem, onChange }) => {
  const negIcon = <RemoveIcon style={{ fontSize: '.8rem' }} />;
  const posIcon = <AddIcon style={{ fontSize: '.8rem' }} />;
  const device = mediaItem.device === 'microphone' ? 'mic' : mediaItem.device;
  const key = `${device}Volume`;

  return mediaItem?.stream ? (
    <Grid container spacing={1} alignItems="center">
      <Grid item>{negIcon}</Grid>
      <Grid item xs>
        <Slider
          value={mediaItem.volume}
          disabled={mediaItem.muted}
          onChange={(e, value) => onChange({ [key]: value })}
        />
      </Grid>
      <Grid item>{posIcon}</Grid>
    </Grid>
  ) : null;
};
VolumeControl.propTypes = {
  mediaItem: PropTypes.shape(),
  onChange: PropTypes.func,
};
VolumeControl.defaultProps = {
  mediaItem: PropTypes.shape({
    device: PropTypes.oneOf('microphone', 'speaker'),
    direction: PropTypes.oneOf('outbound', 'inbound'),
    stream: PropTypes.node,
    volume: PropTypes.number,
    muted: PropTypes.bool,
  }),
  onChange: () => {},
};

export default VolumeControl;
