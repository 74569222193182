/**
 * Dlog
 * Render diagnostic log
 * kayboard 'd' to toggles rendering within a Drawer
 * feature must be enabled in ui-slice
 */

import React from 'react';
import useEvent from '@react-hook/event';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { Grid, Drawer, IconButton, Divider } from '@material-ui/core';
import { uiActions } from 'store/ui-slice';

const useStyles = makeStyles({
  iconButton: {
    transform: 'scale(.8)',
    padding: 5,
  },
  dlogIcon: {
    position: 'fixed',
    top: 60,
    right: 10,
    zIndex: 500,
    transform: 'scale(.8)',
    opacity: 0.1,
  },
});

export default function Dlog({ topPosition }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enabled, open, buff } = useSelector(state => state.ui.dlog);
  const isOpen = enabled && open;

  // keyboard event handler
  // select next/previous message (left/right) or scroll playlist window view (up/down)
  useEvent(document, 'keydown', event => handleKeydown(event.keyCode));
  const handleKeydown = code => {
    if (enabled) {
      switch (code) {
        case 68:
          toggleOpen();
          break; //'d'
        default:
          break;
      }
    }
  };

  const setOpen = open => enabled && dispatch(uiActions.dlogOpen(open));
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const toggleOpen = () => setOpen(!open);
  const handleClear = () => dispatch(uiActions.dlogClear());
  return enabled ? (
    <>
      <IconButton
        classes={{ root: classes.dlogIcon }}
        style={{ top: topPosition }}
        onClick={toggleOpen}
      >
        D
      </IconButton>
      <Drawer anchor="bottom" variant="persistent" open={isOpen}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <h4 style={{ margin: 5 }}>Dlog</h4>
          </Grid>
          <Grid item>
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={handleClear}
            >
              {' '}
              <NotInterestedIcon />
            </IconButton>
            <IconButton onClick={handleClose}>
              {' '}
              <CloseIcon style={{ fontSize: '1rem' }} />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <div>
          {buff.map((item, ix) => (
            <div key={ix}>{item}</div>
          ))}
        </div>
      </Drawer>
    </>
  ) : null;
}
