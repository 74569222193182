/**
 * Encapsualate Player action buttons
 * in order to de-clutter the parent component.
 * Event handlers are supplied by the parent.
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Cancel as CancelIcon } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { Login } from 'mdi-material-ui';
import { currentPartition, currentCommunity } from 'lib/api/communityApi';

import ApplianceControls from 'components/utils/ApplianceControls';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import CommunityInfo from 'containers/WelcomeView/components/CommunityInfo';
import MembershipProfileForm from 'components/Settings/components/MembershipProfileForm';

const NonMemberActions = props => {
  const { onClick } = props;
  const [request, setRequest] = useState(null);

  const handleBtnClick = btn => {
    console.log('handlebtnClick:', btn);
    setRequest(btn);
    onClick && onClick(btn);
  };

  // recorder buttons
  const actionButtons = () => [
    {
      // Visible when mic use has not been approved
      label: 'Join',
      icon: <Login />,
      onClick: () => handleBtnClick('Join'),
    },
    {
      // always visible except while Recording
      label: 'Info',
      icon: <InfoIcon />,
      onClick: () => handleBtnClick('Info'),
    },
    {
      //Alwahys enabled
      label: 'Cancel',
      icon: <CancelIcon />,
      onClick: () => handleBtnClick('Cancel'),
    },
  ];

  const handleCommunityInfoExit = () => setRequest(null);
  const handleQuestionairreClose = () => handleBtnClick('Cancel');

  const handleJoinRequestComplete = joinedPartition => {
    console.log('handleJoinRequestComplete:', joinedPartition);
    onClick && onClick(joinedPartition ? 'Joined' : '');
    setRequest(null);
  };

  return (
    <>
      <ApplianceControls
        statusSpec={{
          visible: ['Join', 'Info', 'Cancel'],
          enabled: ['Join', 'Info', 'Cancel'],
        }}
        buttons={actionButtons()}
        legends
        groups={false}
      />
      <FullScreenDialog
        open={request === 'Info'}
        title={'Community Information'}
        onClose={handleCommunityInfoExit}
        content={
          <CommunityInfo
            partition={currentPartition()}
            onExitRequest={handleCommunityInfoExit}
          />
        }
        zen={true}
      />
      <FullScreenDialog
        open={request === 'Join'}
        title={'Community Membership Questionairre'}
        onClose={handleQuestionairreClose}
        content={
          <MembershipProfileForm
            community={currentCommunity()}
            onExit={handleJoinRequestComplete}
            bypassProfileRecording={true}
            processNewMember={props.processNewMember}
          />
        }
        zen={true}
      />
    </>
  );
};

NonMemberActions.propTypes = {
  recState: PropTypes.string.isRequired, //state of recording procedure
  onClick: PropTypes.func.isRequired,
  directed: PropTypes.bool, // is the message directed or public
  processNewMember: PropTypes.func,
};
NonMemberActions.defaultProps = {
  directed: false,
};

export default NonMemberActions;
