/**
 * VadHark
 * Detects voice on audiotrack in the specified stream,
 * Based on https://github.com/latentflip/hark#readme
 *
 * //@@ DOES not work on Safari
 *
 */

import hark from 'hark';

class VadHark {
  hark = null;
  options = null;

  // from voice-activity-detection sources
  defaultOptions = {
    interval: 200,
    threshold: -50,
    onVoiceStart: function () {
      console.log('hark talking');
    },
    onVoiceStop: function () {
      console.log('hark quiet');
    },
    onUpdate: function (val) {},
  };

  constructor({ type, context, audioStream, options = {}, key }) {
    this.key = key;
    this.type = type;

    this.options = { ...VadHark.defaultOptions, ...options };
    this.hark = hark(audioStream, this.options);
    this.hark.on('speaking', () => this.options.onVoiceStart());
    this.hark.on('stopped_speaking', () => this.options.onVoiceStop());
    //this.hark.on('volume_change', (v, th)=> console.log(`hark v=${v}, th=${th}`));
  }

  resume = () => this.hark && this.hark.resume();
  suspend = () => {
    this.hark && this.hark.suspend();
    this.options.onVoiceStop();
  };

  destroy = () => {
    this.suspend();
    if (this.hark) {
      this.hark.stop();
      delete this.hark;
    }
  };
}

export default VadHark;
