/**
 * Render a list of communities to which user is currently registered.
 * Provide controls to switch, edit, delete memberships in communities.
 * Alternative views selected by props.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCommunities,
  switchCommunity,
  memberAnnotation,
  removeUserFromCommunity,
} from 'lib/api/communityApi';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import MembershipProfileForm from './MembershipProfileForm';
import { t, trans } from 'lib/translation/trans';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever } from 'mdi-material-ui';

const useStyles = makeStyles(theme => ({
  membershipList: {},
  membershipItem: {
    marginBottom: 15,
  },
  iconContainer: {
    width: 30,
    height: 20,
  },
  checkedIcon: {
    transform: 'scale(.7)',
  },
  membershipDetail: {
    width: '80%',
  },
  profileUpdateForm: {},
  updateButton: {
    marginTop: 15,
  },

  wrapperOuter: {
    marginBottom: 15,
  },
}));

export default function MembershipsList({ aboutMe }) {
  const classes = useStyles();
  const { user } = useSelector(state => state.user?.auth);
  const [partitionToUpdate, setPartitionToUpdate] = useState(null);
  const [partitionToLeave, setPartitionToLeave] = useState(null);
  const activePartition = useSelector(
    state => state.ui.pSession.community?.partition
  );
  const communities = useSelector(state => state.ui.communities);
  const dispatch = useDispatch();
  const { members } = useSelector(state => state.user);

  // fetch the communities list
  useEffect(() => {
    getCommunities();
  }, []);

  // if active community changes asychronously
  useEffect(() => {
    if (partitionToUpdate && partitionToUpdate !== activePartition) {
      setPartitionToUpdate(activePartition);
    }
  }, [activePartition, partitionToUpdate]);

  // switch to new community
  const handleSwitchRequest = partition => {
    switchCommunity(
      communities.find(community => community?.partition === partition)
    );
  };

  // enable view/edit of user's community attributes
  const handleEditRequest = partition => {
    if (partition !== activePartition) {
      handleSwitchRequest(partition);
    }
    setPartitionToUpdate(partition);
  };

  // user has exited from view/edit view
  const handleEditCompletd = () => setPartitionToUpdate(null);

  // user has requested to leave membership in a community
  const handleLeaveRequest = partition => setPartitionToLeave(partition);

  // remove the user from the specified community
  // (reverse of processNewUser )
  const handleLeaveConfirmed = () => {
    removeUserFromCommunity(user, partitionToLeave)
      .then(rslt => {
        console.log('handleLeaveConfirmed:', rslt);
        setPartitionToLeave(null);
        return rslt;
      })
      .catch(err => console.log());
  };

  const handleStay = () => setPartitionToLeave(null);

  if (!user) {
    return null;
  }
  const renderStandard = partition => (
    <Grid
      key={partition}
      container
      alignItems="center"
      classes={{ root: classes.membershipItem }}
    >
      <Grid item classes={{ root: classes.iconContainer }}>
        {partition === activePartition ? (
          <CheckIcon classes={{ root: classes.checkedIcon }} />
        ) : null}
      </Grid>
      <Grid
        item
        classes={{ root: classes.membershipDetail }}
        container
        alignItems="center"
      >
        <Grid item xs={8}>
          {trans(communities.find(c => c.partition === partition)?.title)}
        </Grid>
        <Grid item>
          {partition === activePartition ? (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => handleEditRequest(partition)}
            >
              {t('Profile')}
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleSwitchRequest(partition)}
            >
              {t('Switch')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const renderWithParams = partition => {
    const current = partition === activePartition;
    return (
      <List dense={true} className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            {current ? (
              <Avatar>
                <CheckIcon />
              </Avatar>
            ) : null}
          </ListItemAvatar>
          <ListItemText
            style={{ color: '#0096ef' }}
            primary={trans(
              communities.find(c => c.partition === partition)?.title
            )}
            secondary={memberAnnotation(partition, user)}
          />
          <ListItemSecondaryAction>
            {current ? null : (
              <IconButton
                edge="end"
                onClick={() => handleLeaveRequest(partition)}
              >
                <DeleteForever />
              </IconButton>
            )}
            <IconButton edge="end" onClick={() => handleEditRequest(partition)}>
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  };

  if (partitionToUpdate) {
    return (
      <MembershipProfileForm
        community={communities.find(c => c.partition === partitionToUpdate)}
        onExit={handleEditCompletd}
      />
    );
  } else if (partitionToLeave) {
    return (
      <div style={{ textAlign: 'center' }}>
        <div>
          <h2 style={{ color: 'red' }}>{t('Leave_This_Community')}</h2>
        </div>
        <div>
          <h3 style={{ color: '#0096ef' }}>
            {trans(
              communities.find(c => c.partition === partitionToLeave)?.title
            )}
          </h3>
        </div>
        <div style={{ margin: 'auto', width: '75%' }}>{t('If_you_leave')}</div>
        <div>
          <Button
            color="primary"
            style={{ margin: 20 }}
            variant="contained"
            onClick={handleLeaveConfirmed}
          >
            {t('Leave')}
          </Button>
          <Button
            color="secondary"
            style={{ margin: 20 }}
            variant="contained"
            onClick={handleStay}
          >
            {t('Stay')}
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.membershipList}>
        {user.memberships.map(partition => {
          return aboutMe
            ? renderWithParams(partition)
            : renderStandard(partition);
        })}
      </div>
    );
  }
}

MembershipsList.propTypes = {
  aboutMe: PropTypes.bool,
};
MembershipsList.defaultProps = {
  aboutMe: false,
};
