/**
 * MembershipLevelsView Configuration
 *
 * Definition of plans, features and prices.
 *
 */

const features = {
  publicPlay: 'Listen to Public Playlists',

  createVoice: 'Create Voice Recordings',
  saveToCloud: 'Save to Cloud',
  voiceSocNet: 'Voice Social Network',
  memberList: 'View Membership List',
  protectId: 'Protected Identity',
  sharePublic: 'Share Public Messages',
  dm: 'Send/Receive Direct Messages',
  inboxBasic: '7-Day Inbox',

  pushNotify: 'Push Notifications',
  filtersPlaylist: 'Playlist Filters',
  bookmarks: 'Bookmark & Blacklist Members',
  liveCall: 'Live Conversations',
  inboxPlus: 'Expanded Inbox',
  skipAds: 'Skip Advertisements',
  cancelAnytime: 'Cancel at Any Time',

  landing: 'Personalized Landing Page',
  branding: 'Branding Tools (Image, Logo, Avatar, etc.)',
  hosting: 'Hosted Discussions',
  eventNotify: 'Event Notifications to Fans/Followers',
  videoList: 'Curated Video/Media List',

  aggregateBasic: '(all Basic features plus... )',
  aggregateSuper: '(all Super features plus... )',
};

const aggregate = {
  super: "(all 'Basic' features plus...)",
  professional: "(all 'Super' features plus...)",
};

export const levels = [
  {
    key: 'visitor',
    title: 'Visitor',
    rank: 0,
    features: ['publicPlay'],
    description: 'Best for first visit by user exploring the OkSayit community',
  },
  {
    key: 'basic',
    title: 'Basic Level (free)',
    rank: 100,
    features: [
      'createVoice',
      'saveToCloud',
      'voiceSocNet',
      'memberList',
      'protectId',
      'sharePublic',
      'dm',
      'inboxBasic',
    ],
    description:
      'Best for new users who wish to use the free version of OkSayit',
  },
  {
    key: 'super',
    title: 'Super Level',
    rank: 200,
    features: [
      'aggregateBasic',
      'pushNotify',
      'filtersPlaylist',
      'bookmarks',
      'liveCall',
      'inboxPlus',
      'skipAds',
      'cancelAnytime',
    ],
    description:
      'Best for frequent users who wish to optimize communication methods and features',
  },
  {
    key: 'professional',
    title: 'Professional Level',
    rank: 300,
    features: [
      'aggregateSuper',
      'landing',
      'branding',
      'hosting',
      'eventNotify',
      'videoList',
    ],
    description:
      'Best for brands, influencers and self-promoters who wish to curate discussions and recruit fans on OkSayit',
  },
];

const catalog = [
  { sku: '1001', unit: 'month', unitPrice: 0, level: 'visitor', sample: false },
  { sku: '1100', unit: 'month', unitPrice: 0, level: 'basic', sample: false },
  { sku: '1200', unit: 'month', unitPrice: 0, level: 'super', sample: true },
  {
    sku: '1300',
    unit: 'month',
    unitPrice: 0,
    level: 'professional',
    sample: true,
  },
  {
    sku: '2200',
    unit: 'month',
    unitPrice: 4.99,
    level: 'super',
    sample: false,
  },
  {
    sku: '2300',
    unit: 'month',
    unitPrice: 99,
    level: 'professional',
    sample: false,
  },
];

// return all catalog items with level details attached
export const catalogItems = catalog.map(c => {
  // find the level, if it exists
  const level = levels.find(l => l.key === c.level) || {};

  return {
    ...c,
    level: {
      ...level,
      features: level.features.map(f => features[f] || f), // lookup feature names
    },
  };
});
