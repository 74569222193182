import ProductItem from './ProductItem';
import classes from './Products.module.css';

const   DUMMY_PRODUCTS = [
  { id: 2, price:5, title: 'item 2', description:"My Product number 2" },
  { id: 3, price:6, title: 'item 3', description:"My Product number 3" }

]



const Products = (props) => {
  return (
    <section className={classes.products}>
      <h2>Buy your favorite products</h2>
      <ul>
        {DUMMY_PRODUCTS.map((item) => (
        <ProductItem
        key= {item.id}
        id= {item.id}
        title= {item.title}
        price={item.price}
        description= {item.description}
      />

        ))}
      </ul>
    </section>
  );
};

export default Products;
