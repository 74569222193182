/**
 * Render a selector for the main menu position
 *  - getter:   seSelector(state => state.ui.pSession.menuPosition)
 *  - setter:   dispatch(uiActions.setMenuPosition(selection.target.value))
 */

import React from 'react';
import RadioStd from 'components/utils/RadioStd';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  formStyle: {
    position: 'relative',
  },
  groupStyle: {
    // compensate for left margin on first rendered item
    position: 'relative',
    left: -16,
  },
}));

export default function MenuPositionSelector() {
  const currentMenuPosition = useSelector(
    state => state.ui.pSession.menuPosition
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  const items = [
    { key: 'tabs', data: t('Top') },
    { key: 'footer', data: t('Bottom') },
    { key: 'drawer', data: t('Side') },
  ];

  const handleChange = selection =>
    selection.target.value !== currentMenuPosition &&
    dispatch(uiActions.setMenuPosition(selection.target.value));

  return (
    <RadioStd
      name="mainMenu"
      classes={classes}
      items={items}
      value={currentMenuPosition}
      handleChange={handleChange}
    />
  );
}
