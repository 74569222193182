/**
 * Playlist v1
 * Renders entire playlist in a div with scrollbar.
 * Employs auto sizing and react-scroll to prevent window scrollbar
 * Also detects Element scrollbar and adjusts TopicCard layout.
 *
 * //TODO: implement swipable view
 * //TODO: smart management of scrollbar position
 * //TODO: lacks the ability to keep the selected item in view: consider using https://github.com/fisshy/react-scroll
 *
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import TopicCard from './TopicCard_v6';

import { useSelector, useDispatch } from 'react-redux';
import { dialogActions } from 'store/dialog-slice';
import { uiActions } from 'store/ui-slice';

import { isTopic } from 'lib/api/messageApi';

import { Typography } from '@material-ui/core';

import { timeout } from 'lib/utils/utils';
import debounce from 'lodash.debounce';
import { t } from 'lib/translation/trans';

//NOTE: react-scroll wants  "react": "^16.6.0"
// install with 'npm install --force'
const Scroll = require('react-scroll');
const ElementContainer = Scroll.Element;
const Events = Scroll.Events;

const Playlist = ({
  playlist,
  playlistItemComponent,
  onMessageSelect,
  onMemberInfoRequest,
  onActionsEvent,
  height,
  isPlaying,
  isAuthenticated,
  singleThread,
  zen,
}) => {
  const { selectedMessageId, openTopicId, visibility, played } = useSelector(
    state => state.dialog
  );
  const [scrollbarPresent, setScrollbarPresent] = useState(false);

  const mobile = useSelector(state => state.ui.deviceInfo.mobile);
  const containerRef = useRef();
  const dispatch = useDispatch();
  const isSelected = id => id === selectedMessageId;
  const select = id => onMessageSelect(id);
  const toggleOpen = id => dispatch(dialogActions.toggleOpenTopic(id));
  const isPlayed = id => played.find(item => item === id);

  // A vertical scrollbar appears dynamically, depending on whether the playlist overflows its container.
  // On desktop devices, the scrollbar takes up space and required adjustment to the TopicCard layout.
  // However, detection of the scrollbar based on clientheight-scrollHeight mismatch requires a delay after component renders.
  // Scrollbar transitions are expected on changes of visibility, openTopic, and window

  const detectScrollbar = () =>
    !mobile &&
    containerRef.current &&
    containerRef.current.childBindings.domNode.clientHeight !==
      containerRef.current.childBindings.domNode.scrollHeight;

  // give the scrollbar a chance to stabilize
  const handleScrollbarChange = () =>
    timeout(500).then(() => {
      setScrollbarPresent(detectScrollbar());
    });

  // events that likely might change scrollbar status
  useEffect(() => {
    handleScrollbarChange();
  }, [openTopicId, visibility]);

  // watch for resizing of window
  useEffect(() => {
    const handleResize = debounce(() => {
      handleScrollbarChange();
    }, 200);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // holdoff respondTips during playlist scroll
  useEffect(() => {
    Events.scrollEvent.register('begin', () =>
      dispatch(uiActions.respondTipHoldoff(true))
    );
    Events.scrollEvent.register('end', () =>
      dispatch(uiActions.respondTipHoldoff(false))
    );
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  /*
   historical experiment

       const handleResize = debounce(() => {
           setWindowWidth(window.innerWidth)
       }, 100);

       const togglePlay = (isPlaying) => {
           setplayerState(isPlaying ? PLAYING : PAUSED)
       }

       useEffect(() => {
           // update containerWidth whenever windowWidth has changed
           setContainerWidth(containerRef.current.offsetWidth)
           // watch for resizing of window
           window.addEventListener("resize", handleResize)

           //cleanup
           return (() => window.removeEventListener("resize", handleResize))
       }, [windowWidth]);

   */

  //determine if specified message is the last response in a group
  // (check if it immediately precedes a topic)
  const lastInGroup = index =>
    playlist.length === index + 1 || isTopic(playlist[index + 1]);

  //determine if specified message is the first after its topic
  // (check if it immediately succeeds a topic)
  const firstResponse = index => index > 0 && isTopic(playlist[index - 1]);

  /*

// render open thread as a single item
const openThreadStartIx = openTopicId ? playlist.findIndex(msg => msg.id===openTopicId) : -1
const openThreadLength = playlist.reduce((acc, msg) => acc + (getFamilyId(msg) === openTopicId ? 1 : 0) , 0)

const segments = []
segments[0] = playlist.slice(0, openTopicId ? openThreadStartIx : playlist.length)  // entire list if no thread open
segments[1] = playlist.slice(openThreadStartIx, openThreadStartIx + openThreadLength) // the open thread
segments[2] = openTopicId ? playlist.slice(segments[0].length + segments[1].length ) : [] // subsequent to the open thread

// render playlist with the open topic (if any) encapsulated as a single element
const renderList = segments.reduce((acc, segment, ix) => {
    if (ix===1 && segment.length>0 ) {
        return [...acc, <div style={{border: '1px solid black'}}> {segment.map((item, index) =>
            <TopicCard
                    key={index}
                    selected={() => isSelected(item.id)}
                    select={() => select(item.id)}
                    isOpen={item.id === openTopicId}
                    isLastInGroup={lastInGroup(index)}
                    isFirstResponse={firstResponse(index)}
                    played={() => isPlayed(item.id)}
                    toggleOpen={() => toggleOpen(item.id)}
                    message={item}
                    onActionsEvent={onActionsEvent}
                />
        )}</div> ]   //append encapsulated items
    } else {
        return [...acc, ...segment ]
    }
}, [])
*/
  // wrap ElementContainer in an overflow div so that scrollbar can be generated by playlist and detected
  // wrap playlist in ElementContainer to support automatic scrolling and controls
  return (
    <ElementContainer
      id="playlistContainerId"
      ref={containerRef}
      style={zen ? null : { overflow: 'auto', height: height }}
    >
      {playlist.length === 0 ? (
        isAuthenticated ? (
          <>
            <Typography
              style={{ textAlign: 'center', marginTop: '3rem' }}
              component="div"
              variant="h6"
            >
              {t('no_messages')}
            </Typography>
            <Typography
              style={{ textAlign: 'center' }}
              component="div"
              variant="h6"
            >
              {t('in this category')}
            </Typography>
          </>
        ) : (
          <Typography
            style={{ textAlign: 'center', marginTop: '3rem' }}
            component="div"
            variant="h6"
          >
            {t('You_Must_Login_To_See_Your_Messages')}
          </Typography>
        )
      ) : (
        playlist.map((item, index) =>
          //if item component not specified in props, use default
          React.cloneElement(
            playlistItemComponent ? playlistItemComponent : <TopicCard />,
            {
              key: index,
              selected: () => isSelected(item.id),
              select: () => select(item.id),
              onMemberInfoRequest,
              isOpen: item.id === openTopicId,
              played: () => isPlayed(item.id),
              toggleOpen: () => toggleOpen(item.id),
              message: item,
              onActionsEvent: onActionsEvent,
              scrollbarPresent,
              isPlaying: isSelected(item.id) && isPlaying,

              // not referenced by TopicCard_v2a
              isLastInGroup: lastInGroup(index),
              isFirstResponse: firstResponse(index),
              hideResponseIndicator: singleThread,
            }
          )
        )
      )}
    </ElementContainer>
  );
};

Playlist.propTypes = {
  playlist: PropTypes.arrayOf(PropTypes.object),
  onMessageSelect: PropTypes.func,
  onMemberInfoRequest: PropTypes.func,
  onActionsEvent: PropTypes.func,
  height: PropTypes.number,
  isPlaying: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  singleThread: PropTypes.bool,
  zen: PropTypes.bool,
};
Playlist.devaultProps = {
  singleThread: false,
  zen: false,
};

export default Playlist;
