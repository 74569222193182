/**
 *
 * Render a fixed-height top section and a dynamic bottom section.
 * Bottom section enforces overflow so that scrollbar is limited
 * to the bottom section only.
 */

import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { updateObjectKeys } from 'lib/utils/utils';

const useStyles = makeStyles(theme => ({
  topSection: {},
  bottomSection: {
    //height: calculate dynamically,
    overflow: 'auto', //automatically generate scrollbar
  },
}));

const HorizontalSplit = ({ topItem, bottomItem, classes: classesOverride }) => {
  // refactored using updateObjectKeys
  //const classes = Object.entries(useStyles()).reduce((acc, [key, val]) => {
  //  return {
  //    ...acc,
  //    [key]: classesOverride[key] ? `${val} ${classesOverride[key]}` : val,
  //  };
  //}, {});
  const classes = updateObjectKeys(useStyles(), classesOverride);

  const [topSectionFullHeight, setTopSectionFullHeight] = useState(null);
  const totalAvailableHeight = useSelector(
    state => state.ui.layout.mainContentHeight
  );
  const topSectionRef = useRef();

  // measure the fixed component height
  useEffect(() => {
    if (topSectionRef.current) {
      // note: margins of children may extend beyond topSectionRef
      const computedStyle = getComputedStyle(topSectionRef.current);
      const marginTop = parseInt(computedStyle?.marginTop);
      const marginBottom = parseInt(computedStyle?.marginBottom);
      setTopSectionFullHeight(
        topSectionRef.current.clientHeight + marginTop + marginBottom
      );
    }
  }, [topSectionRef.current?.clientHeight]);

  //  console.log(
  //    `HorizontalSplit topSectionRef.current?.clientHeight=${
  //      topSectionRef.current?.clientHeight
  //    }, totalAvailableHeight=${totalAvailableHeight}, topSectionFullHeight=${topSectionFullHeight}, , bottomHeight=${
  //      totalAvailableHeight - topSectionFullHeight
  //    }`
  //  );

  return (
    <>
      <div ref={topSectionRef} className={classes.topSection}>
        {topItem}
      </div>
      <div
        className={classes.bottomSection}
        style={{
          height: totalAvailableHeight - topSectionFullHeight,
        }}
      >
        {/*if scrollbar is necessary, isolate it to this bottomSection div rather than to the full document */}
        {bottomItem}
      </div>
    </>
  );
};

HorizontalSplit.defaultProps = {
  classes: {},
};
export default HorizontalSplit;
