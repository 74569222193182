/**
 * MuteButton
 * Mute/Enable talking (microphone) or listening (speaker) during LiveCall
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'lib/translation/trans';
import { webRTCActions } from 'store/webRTC-slice';
import ApplianceControls, {
  wildcard,
} from 'components/utils/ApplianceControls';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import { VolumeHigh } from 'mdi-material-ui';
import { VolumeOff } from 'mdi-material-ui';

const useStyles = makeStyles(theme => ({}));

const MuteButton = ({ legends, device }) => {
  const classes = { ...useStyles() };
  const mutedMic = useSelector(state => state.webRTC.muted);
  const mutedSpeaker = useSelector(
    state => state.webRTC.participants?.remote?.muted
  );

  const micVolume = useSelector(
    state => state.ui.pSession.liveCallAudioSettings.micVolume
  );
  const speakerVolume = useSelector(
    state => state.ui.pSession.liveCallAudioSettings.speakerVolume
  );

  const dispatch = useDispatch();
  const muted = () => (device === 'microphone' ? mutedMic : mutedSpeaker);
  const toggle = () =>
    device === 'microphone'
      ? dispatch(webRTCActions.toggleMic())
      : dispatch(webRTCActions.toggleSpeaker());
  const icon = () =>
    device === 'microphone' ? (
      muted() ? (
        <MicOffIcon />
      ) : (
        <MicIcon />
      )
    ) : muted() ? (
      <VolumeOff />
    ) : (
      <VolumeHigh />
    );

  return (
    <ApplianceControls
      groups={false}
      legends={legends}
      buttons={[
        {
          label: muted() ? t('Enable') : t('Mute'),
          icon: icon(),
          visible: [wildcard],
          enabled: [wildcard],
          onClick: toggle,
        },
      ]}
    />
  );
};

MuteButton.propTypes = {
  legends: PropTypes.bool,
  device: PropTypes.oneOf('microphone', 'speaker'), //'microphone', 'speaker'
};
MuteButton.defaultProps = {
  legends: true,
  device: 'microphone',
};

export default React.memo(MuteButton);
