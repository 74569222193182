/**
 * Message Recorder SuccessAlert
 * Render temporary alert reporting successful result of recording operation
 */

import React, { useEffect } from 'react';
import { uiActions } from 'store/ui-slice';
import { isToAdmin, isPublicType } from 'lib/api/messageApi';
import { useDispatch } from 'react-redux';
import { t } from 'lib/translation/trans';

const SuccessAlert = ({ type, clipboardCaptured, onCompleted }) => {
  const dispatch = useDispatch();

  const alertContent = () =>
    isToAdmin(type)
      ? [t('Your_message_will_be_delivered_to_management')]
      : [
          summary(),
          clipboardCaptured ? (
            <div>{`${t('Message_Link_Copied_to_Clipboard')} !`}</div>
          ) : null,
        ];

  // summary message, always rendered
  const summary = () => {
    const label = type === 'invite' ? t('invitation') : t('message');
    const action = isPublicType(type) ? t('Saved') : t('Sent');
    return `${t('Your')} ${label} ${t('has_been')} ${action}.`;
  };

  // trigger an alert when component mounts.
  useEffect(() => {
    type === 'invite' ||
      dispatch(
        uiActions.alertSet({
          message: alertContent(),
          severity: 'success',
        })
      );
    onCompleted && onCompleted();
  }, []);

  return null;
};
export default SuccessAlert;
