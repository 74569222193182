/**
 * Render a user's Inbox
 * ( some functional overlap with PlaylistView/PlaylistBrowser )
 *
 * PlaylistView functions missing here:
 * - setVisibilityFilter
 * - Tips tickling
 * - playlist visibility filter
 * - keyboard event handling
 * - handle next/previsou
 * - handleMessageSelect
 * - handlEnded
 *
 *
 */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { List, Typography } from '@material-ui/core';
import { uiActions } from 'store/ui-slice';
import { fetchDialogs, nextMsg } from 'lib/api/messageApi';
import { WEB_HOST } from 'configs/config-hvn';
import BorderedBlock from 'components/utils/BorderedBlock';
import { t } from 'lib/translation/trans';
import Message from './components/Message';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import { webRTCActions } from 'store/webRTC-slice';
import useEvent from '@react-hook/event';
import { useHistory } from 'react-router-dom';
import useInboxConsumer from 'containers/InboxView/hooks/useInboxConsumer';

const InboxView = props => {
  const { limit, renderControls, embed, noRedirect } = props;
  const [ix, setIx] = useState(null); // index of currently selected message
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [initialLength, setInitialLength] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { layout, pSession } = useSelector(state => state.ui);
  const { auth, isAuthenticated } = useSelector(state => state.user);
  const {
    dialogsFetchedFlg,
    inboundIdQueue,
    inboundIdQueueInitialized,
    messageMap,
  } = useSelector(state => state.dialog);
  const { autoPlay } = pSession;
  const messageMapLength = Object.keys(messageMap).length;
  const totalAvailableHeight = layout.mainContentHeight;
  const nonScrollingSectionRef = useRef(null);
  const nonScrollingSectionHeight =
    nonScrollingSectionRef?.current?.clientHeight;
  const scrollableSectionMaxHeight =
    totalAvailableHeight - nonScrollingSectionHeight;

  const noteMessageConsumed = useInboxConsumer();

  // create alias for play() so that additional local processing can be attached
  const {
    isPlaying,
    defaultProps,
    setSrc,
    src,
    play: _play,
    pause,
  } = useVoicePlayerHelper({ playerKey: 'Inbox' });
  const msg = id => messageMap[id];
  const getSrc = message => (message ? `${WEB_HOST}/${message.fileUrl}` : '');
  const isLoggedIn = Boolean(isAuthenticated && auth.user?.id);

  // INITIALIZE --------------------------------------------------------------------------------

  // if not authenticated, immediately launch Login dialog
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(uiActions.holdoffCookieConsentBar(true));
      dispatch(uiActions.setRegDialog({ open: true, mode: 'login' }));
    } else {
      // fetch the dialogs if necessary
      if (!dialogsFetchedFlg) {
        fetchDialogs();
      }
    }
  }, [isLoggedIn]);

  // manage inboundQueue change
  useEffect(() => {
    if (isLoggedIn && dialogsFetchedFlg && inboundIdQueueInitialized) {
      // limit the list for consideration
      const q = inboundIdQueue.slice(0, limit);
      const newLen = q.length;

      // conditionally redirect to PlayViewManager if there is only one initial message
      if (!noRedirect && initialLength === null) {
        // initial queue status, before messages are added or removed
        setInitialLength(q.length);
        if (q.length === 1) {
          history.push(`/play/${q[0]}`);
        }
      }

      const lastIx = newLen ? newLen - 1 : null; // last ix on the list
      let newIx = q.findIndex(id => id === selectedMessageId); // ix of the selected message

      // if none selected and list is not empty, select the first
      // otherwise if prior id remains, adjust ix if necessary
      // otherwise if prior ix remains select its id
      // otherwise select last message if it exists
      // otherwise the inbox is empty
      if (!selectedMessageId && newLen) {
        if (Boolean(messageMapLength)) {
          //console.log("selecting first on list.");
          setIx(0);
          setSelectedMessageId(q[0]);
        }
        setSrc(getSrc(msg(q[0])));
      } else if (newIx !== -1) {
        //console.log("selected message remains.");
        setIx(newIx);
        return;
      } else if (q[ix]) {
        //console.log("selecting next message.");
        setSelectedMessageId(q[ix]);
        setSrc(getSrc(msg(q[ix])));
      } else if (lastIx !== null) {
        //console.log("selecting end of list");
        setIx(lastIx);
        setSelectedMessageId(q[lastIx]);
        setSrc(getSrc(msg(q[lastIx])));
      } else {
        //console.log("Inbox is empty.");
        setSelectedMessageId(null);
        setIx(null);
        setSrc(null);
        return;
      }
    }
  }, [
    isLoggedIn,
    dialogsFetchedFlg,
    inboundIdQueue,
    inboundIdQueueInitialized,
    limit,
    Boolean(messageMapLength),
  ]);

  // disable assertion of new alerts while this component is mounted
  useEffect(() => {
    dispatch(uiActions.preventNewInboxAlerts(true));

    // upon dismount of Inbox
    return () => {
      // resume presentation of Inbox alerts
      dispatch(uiActions.preventNewInboxAlerts(false));
    };
  }, []);

  // END INITIALIZE --------------------------------------------------------------------------------

  // mark message consumed (listende to)
  // mark specified id, or if unspecified mark the currently selected message
  const markAsConsumed = (id = selectedMessageId) => {
    noteMessageConsumed(msg(id), true);
  };

  // play the current message and also annotate that it has been consumed
  const play = msgId => {
    _play();
    markAsConsumed();
  };

  const handleActionEvent = (msgId, ev) => {
    ev === 'playClick' ? play(msgId) : pause();
  };

  // user has clicked on a message
  const handleMessageSelected = (ix, id) => {
    const changed = selectedMessageId !== id;

    const setState = () => {
      const message = msg(id);
      setIx(ix);
      setSelectedMessageId(id);
      setSrc(getSrc(message));
      if (message?.type === 'invite') {
        dispatch(webRTCActions.selectInvite(message?.userId));
      }
    };

    if (autoPlay && changed) {
      markAsConsumed(id); // VoicePlayer will automatically start to play
      setState();
    } else if (autoPlay && !changed) {
      // clicking the message toggles play
      isPlaying ? pause() : play();
    } else if (!autoPlay && changed) {
      pause();
      setState();
    } else {
      // !autoPlay && !changed
      // clicking the selected message has no effect
    }

    dispatch(uiActions.respondTipPing()); // let the respondTip mechanise keep track of message indexing
  };

  // keyboard event handler
  // select next/previous message (left/right) or scroll playlist window view (up/down)
  useEvent(document, 'keydown', event => handleKeydown(event.keyCode));
  const handleKeydown = code => {
    switch (code) {
      case 38:
        handlePreviousRequest();
        break; //up
      case 40:
        handleNextRequest();
        break; //down
      case 39:
        handleNextRequest();
        break; //right
      case 37:
        handlePreviousRequest();
        break; //left
      default:
        break;
    }
  };

  // select next/previous message in playlist
  const handlePreviousRequest = () => {
    /* dispatch */ nextMsg({ directionReverse: true });
  };
  const handleNextRequest = () => {
    /*dispatch*/ nextMsg();
  };

  // prescribe props combinations for versatile rendering of Player
  /*
  const playerProps = {
    playerKey,
    ref: playerRef,
    src,
    autoplay: false,
    onAudioEvent: handleAudioEvent,

    // props passed to PlayerUI
    noBrowserViewControl: true,
    progressStyle: "standard",
    onNext: (inboundQueue.length > 1) ? handleNextRequest : null,
    onPrevious: (inboundQueue.length > 1) ? handlePreviousRequest : null,
    uiItems: ['progressPretto'],
    ident: "Inbox"
  }
*/

  const playerProps = {
    ...defaultProps,
    autoplay: autoPlay,
    noBrowserViewControl: true,
    progressStyle: 'standard',
    onNext: inboundIdQueue.length > 1 ? handleNextRequest : null,
    onPrevious: inboundIdQueue.length > 1 ? handlePreviousRequest : null,
    uiItems: embed ? [] : ['progressPretto'],
    ident: 'Inbox',
  };

  // Advise unauthenticated user to login
  if (!isLoggedIn) {
    return (
      <BorderedBlock labelTop={`${t('Inbox')}`}>
        <Typography variant="h6">
          {t('You_Must_Login_To_See_Your_Messages')}
        </Typography>
      </BorderedBlock>
    );
  }

  // render the raw list of messages
  const renderList = () =>
    inboundIdQueue.length ? (
      <List>
        {inboundIdQueue
          .slice(0, limit)
          .map(
            (id, ix) =>
              msg(id) && (
                <Message
                  key={ix}
                  message={msg(id)}
                  selected={id === selectedMessageId}
                  onSelect={() => handleMessageSelected(ix, id)}
                  onActionEvent={handleActionEvent}
                  isPlaying={isPlaying && selectedMessageId === id}
                />
              )
          )}
      </List>
    ) : (
      <Typography style={{ marginTop: 30 }} variant="h4">
        (No Messages)
      </Typography>
    );

  return embed ? (
    <>
      {renderList() /*raw list without border */}
      <VoicePlayer {...playerProps} />
    </>
  ) : (
    <>
      <div
        id="non-scrolling"
        ref={nonScrollingSectionRef}
        style={{ paddingBottom: 10 }}
      >
        <VoicePlayer {...playerProps} />
      </div>

      <div
        id="scrolling"
        style={{ overflow: 'auto', maxHeight: scrollableSectionMaxHeight }}
      >
        <BorderedBlock
          labelTop={`${t('Inbox_for')} ${auth.user?.screen_name} ( ${
            inboundIdQueue.length
          } )`}
          labelTopRight={renderControls}
          classes={{}}
        >
          {dialogsFetchedFlg ? renderList() : null}
        </BorderedBlock>
      </div>
    </>
  );
};
export default InboxView;

InboxView.propTypes = {
  renderControls: PropTypes.node,
  limit: PropTypes.number,
  embed: PropTypes.bool,
};
InboxView.defaultProps = {
  renderControls: null,
  limit: 1000000,
  embed: false,
  noRedirect: false,
};
