/**
 * MessageRecorderDialog
 * Render as FullScreenDialog or Dialog2 depending on xs
 * Retains capability to employ legacy Recorder component.
 *
 */

import React from 'react';
import Dialog2 from 'components/Modals/Dialog2';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import MessageRecorder from 'containers/MessageRecorder';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { t } from 'lib/translation/trans';
import { BRAND } from 'configs/config-hvn';
import { fromProxyUser, retrieveParentMessage } from 'lib/api/messageApi';
// respond to a post, or if message.type is a child, respond to its parent
export default function MessageRecorderDialog({
  open,
  onClose,
  onActionEvt,
  message,
  typeOverride,
  bannerOverride,
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const heading = t('Respond_to_this_Message');

  const handleClose = rslt => {
    onClose && onClose(rslt);
  };

  const recorderProps = () => {
    if (fromProxyUser(message)) {
      const parentMessage = retrieveParentMessage(message);

      return {
        parentMessage: null,
        typeOverride: 'outgoing',
        bannerOverride: 'Message to a Non-Member',
        toOverride: parentMessage?.recipientScreenName,
        titleOverride: parentMessage?.title
          ? `Re: ${parentMessage.title}`
          : null,
      };
    } else {
      return {
        parentMessage: message,
        typeOverride: typeOverride,
        bannerOverride: bannerOverride,
      };
    }
  };

  const dialogContent = () => (
    <MessageRecorder
      renderAsAppliance={false}
      onExit={handleClose}
      onActionEvt={onActionEvt}
      {...recorderProps()}
    />
  );

  return (
    <>
      <Dialog2
        open={!xs && open}
        onClose={handleClose}
        heading={null}
        content={dialogContent()}
        actions={null}
      />

      <FullScreenDialog
        open={xs && open}
        onClose={null}
        title={BRAND}
        content={<div style={{ paddingTop: 100 }}>{dialogContent()}</div>}
        zen={true}
      />
    </>
  );
}

/*

*/
