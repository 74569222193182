/**
 * DrawerMenu
 * Render menu in a modal drawer
 */

import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Toolbar, IconButton, Drawer, Divider } from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DisplayLanguageSelector from 'components/Settings/components/DisplayLanguageSelector';
import MainMenu from 'components/MainMenu';

const useStyles = makeStyles(theme => ({
  drawer: {
    zIndex: theme.zIndex.drawer,
  },
  drawerPaper: {},
  drawerManu: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    marginTop: 20,
    paddingTop: 40,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20,
    },
  },
  toolbar: {
    minHeight: 64,
    [theme.breakpoints.down('xs')]: {
      minHeight: 40,
    },
  },
}));

const DrawerMenu = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerClose = () => {
    onClose && onClose();
  };

  const handleModalEvent = e => {
    console.log('handleModalEvent:', e);
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Escape')) {
      handleDrawerClose();
    }
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        onClose: e => handleModalEvent(e),
        onKeyDown: e => handleModalEvent(e),
      }}
    >
      <div className={classes.drawerManu}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />

      <MainMenu format="List" legendStyle="none" onChange={handleDrawerClose} />
      <Divider />

      <DisplayLanguageSelector variant="toggle" />
    </Drawer>
  );
};

DrawerMenu.propTypes = {};
DrawerMenu.defaultProps = {};

export default DrawerMenu;
