// English phrase translation map

const en_trans = {
  T$_Country: 'Country',
  T$_About_You: 'About You',
  T$_Accept: 'Accept',
  T$_Accept_all_Terms_and_Conditions: 'Accept all Terms & Conditions',
  T$_Accept_Call: 'Accept Call',
  T$_Acknowledge: 'Acknowledge',
  T$_Advertisement: 'Advertisement',
  T$_Age: 'Age',
  T$_Age_Range: 'Age Range',
  T$_Approaching_maximum_record_time: 'Approaching maximum record time!',
  T$_Attributes: 'Attributes',
  T$_Authenticating: 'Authenticating...',
  T$_Authentication_Method: 'Authentication Method',
  T$_authentication_Page: 'authentication Page',
  T$_bad_sound_quality: 'bad sound quality',
  T$_Beta_Test: 'Beta Test',
  T$_blob: 'blob',
  T$_Block: 'Block',
  T$_BLOCK: 'BLOCK',
  T$_boring: 'boring',
  T$_Call: 'Call',
  T$_Cancel: 'Cancel',
  T$_Cancel_Call: 'Cancel Call',
  T$_Carousel: 'Carousel',
  T$_character_limit: 'character limit.',
  T$_checked: 'checked',
  T$_Choose_a_screen_name: 'Choose a screen name',
  T$_Clear_Filters: 'Clear Filters',
  T$_close: 'close',
  T$_Close: 'Close',
  T$_Confirm_Password: 'Confirm Password',
  T$_Congratulations_NEW_MEMBER:
    'Congratulations NEW MEMBER! You can now proceed with your recording.',
  T$_Connect: 'Connect',
  T$_Connected: 'Connected',
  T$_Contact_and_Support_Request: 'Contact / Support Request',
  T$_controls: 'controls',
  T$_Copy_Link: 'Copy Link',
  T$_Create_OkSayit_Password: 'Create OkSayit Password',
  T$_Create_Password: 'Create Password',
  T$_Create_Username: 'Create Username',
  T$_create_User: 'create User',
  T$_Decline: 'Decline',
  T$_Decline_Call: 'Decline Call',
  T$_decrement: 'decrement',
  T$_defaults: 'defaults',
  T$_Delete: 'Delete',
  T$_DELETE: 'DELETE',
  T$_Direct_Message: 'Direct Message',
  T$_Dismiss: 'Dismiss',
  T$_Email: 'Email',
  T$_Enable: 'Enable',
  T$_Ended: 'Ended',
  T$_Enter_Your_Code: 'Enter Your Code',
  T$_Error: 'Error!',
  T$_example: 'example',
  T$_Exit: 'Exit',
  T$_file: 'file',
  T$_Filters: 'Filters',
  T$_First_Time_Registration: 'First Time Registration',
  T$_Francophone_Anglophone_Conversation_Exchange:
    'Francophone-Anglophone Conversation Exchange',
  T$_gender: 'gender',
  T$_Gender: 'Gender',
  T$_getAttributes: 'getAttributes',
  T$_Get_Invitation: 'Get Invitation',
  T$_Great_your_phone_is_verified: 'Great, your phone is verified !',
  T$_Hangup: 'Hangup',
  T$_I_agree_to_the_terms_and_conditions: 'I agree to the terms and conditions',
  T$_increase: 'increase',
  T$_increment: 'increment',
  T$_Info: 'Info',
  T$_Initial_Registration: 'Initial Registration',
  T$_Inquiry: 'Inquiry',
  T$_invitation: 'invitation',
  T$_Invitation: 'Invitation',
  T$_Invitation_Code: 'Invitation Code',
  T$_It_should_only_take_a_few_seconds: 'It should only take a few seconds.',
  T$_Join: 'Join',
  T$_Language: 'Language',
  T$_Like: 'Like',
  T$_lines: 'lines',
  T$_Live_Call_Invitation: 'Live Call Invitation',
  T$_Login: 'Login',
  T$_Login_RegisterMessage_Type: 'Login / Register',
  T$_Login_Now: 'Login Now',
  T$_Logout: 'Logout',
  T$_Main_Menu: 'Main Menu',
  T$_Meetup_username: 'Meetup username',
  T$_Meetup_Username: 'Meetup Username',
  T$_message: 'message',
  T$_Message_Notification: 'Message Notification',
  T$_Message_Type: 'Message Type',
  T$_The_recorder_will_start_automatically_next_time:
    'The recorder will start automatically next time.',
  T$_More: 'More',
  T$_More_Info: 'More Info',
  T$_My_Profile: 'My Profile',
  T$_Native_Language: 'Native Language',
  T$_Next: 'Next',
  T$_Notification: 'Notification',
  T$_now: 'now',
  T$_OK: 'OK',
  T$_OkSayit_password: 'OkSayit password',
  T$_open: 'open',
  T$_Open: 'Open',
  T$_other_inappropriate_content: 'other inappropriate content',
  T$_Partner_Preference: 'Partner Preference',
  T$_Password: 'Password',
  T$_Pause: 'Pause',
  T$_Phone: 'Phone',
  T$_Play: 'Play',
  T$_Playlist: 'Playlist',
  T$_position: 'position',
  T$_Previous: 'Previous',
  T$_Profile_for: 'Profile for',
  T$_Profile_Recording: 'Profile Recording',
  T$_progress: 'progress',
  T$_Public_Comment: 'Public Comment',
  T$_Public_Post: 'Public Post',
  T$_Public_Screen_Name: 'Public Screen Name',
  T$_Record: 'Record',
  T$_Recorder_Start: 'Recorder Start',
  T$_Register: 'Register',
  T$_Regrets: 'Regrets',
  T$_Regrets_Message: 'Regrets Message',
  T$_Request: 'Request',
  T$_Residency: 'Residency',
  T$_Respond: 'Respond',
  T$_Returning_User: 'Returning User',
  T$_Review: 'Review',
  T$_Ringing: 'Ringing',
  T$_rude: 'rude',
  T$_Screen_Name: 'Screen Name',
  T$_Select_method: 'Select method',
  T$_Sending: 'Sending...',
  T$_Sent_cart_data: 'Sent cart data!',
  T$_Setting_up_your_account: 'Setting up your account',
  T$_sexual_content: 'sexual content',
  T$_Share: 'Share',
  T$_Skill: 'Skill',
  T$_Skill_Level: 'Skill Level',
  T$_Stop: 'Stop',
  T$_Submit: 'Submit',
  T$_Success: 'Success...',
  T$_Suggestion: 'Suggestion',
  T$_Support_Request: 'Support Request',
  T$_Terms_and_Conditions: 'Terms and Conditions',
  T$_test: 'test',
  T$_Thank_you_for_regestering_you_are_logged_in:
    'Thank you for regestering, you are logged in !',
  T$_There_is_a: 'There is a',
  T$_There_was_a_problem_saving_your_recording:
    'There was a problem saving your recording.',
  T$_This_is_an_alert: 'This is an alert',
  T$_Tip: 'Tip',
  T$_title: 'title',
  T$_toggle: 'toggle',
  T$_Toggle_Counter: 'Toggle Counter',
  T$_Topic: 'Topic',
  T$_unMark: 'unMark',
  T$_User_ID: 'User ID',
  T$_Username: 'Username',
  T$_Validate: 'Validate',
  T$_Visit: 'Visit',
  T$_visualizer: 'visualizer',
  T$_Voice_Profile: 'Voice Profile',
  T$_We_are_texting_a_code_to: 'We are texting a code to',
  T$_You_are_logged_in: 'You are logged in !',
  T$_Your_invitation_is_approved: 'Your invitation is approved.',
  T$_Your_Message_is_cancelled: 'Your Message is cancelled.',
  T$_Your_name_cannot_include: 'Your name cannot include',
  T$_Your_Primary_Language: 'Your Primary Language',
  T$_Your_Public_Identification: 'Your Public Identification',
  T$_Your_recording_has_been_downloaded: 'Your recording has been downloaded.',
  T$_Your_recording_is_cancelled: 'Your recording is cancelled.',
  T$_Your_recording_is_short: 'Your recording is short!',
  T$_Your_Voice_Profile_for_this_Community:
    'Your Voice Profile for this Community',
  T$_Subject: 'Subject',
  T$_About_Yourself: 'About Yourself',
  T$_Message_Title: 'Message Title',
  T$_Enter_a_Headline_about_you: 'Enter a Headline about you',
  T$_Please_enter_a_Headline_about_you: 'Please enter a Headline about you',
  T$_Please_enter_a: 'Please enter a',
  T$_Creating_a_Message: 'Creating a Message',
  T$_Direct: 'Direct',
  T$_Live_Call: 'Live Call',
  T$_Profile: 'Profile',
  T$_Message: 'Message',

  T$_Accept_this_invitation_to_talk_live:
    'Accept this invitation to talk live.',
  T$_Bookmark_the_author_of_this_message:
    'Bookmark the author of this message.',
  T$_Cancel_this_action: 'Cancel this action.',
  T$_Commence_playback: 'Commence playback.',
  T$_Decline_this_invitation_to_talk_live:
    'Decline this invitation to talk live.',
  T$_Delete_this_message: 'Delete this message.',
  T$_End_this_live_conversation: 'End this live conversation.',
  T$_Invite_this_user_to_talk_live: 'Invite this user to talk live.',
  T$_More_Actions: 'More Actions.',
  T$_Obtain_a_link_to_this_message_that_can_be_pasted_to_social_media:
    'Obtain a link to this message that can be pasted to social media.',
  T$_Pause_playback: 'Pause playback.',
  T$_Record_a_voice_response_to_decline_this_invitation_to_talk_live:
    'Record a voice response to decline this invitation to talk live.',
  T$_Record_a_voice_response_to_this_message:
    'Record a voice response to this message.',
  T$_Remove_bookmark: 'Remove bookmark.',
  T$_Show_this_information_dialog: 'Show this information dialog.',
  T$_spacer: 'spacer',
  T$_Block_all_messages_from_this_person:
    'Block all messages from this person.',

  T$_Comment: 'Comment',
  T$_Create_Your_Community_Profile_for: 'Create Your Community Profile for',
  T$_Public: 'Public',
  T$_Save: 'Save',
  T$_Send: 'Send',
  T$_to: 'to',
  T$_Creating_a: 'Creating a ',
  T$_READY: 'READY',
  T$_Message_Recorder: 'Message Recorder',
  T$_Download: 'Download',
  T$_Please_login_to_save_your_message: 'Please login to save your message',

  T$_Please_record_at_least: 'Please record at least',
  T$_sec: 'sec.',
  T$_cannot_be_created: 'cannot be created',
  T$_You_must_Login_to_save_this_message: 'You must Login to save this message',
  T$_Problems: 'Problems',
  T$_Message_Player: 'Message Player',
  T$_Action_Options: 'Action Options',
  T$_The_following_actions_are_available_for_this_message:
    'The following actions are available for this message',
  T$_not_all_options_are_available_for_every_message:
    'not all options are available for every message',
  T$_Browse: 'Browse',
  T$_Discuss: 'Discuss',
  T$_Listen: 'Listen',
  T$_Members: 'Members',
  T$_Settings: 'Settings',
  T$_Talk: 'Talk',

  T$_What_is_your_skill_level_in_the_language_you_wish_to_learn:
    'What is your skill level in the language you wish to learn?',
  T$_What_is_your_age_range: 'What is your age range?',
  T$_No_Preference: 'No Preference',
  T$_profile: 'profile',
  T$_Advanced: 'Advanced',
  T$_Intermediate: 'Intermediate',
  T$_Beginner: 'Beginner',
  T$_Over_40: 'Over 40',
  T$_Under_40: 'Under 40',
  T$_Female: 'Female',
  T$_Male: 'Male',
  T$_Anglophone: 'Anglophone',
  T$_Francophone: 'Francophone',
  T$_sms: 'sms',

  T$_password: 'password',
  T$_google: 'google',
  T$_facebook: 'facebook',

  T$_My_Memberships: 'My Memberships',
  T$_Your_Memberships: 'Your Memberships',
  T$_Profile_For: 'Profile For',
  T$_Confirm: 'Confirm',
  T$_Community_Profile_Recording: 'Community Profile Recording',

  T$_Login_with_Google: 'Login with Google',
  T$_Login_with_Facebook: 'Login with Facebook',
  T$_Login_with_Email: 'Login with Email',
  T$_Login_with_Phone: 'Login with Phone',
  T$_Username_Password: 'Username & Password',
  T$_Login: 'Login',
  T$_Setting_Up: 'Setting Up...',
  T$_Awaiting_Google: 'Awaiting Google',
  T$_Please_Record: 'Please Record',
  T$_Community_Members: 'Community Members',
  T$_from: 'from',
  T$_by: 'by',
  T$_post: 'post',
  T$_tip: 'tip',
  T$_ad: 'ad',
  T$_notice: 'notice',
  T$_comment: 'comment',
  T$_years: 'years',
  T$_months: 'months',
  T$_days: 'days',
  T$_hours: 'hours',
  T$_minutes: 'minutes',
  T$_seconds: 'seconds',
  T$_ago: 'ago',
  T$_Delete_This_Message: 'Delete This Message',
  T$_This_message: 'This message',
  T$_and_all_of_its_responses: 'and all of its responses ',
  T$_will_be_permanently_deleted: 'will be permanently deleted!',
  T$_You_cannot_delete_this_message: 'You cannot delete this message',
  T$_Personal: 'Personal',
  T$_no_messages: 'no messages',
  T$_in_this_category: 'in this category',

  T$_Share_This_Message: 'Share This Message',
  T$_Use_the_button_to_copy_a_link_to_this_message:
    'Use the button to copy a link to this message',
  T$_Paste_it_to_social_media_or_email: 'Paste it to social media or email',
  T$_Bookmarking: 'Bookmarking',
  T$_Bookmarked_members_will_appear_with_a:
    'Bookmarked members will appear with a ',
  T$_Blocking: 'Blocking',
  T$_because: 'because',
  T$_You_will_no_longer_hear_messages_from:
    'You will no longer hear messages from',
  T$_Information: 'Information',
  T$_Indicate_that_you_like_the_author_of_this_message:
    'Indicate that you like the author of this message',
  T$_Block_all_messages_from_this_author: 'Block all messages from this author',
  T$_Account: 'Account',
  T$_Preferences: 'Preferences',
  T$_Available_Communities: 'Available Communities',
  T$_Switch: 'Switch',
  T$_General_Group: 'General Group',
  T$_Language_Group: 'Language Group',
  T$_Local_Interest_Group: 'Local Interest Group',
  T$_Cannot_Bookmark: 'Cannot Bookmark',
  T$_You_must_be_logged_in_to_set_a_bookmark:
    'You must be logged-in to set a bookmark !',
  T$_You_must_be_logged_in_to_block_a_user:
    'You must be logged-in to block a user',
  T$_This_user_is_already_bookmarked: 'This user is already bookmarked',
  T$_You_cannot_apply_a_bookmark_to_yourself:
    'You cannot apply a bookmark to yourself',
  T$_You_cannot_block_yourself: 'You cannot block yourself',
  T$_This_user_is_already_blocked: 'This user is already blocked',
  T$_Please_select_the_reason_for_blocking:
    'Please select the reason for blocking',
  T$_bookmarked: 'bookmarked',
  T$_blocked: 'blocked',
  T$_Cannot_Block: 'Cannot Block',
  T$_Applying_Bookmark: 'Applying Bookmark',
  T$_Updating_Blacklist: 'Updating Blacklist',

  T$_Top: 'Top',
  T$_Bottom: 'Bottom',
  T$_Display_Language: 'Display Language',
  T$_What_is_your_gender: 'What is your gender?',
  T$_English: 'English',
  T$_Spanish: 'Spanish',
  T$_French: 'French',
  T$_Lithuanian: 'Lithuanian',
  T$_What_gender_do_you_want_to_speak_with:
    'What gender do you want to speak with?',

  T$_This_device_has_been_logged_out_because_you_have_logged_in_at_another_location:
    'This device has been logged out because you have logged in at another location.',
  T$_Saving_your_message: 'Saving your message...',
  T$_Message_Saved: 'Message Saved',
  T$_Saved: 'Saved',
  T$_Sent: 'Sent',

  T$_It_will_appear_in_XXX_playlist:
    "It will appear in XXX's playlist. You can also copy a link and send it by email to XXX if you wish -- Just use the button below to get a link.",

  T$_It_will_appear_in_the_public_playlist:
    'It will appear in the public playlist. ',
  T$_You_can_also_copy_and_paste_it_to_your_social_media_just_use_the_button_below_to_get_a_link:
    'You can also copy and paste it to your social media, just use the button below to get a link.',

  T$_Your: 'Your',
  T$_has_been: 'has been',
  T$_It_will_be_delivered_to_management: 'It will be delivered to management.',

  T$_You_have_blocked_use_of_your_microphone:
    'You have blocked use of your microphone.',
  T$_Your_browser_is_blocking_use_of_your_microphone_Use_your_browser_Settings_to_correct_this_issue:
    'Your browser is blocking use of your microphone. Use your browser Settings to correct this issue.',
  T$_Please_try_again_Approve_use_of_your_microphone_to_continue:
    'Please try again. Approve use of your microphone to continue.',
  T$_There_was_a_problem_obtaining_permission_to_use_your_microphone:
    'There was a problem obtaining permission to use your microphone.',
  T$_Processing_Community_membership: 'Processing Community membership...',
  T$_Your_Contact_Info: 'Your Contact Info',
  T$_Please_Join_This_Community_In_Order_To_Save_Your_Message:
    'Please Join This Community In Order To Save Your Message!',
  T$_There_is_a: 'There is a',
  T$_character_limit: 'character limit',
  T$_auto_start: 'auto start',
  T$_Auto_Start: 'Auto Start',
  T$_Live_Call_invitation_from: 'Live Call invitation from',
  T$_You_can_connect_now: 'You can connect now',
  T$_Call_Time: 'Call Time',
  T$_PROBLEM: 'PROBLEM',
  T$_accepted: 'accepted',
  T$_Calling: 'Calling',
  T$_Invite_from: 'Invite from',
  T$_Live_Call_Invites: 'Live Call Invites',
  T$_Live_Call_with: 'Live Call with',
  T$_to_accept: 'to accept…',
  T$_has_accepted_your_call: 'has accepted your call.',
  T$_Regrets_Message: 'Regrets Message:',
  T$_declined_your_invitation: 'declined your invitation.',
  T$_This_invitation_cannot_go_through_right_now:
    'This invitation cannot go through right now.',
  T$_Your_party_may_no_longer_be_available:
    'Your party may no longer be available.',
  T$_Invitation_Message: 'Invitation Message:',
  T$_You_have_accepted_this_call: 'You have accepted this call.',
  T$_Waiting_for: 'Waiting for',
  T$_to_connect: 'to connect...',
  T$_Your_call_has_ended: 'Your call has ended.',
  T$_was_calling_you: 'was calling you...',
  T$_This_call_cannot_connect_right_now: 'This call cannot connect right now.',
  T$_Live_Call_Invitations: 'Live Call Invitations',
  T$_Live_Call_Status: 'Live Call Status',
  T$_This_call_is_cancelled: 'This call is cancelled...',
  T$_Your_device_disconnected: 'Your device disconnected.',
  T$_XXXs_device_disconnected: "XXX's device disconnected.",

  T$_Live_Call_accepted_by: 'Live Call accepted by',
  T$_Live_Call_declined_by: 'Live Call declined by',
  T$_inquiry_to_ad: 'inquiry to ad',
  T$_You_have_received_a_Live_Call_invitation:
    'You have received a Live Call invitation.',
  T$_A_new_message_has_arrived_Just_for_you: `A new message has arrived "Just for you".`,
  T$_Inbox: 'Inbox',
  T$_Chat: 'Chat',
  T$_Less: 'Less',
  T$_This_Community: 'This Community',

  T$_Adult: 'Adult',
  T$_Available_to_Registered_Users: 'Available to Registered Users',
  T$_Best_practices_get_the_most_out_of_the_Service:
    'Best practices get the most out of the Service',
  T$_Business: 'Business',
  T$_Community_Selection: 'Community Selection',
  T$_Connect_with_like_minded_strangers: 'Connect with like-minded strangers',
  T$_Features: 'Features',
  T$_General: 'General',
  T$_Language_Practice_EN_DE: 'Language Practice - EN / DE',
  T$_Language_Practice_EN_LT: 'Language Practice - EN / LT',
  T$_Lexington_Residency: 'Lexington Residency',
  T$_Lexington_Senior_Meetingplace: 'Lexington Senior Meetingplace',
  T$_Local_Interest: 'Local Interest',
  T$_Member_List: 'Member List',
  T$_Other: 'Other',
  T$_Overview: 'Overview',
  T$_Practice_your_language_skills: 'Practice your language skills',
  T$_Recommendations: 'Recommendations',
  T$_Resident: 'Resident',
  T$_Senior: 'Senior',
  T$_To_get_the_most_out_of_the_Service: 'To get the most out of the Service',
  T$_Visitor: 'Visitor',
  T$_Welcome_To_OkSayit: 'Welcome To OkSayit',
  T$_Youth: 'Youth',

  T$_Communities: 'Communities',
  T$_Inbox_for: 'Inbox for ',
  T$_Empty: 'Empty',
  T$_From: 'From',
  T$_Your_Messages: 'Your Messages',
  T$_General_Chat_Group: 'General Chat Group',
  T$_SMS_Text_Code: 'SMS Text Code',
  T$_Enter_your_MOBILE_number: 'Enter your MOBILE number',
  T$_Mobile_phones_only: 'Mobile phones only. Message rates may apply.',
  T$_Enter_the_code_we_sent_to: 'Enter the code we sent to',
  T$_Incorrect_Validation_Code: 'Incorrect Validation Code !',
  T$_Please_check_the_code_we_sent:
    'Please check the code we sent, and enter it here carefully.',
  T$_Logging_in_with_mobile_text: 'Logging in with mobile text',
  T$_Your_okSayit_account_verification_code_is:
    'Your okSayit account verification code is',
  T$_is_not_a_valid_phone_number: 'is not a valid phone number !',
  T$_Please_enter_a_valid_phone_number: 'Please enter a valid phone number.',
  T$_is_not_a_US_or_Canada_phone_number: 'is not a US or Canada phone number !',
  T$_Please_enter_a_US_or_Canada_phone_number:
    'Please enter a US or Canada phone number.',
  T$_is_not_a_mobile_phone_in_France: 'is not a mobile phone in France !',
  T$_Please_enter_a_mobile_number_in_France:
    'Please enter a mobile number in France.',
  T$_About_Me: 'About Me',
  T$_For_Each_Membership_You_Can_Adjust_Your_Profile:
    'For Each Membership You Can Adjust Your Profile',
  T$_Logging_Out: 'Logging Out...',
  T$_Welcome: 'Welcome',
  T$_Update_Your_Profile_Recording: 'Update Your Profile Recording',

  T$_What_age_range_do_you_want_to_speak_with:
    'What age range do you want to speak with?',
  T$_What_skill_level_do_you_want_to_speak_with_Check_all_that_apply:
    'What skill level do you want to speak with? (Check all that apply)',
  T$_What_languages_do_you_want_to_speak_with_Check_all_that_apply:
    'What languages do you want to speak with? (Check all that apply)',
  T$_What_residency_do_you_want_to_speak_with_Check_all_that_apply:
    'What residency do you want to speak with? (Check all that apply)',

  T$_Member_Gender: 'Member Gender',
  T$_Member_Age_Range: 'Member Age Range',
  T$_Member_Language: 'Member Language',
  T$_Member_Native_Language: 'Member Native Language',
  T$_Member_Residency: 'Member Residency',
  T$_Member_Skill_in_Second_Language: 'Member Skill in Second Language',
  T$_Mute: 'Mute',
  T$_Send_a_direct_message_to_this_member:
    'Send a direct message to this member.',
  T$_No_Available_Communities: 'No Available Communities',
  T$_Leave_This_Community: 'Leave This Community',
  T$_If_you_leave:
    'If you leave, your profile and all your recordings in this community will be erased !',
  T$_Stay: 'Stay',
  T$_Leave: 'Leave',
  T$_Suppress_echo: 'Suppress echo',
  T$_You_are_calling: 'You are calling',
  T$_is_calling_you: 'is calling you',
  T$_Hide: 'Hide',

  T$_You: 'You',
  T$_hung_up: 'hung up.',
  T$_cancelled_this_call: 'cancelled this call.',
  T$_declined_this_invitation: 'declined this invitation.',
  T$_Text_Invitation: 'Text Invitation',
  T$_Send_Text_Invitation: 'Send Text Invitation',
  T$_Send_Voice_Invitation: 'Send Voice Invitation',
  T$_Bookmark_this_person: 'Bookmark this person',
  T$_public: 'public',
  T$_direct: 'direct',
  T$_foul_language: 'foul language',
  T$_Public_Posts: 'Public Posts',
  T$_Member_Profile_Information: 'Member Profile Information',
  T$_language: 'language',
  T$_ageRange: 'ageRange',
  T$_skill: 'skill',
  T$_Logging_in_with_Facebook: 'Logging in with Facebook',
  T$_Facebook_login_not_available: 'Facebook login not available',
  T$_Facebook_login_cannot_connect: 'Facebook login cannot connect',
  T$_Facebook_dialog_closed_by_user: 'Facebook dialog closed by user',
  T$_Facebook_login_failed: 'Facebook login failed',
  T$_Awaiting_Facebook: 'Awaiting Facebook',
  T$_Logging_in_with_Google: 'Logging in with Google',
  T$_Profile_not_available: 'Profile not available',
  T$_No_profile: 'No profile',
  T$_Google_dialog_closed_by_user: 'Google dialog closed by user',
  T$_Google_popup_blocked: 'Google popup blocked',
  T$_Authentication_Problem: 'Authentication Problem',
  T$_Registration: 'Registration',
  T$_You_Cant_Learn_To_Speak: "You Can't Learn to Speak Without Speaking !",

  T$_Authenticating_now: 'Authenticating now...',
  T$_Carrier_charges_may_apply: 'Carrier charges may apply.',
  T$_Daily_Limit: 'Daily Limit',
  T$_Direct_DM: 'Direct (DM)',
  T$_Direct_Reply: 'Direct Reply',
  T$_Disable: 'Disable',
  T$_Disabled: 'Disabled',
  T$_Enabled: 'Enabled',
  T$_from_your_mobile_phone: 'from your mobile phone.',
  T$_German: 'German',
  T$_Logging_you_in: 'Logging you in...',
  T$_Messages_will_be_sent_from_the_number:
    'Messages will be sent from the number',
  T$_Method: 'Method',
  T$_Mobile_Phone: 'Mobile Phone',
  T$_Notifications_Settings: 'Notifications Settings',
  T$_Please_Accept_Cookies_Before_Continuing:
    'Please Accept Cookies Before Continuing !',
  T$_Please_Select: 'Please Select',
  T$_Please_Wait: 'Please Wait',
  T$_Public_Response: 'Public Response',
  T$_Receive_Notifications_When_Messages_Arrive_For_You:
    'Receive Notifications When Messages Arrive For You',
  T$_Record_a_new_message: 'Record a new message.',
  T$_Reply: 'Reply',
  T$_Required: 'Required',
  T$_Respond_to_this_message: 'Respond to this message.',
  T$_Sending: 'Sending',
  T$_Status: 'Status',
  T$_Stopped: 'Stopped',
  T$_Text: 'Text',
  T$_START: 'START',
  T$_To_enable_Notifications: 'To enable Notifications',
  T$_Updating_your_preferences: 'Updating your preferences...',
  T$_When_you_enable_Notifications: 'When you enable Notifications:',
  T$_You_can_revoke_approval:
    "You can revoke approval at any time using this form or by replying 'Stop' to any notification.",
  T$_You_give_approval_for: 'You give approval for',
  T$_to_send_notification_messages_to_your_phone:
    'to send notification messages to your phone.',
  T$_Maybe_Later: 'Maybe Later',
  T$_Stop_Asking: 'Stop Asking',
  T$_Notifications: 'Notifications',

  T$_Direct_Messages: 'Direct Messages',
  T$_Inbox_View: 'Inbox View',
  T$_Side: 'Side',
  T$_Recorder: 'Recorder',
  T$_Player: 'Player',
  T$_Retrieving_Messages: 'Retrieving Messages...',
  T$_You_Must_Login_To_See_Your_Messages: 'You Must Login To See Your Messages',
  T$_Dont_Show_Again: 'Dont Show Again',
  T$_It_is_also_in_your_clipboard: 'It is also in your clipboard',
  T$_Your_message_will_be_delivered_to_management:
    'Your message will be delivered to management',
  T$_invitation: 'invitation',
  T$_message: 'message',
  T$_Message: 'Message',
  T$_Saved: 'Saved',
  T$_Sent: 'Sent',
  T$_Your: 'Your',
  T$_has_been: 'has been',
  T$_You_can: 'You can',
  T$_paste_it: 'paste it',
  T$_to_email: 'to email',
  T$_to_social_media_or_email: 'to social media or email',
  T$_Sleeping: 'Sleeping',
  T$_Wake_Up: 'Wake Up',
  T$_Delete_Your_Archive: 'Delete Your Archive',
  T$_All_Messages_In_This_List: 'All Messages In This List',
  T$_New: 'New',
  T$_Archive: 'Archive',
  T$_latest: 'latest',
  T$_No_Message_Specified: 'No Message Specified',
  T$_Sorry_cannot_find_that_message:
    'Sorry, cannot find that message. It may have expired or it may have been deleted.',
  T$_Access_denied: 'Access denied, this message is not addressed to you',
  T$_This_message_is_addressed_to:
    'You are not logged in. This message is addressed to ',
  T$_If_you_are: 'If you are',
  T$_please_login_to_play_it: 'please login to play it',
  T$_Message_Link_Copied_to_Clipboard: 'Message Link Copied to Clipboard',
  T$_Set_Up: 'Set Up',
  T$_Problem: 'Problem',

  T$_is_busy_cant_speak: "is busy and can't speak now.",
  T$_Start_recording_immediately:
    'Start recording immediately when Recorder is opened.',
  T$_Start_playback_immediately:
    'Start audio playback immediately when an item is selected.',
  T$_Connect_immediately:
    'Connect immediately with voice when someone accepts your invitation.',

  T$_Not_Available: 'Not Available',
  T$_Subscribed: 'Subscribed',
  T$_You_will_be_notified_of_DM:
    'You will be notified when direct messages arrive for you.',
  T$_By_pressing_next_you_agree:
    'By pressing NEXT you agree to receive a one-time text message with verification code',
};

export default en_trans;

/*
Native Language
Second-Language Skill
Gender
Age Range
*/
