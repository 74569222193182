/**
 * CommunityInfo
 *
 * Render a description of the specified community (partition),
 * and offer option to Join.
 *
 * Requires that user already be authenticated.
 *
 */

import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getCommunity, switchCommunity } from 'lib/api/communityApi';
import { Grid } from '@material-ui/core';
import ButtonStd from 'components/utils/ButtonStd';
import CommunityIndicator from 'components/layouts/components/CommunityIndicatorV1';
import MembershipProfileForm from 'components/Settings/components/MembershipProfileForm';
import CommunityDescriptionPanels from './CommunityDescriptionPanels';
import CommunityInfoPlayer from './CommunityInfoPlayer';

const useStyles = makeStyles(theme => ({
  nonScrollingContainer: {
    paddingTop: '1rem',
  },
  actions: {
    marginTop: '1rem',
  },
  bannerContainer: {
    margin: '1rem',
  },
  buttonGroup: {
    textAlign: 'center',
    margin: '2rem',
  },
}));

// provide welcome page for the specified partition
// if not specified in prop, use value from redux
// if still none specified, use defaultPartition as default

const CommunityInfo = ({ partition, onExitRequest }) => {
  const [community, setCommunity] = useState(null);
  const [joinRequest, setJoinRequest] = useState(false);
  const { isAuthenticated } = useSelector(state => state.user);
  const classes = useStyles();
  const history = useHistory();
  const playerRef = useRef();

  const [userClicked, setUserClicked] = useState(false); // use has clicked on an item

  // initialize
  useEffect(() => {
    // only proceed if user is authenticated
    if (isAuthenticated) {
      getCommunity(partition, 'with media')
        .then(community => {
          if (community) {
            setCommunity(community);
          }
        })
        .catch(err => {
          console.log('getCommunity error:', err);
          history.replace('/');
        });
    }
  }, []);

  const handleAccordionChange = () => {
    if (!userClicked) {
      // on first accordion click, start Player
      playerRef.current?.play && playerRef.current.play();
    }
    setUserClicked(true);
  };

  const handleExitRequest = () => {
    onExitRequest &&
      onExitRequest({ action: 'exit', partition: community.partition });
  };
  const handleJoinRequest = () => setJoinRequest(true);
  const handleJoinRequestComplete = joinedPartition => {
    setJoinRequest(false);
    onExitRequest &&
      onExitRequest({ action: 'joined', partition: joinedPartition });
  };

  // let the user visit the specified community
  const handleVisitRequest = () => {
    switchCommunity(community);
    onExitRequest &&
      onExitRequest({ action: 'visit', partition: community.partition });
  };

  // user must be authenticated
  //if (!isAuthenticated) {
  //    return <h2>User must be logged in to proceed!</h2>
  // }

  // user has requested to join this community
  if (joinRequest) {
    return (
      <MembershipProfileForm
        community={community}
        onExit={handleJoinRequestComplete}
      />
    );
  }

  return (
    <Grid container direction="column" alignItems="center">
      <CommunityIndicator
        titleOverride={`Joining: ${community?.title}`}
        classes={classes}
      />
      <CommunityInfoPlayer ref={playerRef} community={community} />
      <div className={classes.buttonGroup}>
        <ButtonStd
          onClick={handleVisitRequest}
          label={'Visit'}
          color="secondary"
        />
        <ButtonStd
          onClick={handleJoinRequest}
          label={'Join'}
          color="secondary"
        />
        <ButtonStd onClick={handleExitRequest} label={'Exit'} color="default" />
      </div>
      <CommunityDescriptionPanels
        community={community}
        onActivity={handleAccordionChange}
      />
    </Grid>
  );
};

CommunityInfo.propTypes = {
  partition: PropTypes.string, // optional specification of community key
  onExitRequest: PropTypes.func,
};

export default CommunityInfo;
