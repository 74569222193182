/**
 * Test CORS handshaking between client and server
 * 1. read d8 voice_message node
 * 2. attempt to play its file with embedded <audio /> as well as with Audio() button
 * 3. read binary file data and create a blob URL
 * 4. attempt to play blob with embedded <audio /> as well as with Audio() button
 */

import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core'
import AudioElementPlayer from './components/AudioElementPlayer'
import AudioObjectPlayer from './components/AudioObjectPlayer'
import corsTestUtils from './components/corsTestUtils'
import { useSelector } from 'react-redux'


const useStyles = makeStyles({
  button: {
    margin: 10,
    width: 120
  },
  label: {
    width: 80,
  },
  logArea: {
    marginTop: "2rem",
    border: "1px solid white",
    width: "100%",
    minHeight: "5rem",
    padding: "1rem"
  },

  overflowSection: {
    //height: calculate dynamically, 
    overflow: 'auto'   //automatically generate scrollbar

  },
  fixedSection: {
  }
});

export default function CORStest() {
  const demoFileUrl = 'https://hvn.hotlynx.com/sites/default/files/2020-07/kelly.mp3'
  const [demoBlobUrl, setDemoBlobUrl] = useState(null)

  const [logData, setLogData] = useState([])
  const [fileUrl, setFileUrl] = useState(null)
  const [blobUrl, setBlobUrl] = useState(null)
  const classes = useStyles();
  const utils = corsTestUtils({ logCallback: setLogData })

  const fixedSectionRef = useRef()
  const totalAvailableHeight = useSelector(state => state.ui.layout.mainContentHeight)
  const [fixedSectionHeight, setFixedSectionHeight] = useState(null)
  const overflowSectionHeight = totalAvailableHeight - fixedSectionHeight - 100 // account for DemoSelector height

  // measure the fixed component height
  useEffect(() => {
    // note: margins of children may extend beyond fixedSectionRef
    const computedStyle = getComputedStyle(fixedSectionRef.current)
    const marginTop = parseInt(computedStyle?.marginTop);
    const marginBottom = parseInt(computedStyle?.marginBottom);
    setFixedSectionHeight(fixedSectionRef.current.clientHeight + marginTop + marginBottom)
  }, [])




  // fetch the most recently created voice_message node
  const handleFetchNode = () => utils.fetchLatestFileUrl()
    .then(fileUrl => {
      setFileUrl(fileUrl)
      utils.log(`handleFetchNode success: fileUrl= ${fileUrl}`)
    })
    .catch(err => utils.log("handleFetchNode error:", err))

  // fetch binary file data
  const handleFetchBlob = () => utils.fetchBlobUrl(fileUrl)
    .then(blobUrl => {
      setBlobUrl(blobUrl)
      utils.log(`handleFetchBlob success`)
    })
    .catch(err => utils.log("handleFetchBlob error:", err))

  // fetch Demo (hard wired) binary file data
  const handleFetchDemoBlob = () => utils.fetchBlobUrl(demoFileUrl)
    .then(blobUrl => {
      setDemoBlobUrl(blobUrl)
      utils.log(`handleFetchDemoBlob success`)
    })
    .catch(err => utils.log("handleFetchDemoBlob error:", err))


  const renderLog = () => <ul>
    {logData.map((item, ix) => <li key={ix} >{item}</li>)}
  </ul>

  return (<>
    <Grid container direction="column" ref={fixedSectionRef} classes={{ root: classes.fixedSection }} >
    <Grid item container alignItems="center">
        <Grid item ><Button classes={{ root: classes.button }} variant="outlined" disabled={!demoFileUrl} onClick={handleFetchDemoBlob}>Fetch Direct</Button></Grid>
        <Grid item ><Typography classes={{ root: classes.label }} variant="body1">Play Direct Blob:</Typography></Grid>
        <Grid item> <AudioElementPlayer src={demoBlobUrl} logCallback={setLogData} /></Grid>
        <Grid style={{ marginLeft: 10 }} item><AudioObjectPlayer src={demoBlobUrl} logCallback={setLogData} /></Grid>

      </Grid>

      <Grid item container alignItems="center">
        <Grid item ><Button classes={{ root: classes.button }} variant="outlined" onClick={handleFetchNode}>Fetch Node</Button></Grid>
        <Grid item ><Typography classes={{ root: classes.label }} variant="body1">Play File:</Typography></Grid>
        <Grid item> <AudioElementPlayer src={fileUrl} logCallback={setLogData} /></Grid>
        <Grid style={{ marginLeft: 10 }} item><AudioObjectPlayer src={fileUrl} logCallback={setLogData} /></Grid>
      </Grid>

      <Grid item container alignItems="center">
        <Grid item ><Button classes={{ root: classes.button }} variant="outlined" disabled={!fileUrl} onClick={handleFetchBlob}>Fetch Blob</Button></Grid>
        <Grid item ><Typography classes={{ root: classes.label }} variant="body1">Play Blob:</Typography></Grid>
        <Grid item> <AudioElementPlayer src={blobUrl} logCallback={setLogData} /></Grid>
        <Grid style={{ marginLeft: 10 }} item><AudioObjectPlayer src={blobUrl} logCallback={setLogData} /></Grid>

      </Grid>
    </Grid>
    {/*if scrollbar is necessary, isolate it to the overflow div rather than to the full document */}
    <div className={`${classes.overflowSection} ${classes.logArea}`} style={{ height: overflowSectionHeight }}>
      <Typography variant="h6">Results Log</Typography>
      {renderLog()}
    </div>

  </>
  );
}
