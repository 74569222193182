/**
 * Render a suggestion dialog
 * Triggered by: uiActions.suggestionInvoke
 *
 */

import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';

import ExceptionDialog from 'components/Modals/ExceptionDialog';
import { t } from 'lib/translation/trans';
import Suggestion from './components/Suggestion';

// import custom hook for each supported suggeation type
import usePushNotificationSuggestion from './hooks/usePushNotificationSuggestion';
import useSMSNotificationSuggestion from './hooks/useSMSNotificationSuggestion';
import useAppInstallSuggestion from './hooks/useAppInstallSuggestion';

const SuggestionDialog = () => {
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useSelector(state => state.user);
  const { suggestionControl, pSession } = useSelector(state => state.ui);
  const { suggestionDisableTypes } = pSession;

  const dispatch = useDispatch();
  const { invoke, type } = suggestionControl;

  const hooks = {
    app_install: useAppInstallSuggestion(handleClose),
    push_notifications: usePushNotificationSuggestion(handleClose),
    sms_notifications: useSMSNotificationSuggestion(handleClose),
  };

  // a plug is employed until 'type' is intialized
  const plug = 'push_notifications';
  const {
    config,
    canRenderSuggestion: typeSpecificCanRender,
    handleOptIn,
    handleDisable,
    handleLater,
  } = hooks[type || plug];

  // monitor for invoke request
  useEffect(() => {
    if (invoke) {
      // A suggestion has veen invoked, determine if it can be presented now
      if (canRenderSuggestion(type)) {
        dispatch(uiActions.suggestionPresented(type));
        setOpen(true);
      } else {
        handleClose();
      }
    }
  }, [invoke]);

  // apply general constraints here, rely on hook for specific constraints
  const canRenderSuggestion = () => {
    const typeSpecific = typeSpecificCanRender();
    //console.log(
    //  `SuggestionDialog canRenderSuggestion: auth=${isAuthenticated}, presented=${suggestionControl.presentedTypes.includes(
    //    type
    //  )}, disabled=${suggestionDisableTypes.includes(
    //    type
    //  )}, specific=${typeSpecific}`
    //);
    return (
      isAuthenticated && // only registered users
      !suggestionControl.presentedTypes.includes(type) && //not if already presented this session
      !suggestionDisableTypes.includes(type) && // not if user has disabled this suggestion type
      typeSpecific
    );
  };

  function handleClose(rslt) {
    if (rslt?.action === 'disable' && rslt?.type) {
      dispatch(uiActions.suggestionDisable(type));
    }
    setOpen(false);
    dispatch(uiActions.suggestionClear());
  }

  const renderSuggestion = () => (
    <Suggestion
      config={config}
      onOptIn={handleOptIn}
      onDisable={handleDisable}
      onLater={handleLater}
    />
  );

  return (
    <ExceptionDialog
      exceptionDisable={true}
      open={open}
      onClose={handleClose}
      heading={
        <Typography color="secondary">{`${t('Suggestion')}...`} </Typography>
      }
      body={renderSuggestion()}
      actions={null}
    />
  );
};

export default SuggestionDialog;
