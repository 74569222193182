/**
 * Provide context permitting Recorder to buffer a new message
 * rather than issuing submitMessage.
 *
 * parent provides:
 *  value.recBufferEnable = true
 *  value.bufferMessage = () => saveToBuffer
 *
 * approaches  to consume:
 *  1.  RecordingBufferContet.Consumer
 * 2.  or usContext hook
 * 3. in class component:
 *      static contextType = RecordingBufferContext;
 *      recBufferEnable = this.context.recBufferEnable
 */

import React from 'react';
const RecordingBufferContext = React.createContext({
  recBufferEnable: false,
  recording: null,
  bufferMessage: () => ({ id: null, type: null, title: null }),
});

export default RecordingBufferContext;

export const useRecordingBufferContext = () =>
  React.useContext(RecordingBufferContext);
