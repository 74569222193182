import { createSlice} from '@reduxjs/toolkit'


const initialCounterState = {
    counter: 0, showCounter: true,
}

const counterSlice = createSlice({
    name: 'counter',
    initialState:initialCounterState,
    reducers : {
        // all the reducers this slice needs
        // automatically called with mutable cloned state, action
        increment(state) {state.counter++},
        decrement(state) {state.counter--},
        increase(state, action) {state.counter += action.payload},
        toggleCounter(state) {state.showCounter = !state.showCounter},
    }
})

// action creators are provided by createSlice
// there is no need to define list text action "types"
export const counterActions = counterSlice.actions

export default counterSlice.reducer


