/**
 * webPushApi
 *
 * Utilities for managing server endpoint subscriptions
 *
 */

import store from 'store';
import { uiActions } from 'store/ui-slice';
import { fetcher } from 'lib/utils/fetcher';
import { pwa } from 'lib/classes/PWA';

const deleteNode = (type, id) =>
  fetcher('jsonapi', 'DELETE', `jsonapi/node/${type}/${id}`);

const deleteNodes = (type, idList) =>
  Promise.all(idList.map(id => deleteNode(type, id)));

const getUserSubscriptions = () => {
  const user = store.getState().user;
  const fields =
    'fields[push_subscription]=field_user_id,field_channel,field_descriptor';
  const url = `jsonapi/node/push_subscription?${fields}&filter[field_user_id]=${user.auth.user?.id}`;

  return user.isAuthenticated
    ? fetcher('jsonapi', 'GET', url).then(data =>
        data.data.map(item => ({
          id: item.id,
          userId: item.attributes.field_user_id,
        }))
      )
    : Promise.resolve([]);
};

export const deleteUserAllSubscriptions = () =>
  getUserSubscriptions().then(subscriptions =>
    deleteNodes(
      'push_subscription',
      subscriptions.map(item => item.id)
    )
  );

// determine whether push notification should be shown
// only enable when user session is asleep
export const enablePushNotificationEventFromSW = () => {
  const user = store.getState().user;
  return user.status === 'asleep';
};

// permanently record PWA installation event
export const notePWAInstalled = () => {
  store.dispatch(uiActions.notePWAInstalled(true));
};
