/**
 * Select a Component for demonstration
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ReduxDemo1 from 'experiments/ReduxDemo1/Demo1Functional';
import ReduxDemo2 from 'experiments/ReduxDemo2';
import FirebaseDemo from 'experiments/FirebaseDemo';
import TestOAuth from 'experiments/TestOAuth';
import DrawerDemo from 'experiments/DrawerDemo';
import FullScreenDialogDemo from 'experiments/FullScreenDialogDemo';
import CORStest from 'experiments/CORStest';
import {
  createSocialAuthenticatedAccount,
  patchHvnUser,
} from 'lib/api/userApi';
import localStore from 'lib/utils/localStore';
import AnimationDemo from 'experiments/AnimationDemo';
import WebRTCDemo from 'components/WebRTCDemo';
import RecursiveDialogTest from 'experiments/RecursiveDialogTest';
import VolumeMeterTest from 'experiments/VolumeMeterTest';
import VoiceActivityDetectionTest from 'experiments/VoiceActivityDetectionTest';
import WebVoiceSDKTest from 'experiments/WebVoiceSDKTest';
import FlashBallTest from 'experiments/FlashBallTest';
import GoogleIdentityServicesTest from 'experiments/GoogleIdentityServicesTest';
import StorageTest from 'experiments/StorageTest';

// redux not used here, just temporary for reference
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { uiActions } from 'store/ui-slice';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    background: 'gray',
    width: '200px',
    marginBottom: '20px',
  },
  formControl: {
    minWidth: 120,
  },
  label: {
    color: 'white',
  },
});

class SimpleSelect extends React.Component {
  state = {
    type: '',
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    localStore().setObj('demo', event.target.value);
  };

  componentDidMount = () => {
    const item = localStore().getObj('demo');
    this.setState({ type: item });
  };

  demoUser = {
    real_name: 'Joe Test5',
  };

  demoSocialUser = name => ({
    socId: name,
    email: name + '@demo.com',
    provider: 'test',
    birthDate: '1952-03-14',
    gender: 'M',
    realName: 'joe sulmar',
    screenName: name,
    image: 'http://demo.com',
  });

  createHvnUser = name => {
    createSocialAuthenticatedAccount(this.demoSocialUser(name));
  };

  render() {
    const { classes } = this.props;
    let selectedType = <div />;

    switch (this.state.type) {
      case 'StorageTest':
        selectedType = <StorageTest />;
        break;
      case 'GoogleIdentityServicesTest':
        selectedType = <GoogleIdentityServicesTest />;
        break;
      case 'FlashBallTest':
        selectedType = <FlashBallTest />;
        break;
      case 'WebVoiceSDKTest':
        selectedType = <WebVoiceSDKTest />;
        break;
      case 'VoiceActivityDetectionTest':
        selectedType = <VoiceActivityDetectionTest />;
        break;
      case 'VolumeMeterTest':
        selectedType = <VolumeMeterTest />;
        break;
      case 'RecursiveDialogTest':
        selectedType = <RecursiveDialogTest />;
        break;
      case 'WebRTCDemo':
        selectedType = <WebRTCDemo demoUser="callee" />;
        break;
      case 'AnimationDemo':
        selectedType = <AnimationDemo />;
        break;
      case 'ReduxDemo1':
        selectedType = <ReduxDemo1 />;
        break;
      case 'ReduxDemo2':
        selectedType = <ReduxDemo2 />;
        break;
      case 'FirebaseDemo':
        selectedType = <FirebaseDemo />;
        break;
      case 'TestOAuth':
        selectedType = <TestOAuth />;
        break;
      case 'DrawerDemo':
        selectedType = <DrawerDemo />;
        break;
      case 'DialogDemo':
        selectedType = (
          <div>
            <h1>DialogDemo</h1>
            <button
              onClick={() =>
                this.props.actions.ui.dialogSet('This is DialogDemo')
              }
            >
              open
            </button>
          </div>
        );
        break;
      case 'FullScreenDialogDemo':
        selectedType = <FullScreenDialogDemo />;
        break;
      case 'AlertDemo':
        selectedType = (
          <div>
            <button
              onClick={() =>
                this.props.actions.ui.alertSet({
                  message: 'This is an alert',
                  severity: 'error',
                })
              }
            >
              Open
            </button>{' '}
          </div>
        );
        break;
      case 'patchHvnUser test':
        selectedType = (
          <div>
            <button
              onClick={() => {
                console.log('patchHvnUser test: ', this.demoUser);
                patchHvnUser(this.demoUser).catch(err =>
                  console.log('patchHvnUser  error: ', err)
                );
              }}
            >
              patchHvnUser test
            </button>{' '}
          </div>
        );
        break;
      case 'createUser':
        selectedType = (
          <div>
            <button onClick={() => this.createHvnUser(7000)}>createUser</button>{' '}
          </div>
        );
        break;
      case 'CORStest':
        selectedType = <CORStest />;
        break;
      default:
        selectedType = (
          <div>
            <h3>Unrecognized type!</h3>
          </div>
        );
    }
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.label} htmlFor="type-simple">
              Demo Select
            </InputLabel>
            <Select
              value={this.state.type}
              onChange={this.handleChange}
              inputProps={{
                name: 'type',
                id: 'type-simple',
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="StorageTest">StorageTest</MenuItem>
              <MenuItem value="GoogleIdentityServicesTest">
                GoogleIdentityServicesTest
              </MenuItem>
              <MenuItem value="FlashBallTest">FlashBallTest</MenuItem>
              <MenuItem value="WebVoiceSDKTest">WebVoiceSDKTest</MenuItem>
              <MenuItem value="VoiceActivityDetectionTest">
                VoiceActivityDetectionTest
              </MenuItem>
              <MenuItem value="VolumeMeterTest">VolumeMeterTest</MenuItem>
              <MenuItem value="RecursiveDialogTest">
                RecursiveDialogTest
              </MenuItem>
              <MenuItem value="WebRTCDemo">WebRTCDemo</MenuItem>
              <MenuItem value="AnimationDemo">AnimationDemo</MenuItem>
              <MenuItem value="ReduxDemo1">ReduxDemo1</MenuItem>
              <MenuItem value="ReduxDemo2">ReduxDemo2</MenuItem>
              <MenuItem value="FirebaseDemo">FirebaseDemo</MenuItem>
              <MenuItem value="TestOAuth">TestOAuth</MenuItem>
              <MenuItem value="DrawerDemo">DrawerDemo</MenuItem>
              <MenuItem value="DialogDemo">DialogDemo</MenuItem>
              <MenuItem value="FullScreenDialogDemo">
                FullScreenDialogDemo
              </MenuItem>
              <MenuItem value="AlertDemo">AlertDemo</MenuItem>
              <MenuItem value="patchHvnUser test">patchHvnUser test</MenuItem>
              <MenuItem value="createUser">createUser</MenuItem>
              <MenuItem value="CORStest">CORS test</MenuItem>
            </Select>
          </FormControl>
        </form>
        {selectedType}
      </div>
    );
  }
}

// redux not used here, just temporary for reference
const mapStateToProps = state => {
  return {
    ui: state.ui,
    user: state.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      ui: bindActionCreators(uiActions, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SimpleSelect));
