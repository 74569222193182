/**
 * Authenticate using Google Identity Services
 *
 * 1. Dynamically load and initialize the API
 * 2. Compatible with proprietery "login with google" button
 * 3. Initiate Login
 * 4. Report results to parent component.

 * uses @react-oauth/google
 * (previously employed Google Sign-in  https://developers.google.com/identity/sign-in/web/sign-in)
 *
 * Refactoring considerations:
 * - redirect vs. popup mode
 * - retry upon popup failure
 * - move Google credentials to safer place
 * - consider developing a universal authenticator
 *      e.g. using Supabase https://www.youtube.com/watch?v=H1V716XPUEs
 *      e.g. using Passport.js https://www.youtube.com/watch?v=7K9kDrtc4S8
 *
 * References
 *  - Google admin console
 *      https://console.cloud.google.com/apis/credentials?project=hotlynx-development
 *  - js API
 *      https://developers.google.com/identity/gsi/web/reference/js-reference
 *  - Google Identity Services reference
 *      https://developers.google.com/identity/gsi/web/guides/overview
 *  - @react-oauth/google
 *      https://www.npmjs.com/package/@react-oauth/google
 *      https://blog.logrocket.com/guide-adding-google-login-react-app/
 *      https://www.youtube.com/watch?v=roxC8SMs7HU
 *  - migration guide
 *      https://developers.google.com/identity/oauth2/web/guides/migration-to-gis
 *  - example code
 *      https://reactjsexample.com/google-oauth2-using-google-identity-services-for-react/
 *  - example code
 *      https://stackoverflow.com/questions/72209749/react-google-identity-services
 *  - video example
 *  - obsolete Google Sign-In
 *      https://developers.google.com/identity/sign-in/web/sign-in
 *  - detect if browser is blocking popups
 *      https://stackoverflow.com/questions/2914/how-can-i-detect-if-a-browser-is-blocking-a-popup
 *
 *  - https://github.com/MomenSherif/react-oauth
 *  - https://reactjsexample.com/google-oauth2-using-google-identity-services-for-react/
 *  - https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#gis-redirect-ux
 *
 *
 * Available Modules
 *  - react-oauth
 *  - jwt-decode (decode tokens)
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { t } from 'lib/translation/trans';

const client_id =
  '687095247130-idlvvp10uaqjm3dfjq035mp4tpn0nijk.apps.googleusercontent.com'; //name= gglSandbox

//https://developers.google.com/identity/protocols/oauth2/web-server#uri-validation
const redirect_uri = 'http://localhost:3000/google_redirect/';

// future use
const redirect = false;
const signInOptions = {
  ux_mode: redirect ? 'redirect' : 'popup',
  redirect_uri,
};

// wrap component with the provider
function GoogleAuthenticator(props) {
  return (
    <GoogleOAuthProvider clientId={client_id}>
      <Authenticator {...props} />
    </GoogleOAuthProvider>
  );
}

// must be wrapped in GoogleOAuthProvider
function Authenticator({ setHeading, onSuccess, onFail, zen }) {
  const [token, setToken] = useState(null);
  const [profile, setProfile] = useState(null);

  // automatically initiate login sequence
  useEffect(() => {
    setHeading && setHeading(t('Logging_in_with_Google'));
    setTimeout(() => {
      login();
    }, 1000);
  }, []);

  useEffect(() => {
    token && fetchProfile(token);
  }, [token]);

  useEffect(() => {
    if (profile) {
      if (profile?.id) {
        handleSuccess(profile);
      } else if (profile?.error) {
        // error: {code, message, status }
        handleError(profile.error);
      } else {
        handleError({ message: t('Profile_not_available') });
      }
    }
  }, [profile]);

  // obtain access tokan
  const login = useGoogleLogin({
    flow: 'implicit',

    // for redirect:
    //flow: "auth-code",  //auth-code, implicit
    //ux_mode: 'redirect',
    //redirect_uri,

    onSuccess: token => handleToken(token),
    onError: error => handleError(error),
    onNonOAuthError: error2 => handleNonOAuthError(error2),
  });

  function handleToken(token) {
    setToken(token);
  }

  const fetchProfile = () => {
    const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token.access_token}`;
    const headers = new Headers({
      Authorization: `Bearer ${token.access_token}`,
      Accept: 'application/json',
    });
    const request = new Request(url, { method: 'GET', headers: headers });
    return fetch(request)
      .then(response => response.json())
      .then(data => setProfile(data))
      .catch(err => handleError(err));
  };

  function handleSuccess() {
    if (!profile) {
      handleError(t('No_profile'));
      return;
    }

    // normalize response
    onSuccess &&
      onSuccess({
        socId: profile.id,
        email: profile.email,
        realName: profile.name,
        image: profile.picture,
        Authenticator: 'Google',
      });
  }

  const handleError = response => {
    onFail && onFail(`Google --${JSON.stringify(response)}`);
  };

  const handleNonOAuthError = error2 => {
    switch (error2?.type) {
      case 'popup_closed':
        onFail && onFail(t('Google_dialog_closed_by_user'));
        return;
      case 'popup_failed_to_open':
        // needs retry
        console.log(t('Google_popup_blocked'));
        return;
      default:
        break;
    }
    handleError('nonOAuthError');
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {zen || (
        <>
          <div style={{ marginBottom: '.5rem' }}>{t('Awaiting_Google')}</div>
          <CircularProgress color="inherit" />
        </>
      )}
    </div>
  );
}

GoogleAuthenticator.propTypes = {
  setHeading: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  zen: PropTypes.bool, //simplify rendering
};

export default GoogleAuthenticator;
