/**
 * Render a swip-able carousel with optional indexing buttons
 * Props include an array of presentation elements (messages)
 * with an ix of the currently selected message.
 */

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
//TODO2: mitigate 'react-swipeable-views' warning
import SwipeableViews from 'react-swipeable-views'; //triggers warning: "index.js:1 Warning: Legacy context API has been detected"
//TODO2: consider passing MessageTopic to Carousel as a prop
import MessageTopic from 'containers/MessagePlayer/components/MessageTopic';

const useStyles = makeStyles(theme => ({
  root: {
    //maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
}));

function Carousel({ ix, onNext, stepper, onPrevious, messages }) {
  const classes = useStyles();
  const theme = useTheme();
  const activeStep = ix;

  const handleNext = () => {
    onNext && onNext();
  };

  const handleBack = () => {
    onPrevious && onPrevious();
  };

  const handleStepChange = step => {
    step > activeStep ? handleNext() : handleBack();
  };

  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x' : 'x-reverse'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {/* provide active item and neighbors */}
        {messages.map((step, index) => (
          <div key={step.id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <MessageTopic message={messages[activeStep]} />
            ) : null}
          </div>
        ))}
      </SwipeableViews>

      {stepper && (
        <MobileStepper
          steps={messages.length}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === messages.length - 1}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      )}
    </div>
  );
}

Carousel.propTypes = {
  ix: PropTypes.number.isRequired,
  messages: PropTypes.arrayOf(PropTypes.node).isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  stepper: PropTypes.bool,
};

Carousel.defaultProps = {
  stepper: false,
};

export default Carousel;
