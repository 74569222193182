/**
 * useSMSNotificationSuggestion custom hook
 *
 * Support rendering of a suggestion, encourage opt-in for SMS Notifications
 * //@@ must refactor components/Settings/components/NotificationsSettings/components/NotificationsSuggestion.js
 *
 */

import React from 'react';
import { t } from 'lib/translation/trans';
import { useSelector } from 'react-redux';

const useSMSNotificationSuggestion = onCompleted => {
  const config = {
    type: 'sms_notifications',
    offer: t('Receive_Notifications_When_Messages_Arrive_For_You'), //required, provides offer text
    approveBtnText: null, // optional, overrides default
    info: 'SMS notifications.', //optional, rendered upon Info button
    instruct: null, //optional, opt-in instructions
  };

  const { isAuthenticated } = useSelector(state => state.user);
  const { pSession, suggestionControl } = useSelector(state => state.ui);
  const { suggestionDisableTypes } = pSession;

  // A suggestion has veen invoked, determine if it can be presented now
  const canRenderSuggestion = () => false;
  //  - only if notifications are available to this user
  //  - not if user has already subscribed

  // user wishes to move forward with subscription
  const handleOptIn = () => {
    onCompleted();
  };
  // discontinue future rendering of this alert
  const handleDisable = rslt => {
    onCompleted(rslt);
  };

  const handleLater = () => {
    onCompleted();
  };

  return {
    config,
    canRenderSuggestion,
    handleOptIn,
    handleDisable,
    handleLater,
  };
};

export default useSMSNotificationSuggestion;
