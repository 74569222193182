

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import FlashBall from 'components/utils/FlashBall'
import {VolumeMute} from 'mdi-material-ui'


const useStyles = makeStyles((theme) => ({


  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}))

export default function FlashBallTest() {
  const [status, setStatus ] = useState('hidden');
  const classes = useStyles();

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return <Grid container justify="flex-start" alignItems="center" spacing= {2}>
    <Grid item>
      <FlashBall status={status} disIcon= {<VolumeMute />} />
    </Grid>

    <Grid item >
      <FormControl className={classes.formControl}>
        <InputLabel id="select-status">Status</InputLabel>
        <Select
          labelId="select-status"
          id="select-status"
          value={status}
          onChange={handleChange}
        >
          <MenuItem value={'hidden'}>Hidden</MenuItem>
          <MenuItem value={'normal'}>Normal</MenuItem>
          <MenuItem value={"warning"}>Warning</MenuItem>
          <MenuItem value={"disabled"}>Disabled</MenuItem>
        </Select>
      </FormControl>
    </Grid>

  </Grid>
}
