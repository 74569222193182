/**
 * PaypalSuccess
 * Redirect target to capture successful transaction data
 *
 */

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaypalSuccess = () => {
  const query = useQuery();

  const referrer = window.document.referrer;
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  const params = Array.from(searchParams.entries()).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: v }),
    {}
  );
  return (
    <>
      <h1>PaypalSuccess</h1>
      <h2>Referrer= {referrer}</h2>
      <div>paramsString= {paramsString}</div>
    </>
  );
};

export default PaypalSuccess;
