/**
 *
 * Display a table
 * Provide a callabck to parent to handle updating of addressable table cells.
 *
 */

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

const TableStd = forwardRef(({ config }, ref) => {
  const [columns, setColumns] = useState([]); //column labels
  const [rows, setRows] = useState([]); // row labels
  const [data, setData] = useState({}); // a flat object (no nested keys) containing table data

  // initialize table labels
  useEffect(() => {
    const { columns, rows } = config;
    if (Array.isArray(columns) && Array.isArray(rows)) {
      setColumns(columns);
      setRows(rows);
      // initialize data object
      const data = {};
      columns.forEach((x, ixx) => {
        rows.forEach((y, ixy) => {
          data[`${ixx}${ixy}`] = null;
        });
      });
      setData(data);
    }
  }, []);

  // set cell content
  // gridKey 2-character string, x-coordinate concat with y-coordinate
  const setCellContent = (gridKey, content) => {
    if (typeof gridKey === 'string' && gridKey.length === 2) {
      // update  the specified cell
      setData(d => ({ ...d, [gridKey]: content }));
    }
  };

  // Lift up method to parent
  useImperativeHandle(ref, () => ({
    setCellContent,
  }));

  return (
    <>
      <table>
        <tr>
          <th></th>
          {columns.map(xLabel => (
            <th key={xLabel} style={{ minWidth: '50px' }}>
              {xLabel}
            </th>
          ))}
        </tr>
        {rows.map((yLabel, ixy) => (
          <tr key={yLabel}>
            <th>{yLabel}</th>
            {columns.map((xLabel, ixx) => (
              <td key={xLabel + '1'} style={{ border: '1px solid grey' }}>
                {data[`${ixx}${ixy}`]}
              </td>
            ))}
          </tr>
        ))}
      </table>
    </>
  );
});

TableStd.propTypes = {
  config: PropTypes.object,
};
TableStd.defaultProps = {
  config: {
    columns: ['Column-0', 'Column-1'], // column labels
    rows: ['Row-0', 'Row-1'], // row labels
  },
};

export default TableStd;
