/**
 * EntityFilter.js
 *
 * 1. Manage criteria contained in the this.params object.
 * 2. Evaluate entities according to the criteria contained in this.params.
 * 3. Each criterion belongs to a specific context and (optionally) to a specific partition
 *      context:    situation in which the criterion is employed, e.g. viewing members, or playlist messages
 *      partition:  community in which the criterion is employed
 * 4. A submitted entity is tested against all relevant criteria to determine its status.
 * 5. Each criterion specifies a key/value that is compared to the corresponding entity key/value
 * 6. Each instance handles criteria for a specific context and partition
 *
 *  - this.params[filterContext][this.partition] contains an array of criteria
 *  - each criterion is an object {key, value, requirement}
 *  - requirement is one of 'include', 'exclude'
 *
 *  example
 *  this.params = {
 *      user: {
 *          partition1: criterionArray1,
 *          partition2: [{key: key1, val: val1, requirement: 'include'}],
 *      },
 *      message: {
 *          $global: criterionArray3,
 *      }
 *  }
 */

import { makeArray } from 'lib/utils/utils';
const globalPartition = '$global';

class EntityFilter {
  params = {}; // holds criteria for all contexts and pertitions
  partition = globalPartition; // selects the partition to be managed by this instance
  context = null; // selects the context that applies to this instance

  constructor(initialFilterParams, partition, context) {
    // make local copy of params
    typeof initialFilterParams === 'object' &&
      (this.params = JSON.parse(JSON.stringify(initialFilterParams)));

    typeof partition === 'string' && (this.partition = partition);
    typeof context === 'string' && (this.context = context);
  }

  criteria = () =>
    this.params &&
    this.params[this.context] &&
    this.params[this.context][this.partition];

  // remove a single criterion (or all criteria ) from the specified criteria array
  removeCriteria = (key = undefined) => {
    const canRemove =
      typeof this.params[this.context] === 'object' &&
      Array.isArray(this.criteria());
    if (canRemove) {
      // if key is provided, remove it.  Otherwise remove all keys for the entity and partition
      if (typeof key === 'string') {
        this.params = {
          ...this.params,
          [this.context]: {
            ...this.params[this.context],
            [this.partition]: this.criteria().filter(c => c.key !== key),
          },
        };
      } else {
        this.params = {
          ...this.params,
          [this.context]: {
            ...this.params[this.context],
            [this.partition]: [],
          },
        };
      }
    }
    return this.params;
  };

  // add a single criterion to the specified array
  // or remove criterion if the provided value is undefined
  addCriterion = (criterion = null) => {
    const canAdd =
      typeof criterion === 'object' &&
      typeof criterion?.key === 'string' &&
      ['include', 'exclude'].includes(criterion?.requirement);
    if (canAdd) {
      // expunge existing criterion if it previously exists
      const expunged = { ...this.removeCriteria(criterion.key) };

      // add empty branches if they don't exist
      if (!expunged[this.context]) {
        expunged[this.context] = {};
      }
      if (expunged[this.context][this.partition] === undefined) {
        expunged[this.context][this.partition] = [];
      }

      // apply new criterion
      if (criterion?.value !== undefined) {
        this.params = {
          ...expunged,
          [this.context]: {
            ...expunged[this.context],
            [this.partition]: [
              ...expunged[this.context][this.partition],
              criterion,
            ],
          },
        };
      }
    }

    return this.params;
  };

  // return the required value (if it exists) for the specified key
  getRequiredValue = (key = null) => {
    const canGetValue =
      typeof key === 'string' &&
      typeof this.params[this.context] === 'object' &&
      Array.isArray(this.criteria());
    return canGetValue
      ? this.criteria().find(
          c => c?.key === key && c?.requirement === 'include'
        )?.value
      : undefined;
  };

  criteriaToString = () => {
    const criteria = this.params[this.context][this.partition];
    const getString = val => {
      return makeArray(val).reduce((acc, item) => `${acc} ${item}`, '');
    };
    return makeArray(criteria).reduce(
      (acc, item) => `${acc} ${item.key}(${getString(item.value)})`,
      ''
    );
  };

  // evaluate the specified entity against all pertinent criteria
  isEntityExcluded = (entity = {}) => {
    const criteria = this.criteria() || {};

    const canExclude =
      typeof entity === 'object' &&
      typeof this.params[this.context] === 'object';
    // exclude if any entity value fails to meet its 'include' or 'exclude' reequirement
    const isEntityExcludedInPartition = p => {
      return makeArray(criteria).reduce((acc, criterion) => {
        const criterionValues = makeArray(criterion?.value);
        //console.log(`isEntityExcludedInPartition: len=${criterionValues.length}, key=${criterion.key}, reject=${!criterionValues.includes(entity[criterion.key])}`);
        return (
          criterionValues.length &&
          (acc ||
            (criterion?.requirement === 'include' &&
              !criterionValues.includes(entity[criterion.key])) ||
            (criterion?.requirement === 'exclude' &&
              criterionValues.includes(entity[criterion.key])))
        );
      }, false);
    };

    // it is possible that an entity has not asserted any attributes, and it's params might be null
    // in that case, do not exclude when no criteria have been established.
    if (!canExclude) {
      return false;
    } else if (Object.keys(entity).length === 0) {
      // entity is empty, exclude it iff any criteria are specified
      return Boolean(
        makeArray(this.params[this.context][this.partition]).length
      );
    } else {
      // evaluate partition and global criteria
      return (
        isEntityExcludedInPartition(this.partition) ||
        isEntityExcludedInPartition(globalPartition)
      );
    }
  };
}

export default EntityFilter;
