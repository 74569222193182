/**
 * Display Terms and Conditions and require Agreement
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Paper, FormControlLabel, Checkbox } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import Page from 'components/Page';
import { timeout } from 'lib/utils/utils';
import { t } from 'lib/translation/trans';

const ConsentForm = ({ setHeading, onSubmit, delay }) => {
  const dispatch = useDispatch();

  // set the heading of parent dialog
  useEffect(() => {
    setHeading && setHeading('Consent to Terms:');
  }, []);

  const handleConsentChange = e =>
    timeout(delay).then(() => {
      if (e.target.checked) {
        dispatch(uiActions.noteTermsAgreed());
        onSubmit && onSubmit(e.target.checked);
      }
    });

  // Display Terms and Conditions, and an "acceptance" checkbox
  return (
    <div>
      <div
        style={{
          color: 'rgba(255, 255, 255, 0.7)',
          fontSize: '.8em',
          height: 300,
          overflow: 'auto',
        }}
      >
        <Page title={'Terms and Conditions'} />
      </div>
      <hr />
      <Paper
        style={{
          marginTop: '10px',
          paddingLeft: '10px',
          backgroundColor: 'green',
        }}
      >
        <FormControlLabel
          control={<Checkbox onChange={handleConsentChange} name="approval" />}
          label={t('I_agree_to_the_terms_and_conditions')}
        />
      </Paper>
    </div>
  );
};

ConsentForm.propTypes = {
  setHeading: PropTypes.func,
  onSubmit: PropTypes.func,
  delay: PropTypes.number, // delay after box is checked before proceeding
};

ConsentForm.defaultProps = {
  delay: 2000,
};

export default ConsentForm;
