/**
 * DialogCascade
 * To test behavior of cascaded dialogs
 */


import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import {
    Button,
} from '@material-ui/core';
import FullScreenDialog from 'components/Modals/FullScreenDialog'
import ExceptionDialog from 'components/Modals/ExceptionDialog'


const DialogCascade = ({ depth }) => {

    const [openFull, setOpenFull] = useState(false)
    const [openStd, setOpenStd] = useState(false)



    const handleFullScreenClose = () => setOpenFull(false)
    const handleFullScreenOpen = () => setOpenFull(true)

    const handleDialogStdClose = () => setOpenStd(false)
    const handleDialogStdOpen = () => setOpenStd(true)


    const buttons = () => <>
                <Button variant="contained"  onClick={handleFullScreenOpen}>Open Full Screen</Button>
                <Button variant="contained"  onClick={handleDialogStdOpen}>Open DialogStd</Button>
    </>

    return <>

        <h1>DialogCascade</h1>
        <h3>Depth = {depth} </h3>
        {buttons() }
        
        <FullScreenDialog
            open={openFull}
            title={"Full Screen"}
            onClose={handleFullScreenClose}
            content= {<DialogCascade depth={depth +1} />}
        />
        <ExceptionDialog
            exceptionDisable={true}
            open= {openStd}
            onClose={handleDialogStdClose}
            heading= "Cascade Dialog"
            body={<DialogCascade depth={depth +1} />}
        />

    </>

}

DialogCascade.propTypes = {
    depth: PropTypes.number,
}
DialogCascade.defaultProps = {
    depth: 0
}
export default DialogCascade
