/**
 * Render a form to manage Notifications Settings
 *
 * displayAs prop fine tunes the presentation for invocation from
 * Settings page or NotificationsSuggestion alert
 *
 * * Outstanding:
 * - standardize common functionality with NotificationsSettingsPush

 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Chip,
  Typography,
  IconButton,
  FormControl,
  FormLabel,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import RadioStd from 'components/utils/RadioStd';
import SelectorStd from 'components/utils/SelectorStd';
import { patchHvnUser } from 'lib/api/userApi';
import SMSAuthenticator from 'components/AccountButton/components/LoginView/components/Authenticator/components/SMSAuthenticator';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import { t } from 'lib/translation/trans';
import CtiaNotice from './CtiaNotice';
import InfoIcon from '@material-ui/icons/Info';
import { twilioFromFriendly } from 'configs/config-hvn';

const useStyles = makeStyles(theme => ({
  table: {},
  controls: {
    width: '25%',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'block',
    },
  },
  //    description: {
  //      display: "inline",
  //      fontSize: 'small',
  //      [theme.breakpoints.down("xs")]: {
  //        display: "block",
  //      },
  //    },
  infoButton: {
    position: 'absolute',
    bottom: '-11px',
    [theme.breakpoints.up('sm')]: {
      right: '-30px',
    },
    [theme.breakpoints.down('xs')]: {
      left: '130px',
    },
  },
}));

const useSelectorStyles = makeStyles(theme => ({
  formControl: {
    margin: 0,
    minWidth: 0,
    width: 120,
    //height: 36
  },
}));

const NotificationsSettings = ({ displayAs, onSubmit, onLater }) => {
  /* state */
  const [originalSettings, setOriginalSettings] = useState({});
  const [editedSettings, setEditedSettings] = useState({});
  const [phoneDialog, setPhoneDialog] = useState(false);
  const [showForm, setShowForm] = useState(true); // render form initially
  const [infoOpen, setInfoOpen] = useState(false);
  const user = useSelector(state => state.user.auth.user);
  const { userIpAddress } = useSelector(state => state.ui);
  /* end state */

  const classes = useStyles();
  const selectorClasses = useSelectorStyles();
  const dispatch = useDispatch();
  const submitDisabled = () =>
    editedSettings?.consent === '+' && !editedSettings?.address;
  const showFullForm = Boolean(editedSettings.consent === '+');
  const valuesChanged =
    JSON.stringify(originalSettings) !== JSON.stringify(editedSettings);
  const showButtons = valuesChanged || displayAs === 'advice';

  const defaults = {
    channel: 'sms',
    medium: 'web',
    event: 'button',
    campaign: 'hvn-notify',
    limit: 1,
    lang: 'EN',
  };

  // user texted "STOP" to opt-out, must text "START" to resume
  const approvalWithdrawnByText = user?.notifications?.consent === 'x';

  // take snapshot of original settings, and create an editable copy
  useEffect(() => {
    if (user?.notifications) {
      let userNotifications = JSON.parse(JSON.stringify(user.notifications));
      userNotifications.ip = userIpAddress;

      // interpret consent code
      userNotifications.consent = userNotifications.consent === '+' ? '+' : '-'; //interpret anything other than '+' as '-'

      // apply defaults to unspecified keys
      Object.entries(defaults).forEach(([key, val]) => {
        if (userNotifications[key] === undefined) {
          userNotifications[key] = val;
        }
      });

      setOriginalSettings(userNotifications);

      // for 'advice', pre-select with consent enabled
      if (displayAs === 'advice') {
        userNotifications = JSON.parse(JSON.stringify(user.notifications)); //make another copy
        userNotifications.consent = '+'; // preselect value
      }
      setEditedSettings(userNotifications);
    }
  }, [user?.notifications, displayAs]);

  // Handlers -----------------------------------------------------------------------------------

  // update the settings only if they have changed since the originalSettings
  const handleSubmit = () => {
    // helper function
    const exit = () => {
      dispatch(uiActions.backdropClr());
      setShowForm(false);
      onSubmit && onSubmit();
      return true;
    };

    // only patch the user if something has changed
    if (JSON.stringify(originalSettings) !== JSON.stringify(editedSettings)) {
      dispatch(uiActions.backdropSet(t('Please_Wait')));
      const { consent, ...params } = editedSettings;
      patchHvnUser(user, {
        field_notif_consent: consent,
        field_notif_params: JSON.stringify(params),
      })
        .then(() => exit())
        .catch(err => (console.log('patch error:', err), exit()));
    } else {
      exit();
    }
  };

  const handleCancel = () => {
    setShowForm(false);
    setEditedSettings(originalSettings);
    onLater && onLater();
  };

  // render a dialog for collecting phone information
  const handlePhoneFormRequest = () => {
    setPhoneDialog(true);
  };

  const handleScratchpadUpdate = update =>
    setEditedSettings(old => ({ ...old, ...update }));
  const handlePhoneClose = () => {
    setPhoneDialog(false);
  };
  const handlePhoneFail = () => {
    setPhoneDialog(false);
  };

  const handlePhoneSuccess = rslt => {
    setPhoneDialog(false);
    handleScratchpadUpdate({ address: rslt.e164, mode: 'sms' });
  };

  const handleInfoOpen = () => setInfoOpen(true);

  // Form Elements  -----------------------------------------------------------------------------------

  const consentSelector = (
    <FormControl>
      <FormLabel component="legend">
        {t('Receive_Notifications_When_Messages_Arrive_For_You')}{' '}
      </FormLabel>

      <RadioStd
        name="consent"
        classes={classes}
        items={[
          { key: '+', data: t('Enable') },
          { key: '-', data: t('Disable') },
        ]}
        value={editedSettings?.consent}
        handleChange={el =>
          handleScratchpadUpdate({ consent: el.target.value })
        }
      />
    </FormControl>
  );

  const methodSelector = (
    <RadioStd
      name="method"
      classes={classes}
      items={[{ key: 'sms', data: t('Text') }]}
      value="sms"
      handleChange={el => handleScratchpadUpdate({ method: el.target.value })}
    />
  );

  const limitSelector = (
    <SelectorStd
      name="limit"
      classes={selectorClasses}
      items={[
        { key: '1', data: '1' },
        { key: '2', data: '2' },
        { key: '3', data: '3' },
        { key: '4', data: '4' },
        { key: '5', data: '5' },
      ]}
      value={editedSettings?.limit || '1'}
      onChange={val => handleScratchpadUpdate({ limit: val })}
    />
  );

  const languageSelector = (
    <SelectorStd
      name="limit"
      classes={selectorClasses}
      items={[
        { key: 'EN', data: t('English') },
        { key: 'FR', data: t('French') },
        { key: 'SP', data: t('Spanish') },
        { key: 'DE', data: t('German') },
        { key: 'LT', data: t('Lithuanian') },
      ]}
      value={editedSettings?.lang || 'EN'}
      onChange={val => handleScratchpadUpdate({ lang: val })}
    />
  );

  const settingsTable = () => (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {getRows().map(row => (
            <TableRow key={row.name}>
              <TableCell
                style={{ width: '25%' }}
                align="left"
                component="th"
                scope="row"
              >
                {row.name}{' '}
              </TableCell>
              <TableCell
                style={{ paddingLeft: 0, position: 'relative' }}
                align="left"
              >
                {' '}
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Helpers  -----------------------------------------------------------------------------------

  // transform raw field contents into display label values
  const label = {
    '0': t('Disabled'),
    '+': t('Enabled'),
    '-': t('Disabled'),
    'x': t('Stopped'),
    'sms': t('Text'),
    'EN': t('English'),
    'FR': t('French'),
    'SP': t('Spanish'),
    'DE': t('German'),
    'LT': t('Lithuanian'),
  };

  const statusIndicator = () => <>{label[editedSettings?.consent]} </>;

  // deliver table cell contents, either for display of settings or for update
  const createData = (name, value, action) => ({ name, value, action });

  // rows can be generated as status or as form for updating
  const rows = {
    settings: [
      createData(t('Status'), statusIndicator()),
      createData(t('Method'), label[editedSettings?.mode]),
      createData(t('Mobile_Phone'), editedSettings?.address),
      createData(t('Daily_Limit'), editedSettings?.limit),
      createData(t('Language'), label[editedSettings?.lang]),
    ],
    form: [
      createData(`${t('Status')} *`, <>{consentSelector}</>),
      createData(t('Method'), methodSelector),
      createData(
        `${t('Mobile_Phone')} *`,
        editedSettings?.address || phoneFormButton()
      ),
      createData(t('Daily_Limit'), limitSelector),
      createData(t('Language'), languageSelector),
    ],
  };

  // render 'Status' row, or all rows
  const getRows = () =>
    rows[showForm ? 'form' : 'settings'].filter(
      (r, ix) => ix === 0 || showFullForm
    );

  const phoneProps = {
    onClose: handlePhoneClose,
    onSuccess: handlePhoneSuccess,
    onFail: handlePhoneFail,
  };

  // button to request rendering of phone dialog
  function phoneFormButton() {
    return (
      <>
        <Chip
          size="small"
          label={t('Set_Up')}
          onClick={handlePhoneFormRequest}
          color="secondary"
        />
        <Typography style={{ display: 'inline', marginLeft: 16, color: 'red' }}>
          {`(${t('Required')})`}{' '}
        </Typography>
      </>
    );
  }

  const headerButtons = () => (
    <div
      style={{
        position: 'relative',
        visibility: showButtons ? 'visible' : 'hidden',
      }}
      className={classes.controls}
    >
      <Chip
        style={{ marginRight: 16 }}
        size="small"
        label={t('Submit')}
        onClick={handleSubmit}
        color="secondary"
        disabled={submitDisabled()}
      />
      <Chip
        size="small"
        label={t('Cancel')}
        onClick={handleCancel}
        color="primary"
      />
      <IconButton
        classes={{ root: classes.infoButton }}
        onClick={handleInfoOpen}
      >
        <InfoIcon />{' '}
      </IconButton>
    </div>
  );

  const resumeInstructions = () => (
    <>
      <div> {`${t('To_enable_Notifications')} ,`}</div>
      <div>{`${t('Text')} "${t('START')}" to ${twilioFromFriendly} `}</div>
      <div> {`${t('from_your_mobile_phone')}.`} </div>
    </>
  );

  return (
    <div style={{ position: 'relative' }}>
      {headerButtons()}
      {approvalWithdrawnByText ? resumeInstructions() : settingsTable()}

      {/* render a dialog to collect and authorize a phone */}
      <ExceptionDialog
        exceptionDisable={true}
        open={phoneDialog}
        onClose={handlePhoneClose}
        heading={
          <Typography color="secondary">
            {t('Authenticating Your Phone')}
          </Typography>
        }
        body={<SMSAuthenticator {...phoneProps} />}
        actions={null}
      />

      {/* render informative dialog regarding Notifications */}
      <ExceptionDialog
        exceptionDisable={true}
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        heading={
          <Typography color="secondary">{`${t('Notifications')} ${t(
            'Information'
          )}`}</Typography>
        }
        body={<CtiaNotice />}
        actions={null}
      />
    </div>
  );
};

NotificationsSettings.propTypes = {
  displayAs: PropTypes.oneOf(['form', 'advice']),
  onSubmit: PropTypes.func,
  onLater: PropTypes.func,
};

NotificationsSettings.defaultProps = {};

export default NotificationsSettings;
