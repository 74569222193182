/**
 * Display a bimodal Login/Logout button.
 * Launches a state-dependent menu to manage
 * login or logout appropriately.
 *
 * For login button: launch a modal with options for login and registration
 * For account button: launch a menu with options including 'logout'
 *
 * Icons:
 * employ https://materialdesignicons.com/,
 * and https://github.com/TeamWertarbyte/mdi-material-ui
 *
 * //TODO2: offer opportunity for user to expunge account
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Menu, MenuItem } from '@material-ui/core';

import { Login, Account } from 'mdi-material-ui';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { timeout } from 'lib/utils/utils';
import { backgroundRefreshDialogs } from 'lib/api/messageApi';
import { logout, isAdministrator, switchToSupportLogin } from 'lib/api/userApi';
import ContextualHelpButton from 'components/utils/ContextualHelpButton';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import MembershipsList from 'components/Settings/components/MembershipsList';

import { t } from 'lib/translation/trans';

const AccountButton = ({ offset }) => {
  const [showOptions, setShowOptions] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(state => state.user);
  const isAuthenticated = user.isAuthenticated;
  const dlogEnabled = useSelector(state => state.ui.dlog.enabled);

  const dispatch = useDispatch();
  const pausePlay = () => dispatch(uiActions.voicePlayerPause());

  const handleCloseMenu = () => {
    setShowOptions(null);
    dispatch(uiActions.setRegDialog({ open: false, mode: null }));
  };

  const handleLogin = () => {
    pausePlay();
    dispatch(uiActions.setRegDialog({ open: true, mode: 'login' })); // opens LogRegDialog
  };

  const handleLogout = () => {
    pausePlay();
    //artificial UX pause before completing logout
    dispatch(uiActions.backdropSet(t('Logging_Out')));
    timeout(2000).then(() => {
      logout();
      dispatch(uiActions.backdropClr());
      //refresh dialogs, since change in login status might affect available set
      backgroundRefreshDialogs();
      handleCloseMenu();
    });
  };

  const handleAboutMe = () => setShowOptions('for_about_me');

  // render a button to open available options
  const btn = (legend, legendColor, icon, onClick, color = 'default') => (
    <>
      <Button
        variant="contained"
        color={color}
        size="small"
        endIcon={icon}
        onClick={onClick}
      >
        <Typography variant="button" style={{ color: legendColor }}>
          {legend}
        </Typography>
      </Button>
    </>
  );

  // presented to anonymous user
  const loginButton = () => {
    // on btn click, present the login options
    return (
      <>
        {isAuthenticated || <ContextualHelpButton context="Registration" />}
        {btn(t('Login'), 'black', <Login />, handleLogin)}
      </>
    );
  };

  // presented to authenticated user
  const accountButton = () => {
    // account, settings, logout
    const screenName =
      user && user.auth && user.auth.user ? user.auth.user.screen_name : '';

    const handleBtnClick = event => {
      if (showOptions === 'for_account') {
        handleCloseMenu();
      } else {
        setAnchorEl(event.currentTarget);
        setShowOptions('for_account');
      }
    };

    // on btn click, present the logout options
    return (
      <>
        <Menu
          style={{ top: offset }}
          anchorEl={anchorEl}
          open={showOptions === 'for_account'}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleAboutMe}>{t('About_Me')}</MenuItem>
          <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
          {dlogEnabled && (
            <MenuItem onClick={() => dispatch(uiActions.dlogOpen(true))}>
              show Dlog
            </MenuItem>
          )}
          {isAdministrator() && (
            <MenuItem onClick={() => switchToSupportLogin()}>
              Switch to Support
            </MenuItem>
          )}
        </Menu>

        {btn(screenName, 'white', <Account />, handleBtnClick, 'secondary')}
      </>
    );
  };

  // render bimodal button
  return (
    <div>
      {isAuthenticated ? accountButton() : loginButton()}
      <FullScreenDialog
        open={false && showOptions === 'for_about_me'}
        title={t('Memberships')}
        onClose={handleCloseMenu}
        content={<MembershipsList />}
      />
      <ExceptionDialog
        exceptionDisable={true}
        open={showOptions === 'for_about_me'}
        onClose={handleCloseMenu}
        heading={
          <Typography color="secondary">{t('Your_Memberships')}</Typography>
        }
        body={<MembershipsList aboutMe={true} />}
        actions={null}
      />
    </div>
  );
};
AccountButton.propTypes = {
  offset: PropTypes.number, // vertical position of Menu
};
AccountButton.defaultProps = {
  offset: 64,
};
export default AccountButton;
