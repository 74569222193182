import classes from './CartButton.module.css';
import { useDispatch, useSelector } from 'react-redux'
import { uiActions} from '../../store/ui-slice'

const CartButton = (props) => {
  const dispatch = useDispatch()
  const handleClick = () => {dispatch(uiActions.toggle() )}

  const items = useSelector(state => state.cart.items)
  
  const total = items.reduce((acc, item) => (acc + item.quantity), 0)


  return (
    <button className={classes.button} onClick={handleClick}>
      <span>My Cart</span>
      <span className={classes.badge}>{total}</span>
    </button>
  );
};

export default CartButton;
