/**
 * AccountZapper
 * Removes the user's account and all associated data and messages.
 * Render a form that requires robust assertion before proceeding.
 *
 * Renders a button that opens a form for implementing account removal.
 * props.autoOpen bypasses the button and renders the form immediately.
 *
 *
 * State transitions:
 *  - upon component mount, if autoOpen render
 */

import React, { useState, useEffect } from 'react';
import ButtonStd from 'components/utils/ButtonStd';
import { Typography, CircularProgress } from '@material-ui/core';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import { t } from 'lib/translation/trans';
import ZapperCheckBoxes from './ZapperCheckBoxes';
import { useHistory } from 'react-router-dom';
import { removeUserAccount } from 'lib/api/userApi';
import useDialogs from 'hooks/useDialogs';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/user-slice';
import { uiActions } from 'store/ui-slice';
import { useSelector } from 'react-redux';

const AccountZapper = ({ autoOpen }) => {
  const [progress, setProgress] = useState('initial'); // 'initial', 'button', 'loggingIn', 'form', 'working', 'confirmed'
  const { isAuthenticated, newAccountCreated } = useSelector(
    state => state.user
  );
  const regDialogOpen = useSelector(state => state.ui.regDialog.open);

  const [boxes, setBoxes] = React.useState({
    check1: false,
    check2: false,
    check3: false,
  });
  const history = useHistory();
  const { dialogsFetchedFlg } = useDialogs();
  const dispatch = useDispatch();

  // initialize
  // render button, form, or login dialog
  useEffect(() => {
    if (progress === 'initial') {
      if (!isAuthenticated) {
        // require login to proceed
        dispatch(uiActions.setRegDialog({ open: true, mode: 'login' }));
        setProgress('loggingIn');
      } else {
        setProgress(autoOpen ? 'form' : 'button');
      }
    }
  }, [progress, autoOpen, isAuthenticated]);

  // handle results of login dialog
  useEffect(() => {
    if (progress === 'loggingIn' && !regDialogOpen) {
      if (isAuthenticated) {
        //login successful
        if (newAccountCreated) {
          // newly created account, don't allow immediate removal
          handleNewlyCreatedAccount();
        } else {
          // logged in with prior account
          setProgress('form');
        }
      } else {
        // not a successful login
        handleFailedLogin();
      }
    }
  }, [progress, regDialogOpen, isAuthenticated]);

  // user has closed the registration dialog
  const handleFailedLogin = () => {
    history.push('/playlist');
  };

  // user created a new account
  const handleNewlyCreatedAccount = () => {
    history.push('/playlist');
  };

  // user has requested that account be removed
  const handleRemoveInitialClick = () => {
    if (dialogsFetchedFlg) {
      setProgress('form');
    } else {
      console.log('AccountZapper error: dialogs not fetched.');
    }
  };

  // user has cancelled or completed the request
  const handleExit = () => {
    if (autoOpen || !isAuthenticated) {
      history.push('/playlist');
    } else {
      // did not remove account
      setProgress('button');
    }
  };

  // user has confirmed intent to remove account
  const handleRemoveConfirm = () => {
    setProgress('working');
    removeUserAccount()
      .then(rslt => {
        setProgress('confirmed');
      })
      .catch(e => console.log('removeUserAccount error:', e));
  };

  // user has acknowledged confirmation of account removal
  const handleConfirmedAck = () => {
    dispatch(uiActions.updatePauth({ authObject: {}, user: {} }));
    dispatch(userActions.logout());
    // redirect
    history.push('/playlist');
  };

  const contents = {
    initial: { body: null, actions: null },
    loggingIn: { body: null, actions: null },
    button: { body: null, actions: null },
    form: {
      body: (
        <>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              Your Account, All Your Data And Messages Will Be Permanantly
              Removed
            </Typography>
            <hr />
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              This cannot be undone!
            </Typography>
          </div>
          <hr style={{ marginBottom: 20 }} />
          <ZapperCheckBoxes boxes={boxes} setBoxes={setBoxes} />
        </>
      ),
      actions: [
        <ButtonStd onClick={handleExit} label={t('Cancel')} />,
        <ButtonStd
          disabled={!(boxes.check1 && boxes.check2 && boxes.check3)}
          onClick={handleRemoveConfirm}
          label={t('REMOVE')}
          color="secondary"
        />,
      ],
    },
    working: { body: <CircularProgress />, actions: null },
    confirmed: {
      body: (
        <Typography variant="h6" style={{ marginBottom: 10 }}>
          Your Account and Data Have Been Removed.{' '}
        </Typography>
      ),
      actions: (
        <ButtonStd
          onClick={handleConfirmedAck}
          label={t('OK')}
          color="secondary"
        />
      ),
    },
  };

  // hide button when props.autoOpen is asserted
  return (
    <>
      {progress === 'button' && (
        <ButtonStd
          onClick={handleRemoveInitialClick}
          label="Remove"
          color="primary"
          size="small"
        />
      )}
      {/* ExceptionDialog is used to emphasize the severity of this operation */}
      <ExceptionDialog
        exceptionDisable={false}
        open={['form', 'working', 'confirmed'].includes(progress)}
        onClose={handleExit}
        heading="Remove My Account"
        body={contents[progress].body}
        actions={contents[progress].actions}
      />
    </>
  );
};
AccountZapper.defaultProps = {
  autoOpen: false, // launch with the remove dialog already open
};
export default AccountZapper;
