/**
 * Header
 * Render header with site title, login control, and optional content
 *
 */

import React, { useState, useRef, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Hidden,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import AccountButton from 'components/AccountButton';
import { useSelector } from 'react-redux';
import BreakpointIndicator from 'components/utils/BreakpointIndicator';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  appBar: {
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 1200, // do not occlude FullScreenDialog at 1300
  },
  toolbar: {
    minHeight: 64,
    [theme.breakpoints.down('xs')]: {
      minHeight: 40,
    },
  },
}));

const Header = forwardRef(
  (
    { title, heading, showDrawerMenuButton, drawerOpen, drawerOpenRequest },
    ref
  ) => {
    const [twoRowRendering, setTwoRowRendering] = useState(false);
    const classes = useStyles();

    const { layout, showBreakpointIndicator } = useSelector(state => state.ui);
    const hideLogin = layout.componentHide.login;

    const titleRef = useRef();
    const heading1Ref = useRef();
    const acctRef = useRef();

    // render the Toolbar contents in either one or two rows
    // depending on how the contents fit into the viewport width
    return (
      <>
        <AppBar ref={ref} classes={{ root: classes.appBar }}>
          <Toolbar classes={{ root: classes.toolbar }}>
            <Grid
              style={{ paddingTop: 5, paddingBottom: 5 }}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid ref={titleRef} item>
                <Typography variant="h5" color="inherit">
                  {/* button is shown only for xs, unless forced */}
                  <Hidden {...{ smUp: !showDrawerMenuButton }}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={drawerOpenRequest}
                      edge="start"
                      disabled={drawerOpen}
                      size="small"
                      style={{ marginRight: 10 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>

                  {title}
                  {showBreakpointIndicator ? <BreakpointIndicator /> : null}
                </Typography>
              </Grid>

              {/* hide for xs  */}
              <Hidden xsDown>
                <Grid
                  style={{ display: twoRowRendering ? 'none' : 'block' }}
                  ref={heading1Ref}
                  item
                >
                  {heading}
                </Grid>
              </Hidden>

              <Grid ref={acctRef} item>
                {hideLogin ? null : (
                  <AccountButton offset={ref.current?.offsetHeight} />
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </>
    );
  }
);

Header.propTypes = {
  classesHeader: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  heading: PropTypes.node,
  showDrawerMenuButton: PropTypes.bool,
  drawerOpen: PropTypes.bool,
  drawerOpenRequest: PropTypes.func,
};
Header.defaultProps = {
  heading: null,
  showDrawerMenuButton: false,
  drawerOpen: false,
};

export default Header;
