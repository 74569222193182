/**
 * InviteSelector
 *
 * Select from a list of multiple concurrent invites.
 */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Divider,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  List,
  ListItem,
} from '@material-ui/core';
import { t } from 'lib/translation/trans';
import { makeStyles } from '@material-ui/core/styles';
import { makeArray } from 'lib/utils/utils';
import { webRTCActions } from 'store/webRTC-slice';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

// render the content for callee's alert
export default function InviteSelector() {
  const classes = useStyles();
  const { invites, selectedInviteId } = useSelector(state => state.webRTC);
  const dispatch = useDispatch();

  const handleChange = userId => {
    dispatch(webRTCActions.selectInvite(userId));
  };

  const renderRadioGroup = () => (
    <FormControl style={{ marginTop: 20, width: 315 }} component="fieldset">
      <FormLabel component="legend">Select One</FormLabel>
      <RadioGroup
        aria-label="invites"
        name="invites"
        value={selectedInviteId}
        onChange={e => handleChange(e.target.value)}
      >
        {invites.map(i => (
          <Grid item>
            <FormControlLabel
              value={i.userId}
              control={<Radio />}
              label={i.screenName}
            />
          </Grid>
        ))}
      </RadioGroup>
    </FormControl>
  );

  const renderList = () => (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        {makeArray(invites).map(i => (
          <ListItem
            button
            onClick={() => handleChange(i.userId)}
            selected={selectedInviteId === i.userId}
          >
            <div
              style={{
                display: 'inline',
                fontSize: '1em',
                textAlign: 'left',
                color: '#0096ef',
                fontWeight: 'bold',
                fontStyle: 'italic',
              }}
            >
              {' '}
              {`${i.screenName}`}{' '}
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <Grid container>
        <Grid item>
          <div
            style={{ display: 'inline', textAlign: 'left' }}
          >{`Friends are calling, select one:`}</div>
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.root}>{renderRadioGroup()}</div>
    </div>
  );
}
