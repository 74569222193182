// RTCQualityReport
// ref.: https://www.w3.org/TR/webrtc-stats/
//
// Display selected list of RTCPeerConnection stats, including certain computed fields
//
// Also see:
//      Chrome tool: chrome://webrtc-internals
//      Firefox tool: about:webrtc
//      Safari  Console tab, then ensure WebRTC Logging is not Off (and so choose either Basic or Verbose)
//      Safari  Develop/WebRTC menu option
//
// To view all raw stats:
//   peerConnection.getStats().then(stats => stats.forEach(r => {console.log(r.type); for (const [key, value] of Object.entries(r)) {console.log(`   ${key}: ${value}`) } }))
//

/* references

https://developer.mozilla.org/en-US/docs/Web/API/RTCIceCandidatePairStats
https://w3c.github.io/webrtc-stats/#candidatepair-dict*
https://stackoverflow.com/questions/27230542/how-do-i-get-information-about-the-type-of-connection-of-a-webrtc-peerconnection
https://github.com/muaz-khan/getStats
https://testrtc.com/find-webrtc-active-connection/

*/

import React, { useEffect, useState, useRef } from 'react';
import useEvent from '@react-hook/event';

import { peerConnection } from 'lib/api/webRTCApi';
import { Chip, Avatar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import { makeArray } from 'lib/utils/utils';
import { useSelector } from 'react-redux';

const autoRefresh = true;

const useStyles = makeStyles(theme => ({
  table: {
    border: '1px solid white',
    borderCollapse: 'collapse',
    marginBottom: 20,
  },
  col1: {
    minWidth: 230,
    paddingLeft: 10,
  },
  col2: {
    minWidth: 160,
  },
  th: {
    borderBottom: '1px solid grey',
    textAlign: 'left',
  },
}));

// specify which types and keys to display in the report
// (see full dictionary at end of this file )
const statsItems = {
  'inbound-rtp': [
    'bandwidth',
    'packetLostPct',
    'jitter',
    //"packetsLost",
    //"packetsDiscarded",
    //"packetsReceived",
    //"fecPacketsReceived",
    //"fecPacketsDiscarded",
    //"jitterBufferDelay",
    //"jitterBufferTargetDelay",
    //"jitterBufferMinimumDelay",
    //"jitterBufferEmittedCount",
    'audioLevel',
    //"totalAudioEnergy",
    //"totalSamplesDuration",
    //"bytesReceived",
    //"timestamp",
  ],
  'outbound-rtp': [
    'bandwidth',
    'packetRetransmitPct',
    //"packetsSent",
    'retransmittedPacketsSent',
    'nackCount',
    //"retransmittedBytesSent",
    //"targetBitrate",
    //"bytesSent",
    //"timestamp",
  ],
  'remote-inbound-rtp': [
    'packetsLost',
    'fractionLost',
    'jitter',
    'roundTripTime',
    'totalRoundTripTime',
    //"roundTripTimeMeasurements",
    //"timestamp",
  ],
  'remote-outbound-rtp': [
    'bandwidth',
    //"packetsSent",
    //"roundTripTimeMeasurements",
    //"totalRoundTripTime",
    //"bytesSent",
    //"timestamp",
  ],
  'media-source': ['audioLevel', 'totalAudioEnergy', 'totalSamplesDuration'],
  'remote audio track': [
    'jitterBufferDelay',
    'jitterBufferEmittedCount',
    'audioLevel',
    //        "totalAudioEnergy",
    'totalSamplesReceived',
    'totalSamplesDuration',
    'concealedSamples',
    'silentConcealedSamples',
    'concealmentEvents',
    'insertedSamplesForDeceleration',
    'removedSamplesForAcceleration',
  ],
  'transport': [
    'bandwidthSend',
    'bandwidthReceive',
    'iceState',
    'selectedCandidatePairChanges',
    //        "packetsSent",
    //        "packetsReceived",
    //        "bytesSent",
    //        "bytesReceived",
    //        "timestamp",
  ],

  'selected ICE': ['iceLocal', 'iceRemote'],
};

// derived statsItems, not directly returned by getStats
// algorithmically derived
const derivedTypes = ['selected ICE', 'remote audio track'];

const RTCQualityReport = () => {
  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState([]);
  const statsRef = useRef();
  statsRef.current = stats;
  const [refreshCount, setRefreshCount] = useState(0);

  const rawStatsRef = useRef([]); // latest snapshot of raw stats
  const classes = useStyles();

  // keyboard handler: press "Q" to open the report
  useEvent(document, 'keydown', event => handleKeydown(event.keyCode));
  const handleKeydown = code => {
    switch (code) {
      case 81:
      case 113:
        handleOpen();
        break; //Q, q
      default:
        break;
    }
  };

  // provide quick instruction for developer
  useEffect(
    () => console.log("RTCQualityReport... press 'Q' to open the report."),
    []
  );

  // initiate periodic sampling
  useEffect(() => {
    let intervalId = null;
    if (autoRefresh && open) {
      intervalId = setInterval(() => {
        refreshStats();
      }, 2000);
    }
    return () => clearInterval(intervalId);
  }, [open]);

  // derive certain stats dynamically
  const applyDerivedStats = s => {
    const updates = {};

    // requires availability of prior sample
    const calcBandwidth = (s, packetKey) => {
      const prior = statsRef.current.find(s0 => s0.type === s.type);
      if (!prior) {
        return 'unavailable';
      }
      const t0 = prior.timestamp;
      const d0 = prior[packetKey];
      const t1 = s.timestamp;
      const d1 = s[packetKey];
      const deltaT = t1 - t0;
      const deltaD = d1 - d0;
      const bw = Math.round(deltaT ? (1000 * deltaD) / deltaT : undefined); // bytes/sec
      //console.log(`calcBandwidth t0=${t0}, t1=${t1}, d0=${d0}, d1=${d1}, bw=${bw}`)
      return bw;
    };

    const calcPacketPct = (s, packetsKey, lostKey) => {
      const packetCnt = s[packetsKey];
      const lostCnt = s[lostKey];
      //console.log(`calcPacketPct packetCnt=${packetCnt}, lostCnt=${lostCnt}, ratio=${  +(lostCnt/packetCnt).toFixed(5) * 100 }`)
      return packetCnt ? (100 * (lostCnt / packetCnt)).toFixed(3) : undefined;
    };

    const getStatsByType = type =>
      makeArray(rawStatsRef.current).filter(s => s.type === type);
    const getStatsById = id =>
      makeArray(rawStatsRef.current).find(s => s.id === id);

    const describeCandidates = s => {
      // some browsers provide stats type==="transport" which identifies the selectedCandidatePair
      // others (e.g. firefox) provide candisate-pair with 'nominated' && 'selected' asserted
      const transports = getStatsByType('transport');
      const candidatePairs = getStatsByType('candidate-pair');

      const selectedCandidatePairId =
        makeArray(transports)[0]?.selectedCandidatePairId;
      const selectedCandidatePair =
        getStatsById(selectedCandidatePairId) ||
        candidatePairs.find(s => s.nominated && s.selected);

      const candidateDescriptor = c =>
        c
          ? `[${c.candidateType}] ` + `${c?.protocol}-${c?.address}:${c?.port}`
          : null;

      const localCandidate = getStatsById(
        selectedCandidatePair?.localCandidateId
      );
      const remoteCandidate = getStatsById(
        selectedCandidatePair?.remoteCandidateId
      );
      return {
        local: candidateDescriptor(localCandidate),
        remote: candidateDescriptor(remoteCandidate),
      };
    };

    switch (s.type) {
      case 'inbound-rtp':
        updates.bandwidth = calcBandwidth(s, 'bytesReceived');
        updates.packetLostPct = calcPacketPct(
          s,
          'packetsReceived',
          'packetsLost'
        );
        break;
      case 'outbound-rtp':
        updates.bandwidth = calcBandwidth(s, 'bytesSent');
        updates.packetRetransmitPct = calcPacketPct(
          s,
          'packetsSent',
          'retransmittedPacketsSent'
        );
        break;
      case 'remote-inbound-rtp':
        updates.bandwidth = calcBandwidth(s, 'bytesReceived');
        updates.jitter = s.jitter ? s.jitter.toFixed(4) : null;
        break;
      case 'remote-outbound-rtp':
        updates.bandwidth = calcBandwidth(s, 'bytesSent');
        break;
      case 'transport':
        updates.bandwidthSend = calcBandwidth(s, 'bytesSent');
        updates.bandwidthReceive = calcBandwidth(s, 'bytesReceived');
        //const ice = describeCandidates(s);
        //updates.iceLocal = ice.local;
        //updates.iceRemote = ice.remote;
        break;
      case 'selected ICE':
        const c = describeCandidates(s);
        updates.iceLocal = c.local;
        updates.iceRemote = c.remote;
        break;
      case 'remote audio track':
        const track = rawStatsRef.current.find(
          s => (s.type = 'track' && s.remoteSource && s.kind === 'audio')
        );
        return { ...track, type: 'remote audio track' };
    }
    return { ...s, ...updates };
  };

  const refreshStats = () => {
    const stats = [];
    if (peerConnection) {
      peerConnection.getStats().then(rawStats => {
        // save raw stats sample
        rawStatsRef.current = [];
        rawStats.forEach(s => rawStatsRef.current.push(s));

        // buffer getStats result
        rawStats.forEach(s => {
          statsItems[s.type] && stats.push(applyDerivedStats(s));
        });

        // add derived (calculated) stats
        derivedTypes.forEach(type => {
          if (statsItems[type]) {
            stats.push(applyDerivedStats({ type }));
          }
        });

        setStats(stats);
        setRefreshCount(c => c + 1);
      });
    }
  };

  const handleOpen = () => {
    refreshStats();
    setOpen(true);
  };
  const handleFullScreenClose = () => setOpen(false);

  const renderFieldValue = (type, field) => {
    const s = statsRef.current.find(s => s.type === type);
    if (typeof (s && s[field]) === 'boolean') {
      return s[field] ? 'true' : 'false';
    }
    return s ? s[field] : null;
  };

  // map statsItems, lookup values in stats
  const renderReport = () => (
    <div style={{ marginTop: '2rem' }}>
      <div>
        {Object.entries(statsItems).map(([type, fieldArr]) => (
          <>
            <table className={classes.table}>
              <caption>{type}</caption>
              <tr>
                <th className={`${classes.col1} ${classes.th}`}>Parameter</th>
                <th className={`${classes.col2} ${classes.th}`}>Value</th>
              </tr>
              {fieldArr.map(field => (
                <tr>
                  <td className={classes.col1}>{field}</td>
                  <td className={classes.col2}>
                    {renderFieldValue(type, field)}
                  </td>{' '}
                </tr>
              ))}
            </table>
          </>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <Chip
        avatar={<Avatar>Q</Avatar>}
        label=""
        clickable
        onClick={handleOpen}
        color="primary"
        size="small"
      />
      <FullScreenDialog
        open={open}
        title={'WebRTC Quality Report'}
        onClose={handleFullScreenClose}
        content={
          open ? (
            <div style={{ marginTop: '2rem' }}>
              {autoRefresh ? (
                <div>{`Refresh Count: ${refreshCount}`}</div>
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={refreshStats}
                >
                  Refresh
                </Button>
              )}
              {renderReport()}
            </div>
          ) : null
        }
        zen={true}
      />
    </>
  );
};
export default RTCQualityReport;

/*
peerConnection.getLocalStreams()[0].getStats().then(s => console.log(s))
peerConnection.getReceivers()[0].getStats().then(s => console.log(s))
peerConnection.getRemoteStreams()[0]
peerConnection.getSenders()[0]
peerConnection.getStats().then(s => console.log(s))
peerConnection.getTransceivers()[0].sender.getStats().then(s => console.log(s)) //RTCStatsReport
peerConnection.getTransceivers()[0].receiver.getStats().then(s => console.log(s)) //RTCStatsReport

*/

/*
Full Dictionary of available stats

peerConnection.getStats().then(stats => stats.forEach(r => {console.log(r.type); for (const [key, value] of Object.entries(r)) {console.log(`   ${key}: ${value}`) } }))

VM4621:1 certificate
VM4621:1    id: CF54:C0:2B:26:D3:B5:CA:28:51:8A:99:4F:EA:66:F0:61:AC:99:CF:3E:AC:35:F8:83:15:A9:49:1C:A0:B7:C1:1C
VM4621:1    timestamp: 1669658523334
VM4621:1    type: certificate
VM4621:1    fingerprint: 54:C0:2B:26:D3:B5:CA:28:51:8A:99:4F:EA:66:F0:61:AC:99:CF:3E:AC:35:F8:83:15:A9:49:1C:A0:B7:C1:1C
VM4621:1    fingerprintAlgorithm: sha-256
VM4621:1    base64Certificate: MIIBFTCBvaADAgECAgkAuK2xX/LOIUEwCgYIKoZIzj0EAwIwETEPMA0GA1UEAwwGV2ViUlRDMB4XDTIyMTEyNzE2MTMyMFoXDTIyMTIyODE2MTMyMFowETEPMA0GA1UEAwwGV2ViUlRDMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAENcU71pVH+0AiVrJisK0zFufZjcv3ZjvdfPAvvq3LHdTxt3P5e8FhgXfrgneHXNMDZGN9agYhLK/dtit2xqQK2zAKBggqhkjOPQQDAgNHADBEAiAk3hKGWsOPKuL7Wvv5MVm6I6W3nrLEamhk+NmK/3a22wIgIvX/RpYb/KfnywdgYkz9JNJBmXohnWdPbG1LuD8qgKk=
VM4621:1 certificate
VM4621:1    id: CF76:88:2D:66:A6:64:1D:17:95:44:DC:97:20:93:CE:23:7E:2F:86:2A:29:4F:62:F0:CA:5A:15:9E:7A:AB:C0:D6
VM4621:1    timestamp: 1669658523334
VM4621:1    type: certificate
VM4621:1    fingerprint: 76:88:2D:66:A6:64:1D:17:95:44:DC:97:20:93:CE:23:7E:2F:86:2A:29:4F:62:F0:CA:5A:15:9E:7A:AB:C0:D6
VM4621:1    fingerprintAlgorithm: sha-256
VM4621:1    base64Certificate: MIIBFzCBvaADAgECAgkA1dVQWLK6h10wCgYIKoZIzj0EAwIwETEPMA0GA1UEAwwGV2ViUlRDMB4XDTIyMTEyNzE2MTMxNFoXDTIyMTIyODE2MTMxNFowETEPMA0GA1UEAwwGV2ViUlRDMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEJ3UQFzslO7fusfLJ1508olvub5vJtVs5MgXA54J5n+bEBE3ZsTh8mgMlmUsCZ76q3d7BLXplle2byrE7uZ49PDAKBggqhkjOPQQDAgNJADBGAiEAlazso0xwDnEJgao8UY/BlJT45xumBFosLyYRkPD2JNQCIQCUk5fJmswRuz2rS1JNI8/7ixWcs5vWeuRsXI+OBJqM5A==
VM4621:1 codec
VM4621:1    id: CIT01_0
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 0
VM4621:1    mimeType: audio/PCMU
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: CIT01_103
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 103
VM4621:1    mimeType: audio/ISAC
VM4621:1    clockRate: 16000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: CIT01_105
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 105
VM4621:1    mimeType: audio/CN
VM4621:1    clockRate: 16000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: CIT01_110
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 110
VM4621:1    mimeType: audio/telephone-event
VM4621:1    clockRate: 48000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: CIT01_111_minptime=10;useinbandfec=1
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 111
VM4621:1    mimeType: audio/opus
VM4621:1    clockRate: 48000
VM4621:1    channels: 2
VM4621:1    sdpFmtpLine: minptime=10;useinbandfec=1
VM4621:1 codec
VM4621:1    id: CIT01_113
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 113
VM4621:1    mimeType: audio/telephone-event
VM4621:1    clockRate: 16000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: CIT01_126
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 126
VM4621:1    mimeType: audio/telephone-event
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: CIT01_13
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 13
VM4621:1    mimeType: audio/CN
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: CIT01_63_111/111
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 63
VM4621:1    mimeType: audio/red
VM4621:1    clockRate: 48000
VM4621:1    channels: 2
VM4621:1    sdpFmtpLine: 111/111
VM4621:1 codec
VM4621:1    id: CIT01_8
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 8
VM4621:1    mimeType: audio/PCMA
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: CIT01_9
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 9
VM4621:1    mimeType: audio/G722
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_0
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 0
VM4621:1    mimeType: audio/PCMU
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_103
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 103
VM4621:1    mimeType: audio/ISAC
VM4621:1    clockRate: 16000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_105
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 105
VM4621:1    mimeType: audio/CN
VM4621:1    clockRate: 16000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_110
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 110
VM4621:1    mimeType: audio/telephone-event
VM4621:1    clockRate: 48000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_111_minptime=10;useinbandfec=1
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 111
VM4621:1    mimeType: audio/opus
VM4621:1    clockRate: 48000
VM4621:1    channels: 2
VM4621:1    sdpFmtpLine: minptime=10;useinbandfec=1
VM4621:1 codec
VM4621:1    id: COT01_113
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 113
VM4621:1    mimeType: audio/telephone-event
VM4621:1    clockRate: 16000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_126
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 126
VM4621:1    mimeType: audio/telephone-event
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_13
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 13
VM4621:1    mimeType: audio/CN
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_63_111/111
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 63
VM4621:1    mimeType: audio/red
VM4621:1    clockRate: 48000
VM4621:1    channels: 2
VM4621:1    sdpFmtpLine: 111/111
VM4621:1 codec
VM4621:1    id: COT01_8
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 8
VM4621:1    mimeType: audio/PCMA
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 codec
VM4621:1    id: COT01_9
VM4621:1    timestamp: 1669658523334
VM4621:1    type: codec
VM4621:1    transportId: T01
VM4621:1    payloadType: 9
VM4621:1    mimeType: audio/G722
VM4621:1    clockRate: 8000
VM4621:1    channels: 1
VM4621:1 data-channel
VM4621:1    id: D1
VM4621:1    timestamp: 1669658523334
VM4621:1    type: data-channel
VM4621:1    label: telemetry
VM4621:1    protocol:
VM4621:1    dataChannelIdentifier: 0
VM4621:1    state: open
VM4621:1    messagesSent: 264
VM4621:1    bytesSent: 17892
VM4621:1    messagesReceived: 0
VM4621:1    bytesReceived: 0
VM4621:1 data-channel
VM4621:1    id: D2
VM4621:1    timestamp: 1669658523334
VM4621:1    type: data-channel
VM4621:1    label: telemetry
VM4621:1    protocol:
VM4621:1    dataChannelIdentifier: 1
VM4621:1    state: open
VM4621:1    messagesSent: 0
VM4621:1    bytesSent: 0
VM4621:1    messagesReceived: 170
VM4621:1    bytesReceived: 11477
VM4621:1 stream
VM4621:1    id: DEPRECATED_Se3a51e7c-312d-432c-add2-e9c68884da89
VM4621:1    timestamp: 1669658523334
VM4621:1    type: stream
VM4621:1    streamIdentifier: e3a51e7c-312d-432c-add2-e9c68884da89
VM4621:1    trackIds: DEPRECATED_TO1
VM4621:1 stream
VM4621:1    id: DEPRECATED_Sf05c4178-e14d-4472-a249-37757ed34c59
VM4621:1    timestamp: 1669658523334
VM4621:1    type: stream
VM4621:1    streamIdentifier: f05c4178-e14d-4472-a249-37757ed34c59
VM4621:1    trackIds: DEPRECATED_TI1
VM4621:1 track
VM4621:1    id: DEPRECATED_TI1
VM4621:1    timestamp: 1669658523334
VM4621:1    type: track
VM4621:1    trackIdentifier: 592422c0-2170-45a0-8daf-058b70262a6a
VM4621:1    remoteSource: true
VM4621:1    ended: false
VM4621:1    detached: false
VM4621:1    kind: audio
VM4621:1    jitterBufferDelay: 17663395.2
VM4621:1    jitterBufferEmittedCount: 264187200
VM4621:1    audioLevel: 0
VM4621:1    totalAudioEnergy: 0
VM4621:1    totalSamplesReceived: 312896000
VM4621:1    totalSamplesDuration: 6518.920000095873
VM4621:1    concealedSamples: 48915407
VM4621:1    silentConcealedSamples: 48632889
VM4621:1    concealmentEvents: 189
VM4621:1    insertedSamplesForDeceleration: 84036
VM4621:1    removedSamplesForAcceleration: 290427
VM4621:1 track
VM4621:1    id: DEPRECATED_TO1
VM4621:1    timestamp: 1669658523334
VM4621:1    type: track
VM4621:1    trackIdentifier: 8b551009-e8a8-41d3-bec9-2eada4929b37
VM4621:1    mediaSourceId: SA1
VM4621:1    remoteSource: false
VM4621:1    ended: false
VM4621:1    detached: false
VM4621:1    kind: audio
VM4621:1 inbound-rtp
VM4621:1    id: IA4034612361
VM4621:1    timestamp: 1669658523334
VM4621:1    type: inbound-rtp
VM4621:1    ssrc: 4034612361
VM4621:1    kind: audio
VM4621:1    trackId: DEPRECATED_TI1
VM4621:1    transportId: T01
VM4621:1    codecId: CIT01_111_minptime=10;useinbandfec=1
VM4621:1    mediaType: audio
VM4621:1    jitter: 0.009
VM4621:1    packetsLost: 63
VM4621:1    packetsDiscarded: 22
VM4621:1    trackIdentifier: 592422c0-2170-45a0-8daf-058b70262a6a
VM4621:1    mid: 0
VM4621:1    remoteId: ROA4034612361
VM4621:1    packetsReceived: 278376
VM4621:1    fecPacketsReceived: 0
VM4621:1    fecPacketsDiscarded: 0
VM4621:1    bytesReceived: 20957776
VM4621:1    headerBytesReceived: 7939842
VM4621:1    lastPacketReceivedTimestamp: 1669657510339
VM4621:1    jitterBufferDelay: 17663395.2
VM4621:1    jitterBufferTargetDelay: 18248448
VM4621:1    jitterBufferMinimumDelay: 18248294.4
VM4621:1    jitterBufferEmittedCount: 264187200
VM4621:1    totalSamplesReceived: 312896000
VM4621:1    concealedSamples: 48915407
VM4621:1    silentConcealedSamples: 48632889
VM4621:1    concealmentEvents: 189
VM4621:1    insertedSamplesForDeceleration: 84036
VM4621:1    removedSamplesForAcceleration: 290427
VM4621:1    audioLevel: 0
VM4621:1    totalAudioEnergy: 0
VM4621:1    totalSamplesDuration: 6518.920000095873
VM4621:1 local-candidate
VM4621:1    id: IAO+ZziIh
VM4621:1    timestamp: 1669658523334
VM4621:1    type: local-candidate
VM4621:1    transportId: T01
VM4621:1    isRemote: false
VM4621:1    networkType: ethernet
VM4621:1    ip: 54.172.60.95
VM4621:1    address: 54.172.60.95
VM4621:1    port: 27434
VM4621:1    protocol: udp
VM4621:1    relayProtocol: tcp
VM4621:1    candidateType: relay
VM4621:1    priority: 25108479
VM4621:1    url: turn:global.turn.twilio.com:3478?transport=tcp
VM4621:1 local-candidate
VM4621:1    id: IIF12R7Qc
VM4621:1    timestamp: 1669658523334
VM4621:1    type: local-candidate
VM4621:1    transportId: T01
VM4621:1    isRemote: false
VM4621:1    networkType: ethernet
VM4621:1    ip: 54.172.60.95
VM4621:1    address: 54.172.60.95
VM4621:1    port: 32051
VM4621:1    protocol: udp
VM4621:1    relayProtocol: udp
VM4621:1    candidateType: relay
VM4621:1    priority: 41885951
VM4621:1    url: turn:global.turn.twilio.com:3478?transport=udp
VM4621:1 local-candidate
VM4621:1    id: IsGEnxEPb
VM4621:1    timestamp: 1669658523334
VM4621:1    type: local-candidate
VM4621:1    transportId: T01
VM4621:1    isRemote: false
VM4621:1    networkType: ethernet
VM4621:1    ip: 54.172.60.95
VM4621:1    address: 54.172.60.95
VM4621:1    port: 27135
VM4621:1    protocol: udp
VM4621:1    relayProtocol: tcp
VM4621:1    candidateType: relay
VM4621:1    priority: 25108223
VM4621:1    url: turn:global.turn.twilio.com:443?transport=tcp
VM4621:1 outbound-rtp
VM4621:1    id: OA376469827
VM4621:1    timestamp: 1669658523334
VM4621:1    type: outbound-rtp
VM4621:1    ssrc: 376469827
VM4621:1    kind: audio
VM4621:1    trackId: DEPRECATED_TO1
VM4621:1    transportId: T01
VM4621:1    codecId: COT01_111_minptime=10;useinbandfec=1
VM4621:1    mediaType: audio
VM4621:1    mediaSourceId: SA1
VM4621:1    remoteId: RIA376469827
VM4621:1    mid: 0
VM4621:1    packetsSent: 329891
VM4621:1    retransmittedPacketsSent: 0
VM4621:1    bytesSent: 15107773
VM4621:1    headerBytesSent: 9419200
VM4621:1    retransmittedBytesSent: 0
VM4621:1    targetBitrate: 32000
VM4621:1    nackCount: 0
VM4621:1    active: true
VM4621:1 peer-connection
VM4621:1    id: P
VM4621:1    timestamp: 1669658523334
VM4621:1    type: peer-connection
VM4621:1    dataChannelsOpened: 2
VM4621:1    dataChannelsClosed: 0
VM4621:1 remote-inbound-rtp
VM4621:1    id: RIA376469827
VM4621:1    timestamp: 1669657506267
VM4621:1    type: remote-inbound-rtp
VM4621:1    ssrc: 376469827
VM4621:1    kind: audio
VM4621:1    transportId: T01
VM4621:1    codecId: COT01_111_minptime=10;useinbandfec=1
VM4621:1    jitter: 0.0021041666666666665
VM4621:1    packetsLost: 21
VM4621:1    localId: OA376469827
VM4621:1    roundTripTime: 0.038
VM4621:1    fractionLost: 0
VM4621:1    totalRoundTripTime: 39.644
VM4621:1    roundTripTimeMeasurements: 1108
VM4621:1 remote-outbound-rtp
VM4621:1    id: ROA4034612361
VM4621:1    timestamp: 1669657508473
VM4621:1    type: remote-outbound-rtp
VM4621:1    ssrc: 4034612361
VM4621:1    kind: audio
VM4621:1    transportId: T01
VM4621:1    codecId: CIT01_111_minptime=10;useinbandfec=1
VM4621:1    packetsSent: 278344
VM4621:1    bytesSent: 20954957
VM4621:1    localId: IA4034612361
VM4621:1    remoteTimestamp: 1669595296003
VM4621:1    reportsSent: 1104
VM4621:1    roundTripTimeMeasurements: 0
VM4621:1    totalRoundTripTime: 0
VM4621:1 media-source
VM4621:1    id: SA1
VM4621:1    timestamp: 1669658523334
VM4621:1    type: media-source
VM4621:1    trackIdentifier: 8b551009-e8a8-41d3-bec9-2eada4929b37
VM4621:1    kind: audio
VM4621:1    audioLevel: 0
VM4621:1    totalAudioEnergy: 0.19612359960575246
VM4621:1    totalSamplesDuration: 6518.920000095873
VM4621:1 transport
VM4621:1    id: T01
VM4621:1    timestamp: 1669658523334
VM4621:1    type: transport
VM4621:1    bytesSent: 27178761
VM4621:1    packetsSent: 346897
VM4621:1    bytesReceived: 34626349
VM4621:1    packetsReceived: 346789
VM4621:1    dtlsState: connected
VM4621:1    localCertificateId: CF54:C0:2B:26:D3:B5:CA:28:51:8A:99:4F:EA:66:F0:61:AC:99:CF:3E:AC:35:F8:83:15:A9:49:1C:A0:B7:C1:1C
VM4621:1    remoteCertificateId: CF76:88:2D:66:A6:64:1D:17:95:44:DC:97:20:93:CE:23:7E:2F:86:2A:29:4F:62:F0:CA:5A:15:9E:7A:AB:C0:D6
VM4621:1    tlsVersion: FEFD
VM4621:1    dtlsCipher: TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256
VM4621:1    dtlsRole: client
VM4621:1    srtpCipher: AES_CM_128_HMAC_SHA1_80
VM4621:1    selectedCandidatePairChanges: 2
VM4621:1    iceRole: controlled
VM4621:1    iceLocalUsernameFragment: cXDt
VM4621:1    iceState: failed
*/

/*
ref: https://stackoverflow.com/questions/27230542/how-do-i-get-information-about-the-type-of-connection-of-a-webrtc-peerconnection

        let stats = await peerConnection.getStats();
        let selectedPairs = [];

        if(stats){
            let selectedPairId = null;
            for(const [key, stat] of stats){
                if(stat.type == "transport"){
                    selectedPairId = stat.selectedCandidatePairId;
                    break;
                }
            }
            let candidatePair = stats.get(selectedPairId);
            if(!candidatePair){
                stats.forEach(s => {
                    if (s.type==='candidate-pair' && s.nominated && s.selected){
                        selectedPairs.push(s);
                })


                for(const [key, stat] of stats){
                    if(stat.type == "candidate-pair" && stat.selected){
                        candidatePair = stat;
                        break;
                    }
                }
            }

            console.log("candisatePair:", candidatePair)
        }

*/
