/**
 * TopMenu
 * Renders standardized "top of page" tools
 * Accepts a forwardRef so parent can access outermost component
 * Accepts props.classes.subHeader to style outermost component
 *
 *
 */

import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Grid, Button, Typography } from '@material-ui/core';

import BorderedBlock from 'components/utils/BorderedBlock';
import MainMenu from 'components/MainMenu';
import DisplayLanguageSelector from 'components/Settings/components/DisplayLanguageSelector';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  subHeader: {
    position: 'relative',
  },
  langBtnContainer: {
    paddingLeft: 24,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8,
    },
  },
}));

const TopMenu = forwardRef(
  ({ classes: classesOverride, langBtnEn }, componentRef) => {
    const classes = { ...useStyles(), ...classesOverride };
    const activeCommunity = useSelector(state => state.ui.pSession.community);

    return (
      <Grid
        ref={componentRef}
        classes={{ root: classes.subHeader }}
        container
        justify="space-between"
        alignItems="center"
      >
        <Grid item classes={{ root: classes.langBtnContainer }}>
          <DisplayLanguageSelector variant="toggle" />
        </Grid>
        <Grid item>
          <MainMenu classes={classes} format="Tabs" legendStyle="label" />
        </Grid>
      </Grid>
    );
  }
);
export default TopMenu;
