/**
 * WebVoiceSDKTest
 * based on https://www.npmjs.com/package/@linto-ai/webvoicesdk
 * 
 * vad demo implemented
 * downSampler, speechPreemphasizer, feat, hotword not implemented at this time
 * 
 */


import React, { useEffect, useRef } from 'react';
import useState from 'react-usestateref'
import { makeStyles } from '@material-ui/core/styles';
import {
} from '@material-ui/core';

/**
* yarn add @linto-ai/webvoicesdk
*     //@@WARNING in ./node_modules/@linto-ai/webvoicesdk/dist/webVoiceVad-linto.min.js 11:46-50
*     Critical dependency: the request of a dependency is an expression
*/

//import vad from 'webvoice-vad';
const webVoiceVad = require('webvoice-vad');


const useStyles = makeStyles((theme) => ({
    container: {
        //        backgroundColor: 'white', //"#303030",
        '& button': {
        },
        '& textarea': {
            fontSize: ".8rem",
            letterSpacing: "1px",
            display: "block",
            padding: "5px",
            lineHeight: 1.5,
            borderRadius: "5px",
            border: "1px solid #ccc",
            boxShadow: "1px 1px 1px #999",
        },
        '& div': {
            display: "inline-block",
        },
    },
    ledBox: {
        height: "30px",
        width: "25%",
        margin: "10px 0",
        display: "block",
        '& p': {
            fontSize: "12px",
            textAlign: "center",
            margin: "1em",

        },
    },
    '@keyframes blinkRed': {
        'from': {
            backgroundColor: "#F00",
        },
        '50%': {
            backgroundColor: "#A00",
            boxShadow: "rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0",
        },
        'to': {
            backgroundColor: "#F00",
        },
    },
    ledNone: {
        opacity: 0,
    },
    ledRed: {
        margin: "0 auto",
        width: "24px",
        height: "24px",
        backgroundColor: "#F00",
        borderRadius: "50%",
        //boxShadow: "rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px",
        animation: '$blinkRed 0.5s infinite',
    },

    ledGreen: {
        margin: "0 auto",
        width: "24px",
        height: "24px",
        backgroundColor: "#ABFF00",
        borderRadius: "50%",
        //boxShadow: "rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px",
    },
}))

const micOptions = `{
    "frameSize": 4096,
    "constraints": {
        "echoCancellation": true,
        "autoGainControl": true,
        "noiseSuppression": true
    }
}`

const vadOptions = `{
    "numActivations": 10,
    "threshold": 0.85,
    "timeAfterStop": 800
}`

const WebVoiceSDKTest = ({ }) => {
    const classes = useStyles();
    const [micEnabled, setMicEnabled] = useState(false);
    const [talking, setTalking] = useState(false);

    const startRef = useRef();
    const stopRef = useRef();
    const startRecordRef = useRef();
    const stopRecordRef = useRef();
    const lintomodelRef = useRef();
    const slinfoxmodelRef = useRef();
    const micRef = useRef();
    const vadRef = useRef();
    const assets = useRef({}); // hold allocated objects

    useEffect(() => {
        console.log("webVoiceVad:", webVoiceVad)

        startRef.current.addEventListener('click', handleStart);
        stopRef.current.addEventListener('click', handleStop);

        return (() => {
            try {
                startRef.current.removeEventListener('click', handleStart);
                stopRef.current.removeEventListener('click', handleStop);
   
            } catch (e) {
                // references may be unavailable during dismount
            }
            handleStop();
        })
    }, [])

    const handleStart = async () => {
        const obj = assets.current;

        obj.mic = new webVoiceVad.Mic(JSON.parse(micRef.current.value));
        obj.downSampler = new webVoiceVad.DownSampler();
        obj.vad = new webVoiceVad.Vad(JSON.parse(vadRef.current.value));
        obj.speechPreemphaser = new webVoiceVad.SpeechPreemphaser();
        obj.feat = new webVoiceVad.FeaturesExtractor();
        obj.hotword = new webVoiceVad.Hotword();

        await obj.mic.start()
        await obj.downSampler.start(obj.mic)
        await obj.vad.start(obj.mic)
        await obj.speechPreemphaser.start(obj.downSampler)
        await obj.feat.start(obj.speechPreemphaser)
        await obj.hotword.start(obj.feat, obj.vad, 0.9)
        await obj.hotword.loadModel(obj.hotword.availableModels["linto"])


        setMicEnabled(Boolean(obj.mic));
        obj.vad.addEventListener("speakingStatus", handleVadEvent)
        global.mic = obj.mic
        console.log("mic:", obj.mic)
    }

    const handleStop = async () => {
        const obj = assets.current;
        obj.downSampler && await obj.downSampler.stop();
        obj.vad && await obj.vad.stop();
        obj.speechPreemphaser && await obj.speechPreemphaser.stop();
        obj.feat && await obj.feat.stop();
        obj.hotword && obj.feat && obj.vad && await obj.hotword.stop(obj.feat, obj.vad);
        obj.vad && obj.vad.removeEventListener("speakingStatus", handleVadEvent);
        setMicEnabled(false);
    }

    const handleVadEvent = function (e) {
        setTalking(e.detail)
    }

    // turn on the LED when mic becomes enabled, then blink it with talking
    const ledClass = () => micEnabled
        ? (talking ? classes.ledRed : classes.ledGreen)
        : classes.ledNone

    return <div className={classes.container}>
        <hr />
        <div>
            {/* only start and stop are implemented */}
            <button ref={startRef} id="start">Start VAD</button>
            <button ref={stopRef} id="stop">Stop VAD</button>
            <button disabled ref={startRecordRef} id="startrecord">Record events</button>
            <button disabled ref={stopRecordRef} id="stoprecord">Stop recording</button>
            <button disabled ref={lintomodelRef} id="lintomodel">Use LinTO model</button>
            <button disabled ref={slinfoxmodelRef} id="slinfoxmodel">Use LinTO-Snips-Firefox model</button>
        </div>
        <hr />
        <div>
            <label for="mic">Microphone options :</label>
            <textarea ref={micRef} id="mic" rows="10" cols="50">{micOptions}</textarea>
        </div>
        <hr />
        <div>
            <div className={classes.ledBox}>
                <div id="VADLed" className={ledClass()} ></div>
            </div>
            <label for="useVAD">Voice Activity Detection :</label>
            <textarea ref={vadRef} id="VAD" rows="10" cols="50">{vadOptions}</textarea>
        </div>
    </div>

}

export default WebVoiceSDKTest;

