/**
 * Render a static page with content fetched from the host
 */

import React, { useState, useEffect } from 'react';
import Copyright from 'components/utils/Copyright';
import HtmlContent from 'lib/classes/HtmlContent';

const Page = ({ title }) => {
  const [content, setContent] = useState(null);
  const htmlContent = new HtmlContent(title);

  useEffect(() => {
    htmlContent.fetch().then(body => setContent(HtmlContent.html(body)));
  }, []);

  return (
    <>
      {content}
      <Copyright />
    </>
  );
};

export default Page;
