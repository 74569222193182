import { minScreenNameLength, forbiddenScreenNames } from 'configs/config-hvn';
import { t } from 'lib/translation/trans';

// check structure of entered username
export const validateProposedUsername = username => {
  let errObj = null;
  // check for proper length
  const badLength = () => username.length < minScreenNameLength;
  // disallow illegal characters
  // only alphanumeric, whitespace, "_", "-", "." are permitted
  const badChar = () => !username.match(/^[0-9a-zA-Z_\s\-\.]+$/);
  // disallow forbidden screenNames
  const badWord = () =>
    forbiddenScreenNames.words.find(
      word => word.toLowerCase() === username.trim().toLowerCase()
    );
  // disallow forbidden substrings
  const badSubstring = () =>
    forbiddenScreenNames.substrings.find(
      badString => username.search(badString) != -1
    );

  if (badLength()) {
    errObj = {
      heading: `Bad username Length`,
      body: `Your name must be at least ${minScreenNameLength} characters long.`,
    };
  } else if (badChar()) {
    errObj = {
      heading: `Bad character in username`,
      body: ` Only alphanumeric, whitespace, "_", "-", "." are permitted.`,
    };
  } else if (badWord()) {
    errObj = {
      heading: `Bad word in username`,
      body: `The word "${badWord()}" is not permitted in your name.`,
    };
  } else if (badSubstring()) {
    errObj = {
      heading: `Bad string in username`,
      body: `Your name cannot contain the sequence "${badSubstring()}"`,
    };
  }

  return errObj;
};

export const validateProposedPassword = (pw1, pw2) =>
  pw1 === pw2
    ? null
    : {
        heading: `Passwords do not match`,
        body: `Make sure "Create OkSayit Password" is exactly the same as "Confirm Password"`,
      };

export const validateProposedInvitation = (partition, proposedInvitation) =>
  proposedInvitation === '12345678'
    ? null
    : {
        heading: `Unrecognized Invitation`,
        body: `Make sure that you have entered your invitation code carefully. If you continue to have difficulty please contact your Community Host for assistance.`,
      };

export const validateProposedSocAuth = auth => {
  const isGood = typeof auth === 'object' && Boolean(auth?.socId);

  return isGood
    ? null
    : {
        heading: t(`Authentication_Problem`),
        body:
          typeof auth === 'string'
            ? auth
            : `Authentication declined or cancelled.`,
      };
};
