/**
 * Render a radio or checklist form for selecting filter criteria.
 * Obtain initial value from redux store, and save results to redux store and localstorage.
 */

import React from 'react';
import PropTypes from 'prop-types';
import LogRegFieldRadio from 'containers/RegistrationManager/components/LogRegFieldRadio.js';
import LogRegFieldCheckbox from 'containers/RegistrationManager/components/LogRegFieldCheckbox.js';

import {
  FormLabel,
  FormControl,
  IconButton,
  Menu,
  Grid,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import FilterMenuButton from './FilterMenuButton';
import { t } from 'lib/translation/trans';

// Original design here: https://github.com/siriwatknp/mui-treasury/issues/777
const minWidth = 140;

const useStyles = makeStyles(theme => ({
  button: {
    //textTransform: 'capitalize',
    'paddingTop': 8,
    'paddingBottom': 8,
    '& > span': {
      fontSize: '0.9em',
    },
  },
  downloadIcon: {
    color: blue[400],
    userSelect: 'none',
    pointerEvents: 'none',
    marginRight: 8,
  },
  paper: {
    minWidth: minWidth,
    marginTop: 8,
  },
  form: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    padding: '5px',
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: '24px',
    width: '24px',
  },
  helpButton: {
    color: theme.palette.secondary.main,
    height: '24px',
    width: '24px',
  },
}));

const FilterMenu = ({ field, onFieldChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    //dispatch(uiActions.optionSet({ key: filterKey, value: currentValue }))
  };

  return (
    <div>
      <FilterMenuButton field={field} onClick={handleOpenMenu} />
      <Menu
        id="simple-menu"
        classes={{ paper: classes.paper }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <div className={classes.form}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel style={{ marginBottom: 10 }} component="legend">
              {field?.helper}
            </FormLabel>
            {field?.type === 'radio' && (
              <LogRegFieldRadio field={field} onFieldChange={onFieldChange} />
            )}
            {field?.type === 'checkbox' && (
              <LogRegFieldCheckbox
                field={field}
                onFieldChange={onFieldChange}
              />
            )}

            <Grid style={{ marginTop: 10 }} container justify="flex-start">
              <Button variant="outlined" size="small" onClick={handleCloseMenu}>
                {t('OK')}
              </Button>
            </Grid>
          </FormControl>
        </div>
      </Menu>
    </div>
  );
};

FilterMenu.propTypes = {
  fieldbtnLabel: PropTypes.string,
  menuHeading: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
  renderList: PropTypes.func.isRequired,
  helpContent: PropTypes.node,
};
FilterMenu.defaultProps = {
  field: PropTypes.object,
  onFieldChange: PropTypes.func,
};

export default FilterMenu;
