/**
 * Google Identity Services Test
 * ref.: https://www.youtube.com/watch?v=roxC8SMs7HU
 * 
 * Requires:
 *  - jwt-decode module
 *  - <script src="https://accounts.google.com/gsi/client" async defer> </script>
 */

 import React, { useState, useEffect } from 'react';
 import jwt_decode from "jwt-decode";
 
 const client_id = "687095247130-c03i76jp9qt2fbp70qslujmnthgjrpp2.apps.googleusercontent.com";
 
 function GoogleIdentityServicesTest() {
   const [ user, setUser ] = useState(null);
 
   useEffect(() => {
     /* global google */ // let lint know that google has been pre-loaded
      google.accounts.id.initialize({client_id, callback: handleCallbackResponse });
 
      google.accounts.id.renderButton(
         document.getElementById("signInDiv"),
         { theme: "outline", size: "large"}
      );
 
      // optional, shows prompt
      //google.accounts.id.prompt();
 }, []);
 
 function handleCallbackResponse(response){
     //console.log("Encoded JWT ID token: ", response.credential);
     const userObject = jwt_decode(response.credential);
     setUser(userObject);
     console.log("user:", userObject);
     document.getElementById("signInDiv").hidden = true; //hide button
 }
 function handleSignOut(e){
     setUser(null);
     document.getElementById("signInDiv").hidden = false;
 }
 
 
 function handleCustomButtonClick(){

 }

 
   return (<div >
    <div><button onClick={handleCustomButtonClick}>Custom Login</button></div>
     <div  id="signInDiv"> </div>
     {user 
     ? <div style={{textAlign: "center"}}>
       <h3>{user.name}</h3>
       <div><img src={user.picture}></img></div>
       <div><button onClick={handleSignOut}>Logout</button> </div>
       </div> 
     : null
     }
   </div>
   );
 }
 
 export default GoogleIdentityServicesTest;
 