/**
 * Provides a translucent overlay with async "please wait" indication
 *  - responds to props or redux state
 *  - does NOT overwrite active <Dialog />
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
}));

export default function BackdropStd(props) {
  const defaults = { show: false, showProgress: true, msg: t('Please_Wait') };
  const redux = useSelector(state => state.ui.backdrop);
  const { show, showProgress, msg } = props.redux
    ? redux
    : { ...defaults, ...props };

  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={show}>
        <Grid container direction="column" justify="center" alignItems="center">
          {msg && <h2>{msg}</h2>}
          {showProgress && <CircularProgress color="inherit" />}
        </Grid>
      </Backdrop>
    </div>
  );
}

BackdropStd.propTypes = {
  redux: PropTypes.bool, // place control in hands of redux
  show: PropTypes.bool,
  showProgress: PropTypes.bool,
  msg: PropTypes.string,
};
