/**
 * Render auto-start mode controls for Player, Recorder, LiveCall
 *
 * Recorder can automatically start recording upon render.
 * Playlists (MemberView, PlaylistView, Inbox) can start playing a message upon item selection.
 * LiveCall can connect immediately when callee accepts.
 *
 * props.format determines whether controls are rendered as checkboxes or switches
 * props.items itemize appliance controls to be rendered
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { t } from 'lib/translation/trans';
import Help from 'components/utils/ContextualHelpButton2';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  description: {
    fontSize: '.8rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },

  label: {
    fontSize: '1rem',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },
}));

export default function AutoStartControl({ items, format }) {
  const { micApproved, autoRecord, autoPlay, autoLiveCall } = useSelector(
    state => state.ui.pSession
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  const checked = {
    Recorder: autoRecord,
    Player: autoPlay,
    LiveCall: autoLiveCall,
  };

  const helpMessage = {
    Recorder: t('Start_recording_immediately'),
    Player: t('Start_playback_immediately'),
    LiveCall: t('Connect_immediately'),
  };

  const handleChange = item => {
    switch (item) {
      case 'Player':
        dispatch(uiActions.autoPlaySetStatus(!autoPlay));
        break;
      case 'Recorder':
        autoRecord ||
          dispatch(
            uiActions.alertSet({
              message: t('The_recorder_will_start_automatically_next_time'),
              severity: 'info',
            })
          );
        dispatch(uiActions.autoRecordSetStatus(!autoRecord));
        break;
      case 'LiveCall':
        dispatch(uiActions.autoLiveCallSetStatus(!autoLiveCall));
        break;
      default:
        break;
    }
  };

  // render an array of checkboxes
  // Recorder control is disabled until mic is approved by user
  const renderCheckboxes = () => (
    <div style={{ display: 'inline-block' }}>
      <FormGroup row>
        {items.map(({ appliance, label }, ix) => (
          <FormControlLabel
            key={ix}
            style={{ marginLeft: ix === 0 ? -16 : 16 }}
            labelPlacement="start"
            control={
              <Checkbox
                disabled={appliance === 'Recorder' ? !micApproved : false}
                checked={checked[appliance]}
                onChange={() => handleChange(appliance)}
                name={appliance}
              />
            }
            label={t(label || appliance)}
          />
        ))}
      </FormGroup>
    </div>
  );

  // render an array of switches
  // Recorder control is disabled until mic is approved by user
  const renderSwitches = () => (
    <div style={{ marginTop: '10px' }}>
      {items.map(({ appliance, label }, ix) => (
        <FormControlLabel
          key={ix}
          control={
            <Switch
              disabled={appliance === 'Recorder' ? !micApproved : false}
              size="small"
              checked={appliance === 'Recorder' ? autoRecord : autoPlay}
              onChange={() => handleChange(appliance)}
              name={appliance}
              color="secondary"
            />
          }
          label={
            <Typography style={{ fontSize: '.9rem' }} variant="body1">
              {t(label || appliance)}
            </Typography>
          }
        />
      ))}
    </div>
  );

  return (
    <>
      {format === 'switches' ? renderSwitches() : renderCheckboxes()}
      <Help
        heading={`${t('Auto_Start')} ${t('Preferences')}`}
        items={items.map(item => ({
          label: t(item.appliance),
          description: helpMessage[item.appliance],
        }))}
        classes={classes}
      />
    </>
  );
}

AutoStartControl.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      appliance: PropTypes.oneOf(['Recorder', 'Player', 'LiveCall']),
      label: PropTypes.string,
    })
  ),
  format: PropTypes.oneOf(['checkboxes', 'switches']),
};

AutoStartControl.defaultProps = {
  items: [{ appliance: 'Recorder', label: 'auto_start' }],
  format: 'switches',
};
