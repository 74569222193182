import React from 'react';
import { pwa } from 'lib/classes/PWA';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  true: { color: 'green', fontWeight: 'bold' },
  false: { color: 'red', fontWeight: 'normal' },
}));

const PWAStatus = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const pwaInstalled = useSelector(state => state.ui.pSession.pwaInstalled);

  const clearPWAInstallStatus = () => {
    dispatch(uiActions.notePWAInstalled(false));
  };

  const unregisterServiceWorker = () => {
    clearPWAInstallStatus();
    pwa.unregister().then(() => window.location.reload());
  };

  return (
    <ul>
      <li
        className={pwa.serviceWorkerRegistration ? classes.true : classes.false}
      >
        service worker registered
        {pwa.serviceWorkerRegistration && (
          <IconButton
            onClick={unregisterServiceWorker}
            style={{ transform: 'scale(.8)' }}
          >
            <ClearIcon />
          </IconButton>
        )}
      </li>
      <li className={pwaInstalled ? classes.true : classes.false}>
        PWA {pwaInstalled ? 'Installed' : 'NOT Installed'}{' '}
        {pwaInstalled && (
          <IconButton
            onClick={clearPWAInstallStatus}
            style={{ transform: 'scale(.8)' }}
          >
            <ClearIcon />
          </IconButton>
        )}
      </li>
      <li className={pwa.pushSubscription ? classes.true : classes.false}>
        subscribed for notifications
      </li>
      <li className={pwa.canInstall() ? classes.true : classes.false}>
        can-install-with-button
      </li>
      <li
        className={pwa.canGrantPushPermission() ? classes.true : classes.false}
      >
        can-subscribe
      </li>
      <hr />
      <li
        className={pwa.beforeinstallpromptEvent ? classes.true : classes.false}
      >
        beforeinstallpromptEvent
      </li>
      <li
        className={
          pwa.notificationsPermissionSupported ? classes.true : classes.false
        }
      >
        notificationsPermissionSupported
      </li>
      <li
        className={
          pwa.notificationsPermissionGrantedThisSession
            ? classes.true
            : classes.false
        }
      >
        notificationsPermissionGrantedThisSession
      </li>
      <li className={pwa.runningAsPWA() ? classes.true : classes.false}>
        running as PWA
      </li>

      <li>
        pwa.pushSubscription ={' '}
        {pwa.pushSubscription
          ? typeof pwa.pushSubscription === 'object'
            ? pwa.pushSubscription.constructor.name
            : 'not object'
          : 'null'}
      </li>
    </ul>
  );
};

export default PWAStatus;
