/**
 *
 */

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const includeVideo = false;

const styles = {
  mediaContainer: {
    width: '150px',
    height: '150px',
    borderRadius: '8px',
  },
  mediaElement: {
    width: '100%',
    height: '100%',
  },
};

const MediaView = props => {
  const { stream, muted, visible } = props;
  const demoMediaRef = useRef();

  useEffect(() => {
    if (stream) {
      const demoMedia = demoMediaRef.current;
      demoMedia.srcObject = stream;

      demoMedia.onloadedmetadata = () => {
        demoMedia.play();
      };
    }
  }, [stream]);

  return (
    <div
      style={visible ? styles.mediaContainer : null}
      className="background_secondary_color"
    >
      {includeVideo ? (
        <video
          style={styles.mediaElement}
          ref={demoMediaRef}
          autoPlay
          muted={muted}
        />
      ) : (
        <audio
          controls={visible}
          style={visible ? styles.mediaElement : null}
          ref={demoMediaRef}
          autoPlay
          muted={muted}
        />
      )}
    </div>
  );
};

export default MediaView;

MediaView.propTypes = {
  stream: PropTypes.object,
  muted: PropTypes.bool,
  visible: PropTypes.bool, // enable/disable UI
};

MediaView.defaultProps = {
  visible: true,
};
