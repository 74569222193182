/**
 * Manage us1er-controlled settings and parameters
 * Dynamically render sub-section scrollbar to prevent document scrollbar
 *
 * By default, any settings change takes effect immediately without a form 'submit'
 * Exception: NotificationsSettings
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';

import CommunitySelector from './components/CommunitySelectorDialog/components/CommunitySelector';
import NotificationsSettings from './components/NotificationsSettings';
import MenuPositionSelector from './components/MenuPositionSelector';
import DisplayLanguageSelector from './components/DisplayLanguageSelector';
import MembershipsList from './components/MembershipsList';
import { getCommunity, switchCommunity } from 'lib/api/communityApi';
import useSettingsCategory from './hooks/useSettingsCategory';
import AutoStartControl from './components/AutoStartControl';
import { t } from 'lib/translation/trans';
import ButtonStd from 'components/utils/ButtonStd';
import { useHistory } from 'react-router-dom';
import { pwa } from 'lib/classes/PWA';
import AccountZapper from './components/AccountZapper';

const useStyles = makeStyles(theme => ({
  fixedSection: {
    marginBottom: '1rem',
  },
  overflow: {
    //height: calculate dynamically,
    overflow: 'auto', //automatically generate scrollbar
  },
}));

export default function SettingsList() {
  const [open, setOpen] = useState(false);
  const [fixedSectionHeight, setFixedSectionHeight] = useState(null);
  const t1 = useSelector(state => state.lang.langMap);
  const totalAvailableHeight = useSelector(
    state => state.ui.layout.mainContentHeight
  );
  const micApproved = useSelector(state => state.ui.pSession.micApproved);
  const { user } = useSelector(state => state.user.auth);
  const dispatch = useDispatch();

  const classes = useStyles();
  const fixedSectionRef = useRef();
  const { screen_name, name: userId } = {
    ...{ screenName: null, name: null },
    ...user,
  };
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const history = useHistory();

  const toggleOpen = item => {
    setOpen(item === open ? null : item);
  };

  const handleSubmit = () => setOpen(null);

  const settingsCategory = useSettingsCategory({ open, toggleOpen });

  // measure the fixed component height
  useEffect(() => {
    // note: margins of children may extend beyond fixedSectionRef
    const computedStyle = getComputedStyle(fixedSectionRef.current);
    const marginTop = parseInt(computedStyle?.marginTop);
    const marginBottom = parseInt(computedStyle?.marginBottom);
    setFixedSectionHeight(
      fixedSectionRef.current.clientHeight + marginTop + marginBottom
    );
  }, []);

  // switch to joined community, and expand "Memberships" panel
  const handleJoined = joinedPartition => {
    getCommunity(joinedPartition).then(c => {
      c?.partition && switchCommunity(c);
      toggleOpen('Memberships');
    });
  };
  // using sms or web-push notifications
  const channel = 'push'; // sms temporarily bypassed

  const notificationsAvailable =
    channel === 'sms' || pwa.notificationsPermissionSupported;
  const notificationsEnabled =
    channel === 'sms'
      ? user?.notifications.consent === '+'
      : Boolean(pwa.pushSubscription);

  return (
    <>
      <Typography
        ref={fixedSectionRef}
        variant="h6"
        classes={{ root: classes.fixedSection }}
      >
        {t1.T$_Settings}
      </Typography>

      {/*if scrollbar is necessary, isolate it to the overflow div rather than to the full document */}
      <div
        className={classes.overflow}
        style={{ height: totalAvailableHeight - fixedSectionHeight }}
      >
        {/* display screen name when panel is closed */}
        {settingsCategory(t1.T$_Account, screen_name, [
          { title: t1.T$_User_ID, content: userId },
          { title: t1.T$_Screen_Name, content: screen_name },
          {
            title: 'Remove My Account',
            content: <AccountZapper />,
          },
        ])}

        {/* Support sms and web-push notification modes */}
        {notificationsAvailable
          ? settingsCategory(
              t1.T$_Notifications,
              notificationsEnabled ? t1.T$_Enabled : t1.T$_Disabled,
              [
                {
                  title: null,
                  content: (
                    <NotificationsSettings
                      displayAs="form"
                      channel={channel}
                      onSubmit={handleSubmit}
                    />
                  ),
                },
              ]
            )
          : settingsCategory(t1.T$_Notifications, t1.T$_Not_Available, [])}

        {settingsCategory(t1.T$_Preferences, null, [
          xs
            ? null
            : { title: t1.T$_Main_Menu, content: <MenuPositionSelector /> }, // do not offer while xs
          {
            title: t1.T$_Display_Language,
            content: <DisplayLanguageSelector />,
          },
          {
            title: t1.T$_Auto_Start,
            content: (
              <AutoStartControl
                items={[
                  { appliance: 'Recorder' },
                  { appliance: 'Player' },
                  { appliance: 'LiveCall' },
                ]}
                format="checkboxes"
              />
            ),
          },
        ])}
        {settingsCategory(t1.T$_My_Memberships, t1.T$_Your_Communities, [
          { title: null, content: <MembershipsList /> },
        ])}
        {settingsCategory(t1.T$_Join, t1.T$_Other_Communities, [
          {
            title: null,
            content: (
              <CommunitySelector
                format="tree"
                excludeMemberships={true}
                onJoined={handleJoined}
              />
            ),
          },
        ])}
        {/*settingsCategory('Diagnostics', null, [
          {
            title: 'Debug',
            content: (
              <ButtonStd
                onClick={() => {
                  history.push('/debug');
                }}
                label={t('Launch')}
                color="secondary"
                centered
              />
            ),
          },
        ]) */}
      </div>
    </>
  );
}
