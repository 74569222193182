import React from 'react';
import { msgUrl } from 'lib/api/messageApi';
import { t } from 'lib/translation/trans';
import ClipboardDialog from 'components/ClipboardDialog';
import { getClipboardText } from 'containers/MessageRecorder/lib/outgoingMessageUtils';

export default function ShareActionDialog({ open, onClose, message }) {
  const handleClose = () => {
    onClose && onClose();
  };

  const heading = t('Share_This_Message');
  const body = `${t('Use_the_button_to_copy_a_link_to_this_message')}.  ${t(
    'Paste_it_to_social_media_or_email'
  )}.`;
  const title = `Message:  ${message.title}`;

  const clipboardText = getClipboardText({
    type: message?.type,
    title: message?.title,
    linkText: msgUrl(message),
  });

  return (
    <ClipboardDialog
      open={open}
      heading={heading}
      body={body}
      title={title}
      payload={clipboardText}
      onCancel={handleClose}
      onCopy={handleClose}
    />
  );
}
