/**
 * Render tabs menu for Login/Registration
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TabsStd from 'components/utils/TabsStd';

const useStyles = makeStyles(theme => ({}));

const LogRegTabs = ({ tabItems, onChange }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // report initial tab state
    tabItems && onChange && onChange(tabItems[tabValue]?.key); // report the page key
  }, []);

  // report changes to tab selection
  const handleTabClick = value => {
    if (value !== tabValue) {
      setTabValue(value);
      onChange && onChange(tabItems[value].key); // report the page key
    }
  };

  return tabItems ? (
    <TabsStd
      classes={classes}
      items={tabItems}
      value={tabValue}
      onChange={handleTabClick}
    />
  ) : null;
};

LogRegTabs.propTypes = {
  //    pages: PropTypes.arrayOf(PropTypes.shape({
  //        key: PropTypes.string,
  //        title: PropTypes.string,
  //    })),
  tabItems: PropTypes.array,
  onChange: PropTypes.func,
};

export default LogRegTabs;
