/**
 * LiveCallAlert
 * Provides notification and controls upon issuance or receipt of a LiveCall invitation
 *
 *  caller initiated an 'invite'
 *  callee received one or more 'invite's
 *
 * Notes:
 *  - The transition upon opening must not be repeated, so care not to re-render component is taken.
 *  - Alert remains open
 *      for caller while call progress is in 'invite' state
 *      for recipient while call progress is 'ready' and 'invites' exist
 *  */

import React, { useEffect, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { webRTCActions } from 'store/webRTC-slice';
import {} from '@material-ui/core';
import SnackbarAlert from 'components/utils/SnackbarAlert';
import CalleeActions from './components/CalleeActions';
import CallerActions from './components/CallerActions';

import { getCallManager } from 'lib/api/webRTCApi';
const callProc = getCallManager();

export default memo(function LiveCallAlert({ onStatusChange }) {
  const call = useSelector(state => state.webRTC.call);
  const participants = useSelector(state => state.webRTC.participants);
  const invites = useSelector(state => state.webRTC.invites);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const status = useSelector(state => state.user.status);
  const userIsAwake = status === 'auth';

  const dispatch = useDispatch();

  const remoteScreenName = participants.remote?.screenName;
  const { progress } = call;
  const role = participants.local.role || 'callee'; // callee by default
  const isCaller = role === 'caller';

  const openSnackbar = Boolean(
    isCaller
      ? ['invited'].includes(progress) && Boolean(remoteScreenName) // invite issued
      : ['ready'].includes(progress) && invites.length // inbound invites queued
  );

  useEffect(() => {
    if (openSnackbar && !userIsAwake) {
      // caller has fallen asleep while alert is open
      // force termination of the call and clear the invites queue
      callProc.handleLocalEvent({ type: 'reset' });
      dispatch(webRTCActions.clearInvites());
    }
  }, [openSnackbar, userIsAwake]);

  // lift-up status change
  useEffect(() => {
    onStatusChange && onStatusChange(openSnackbar);
  }, [openSnackbar]);

  // prevent re-rendering unless openSnackbar changes
  const getContent = useCallback(() => {
    return openSnackbar ? (
      isCaller ? (
        <CallerActions />
      ) : (
        <CalleeActions />
      )
    ) : null;
  }, [openSnackbar]);

  return <SnackbarAlert content={getContent()} />;
});
