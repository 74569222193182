/**
 * AttributesSelector
 * //@@ NOTE: redundancy with RegistrationManager, consider refactoring

 *
 * Derived/simplified from RegistrationManager in order to support
 * attribute updating after registration.
 *
 */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from 'store/user-slice';
import LogRegSection from 'containers/RegistrationManager/components/LogRegSection';
import { updatedAggregateUserParams } from 'lib/api/communityApi';
import { patchHvnUser } from 'lib/api/userApi';
import { Button, CircularProgress } from '@material-ui/core';

//@@ duplicated in RegistrationManager
import {
  regHelpers,
  RegPages,
} from 'containers/RegistrationManager/lib/registrationUtils';
import { t } from 'lib/translation/trans';
import { makeArray } from 'lib/utils/utils';

const readPagesFromLocal = true;
const regUtils = regHelpers();

// Community-specific initialization
// if community partition is not specified in prop, use value from redux
// if still none specified, use defaultPartition as default
const AttributesSelector = props => {
  const { community, onComplete } = props;
  const setUpdateCnt = useState(0)[1]; // variable never read, setter used to cause re-render

  // create a reference to hold the pages object, to be instantiated in useEffect
  const pagesObj = useRef();
  const pg = pagesObj.current; // a shorthand reference to the  pages object

  const [progress, setProgress] = useState(false);
  const user = useSelector(state => state.user.auth.user);
  const { partition } = typeof community === 'object' ? community : {};

  const isMember = user?.memberships.includes(partition);
  const { langCode, langMap: t1 } = useSelector(state => state.lang);

  const reRender = () => setUpdateCnt(cnt => ++cnt);
  const dispatch = useDispatch();
  const userParamsSection = {
    ...regUtils.getSection('userParams', pg?.trans),
    title: null,
  };

  // initialize the pages object
  useEffect(() => {
    pagesObj.current = new RegPages({ partition, user, refreshFn: reRender });
  }, [partition]);

  // re-translate pg when langCode changes
  useEffect(() => {
    pg && pg.translate(langCode);
  }, [langCode]);

  // add the new membership, prevent duplication
  const addMembership = (memberships, partition) => [
    ...user.memberships.filter(m => m !== partition),
    partition,
  ];

  const handleFieldChange = (key, value) => {
    pg && pg.setFieldValue({ key, value });
  };

  // non-member user is patched to apply membership
  // member user is only patched if attributes have changed
  const handleConfirm = () => {
    const exit = partition => {
      setProgress(false);
      onComplete && onComplete(partition);
    };

    const processUpdate = (user, updates) => {
      if (updates.params) {
        const memberParams = {
          params: updates.params.partitionParams.find(
            pp => pp.partition === partition
          )?.params,
        };
        dispatch(
          userActions.updateMember({ userId: user.id, update: memberParams })
        );
      }
      setProgress(true);
      patchHvnUser(user, updates)
        .then(() => exit(partitionToJoin))
        .catch(err => {
          console.log('patchHvnUser error:', err);
          exit();
        });
    };

    const updates = {};
    let partitionToJoin = null;

    // append membership if not already
    if (!isMember) {
      updates.field_memberships = addMembership(user.memberships, partition);
      partitionToJoin = partition;
    }

    if (pg.diff()) {
      updates.params = updatedAggregateUserParams(
        partition,
        user,
        regUtils.getSectionValues('userParams', pg.eng)
      );
    }

    if (updates.field_memberships || updates.params) {
      // process the update here, or via callback
      if (props.processNewMember) {
        props.processNewMember(user, updates);
      } else {
        processUpdate(user, updates);
      }
    } else {
      // no changes
      exit();
    }
  };

  const handleCancel = () => onComplete && onComplete(null);

  //@@ validation is bypassed for now
  const validateField = fieldData => {
    let errObj = null;
    // iterate over fields, break out on first exception
    fieldData.every(({ key, value }) => {
      // required fields have already been checked for non-null
      switch (key) {
        default:
          break;
      }
      errObj && (errObj = { ...errObj, key });
      return !Boolean(errObj);
    });
    return errObj;
  };

  if (progress) {
    return <CircularProgress />;
  }

  // check if all required fields have been populated
  const areFieldRequirementsSatisfied = () =>
    makeArray(userParamsSection.fields).reduce((acc2, field) => {
      const fieldOk = Boolean(!field?.required || field?.value);
      return acc2 && fieldOk;
    }, true);

  const enableSubmit = areFieldRequirementsSatisfied();
  return userParamsSection ? (
    <>
      <LogRegSection
        s={userParamsSection}
        onFieldChange={handleFieldChange}
        noWrap
        zen
      />
      <Button
        disabled={!enableSubmit}
        style={{ marginTop: 15 }}
        size="small"
        color="secondary"
        variant="contained"
        onClick={handleConfirm}
      >
        {t('Confirm')}
      </Button>
      <Button
        style={{ marginTop: 15, marginLeft: 15 }}
        size="small"
        color="primary"
        variant="contained"
        onClick={handleCancel}
      >
        {t('Cancel')}
      </Button>
    </>
  ) : null;
};

AttributesSelector.propTypes = {
  onComplete: PropTypes.func, // invoked upon success or cancel
  processNewMember: PropTypes.func, // optional callback provided by parent to bypass processing here
};

AttributesSelector.defaultProps = {};

export default AttributesSelector;
