/*
 * An mp3 voice recorder
 * with visualization and controls including management of playback
 *
 * Features
 * --------------------------------
 * - obtain and persist microphone approval status
 * - detect quick-decline if browser is blocking mic use
 *
 * omissions:
 * - implement auto-record
 */

import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import BorderedBlock from 'components/utils/BorderedBlock';
import AudioRecorder from './components/react-mic-gold/AudioRecorder';
import { seekMicApproval } from 'lib/utils/seekMicApproval';
import { makeArray, timeout } from 'lib/utils/utils';
import ProblemReport from 'components/utils/ProblemReport';
import ApplianceControls from 'components/utils/ApplianceControls';
import ActionControls from './lib/ActionControlsClass';
import { recStates, recIconMap, vrs } from './lib/vrecConfig';

// fixed filename for use in download to local device
const downloadFilename = 'OkSayit.mp3';

const useStyles = makeStyles(theme => ({
  outerWrapper: {
    maxWidth: 600,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },

  applianceOuterWrapper: {
    /* width derived from props.settings */
    overflow: 'hidden',
    marginTop: 10,
  },
  applianceInnerWrapper: {
    /* height derived from props.settings */
    width: '100%',
  },

  containerFollowingRow: {
    marginTop: 0,
  },
}));

const VoiceRecorder = forwardRef((props, ref) => {
  const {
    onActionEvt,
    onRecordingCaptured,
    onCancel, //parent can clear title and any in-progress status
    readyNoCancel,
    applianceTitle,
    bannerText,
    children,
    settings,
    hideVisualization,
    actionsSpecOverride,
    autoStart,
    actionBlockTitle,
    customFooter,
    customBody,
    actionButtonExclusionList,
    actionClasses,
  } = props;

  const {
    defaultProps: playerProps,
    setSrc,
    play,
    pause,
    handleAudioEvent,
  } = useVoicePlayerHelper({ playerKey: 'RecorderPlayback' });

  //****  state --------------------------------------------------------------------
  const [recState, setRecState] = useState(null);
  const [recording, setRecording] = useState({
    blob: null,
    blobURL: null,
    durationSec: 0,
  });
  const [startTimeMs, setStartTimeMs] = useState(0); //ms.
  const [enableWarning, setEnableWarning] = useState(false); //warn user of approaching limit
  const [actionsKey, setActionsKey] = useState(0); // increment to re-render the action buttons
  const [validationExceptions, setValidationExceptions] = useState(null);
  const [actionControls, setActionControls] = useState(
    new ActionControls(
      recStates,
      ['Enable', 'Record', 'Stop', 'Review', 'Pause', 'Download', 'Cancel'],
      recIconMap,
      handleRecorderActionClick
    )
  );

  // certain callbacks (e.g. when passed to setTimeout, ActionControls(), etc.)
  // will reference initial value of state rather than real time values.
  //
  // real time state
  // enable reference to up-to-date state from within setTimeout callbacks
  // per.: https://medium.com/programming-essentials/how-to-access-the-state-in-settimeout-inside-a-react-function-component-39a9f031c76f
  const recStateRef = useRef(recState);
  recStateRef.current = recState;

  //****  state --------------------------------------------------------------------

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const { micApproved } = useSelector(state => state.ui.pSession);

  const startTimeMsRef = useRef(startTimeMs);
  startTimeMsRef.current = startTimeMs;
  const downloadRef = useRef();
  const beepRef = useRef(); // uses Audio instead of uifx because event handlers are needed

  // differential version of ready state depending on exit strategy
  const readyState = readyNoCancel ? vrs.READY_NOCANCEL : vrs.READY;
  const isRecording = () =>
    [vrs.COMMENCING, vrs.RECORDING].includes(recStateRef.current);
  const isRequestingPermission = () =>
    [vrs.APPROVAL_PENDING].includes(recStateRef.current);

  // toggle between player/recorder visualization
  const includeRecorder = ![
    vrs.RECORDED,
    vrs.PLAYING,
    vrs.DOWNLOADING,
    vrs.HIDE_ACTIONS,
  ].includes(recStateRef.current);

  const isProblem = vrs.PROBLEM === recStateRef.current;

  // timers
  const finishingWatchdogTimerId = useRef(null); //watchdog timeout`
  const maxRecordingTimeoutId = useRef(null); // timeout for recording duration
  const maxRecordingTimeoutWarningId = useRef(null); // timeout for warning prior to forced termination
  const isFinishing = recStateRef.current === vrs.FINISHING;
  const { minRecordingSec, maxRecordingSec, maxRecordingWarningSec } =
    settings.duration;

  // viewport dependent parameters
  const applianceOuterWrapperWidth = xs
    ? settings.size.xs.width
    : settings.size.std.width;
  const applianceInnerWrapperHeight = settings.size.height;

  // do any of the direct children assert the actionsHide prop?
  const actionsHide = React.Children.toArray(children).some(
    c => c.props.actionsHide
  );

  // the current language and translation map
  const t1Ref = useRef();
  const { langCode, langMap: t1 } = useSelector(state => state.lang);
  t1Ref.current = t1;

  const beepPlay = () => {
    if (beepRef.current) {
      beepRef.current
        .play()
        .then(() => console.log('beepPlay.'))
        .catch(err => {
          console.log('beepPlay error:', err);
        });
    } else {
      console.log('beepPlay error, no beepRef.current');
    }
  };

  //@@ initialize state based on mic status
  useEffect(() => {
    //console.log(`VoiceRecorder initialized state=${micApproved ? (readyNoCancel ? vrs.READY_NOCANCEL : vrs.READY) : vrs.APPROVAL_NEEDED}`); //@@ diagnostic
    dispatch(uiActions.voicePlayerPause()); //pause any play operation

    setRecState(
      micApproved
        ? readyNoCancel
          ? vrs.READY_NOCANCEL
          : vrs.READY
        : vrs.APPROVAL_NEEDED
    );
    micApproved && autoStart && beepPlay(); // start recording automatically
    return () => {
      /* dismount */
      clearTimeout(maxRecordingTimeoutId.current); // prevent possibly redundant firing of maxRecordingTimeout
      clearTimeout(maxRecordingTimeoutWarningId.current); // prevent possibly redundant firing of maxRecordingTimeout
    };
  }, []);

  //translate exceptions when langCode changes
  useEffect(() => {
    setValidationExceptions(old =>
      old ? old.map(({ key }) => ({ key, msg: exceptionMsg(key) })) : null
    );
  }, [langCode]);

  // initialize sound effect
  useEffect(() => {
    if (beepRef.current) {
      beepRef.current.addEventListener('ended', () => handleBeepEnded('v2'));
      beepRef.current.addEventListener('error', () => handleBeepError('v2'));
      beepRef.current.addEventListener('canplay', () =>
        handleBeepCanplay('v2')
      );
      beepRef.current.volume = 0.1;
    }

    return () => {
      beepRef.current &&
        beepRef.current.removeEventListener('canplay', handleBeepCanplay('v2'));
      beepRef.current &&
        beepRef.current.removeEventListener('error', handleBeepError('v2'));
      beepRef.current &&
        beepRef.current.removeEventListener('ended', handleBeepEnded('v2'));
    };
  }, []);

  // provide watchdog to prevent getting stuck in vrs.FINISHING
  // expected sequence: vrs.FINISHING -> !recorderProps.isRecording -> recorderProps.onStopRecording -> vrs.RECORDED
  // observed that it is possible to miss onStopRecording event
  useEffect(() => {
    if (isFinishing) {
      // onset of vrs.FINISHING
      finishingWatchdogTimerId.current = setTimeout(() => {
        if (isFinishing) {
          //still?
          handleStopRecording(null);
        }
      }, 1000);
    }
    return () => {
      clearTimeout(finishingWatchdogTimerId.current);
    };
  }, [isFinishing]);

  // re-render actions when reassigned buttons change
  useEffect(() => {
    setActionsKey(old => old + 1); //re-render the actions buttons
  }, [props.reAssignButtons]);

  //****  misc. event handlers **************************************************************

  // check if microphone has been approved
  // NOTE: does not employ validationExceptions
  const handleMicApprovedStatus = code => {
    const approved = code === 0;
    // only take action if event occurred in an expected recState
    if (
      [vrs.APPROVAL_NEEDED, vrs.APPROVAL_PENDING].includes(recStateRef.current)
    ) {
      // set recState as well as redux state
      setRecState(approved ? readyState : vrs.APPROVAL_NEEDED);
      dispatch(uiActions.micApprovedSetStatus(approved));

      //launch explanation if approval not obtained
      if (!approved) {
        const msg = () => {
          switch (code) {
            case 1:
              return t1Ref.current.T$_You_have_blocked_use_of_your_microphone;
            case 2:
              return t1Ref.current
                .T$_Your_browser_is_blocking_use_of_your_microphone_Use_your_browser_Settings_to_correct_this_issue;
            case 3:
              return t1Ref.current
                .T$_Please_try_again_Approve_use_of_your_microphone_to_continue;
            default:
              return t1Ref.current
                .T$_There_was_a_problem_obtaining_permission_to_use_your_microphone;
          }
        };
        dispatch(uiActions.dialogSet(msg()));
      }
    }
  };

  // compose an exception message for the specified key
  const exceptionMsg = key => {
    switch (key) {
      case 'duration':
        return `${t1.T$_Please_record_at_least} ${minRecordingSec} ${t1.T$_sec}.`;
      default:
        return null;
    }
  };

  const handleStopRecording = blobObject => {
    const { blob, blobURL } = blobObject || { blob: null, blobURL: null };
    const durationSec = Math.floor(
      (Date.now() - startTimeMsRef.current) / 1000
    ); // direct reference to startTimeMs failed to get current value

    setRecording({ blob, blobURL, durationSec });
    setSrc(blobURL);
    onRecordingCaptured && onRecordingCaptured({ blob, blobURL, durationSec });

    if (durationSec < minRecordingSec) {
      const key = 'duration';
      setValidationExceptions([{ key, msg: exceptionMsg(key) }]);
      setRecState(vrs.PROBLEM);
    } else {
      setRecState(vrs.RECORDED);
    }
  };

  // beep is done, start recording
  const handleBeepEnded = ver => {
    console.log(`handleBeepEnded ${ver}`);
    const obj = { recState: recStateRef.current };
    // commence recording
    setRecState(vrs.COMMENCING);
    dispatch(uiActions.setRecorderBusy(true)); //notify the store

    //enforce record time limit
    maxRecordingTimeoutId.current = setTimeout(() => {
      isRecording() && handleStopClick();
    }, maxRecordingSec * 1000);

    //warn of approaching time limit
    maxRecordingTimeoutWarningId.current = setTimeout(() => {
      isRecording() && setEnableWarning(true);
      dispatch(
        uiActions.alertSet({
          message: t1.T$_Approaching_maximum_record_time,
          severity: 'warning',
        })
      );
    }, (maxRecordingSec - maxRecordingWarningSec) * 1000);
  };

  //@@diagnostics: (disabled )
  const handleBeepError = ver => null && console.log(`handleBeepError ${ver}`);
  const handleBeepCanplay = ver =>
    null && console.log(`handleBeepCanplay ${ver}`);

  const handleStartRecording = () => {
    setRecState(vrs.RECORDING);
    setStartTimeMs(Date.now());
  };

  // recorder has re-mounted and is ready to record again
  const handleReRecord = () => {
    if (recStateRef.current === vrs.RE_RECORD) {
      // commence beep prior to recording
      beepPlay();
    }
  };

  const handleProblemsClose = () => {
    setValidationExceptions(null);
    setRecState(readyState);
  };

  //****  click event handlers **************************************************************

  function handleRecorderActionClick(button) {
    if (button === 'Enable') {
      handleEnableClick();
    } else if (button === 'Record') {
      handleRecordClick();
    } else if (button === 'Stop') {
      handleStopClick();
    } else if (button === 'Review') {
      handleReviewClick();
    } else if (button === 'Pause') {
      handlePauseClick();
    } else if (button === 'Cancel') {
      handleCancelClick();
    } else if (button === 'Download') {
      handleDownloadClick();
    }

    // propagate event to parent
    onActionEvt && onActionEvt(button);
  }

  const handlePlaybackEvent = e => {
    ['pause', 'ended'].includes(e) && setRecState(vrs.RECORDED);
    handleAudioEvent(e); // invoke useVoicePlayerHelper handler
  };

  // obtain permission to use microphone (if not alreasy obtained )
  const handleEnableClick = () => {
    // initiate request for mic. approval
    setRecState(vrs.APPROVAL_PENDING);
    seekMicApproval()
      .then(rslt => {
        /*
                Chrome observations:
                approved:   handleEnableClick rslt: {stream: MediaStream, code: 0, name: 'approved'}
                dismissed:  handleEnableClick rslt: {stream: null, code: 3, name: 'NotAllowedErrorDismissed'}
                blocked:    handleEnableClick rslt: {stream: null, code: 1, name: 'NotAllowedError'}
                priorBlock: handleEnableClick rslt: {stream: null, code: 2, name: 'NotAllowedErrorQuick'}

                Firefox
                approved  handleEnableClick rslt:  Object { stream: MediaStream, code: 0, name: "approved" }
                dismissed handleEnableClick rslt:  Object { stream: null, code: 1, name: "NotAllowedError" }
                blocked:  handleEnableClick rslt:  Object { stream: null, code: 1, name: "NotAllowedError" }
                priorBlock: handleEnableClick rslt: Object { stream: null, code: 2, name: "NotAllowedErrorQuick" }
                */

        const { code, name } = rslt;
        switch (code) {
          case 0: //approved
            handleMicApprovedStatus(code);
            break;
          default: //NOT approved
            console.log(`handleEnableClick: ${code} -- ${name}`);
            handleMicApprovedStatus(code);
            break;
        }
      })
      .catch(err => {
        console.log(`seekMicApproval: `, err);
        handleMicApprovedStatus(99);
      });
  };

  // expose method to parent
  const requestStartRecording = () => micApproved && handleRecordClick();
  useImperativeHandle(ref, () => ({
    requestStartRecording,
  }));

  // first time recording: initiate a beep
  // subsequent: must remount recorder. Wait for mount recorder again
  const handleRecordClick = () => {
    switch (recStateRef.current) {
      case 'Ready':
      case 'ReadyNoCancel':
        beepPlay(); // beep 'ended' event will start recorder
        break;
      case 'Recorded':
        // re-render recorder
        // but don't start recording until recorder is mounted
        setRecState(vrs.RE_RECORD);
        break;
      default:
        //unexpected
        break;
    }
  };

  const handleStopClick = () => {
    // disable recording limit timers
    clearTimeout(maxRecordingTimeoutId.current); // prevent possibly redundant firing of maxRecordingTimeout
    clearTimeout(maxRecordingTimeoutWarningId.current); // prevent possibly redundant firing of maxRecordingTimeout
    maxRecordingTimeoutId.current = null;
    maxRecordingTimeoutWarningId.current = null;
    setRecState(vrs.FINISHING);
    setEnableWarning(false);
    dispatch(uiActions.setRecorderBusy(false)); //notify the store
  };

  const handleReviewClick = () => {
    play();
    setRecState(vrs.PLAYING);
  };

  const handlePauseClick = () => {
    pause();
    setRecState(vrs.RECORDED);
  };

  const handleDownloadClick = () => {
    setRecState(vrs.DOWNLOADING);
    downloadRef.current && downloadRef.current.click();
    // no event to detect download result, so employ a timeout
    timeout(1000).then(() => setRecState(vrs.READY));
  };

  const handleCancelClick = () => {
    setRecState(readyState);
    setRecording({ blob: null, blobURL: null, durationSec: 0 });
    setSrc(null);
    setValidationExceptions(null);
    dispatch(
      uiActions.alertSet({
        message: `Your recording is cancelled.`,
        severity: 'warning',
      })
    );
    props.onExit && props.onExit(null);
    onCancel && onCancel();
  };

  //****  rendering **************************************************************

  const recorderProps = () => ({
    isRecording: isRecording(),
    isRequestingPermission: false,
    height: settings.size.height,
    enableWarning,
    onStartRecording: handleStartRecording,
    onStopRecording: blobObject => handleStopRecording(blobObject),
    onRecorderRendered: handleReRecord,
  });

  // render the action buttons with an optional labeled border
  const renderActionControls = () => {
    // lookup the state-dependent action spec, apply button filter if specified
    const actionsSpec = () => {
      const actionsSpecStandard = actionControls.actionsSpec(
        recStateRef.current,
        props.reAssignButtons
      );
      const buttons = actionsSpecStandard.buttons.filter(
        f => !actionButtonExclusionList.includes(f.label)
      );
      const actionsSpecFiltered = { ...actionsSpecStandard, buttons };
      return actionsSpecOverride || actionsSpecFiltered;
    };

    const applianceControls = (
      <ApplianceControls legends={true} groups={false} {...actionsSpec()} />
    );
    const border = (
      <BorderedBlock
        labelTop={actionBlockTitle}
        classes={actionClasses}
      ></BorderedBlock>
    );

    return Boolean(actionBlockTitle) ? (
      // a title has been specified in the props, apply border
      React.cloneElement(border, null, applianceControls)
    ) : (
      // no title, render without a border
      <div className={actionClasses?.blockContent}>
        <ApplianceControls legends={true} groups={false} {...actionsSpec()} />
      </div>
    );
  };

  // render recorder/playback visualizer and action controls
  return (
    <div className={classes.outerWrapper}>
      <BorderedBlock labelTop={applianceTitle} classes={classes}>
        <Grid container direction="column" alignItems="center">
          {bannerText && <Grid item>{bannerText}</Grid>}

          <Grid
            item
            classes={{ root: classes.applianceOuterWrapper }}
            style={{
              width: applianceOuterWrapperWidth,
              border: hideVisualization ? '' : '1px grey solid',
            }}
          >
            <Grid
              container
              direction="column"
              justify="center"
              classes={{ root: classes.applianceInnerWrapper }}
              style={{
                height: hideVisualization ? 0 : applianceInnerWrapperHeight,
              }}
            >
              {includeRecorder ? (
                /* player or recorder, one at a time */
                <AudioRecorder {...recorderProps()} />
              ) : (
                <VoicePlayer
                  {...playerProps}
                  uiItems={['progressPretto']}
                  onAudioEvent={handlePlaybackEvent}
                />
              )}
            </Grid>
          </Grid>

          {children &&
            makeArray(children).map((child, ix) => <div key={ix}>{child}</div>)}

          {validationExceptions && (
            <Grid classes={{ root: classes.containerFollowingRow }} item>
              <ProblemReport
                exceptions={validationExceptions}
                onExit={handleProblemsClose}
              />
            </Grid>
          )}
          {customBody && !isProblem && (
            <Grid classes={{ root: classes.containerFollowingRow }} item>
              {customBody}
            </Grid>
          )}

          {actionControls && !isProblem && (
            <Grid classes={{ root: classes.containerFollowingRow }} item>
              {renderActionControls()}
            </Grid>
          )}
          {customFooter && !isProblem && (
            <Grid classes={{ root: classes.containerFollowingRow }} item>
              {customFooter}
            </Grid>
          )}
        </Grid>
      </BorderedBlock>

      {/* render an anchor tag capable of downloading the recorded file */}
      <a ref={downloadRef} href={recording.blobURL} download={downloadFilename}>
        {' '}
      </a>

      {/* render invisible audio element for sound effect */}
      <audio ref={beepRef} src="/audio/beep1.mp3" />
    </div>
  );
});

VoiceRecorder.propTypes = {
  onActionEvt: PropTypes.func,
  onRecordingCaptured: PropTypes.func,
  onCancel: PropTypes.func,
  readyNoCancel: PropTypes.bool, // disable 'Cancel' while 'Ready'
  settings: PropTypes.object,
  autoStart: PropTypes.bool,
  //btnList: PropTypes.arrayOf(PropTypes.string),
  reAssignButtons: PropTypes.object,
  //isAuthenticated: PropTypes.bool,
  actionsSpecOverride: PropTypes.object,
  actionButtonExclusionList: PropTypes.array, // list of button labels to exclude

  // decorations control
  applianceTitle: PropTypes.string,
  bannerText: PropTypes.string,
  hideVisualization: PropTypes.bool,
  actionBlockTitle: PropTypes.string, // if truthy, apply border and title to actions block
  customFooter: PropTypes.node, // optional content to be pladed at bottom of VoiceRecorder
  customBody: PropTypes.node, // optional content to be pladed within the VoiceRecorder
};

VoiceRecorder.defaultProps = {
  readyNoCancel: false,
  settings: {
    recorderType: 'react-mic-gold',
    size: {
      std: { width: 300 },
      xs: { width: 200 },
      height: 60,
    },
    duration: {
      minRecordingSec: 5, // minimum allowable duration of a recording
      maxRecordingSec: 60, // maximum duration of a recording
      maxRecordingWarningSec: 5, // warning trigger prior to impending limit
    },
  },
  autoStart: false,
  reAssignButtons: {},
  //btnList: undefined,
  //isAuthenticated: false,
  actionsSpecOverride: null,
  applianceTitle: 'Voice Recorder',
  bannerText: null,
  hideVisualization: false,
  actionBlockTitle: null,
  customFooter: null,
  customBody: null,
  actionButtonExclusionList: [],
};

export default VoiceRecorder;
