/**
 * A clone of webRTCApi.js but with dlog statements added
 *
 * webRTCAPI
 * WebRTC  and call processing utilities
 *
 * Workflow:
 *    1. user (caller) sends an 'invite' message (with optional voice recording)
 *    2. callee receives notice of invite and indicates 'accept'
 *    3. caller receives notice of accept and indicates 'connect'
 *    4. cut through of live audio paths takes place
 *    5. one party indicates 'hangup'
 *    6. live audio path terminates
 *    7. opposite party receives notice of hangup
 *    8. call sequence is ended
 *
 * Conventions:
 * - only this module manipulates call progress state
 * - session configuration is performed once on first use
 * - typical call sequence
 */

import store from 'store';
import { dialogActions } from 'store/dialog-slice';
import { webRTCActions } from 'store/webRTC-slice';
import UIfx from 'uifx';
import { addLocalMessage, deleteMessageById, nextMsg } from './messageApi';
import sigServer from './signalingServer';
import { getPeer, isPeerRegistered, isBlacklisted } from './userApi';
import { makeArray } from 'lib/utils/utils';
import { sigServerConfig } from 'configs/config-hvn';
import { t } from 'lib/translation/trans';
import StreamProcessor, {
  getAudioTrackInfo,
  getAudioTrack,
} from 'lib/classes/StreamProcessor';
import PeerDataManager from 'lib/classes/PeerDataManager';

export let peerConnection = null; //local visibility
global.peerConnection = peerConnection; //@@ temporary dev
export let pdm = null;
export let localStreamProcessor = null;
export let remoteStreamProcessor = null;

const enableLiveCallLogging = false; //diagnostic only

global.lsp = () => localStreamProcessor; //@@ diagnostic
global.rsp = () => remoteStreamProcessor; //@@ diagnostic
global.pdm = () => pdm;

/**
 *
 * example use:
 * import { getCallManager } from '/lib/api/webRTCApi'
 * const callProc = getCallManager()
 * callProc.method() ...
 */
export function getCallManager() {
  // ref.: https://blog.addpipe.com/audio-constraints-getusermedia/#getusermediaaudioconstraints
  const defaultAudioConstraints = {
    autoGainControl: false,
    noiseSuppression: false,
    echoCancellation: true, // observed: setting echoCancellation false forces channelCount = 2
    channelCount: 1,
    latency: 0,
    sampleRate: 48000,
    sampleSize: 16,
    volume: 1.0,
  };

  // persistent user settings
  const persistentAudioConstraints = () => {
    const liveCallAudioSettings =
      store && store.getState().ui.pSession?.liveCallAudioSettings;
    return liveCallAudioSettings
      ? {
          autoGainControl: Boolean(liveCallAudioSettings?.autoGainControl),
          noiseSuppression: Boolean(liveCallAudioSettings?.noiseSuppression),
          echoCancellation: Boolean(liveCallAudioSettings?.echoCancellation),
        }
      : {};
  };

  const mediaConstraints = () => {
    const audioConstraints = {
      ...defaultAudioConstraints,
      ...persistentAudioConstraints(),
    };
    return {
      video: false,
      audio: audioConstraints,
    };
  };

  // transition to next call processing state
  const transitionEvent = (event, initiator = '*') =>
    store.dispatch(webRTCActions.transition({ event, initiator }));

  // notify peer of local event
  const notifyPeer = (event, peerId = null) =>
    sigServer.sendCallProcEvent({
      event: { ...event, userId: store.getState().user.auth.user?.id },
      recipientSocketId: getPeer(
        peerId || store.getState().webRTC.participants.remote.userId
      )?.socketId,
    });

  // convey message metadata to peer
  const convey = (type, title, parent) => {
    const newMessage = {
      type,
      isPublic: false,
      title,
      userId: store.getState().user.auth.user?.id,
      parentId: parent?.id,
      recipient: parent?.userId,
    };
    const recipientSocketId = getPeer(newMessage.recipient)?.socketId;
    sigServer.sendNewMessageNotification({
      message: newMessage,
      recipientSocketId,
    });
  };

  // Promise creates or re-uses a local microphone stream
  const getMicStream = () => {
    const { stream } = store.getState().webRTC;
    if (stream) {
      // re-using previous allocated stream & resources
      localStreamProcessor && localStreamProcessor.resume();
      return Promise.resolve(stream);
    } else {
      return navigator?.mediaDevices
        ?.getUserMedia(mediaConstraints())
        .then(micStream => {
          const micVolume =
            store.getState().ui.pSession.liveCallAudioSettings.micVolume;
          // for evaluation purposes, enable multiple VAD options
          localStreamProcessor = new StreamProcessor(
            'local',
            micStream,
            micVolume,
            ['Linto' /* , 'Hark', 'Jam3', 'Rms' */],
            'outputStream',
            false //diagnostic logging
          );
          //console.log("micStream:", getAudioTrackInfo(getAudioTrack(micStream)))
          store.dispatch(
            webRTCActions.setStream(localStreamProcessor.getOutputStream())
          );
          return micStream;
        });
    }
  };

  // Promise obtains or reuses TURN servers
  const getTurnServers = () => {
    const { turnServers } = store.getState().webRTC;
    if (turnServers) {
      return Promise.resolve(turnServers);
    } else {
      return fetch(`${sigServerConfig.serverUrl}/api/get-turn-credentials`)
        .then(response => response.json())
        .then(data => {
          const iceServers = data?.token?.iceServers;
          store.dispatch(webRTCActions.setTurnServers(iceServers));
          return iceServers;
        });
    }
  };

  const initialize = () => {
    peerConnectionDestroy(); // kill old peerConnection if it still exists
    return getMicStream()
      .then(() => getTurnServers())
      .then(() => Promise.resolve(createPeerConnection()))
      .catch(err => console.log('getCallManager().initialize failed:', err));
  };

  const peerConnectionDestroy = () => {
    remoteStreamProcessor && remoteStreamProcessor.destroy();
    localStreamProcessor && localStreamProcessor.suspend(); // may re-use next LiveCall
    pdm && pdm.destroy();
    peerConnection && peerConnection.close();

    remoteStreamProcessor = null;
    pdm = null;
    peerConnection = null;
  };

  // confirm that the requirements to proceed with call processing are met
  const checkRequirements = (context, remoteUserId) => {
    const { call } = store.getState().webRTC;
    const { isAuthenticated, auth } = store.getState().user;

    const acceptableInitialStates = {
      // outbound events
      checking: ['ready'], // confirm that local user can initiate a call now
      inviting: ['ready'],
      accepting: ['ready', 'invited'], //@@ TBD whether accepting a queued invite from dialogs list should be honored
      declined: ['*'],
      terminating: ['*'],

      //inbound events
      invited: ['*'], //process new invites regardless of call progress state
      accepted: ['invited'],
      declined: ['*'],
      terminated: ['*'],
    };

    // common requirements
    // * call progress must be context-appropriate
    // * must be logged-in
    // * both parties must be active
    // * caller cannot call self
    // * remote user must not be blacklisted
    const acceptableProgress = makeArray(acceptableInitialStates[context]);
    let requirements = [
      //local requirements
      { name: 'authenticated', test: () => isAuthenticated },
      {
        name: 'progress',
        test: () =>
          acceptableProgress.includes(call.progress) ||
          acceptableProgress.includes('*'),
      },

      //remote user requirements
      { name: 'active', test: () => isPeerRegistered(remoteUserId) },
      { name: 'self', test: () => auth.user.id !== remoteUserId },
      { name: 'blocked', test: () => !isBlacklisted(remoteUserId) },
    ];

    // example context-specific tests:
    switch (context) {
      case 'invited':
        //requirements.push({ name: 'progress', test: () => ['ready'].includes(call.progress) })
        break;
      default:
        break;
    }

    let details = '';
    // apply the list of requirements, find a failing test,  if any:
    const fail = requirements.find(r => {
      const ok = r.test();
      /*console.log(`${context}: ${r.name} = ${ok}`);  */
      details += `${ok ? '+' : '-'}${r.name}`;
      return !ok;
    });
    if (fail) {
      //console.log(`checkRequirements (${context}): ${details}`)
    }
    return fail
      ? { status: 'FAIL', name: fail?.name, details }
      : { status: 'OK', details };
  };

  // messages with voice recording that have call processing ramifications
  // (voice file management and conveyance of metadata have been previously handled)
  const handleOutboundMessage = message => {
    enableLiveCallLogging &&
      console.log(`LiveCall handleOutboundMessage type=${message?.type}`);
    switch (message?.type) {
      case 'invite':
        if (checkRequirements('inviting', message?.recipient).status === 'OK') {
          // on first execution, obtain persistent local stream and TURN servers
          // on each execution, obtain a new peerConnection
          // obtain a microphone stream

          // initialize stream and TURN here, perform rest of initialization for caller upon 'connect' event
          initialize()
            .then(items => {
              // upon sending an invite, user is no longer available to continue
              // with any invites or prior acceptances
              makeUserUnavailable(message.recipient);

              // set up the call progress and participant information
              const callerId = store.getState().user.auth.user?.id;
              store.dispatch(
                webRTCActions.setParticipantInfo({
                  party: 'local',
                  info: {
                    role: 'caller',
                    micEnabled: true,
                    userId: callerId,
                    screenName: message.screenName,
                    username: message.username,
                    inviteId: message.id,
                  },
                })
              );
              store.dispatch(
                webRTCActions.setParticipantInfo({
                  party: 'remote',
                  info: {
                    userId: message.recipient,
                    screenName: message.recipientScreenName,
                  },
                })
              );

              transitionEvent('invite', 'local');
            })
            .catch(err => {
              console.log('error initializing micStream or TURN:', err);
            });
        }
        break;
      case 'regrets':
        // transitionEvent('regrets', 'local')
        break;
      default:
        // the message was not related to call progress
        return false;
    }

    // the message was handled
    return true;
  };

  // handle event associated with a voice recording
  const handleInboundMessage = message => {
    const { user } = store.getState().user.auth;
    enableLiveCallLogging &&
      console.log(`LiveCall handleInboundMessage type=${message?.type}.`);

    switch (message?.type) {
      case 'invite':
        // check requirements and set call progress to 'invited'
        // note that the most recent invite will be represented in the webRTC slice

        // check if viable invite
        if (checkRequirements('invited', message?.userId).status === 'OK') {
          // place message into directed message playlist
          // add this invite to pending list
          // perform call progress state transition
          addLocalMessage(
            message,
            t('You_have_received_a_Live_Call_invitation')
          );
          //@@ WIP experiment transitionEvent('invite', 'remote');

          // WIP enqueue invite
          store.dispatch(
            webRTCActions.enqueueInvite({
              userId: message?.userId,
              screenName: message?.screenName,
              fileUrl: message?.fileUrl,
            })
          );
        } else {
          //non viable invite, ignore it
          console.log(`non-viable invite from ${message.screenName} ignored`);
        }
        break;
      case 'regrets':
        // deliver the message to directed playlist
        addLocalMessage(message, t('A_new_message_has_arrived_Just_for_you'));

        // perform state transition, annotate event with audio url
        store.dispatch(
          webRTCActions.transition({
            event: 'regrets',
            initiator: 'remote',
            data: message?.fileUrl,
          })
        );
        break;
      default:
        // the message was not related to call progress
        return false;
    }

    // the message was handled
    return true;
  };

  const isEndOfCallEvent = type =>
    ['cancel', 'decline', 'regrets', 'remove', 'hangup', 'exception'].includes(
      type
    );

  // delete ALL invites from the caller  ( to everyone )
  // remove from local playlist and from the server
  const expungeStrandedInvites = userId => {
    //console.log(`expungeStrandedInvites userId= ${userId}`);
    const strandedInvites = store
      .getState()
      .dialog.dialogs.filter(
        dialog => dialog.type === 'invite' && dialog.userId === userId
      );

    strandedInvites.forEach(msg => {
      //delete from server
      deleteMessageById(msg.id);

      //delete from playlist
      if (msg.id === store.getState().dialog.selectedMessageId) {
        nextMsg();
      }
      store.dispatch(dialogActions.removeTopic(msg.id)); //remove from local   dialogs

      // delete from invite list
      store.dispatch(webRTCActions.removeInvite(userId));
    });
  };

  const handleLocalEvent = event => {
    const { type, data } = event;

    const { participants } = store.getState().webRTC;
    enableLiveCallLogging &&
      console.log(`LiveCall handleLocalEvent type= ${type}, data:}`, data);

    const remoteParticipantId = participants.remote.userId;
    const inviteId = participants.local.inviteId;
    const isCaller = participants.local.role === 'caller';

    switch (type) {
      case 'connect':
        sendOffer();
        notifyPeer({ type }); //@@ consider removing this, allow sendOffer to be the notification
        transitionEvent(type, 'local');
        return;
      case 'reset':
        if (isCaller) {
          deleteMessageById(inviteId); //caller deletes invite from server
        } else {
          // callee removes message from playlist
          // if removing the currently selected message, move to another message first
          inviteId === store.getState().dialog.selectedMessageId && nextMsg();
          store.dispatch(dialogActions.removeTopic(inviteId));

          // WIP callee removes invite from list
          store.dispatch(webRTCActions.removeInvite(remoteParticipantId));
        }
        store.dispatch(webRTCActions.clearCallProgress());
        return;
      case 'block':
        // the caller has been blocked by the callee,
        // send a decline message and terminate the call
        // ... first verify that the current caller is the person being blocked
        if (data === remoteParticipantId) {
          notifyPeer({ type: 'decline' });
          store.dispatch(webRTCActions.clearCallProgress());
        }
        return;
      case 'accept':
        // invoked only by callee
        const invite = data;
        setCalleeParticipantInfo(invite);

        // verify that call can proceed, initialize, and notify peer of acceptance
        const check = checkRequirements('accepting', invite?.userId);
        if (check.status === 'OK') {
          initialize()
            .then(() => {
              // upon accepting an invite, user is no longer available to continue
              // with any other invites
              makeUserUnavailable(invite.userId);

              // call can proceed
              notifyPeer({ type }); //@@not sure how long redux takes to achieve setCalleeParticipantInfo
              transitionEvent(type, 'local');
            })
            .catch(err => {
              // call cannot proceed, failed initialization
              console.log(`accept: initialization err: ${err}`);
              notifyPeer({ type: 'exception' });
              store.dispatch(
                webRTCActions.transition({
                  event: 'exception',
                  initiator: 'local',
                  data: `initialization err: ${err}`,
                })
              );
            });
        } else {
          // call cannot proceed, failed checkRequirements
          console.log(`accept: failed check: ${check?.name}`);
          notifyPeer({ type: 'exception' });
          store.dispatch(
            webRTCActions.transition({
              event: 'exception',
              initiator: 'local',
              data: `failed check: ${check?.name}`,
            })
          );
        }
        return;
      default:
        if (
          isEndOfCallEvent(type) ||
          (type === 'disconnect' && callActivitiesInProgress())
        ) {
          peerConnectionDestroy();
        }
        break;
    }

    // for events that fell through the switch...
    notifyPeer({ type });
    transitionEvent(type, 'local');
  };

  const handleInboundEvent_legacy = event => {
    const { participants } = store.getState().webRTC;
    //console.log(`handleInboundEvent:`, event)

    const eventOriginatorId = event?.userId;
    const remoteParticipantId = participants.remote.userId;
    const eventType = event?.type;
    const isTermination =
      isEndOfCallEvent(eventType) || eventType === 'disconnect';

    // process event from the active remote participant
    if (eventOriginatorId === remoteParticipantId) {
      if (isTermination) {
        peerConnectionDestroy();
      }
      transitionEvent(eventType, 'remote');
    }

    // additional processing for event from any peer
    expungeStrandedInvites(eventOriginatorId);
  };

  // SigServer relays events delivered via telematry channel.
  // Voice-Message events (e.g. invite, regrets) are handled by handleInboundMessage
  const handleInboundEvent = ({ type, userId, data }) => {
    enableLiveCallLogging &&
      console.log(
        `LiveCall handleInboundEvent type=${type}, userId=${userId}, data:`,
        data
      );
    const screenName = data;
    const { participants } = store.getState().webRTC;
    const remoteParticipantId = participants.remote.userId;
    const isTermination = isEndOfCallEvent(type) || type === 'disconnect';
    switch (type) {
      case 'invite':
        // process a telemetry invite
        if (checkRequirements('invited', userId).status === 'OK') {
          store.dispatch(webRTCActions.enqueueInvite({ userId, screenName }));
        }
        break;
      default:
        if (isTermination) {
          expungeStrandedInvites(userId);
          if (userId === remoteParticipantId) {
            // only manipulate the call if event is from the remote participant
            peerConnectionDestroy();
            transitionEvent(type, 'remote');
          }
        } else {
          // NOT a termination event type
          if (userId === remoteParticipantId) {
            transitionEvent(type, 'remote');
          }
        }
        break;
    }
  };

  // don't keep remote users waiting if local user becomes unavailable
  // send notice to issuers of other invites
  const makeUserUnavailable = (remoteUserId = null) => {
    const {
      messageSequence, // ordered array of messages
      messageMap,
    } = store.getState().dialog;

    const { invites, selectedInviteId } = store.getState().webRTC;
    console.log(
      `makeUserUnavailable remote=${remoteUserId}, invites:`,
      invites
    );

    // notify invite authors that user is unavailable
    //@@ consider making this a broadcast to EVERYBODY
    invites
      .filter(invite => invite.userId !== remoteUserId)
      .forEach(invite => {
        console.log('notifying:', invite.userId);
        notifyPeer({ type: 'remove' }, invite.userId);
      });

    // remove all invites
    const inviteMessages = messageSequence.filter(
      id => messageMap[id].type === 'invite'
    );
    store.dispatch(dialogActions.remove(inviteMessages));
    store.dispatch(webRTCActions.clearInvites());
  };

  // indicate if call activities are in progress
  const callActivitiesInProgress = () => {
    const progress = store.getState().webRTC.call.progress;
    const rslt = !['noService', 'ready'].includes(progress);
    return rslt;
  };

  const parseOffer = offer => {
    // ref.: https://www.tutorialspoint.com/webrtc/webrtc_session_description_protocol.htm
    // ref.: https://en.wikipedia.org/wiki/RTP_payload_formats#:~:text=Payload%20identifiers%2096%E2%80%93127%20are,assigned%20port%20is%20not%20required.

    let lines = offer.sdp.split('\n').map(l => l.trim()); // split and remove trailing CR
    lines.forEach(line => {
      console.log('parse:', line);
    });
  };

  const sendOffer = async () => {
    if (peerConnection) {
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);
      logging && console.log('offer created.');
      //parseOffer(offer);

      sigServer.sendWebRTCOffer({
        calleeSocketId: getPeer(
          store.getState().webRTC.participants.remote.userId
        ).socketId,
        offer: offer,
      });
    }
  };

  const handleOffer = async data => {
    if (peerConnection) {
      await peerConnection.setRemoteDescription(data.offer);
      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);
      sigServer.sendWebRTCAnswer({
        callerSocketId: getPeer(
          store.getState().webRTC.participants.remote.userId
        ).socketId,
        answer: answer,
      });
    } else {
      console.log('handleOffer: no peerConnection');
    }
  };

  const handleAnswer = async data => {
    peerConnection && (await peerConnection.setRemoteDescription(data.answer));
  };

  const handleCandidate = async data => {
    if (peerConnection) {
      try {
        await peerConnection.addIceCandidate(data.candidate);
        // console.log(`added ICE ${data.candidate.candidate.split(" ")[0]}`)
      } catch (err) {
        console.error(
          'error occured when trying to add received ice candidate',
          err
        );
      }
    }
  };

  const webRTCDemo = {
    getMicStream,
    getTurnServers,
    sendOffer,
    createPeerConnection,
  };

  // can a call be placed to the specified user
  const isUserCallable = id => {
    const check = checkRequirements('checking', id);
    return check.status === 'OK';
  };

  const exception = msg => {
    console.log(`callProc exception: ${msg}`);

    // set up the call progress and participant information
    store.dispatch(
      webRTCActions.setParticipantInfo({
        party: 'local',
        info: {
          role: 'caller',
          userId: store.getState().user.auth.user?.id,
        },
      })
    );
    store.dispatch(
      webRTCActions.transition({
        event: 'exception',
        initiator: 'local',
        data: msg,
      })
    );
  };

  // invoke when callee acts on an invite
  // NOT for use by caller
  const setCalleeParticipantInfo = inviteMessage => {
    // clear participant information:
    if (!inviteMessage) {
      store.dispatch(webRTCActions.setParticipantInfo(null));
      return;
    }

    // set local and remote participant info:
    const user = store.getState().user.auth.user;
    store.dispatch(
      webRTCActions.setParticipantInfo({
        party: 'local',
        info: {
          role: 'callee',
          micEnabled: true,
          userId: user?.id,
          screenName: user?.screen_name,
          username: user?.name,
          inviteId: inviteMessage?.id,
        },
      })
    );
    store.dispatch(
      webRTCActions.setParticipantInfo({
        party: 'remote',
        info: {
          userId: inviteMessage?.userId,
          screenName: inviteMessage?.screenName,
        },
      })
    );
  };

  return {
    handleOutboundMessage,
    handleInboundMessage,
    handleInboundEvent,
    handleLocalEvent,
    handleLocalEvent,

    callActivitiesInProgress,
    setCalleeParticipantInfo,
    expungeStrandedInvites,

    handleOffer,
    handleAnswer,
    handleCandidate,
    webRTCDemo, //used ONLY to expose resources to WebRTCDemo

    isUserCallable,
    exception,
  };
}

const logging = false;

let logLoopCnt = 0;
const logLoop = stream => {
  const str = stream;

  const sr = () => {
    const track = str.getAudioTracks()[0];
    return track && track.getSettings && track.getSettings().sampleRate;
  };

  console.log(`ontrack sampleRate=${sr()}`);
  setInterval(() => {
    console.log(`ontrack sampleRate=${sr()}`);
    if (++logLoopCnt === 5) {
      const ctx = new AudioContext();
      const srcNode = ctx.createMediaStreamSource(str);
    }

    if (++logLoopCnt === 10) {
      const audioEl = new Audio();
      audioEl.srcObject = str;
      audioEl.muted = true;
      console.log('audioEl:', audioEl);
    }
  }, 50);
};

const createPeerConnection = () => {
  // ref. public stun: https://ourcodeworld.com/articles/read/1536/list-of-free-functional-public-stun-servers-2021

  const turnServers = store.getState().webRTC.turnServers;
  const failoverStunServer = { urls: 'stun:stun1.l.google.com:19302' };
  const iceServers = [...turnServers, failoverStunServer];
  const configuration = {
    iceServers,
    //    iceTransportPolicy: "relay"   //force the use of TURN
  };

  try {
    logging && console.log('RTCPeerConnection configuration:', configuration);
    peerConnection = new RTCPeerConnection(configuration);
    logging &&
      console.log(
        `new peerConnection iceConnectionState=${peerConnection.iceConnectionState}, iceGatheringState=${peerConnection.iceGatheringState}, signalingState=${peerConnection.signalingState}.`
      );
    window.peerConnection = peerConnection;
  } catch (error) {
    console.log(`RTCPeerConnection error:`, error);
    return null;
  }

  store.dispatch(webRTCActions.setPeerConnectionReady(true));

  const localStream = localStreamProcessor.getOutputStream();
  for (const track of localStream.getTracks()) {
    peerConnection.addTrack(track, localStream);
  }

  // handle remote stream
  peerConnection.ontrack = e => {
    //const { streams: [stream] } = e
    const remoteStream = e.streams[0];
    const speakerVolume =
      store.getState().ui.pSession.liveCallAudioSettings.speakerVolume;

    //logLoop(remoteStream);

    remoteStreamProcessor = new StreamProcessor(
      'remote',
      remoteStream,
      speakerVolume,
      ['Linto'],
      'outputStream',
      false //dlog
    );

    store.dispatch(
      webRTCActions.setParticipantInfo({
        party: 'remote',
        info: { stream: remoteStreamProcessor.getOutputStream() },
      })
    );
  };

  peerConnection.onicecandidate = event => {
    if (event.candidate) {
      sigServer.sendWebRTCCandidate({
        candidate: event.candidate,
        connectedUserSocketId: getPeer(
          store.getState().webRTC.participants.remote.userId
        ).socketId,
      });
    }
  };

  peerConnection.onconnectionstatechange = event => {
    logging &&
      console.log(
        `onconnectionstatechange state=${peerConnection.connectionState}.`
      );
    if (peerConnection.connectionState === 'connected') {
      logging && console.log('succesfully connected with other peer');

      const hi = new UIfx('/audio/hi-1.mp3');
      hi.play();
      // Peers connected!
    }
  };

  // diagnostic events
  peerConnection.oniceconnectionstatechange = e => {
    logging &&
      console.log(
        `oniceconnectionstatechange state=${peerConnection.iceConnectionState}.`
      );
  };
  peerConnection.onicegatheringstatechange = e => {
    logging &&
      console.log(
        `onicegatheringstatechange state=${peerConnection.iceGatheringState}.`
      );
  };
  peerConnection.onsignalingstatechange = e => {
    logging &&
      console.log(
        `onsignalingstatechange state=${peerConnection.signalingState}.`
      );
  };
  peerConnection.onnegotiationneeded = e => {
    logging && console.log('negotiationneeded:');
  };
  // error codes: https://www.webrtc-developers.com/oups-i-got-an-ice-error-701/
  peerConnection.onicecandidateerror = e => {
    logging &&
      console.log(
        `ERROR: onicecandidateerror code=${e.errorCode} candidate=${e.hostCandidate} ${e.errorText}.`
      );
  };

  // open a data channel
  pdm = new PeerDataManager(peerConnection);
  return peerConnection;
};

export const setMicVolume = v => {
  localStreamProcessor && localStreamProcessor.setVolume(v);
};
export const setSpeakerVolume = v =>
  remoteStreamProcessor && remoteStreamProcessor.setVolume(v);
export const setMicMuted = m =>
  localStreamProcessor && localStreamProcessor.setMuted(m);
export const setSpeakerMuted = (m, t0) =>
  remoteStreamProcessor && remoteStreamProcessor.setMuted(m, t0);

/*
RTCPeerConnection

Unused Events
  onicecandidateerror
  oniceconnectionstatechange
  onicegatheringstatechange
  onnegotiationneeded
  onsignalingstatechange

Unused Methods
    ()
  removeTrack()
  restartIce()
  setConfiguration()
  setIdentityProvider()

  getConfiguration()
  getIdentityAssertion()
  getReceivers()
  getSenders()
  getStats()
  getTransceivers()



*/
