/**
 * Dialog for bookmarking or blacklisting a message author
 * Rules:
 *  - must be logged in
 *  - cannot apply bookmark/blacklist to one's self
 *  - cannot apply redundant mark (if already marked)
 *  - blacklist requires specification of a reason (radio list)
 *
 * //TODO2: prevent bookmarking of admin users
 *
 *
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonStd from 'components/utils/ButtonStd';
import Dialog2 from 'components/Modals/Dialog2';
import { useDispatch, useSelector } from 'react-redux';
import { setBookmark } from 'store/user-slice';
import { uiActions } from 'store/ui-slice';
import AuthorAnnotation from 'containers/MessagePlayer/components/AuthorAnnotation';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { getCallManager } from 'lib/api/webRTCApi';
import { t } from 'lib/translation/trans';

const callProc = getCallManager();

export default function BookmarkActionDialog({
  open,
  onClose,
  message,
  isPositiveMark,
}) {
  const { auth, isAuthenticated } = useSelector(state => state.user);
  const { user } = auth;
  const dispatch = useDispatch();
  const [blacklistReason, setBlacklistReason] = useState(null);
  const [formValidationFail, setFormValidationFail] = useState(false);

  const { screenName, bookmarked, blacklisted } = message;

  const isSelf = isAuthenticated && user && user.name === message.username;
  const isAlready =
    isAuthenticated &&
    ((isPositiveMark && bookmarked) || (!isPositiveMark && blacklisted));

  // If LiveCall invites are pending, LiveCallAlert may be rendered.
  // Move this dialog up to avoid overlap.
  const invites = useSelector(state => state.webRTC.invites);

  // optional reasons for blacklisting this person
  const options = [
    { mnemonic: 'sex', label: t('sexual_content') },
    { mnemonic: 'foul', label: t('foul_language') },
    { mnemonic: 'rude', label: t('rude') },
    { mnemonic: 'boring', label: t('boring') },
    { mnemonic: 'othr', label: t('other_inappropriate_content') },
    { mnemonic: 'qual', label: t('bad_sound_quality') },
  ];

  const handleChange = event => {
    setBlacklistReason(event.target.value);
    setFormValidationFail(false);
  };

  const handleCancel = () => onClose();

  const handleConfirm = () => {
    // blacklisting requires a blacklist reason
    if (!isPositiveMark && !blacklistReason) {
      setFormValidationFail(true);
      return;
    }

    dispatch(
      setBookmark({
        targetUser: message.userId,
        blacklistReason,
        isPositiveMark,
      })
    );
    dispatch(
      uiActions.alertSet({
        message: `${message.screenName} ${t('has_been')} ${
          isPositiveMark ? t('bookmarked') : t('blocked')
        }`,
        severity: 'success',
      })
    );

    // if blacklisting the author of A Live Call invite, notify the call processing system
    if (!isPositiveMark && message?.type === 'invite') {
      callProc.handleLocalEvent({ type: 'block', data: message?.userId });
    }

    onClose(isPositiveMark ? 'bookmarkConfirm' : 'blacklistConfirm');
  };

  const handleLogin = () => {
    dispatch(uiActions.setRegDialog({ open: true, mode: 'login' }));
  };

  // after logging in, re-try handleConfirm
  const onLoginSuccess = () => {
    dispatch(uiActions.setRegDialog({ open: false, mode: null }));
    handleConfirm();
  };

  // Prepare context-sensitive scripts
  // cases: unauthenticated, self-bookmarking attempt, positive/negative (blacklist) bookmark

  // excaption Scripts for unauthenticated user
  const headingCannotBookmark = `${t('Cannot_Bookmark')} ${screenName}...`;
  const headingCannotBlock = `${t('Cannot_Block')} ${screenName}...`;
  const scriptsUnauthenticated = () =>
    isPositiveMark
      ? {
          heading: headingCannotBookmark,
          body: `${t('You_must_be_logged_in_to_set_a_bookmark')}\u00a0!`,
          actionBtn: (
            <ButtonStd
              onClick={handleLogin}
              label={t('Login Now')}
              color="secondary"
            />
          ),
        }
      : {
          heading: headingCannotBlock,
          body: `${t('You_must_be_logged_in_to_block_a_user')}\u00a0!`,
          actionBtn: (
            <ButtonStd
              onClick={handleLogin}
              label={t('Login Now')}
              color="secondary"
            />
          ),
        };

  // Exception Scripts for attempts to annotate self
  const scriptsOnSelf = () =>
    isPositiveMark
      ? {
          heading: headingCannotBookmark,
          body: `${t('You_cannot_apply_a_bookmark_to_yourself')}\u00a0!`,
          actionBtn: null,
        }
      : {
          heading: headingCannotBlock,
          body: `${t('You_cannot_block_yourself')}\u00a0!`,
          actionBtn: null,
        };

  // Exception Scripts redundant attempt
  const scriptsRedundant = () =>
    isPositiveMark
      ? {
          heading: headingCannotBookmark,
          body: `${t('This_user_is_already_bookmarked')}\u00a0!`,
          actionBtn: null,
        }
      : {
          heading: headingCannotBlock,
          body: `${t('This_user_is_already_blocked')}\u00a0!`,
          actionBtn: null,
        };

  // Nominal scripts
  const scriptsNominal = () =>
    isPositiveMark
      ? {
          heading: `${t('Bookmarking')} ${screenName} ...`,
          body: (
            <div>
              {t('Bookmarked_members_will_appear_with_a')}{' '}
              <AuthorAnnotation message={{ bookmarked: true }} />
            </div>
          ),
          actionBtn: (
            <ButtonStd
              onClick={handleConfirm}
              label={t('OK')}
              color="secondary"
            />
          ),
        }
      : {
          heading: `${t('Blocking')} ${screenName} ${t('because')}...`,
          body: (
            <div>
              <RadioGroup
                name="blacklistReason"
                value={blacklistReason}
                onChange={handleChange}
              >
                {options.map(option => (
                  <FormControlLabel
                    value={option.mnemonic}
                    key={option.mnemonic}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
              {formFooter()}
            </div>
          ),
          actionBtn: (
            <ButtonStd
              onClick={handleConfirm}
              label={t('BLOCK')}
              color="secondary"
            />
          ),
        };

  // provide a footer with styling dependent form validation results
  const formFooter = () => {
    const style = { marginTop: '1rem' };
    formValidationFail && (style.backgroundColor = 'red');

    const msg = formValidationFail
      ? `${t('Please_select_the_reason_for_blocking')} ${message.screenName}.`
      : `${t('You_will_no_longer_hear_messages_from')}  ${message.screenName}.`;

    return <div style={style}> {msg} </div>;
  };

  //select the set of scipts and buttons to be employed
  const scripts = isAuthenticated
    ? isSelf
      ? scriptsOnSelf()
      : isAlready
      ? scriptsRedundant()
      : scriptsNominal()
    : scriptsUnauthenticated();

  return (
    <>
      <Dialog2
        open={open}
        onClose={handleCancel}
        heading={scripts.heading}
        content={scripts.body}
        actions={
          <>
            <ButtonStd onClick={handleCancel} label={t('Cancel')} />
            {scripts.actionBtn}
          </>
        }
        moveUp={Boolean(invites.length)}
      />
    </>
  );
}

BookmarkActionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.object.isRequired,
  isPositiveMark: PropTypes.bool.isRequired, // controls whether bookmark or blacklist will be applied
  onClose: PropTypes.func,
};
BookmarkActionDialog.defaultProps = {
  onClose: () => {},
};
