import { useSelector, useDispatch } from 'react-redux'
import { logout } from 'lib/api/userApi'

const Header = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)    //sets up a subscription

  const handleLogout = () => { logout() }


  return (
    <header >
      <h1>Redux Auth</h1>
      {isAuthenticated
        ? <nav>
          <ul>
            <li>
              <a href='/'>My Products</a>
            </li>
            <li>
              <a href='/'>My Sales</a>
            </li>
            <li>
              <button onClick={handleLogout}>Logout</button>
            </li>
          </ul>
        </nav>
        : <h2>Anonymous User</h2>
      }


    </header>
  );
};

export default Header;

