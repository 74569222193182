/**
 * An HOC wrapper to render an "options" button
 * and Popover to select from a list of options and
 * dispatch the new selected value to redux
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
} from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';
import { timeout } from 'lib/utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';

export class OptionControls {
  heading = ''; // subtitle to appear in Popover menu
  optionKey = ''; // key to the parameter to be manipulated
  options = []; // array of selectable {label, icon} values

  constructor({ heading = '', optionKey = '', options = [] }) {
    this.heading = heading;
    this.optionKey = optionKey;
    this.options = options;
  }
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  btn: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  typography: {
    padding: theme.spacing(2),
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const WithOptionControls = props => {
  const { heading, optionKey, options } = props.controls;
  const currentOptions = useSelector(state => state.ui.pSession);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // initialize selected item index
  useEffect(() => {
    // if redux store holds an option matching optionKey
    // find its index within options array
    const currentValue = currentOptions && currentOptions[optionKey];
    const ix = options.findIndex(option => option.label === currentValue);

    // apply it if found
    ix >= 0 && setSelectedIndex(ix);
  }, [currentOptions]);

  const openPopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = index => {
    setSelectedIndex(index);
    timeout(250).then(() => {
      closePopover();
      dispatch(
        uiActions.optionSet({ key: optionKey, value: options[index].label })
      );
    });
  };
  return (
    <div className={classes.wrapper}>
      <IconButton
        classes={{ root: classes.btn }}
        color="secondary"
        onClick={openPopover}
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        id="popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div className={classes.list}>
          <List
            component="nav"
            aria-label="main mailbox folders"
            subheader={<ListSubheader> {heading} </ListSubheader>}
          >
            <Divider />
            {options.map((option, ix) => (
              <ListItem
                key={option.label}
                button
                selected={selectedIndex === ix}
                onClick={() => handleListItemClick(ix)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
      {props.children}
    </div>
  );
};

/*
WithOptionControls.propTypes = {
    heading: PropTypes.string,
    optionKey: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,     //serves as Popover menu label and key to redux state variable
        icon: PropTypes.element,
    })).isRequired,

}
*/
WithOptionControls.propTypes = {
  //    controls: PropTypes.instanceOf(OptionControls),
  controls: PropTypes.shape({
    heading: PropTypes.string,
    optionKey: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired, //serves as Popover menu label and key to redux state variable
        icon: PropTypes.element,
      })
    ).isRequired,
  }).isRequired,
};

export default WithOptionControls;
