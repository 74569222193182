/**
 * MessageTopic
 * Refactor to eliminate TextField
 * Renders topic and author information for an existing message
 *
 * Optionally includes rendering of Action options
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

import AuthorAnnotation from './AuthorAnnotation';
import BorderedBlock from 'components/utils/BorderedBlock';
import { makeStyles } from '@material-ui/core/styles';
import { timeSince } from 'lib/utils/utils';
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';

const useStyles = makeStyles(theme => ({
  wrapperOuter: {
    paddingTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  title: {
    color: '#0096ef', // lightBlue
  },
  authorInfo: {
    fontStyle: 'italic',
    fontSize: '.8rem',
  },
}));

const MessageTopic = ({
  message,
  enableActions,
  onActionsClick,
  isPlaying,
}) => {
  const classes = useStyles();

  const authorInfo = (
    <Typography classes={{ root: classes.authorInfo }} variant="body1">
      By: {message?.screenName} <AuthorAnnotation message={message} />{' '}
      {` -- (${timeSince(new Date(message?.created))} ago)`}
    </Typography>
  );

  return (
    <BorderedBlock
      classes={classes}
      labelTop={message && message.type && message.type.toUpperCase()}
      labelBottom={authorInfo}
      labelBottomRight={
        enableActions && (
          <PlayerActions
            groups={false}
            legends={false}
            message={message}
            onEvent={onActionsClick}
            isPlaying={isPlaying}
          />
        )
      }
    >
      <div className={classes.title}>{message?.title}</div>
    </BorderedBlock>
  );
};

MessageTopic.propTypes = {
  message: PropTypes.object,
  isPlaying: PropTypes.bool,
  enableActions: PropTypes.bool,
  onActionsClick: PropTypes.func,
};

MessageTopic.defaultProps = {
  message: {},
  enableActions: false,
};

export default MessageTopic;
