/**
 * DevNotice
 * Rendar a warning when development client or server is in use
 *
 *
 */

import React from 'react';
import { Chip } from '@material-ui/core';
import AnimationDecoration from 'components/utils/AnimationDecoration';
import { HOST_LABEL } from 'configs/config-hvn';
import { devClient } from 'lib/utils/utils';

const DevNotice = () => {
  // detect substring in howt path
  const devServer = HOST_LABEL.includes('dev');

  // stitch together the label
  let label = devClient || devServer ? 'dev' : '';
  devClient && (label += ' client ');
  devClient && devServer && (label += '&');
  devServer && (label += ` ${HOST_LABEL} server `);

  return devClient || devServer ? (
    <div style={{ position: 'fixed', bottom: 10, right: 0 }}>
      <AnimationDecoration action="bounce" enable={true}>
        <Chip
          style={{ marginRight: 10, color: 'white', backgroundColor: 'red' }}
          label={label}
          size="small"
        />
      </AnimationDecoration>
    </div>
  ) : null;
};

export default DevNotice;
