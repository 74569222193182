/**
 * TopicCard_v6
 * Adapted TopicCard_v2a, refactor thread container styles
 *
 * Render a single message (topic or response) within a Playlist
 * A Topic contains a "pull down" indicator if it owns responses.
 * A Response is only displayed if its Topic is open
 *
 *
 * A Topic is rendered with a response pulldown
 * render responses in narrow format
 * use Transition
 * render background with border around optn threads
 *
 * Threads
 * - consist of a topic (root of thread) and zero or more responses (members)
 *      from various users.
 * - thread presentation is with members under the root, with
 *      background indicating the scope of the thread
 *
 * Public Threads
 * - presented with full-width roots and narrow members
 *
 * Directed Threads
 * - messages (roots and members) presented with left/right
 *      indentation indicating inbound/outbound status
 *
 * TEMPORARY ADDITION
 *  Avatar Demo for Nelly
 *  Avatar prevents rendering of LiveCallInitiatorButton
 *
 */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';

import { makeStyles } from '@material-ui/core/styles';
import {
  isTopic,
  isDirectedType,
  isSelfAuthored,
  fromProxyUser,
} from 'lib/api/messageApi';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Card,
  CardContent,
  Grid,
  Typography,
  Collapse,
  Button,
  Avatar,
  IconButton,
} from '@material-ui/core';

import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';

import AuthorAnnotation from 'containers/MessagePlayer/components/AuthorAnnotation';
import ResponseIndicator from './ResponseIndicator';
import { timeSince } from 'lib/utils/utils';
import { t } from 'lib/translation/trans';
import { isMember } from 'lib/api/communityApi';
import LiveCallInitiatorButton from 'components/LiveCall/components/LiveCallInitiatorButton';
import NewInboundMessageIndicator from './NewInboundMessageIndicator';
import { useHistory } from 'react-router-dom';

//NOTE: react-scroll wants  "react": "^16.6.0"
// install with 'npm install --force'
const Scroll = require('react-scroll');
const Element = Scroll.Element;

const cardVerticalMargin = 10;
const responseHorizontalMargin = 60;
const responseLargeHorizontalMargin = '20%';
const marginIndicatorWidth = 40;

const useStyles = makeStyles(theme => {
  //console.log("theme:", theme)
  return {
    card: {
      //minWidth: 275,
      margin: `${cardVerticalMargin}px 10px`,
      marginBottom: 0,
      borderRadius: '1rem',
      paddingLeft: '8px',
      position: 'relative',
      marginRight: 20,
    },
    unSelected: {
      '&:hover': {
        backgroundColor: '#5a5a5a',
      },
    },
    scrollbarMarginRight: {
      marginRight: '20px',
    },
    smallMargnBoth: {
      marginLeft: responseHorizontalMargin,
      marginRight: responseHorizontalMargin,
    },
    largeMarginLeft: {
      marginLeft: responseLargeHorizontalMargin,
    },
    largeMarginRight: {
      marginRight: responseLargeHorizontalMargin,
    },
    directedHighlight: {
      backgroundColor: theme.palette.primary.main,
    },
    publicHighlight: {
      backgroundColor: theme.palette.secondary.dark,
    },
    playedMarker: {
      width: 0,
      height: 0,
      borderRight: '15px solid transparent',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    playedMarkerPublic: {
      borderTop: `20px solid ${theme.palette.secondary.main}`,
    },
    playedMarkerDirected: {
      borderTop: `20px solid ${theme.palette.primary.light}`,
    },
    type: {
      fontSize: 14,
      textTransform: 'capitalize',
    },
    pos: {
      marginBottom: 0,
    },
    responsesContainer: {
      borderLeft: '2px black solid',
      paddingLeft: '.5rem',
      textAlign: 'left',
      borderRadius: 0,
    },
    title: {
      color: '#0096ef', // lightBlue
      fontSize: '.8rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '.6rem',
      },
    },
    titleTopicHighlighted: {
      color: '#6eff01',
    },
    titleResponseHighlighted: {
      color: '#00ff4a',
    },
    user: {
      fontStyle: 'oblique',
      fontSize: '.8rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '.6rem',
      },
    },
    cardResponse: {
      marginLeft: responseHorizontalMargin,
      marginRight: responseHorizontalMargin,
    },
    threadBorderTopic: {
      borderTop: `2px solid white`,
      borderLeft: `2px solid white`,
      borderRight: `2px solid white`,
      borderRadius: '1rem 1rem 0 0', //top-left | top-right | bottom-right | bottom-left
      position: 'absolute',
      zIndex: -100,
      top: `calc(-${cardVerticalMargin / 2}px )`,
      left: 0,
    },
    threadBorderResponse: {
      borderBottom: `2px solid white`,
      borderLeft: `2px solid white`,
      borderRight: `2px solid white`,
      borderRadius: '0 0 1rem 1rem', //top-left | top-right | bottom-right | bottom-left
      position: 'absolute',
      zIndex: -100,
      top: `calc(-${cardVerticalMargin + 10}px )`,
      left: 0,
    },
    threadBorderResponseBackgroundPublic: {
      backgroundColor: theme.palette.secondary.main,
    },
    threadBorderResponseBackgroundDirected: {
      backgroundColor: theme.palette.primary.dark,
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  };
});

export default function TopicCard({
  noteHeight,
  message,
  selected,
  includeActions,
  isOpen,
  played,
  toggleOpen,
  select,
  onMemberInfoRequest,
  onActionsEvent,
  scrollbarPresent,
  displayAsRoot,
  centered,
  isPlaying,
  hideResponseIndicator,
}) {
  const [nameHover, setNameHover] = useState(false);
  const dispatch = useDispatch();
  const { id, responses, title, type, screenName } = message;
  const cardRef = useRef();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const isTopicMsg = isTopic(message);
  const isDirectedMsg = isDirectedType(type);
  const { openTopicId } = useSelector(state => state.dialog);
  const isParentOpen = openTopicId === message.parent;
  const classes = useStyles();
  const isSelected = selected && typeof selected === 'function' && selected();
  // a selected message (topic or response) is being played,
  // an open message is a topic that is exposing its responses
  const isHighlighted = isSelected; //|| isOpen

  const isPublicResponse = !isTopicMsg && !isDirectedMsg;
  const isInbound = isDirectedType(type) && !isSelfAuthored(message);
  const isOutbound = isDirectedType(type) && isSelfAuthored(message);
  const enableClickOnUserButton = isMember(); // only logged-in users can click on author to get information
  const history = useHistory();

  // measure and report component height
  useEffect(() => {
    const height = cardRef.current && cardRef.current.clientHeight;
    noteHeight && noteHeight(height);
  }, []);

  const responseIndicatorProps = () => ({
    count: Array.isArray(responses) ? responses.length : 0,
    isOpen,
    onClick: id => toggleOpen(id),
    includeLabel: false,
  });

  // set up the Actions for the current message
  const actions = () => {
    const actionsProps = {
      isPlaying,
      disabled: !isSelected,
      groups: false,
      legends: false,
      message: { ...message, selected },
      onEvent: onActionsEvent,
      justify: 'flex-start',
      btnStyleOverride: { transform: 'scale(.8)' },
      btnList: fromProxyUser(message)
        ? ['play', 'pause', null, 'respond', 'delete', 'info']
        : [
            'play',
            'pause',
            null,
            'call',
            'respond',
            'share',
            'delete',
            'bookmark',
            'unMark',
            'blacklist',
            'info',
          ],
    };
    if (type === 'invite') {
      actionsProps.btnList = ['accept', 'decline', 'regrets', 'blacklist'];
      actionsProps.legends = true;
    }
    return <PlayerActions {...actionsProps} />;
  };

  // determine classes to be applied to this card
  const cardClasses = () => {
    const {
      card,
      scrollbarMarginRight,
      smallMargnBoth,
      largeMarginLeft,
      largeMarginRight,
      directedHighlight,
      publicHighlight,
    } = classes;
    // all cards have card class
    // all responses have smallMarginBoth
    // directed messages have a large margin on left or right, depending on authorship
    // selected messages are highlighted

    let cardClasses = card;
    (isOpen || isOutbound) &&
      scrollbarPresent &&
      (cardClasses += ` ${scrollbarMarginRight}`);
    !centered && isInbound && (cardClasses += ` ${largeMarginRight}`);
    !centered && isOutbound && (cardClasses += ` ${largeMarginLeft}`);
    isPublicResponse && (cardClasses += ` ${smallMargnBoth}`);
    isHighlighted
      ? (cardClasses += ` ${
          isDirectedMsg ? directedHighlight : publicHighlight
        }`) // selected card
      : (cardClasses += ` ${classes.unSelected}`); // unselected card
    return cardClasses;

    /*
        //alternate implementation
        let cardClasses = `${card} ${isResponse(message) ? smallMargnBoth : ''}`
        isDirectedType(message.type) && (cardClasses += ` ${isSelfAuthored(message) ? largeMarginRight : largeMarginLeft}`);
        isSelected && (cardClasses += ` ${isDirectedType(message.type) ? directedHighlight : publicHighlight}`);
        */

    return cardClasses;
  };

  // determine classes to be applied to this card
  const cardClasses_v2 = () => {
    const { card, smallMargnBoth, directedHighlight, publicHighlight } =
      classes;
    // all cards have card class
    // all responses have smallMargnBoth
    // selected messages are highlighted

    let cardClasses = card;
    isTopicMsg || (cardClasses += ` ${smallMargnBoth}`);
    isHighlighted
      ? (cardClasses += ` ${
          isDirectedMsg ? directedHighlight : publicHighlight
        }`) // selected card
      : (cardClasses += ` ${classes.unSelected}`); // unselected card
    return cardClasses;
  };

  const markerClasses = () => {
    const { playedMarker, playedMarkerPublic, playedMarkerDirected } = classes;
    return `${playedMarker} : ${
      isDirectedMsg ? playedMarkerDirected : playedMarkerPublic
    }`;
  };

  const titleClasses = () => {
    const { title, titleTopicHighlighted, titleResponseHighlighted } = classes;
    return `${title} ${
      isHighlighted &&
      (isTopicMsg ? titleTopicHighlighted : titleResponseHighlighted)
    }`;
  };

  // preamble to the user screen name in the message description
  const authorIntro = isDirectedMsg
    ? `${t(isOutbound ? 'to' : 'from')}:`
    : `${t(type)} ${t('by')}:`;

  // name to use in the message description
  const name = isOutbound ? message?.recipientScreenName : screenName;

  // user has clicked on the user, requesting info about the member
  const handleNameClick = ev => {
    ev.cancelBubble = true;
    ev.stopPropagation && ev.stopPropagation();
    dispatch(uiActions.voicePlayerPause());
    setNameHover(false);
    onMemberInfoRequest && onMemberInfoRequest(message?.userId);
  };

  // Render the author's name, optionally clickable
  const wrappedName = () =>
    enableClickOnUserButton && nameHover && message?.userId ? (
      <Button
        variant="outlined"
        onMouseEnter={() => setNameHover(true)}
        onMouseLeave={() => setNameHover(false)}
        onClick={handleNameClick}
      >
        {name}
      </Button>
    ) : (
      <div
        style={{ display: 'inline-block' }}
        onMouseEnter={() => setNameHover(true)}
        onMouseLeave={() => setNameHover(false)}
      >
        {name}
      </div>
    );
  const renderTitle = () => (
    <Typography classes={{ root: titleClasses() }} variant="body1">
      {isDirectedMsg ? `"${title}"` : title}
    </Typography>
  );

  const renderUser = () => (
    <Typography color="textSecondary" classes={{ root: classes.user }}>
      {authorIntro} {wrappedName()}
      <AuthorAnnotation message={message} />{' '}
      {` -- (${timeSince(new Date(message.created))} ${t('ago')})`}
    </Typography>
  );

  // render user-first for directed message
  const renderMsgInfo = () =>
    isDirectedMsg ? (
      <>
        {renderUser()}
        {renderTitle()}
      </>
    ) : (
      <>
        {/* disabled <div style={{ position: 'absolute', top: 18, left: 3 }}><LiveAnnotation message={message} /></div>  */}
        {renderTitle()}
        {renderUser()}
      </>
    );

  // standard layout
  // left: title, user, actions
  // right: expander
  const stdLayout = () => (
    <Grid container justify="space-between" alignItems="center">
      <Grid item sm={10} md={10}>
        {renderMsgInfo()}
        {
          <div style={{ position: 'relative', left: -10 }}>
            {isSelected && includeActions && actions()}
          </div>
        }
      </Grid>
      <Grid item>
        {hideResponseIndicator ? null : (
          <ResponseIndicator {...responseIndicatorProps()} />
        )}
      </Grid>
    </Grid>
  );

  // revised layout, expander on left
  const layoutV2 = () => (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item style={{ width: isTopicMsg ? 34 : 0 }}>
            {hideResponseIndicator ? null : (
              <ResponseIndicator {...responseIndicatorProps()} />
            )}
          </Grid>
          <Grid item> {renderMsgInfo()}</Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div> {isSelected && includeActions && actions()}</div>
      </Grid>
    </Grid>
  );

  // xs breakpoint layout
  // 3 rows: title, user, actions/expander
  // (presently bypassed/unused )
  const xsLayout = () => (
    <div>
      {renderMsgInfo()}
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <div style={{ position: 'relative', left: -10 }}>
            {isSelected && actions()}
          </div>
        </Grid>
        <Grid item>
          <ResponseIndicator {...responseIndicatorProps()} />
        </Grid>
      </Grid>
    </div>
  );

  // Apply decoration to an open thread
  // Since a TopicCard only renders a single message, all TopicCards in a thread must cooperate.
  // The goal is to show the thread in a container with border and background color.
  // NOTE: consider refactoring Playlist to apply thread border at a higher level
  const decorationWidth =
    cardRef.current?.clientWidth - (scrollbarPresent ? 10 : 0);

  const threadDecoration = () => (
    <div
      // create an empty div the size of this ToipicCard, and account for vertical margin and possible horizontal scrollbar
      style={{
        height: cardRef.current?.clientHeight + 1.5 * cardVerticalMargin + 10,
        width: cardRef.current?.clientWidth - (scrollbarPresent ? 10 : 0),
      }}
      className={`
            ${
              isTopicMsg
                ? classes.threadBorderTopic
                : classes.threadBorderResponse
            }
            ${
              isDirectedMsg
                ? classes.threadBorderResponseBackgroundDirected
                : classes.threadBorderResponseBackgroundPublic
            }
        `}
    ></div>
  );

  const marginIndicator = message =>
    isDirectedType(message.type) ? (
      <NewInboundMessageIndicator message={message} isOpen={isOpen} />
    ) : id === '256651a4-ff28-4794-a636-137ae6e323bc' ? (
      <IconButton onClick={() => history.push('/host/nelly')}>
        <Avatar
          alt="Nelly"
          src="/images/nelly/nelly2a.png"
          className={classes.avatar}
        />
      </IconButton>
    ) : (
      <LiveCallInitiatorButton message={message} />
    );

  const renderMessage = ({ message, decorate }) =>
    // display a Topic message
    message ? (
      <Element name={id}>
        <Grid container alignItems="center">
          <Grid item style={{ width: marginIndicatorWidth }}>
            {marginIndicator(message)}
          </Grid>
          <Grid
            item
            style={{ width: `calc(100% - ${marginIndicatorWidth}px)` }}
          >
            <div
              ref={cardRef}
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={select}
            >
              <Card
                id="joecard"
                classes={{ root: cardClasses_v2() }}
                elevation={3}
              >
                {played() ? <div className={markerClasses()}></div> : null}
                <CardContent style={{ paddingBottom: 6, paddingTop: 4 }}>
                  {/* experimental disable of differential layout */}
                  {false && xs ? xsLayout() : layoutV2()}
                </CardContent>
              </Card>
              {decorate && threadDecoration()}
            </div>
          </Grid>
        </Grid>
      </Element>
    ) : (
      <Element></Element>
    );
  return isTopicMsg || displayAsRoot ? (
    renderMessage({ message, decorate: isOpen })
  ) : (
    // collapse a response message unless its parent is open
    <Collapse in={isParentOpen} collapsedSize={0} onExited={() => {}}>
      {renderMessage({ message, decorate: true })}
    </Collapse>
  );
}

TopicCard.propTypes = {
  message: PropTypes.object,
  selected: PropTypes.func,

  //not supplied by Playlist_v1
  isPlaying: PropTypes.bool,
  includeActions: PropTypes.bool, // include actions within card

  select: PropTypes.func,
  onMemberInfoRequest: PropTypes.func,
  isOpen: PropTypes.bool,
  played: PropTypes.func, // message has been played to completion
  toggleOpen: PropTypes.func,
  onActionsEvent: PropTypes.func,
  scrollbarPresent: PropTypes.bool, // scrollbar is present
  hideResponseIndicator: PropTypes.bool,

  //notsupplied by Playlist_v1
  displayAsRoot: PropTypes.bool, // force message to display independently of parent
  noteHeight: PropTypes.func, // report component height on rendering
  centered: PropTypes.bool, // eliminate offset margins
};

TopicCard.defaultProps = {
  scrollbarPresent: false,
  played: () => false,
  selected: () => true,
  message: {
    id: null,
    responses: null,
    title: null,
    type: null,
    screenName: null,
    hideResponseIndicator: false,
  },

  //not supplied by Playlist_v1
  includeActions: true,
  displayAsRoot: false,
  centered: false,
};
