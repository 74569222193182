/**
 * simple encryption utilities
 *
 * simple-crypto-js
 *
 * repo
 * https://github.com/danang-id/simple-crypto-js
 *
 * doc
 * https://simplecrypto.js.org/
 * https://simplecrypto.js.org/docs/
 * https://stackoverflow.com/questions/51984146/how-to-disable-source-maps-for-react-js-application
 *
 * install
 * yarn add simple-crypto-js
 */

import SimpleCrypto from 'simple-crypto-js';
const _secretKey = '80951b23fbc781f9a4a754ffc1928dcf'; // generated by SimpleCrypto.generateRandom()
const simpleCrypto = new SimpleCrypto(_secretKey);

/*
export const cryptoTest = () => {

    const plainObject = {
      key1: "This is a test.",
      key2: true,
    }
    const encryptedObject1 = simpleCrypto.encrypt(plainObject)
    const encryptedObject2 = simpleCrypto.encrypt(plainObject)

    console.log("Plain Object     : ", plainObject)
    console.log("Encrypted Object1 : ", encryptedObject1)
    console.log("Encrypted Object2 : ", encryptedObject2)

    // decrypt() function will automatically detects and returns data with the proper data type
    const decryptedObject1 = simpleCrypto.decrypt(encryptedObject1)
    const decryptedObject2 = simpleCrypto.decrypt(encryptedObject2)
    console.log("Decrypted Object1 : ", decryptedObject1)
    console.log("Decrypted Object2 : ", decryptedObject2)
}
*/

export const encrypt = plainObject => {
  let rslt = null;
  try {
    rslt = simpleCrypto.encrypt(plainObject);
  } catch (error) {
    //console.log("encrypt error:", error)
  }
  return rslt;
};

export const decrypt = encryptedObject => {
  let rslt = null;
  try {
    rslt = simpleCrypto.decrypt(encryptedObject);
  } catch (error) {
    //console.log("decrypt error:", error)
    // decrypt error: Error: There is no data provided. Process halted.
    // decrypt error: Error: Invalid encrypted text received. Decryption halted.
  }
  return rslt;
};

// NOTE: this provides weak encryption of an integer string
// reversably obfuscate a string representing a non-negative integer preserving the string length
// algorithm: add random modulo 10 no. to each digit, and flip digits pair-wise
export const obfuscateDigitString = (inStr, encode = true) => {
  // helpers
  const randomDigitArray = [
    7, 9, 1, 0, 7, 0, 5, 8, 5, 4, 0, 0, 4, 2, 4, 2, 8, 9, 1, 5, 4, 3, 7, 4, 2,
    5, 2, 4, 4, 2, 6, 8, 4, 7, 2, 2, 1, 1, 1, 3, 7, 8, 7, 4, 1, 5, 7, 5, 0, 1,
    2, 5, 1, 9, 7, 3, 7, 4, 9, 6, 3, 5, 9, 2,
  ];

  // pairwise reverse position of elements in an array
  // e.g. [a,b,c,d, ...] flips to [b,a,d,c, ...]
  const flip = arr =>
    arr.reduce((out, item, ix, inp) => {
      const nextOut = inp[ix % 2 ? ix - 1 : ix + 1];
      return [...out, nextOut || inp[ix]]; // for odd length arr, cannot flip last item
    }, []);

  const isNonNegInteger = str => {
    const num = Number(str);
    return typeof str === 'string' && Number.isInteger(num) && num >= 0;
  };

  // generate a modulo 10 offset to encode or decode one digit
  const offset = ix =>
    10 + (encode ? randomDigitArray[ix] : -randomDigitArray[ix]);

  // only process a string representing a non-negative integer
  if (isNonNegInteger(inStr) && inStr.length <= randomDigitArray.length) {
    // convert each char to int, add random mod 10 offset, convert back to char

    // flip input before applying offset during encode
    // flip result after reversing offset during decode
    const outArr = encode
      ? flip([...inStr]).map((digit, ix) =>
          ((parseInt(digit) + offset(ix)) % 10).toString()
        )
      : flip(
          [...inStr].map((digit, ix) =>
            ((parseInt(digit) + offset(ix)) % 10).toString()
          )
        );
    return outArr.join('');
  }
  return null;
};
