/**
 * Invoke react router and render main content
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PlaylistView from 'containers/PlaylistView'; //browse set of messages
import MembershipView from 'containers/MembershipView'; //view community membership list
import PlayViewManager from 'containers/PlayViewManager'; //play individual message
import MessageRecorder from 'containers/MessageRecorder';
import VoiceRecorder from 'components/VoiceRecorder';
import Settings from 'components/Settings';
import Info from 'components/Info';
import WelcomeView from 'containers/WelcomeView';
import SupportView from 'containers/SupportView';
import Diagnostic from 'components/utils/Diagnostic';
import DemoSelector from 'components/DemoSelector';
import SigServerLog from 'components/utils/SigServerLog';
import DebugView from 'containers/DebugView';
import InboxView from 'containers/InboxView';
import Page from 'components/Page';
import Scroller from 'components/utils/Scroller';
//import GoogleRedirect from 'experiments/GoogleRedirect';
import Landing from 'components/Landing';
import Nelly from 'experiments/Nelly';
import NellyMais from 'experiments/NellyMais';
import AccountZapper from 'components/Settings/components/AccountZapper';
import MembershipLevelsView from 'containers/MembershipLevelsView';
import PaypalSuccess from 'containers/MembershipLevelsView/components/PaypalSuccess';

const enableSigServerLog = false;

//TODO2:  add default route
const Router = () => {
  const user = useSelector(state => state.user);
  const { pSession, enableDebugView } = useSelector(state => state.ui);
  const { welcomed } = pSession;
  const isAuthenticated = user.isAuthenticated;

  return (
    <Switch>
      {/*
                  NOTE: component prop cannot be set to a functional component
                  see https://material-ui.com/guides/composition/#caveat-with-refs
                  kludge: https://stackoverflow.com/questions/37959896/warning-failed-proptype-invalid-prop-component-supplied-to-route

       */}

      {/* <Route path="/debug" render={props => (enableDebugView ? <DebugView /> : <WelcomeView />)} /> */}
      <Route path="/host/nelly/mais" render={props => <NellyMais />} />
      <Route path="/host/nelly" render={props => <Nelly />} />

      <Route
        path="/doc/terms"
        render={props => (
          <Scroller>
            <Page title={'Terms and Conditions'} />
          </Scroller>
        )}
      />
      <Route
        path="/doc/privacy"
        render={props => (
          <Scroller>
            <Page title={'Privacy Policy'} />
          </Scroller>
        )}
      />

      <Route
        path="/welcome/:partition"
        render={props => (
          <WelcomeView partition={props.match.params.partition} />
        )}
      />
      <Route path="/welcome" render={props => <WelcomeView />} />
      {/* vvvvvvvvvvvvv       recorder routes  vvvvvvvvvvvvvvvvvvvvv  */}
      {/* record specific type */}
      <Route
        path="/create/:type"
        render={props => (
          <MessageRecorder
            readyNoCancel={true}
            typeOverride={props.match.params.type}
          />
        )}
      />
      {/* select from available types */}
      <Route
        path="/create"
        render={props => <MessageRecorder readyNoCancel={true} />}
      />
      {/* record a post, minimalist  first screen  */}
      <Route
        path="/record"
        render={props => (
          <MessageRecorder
            readyNoCancel={true}
            typeOverride="post"
            enableTwoStage
          />
        )}
      />
      {/* low lever voice recorder test */}
      <Route path="/createV" render={props => <VoiceRecorder />} />
      {/* <Route path="/google_redirect" render={props => <GoogleRedirect />} />  */}
      {/* ^^^^^^^^^^^^^ END   recorder routes  ^^^^^^^^^^^^^^^^^^^^^  */}
      <Route path="/support" render={props => <SupportView />} />
      {/* Playlist routes
           - disable use of initialVisibility filter
           - add specific routes to select initial visibility
        */}
      {/* <Route path="/playlist/:filter" render={props => <PlaylistView  initialVisibility={props.match.params.filter} />} /> */}
      <Route
        path="/playlist/dm/new"
        render={props => <PlaylistView initialView="new" />}
      />
      <Route
        path="/playlist/dm"
        render={props => <PlaylistView initialView="dm" />}
      />
      <Route path="/playlist" render={props => <PlaylistView />} />
      <Route path="/inbox" render={props => <InboxView />} />
      <Route
        path="/members"
        render={props =>
          isAuthenticated ? <MembershipView /> : <PlaylistView />
        }
      />
      <Route
        path="/play/:id/:shareMethod"
        render={props => <PlayViewManager {...props} />}
      />
      <Route
        path="/play/:id"
        render={props => <PlayViewManager {...props} />}
      />
      <Route
        path="/info/:topic"
        render={props => <div>{`/info/${props.match.params.topic}`}</div>}
      />
      <Route path="/info" render={props => <Info {...props} />} />
      <Route
        path="/settings"
        render={props =>
          isAuthenticated ? <Settings {...props} /> : <WelcomeView />
        }
      />
      {/* login and register routes render WelcomeView with login or registration dialog respectively open   */}
      <Route
        path="/register/:partition"
        render={props => (
          <WelcomeView partition={props.match.params.partition} register />
        )}
      />
      <Route path="/register" render={props => <WelcomeView />} register />
      <Route
        path="/login/:partition"
        render={props => (
          <WelcomeView partition={props.match.params.partition} login />
        )}
      />
      <Route path="/login" render={props => <WelcomeView login />} />
      {/* <Route path="/demo" render={props => <DemoSelector {...props} />} />  */}
      {/*
       // development only:
        <Route path="/diagnostic" render={props =>  true ? <Diagnostic {...props} /> : <WelcomeView /> } />
        <Route path="/demo" render={props => <DemoSelector {...props} />} />
        <Route path="/sigserver" render={props => (enableSigServerLog ? <SigServerLog {...props} /> : <PlaylistView {...props} /> )} />
        <Route path='/caller'>
          <WebRTCDemo demoUser='caller'/>
        </Route>
        <Route path='/callee'>
          <WebRTCDemo demoUser='callee'/>
        </Route>
*/}
      {/* render WelcomeView, unless visitor has already been to WelcomeView */}

      {/* experimental landing page */}
      <Route path="/landing" render={props => <Landing />} />

      {/* Form to enable user to remove his/her account */}
      <Route
        path="/user/remove"
        render={props => <AccountZapper autoOpen={true} />}
      />

      <Route path="/membership" render={props => <MembershipLevelsView />} />
      <Route path="/paypal-success" render={props => <PaypalSuccess />} />
      <Route
        path="/"
        render={props =>
          welcomed ? <Redirect to="/playlist" /> : <Redirect to="/welcome" />
        }
      />
    </Switch>
  );
};

Router.propTypes = {};
Router.defaultProps = {};
export default Router;
