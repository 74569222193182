/**
 * Render Tip popover for Menu microphone
 * (open only once after specified number of pings )
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Grid } from '@material-ui/core';

// get configuration and classes data for rendering with arrows in each direction
import {
  useStylesDownArrow,
  configDownArrow,
} from '../lib/tipsPopoverConfigDown';
import {
  useStylesBackArrow,
  configBackArrow,
} from '../lib/tipsPopoverConfigBack';
import {
  useStylesForwardArrow,
  configForwardArrow,
} from '../lib/tipsPopoverConfigForward';

const TipsPopover = props => {
  const { open, onClose, anchorEl, arrow, text } = props;

  const classesPicker = {
    back: useStylesBackArrow(),
    forward: useStylesForwardArrow(),
    down: useStylesDownArrow(),
  };
  const classes = classesPicker[arrow];

  const configPicker = classes => ({
    back: configBackArrow(classes),
    forward: configForwardArrow(classes),
    down: configDownArrow(classes),
  });
  const config = configPicker(classes)[arrow];

  // insert an arrow in the designated place
  const applyArrow = content => (
    <>
      {config.iconPlacement === 'before' && (
        <Grid item xs={1}>
          {config.icon}
        </Grid>
      )}
      {content}
      {config.iconPlacement === 'after' && <Grid item>{config.icon}</Grid>}
    </>
  );

  // render the text in a Popover of the designated config and style
  const layout = (
    <Grid {...config.outerContainerProps}>
      {applyArrow(<Grid {...config.innerContainerProps}> {text} </Grid>)}
    </Grid>
  );

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      classes={{ root: classes.popoverPosition, paper: classes.popoverPaper }}
      onClose={onClose}
      anchorOrigin={config.anchorOrigin}
      transformOrigin={config.transformOrigin}
    >
      {layout}
    </Popover>
  );
};

TipsPopover.propTypes = {
  anchorEl: PropTypes.element,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  arrow: PropTypes.oneOf(['down', 'back', 'forward']),
  text: PropTypes.string,
};

TipsPopover.defaultProps = {};
export default TipsPopover;
