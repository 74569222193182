/**
 * TipsPopover configuration using Down arrow
 *
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

export const useStylesDownArrow = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  container: {},
  content: {
    textAlign: 'center',
    padding: '0 1rem',
    paddingTop: '1rem',
  },
  popoverPosition: {
    transform: 'translate(0px, -10px)',
  },
  popoverPaper: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    borderRadius: '30px',
    width: '20%',
    [theme.breakpoints.only('xs')]: {
      width: '50%',
    },
  },
}));

export const configDownArrow = classes => ({
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  arrow: 'down',
  icon: <ArrowDownwardIcon />,
  iconPlacement: 'after',
  outerContainerProps: {
    container: true,
    classes: { root: classes.container },
    justify: 'center',
  },
  innerContainerProps: {
    item: true,
    classes: { root: classes.content },
    xs: 12,
  },
});
