/**
 * DebugView
 *
 * Provide manual triggers and controls for certain diagnostic tests.
 *  * enable remote js console
 *  * single-step through login sequence
 *
 * The list of tool items along with their respective status is
 * stored in a single state object.
 *
 * The state object is implemented with react-usestateref so that
 * values can be read from within callback functions.
 *
 *
 */

import React, { useEffect, useRef } from 'react';
import useState from 'react-usestateref';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {
  Grid,
  Button,
  Divider,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import Cookies from 'js-cookie';
import IdentityDialog from './components/IdentityDialog';
import { authenticator } from 'lib/utils/authenticator';
import { socToD8Creds, getHvnUser } from 'lib/api/userApi';
import { getDialogs } from 'lib/api/messageApi';
import { makeArray } from 'lib/utils/utils';
import { diagnosticGetDirectedAllPartitionsUrl } from 'lib/api/jsonApi';
import { fetcher } from 'lib/utils/fetcher';
import { dinger } from 'lib/classes/Dinger';

const { getToken } = authenticator();

const useStyles = makeStyles(theme => ({
  progress: {
    marginLeft: 100,
  },
  checkbox: {
    'color': green[400],
    '&$checked': {
      color: green[600],
    },
  },
}));

export default function Debug() {
  const classes = useStyles();
  const progress = (
    <div className={classes.progress}>
      <CircularProgress />{' '}
    </div>
  );
  const t0Ref = useRef(0); // time reference for latency measurement

  // Event Handlers -------------------------------------------------------------------------------------------------

  // clear storage and cookies, perform hard reload
  const handleReset = () => {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove('CookieConsent');
    window.location.reload(true); // not all browsers do a hard reload
  };

  // get an oAuth token for the specified user from the HVN server
  const handleTokenTest = () => {
    updateTools('token', { display: progress });
    return getToken(socToD8Creds(getValue('socId')))
      .then(authObject => {
        updateTools('token', {
          value: authObject,
          display: authObject.token_type,
          latency: new Date() - t0Ref.current,
        });
        updateTools('user', { disabled: false });
        updateTools('DM', { disabled: false });
      })
      .catch(err => {
        console.log('getToken error:', err);
        updateTools('token', { display: 'token error' });
      });
  };

  // get profile info for the authenticated user
  const handleUserTest = () => {
    updateTools('user', { display: progress });
    return getHvnUser({ name: socToD8Creds(getValue('socId'))?.username })
      .then(userData => {
        updateTools('user', {
          value: userData,
          display: userData.screen_name,
          latency: new Date() - t0Ref.current,
        });
      })
      .catch(err => {
        console.log('getHvnUser error:', err);
        updateTools('user', { display: 'user error' });
      });
  };

  // get public dialogs
  const handlePublicTest = () => {
    updateTools('public', { display: progress });
    return getDialogs('public')
      .then(data => {
        updateTools('public', {
          value: makeArray(data).length,
          display: `${makeArray(data).length} messages`,
          latency: new Date() - t0Ref.current,
        });
      })
      .catch(err => {
        console.log('getDoa;pgs error:', err);
        updateTools('public', { display: 'public dialogs error' });
      });
  };

  // get DM dialogs
  const handleDMTest = () => {
    updateTools('DM', { display: progress });
    const user = getValue('user');
    return fetcher(
      'jsonapi',
      'GET',
      diagnosticGetDirectedAllPartitionsUrl(user.id)
    )
      .then(({ data }) => {
        updateTools('DM', {
          value: makeArray(data).length,
          display: `${makeArray(data).length} DMs`,
          latency: new Date() - t0Ref.current,
        });
      })
      .catch(err => {
        console.log('getDMs error:', err);
        updateTools('DM', { display: 'DM dialogs error' });
      });
  };

  // process the activation of remote console
  // this cannot be undone without a re-render of the application
  const handleRemoteConsoleChange = event => {
    if (event?.target?.checked) {
      updateTools('Remote Console', {
        value: true,
        display: 'Remote Console ON',
        disabled: true,
      });
    }
  };

  // start or stop periodic play of "ding" sound
  const handleDingerChange = event => {
    if (getValue('Dinger')) {
      updateTools('Dinger', { value: false, display: 'Dinger' });
      clearInterval(dingIntervalRef.current);
    } else {
      updateTools('Dinger', { value: true, display: 'Dinging...' });
      dinger.play();
      dingIntervalRef.current = setInterval(() => {
        dinger.play();
      }, 5000);
    }
  };
  const dingIntervalRef = useRef(null);

  // install the agent to support remote monitor of console.log
  const installRemoteConsole = () => {
    var s = document.createElement('script');
    s.src = 'https://remotejs.com/agent/agent.js';
    s.setAttribute(
      'data-consolejs-channel',
      '7334ee4c-fcb5-898c-3cbf-1fcdf933fbeb'
    );
    document.head.appendChild(s);
  };

  // render IdentityDialog to obtain social ID
  const handleSocIdTest = () => {
    updateTools('socId', {
      display: <IdentityDialog open={true} onIdentity={updateSocId} />,
    });
  };

  // process result from IdentityDialog
  const updateSocId = socId => {
    updateTools('socId', {
      value: socId,
      display: socId,
      latency: new Date() - t0Ref.current,
    });
    socId && updateTools('token', { disabled: false });
  };

  // State Management -------------------------------------------------------------------------------------------------

  // update the state for the specified tool
  const updateTools = (label, updates) => {
    setTools(tools =>
      tools.map(tool => (tool.label === label ? { ...tool, ...updates } : tool))
    );
  };

  // get the value for the specified tool
  // might be invoked from a callback, so this must use ref.current for the state
  const getValue = label => {
    const tool = toolsRef.current.find(t => t.label === label);
    const value = tool?.value;
    //console.log(`getValue label=${label}, value=${value}`) ;
    return value;
  };

  // collection of available tools, in order of rendering
  const [tools, setTools, toolsRef] = useState([
    {
      type: 'button',
      label: 'reset',
      value: null,
      display: null,
      latency: 0,
      handler: handleReset,
    },
    {
      type: 'button',
      label: 'socId',
      value: null,
      display: null,
      latency: 0,
      handler: handleSocIdTest, //launch dialog
    },
    {
      type: 'button',
      label: 'token',
      value: null,
      display: null,
      latency: 0,
      handler: handleTokenTest,
      disabled: true,
    },
    {
      type: 'button',
      label: 'user',
      value: null,
      display: null,
      latency: 0,
      handler: handleUserTest,
      disabled: true,
    },
    {
      type: 'button',
      label: 'public',
      value: null,
      display: null,
      latency: 0,
      handler: handlePublicTest,
    },
    {
      type: 'button',
      label: 'DM',
      value: null,
      display: null,
      latency: 0,
      handler: handleDMTest,
      disabled: true,
    },
    {
      type: 'checkbox',
      label: 'Dinger',
      value: null,
      display: 'Dinger',
      latency: 0,
      handler: handleDingerChange,
      disabled: false,
    },
    {
      type: 'checkbox',
      label: 'Remote Console',
      value: null,
      display: 'Remote Console',
      latency: 0,
      handler: handleRemoteConsoleChange,
      disabled: false,
    },
  ]);

  // install the remote console daemon when first enabled
  // to monitor: https://remotejs.com/viewer/7334ee4c-fcb5-898c-3cbf-1fcdf933fbeb
  const remoteConsoleEnable = tools.find(
    t => t.label === 'Remote Console'
  ).value;
  useEffect(() => {
    remoteConsoleEnable && installRemoteConsole();
  }, [remoteConsoleEnable]);

  // Rendering Helpers -------------------------------------------------------------------------------------------------

  // render a tool line item
  const renderTool = tool => (
    <Grid item container alignItems="center">
      {tool?.type === 'button' && renderButtonTool(tool)}
      {tool?.type === 'checkbox' && renderCheckboxTool(tool)}
      <Divider style={{ width: '100%', margin: 10 }} />
    </Grid>
  );

  // render a button-type tool
  const renderButtonTool = tool => (
    <>
      <Grid item style={{ width: 100 }}>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            t0Ref.current = new Date();
            tool.handler && tool.handler();
          }}
          disabled={tool.disabled}
        >
          {tool.label}
        </Button>
      </Grid>
      <Grid item>
        <div style={{ display: 'inline' }}> {tool.display} </div>
        <div
          style={{ color: 'green', display: tool.latency ? 'inline' : 'none' }}
        >
          {' '}
          {`  (${tool.latency} ms.)`}
        </div>
      </Grid>
    </>
  );

  // render a checkbox-type tool
  const renderCheckboxTool = tool => (
    <>
      <Grid item style={{ width: 100 }}>
        <Checkbox
          name="tool.label"
          checked={tool.value}
          onChange={tool.handler}
          disabled={tool.disabled}
        />
      </Grid>
      <Grid item>{tool.display}</Grid>
    </>
  );

  return (
    <>
      <h1>Debug Tools</h1>
      <Grid container>{tools.map(tool => renderTool(tool))}</Grid>
    </>
  );
}
