/**
 * AnimationDecoration
 * Bounce the children if props.enable is asserted
 *
 *  Animation alternatives, consider:
 *  https://codepen.io/callmenick/pen/XdwmeV
 *  https://bbbootstrap.com/snippets/bootstrap-ringing-94422307
 *  https://embed.plnkr.co/plunk/wWUhYu
 *  https://www.npmjs.com/package/react-pulse-ring-animation
 *  https://codepen.io/angeladelise/pen/VwLJpWL

 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@keyframes bounce': {
    '0%': { transform: 'translateY(0)' },
    '20%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(0)' },
    '80%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(0)' },
    '40%': { transform: 'translateY(-15px)' },
    '60%': { transform: 'translateY(-8px)' },
  },
  'bounceItem': {
    animation: '$bounce 2.5s both infinite',
  },

  // based on: https://bbbootstrap.com/snippets/bootstrap-ringing-94422307
  '@keyframes wobble': {
    '0%': { transform: 'rotate(-15deg)' },
    '2%': { transform: 'rotate(15deg)' },
    '4%': { transform: 'rotate(-18deg)' },
    '6%': { transform: 'rotate(18deg)' },
    '8%': { transform: 'rotate(-22deg)' },
    '10%': { transform: 'rotate(22deg)' },
    '12%': { transform: 'rotate(-18deg)' },
    '14%': { transform: 'rotate(18deg)' },
    '16%': { transform: 'rotate(-12deg)' },
    '18%': { transform: 'rotate(12deg)' },
    '20%': { transform: 'rotate(0deg)' },
  },
  'wobbleItem': {
    animationDuration: '2s',
    animationFillMode: 'ease',
    animationIterationCount: 'infinite',
    animationName: '$wobble',
  },

  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 },
  },

  'blinkItem': {
    animation: '$blink 1s steps(1, end) infinite',
  },
}));

const AnimationDecoration = props => {
  const { enable, action } = props;

  const classes = useStyles();

  const animationClass = () =>
    (action === 'bounce' && classes.bounceItem) ||
    (action === 'wobble' && classes.wobbleItem) ||
    (action === 'blink' && classes.blinkItem) ||
    null;

  return (
    <div className={enable ? animationClass() : null}>{props.children}</div>
  );
};

AnimationDecoration.propTypes = {
  enable: PropTypes.bool,
  action: PropTypes.oneOf('bounce', 'wobble', 'blink'),
  children: PropTypes.node,
};
AnimationDecoration.defaultProps = {
  enable: false,
};
export default AnimationDecoration;
