/**
 * Manage user registration and login workflow
 * //@@ NOTE: some redundancy with AttributesSelector
 *
 * Use Cases:
 *      MessageRecorder
 *      LogRegDialog
 *          login button
 *          Join button (WelcomeView)
 *          Play private message (PlayView)
 *          BookmarkActionDialog

 *
 * RecordingBufferContext provides a buffer and handler for the Recorder
 * in order to support the recording of a prospective Profile before an account is created
 *
 * User Status:
 * anonymous (never registered with OkSayit)
 * registered (has become a member of at least one community)
 * member (has become a member of the current community)
 *
 * Constraints:
 *  1. must obtain consent before creating account
 *  2. must create account before saving Profile recording
 *  3. only gated communities permit password authentication
 *  4. gated communities mandate an invitation code prior to registration or first login
 *  5. when invoked from the Recorder, RegistrationManager
 *     will not offer new registrants the opportunity to creaate a
 *     Profile during that workflow. (prevenbt circular reference)
 *
 *  Layout:
 *  Can optionally render in a dialog (e.g. when "Login" button is prssed),
 *  A static header with scrollable fixed content area are maintained, avoiding full-document scrollbar
 *  Registration process consists of one or more pages, each with one or more sections and a submit button
 *  Paginated workflow
 *   - community-specific workflow specified by community.params field
 *   - sequence of pages rendered individually
 *   - sections within page
 *   - fields within section
 *   - submit button or auth-submit per page
 *
  *  Omissions:
 *  - handleLoginFailure, handleRegistrationFailure exceptions must be implemented
 *
 *  Punchlist:
 *  - test/debug tabs (not tested when multi-lang was implemented)
 *  - auto submit with authenticate page causes iteration
 *  - test if D8 accepts spaces in username/password
 *  - auto-advance if only one authMethod available
 *  - registration error 422 must throw an exception
 *  - password registration needs to validate invite code
 *  - refactor handlePageTransition, make it data driven
 *  - retest refactored handlePageTransition for 'authenticate' case
 *  - do NOT prompt for screen name when registered user is joining a community
 *  - upw redundant registration is only caught at very end.  Not friendl
 *  - returning non-member fails because k8 username derived from original community
 *
 *  - functional
 *  - save user attributes per-partition
 *  - screen name is not partition-dependent, and not editable
 *  - during google login, brief flash "Authenticating... This authenticator is not yet available !"
 *  - fails to logout when routing to /register/lextalk
 *  - wrong profile played during google registration of LabGirl
 *  - "not now" button should trigger submit for ProfileMessageManager
 *  - during login, if no profile message offer opportunity
 *  - on membership page, for user w/o profile, mic icon triggers problematic recorder
 *
 *  - UI
 *  - center progress circle within Login dialog
 *  - bypass spurious transient state after successful login
 *  - helper text for public screen name
 *  - add section labels back in for desktop
 *  - awkward placement of help icon for Profile recording
 *  - change 'Login' banner to be context sensitive
 *  - auto submit Terms&Conditions
 *  - friendlier font for T&C
 *  - confirm success with alert
 *  - consider placing Submit button in footer of dialog
 *  - long delay for confirmation
 *  - for xs, ProfileMessageManager display is garbled
 *  - Welcome page, xs: wasted space under main menu with no src is present
 *
 *
 */

/*
Test Cases
via router
    socauth, open, returning, non member
    socauth, open, returning, member
    socauth, open, ftu
    socauth, gated, returning, non member
    socauth, gated, returning, member
    socauth, gated, ftu
    password, gated, returning, non member
    password, gated, returning, member
    password, gated, ftu

via Login Button
    socauth, open, returning, non member
    socauth, open, returning, member
    socauth, open, ftu
    socauth, gated, returning, non member
    socauth, gated, returning, member
    socauth, gated, ftu
    password, gated, returning, non member
    password, gated, returning, member
    password, gated, ftu

via mobile
    (repeat all)

*/

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { userActions } from 'store/user-slice';
import { defaultCommunity } from 'configs/config-hvn';
import RecordingBufferContext from 'context/recordingBufferContext';
import LogRegBanner from './components/LogRegBanner';
import LogRegPage from './components/LogRegPage';
import LogRegTabs from './components/LogRegTabs';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import {
  validateProposedUsername,
  validateProposedPassword,
  validateProposedInvitation,
  validateProposedSocAuth,
} from './lib/validation';
import {
  getCommunity,
  switchCommunity,
  appendCommunityParams,
  updatedAggregateUserParams,
} from 'lib/api/communityApi';
import {
  createSocialAuthenticatedAccount,
  createPasswordAuthenticatedAccount,
  patchHvnUser,
  loginHvnUser,
  loginSocAuthUser,
  logout,
} from 'lib/api/userApi';
import { submitMessage } from 'lib/api/submitMessage';
import { makeArray } from 'lib/utils/utils';

//@@ duplicated in RegistrationManager
import {
  regHelpers,
  RegPages,
} from 'containers/RegistrationManager/lib/registrationUtils';
import { t } from 'lib/translation/trans';
const regUtils = regHelpers();

const pageConfig = {
  // used when rendered with route
  enableBanner: false,
  enableOverflow: false,
};

// Handle Community Registration and Login workflow
// community sselected by by props, or by redux, or default
const RegistrationManager = props => {
  const cookiesAccepted = Boolean(
    useSelector(state => state.ui.pSession.cookiesAcceptedTime)
  );
  const { holdoffCookieConsentBar } = useSelector(state => state.ui);

  const requireCookieAcceptance = !cookiesAccepted && !holdoffCookieConsentBar;

  const {
    partition: propsPartition,
    renderWithinDialog,
    onComplete,
    bypassGetAttributes,
    zen,
    onIdentity,
  } = props;
  const [pageKey, setPageKey_] = useState(null); // current page being rendered
  const setPageKey = key => {
    props.notePage && props.notePage(key);
    setPageKey_(key);
  };

  const [options, setOptions] = useState(null);
  const [exception, setException] = useState(null); // uhoh
  const [submitArgs, setSubmitArgs] = useState(null); // profile message data, cached until account is created
  const [success, setSuccess] = useState(false); // trigger dismount of component upon successful workflow
  const [enableTabs, setEnableTabs] = useState(false); // tabs can be turned on per workflow needs

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.user);
  const user = useSelector(state => state.user.auth.user);
  const { pSession, layout, deviceInfo, regDialog } = useSelector(
    state => state.ui
  );
  const nonScrollingSectionRef = useRef(null);

  const { community } = pSession;
  const { partition, gated } = community;

  const totalAvailableHeight = layout.mainContentHeight;
  const nonScrollingSectionHeight =
    nonScrollingSectionRef?.current?.clientHeight;
  const scrollableSectionMaxHeight =
    totalAvailableHeight - nonScrollingSectionHeight;

  // create a reference to hold the pages object, to be instantiated in useEffect
  const [updateCnt, setUpdateCnt] = useState(0); // used to cause re-render
  const pagesObj = useRef();
  const pg = pagesObj.current; // a shorthand reference to the  pages object
  const { langCode, langMap: t1 } = useSelector(state => state.lang);
  const page = pg && pg.getPage(pageKey);

  // allow pagesObj to trigger re-rendering of components by updating reRender state
  const reRender = () => setUpdateCnt(cnt => ++cnt);

  // do not read community info if no propsPartiion provided
  const useCurrentCommunity =
    community && (propsPartition === community?.partition || !propsPartition);

  const customTitle = submitArgs?.recording?.title;

  // initialization upon component mount
  useEffect(() => {
    // make sure user is logged out
    if (isAuthenticated) {
      logout();
    }

    const initializeWorkflow = community => {
      //@@ must implement...  restrictAuthMethods(pagesWithOverrides, community)
      pagesObj.current = new RegPages({ partition, user, refreshFn: reRender });
      restrictAuthMethods(pagesObj.current, community?.gated);
      setPageKey('getAuthMethod');
      setOptions(pagesObj.current.options);
    };

    // render initial page of login/registration sequence
    // and initialize workflow
    if (useCurrentCommunity) {
      // if mounted in response to 'Login' button
      // community configuration isd already in redux store
      initializeWorkflow(community);
    } else {
      // if view mounted by Router at specified in route

      /**
       *  - hide distracting layout elements pending login (unless rendering in a dialog)
       *  - derive community key from the route
       *  - fetch community-specific configuration
       */
      renderWithinDialog ||
        dispatch(
          uiActions.layoutComponentHide({
            menu: true,
            login: true,
            community: true,
          })
        );
      dispatch(uiActions.backdropSet(`${t('Please_Wait')}...`));
      // fetch the community information
      // use partition from props, if none use redux, if none use defaultPartition
      getCommunity(propsPartition || partition || defaultCommunity?.partition)
        .then(community => {
          if (community) {
            switchCommunity(community);
            initializeWorkflow(community);
            dispatch(uiActions.backdropClr());
          }
        })
        .catch(err => {
          console.log('getCommunity error:', err);
        });
    }

    // cleanup upon dismount
    return () => {
      dispatch(
        uiActions.layoutComponentHide({
          menu: false,
          login: false,
          community: false,
        })
      );
      dispatch(uiActions.backdropClr());
    };
  }, []);

  // re-translate pg when langCode changes
  useEffect(() => {
    pg && pg.translate(langCode);
  }, [langCode]);

  useEffect(() => {
    success && handleSuccess();
  }, [success]);

  // EVENT HANDLERS ---------------------------------------------------------------------------

  const handleSuccess = () => {
    if (!renderWithinDialog) {
      onComplete && onComplete({ status: 'success' });
    } else {
      dispatch(uiActions.setRegDialog({ open: false, mode: null }));
    }
  };

  const handleTabChange = key => {
    setPageKey(key);
  };

  const handleFieldChange = (key, value) => {
    pg && pg.setFieldValue({ key, value });
    props.noteField && props.noteField(key, value);
  };

  const handleProfileRecorded = data => {
    setSubmitArgs(data);
  };

  // organize field values, validate, take appropriate action
  const handlePageSubmit = () => {
    /*
        // normalize field data into array of key:value pairs
        const reduce1 = (acc1, s) => {
            const rslt1 = s.fields.reduce((acc2, f) => reduce2(acc2, f), acc1)
            return rslt1
        }
        const reduce2 = (acc2, f) => {
            const rslt2 = [...acc2, { key: f.key, value: f.value }]
            return rslt2
        }
        const pageFieldsArray = page.sections
            .reduce((acc1, s) => reduce1(acc1, s), [])
        */

    const pageFieldsArray = page.sections.reduce(
      (acc1, s) =>
        s.fields.reduce(
          (acc2, f) => [...acc2, { key: f.key, value: f.value }],
          acc1
        ),
      []
    );

    const errObj = validateField(pageFieldsArray);
    if (errObj) {
      handleValidationException(errObj);
    } else {
      handlePageTransition()
        .then(nextPage => nextPage && setPageKey(nextPage))
        .catch(err =>
          console.log('handleValidatePageSubmitPromise error:', err)
        );
    }
  };

  // mark the offending field and open a dialog with exception report
  const handleValidationException = errObj => {
    setException(errObj);
    const { key } = errObj;

    // apply the error flag to the offending field
    page.sections.every(s =>
      s.fields.every(f => {
        return !(f.key === key && (f.error = true));
      })
    );
  };

  const handleExceptionClose = () => {
    setException(null);
    props.notePage && props.notePage('exceptionAck'); //advise parent that an exception has been acknowledged
  };

  // launch full page dialog with progress indicator
  // clear the dialog with falsey message
  const renderProgress = message => {
    // advise parent of progress if callback available in props
    if (props.renderRegistrationProgress) {
      props.renderRegistrationProgress(message);
    } else if (message) {
      // if no props callback, request full page progress through redux
      dispatch(uiActions.backdropSet(message));
    } else {
      dispatch(uiActions.backdropClr());
    }
  };

  // invoked after page fields have been validated
  // workflow branching and sequencing is controlled here
  const handlePageTransition = () =>
    new Promise((resolve, reject) => {
      const passwordRegistration =
        !isAuthenticated && getFieldValue('authMethod') === 'password';
      // apply backdrop until input promise is resolved
      const manageAsyncMethod = (promise, message) =>
        new Promise((resolve, reject) => {
          renderProgress(message);
          promise()
            .then(rslt => {
              renderProgress();
              resolve(rslt);
            })
            .catch(err => {
              renderProgress();
              reject(err);
            });
        });

      // bypass getAttributes page?
      const getAttributesConditionally = bypassGetAttributes
        ? 'getTermsConsent'
        : 'getAttributes';

      /* console.log(`handlePageTransition from ${pageKey}`) */
      switch (pageKey) {
        case 'getAuthMethod':
          // possible successors: authenticate, getCredsLogin, getInvitation
          if (getFieldValue('authMethod') === 'password') {
            // enable tabs, which will control rendering of next page
            setEnableTabs(true);
            resolve(null);
          } else {
            resolve('authenticate');
          }
          break;
        case 'authenticate':
          // this case is only for social auth.
          // for username/password auth see getCredsLogin

          // if onIdentity prop is provided, capture socId but
          // bypass rest of authentication sequence.
          if (onIdentity) {
            // Only obtain identity, bypass other authentication steps
            onIdentity(getFieldValue('authField')?.socId);
            setSuccess(true);
            resolve(null);
          } else {
            // normal authentication...

            // possible successors:  optionalGetUpdates, getInvitation, getAttributes'
            manageAsyncMethod(processExistingUser, t('Authenticating_now'))
              .then(user => {
                const isReturningUser = Boolean(user);
                const isMember = makeArray(user?.memberships).includes(
                  partition
                );

                if (!isReturningUser) {
                  // first time users must provide a screen name
                  resolve('screenNamePage');
                } else if (isMember) {
                  // members will skip invitation screening and initial attributes
                  resolve('optionalGetUpdates');
                } else {
                  resolve(gated ? 'getInvitation' : getAttributesConditionally);
                }
              })
              .catch(err => handleLoginFailure(err));
          }

          break;
        case 'screenNamePage':
          resolve(gated ? 'getInvitation' : getAttributesConditionally);
          break;
        case 'getInvitation':
          // possible successors: getAttributes, getCredsRegister
          resolve(
            passwordRegistration
              ? 'getCredsRegister'
              : getAttributesConditionally
          );
          break;
        case 'getAttributes':
          // possible successors: success, getTermsConsent
          if (passwordRegistration) {
            resolve('getTermsConsent');
          } else {
            if (isAuthenticated) {
              // update hvn and report success
              manageAsyncMethod(
                updateUserAccount,
                t('Updating_your_preferences')
              )
                .then(user => {
                  setSuccess(true);
                  resolve(null);
                })
                .catch(err => {
                  console.log('updateUserAccount error:', err);
                });
            } else {
              resolve('getTermsConsent');
            }
          }
          break;
        case 'optionalGetUpdates':
          //possible successors: success
          //@@ if values have changed, update user
          setSuccess(true);
          resolve(null);
          break;
        case 'getTermsConsent': //terms have been agreed to
          // possible successsors: success
          manageAsyncMethod(
            processNewUser,
            `${t('Setting_up_your_account')}...`
          )
            .then(user => {
              setSuccess(true);
              resolve(null);
            })
            .catch(err => {
              handleRegistrationFailure(err);
            });
          break;
        case 'getCredsLogin':
          // possible successors: die, optionalGetUpdates, getInvitation
          // possible successors:  optionalGetUpdates, getInvitation, getAttributes'
          manageAsyncMethod(processExistingUser, t('Logging_you_in'))
            .then(user => {
              if (user) {
                resolve(
                  makeArray(user?.memberships).includes(partition)
                    ? 'optionalGetUpdates'
                    : gated
                    ? 'getInvitation'
                    : getAttributesConditionally //gated test is unnecessary here
                );
              } else {
                // user unrecognized using password authentication
                handleLoginFailure();
              }
            })
            .catch(err => handleLoginFailure(err));
          break;
        case 'getCredsRegister':
          // possible successors: getAttributes
          resolve(getAttributesConditionally);
          break;

        // lang-en-fr-1 community prototypes
        case 'register':
          break;
        case 'login':
          break;
        case 'consent':
          break;
        case 'filter':
          break;
        default:
          // consider throwing exception
          break;
      }
    });

  // pre-append partition key to name entered by uiser in order to form d8 username
  const d8Username = username => (username ? `${partition}_${username}` : null);

  const d8Creds = () => {
    // for user who has already authenticated( social or SMS )
    const auth = getFieldValue('authField');

    // for user who has proposed credentials (username, password)
    const loginCreds = {
      username: d8Username(getFieldValue('username')),
      password: getFieldValue('password'),
    };
    const regCreds = {
      username: d8Username(getFieldValue('username0')),
      password: getFieldValue('password0a'),
    };

    // return auth creds if available, otherwise login creds if they have been entered, otherwise registration creds
    return {
      username: auth?.socId || loginCreds.username || regCreds.username,
      password: auth?.socId || loginCreds.password || regCreds.password,
    };
  };

  // log the user in to HVN server
  // with appropriate method and credentials
  const login = () => {
    const login = {};
    if (getFieldValue('authMethod') === 'password') {
      // for existing user who has proposed credentials (username, password)
      const loginCreds = {
        username: d8Username(getFieldValue('username')),
        password: getFieldValue('password'),
      };

      // for new user who has proposed credentials (username, password)
      const regCreds = {
        username: d8Username(getFieldValue('username0')),
        password: getFieldValue('password0a'),
      };

      // use login creds if they have been entered, otherwise registration creds
      const username = loginCreds.username || regCreds.username;
      const password = loginCreds.password || regCreds.password;

      // method and args depend on which authMethod has been employed
      login.function = loginHvnUser;
      login.args = { username, password };
    } else {
      login.function = loginSocAuthUser;
      login.args = getFieldValue('authField');
    }
    return login.function(login.args);
  };

  // attempt to login to HVN server with credentials supplied
  const processExistingUser = () =>
    new Promise((resolve, reject) => {
      login()
        .then(user => resolve(user))
        // if user does not have account, resolve as  null, otherwise report error
        //NOTE: D8 returned 401 for unrecognized account, D9 however returns 400
        .catch(err =>
          [400, 401].includes(err.status) ? resolve(null) : reject(err)
        );
    });

  const getUpdatedUserParams = () => {
    // user may and may not already exist, and may or may not already be a member of a partition
    // also, user may hold params not related to specific partitions
    // extract the new or updated information provided by user
    const thisPartitionParams = {
      partition,
      params: getSectionValuesEng('userParams'),
    };
    //return appendCommunityParams(partition, user, thisPartitionParams)
    return updatedAggregateUserParams(partition, user, thisPartitionParams);
  };

  // create account, log user in, save profile (if available)
  const processNewUser = () =>
    new Promise((resolve, reject) => {
      const screenName =
        getFieldValue('screenName') || getFieldValue('username0');

      // apply title to profile message
      const applyTitle = submitArgs => ({
        ...submitArgs,
        recording: {
          ...submitArgs.recording,
          title: customTitle || `Profile for ${screenName}`,
        },
      });

      // assemble screen name and other attributes
      const thisPartitionParams = getSectionValuesEng('userParams');
      const params = updatedAggregateUserParams(
        partition,
        user,
        thisPartitionParams
      );

      // createAccount method depends on whether social auth or password auth is used
      const createAccount = () =>
        getFieldValue('authMethod') === 'password'
          ? createPasswordAuthenticatedAccount(
              partition,
              d8Username(getFieldValue('username0')),
              getFieldValue('password0a'),
              screenName,
              params
            )
          : createSocialAuthenticatedAccount({
              ...getFieldValue('authField'),
              screenName: screenName,
              memberships: [partition],
              params,
            });

      createAccount()
        // account created, now login
        .then(createdUser => login())
        .then(() => dispatch(userActions.noteAccountCreated()))
        // logged in, now save profile if any
        .then(() => submitArgs && submitMessage(applyTitle(submitArgs)))
        .then(() => {
          resolve(null);
        })
        .catch(err => {
          console.log('processNewUser error:', err);
          if (err.status === 422) {
            // already exists
            console.log('processNewUser already exists.');
          }
          reject(err);
        });
    });

  const updateUserAccount = () =>
    new Promise((resolve, reject) => {
      if (user.id) {
        // apply membership to this community, avoid duplication
        const memberships = [
          ...user.memberships.filter(m => m !== partition),
          partition,
        ];

        // assemble screen name and other attributes
        const thisPartitionParams = getSectionValuesEng('userParams');

        // apply this partitions's params to user's aggregate params
        const params = updatedAggregateUserParams(
          partition,
          user,
          thisPartitionParams
        );
        patchHvnUser(user, { field_memberships: memberships, params })
          .then(msg => resolve(msg))
          .catch(err => reject(err));
      } else {
        reject('Cannot patch null user');
      }
    });

  const handleLoginFailure = err => {
    // err.status 401 : user does not exist
    const errObj = {
      heading: `Cannot Login "${getFieldValue('username')}"`,
      body: `Make sure the username and password are correct. If you have not yet registered please use the "Register" tab. If you continue to have difficulty, please contact your Meetup group coordinator for assistance.`,
    };
    handleValidationException(errObj);
  };

  const handleRegistrationFailure = err => {
    const alreadyTaken = err.status === 422;
    const name = getFieldValue('username0');
    const errObj = {
      heading: 'Cannot Register ' + (name ? `"${name}"` : ''),
      body: alreadyTaken
        ? `That username is already registered.  Please try the 'Login' tab. If you continue to have difficulty, contact your Community coordinator for assistance.`
        : `Something went wrong, we could not complete your registration. Please contact your Community coordinator for assistance.`,
    };
    handleValidationException(errObj);
  };

  // HELPERS ----------------------------------------------------------------------------------

  const getFieldValue = (key, pAges = pg.trans) =>
    regUtils.getFieldValue(key, pAges);
  const getSectionValuesEng = key => regUtils.getSectionValues(key, pg.eng);

  // disallow password authentication for open community
  // filter the 'values' list for the 'authMethod' field
  const restrictAuthMethods = (pg, gated) => {
    if (!gated) {
      // process english version
      let pages = pg.eng;
      const fieldKey = 'authMethod';
      const authMethod = regUtils.getField(fieldKey, pages);
      let values = authMethod.values.filter(v => v !== 'password');
      pg.overrideFieldAttributes({
        pages,
        fieldKey,
        attributeOverrides: { values },
      });

      // process translated
      pages = pg.trans;
      values = authMethod.values.filter(v => v !== t('password'));
      pg.overrideFieldAttributes({
        pages,
        fieldKey,
        attributeOverrides: { values },
      });
    }
  };

  // return null on success
  const validateField = fieldData => {
    let errObj = null;
    // iterate over fields, break out on first exception
    makeArray(fieldData).every(({ key, value }) => {
      // required fields have already been checked for non-null
      switch (key) {
        case 'username':
        case 'username0':
        case 'screenName':
          errObj = validateProposedUsername(value);
          break;
        case 'password0b':
          errObj = validateProposedPassword(
            value,
            fieldData.find(data => data.key === 'password0a').value
          );
          break;
        case 'invitation':
        case 'regInvitation':
          errObj = validateProposedInvitation(partition, value);
          break;
        case 'authField':
          errObj = validateProposedSocAuth(value);
          break;
        default:
          break;
      }
      errObj && (errObj = { ...errObj, key });
      return !Boolean(errObj);
    });
    return errObj;
  };

  // RENDERING ----------------------------------------------------------------------------------

  // list of tabs to be rendered
  // extract labels from appropriate pages
  // order of tabs depends on regDialog mode
  const tabItems = [
    regDialog?.mode === 'login' ? 'getCredsLogin' : 'getCredsRegister',
    regDialog?.mode === 'login' ? 'getCredsRegister' : 'getCredsLogin',
  ].map(key => ({
    key,
    legend: pg && pg.trans.find(p => p.key === key)?.title,
  }));

  const renderPageHeader = () => (
    <div
      id="non-scrolling"
      ref={nonScrollingSectionRef}
      style={{ paddingBottom: 10 }}
    >
      {pageConfig.enableBanner && <LogRegBanner config={options} />}
      {enableTabs && (
        <LogRegTabs tabItems={tabItems} onChange={handleTabChange} />
      )}
    </div>
  );

  const renderBody = () => (
    <RecordingBufferContext.Provider
      value={{
        recBufferEnable: true,
        recording: submitArgs?.recording,
        bufferMessage: handleProfileRecorded,
      }}
    >
      {page && (
        <LogRegPage
          page={page}
          onFieldChange={handleFieldChange}
          onSubmit={handlePageSubmit}
          zen={zen}
          refresh={updateCnt}
        />
      )}
    </RecordingBufferContext.Provider>
  );

  // this component can optionally be rendered within a dialog
  if (requireCookieAcceptance) {
    return <h2>{t('Please_Accept_Cookies_Before_Continuing')}</h2>;
  }

  return (
    <>
      {renderWithinDialog ? (
        <>
          {enableTabs && (
            <LogRegTabs tabItems={tabItems} onChange={handleTabChange} />
          )}
          {renderBody()}
        </>
      ) : (
        <>
          {renderPageHeader()}
          <div
            id="scrolling"
            style={
              pageConfig.enableOverflow
                ? { overflow: 'auto', maxHeight: scrollableSectionMaxHeight }
                : null
            }
          >
            {renderBody()}
          </div>
        </>
      )}

      <ExceptionDialog
        open={Boolean(exception)}
        onClose={handleExceptionClose}
        heading={exception && exception.heading}
        body={exception && exception.body}
      />
    </>
  );
};

RegistrationManager.propTypes = {
  partition: PropTypes.string, // optional specification of community key
  renderWithinDialog: PropTypes.bool, // true if view is to be rendered within a dialog
  onComplete: PropTypes.func, // invoked upon success or cancel
  renderRegistrationProgress: PropTypes.func, // optional callback to handle progress display
  notePage: PropTypes.func, // callback to advise parent of page transition
  noteField: PropTypes.func, // callback to advise parent of field update
  zen: PropTypes.bool, // simplify rendering
  bypassGetAttributes: PropTypes.bool,
  onIdentity: PropTypes.func, // if truthy, socId is obtained but rest of auth is bypassed
};

RegistrationManager.defaultProps = {
  renderWithinDialog: false,
  bypassGetAttributes: false,
};

export default RegistrationManager;
