/**
 * Display the current breakpoint
 */

import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';

// xs:600px    sm:960px    md:1280px   lg:1920px  xl: ...
const BreakpointIndicator = () => {
  const vxs = useSelector(state => state.ui.vxs);

  let breakpoint = null;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));

  xs && (breakpoint = 'xs');
  !breakpoint && sm && (breakpoint = 'sm');
  !breakpoint && md && (breakpoint = 'md');
  !breakpoint && lg && (breakpoint = 'lg');
  !breakpoint && xl && (breakpoint = 'xl');

  return (
    <div
      style={{
        display: 'inline',
        width: 20,
        padding: '0px 3px',
        border: '1px solid black',
        backgroundColor: 'yellow',
        color: 'black',
      }}
    >
      {`${breakpoint ? breakpoint : '??'}${vxs ? ' (vxs)' : ''}`}
    </div>
  );
};

export default BreakpointIndicator;
