/**
 * Renders a Dialog controlled through Redux store
 *  - When rendered high in component tree, can be manged by Redux store
 *  - Reduces complexity of state management at component level
 *  - Can take content through redux store or props
 *  - content can comprise text, html element, or react component
 *  - Includes optional Progress indicator
 *
 * Invoke with Hooks:
 *  dispatch(uiActions.dialogSet( content ))
 *  dispatch(uiActions.dialogClr())
 *
 * Invoke with Class:
 *  this.props.actions.ui.dialogSet( content)
 *  this.props.actions.ui.dialogClr( )
 *
 * Current Usage:
 *  DemoSelector
 *  AccountButton   //TODO: replace with Dialog2
 *  Recorder        //TODO: replace with DialogException
 *  App  (non-redux use, uses progress flag)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: '10px',
  },
}));

export default function DialogStd(props) {
  const classes = useStyles();
  const handleClose = () => {
    props.onClose && props.onClose();
    dispatch(uiActions.dialogClr());
  };

  //TODO2:  DialogStd: remove non-stringifyable content from redux store
  const redux = useSelector(state => state.ui.dialog);

  const defaults = {
    open: false,
    content: 'Standard Dialog',
    showProgress: false,
    classes: { paper: classes.dialog }, // do not confuse with 'const classes'
    maxWidth: 'xs',
    fullWidth: false,
    onBackdropClick: handleClose,
    onEscapeKeyDown: handleClose,
    showButton: true,
    btnLabel: t('Close'),
    onClick: e => e.stopPropagation(),
  };
  const options = props.redux
    ? { ...defaults, ...redux, ...props }
    : { ...defaults, ...props };

  const dispatch = useDispatch();
  return (
    <div>
      <Dialog {...options}>
        <Grid container direction="column" justify="center" alignItems="center">
          <div>{options.content}</div>
          {options.showProgress && <CircularProgress color="inherit" />}
          {options.showButton && (
            <Button
              variant="contained"
              style={{ marginTop: '10px' }}
              onClick={handleClose}
            >
              {options.btnLabel}
            </Button>
          )}
        </Grid>
      </Dialog>
    </div>
  );
}

DialogStd.propTypes = {
  redux: PropTypes.bool, // place control in hands of redux
  showProgress: PropTypes.bool,
  content: PropTypes.string,
  showButton: PropTypes.bool,
  btnLabel: PropTypes.string,
  onClose: PropTypes.func,
};
