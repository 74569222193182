/**
 * Render a membership list for the selected Community.
 *
 * - Render only for authenticated user, member of the community
 * - A static header with filter controls
 * - Scrolling section renders the member list
 * - For each member, display attributes and action icons
 * - actions: PlayGreeting, SendDirectMessage, applyBookmark
 *
 *
 * //@@ consider refactor to employ useMembers custom hook
 */

import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { List } from '@material-ui/core';
import FilterBar from './components/FilterBar';
import Member from './components/Member';
import { uiActions } from 'store/ui-slice';
import { makeArray } from 'lib/utils/utils';
import { userActions } from 'store/user-slice';
import { getProfiles, fetchDialogs, retrieveMessage } from 'lib/api/messageApi';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';

import { WEB_HOST } from 'configs/config-hvn';
import {
  isMember,
  getCommunityMembers,
  currentPartition,
} from 'lib/api/communityApi';
import { getMemberParams } from 'lib/api/communityApi';
import {
  regHelpers,
  RegPages,
} from 'containers/RegistrationManager/lib/registrationUtils';
import BorderedBlock from 'components/utils/BorderedBlock';
import { t, rt } from 'lib/translation/trans';
import EntityFilter from 'lib/classes/EntityFilter';

const regUtils = regHelpers();
/*

// demo list, installed with one-time execution of loadNewMembers
const newMembers = [
  { username: '', screenName: 'Rosie', language: 'Anglophone', skill: '', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Yann', language: 'Francophone', skill: '', gender: 'Male', ageRange: 'Under 40' },
  { username: '', screenName: 'Jay', language: 'Anglophone', skill: '', gender: 'Male', ageRange: 'Over 40' },
  { username: '', screenName: 'Hélène (Paris)', language: 'Francophone', skill: 'Advanced', gender: 'Female', ageRange: 'Over 40' },
  { username: '', screenName: 'Abby Goldman', language: 'Anglophone', skill: 'Advanced', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Julien', language: 'Francophone', skill: 'Advanced', gender: 'Male', ageRange: 'Under 40' },
  { username: '', screenName: 'Grace Moore', language: 'Anglophone', skill: 'Beginner', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Franck', language: 'Francophone', skill: 'Intermediate', gender: 'Male', ageRange: 'Under 40' },
  { username: '', screenName: 'Aliénor', language: 'Francophone', skill: '', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Magin', language: 'Anglophone', skill: '', gender: 'Male', ageRange: 'Under 40' },
  { username: '', screenName: 'Marianne', language: 'Anglophone', skill: '', gender: 'Female', ageRange: 'Over 40' },
  { username: '', screenName: 'Mar Espinosa', language: 'Francophone', skill: '', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Jerry Genova', language: 'Anglophone', skill: '', gender: 'Male', ageRange: 'Over 40' },
  { username: '', screenName: 'alaguiry', language: 'Francophone', skill: '', gender: 'Male', ageRange: 'Under 40' },
  { username: '', screenName: 'oiramgdbl', language: 'Francophone', skill: '', gender: 'Male', ageRange: 'Over 40' },
  { username: '', screenName: 'Reine', language: 'Francophone', skill: '', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Arnaud Pierre', language: 'Francophone', skill: '', gender: 'Male', ageRange: 'Over 40' },
  { username: '', screenName: 'Justine (Paris)', language: 'Francophone', skill: 'Advanced', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Mario Diego Garcia', language: 'Francophone', skill: 'Intermediate', gender: 'Male', ageRange: 'Over 40' },
  { username: '', screenName: 'Élise', language: 'Francophone', skill: 'Beginner', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Gonzalo Rojas Oberreuter', language: 'Francophone', skill: 'Advanced', gender: 'Male', ageRange: 'Over 40' },
  { username: '', screenName: 'Cycy', language: 'Francophone', skill: 'Advanced', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Ahmed', language: 'Francophone', skill: '', gender: 'Male', ageRange: 'Under 40' },
  { username: '', screenName: 'Kamel (Arabe)', language: 'Francophone', skill: '', gender: 'Male', ageRange: 'Under 40' },
  { username: '', screenName: 'Anne Rose Conjeaud', language: 'Francophone', skill: '', gender: 'Female', ageRange: 'Over 40' },
  { username: '', screenName: 'Myriam Langlois', language: 'Francophone', skill: '', gender: 'Female', ageRange: 'Over 40' },
  { username: '', screenName: 'Laurence ', language: 'Francophone', skill: '', gender: 'Female', ageRange: 'Over 40' },
  { username: '', screenName: 'Wawa', language: 'Francophone', skill: '', gender: 'Male', ageRange: 'Over 40' },
  { username: '', screenName: 'Rosalie Weiss', language: 'Anglophone', skill: '', gender: 'Female', ageRange: 'Over 40' },
  { username: '', screenName: 'Evaleon Hill', language: 'Anglophone', skill: '', gender: 'Female', ageRange: 'Over 40' },
  { username: '', screenName: 'Joan.stanton 21@gmail.com', language: 'Anglophone', skill: '', gender: 'Female', ageRange: 'Over 40' },
  { username: '', screenName: 'josephine diouf', language: 'Francophone', skill: '', gender: 'Female', ageRange: 'Under 40' },
  { username: '', screenName: 'Corinne', language: 'Francophone', skill: '', gender: 'Female', ageRange: 'Over 40' },
]


// per https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop

import { createPasswordAuthenticatedAccount } from 'lib/api/userApi'


async function loadNewMembers(partition) {
  for await (const created of newMembers.map(m => createPasswordAuthenticatedAccount(
    partition,
    `${partition}_${m?.screenName}`,
    `${partition}_${m?.screenName}`,
    m?.screenName,
    {
      language: m?.language,
      gender: m?.gender,
      ageRange: m?.ageRange,
      skill: m?.skill,
    }
  ))) {
    console.log("created:", created)

  }
}

*/

const MembershipView = () => {
  const { isPlaying, defaultProps, setSrc, play, pause, togglePlay, src } =
    useVoicePlayerHelper({ playerKey: 'MembershipView' });
  const [fields, setFields] = useState(null); //key, title, value, error, helper, required, type, values4
  const [selectedId, setSelectedId] = useState(null);
  const [filterObj, setFilterObj] = useState(null);
  const dispatch = useDispatch();
  const { pSession, layout } = useSelector(state => state.ui);
  const { auth, isAuthenticated, members } = useSelector(state => state.user);
  const { dialogsFetchedFlg, selectedMessageId } = useSelector(
    state => state.dialog
  );
  const { community, filterParams, autoPlay } = pSession;
  const { partition } = community;
  const history = useHistory();
  const totalAvailableHeight = layout.mainContentHeight;
  const nonScrollingSectionRef = useRef(null);
  const nonScrollingSectionHeight =
    nonScrollingSectionRef?.current?.clientHeight;
  const scrollableSectionMaxHeight =
    totalAvailableHeight - nonScrollingSectionHeight;
  const bookmarks = makeArray(
    isAuthenticated && auth.user?.info?.field_bookmarks
  );
  const { langCode, langMap: t1 } = useSelector(state => state.lang);

  // create a reference to hold the pages object, to be instantiated in useEffect
  const pagesObj = useRef();
  const pg = pagesObj.current; // a shorthand reference to the  pages object

  const listRef = useRef();

  // if a message was selected during browsing, select its author now
  // otherwise select the first member on the list
  const setInitialSelectedMember = members => {
    const selectedMessage = retrieveMessage(selectedMessageId);
    const selectedAuthor =
      members &&
      selectedMessage &&
      members.find(m => m.id === selectedMessage?.userId);
    const selectedAuthorId = selectedAuthor?.id;
    const initialId = selectedAuthorId || members[0]?.id;
    setSelectedId(initialId);

    // cannot employ getUrl because members state may not yet be stable
    const initialUrl = members.find(m => m.id === initialId)?.profile?.fileUrl; // WARNING: invoking th8is before members is stable produces unpredictable
    const initialSrc = getSrc(initialUrl);
    setSrc(initialSrc);
  };

  // initialize community parameters and obtain list of members
  useEffect(() => {
    // allow access only if user is an authenticated member of the current community
    isMember() || history.push('/register');

    // obtain roadmap with fields definition
    pagesObj.current = new RegPages({ partition });

    // load members ONLY if they have not already been loaded
    if (members.length === 0) {
      // members not yet fetched
      getCommunityMembers()
        // apply partition-specific params to member object
        .then(members =>
          members.map(m => ({ ...m, params: getMemberParams(partition, m) }))
        )
        .then(members => appendProfileMessages(members))
        //.then(members => applyBookmarks(members) )
        .then(members => {
          if (Array.isArray(members)) {
            dispatch(userActions.setMembers(members));
            setInitialSelectedMember(members);
          }
        })
        .then(() => !dialogsFetchedFlg && fetchDialogs()) // this is done so that Profile Messages are available
        .catch(err => console.log('member params error:', err));
    } else {
      // member list already retrieved
      setInitialSelectedMember(members); // members from redux
    }
  }, [partition]);

  // load FilterBar fields from community parameters
  // re-translate pg and fields when langCode changes
  useEffect(() => {
    pg && pg.translate();
    // read fields from community configuration data
    const fields = regUtils.getSection(
      'filterSection',
      pagesObj.current.trans
    )?.fields;

    setFilterObj(new EntityFilter(filterParams, partition, 'user'));
    setFields(applyFilterbarValues(fields));
  }, [langCode, pg, partition]);

  // initialize the local form values with persistent filter attributes
  useEffect(() => {
    setFields(fields => applyFilterbarValues(fields));
  }, [filterParams, langCode]);

  // configure Tips to be rendered while this component is mounted
  useEffect(() => {
    dispatch(
      uiActions.tipsControlSetKeys([
        ['responseMic', 'forward', 'Send a message to this member.'],
        ['recorderMic', 'down', 'Create/Update your profile.'],
      ])
    );
    return () =>
      dispatch(uiActions.tipsControlClearKeys(['responseMic', 'recorderMic']));
  }, []);

  // fetch profiles and append to member objects
  const appendProfileMessages = members => {
    let p;
    return getProfiles({ partition: currentPartition() }).then(profiles =>
      members.map(m =>
        (p = profiles.find(p => p.userId === m?.id)) ? { ...m, profile: p } : m
      )
    );
  };

  /*
  // annotate members who are bookmarked by the current user
  const applyBookmarks = members  => {
    const promise = new Promise((resolve, reject) => {
      resolve(members.map(m => ({...m, bookmark: Boolean(bookmarks && bookmarks.find(b => b===m.id))})))
    })
    return promise
}

*/

  const handleFilterFieldChange = (key, value) => {
    // note that user has changed a filter parameter
    // store the change to persistent memory
    if (fields.find(f => f.key === key)) {
      encodeIncrementalFilterAttribute(key, rt(value));
    }
  };

  // remove all persistent filter attributes
  const handleClearFilters = () => {
    // update filterObj, also update persistent store
    filterObj &&
      dispatch(uiActions.updateFilterParamsV3(filterObj.removeCriteria()));
  };

  // apply persistent criteria values to fields
  const applyFilterbarValues = fields => {
    const updatedFields = fields ? [...fields] : [];
    updatedFields.forEach(f => {
      const v = filterObj && filterObj.getRequiredValue(f.key);
      switch (f.type) {
        case 'radio':
          f.value = t(v || 'No Preference');
          break;
        case 'checkbox':
          const checkedBoxes = makeArray(v);
          f.values.forEach(label => {
            f.value[label] = checkedBoxes.includes(rt(label));
          });
          break;
      }
    });
    return updatedFields;
  };

  // in response to a change in a particular field value
  // construct and persist a new filter attribute
  const encodeIncrementalFilterAttribute = (key, value) => {
    const fieldType = fields.find(f => f.key === key)?.type;
    let filterValue = null;

    switch (fieldType) {
      case 'radio':
        // remove or set the filter attribute
        filterValue = value === 'No Preference' ? undefined : value;
        break;
      case 'checkbox':
        // create an array of acceptable attribute values
        // based on which boxes are checked
        const valList = Object.entries(value)
          .filter(([key, val]) => val)
          .map(([key, val]) => key);
        filterValue = valList.length ? valList : undefined;
        break;
      default:
        // unrecognized type
        // do not change filter attributes
        return;
    }

    // set or delete the filter attribute (2nd generation method)
    // update filterObjm aksi yodate persistent store
    filterObj &&
      dispatch(
        uiActions.updateFilterParamsV3(
          filterObj.addCriterion({
            key,
            value: filterValue,
            requirement: 'include',
          })
        )
      );
  };

  const qualifiedMembers = () =>
    members.filter(m => {
      // apply suffix to keys
      const suffix = 'Pref';
      const applySuffix = k => `${k}${suffix}`;
      const entity = Object.entries(m.params || {}).reduce(
        (out, [key, val]) => {
          return { ...out, [applySuffix(key)]: val };
        },
        {}
      );

      return !(filterObj && filterObj.isEntityExcluded(entity));
    });

  const getUrl = id => members.find(m => m.id === id)?.profile?.fileUrl;
  const getSrc = url => (url ? `${WEB_HOST}/${url}` : null);

  // user has clicked on a member
  const handleMemberSelected = id => {
    const url = getUrl(id);

    if (autoPlay) {
      //automatically play message upon selection of Member

      if (id === selectedId) {
        // clicked the selected member, toggle playing its profile
        url && togglePlay();
      } else {
        // clicked a new member, start playing its profile, if available
        setSrc(getSrc(url));
        url ? isPlaying || play() : pause();
        setSelectedId(id); // switch if new id
      }
    } else {
      // no automatic play of member message upon selection
      // clicking the previously selected Member has no effect on play
      if (id !== selectedId) {
        // stop playing and await manual action
        pause();
        setSelectedId(id); // switch if new id
        setSrc(getSrc(url));
      }
    }
    dispatch(uiActions.respondTipPing()); // let the respondTip mechanise keep track of message indexing
  };

  // user has actuated a player action button
  const handlePlayerActionEvent = (id, ev) => {
    if (ev === 'pauseClick') {
      pause();
    } else if (ev === 'playClick') {
      const newSrc = getSrc(getUrl(id));

      if (id !== selectedId || src !== newSrc) {
        // Play button was actuated from a member other than from selectedId
        handleMemberSelected(id);
        // access to VoicePlayer events is unavailable here.
        // wait for src to be loaded
        setTimeout(() => {
          play();
        }, 1000);
      } else {
        isPlaying || play();
      }
    }
  };

  const playerProps = {
    ...defaultProps,
    autoplay: autoPlay,
    uiItems: [],
  };

  return (
    <>
      <div
        id="non-scrolling"
        ref={nonScrollingSectionRef}
        style={{ paddingBottom: 10 }}
      >
        {fields && (
          <FilterBar
            fields={fields}
            onFieldChange={handleFilterFieldChange}
            onClearFilters={handleClearFilters}
          />
        )}
        <div style={{ width: '25%', margin: 'auto' }}>
          <VoicePlayer {...playerProps} />
        </div>
      </div>

      <div
        id="scrolling"
        style={{ overflow: 'auto', maxHeight: scrollableSectionMaxHeight }}
      >
        <BorderedBlock
          labelTop={`${t('Community_Members')} ( ${
            members ? members.length : 0
          } )`}
          classes={{}}
        >
          {
            <List ref={listRef} style={{ minHeight: 400 }}>
              {qualifiedMembers().map((user, ix) => (
                <Member
                  key={ix}
                  selected={user.id === selectedId}
                  user={user}
                  onSelect={handleMemberSelected}
                  onPlayerEvent={handlePlayerActionEvent}
                  isPlaying={isPlaying && user.id === selectedId}
                  fields={fields}
                  bookmarked={Boolean(bookmarks.find(b => b === user.id))}
                  containerRef={listRef}
                />
              ))}
            </List>
          }
        </BorderedBlock>
      </div>
    </>
  );
};
export default MembershipView;

MembershipView.propTypes = {};
