/**
 * Basic Dialog with
 *  Title (heading),        //string
 *  Content with dividers   //node
 *  Actions                 //node
 *
 * Current Usage:
 *   LoginDialog
 *   BookmarkActionDialog
 *   MessageRecorderDialog
 *   ShareActionDialog
 */

import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Dialog2(props) {
  const { open, onClose, heading, content, actions, moveUp } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onClick={e => e.stopPropagation()}
      disableBackdropClick={props.disableBackdropClick}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      PaperProps={moveUp ? { style: { position: 'fixed', top: 10 } } : {}}
    >
      {Boolean(heading) && (
        <DialogTitle style={{ textAlign: 'center' }}> {heading} </DialogTitle>
      )}
      <DialogContent dividers={true}> {content} </DialogContent>
      {Boolean(actions) && <DialogActions> {actions} </DialogActions>}
    </Dialog>
  );
}

Dialog2.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,

  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,

  heading: PropTypes.string,
  content: PropTypes.node,
  actions: PropTypes.node,
  moveUp: PropTypes.bool,
};

Dialog2.defaultProps = {
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  moveUp: false,
};
