/**
 * SleepCountdown
 * Rendar a countdown until scheduled sleep
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import { activityDetector } from 'lib/classes/ActivityDetector';

const SleepCountdown = () => {
  const [tillSleepSec, setTillSleepSec] = useState(0);
  const status = useSelector(state => state.user.status);
  const intervalRef = useRef(null);
  const awake = status === 'auth';

  useEffect(() => {
    awake
      ? (intervalRef.current = setInterval(
          () => setTillSleepSec(activityDetector.getTillSleepSec()),
          1000
        ))
      : clearInterval(intervalRef.current);
    return () => clearInterval(intervalRef.current);
  }, [awake]);

  return awake ? (
    <div style={{ position: 'fixed', bottom: 10, left: 10 }}>
      <Chip
        style={{ marginRight: 10, color: 'white', backgroundColor: 'grey' }}
        label={`${tillSleepSec}`}
        size="small"
      />
    </div>
  ) : null;
};

export default SleepCountdown;
