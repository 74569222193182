/*
 * An mp3 voice player
 * with optional visualization and controls
 * Expose togglePlay to parent and employ custom hook to expose status and controls to parent
 *
 * Features
 * --------------------------------
 * - can optionally render controls
 * - responds to redux-based pauseAll trigger
 *
 * Usage
 * ----------------------------------
 *      const { isPlaying, defaultProps, setSrc, play, pause, togglePlay } = useVoicePlayerHelper({playerKey: 'demo'} )
 *      <VoicePlayer {...defaultProps} />
 *
 *
 * ----------------------------------
 */

import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import useState from 'react-usestateref';
import AudioObj from 'lib/classes/AudioObj';
import PlayerUI from './components/PlayerUI';

const VoicePlayer = forwardRef((props, ref) => {
  const { src, playerKey, autoplay, onAudioEvent, ...propsPlayerUI } = props;
  //****  state --------------------------------------------------------------------
  const [audioObj, setAudioObj, audioObjRef] = useState(null); // make current value accessible from within callback
  const voicePlayerMasterPauseTrigger = useSelector(
    state => state.ui.voicePlayerMasterPauseTrigger
  );

  //****  state --------------------------------------------------------------------

  // allocate resources, destroy when component dismounts
  useEffect(() => {
    setAudioObj(new AudioObj({ playerKey, onEvent: handleAudioEvent }));
    //@@ register for global pause method
    return () => {
      audioObjRef.current.destroy();
    };
  }, []);

  // apply the src to audioObj
  useEffect(() => {
    if (audioObj) {
      if (src) {
        audioObj.setSrc(src, autoplay); // autoplay will be ignored untio play() or togglePlay() is invoked
      } else {
        audioObj.clrSrc();
      }
    }
  }, [src, autoplay, audioObj]);

  // pause audio on any change in voicePlayerMasterPauseTrigger
  useEffect(() => {
    audioObj?.isPlaying && togglePlay();
  }, [voicePlayerMasterPauseTrigger]);

  // Expose the togglePlay method to parent
  // ref.: https://dev.to/007_dark_shadow/react-hooks-calling-child-component-method-from-parent-144p
  useImperativeHandle(ref, () => ({
    togglePlay,
  }));

  const togglePlay = () => audioObj?.togglePlay();

  // re-render when PlayerUI requests togglePlay
  const uiTogglePlay = () => {
    togglePlay();
  };

  const handleAudioEvent = e => {
    onAudioEvent && onAudioEvent(e);
  };

  // render recorder/playback visualizer and action controls
  return (
    <PlayerUI
      src={src}
      audioEl={audioObj?.el}
      isPlaying={audioObj?.isPlaying}
      togglePlay={uiTogglePlay}
      {...propsPlayerUI}
    />
  );
});

VoicePlayer.propTypes = {
  // dynamic source (can change while component is mounted )
  src: PropTypes.string,

  // event reporting
  onAudioEvent: PropTypes.func,
  //(also onNext, onPrevious from PlayerUI )

  // configuration
  playerKey: PropTypes.string,
  autoplay: PropTypes.bool,

  // optional UI configuration
  classes: PropTypes.object,
  uiItems: PropTypes.arrayOf(
    PropTypes.oneOf([
      'title',
      'visualizer',
      'progress',
      'progressPretto',
      'controls',
      'controlsCompact',
      'hControls',
      'custom',
    ])
  ),
  noBrowserViewControl: PropTypes.bool,
  visualizerPausedContent: PropTypes.node,
  titleComponent: PropTypes.node,
  progressStyle: PropTypes.string,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  makeVisualizerAvailable: PropTypes.func,
  customComponent: PropTypes.node,
};

VoicePlayer.defaultProps = {
  playerKey: 'default',
  autoplay: false,
};

export default VoicePlayer;

/**
 * useVoicePlayerHelper custom hook
 *
 * Convenience wrapper for VoicePlayer
 * to expose state and controls
 *
 */

export const useVoicePlayerHelper = props => {
  const playerKey = props?.playerKey || 'player';
  const [src, setSrc] = useState(null);

  const [isPlaying, setIsPlaying, isPlayingRef] = useState(false);
  const playerRef = useRef();

  // keep track of current isPlaying status
  const handleAudioEvent = e => {
    ['playing'].includes(e) && setIsPlaying(true);
    ['pausing'].includes(e) && setIsPlaying(false);
  };

  // ref
  const togglePlay = () =>
    playerRef?.current ? playerRef.current.togglePlay() : () => {};
  const play = () => !isPlaying?.current && togglePlay();
  const pause = () => isPlayingRef?.current && togglePlay();

  const defaultProps = {
    playerKey,
    ref: playerRef,
    src,
    onAudioEvent: handleAudioEvent,
  };

  return {
    isPlaying,
    defaultProps,
    setSrc,
    play,
    pause,
    togglePlay,
    src,
    handleAudioEvent,
  };
};
