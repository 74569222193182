/**
 * Render buttons offering opportunity to copy text to clipboard,
 * or to cancel operation.
 *
 * Copies raw CF_TEXT format to clipboard, no formatting
 *
 */

import React from 'react';
import { Grid, IconButton } from '@material-ui/core';

import { t } from 'lib/translation/trans';
import CloseIcon from '@material-ui/icons/Close';
import ClipboardButton from './ClipboardButton';
import FacebookShareButton from 'components/utils/FacebookShareButton';

const ClipboardActions = ({ link, cancelLabel, onCopy, onExit }) => {
  const handleClipboardLoad = rslt => {
    rslt === 'success' ? onCopy() : onExit();
  };

  const handleFacebookShare = () => {};

  return link ? (
    <>
      <Grid container justify="flex-end" spacing={2}>
        <Grid item style={{ textAlign: 'center' }}>
          <IconButton onClick={onExit}>
            <CloseIcon />
          </IconButton>
          <div>{cancelLabel}</div>
        </Grid>

        <Grid item style={{ textAlign: 'center' }}>
          <FacebookShareButton url={link} btnStyle="iconButton" />
        </Grid>

        <Grid item style={{ textAlign: 'center' }}>
          <ClipboardButton text={link} onClick={handleClipboardLoad} />
        </Grid>
      </Grid>
    </>
  ) : null;
};

ClipboardActions.defaultProps = {
  link: '',
  cancelLabel: 'Cancel',
  onCopy: () => {},
  onExit: () => {},
};
export default ClipboardActions;
