// react-audio-spectrum parameter sets
// from https://github.com/hu-ke/react-audio-spectrum/tree/master/exmaple/react-audio-spectrum-demo/src
// example assets https://github.com/hu-ke/react-audio-spectrum/tree/master/exmaple/react-audio-spectrum-demo/src/assets/media
//      Boy Don't Cry,  Mr. Turtle
//      How Long Will I Love You, Ellie Goulding
//      Can't Stand The Rain, The Rescues
//      Honey Honey, Stefanie Sun

export const settings = {
  size: {
    std: { width: 300 },
    xs: { width: 200 },
    height: 60,
  },
};

export const profiles = [
  {
    title: 'defaults',
    capColor: '#FFF',
    capHeight: 2,
    meterWidth: 2,
    meterCount: 160,
    meterColor: [
      { stop: 0, color: '#f00' },
      { stop: 0.5, color: '#0CD7FD' },
      { stop: 1, color: 'red' },
    ],
    gap: 10,
  },
  {
    title: 'example',
    capColor: 'red',
    capHeight: 2,
    meterWidth: 2,
    meterCount: 512,
    meterColor: [
      { stop: 0, color: '#f00' },
      { stop: 0.5, color: '#0CD7FD' },
      { stop: 1, color: 'red' },
    ],
    gap: 4,
  },
  {
    title: 'gradient colors',
    capColor: 'red',
    capHeight: 2,
    meterWidth: 2,
    meterCount: 512,
    meterColor: [
      { stop: 0, color: '#f00' },
      { stop: 0.5, color: '#0CD7FD' },
      { stop: 1, color: 'red' },
    ],
    gap: 4,
  },
  {
    title: 'meterWidth: 10',
    capColor: 'red',
    capHeight: 2,
    meterWidth: 10,
    meterCount: 512,
    meterColor: [
      { stop: 0, color: 'orange' },
      { stop: 0.5, color: 'red' },
      { stop: 1, color: '#FFF' },
    ],
    gap: 4,
  },
  {
    title: 'meterCount 20 gap 1',
    capColor: 'red',
    capHeight: 2,
    meterWidth: 2,
    meterCount: 512,
    meterColor: [
      { stop: 0, color: 'pink' },
      { stop: 1, color: 'red' },
    ],
    gap: 1,
  },
  {
    title: 'gap 10 capHeight10',
    capColor: '#92BF3F',
    capHeight: 10,
    meterWidth: 10,
    meterCount: 20,
    meterColor: '#41BF3F',
    gap: 10,
  },
];
