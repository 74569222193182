import React from 'react';
import ButtonStd from 'components/utils/ButtonStd';
import Dialog2 from 'components/Modals/Dialog2';
import {
  isSelfAuthored,
  isTopicType,
  isWithMe,
  deleteMessageWithUser,
} from 'lib/api/messageApi';
import UIfx from 'uifx';
import { t } from 'lib/translation/trans';
import { nextMsg } from 'lib/api/messageApi';

export default function DeleteActionDialog({ open, onClose, message }) {
  const byMe = isSelfAuthored(message);
  const toFromMe = isWithMe(message);
  const deletable = byMe || toFromMe;

  const handleClose = () => {
    onClose && onClose();
  };

  const handleDelete = () => {
    nextMsg();
    deletable && deleteMessageWithUser(message);
    const del = new UIfx('/audio/delete.mp3');
    del.play();

    handleClose();
  };

  const heading = `${t('Delete_This_Message')}:`;

  //deleting a topic will zap all responses with it
  const responsesToo = () =>
    isTopicType(message?.type) &&
    //  && isPublicType(message?.type)
    Array.isArray(message?.responses) &&
    message?.responses.length > 0;
  return (
    <>
      <Dialog2
        open={open}
        onClose={handleClose}
        heading={heading}
        content={
          deletable ? (
            <div>{`${t('This_message')} ${
              responsesToo() ? t('and_all_of_its_responses') : ''
            }${t('will_be_permanently_deleted')}`}</div>
          ) : (
            <div>{`${t('You_cannot_delete_this_message')}!`}</div>
          )
        }
        actions={
          <>
            <ButtonStd onClick={onClose} label={t('Cancel')} />
            {deletable ? (
              <ButtonStd
                onClick={handleDelete}
                label={t('DELETE')}
                color="secondary"
              />
            ) : null}
          </>
        }
      />
    </>
  );
}
