/**
 * Present selected authenticator
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SMSAuthenticator from './components/SMSAuthenticator';
import GoogleAuthenticator from './components/GoogleAuthenticator';
import FacebookAuthenticator from './components/FacebookAuthenticator';
import { obfuscateDigitString } from 'lib/utils/crypto';

const Authenticator = ({ authMethod, onClose, onSuccess, onFail, zen }) => {
  const handleSuccess = rawCreds => {
    // obfuscate the id, except for historical alpha-test accounts
    const obfuscate = socId => {
      const alphaList = [
        '112218454086208544440', // joe
        '4845740500', // lauri
        '4846844142', // graz
        '6178941210', // peter
        '7813252281', //dave
      ];
      return alphaList.includes(socId) ? socId : obfuscateDigitString(socId);
    };

    //console.log(`Authenticator creds: ${rawCreds.socId} -> ${obfuscate(rawCreds.socId)}`);
    onSuccess && onSuccess({ ...rawCreds, socId: obfuscate(rawCreds.socId) });
  };

  const renderSelectedAuthenticator = () => {
    const authProps = {
      onClose,
      onSuccess: handleSuccess,
      onFail,
      zen,
      enableCodeSuccessAlert: false,
    };
    switch (authMethod) {
      case 'google':
        return <GoogleAuthenticator {...authProps} />;
      case 'facebook':
        return <FacebookAuthenticator {...authProps} />;
      case 'sms':
        return <SMSAuthenticator {...authProps} />;
      default:
        return <div>This authenticator is not yet available ! </div>;
    }
  };
  return renderSelectedAuthenticator();
};

Authenticator.propTypes = {
  authMethod: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  zen: PropTypes.bool, //simplify rendering
};

export default Authenticator;
