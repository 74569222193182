/**
 * jsonApi.js
 * a central repository (disorganized for now) of JSON:API URL's
 * and utilities for creating them
 *
 *
 * Useful Symbols:
 *  [operator]=%3C%3E  <>
 *  [operator]=%3E	   >
 *  [operator]=%3C	   <
 *  [operator]=%3C%3D  <=
 *  [operator]=%3E%3D  >=
 */

import { API_ROOT } from 'configs/config-hvn';
import store from 'store';
import { flattenObj, makeArray } from 'lib/utils/utils';

export const getUserInfoUrl = id =>
  `jsonapi/node/user_info?include=uid&filter[uid.id]=${id}&fields[node--user_info]=field_blacklist&fields[user--user]=field_screen_name`;

export const getCommunitiesWithMediaUrl = () => `/jsonapi/node/community
?include=field_voice_intro,field_community_logo
&fields[node--community]=title,field_weight,field_community_group,field_partition,field_voice_intro,field_community_logo,body,field_community_welcome_sections,field_gated,field_slogan,field_json_params
&fields[file--file]=uri,filesize
&sort=field_weight
`;

export const getCommunitiesUrl = () => `/jsonapi/node/community
?fields[node--community]=title,field_weight,field_community_group,field_partition,field_gated,field_slogan,field_json_params
&sort=field_weight
`;

/*
All messages
jsonapi/node/voice_message
?include=uid,field_file_public
&sort=-created

All public less profiles
jsonapi/node/voice_message?include=uid,field_file_public&fields[node--voice_message]=langcode,status,title,created,promote,sticky,field_responses_to_topic,field_parent,field_partition,field_public,field_recipient,field_type,uid,field_file_public&fields[user--user]=name,field_screen_name&fields[file--file]=uri,filesize&filter[field_public]=1&filter[field_type][condition][value]=profile&filter[field_type][condition][path]=field_type&filter[field_type][condition][operator]=%3C%3E&sort=-created

jsonapi/node/voice_message
?include=uid,field_file_public
&fields[node--voice_message]=langcode,status,title,created,promote,sticky,field_responses_to_topic,field_parent,field_partition,field_public,field_recipient,field_type,uid,field_file_public
&fields[user--user]=name,field_screen_name
&fields[file--file]=uri,filesize
&filter[field_public]=1
&filter[field_type][condition][value]=profile
&filter[field_type][condition][path]=field_type
&filter[field_type][condition][operator]=%3C%3E
&sort=-created

All directed to Joe
jsonapi/node/voice_message
?include=uid,field_file_public
&fields[node--voice_message]=langcode,status,title,created,promote,sticky,field_responses_to_topic,field_parent,field_partition,field_public,field_recipient,field_type,uid,field_file_public
&fields[user--user]=name,field_screen_name
&fields[file--file]=uri,filesize
&filter[field_public]=0
&filter[field_recipient]=11221845-4086-2085-4444-000000000000
&sort=-created

All Authored by Joe
jsonapi/node/voice_message
?include=uid,field_file_public
&fields[node--voice_message]=langcode,status,title,created,promote,sticky,field_responses_to_topic,field_parent,field_partition,field_public,field_recipient,field_type,uid,field_file_public
&fields[user--user]=name,field_screen_name
&fields[file--file]=uri,filesize
&filter[uid.id]=11221845-4086-2085-4444-000000000000
&sort=-created

*/

//TODO: add partition filter (currently, relies on filter within parseDialogs)
const directedFilter =
  id => `&filter[recipient][condition][path]=field_recipient
&filter[recipient][condition][value]=${id}
&filter[recipient][condition][memberOf]=root
&filter[author][condition][path]=uid.id
&filter[author][condition][value]=${id}
&filter[author][condition][memberOf]=sent
&filter[directed][condition][path]=field_public
&filter[directed][condition][value]=0
&filter[directed][condition][memberOf]=sent
&filter[sent][group][conjunction]=AND
&filter[sent][condition][memberOf]=root
&filter[root][group][conjunction]=OR
`;

const excludeProfiles = `
$&filter[field_type][condition][value]=profile
&filter[field_type][condition][path]=field_type
&filter[field_type][condition][operator]=%3C%3E
`;

const publicFilter = (withProfiles = true) => `
&filter[field_partition]=${store.getState().ui.pSession.community?.partition}
&filter[field_public]=1
${withProfiles ? '' : excludeProfiles}
`;

// filter for itemized list of users
// WARNING: if idList is too long, fetch will fail
export const profileFilter_deprecated = idList => {
  const authorFilter = id => `&filter[author${id}][condition][path]=uid.id
&filter[author${id}][condition][value]=${id}
&filter[author${id}][condition][memberOf]=idlist`;
  const authorListFilter = () =>
    idList.reduce((acc, id) => acc + authorFilter(id), '');
  const messageTypeFilter = `&filter[public][condition][path]=field_public
&filter[public][condition][value]=1
&filter[public][condition][memberOf]=root
&filter[type][condition][path]=field_type
&filter[type][condition][value]=profile
&filter[type][condition][memberOf]=root`;

  const listConjunctionOr =
    '&filter[idlist][group][conjunction]=OR&filter[idlist][condition][memberOf]=root';
  const rootConjunctionAnd = '&filter[root][group][conjunction]=AND';

  return idList.length === 1
    ? `&filter[field_public]=1&filter[field_type]=profile&filter[uid.id]=${idList[0]}`
    : `${authorListFilter()}${listConjunctionOr}${messageTypeFilter}${rootConjunctionAnd}`;
};

// generate filter for fetching profile messages
// optionally restrict result  set by id and/or partition
// e.g. if id===n, partition==="general" then
//  only profiles by user n in general partition will be returned
const profileFilter = ({ userId, partition }) => {
  const baseFilter = `'&filter[root][group][conjunction]=AND
&filter[public][condition][path]=field_public
&filter[public][condition][value]=1
&filter[public][condition][memberOf]=root
&filter[type][condition][path]=field_type
&filter[type][condition][value]=profile
&filter[type][condition][memberOf]=root
&filter[published][condition][path]=status
&filter[published][condition][value]=1
&filter[published][condition][memberOf]=root
`;

  const idFilter = userId
    ? `&filter[id][condition][path]=uid.id
&filter[id][condition][value]=${userId}
&filter[id][condition][memberOf]=root
`
    : '';
  const partitionFilter = partition
    ? `&filter[partition][condition][path]=field_partition
&filter[partition][condition][value]=${partition}
&filter[partition][condition][memberOf]=root
`
    : '';
  const filter = baseFilter + idFilter + partitionFilter;
  return filter;
};

// generate filter for fetching all messages for a specific user and partition
const userMessagesInPartitionFilter = ({ userId, partition }) => {
  const baseFilter = `'&filter[root][group][conjunction]=AND
`;

  const idFilter = userId
    ? `&filter[id][condition][path]=uid.id
&filter[id][condition][value]=${userId}
&filter[id][condition][memberOf]=root
`
    : '';
  const partitionFilter = partition
    ? `&filter[partition][condition][path]=field_partition
&filter[partition][condition][value]=${partition}
&filter[partition][condition][memberOf]=root
`
    : '';
  const filter = baseFilter + idFilter + partitionFilter;
  return filter;
};

// generate filter for fetching all public response messages for a specific partition
const publicResponseMessagesInPartitionFilter = ({ partition }) => {
  const baseFilter = `'&filter[root][group][conjunction]=AND
&filter[public][condition][path]=field_public
&filter[public][condition][value]=1
&filter[public][condition][memberOf]=root
&filter[parent][condition][path]=field_parent
&filter[parent][condition][operator]=IS NOT NULL
`;

  const partitionFilter = partition
    ? `&filter[partition][condition][path]=field_partition
&filter[partition][condition][value]=${partition}
&filter[partition][condition][memberOf]=root
`
    : '';
  const filter = baseFilter + partitionFilter;
  return filter;
};

const vmFields =
  'fields[node--voice_message]=created,field_file_public,field_parent,field_partition,field_public,field_recip_name,field_recipient,field_consumed_dm,field_responses_to_topic,field_type,langcode,promote,status,sticky,title,uid';
const userFields = 'fields[user--user]=name,field_screen_name';
const fileFields = 'fields[file--file]=uri,filesize';
const dialogFields = `${vmFields}&${userFields}&${fileFields}`;
const invitesFields =
  'fields[node--voice_message]=field_recipient,field_consumed_dm,field_recip_name,field_type,uid';

// create URL to request public messages (public visibility)
// or for directed messages
const getDialogsUrl = visibility => {
  const userId = store.getState().user.auth.user?.id;
  return visibility === 'public'
    ? `jsonapi/node/voice_message?include=uid,field_file_public${publicFilter()}&${dialogFields}&sort=-created&page[limit]=500`
    : // directed inbound
      //: `jsonapi/node/voice_message?include=uid,field_file_public&fields[node--voice_message]=langcode,status,title,created,promote,sticky,field_responses_to_topic,field_parent,field_partition,field_public,field_recipient,field_type,uid,field_file_public&fields[user--user]=name,field_screen_name&fields[file--file]=uri,filesize&filter[field_public]=0&filter[field_recipient]=${userId}&sort=-created`

      //directed both directions:
      `jsonapi/node/voice_message?include=uid,field_file_public&${dialogFields}${directedFilter(
        userId
      )}&sort=-created&page[limit]=500`;
};

export const getPublicWithinPartitionUrl = () => getDialogsUrl('public');
export const getDirectedAllPartitionsUrl = () => getDialogsUrl('directed');
export const diagnosticGetDirectedAllPartitionsUrl = userId =>
  `jsonapi/node/voice_message?include=uid,field_file_public&${dialogFields}${directedFilter(
    userId
  )}&sort=-created&page[limit]=500`;

// create URL to request profile message(s)
// accepts single user ID or array of IDs
export const getProfilesUrl = ({ userId, partition }) =>
  `jsonapi/node/voice_message?include=uid,field_file_public&${dialogFields}${profileFilter(
    { userId, partition }
  )}&sort=-created`;

export const getUserMessagesInPartitionUrl = ({ userId, partition }) =>
  `jsonapi/node/voice_message?include=uid,field_file_public&${dialogFields}${userMessagesInPartitionFilter(
    { userId, partition }
  )}&sort=-created`;

export const getPublicResponserMessagesInPartitionUrl = ({ partition }) =>
  `jsonapi/node/voice_message?include=uid,field_file_public&${dialogFields}${publicResponseMessagesInPartitionFilter(
    { partition }
  )}&sort=-created`;

export const getMessageUrl = id =>
  `jsonapi/node/voice_message/${id}?include=uid,field_file_public&${dialogFields}`;

/*
filters for invite messages

to recipient (outbound)
https://hvn.hotlynx.com/jsonapi/node/voice_message
?include=uid
&filter[field_recipient]=11638807-5989-1929-9843-800000000000
&filter[field_type]=invite
&fields[node--voice_message]=title,created,field_partition,field_recipient,field_type,uid
&fields[user--user]=name,field_screen_name


to recipient (outbound, minimal fields)
https://hvn.hotlynx.com/jsonapi/node/voice_message
?fields[node--voice_message]=field_partition,field_recipient,field_type
&filter[field_recipient]=11638807-5989-1929-9843-800000000000
&filter[field_type]=invite

https://hvn.hotlynx.com/jsonapi/node/voice_message?fields[node--voice_message]=field_partition,field_recipient,field_type&filter[field_recipient]=11638807-5989-1929-9843-800000000000&filter[field_type]=invite

---

https://hvn.hotlynx.com/jsonapi/node/voice_message
&filter[field_recipient]=11638807-5989-1929-9843-800000000000
&filter[field_type]=invite
&fields[node--voice_message]=field_partition,field_recipient,field_type


with user (minimal fields)
https://hvn.hotlynx.com/jsonapi/node/voice_message
?fields[node--voice_message]=field_recipient,field_type,uid
&filter[recipient][condition][path]=field_recipient
&filter[recipient][condition][value]=11221845-4086-2085-4444-000000000000
&filter[recipient][condition][memberOf]=withUser
&filter[author][condition][path]=uid.id
&filter[author][condition][value]=11221845-4086-2085-4444-000000000000
&filter[author][condition][memberOf]=withUser
&filter[withUser][group][conjunction]=OR
&filter[type][condition][memberOf]=root
&filter[type][condition][path]=field_type
&filter[type][condition][value]=invite
&filter[root][group][conjunction]=AND

https://hvn.hotlynx.com/jsonapi/node/voice_message?fields[node--voice_message]=field_recipient,field_type,uid&filter[recipient][condition][path]=field_recipient&filter[recipient][condition][value]=11221845-4086-2085-4444-000000000000&filter[recipient][condition][memberOf]=withUser&filter[author][condition][path]=uid.id&filter[author][condition][value]=11221845-4086-2085-4444-000000000000&filter[author][condition][memberOf]=withUser&filter[withUser][group][conjunction]=OR&filter[type][condition][memberOf]=root&filter[type][condition][path]=field_type&filter[type][condition][value]=invite&filter[root][group][conjunction]=AND

*/

// url to filter invites to/from the specified user
export const getUserInvitesUrl = id =>
  `jsonapi/node/voice_message?${invitesFields}&filter[recipient][condition][path]=field_recipient&filter[recipient][condition][value]=${id}&filter[recipient][condition][memberOf]=withUser&filter[author][condition][path]=uid.id&filter[author][condition][value]=${id}&filter[author][condition][memberOf]=withUser&filter[withUser][group][conjunction]=OR&filter[type][condition][memberOf]=root&filter[type][condition][path]=field_type&filter[type][condition][value]=invite&filter[root][group][conjunction]=AND`;

//--------------------------------------------------------------------------------------------------------
// previously from buildRequest.js

/**
 * Drupal 8 entities and available fields
 * (unused fields are commented out)
 */
const post = {
  type: 'node--public_dialog',
  descriptors: [
    'id',
    'attributes.drupal_internal__nid',
    //        'attributes.drupal_internal__vid',
    'attributes.langcode',
    //        'attributes.revision_timestamp',
    //        'attributes.revision_log',
    'attributes.status',
    'attributes.title',
    'attributes.created',
    //        'attributes.changed',
    //        'attributes.promote',
    //        'attributes.sticky',
    //        'attributes.default_langcode',
    //        'revision_translation_affected',
    'attributes.field_responses.last_comment_timestamp',
    'attributes.field_responses.comment_count',
    'relationships.uid.data.id',
    'relationships.field_public_post.data.id',
    //        'relationships.field_public_post.data.meta.description'
  ],
};
// example request:
// `${API_ROOT}/node/public_dialog?include=uid,field_public_post&filter[field_public_post.filesize][condition][value]=1000&filter[field_public_post.filesize][condition][path]=field_public_post.filesize&filter[field_public_post.filesize][condition][operator]=%3C%3E&fields[node--public_dialog]=drupal_internal__nid,langcode,status,title,created,field_responses,field_public_post&fields[user--user]=display_name,drupal_internal__uid,status,langcode&fields[file--file]=uri,filesize,status&sort=-created`;

const response = {
  type: 'comment--public_response',
  descriptors: [
    'id',
    //        'attributes.drupal_internal__cid',
    'attributes.langcode',
    //        'attributes.status',
    'attributes.subject',
    //        'attributes.name',
    //        'attributes.homepage',
    'attributes.created',
    //        'attributes.changed',
    //        'attributes.thread',
    //        'attributes.entity_type',
    //        'attributes.field_name',
    //        'attributes.default_langcode',
    'relationships.uid.data.id',
    'relationships.entity_id.data.id',
    'relationships.field_public_response_recording.data..id',
    //        'relationships.field_public_response_recording.meta.description'
  ],
};

/*
Example requests:

${API_ROOT}/node/public_dialog
?include=uid,field_public_post
&filter[field_public_post.filesize][condition][value]=1000&filter[field_public_post.filesize][condition][path]=field_public_post.filesize&filter[field_public_post.filesize][condition][operator]=%3C%3E
&fields[node--public_dialog]=drupal_internal__nid,langcode,status,title,created,field_responses,field_public_post
&fields[user--user]=display_name,drupal_internal__uid,status,langcode
&fields[file--file]=uri,filesize,status&sort=-created

${API_ROOT}/comment/public_response
?include=field_public_response_recording,entity_id,uid
&filter[field_public_response_recording.filesize][condition][value]=1000&filter[field_public_response_recording.filesize][condition][path]=field_public_response_recording.filesize&filter[field_public_response_recording.filesize][condition][operator]=%3E%3D
&fields[comment--public_response]=langcode,created,subject,uid,entity_id,field_public_response_recording
&fields[file--file]=filesize,uri&fields[user--user]=display_name
&sort=-created


${API_ROOT}//user/user/11221845-4086-2085-4444-000000000000
?fields[user--user]=access,changed,created,default_langcode,display_name,drupal_internal__uid,field_birthdate,field_gender,field_id_provider,field_real_name,field_screen_name,field_socialimage,init,langcode,login,mail,name,preferred_admin_langcode,preferred_langcode,status,timezone

    full list of fields
    fields[user--user]=
      access,
      changed,
      reated,
      default_langcode,
      display_name,
      drupal_internal__uid,
      field_birthdate,
      field_gender,
      field_id_provider,
      field_real_name,
      field_screen_name,
      field_socialimage,
      init,
      langcode,
      login,
      mail,
      name,
      preferred_admin_langcode,
      preferred_langcode,
      status,
      timezone

*/

const file = {
  type: 'file--file',

  descriptors: [
    'id',
    //        'attribute.drupal_internal__fid',
    //        'attribute.langcode',
    //        'attribute.filename',
    //        'attribute.uri.value',
    'attribute.uri.url',
    //        'attribute.filemime',
    'attribute.filesize',
    //        'attribute.status',
    //        'attribute.created',
    //        'attribute.changed',
    //        'relationships.uid.data.id'
  ],
};

const user = {
  type: 'user--user',
  descriptors: [
    'id',
    'attributes.display_name',
    //        'attributes.drupal_internal__uid',
    //        'attributes.langcode',
    //        'attributes.preferred_langcode',
    //        'attributes.preferred_admin_langcode',
    //        'attributes.name',
    //        'attributes.mail',
    //        'attributes.timezone',
    //        'attributes.status',
    //        'attributes.created',
    //        'attributes.changed',
    //        'attributes.access',
    //        'attributes.login',
    //        'attributes.init',
    //        'attributes.default_langcode'
    //       custom:
    //        'attributes.field_birthdate,
    //        'attributes.field_first_name,
    //        'attributes.field_gender,
    //        'attributes.field_id_provider,
    //        'vfield_last_name,
    //        'attributes.field_socialimage,
    'relationships.roles.data.id',
    'relationships.field_attributes.data.id',
    //         'relationships.user_picture'
  ],
};

export const d8Entities = [post, response, file, user];

/**
 * Helper function to compose JSON:API requests
 * example posts.url: /jsonapi/node/public_dialog?include=uid,field_public_post&fields[node--public_dialog]=drupal_internal__nid,langcode,status,title,created,field_responses,field_public_post&fields[user--user]=display_name,drupal_internal__uid,status,langcode&fields[file--file]=uri,filesize,status&filter[field_public_post.filesize][condition][value]=1000&filter[field_public_post.filesize][condition][path]=field_public_post.filesize&filter[field_public_post.filesize][condition][operator]=%3E%3D&sort=-created
 * example responses.url: /jsonapi/comment/public_response?include=uid,field_public_response_recording,entity_id&fields[node--public_dialog]=drupal_internal__nid,langcode,status,title,created,field_responses,field_public_post&fields[user--user]=display_name,drupal_internal__uid,status,langcode&fields[file--file]=uri,filesize,status&filter[field_public_response_recording.filesize][condition][value]=1000&filter[field_public_response_recording.filesize][condition][path]=field_public_response_recording.filesize&filter[field_public_response_recording.filesize][condition][operator]=%3E%3D&sort=-created
 *
 * @param {string} type      - entity type: {posts, responses}
 * @param {string} uuid - optional, if present a singular entity is requested, otherwise a collection
 * @param {string} filter - optional, [{tag, path, val, op} ... ] array of specifications for additional filtering
 */
export const buildRequest = request => {
  const { type, uuid, filter } = request;

  //console.log(`buildRequest: type=${type}; uuid=${uuid}`)
  if (filter && filter.length > 0) {
    //console.log('...with filter: ', JSON.stringify(filter))
  }

  /**
   * Data structure defining the components of JSON:API request strings
   */
  let args = null;
  switch (type) {
    case 'posts':
      args = {
        entity: '/node/public_dialog',
        includes: ['uid', 'field_public_post'],
        fields: [
          {
            grp: 'node--public_dialog',
            list: [
              'drupal_internal__nid',
              'langcode',
              'status',
              'title',
              'created',
              'field_responses',
              'field_public_post',
              'uid',
            ],
          },
          {
            grp: 'user--user',
            list: ['display_name', 'field_screen_name'],
          },
          {
            grp: 'file--file',
            list: ['uri', 'filesize', 'status'],
          },
        ],
        filter: {
          tag: 'field_public_post.filesize',
          path: 'field_public_post.filesize',
          val: 1000,
          op: '%3E%3D',
        },
        sort: '-created',
      };
      break;
    case 'responses':
      args = {
        entity: '/comment/public_response',
        includes: ['uid', 'field_public_response_recording', 'entity_id'],
        fields: [
          {
            grp: 'node--public_dialog',
            list: [
              'drupal_internal__nid',
              'langcode,status',
              'title',
              'created',
              'field_responses',
              'field_public_post',
            ],
          },
          {
            grp: 'user--user',
            list: ['display_name', 'field_screen_name'],
          },
          {
            grp: 'file--file',
            list: ['uri', 'filesize', 'status'],
          },
        ],
        filter: {
          tag: 'field_public_response_recording.filesize',
          path: 'field_public_response_recording.filesize',
          val: 1000,
          op: '%3E%3D',
        },
        sort: '-created',
      };
      break;
    case 'users':
      args = {
        entity: '/user/user',
        includes: ['roles', 'field_attributes', 'field_user_info'],
        fields: [
          {
            grp: 'user--user',
            list: [
              'created',
              'drupal_internal__uid',
              'field_attributes',
              'field_user_info',
              'field_birthdate',
              'field_gender',
              'field_language',
              'field_id_provider',
              'field_real_name',
              'field_screen_name',
              'field_socialimage',
              'field_json_params',
              'field_memberships',
              'field_service_level',
              'field_service_expire_date',
              'field_max_service_rank',

              'mail',
              'name',
              'roles',
              'status',
              'timezone',

              // added to support notifications
              'field_notif_consent',
              'field_notif_params',
            ],
          },
          {
            grp: 'user_role--user_role',
            list: ['label'],
          },
          {
            grp: 'taxonomy_term--user_tags',
            list: ['name', 'description'],
          },
          {
            grp: 'node--user_info',
            list: [
              'title',
              'field_bookmarks',
              'field_blacklist',
              'field_ping_awake',
              'field_user_is_awake',
            ], //@@ not kept current locally, use user.status instead
          },
        ],
        sort: '-created',
      };
      break;
    default:
      console.error(`buildRequest- bad type: ${type}`);
      return null;
  }

  const getInclude = includes =>
    includes ? `?include=${includes.toString()}` : '';
  const getFilter = ({ tag, path, val, op }) => {
    return tag && val && path && op
      ? `&filter[${tag}][condition][value]=${val}` +
          `&filter[${tag}][condition][path]=${path}` +
          `&filter[${tag}][condition][operator]=${op}`
      : '';
  };
  const getSort = sort => (sort ? `&sort=${sort}` : '');
  const getFields = ({ grp, list }) =>
    grp ? `&fields[${grp}]=${list.toString()}` : '';

  /**
   *  If uuid is falsy the request is for a collection
   */

  if (!args) {
    console.error(`buildRequest- bad type: ${type}`);
    return null; // invalid type results in null args
  }

  let url = API_ROOT + args.entity;
  url += uuid ? `/${uuid}` : '';
  url += args.includes && getInclude(args.includes);
  url +=
    args.fields &&
    args.fields.map(({ grp, list }) => getFields({ grp, list })).join('');

  // omit filters and sorting for singular requests
  url += !uuid && args.filter ? getFilter(args.filter) : ''; // from fixed args

  if (!uuid && filter && filter.length > 0) {
    filter.forEach(item => {
      url += getFilter(item); // from input args
    });
  }

  url += !uuid && args.sort ? getSort(args.sort) : '';

  const headers = new Headers({
    'Content-Type': 'application/vnd.api+json',
    'Accept': 'application/vnd.api+json',
  });

  //console.log('buildRequest: ', url)
  return { url, headers };
};

/*

https://hvn.hotlynx.com/jsonapi/node/voice_message?include=uid,field_file_public&filter[field_public]=1&filter[field_type]=profile
jsonapi/node/voice_message?include=uid,field_file_public&fields[node--voice_message]=created,field_file_public,field_parent,field_partition,field_public,field_recip_name,field_recipient,field_responses_to_topic,field_type,langcode,promote,status,sticky,title,uid&fields[user--user]=name,field_screen_name&fields[file--file]=uri,filesize&filter[field_public]=1&filter[field_type]=profile&filter[uid.id]=11221845-4086-2085-4444-000000000000
*/

/**
 * If item values (data, included) are not arrays,
 * Place those values into arrays
 */
export const makeArrays = item => {
  const { data, included } = item;
  return {
    data: makeArray(data),
    included: makeArray(included),
  };
};

// assemble and flatten the objects returned from jsonapi GET request
export const assemble = (
  nodes,
  relationshipFields = ['uid', 'field_file_public']
) => {
  const { data, included } = makeArrays(nodes); //be sure that data, included are arrays
  const relationshipItemId = (node, item) =>
    node.relationships ? node.relationships[item]?.data?.id : null;

  // gather all key:value pairs from relationship fields
  const relationships = node =>
    relationshipFields.reduce((o, key) => {
      const id = relationshipItemId(node, key);
      return {
        ...o,
        [key]: {
          // for each relation, apply its id and find its attributes
          id: id,
          attributes: included.find(incItem => incItem.id === id)?.attributes,
        },
      };
    }, {});

  //const nodesWithRelationshipData = data.map(node => ({ id: node.id, ...(node.attributes), ...relationships(node) }))
  const assembled = data.map(node => {
    // no need to flatten if included.length === 0
    if (node.relationships) {
      return flattenObj({
        id: node.id,
        ...node.attributes,
        ...relationships(node),
      });
    } else {
      return { id: node.id, ...node.attributes };
    }
  });
  return assembled;
};
