/**
 * useEchoSuppressor custom hook
 *
 * Wrapper for EchoSuppressor class
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog2 from 'components/Modals/Dialog2';
import { useSelector, useDispatch } from 'react-redux';
import AuthorAnnotation from 'containers/MessagePlayer/components/AuthorAnnotation';
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';
import { Grid, Paper, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MediaView from 'components/WebRTCDemo/components/MediaView';
import ButtonStd from 'components/utils/ButtonStd';
import { t } from 'lib/translation/trans';
import { getCallManager } from 'lib/api/webRTCApi';
// import CallStatus from '../CallStatus'
//import CallTimeIndicator from './components/CallTimeIndicator'
//import ProgressIndicator from './components/ProgressIndicator'
//import CallSettings from './components/CallSettings'

import {
  localStreamProcessor,
  remoteStreamProcessor,
  pdm,
  setSpeakerMuted,
} from 'lib/api/webRTCApi';
import EchoSuppressor from 'lib/classes/EchoSuppressor';
import { uiActions } from 'store/ui-slice';

const useEchoSuppressor = () => {
  const [expanded, setExpanded] = useState(false);
  const suppressingEcho = useSelector(state => state.ui.speakerAutoMute);

  const dispatch = useDispatch();

  const { call, muted, participants } = useSelector(state => state.webRTC);

  const micMuted = muted;
  const speakerMuted = participants.remote.muted;
  const echoSuppressOn = useSelector(
    state => state.ui.pSession.liveCallAudioSettings.halfDuplex
  );
  const { progress } = call;
  const echo = useRef(null);

  // handle call progress changes
  useEffect(() => {
    switch (progress) {
      case 'connected':
        break;
      default:
        echo.current && echo.current.destroy();
        echo.current = null;
        break;
    }
  }, [progress]);

  // instantiate echo management upon establishment of connection
  useEffect(() => {
    if (
      localStreamProcessor &&
      remoteStreamProcessor &&
      progress === 'connected'
    ) {
      echo.current = new EchoSuppressor(
        localStreamProcessor,
        remoteStreamProcessor,
        pdm,
        handleAutoMute
      );
      global.echo = echo.current;
    }
  }, [localStreamProcessor, remoteStreamProcessor, progress]);

  // process changes in manual controls
  useEffect(() => {
    echo.current &&
      echo.current.applyManualControls({
        micMuted,
        speakerMuted,
        echoSuppressOn,
      });
  }, [echo.current, micMuted, speakerMuted, echoSuppressOn]);

  const handleAutoMute = mute => dispatch(uiActions.setSpeakerAutoMute(mute));

  return echo.current;
};

export default useEchoSuppressor;
