/**
 * MembershipLevelActionButtons
 *
 * Action button for selecting a specific plan.
 *
 */
import { Grid } from '@material-ui/core';
import ButtonStd from 'components/utils/ButtonStd';
import { useSelector } from 'react-redux';
import { levels } from '../config/plans.js';

const MembershipLevelActionButtons = ({ plan, onClick }) => {
  const { isAuthenticated, auth } = useSelector(state => state.user);
  const { user } = auth;

  console.log(
    `isAuthenticated=${isAuthenticated}, serviceLevel=${user?.serviceLevel}, maxServiceRank=${user?.maxServiceRank}, serviceExpireDate=${user?.serviceExpireDate}`
  );
  // dynamically calculated labels for buttons
  // for empty label, do not show the button
  let buyButtonLabel = '';
  let trialButtonLabel = '';

  // is this plan ranked as a free service (100 range)?
  const isFreePlan = plan.level.rank < 200;

  // has the user made a prior purchase or trial at the current or lower rank?
  const isUserEligibleForSample = user?.maxServiceRank < plan.level.rank;

  //look up the rank of the user's current service level
  const userRank = () => levels.find(l => l.level === user?.serviceLevel)?.rank;

  // label for the 'buy' button is context sensitive
  const buyLabel = () => {
    if (isAuthenticated) {
      if (plan.level.key === user?.serviceLevel) {
        return 'Renew';
      } else if (userRank() < plan.level.rank) {
        return 'Upgrade';
      } else {
        return 'Buy Now';
      }
    } else {
      return 'Buy Now';
    }
  };

  if (!isAuthenticated) {
    // free services do not offer trials, but offer a "Get Started" action
    buyButtonLabel = isFreePlan ? 'Get Started' : 'Buy Now';
    trialButtonLabel = isFreePlan ? '' : 'Free Trial';
  } else {
    // for authenticated user, paid services can be bought
    // and can be free tried as long as they pass the priorExclusion test
    buyButtonLabel = isFreePlan ? '' : buyLabel();

    trialButtonLabel =
      isUserEligibleForSample && !isFreePlan ? 'Free Trial' : '';
  }

  return (
    <Grid style={{ width: '100%' }} container justifyContent="space-between">
      <ButtonStd
        onClick={() => onClick({ plan, button: buyButtonLabel })}
        label={buyButtonLabel}
        color="secondary"
        style={{ visibility: buyButtonLabel.length ? 'visible' : 'hidden' }}
      />
      <ButtonStd
        onClick={() => onClick({ plan, button: trialButtonLabel })}
        label={trialButtonLabel}
        color="primary"
        style={{ visibility: trialButtonLabel.length ? 'visible' : 'hidden' }}
      />
    </Grid>
  );
};

export default MembershipLevelActionButtons;
