import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import { Grid, Switch, Typography } from '@material-ui/core';
import { t } from 'lib/translation/trans';

// styled switch
const PurpleSwitch = withStyles({
  switchBase: {
    'color': purple[300],
    '&$checked': {
      color: purple[500],
    },
    '&$checked + $track': {
      backgroundColor: purple[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function DMViewSelector({ view, setView }) {
  const handleChange = () => {
    setView(view === 'New' ? 'Archive' : 'New');
  };

  return (
    <Typography component="div">
      <Grid
        style={{ margin: 1, height: 56 }}
        component="label"
        container
        alignItems="center"
        spacing={1}
      >
        <Grid item>{t('New')}</Grid>
        <Grid item>
          <PurpleSwitch
            checked={view === 'Archive'}
            onChange={handleChange}
            name="dmSwitch"
          />
        </Grid>
        <Grid item>{t('Archive')}</Grid>
      </Grid>
    </Typography>
  );
}
