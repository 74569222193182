import { createSlice } from '@reduxjs/toolkit';
import localStore from 'lib/utils/localStore';
import { defaultCommunity, tipsControl } from 'configs/config-hvn';
import { langActions } from './lang-slice';
import { t } from 'lib/translation/trans';
import { dupFreePush } from 'lib/utils/utils';
import { StayCurrentPortraitTwoTone } from '@material-ui/icons';

// default recommended filter settings
export const browseFilterTypesRecommendedSet = {
  recommended: true,
  posts: true,
  responses: false,
  profiles: false,
  directed: true,
  tips: true,
  ads: true,
  notifications: true,
};

// if structure of pSession has changed, update this revision
// no need to update for simpla addition of a key
//export const PSESSION_VERSION = "20230601"
export const PSESSION_VERSION = '20231030';

//@@ consider adding cross-device persistent parameters
const initialState = {
  // persistent session info from localstorage
  pSession: {
    version: PSESSION_VERSION,
    initialized: false, // indicates that pSession has been initialized
    welcomed: false, // indicates that user h as visited a welcome page
    cookiesAcceptedTime: null, //timestamp when cookies were accepted
    termsAgreedTime: null, //timestamp when user agreed to  Terms and Conditions
    community: defaultCommunity, // current community/SIG {partition, title, params }

    micApproved: false, //mic. has been approved on this workstation
    autoRecord: false, //Recorder starts automatically when launched
    autoPlay: false, //Player starts automatically when message is selected
    autoLiveCall: false, //LiveCall connects when callee accepts

    // beta test requires users to first enter an invitation code.
    // this flag is extinguished when user presents a valid code.
    betaTestRestriction: false, // disabled feature

    browserView: 'Playlist', // Playlist | Carousel
    // disable future rendering of respondTips
    //TODO: re-enable respond tips
    respondTipDisable: false,
    menuPosition: 'tabs', // how should the main menu be presented: 'tabs', 'footer', 'drawer', 'none'

    // filter criteria for users, messages, other entities
    // see EntityFilter class for usage information
    filterParams: {},

    browseFilterAuthors: 'all', // playlist filter by author group
    browseFilterTypes: browseFilterTypesRecommendedSet, // browser filter by message types

    langCode: 'EN', // persistent chosen UI language

    // parameters to optimize talk and listen behavior during LiveCall
    liveCallAudioSettings: {
      micVolume: 50,
      speakerVolume: 50,
      halfDuplex: false,
      autoGainControl: false, //initializetion constraint set by user
      noiseSuppression: false, // initialization
      echoCancellation: true, // initialization
    },
    pAuth: {
      user: {
        name: null,
        id: null,
      },
      authObject: {},
    },

    bypassMessageRecorderSuccessForm: false, // bypass the success dialog post-recording
    suggestionDisableTypes: [], // list of reminder types that user has disabled
    pwaInstalled: false, // assert when PWA is first installed
    // NOTE: cannot detect if PWA is later uninstalled
  },

  // state of current session
  pwaSession: {
    startedAsPWA: null, // assert if session started as installed PWA
    newInstall: null, // assert when newly installed PWA becomes visible
  },

  suggestionControl: {
    invoke: false,
    type: null, // 'app_install', "push_notifications", "sms_notifications"
    presentedTypes: [], // list of suggestions previously presented this session
  },

  enableDebugView: true, // enable /debug route
  dlog: {
    // diagnostic log
    enabled: false, // enable the dlog feature (kayboard 'd' to toggle display)
    open: false, // render the log
    buff: [], // log data buffer
    maxLen: 200, //maximun buff size
  },

  initialPathname: '/', // initial route accessed during this session

  // layout control
  layout: {
    //disable-- menuVariant: 'group',  // selects a variant of the main menu to use {}
    mainContentHeight: 0, //height available for main content without scrollbar
    componentHide: {
      // temporarily hide components
      menu: false,
      login: false,
      community: false,
    },
  },

  voicePlayerMasterPauseTrigger: 0, // any change in value initiates global pause of VoicePlayers

  // Recorder status
  recorder: {
    busy: false,
    micSampleRate: new (window.AudioContext || window.webkitAudioContext)()
      .sampleRate,
  },
  // Inbox view control
  inbox: {
    alert: false, // bring attention to the user while browsing
    mostRecentId: null, // the most recent message that triggerd an alert
    preventNewAlerts: false, // ignore new alerts while asserted
    deferAlerts: true, // defer alerts during critical activities
  },
  // information about the user's device gathered during initialization
  deviceInfo: {
    mobile: null, // true for mobile device
    device: null,
    os: null,
    descriptor: '',
    innerWidth: null,
    innerHeight: null,
    fieldFocus: false, //indicates wither a user input field is in focus
    virtualKeyboardActive: false, //indicates that mobile device virtual keyboard is probably active
  },

  userIpAddress: null,

  // indicates vertical extra small viewport
  vxs: false, //TODO2: move vxs into deviceInfo
  // full page backdrop with optional circular progress
  backdrop: {
    show: false,
    showProgress: true,
    msg: 'Please_Wait',
  },
  // momentary alert
  alert: {
    open: false,
    severity: '', //'error', 'warning', 'info', 'success'
    message: '',
  },
  dialog: {
    open: false,
    content: 'Redux Default Dialog',
  },
  // registration dialog
  regDialog: {
    open: false,
    mode: null, // 'register' or 'login', otherwise will prompt
    bypassVoiceAttributes: false, // do not prompt for voice attributes during dialog
  },

  // experimental implementation of tips Popover controls
  tipsControl: {
    respondTipOpen: false, //initially, one tip Popover is supported for the 'respond' action button
    openCnt: 0, // no. of times that tips have been shown
    pingCnt: 0, //counter to control when to launch tip
    holdoff: false, // delay actuation of tips when respondTipOpen is true
    // pSession.respondTipDisable prevents ongoing presentation of tips
    keys: {
      default: {
        arrow: 'down', //'down', 'back', 'forward'
        text: 'Use this microphone to record',
      },
    },
  },

  showBreakpointIndicator: false, //use for debug only

  communities: [], // complete list of available communities
  speakerAutoMute: false, //indicate that echo suppression is currently muting the speaker
  holdoffCookieConsentBar: false, // when set, temporarily hide the CookieConsentBar
  browserIsHidden: false, // asserted when the browser session is moved to the background
  applianceLegendsOverride: null, // overrides ApplianceControls props.legends
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    // note whether the browser window is hidden/unhidden
    setBrowserIsHidden(state, action) {
      state.browserIsHidden = Boolean(action.payload);
    },

    // set the entrypoint for the session
    setInitialPathname(state, action) {
      state.initialPathname = action.payload;
    },

    // record timestamp when cookies were accepted by user
    noteCookiesAccepted(state) {
      state.pSession.cookiesAcceptedTime = Date.now();
      localStore().setObj('pSession', state.pSession);
    },

    // record timestamp when Terms and Conditions were agreed to
    noteTermsAgreed(state) {
      console.log('noteTermsAgreed.');
      state.pSession.termsAgreedTime = Date.now();
      localStore().setObj('pSession', state.pSession);
    },

    //override the main menu items
    setMenuVariant(state, action) {
      //disable--  state.layout.menuVariant = action.payload
    },

    //set the position of the main menu
    setMenuPosition(state, action) {
      state.pSession.menuPosition = action.payload;
      localStore().setObj('pSession', state.pSession);
    },

    // set the vxs status
    vxsSetStatus(state, action) {
      state.vxs = action.payload;
    },

    // initialize persistent session
    initPsession(state) {
      // attempt to read pSession from local storage
      let pSessionLocal = localStore().getObj('pSession');
      if (!pSessionLocal) {
        // initialize with defaults
        state.pSession = { ...initialState.pSession, initialized: true };
        localStore().setObj('pSession', state.pSession);
        console.log(
          'initPsession with defaults:',
          JSON.parse(JSON.stringify(state.pSession))
        );
      } else if (pSessionLocal.version !== PSESSION_VERSION) {
        // version update, special handling of  PSESSION_VERSION_20230623

        // version update, transcribe to new template
        localStore().removeObj('pSession');

        // initialize using defaults
        // then iterate over pSessionLocal keys to overwrite default values
        // do NOT install old keys that are no longer present in the defaults.
        const pSession = Object.entries(pSessionLocal).reduce(
          (out, [key, value]) => (key in out ? { ...out, [key]: value } : out),
          initialState.pSession
        );
        state.pSession = {
          ...pSession,
          initialized: true,
          version: initialState.pSession.version,
        };

        localStore().setObj('pSession', state.pSession);
        console.log(
          `initPsession updating (${pSessionLocal.version} --> ${PSESSION_VERSION}):`,
          JSON.parse(JSON.stringify(state.pSession))
        );
      } else {
        // retrieved from storage
        state.pSession = pSessionLocal;
        //console.log("initPsession from local storage:", JSON.parse(JSON.stringify(state.pSession)));
      }
    },

    // post a new entry in dlog
    dlogPost(state, action) {
      if (state.dlog.enabled) {
        const newItem =
          typeof action.payload === 'string'
            ? action.payload
            : JSON.stringify(action.payload);

        // check buff length
        const isFull = state.dlog.buff.length >= state.dlog.maxLen;

        // remove first element if buff is full, then append new item
        const newBuff = [...state.dlog.buff.slice(isFull ? 1 : 0), newItem];
        state.dlog.buff = newBuff;
      }
    },
    dlogClear(state) {
      state.dlog.buff = initialState.dlog.buff;
    },
    dlogOpen(state, action) {
      state.dlog.open = state.dlog.enabled && action.payload;
    },

    // save user's IP address
    setIpAddress(state, action) {
      state.userIpAddress = action.payload;
    },

    //initialize deviceInfo
    initDeviceInfo(state, action) {
      const oldDeviceInfo = state.deviceInfo;
      state.deviceInfo = { ...oldDeviceInfo, ...action.payload };
    },

    //update viewport dimensions
    setViewportDimensions(state) {
      const oldDeviceInfo = state.deviceInfo;
      state.deviceInfo = {
        ...oldDeviceInfo,
        ...{ innerHeight: window.innerHeight, innerWidth: window.innerWidth },
      };
    },

    // set the special interest group (Community) in persistent memory
    setCommunity(state, action) {
      const newCommunity = action.payload;
      state.pSession.community = {
        partition: newCommunity?.partition,
        title: newCommunity?.title,
        gated: newCommunity?.gated,
        slogan: newCommunity?.slogan,
        params: newCommunity ? newCommunity?.params : {},
      };
      localStore().setObj('pSession', state.pSession);
    },

    // list of available communities
    setCommunities(state, actions) {
      state.communities = actions.payload;
    },

    //indicate that a user input field has focus
    noteFieldFocus(state, action) {
      const focus = action.payload;
      state.deviceInfo.fieldFocus = focus;
      //if a field on mobile device has focus, assume the virtual keyboard is active
      state.deviceInfo.virtualKeyboardActive =
        focus && Boolean(state.deviceInfo.mobile);
    },

    // note that beta test invitation has been approved
    betaTestInvitationApproved(state) {
      state.pSession.betaTestRestriction = false;
      localStore().setObj('pSession', state.pSession);
    },

    // note that the user has visited a welcome page
    setWelcomed(state) {
      state.pSession.welcomed = true;
      localStore().setObj('pSession', state.pSession);
    },
    // set microphone approval status for this workstation
    micApprovedSetStatus(state, action) {
      state.pSession.micApproved = action.payload;
      localStore().setObj('pSession', state.pSession);
    },
    // change autoRecord status
    autoRecordSetStatus(state, action) {
      state.pSession.autoRecord = action.payload;
      localStore().setObj('pSession', state.pSession);
    },
    // change autoPlay status
    autoPlaySetStatus(state, action) {
      state.pSession.autoPlay = action.payload;
      localStore().setObj('pSession', state.pSession);
    },
    // change autoLiveCall status
    autoLiveCallSetStatus(state, action) {
      state.pSession.autoLiveCall = action.payload;
      localStore().setObj('pSession', state.pSession);
    },

    // set persistent option
    optionSet(state, action) {
      const { key, value } = action.payload;
      state.pSession[key] = value;
      localStore().setObj('pSession', state.pSession);
    },

    backdropClr(state) {
      state.backdrop = initialState.backdrop;
    },
    backdropSet(state, action) {
      state.backdrop.show = true;
      state.backdrop.msg = action.payload || t('Please_Wait');
    },

    // passing a non-serializeable payload to dialogSet triggers console errors
    // (instead, refactor this to use react context)
    dialogClr(state) {
      state.dialog = initialState.dialog;
    },
    dialogSet(state, action) {
      state.dialog.open = true;
      state.dialog.content = action.payload;
    },

    // manage registration dialog
    setRegDialog(state, action) {
      const update = action.payload; // { open, mode, bypassVoiceAttributes }
      const newRegDialog = {
        ...state.regDialog,
        bypassVoiceAttributes: false,
        ...update,
      };
      state.regDialog = newRegDialog;
    },

    alertSet(state, action) {
      state.alert = { ...action.payload, open: true };
    },
    alertClr(state) {
      state.alert = initialState.alert;
    },

    // preserve existing keys in state.tipsControl
    // overwrite or create the specified keys
    tipsControlSetKeys(state, action) {
      // convert array of arrays to object
      //[[key, arrow, text], ... ]
      const keyArr = action.payload;
      if (Array.isArray(keyArr)) {
        // { key: {arrow, text}, ... }
        const keyObj = keyArr.reduce(
          (acc, [key, arrow, text]) => ({
            ...acc,
            ...{ [key]: { arrow, text } },
          }),
          {}
        );
        // append/overwrite existing keys
        const newKeys = Object.entries({
          ...state.tipsControl.keys,
          ...keyObj,
        }).reduce((out, [key, val]) => {
          out[key] = val;
          return out;
        }, {});
        state.tipsControl.keys = newKeys;
      }
    },

    // remove the keys specified in the payload array
    tipsControlClearKeys(state, action) {
      const keyArr = action.payload;
      if (Array.isArray(keyArr)) {
        const newKeys = Object.entries(state.tipsControl.keys).reduce(
          (out, [key, val]) => {
            // only include key if it is not in keyArr
            keyArr.includes(key) || (out[key] = val);
            return out;
          },
          {}
        );
        state.tipsControl.keys = newKeys;
      }
    },

    // close the tip modal
    respondTipClose(state) {
      state.tipsControl.respondTipOpen = false;
      state.tipsControl.respondTipHoldoff = false;

      // disable tips after max count
      if (state.tipsControl.openCnt >= tipsControl.maxCnt) {
        state.pSession.respondTipDisable = true;
        localStore().setObj('pSession', state.pSession);
      }
    },

    // track the ongoing need to present a tip
    respondTipPing(state) {
      if (!state.pSession.respondTipDisable) {
        const tipFrequency = tipsControl.frequency; //present tip every n pings
        state.tipsControl.pingCnt = ++state.tipsControl.pingCnt % tipFrequency;
        if (state.tipsControl.pingCnt === tipFrequency - 1) {
          state.tipsControl.respondTipOpen = true;
          state.tipsControl.openCnt += 1;
        }
      }
    },

    // indicate DOM transitions (e.g. playlist scrolling) that might interfere with rendering of Tips
    respondTipHoldoff(state, action) {
      const holdoff = action.payload;
      state.tipsControl.holdoff = holdoff;
    },

    // pause all VoicePlayer instances
    voicePlayerPause(state) {
      state.voicePlayerMasterPauseTrigger++;
    },

    // Recorder actions
    setRecorderBusy(state, action) {
      state.recorder.busy = action.payload;
    },

    // Inbox alert status
    setInboxAlert(state, action) {
      const messageId = action.payload;

      if (!Boolean(messageId)) {
        // clear the alert
        state.inbox.alert = false;
        return;
      }

      // only apply alert for a new mwssage
      if (
        !state.inbox.preventNewAlerts &&
        !state.inbox.deferAlerts &&
        messageId !== state.inbox.mostRecentId
      ) {
        state.inbox.alert = true;
        state.inbox.mostRecentId = messageId;
      }
    },

    // Disable assertion of Inbox alert
    preventNewInboxAlerts(state, action) {
      state.inbox.preventNewAlerts = action.payload;
    },

    // defer alerts until resumeInboxAlerts is invoked
    deferInboxAlerts(state) {
      state.inbox.deferAlerts = true;
    },

    // resume alerts, and assert alert if new message has arrived
    resumeInboxAlerts(state, action) {
      const inboundIdQueue = action.payload;
      const messageId = inboundIdQueue[0];

      state.inbox.deferAlerts = false;
      if (
        !state.inbox.preventNewAlerts &&
        messageId &&
        messageId !== state.inbox.mostRecentId
      ) {
        state.inbox.alert = true;
        state.inbox.mostRecentId = messageId;
      }
    },

    // hide or unhide layout components
    layoutComponentHide(state, action) {
      const updates = action.payload;
      state.layout.componentHide = {
        ...state.layout.componentHide,
        ...updates,
      };
    },

    setMainContentHeight(state, action) {
      state.layout.mainContentHeight = action.payload;
    },

    updateFilterParamsV3(state, action) {
      const filterParams = action.payload;
      state.pSession.filterParams = filterParams;
      localStore().setObj('pSession', state.pSession);
    },

    // set & persist the current UI language
    setLangCode(state, action) {
      // persist the selected language code
      const langCode = action.payload;
      state.pSession.langCode = langCode;
      localStore().setObj('pSession', state.pSession);
    },

    setLiveCallAudioSettings(state, action) {
      const updates = action.payload;
      const oldSettings = state.pSession.liveCallAudioSettings || {};
      state.pSession.liveCallAudioSettings = { ...oldSettings, ...updates };
      localStore().setObj('pSession', state.pSession);
    },
    setSpeakerAutoMute(state, action) {
      state.speakerAutoMute = action.payload;
    },

    /* DEPRECATED

    // open or close the SMS notificationsSuggestion
    // open:
    //  - only for registered user
    //  - only for user who has not previously opted in or out
    //  - only once per session
    notificationsSuggestion(state, action) {
      const { open: request, user } = action.payload;
      const { open, sessionCount } = state.notificationsSuggestion;
      console.log(
        `notificationsSuggestion count=${sessionCount}, request=${request}, open=${open}, consent=${user?.notifications?.consent}`
      );
      if (!request) {
        state.notificationsSuggestion.open = false;
      } else if (
        request &&
        sessionCount === 0 &&
        user.id &&
        !['+', '-', 'x'].includes(user?.notifications?.consent) // no previous opt-in or opt-out
      ) {
        console.log(`notificationsSuggestion setting open`);
        state.notificationsSuggestion.open = true;
        state.notificationsSuggestion.sessionCount += 1;
      }
    },

    // open or close the web-push notificationsSuggestion
    // open:
    //  - only for registered user
    //  - only if push notifications are available on this device
    //  - not if user has already subscribed on this device
    //  - not if user has asked not to be nagged on this device
    //  - only once per session
    pushNotificationsSuggestion(state, action) {
      const { open: request, user } = action.payload;
      const { open, sessionCount } = state.notificationsSuggestion;
      if (!request) {
        state.notificationsSuggestion.open = false;
      } else if (
        user.id && // registered User
        pwa.canGrantPushPermission() && // push available
        !state.pSession.pushNotificationsReminderDisable && //okToNag
        sessionCount === 0 //!already This Session
      ) {
        state.notificationsSuggestion.open = true;
        state.notificationsSuggestion.sessionCount += 1;
      }
    },

    // discontinue rendering NotificationsSuggestion modal
    pushNotificationsReminderDisable(state, action) {
      state.pSession.pushNotificationsReminderDisable = action.payload;
      localStore().setObj('pSession', state.pSession);
    },
*/

    // save persistent credentials
    updatePauth(state, action) {
      const updates = action.payload;
      const old =
        state.pSession?.pAuth?.authObject?.access_token &&
        JSON.parse(
          JSON.stringify(state.pSession?.pAuth?.authObject?.access_token)
        );
      const new1 = updates?.authObject?.access_token;

      //console.log(`updatePauth access_token  ${stringHash(old)} -->> ${stringHash(new1)}.`);
      state.pSession.pAuth = { ...state.pSession.pAuth, ...updates };
      localStore().setObj('pSession', state.pSession);
    },

    // temporarily delay rendering of CookieConsentBar
    holdoffCookieConsentBar(state, action) {
      state.holdoffCookieConsentBar = action.payload;
    },

    bypassMessageRecorderSuccessForm(state, action) {
      state.pSession.bypassMessageRecorderSuccessForm = action.payload;
      localStore().setObj('pSession', state.pSession);
    },

    // conditionally trigger a suggestion, contigent on feature availaabiliity
    suggestionInvoke(state, action) {
      const type = action.payload;
      state.suggestionControl.invoke = true;
      state.suggestionControl.type = type;
    },

    // note that a SuggestionDialog has been presented during this session
    suggestionPresented(state, action) {
      state.suggestionControl.presentedTypes = dupFreePush(
        state.suggestionControl.presentedTypes,
        action.payload
      );
    },

    suggestionClear(state) {
      state.suggestionControl.invoke = initialState.suggestionControl.invoke;
      state.suggestionControl.type = initialState.suggestionControl.type;
    },

    suggestionDisable(state, action) {
      state.pSession.suggestionDisableTypes = dupFreePush(
        state.pSession.suggestionDisableTypes,
        action.payload
      );
      localStore().setObj('pSession', state.pSession);
    },

    // set or clear persistent pwaInstalled flag
    notePWAInstalled(state, action) {
      state.pSession.pwaInstalled = action.payload;
      localStore().setObj('pSession', state.pSession);
    },

    pwaSetSessionStatus(state, action) {
      const { progress, runningAsPWA } = action.payload;
      if (progress === 'initialize') {
        // remember if session started as an installed PWA
        if (state.pwaSession.startedAsPWA === null) {
          // only update this once per session
          //console.log('diag startedAsPWA:', runningAsPWA);
          state.pwaSession = {
            ...state.pwaSession,
            startedAsPWA: runningAsPWA,
          };
        }
      }
      // at start of session and upon update, determine if we're running a fresh PWA install
      const newInstall =
        runningAsPWA &&
        state.pwaSession.startedAsPWA ===
          false; /* pwa now but didn's start as pwa */
      state.pwaSession = { ...state.pwaSession, newInstall };
    },

    setApplianceLegendsOverride(state, action) {
      state.applianceLegendsOverride = action.payload;
    },
  },
});

// persist the langCode and set up its langMap
// if no arg is supplied, propagate prior persisted langCode to the langMap
export const setLanguage = langCode => {
  return (dispatch, getState) => {
    if (langCode) {
      dispatch(uiActions.setLangCode(langCode));
      dispatch(langActions.setLangMap(langCode));
    } else {
      const savedLangCode = getState()?.ui?.pSession?.langCode;
      dispatch(langActions.setLangMap(savedLangCode));
    }
  };
};

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
