/**
 * Manage Progressive Web App features including a service worker.
 *
 * To Do:
 * - if unregister service worker, remove endpoint from push server.
 * - if consent is bring withdrawn, remove all of user's endpoints from the push server
 * - if a new subscription is being created, remove all prior endpoints for this user/platform
 * - consider adding expiration to all endpoint subscriptions
 * - IOS
 *    - tips: https://www.netguru.com/blog/pwa-ios
 *    - compatibility: https://firt.dev/notes/pwa-ios/
 *    - share icon: https://pictogrammers.com/library/mdi/icon/export-variant/
 *    - example install instructions: https://stackoverflow.com/questions/51160348/pwa-how-to-programmatically-trigger-add-to-homescreen-on-ios-safari#answer-61974926
 *
 */

export let pwa = null;

export default class PWA {
  serviceWorkerRegistration = null;
  pushSubscription = null;
  beforeinstallpromptEvent = null;
  notificationsPermissionSupported = null;
  notificationsPermissionGrantedThisSession = null;

  //default callbacks, override in constructor
  t = text => text; // translation utility
  deleteUserAllSubscriptions = () => {};
  notePWAInstalled = () => {};
  enablePushNotificationEventFromSW = () => {};

  constructor({
    t,
    deleteUserAllSubscriptions,
    enablePushNotificationEventFromSW,
    notePWAInstalled,
  }) {
    const installCallback = fn => {
      if (typeof fn === 'function') {
        this[fn.name] = fn;
      }
    };
    installCallback(t);
    installCallback(deleteUserAllSubscriptions);
    installCallback(notePWAInstalled);
    installCallback(enablePushNotificationEventFromSW);

    // register the service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/sw.js')
        .then(reg => {
          this.serviceWorkerRegistration = reg;
          return reg.pushManager.getSubscription();
        })
        .then(sub => {
          this.pushSubscription = sub;
        })
        .catch(function (err) {
          console.log('serviceWorker.register error:', err);
          this.serviceWorkerRegistration = null;
        });

      // listen for messages from service worker
      navigator.serviceWorker.onmessage = event => {
        if (event.data && event.data.type === 'HANDLE_PUSH_NOTIFICATION') {
          const options = event.data.options;
          if (this.enablePushNotificationEventFromSW()) {
            console.log(`HANDLE_PUSH_NOTIFICATION showNotification.`);
            navigator.serviceWorker.ready.then(swreg => {
              swreg.showNotification('OkSayit', options);
            });
          }
        }
      };
    }

    // intercept device's offer to install web-app
    window.addEventListener('beforeinstallprompt', event => {
      // References:
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeinstallprompt_event
      // https://web.dev/learn/pwa/installation/#installation-criteria
      // https://web.dev/learn/pwa/installation-prompt/

      event.preventDefault();
      this.beforeinstallpromptEvent = event;
      return false;
    });

    //detect when PWA is installed
    window.addEventListener('appinstalled', () => {
      this.notePWAInstalled();
      console.log('Event "appinstalled" ');
    });

    // checking if push Notifications are supported in this browser...
    this.notificationsPermissionSupported = Boolean(
      window.Notification &&
        navigator.serviceWorker &&
        Notification?.requestPermission
    );
    // 'PushManager' in window;

    // check whether we are running in browser tab or standalone
    window.addEventListener('DOMContentLoaded', () => {});
  }

  getState = () => this?.serviceWorkerRegistration?.active?.state;
  isActivated = () => this.getState() === 'activated';

  // install the app on desktop or home screen
  install = () => {
    console.log('PWA install');
    if (this.beforeinstallpromptEvent === null) {
      return Promise.resolve('no-op');
    }
    this.beforeinstallpromptEvent.prompt();
    return this.beforeinstallpromptEvent.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'dismissed') {
        console.log('User cancelled installation.');
      } else {
        //user approved installation after programmatic request
        this.notePWAInstalled();
      }
      this.beforeinstallpromptEvent = null;
      return choiceResult.outcome;
    });
  };

  // default function subscribes to firebase server
  defaultSubscribe = subscription => {
    console.log('defaultSubscribe:', subscription);
    return fetch(
      'https://pwa---the-complete-guide-default-rtdb.firebaseio.com/subscriptions.json',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(subscription),
      }
    );
  };

  // Notification.requestPermission used to take a callback (deprecated), but
  // now returns a Promise.  Handle both cases...
  requestPushPermission({
    userId,
    userName,
    descriptor,
    uploadPushSubscription,
  }) {
    if (Notification.permission === 'denied') {
      // user previously denied permission.
      alert('Notification permission was previously denied for this browser !');
      return Promise.reject('denied');
    }
    if (!Boolean(userId)) {
      // only registered user can subscribe.
      console.log('requestPushPermission:', 'No User');
      return Promise.reject('no user');
    }
    return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission(result =>
        resolve(result)
      );
      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(permissionResult => {
      console.log('Notification permissionResult:', permissionResult);
      this.notificationsPermissionGrantedThisSession =
        permissionResult === 'granted';
      return this.notificationsPermissionGrantedThisSession
        ? this.pushSubscribe({
            userId,
            userName,
            descriptor, //@@ add host to descriptor, support subscription from localhost, dev.oksayit.com
            uploadPushSubscription,
          })
        : Promise.resolve('No notification permission granted');
    });
  }

  // configure push notifications, register with server
  pushSubscribe = ({
    userId,
    userName,
    descriptor,
    uploadPushSubscription,
  }) => {
    //get access to service worker
    let reg;
    return (
      navigator.serviceWorker.ready
        .then(swreg => {
          // check for existing subscription
          reg = swreg;
          return swreg.pushManager.getSubscription();
        })
        .then(sub => {
          // already have a subscription ?
          if (sub === null) {
            // create a new subscription
            // (will render any prior subscription useless)
            // must coordinate security
            let vapidPublicKey =
              'BDFU_UGMX4-pScyIl3j5BMsK2suE45j-jw3ZJYZgMzHLd5jHzDTk4CmcmCFsyNOS0VD90VxbDBBav0VcyvYIhn4';
            let convertedVapidPublicKey =
              this.urlBase64ToUint8Array(vapidPublicKey);
            return reg.pushManager.subscribe({
              userVisibleOnly: true,
              // identify our backend server using vapid
              applicationServerKey: convertedVapidPublicKey,
            });
          } else {
            // we already have a subscription
            console.log('prior subscription:', sub);
            console.log('but this.pushSubscription:', this.pushSubscription);
            return null;
            // consider confirming proper registration with backend server
          }
        })

        // pass the subscription to the back end
        .then(newSub => {
          if (newSub) {
            this.pushSubscription = newSub;

            // pass the new subscription to the server
            const uploadData = {
              userId,
              userName,
              descriptor,
              ...JSON.parse(JSON.stringify(newSub)),
            };

            /**
             * @@ cleanup dead subscriptions from server
             * - remove all subscriptions for this browser with  newSub.endpoint
             */

            //@@ upload subscription using provided or default method
            return uploadPushSubscription
              ? uploadPushSubscription(uploadData)
              : this.defaultSubscribe(uploadData);
          } else {
            return null;
          }
        })
        .then(res => {
          if (res?.ok) {
            this.displayConfirmNotification();
          } else {
            console.log('Failed to upload subscription to back end.');
          }
          return res?.ok;
        })
        .catch(function (err) {
          console.log('pushSubscribe error:', err);
          return err;
        })
    );
  };

  // unsubscribe from existing push subscription
  pushUnsubscribe = () =>
    navigator.serviceWorker.ready.then(reg =>
      reg.pushManager.getSubscription().then(subscription =>
        subscription
          ? subscription
              .unsubscribe()
              .then(successful => {
                console.log('unsubscribe success');
                this.pushSubscription = null;
                return 'ok';
              })
              //remove ALL of user's subscriptions (all browsers, all devices)
              .then(() => this.deleteUserAllSubscriptions())
              .catch(e => {
                console.log('unsubscribe fail:', e);
                return e;
              })
          : (console.log('no subscription found.'), null)
      )
    );

  getPushEndpoint = async () => {
    const ep = await navigator.serviceWorker.ready
      .then(reg => reg.pushManager.getSubscription())
      .then(sub => {
        return sub ? sub.endpoint : null;
      });
    console.log('ep:', ep);
    return ep;
  };

  displayConfirmNotification = () => {
    console.log('issuing notification...');

    if (this.notificationsPermissionSupported) {
      navigator.serviceWorker.ready.then(swreg => {
        let options = {
          body: this.t('You_will_be_notified_of_DM'),
          icon: '/images/icons/favicon-64x64.png',
          //image: "/src/images/sf-boat.jpg",
          dir: 'ltr',
          lang: 'en-US', // BCP 47
          vibrate: [100, 50, 200],
          badge: '/images/icons/favicon-64x64.png',

          //advanced
          //tag: 'confirm-notification',  // controls overwriting, latest replaces prior
          //renotify: true, // vibrate, even when tag causes overwrite
          //actions: [
          //  {
          //    action: 'demo 1',
          //    title: 'Demo 1',
          //    icon: '/images/icons/favicon-64x64.png',
          //  },
          //  {
          //    action: 'demo 2',
          //    title: 'Demo 2',
          //    icon: '/images/icons/favicon-64x64.png',
          //  },
          //],
        };
        swreg.showNotification(`OkSayit ${this.t('Subscribed')} !`, options);
      });
    }
  };

  // utility to create Uint8 Array
  urlBase64ToUint8Array = base64String => {
    let padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    let base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  // unregister the service worker
  unregister = () => {
    console.log('PWA unregister');
    if (this.serviceWorkerRegistration === null) {
      return Promise.resolve('no-op');
    }

    return this.serviceWorkerRegistration
      .unregister()
      .then(ok => {
        console.log(`pwa unregister ${ok ? 'success.' : 'FAIL !'}`);
        this.serviceWorkerRegistration = null;
        this.pushSubscription = null;
        this.notificationsPermissionGrantedThisSession = null;
        return ok ? 'success' : 'FAIL';
      })
      .then(() => this.deleteUserAllSubscriptions());
  };

  // can install app if event has arrived
  canInstall = () => Boolean(this.beforeinstallpromptEvent);

  // can ask for permission only if feature is supported and not already active
  canGrantPushPermission = () =>
    this.notificationsPermissionSupported &&
    !(this.pushSubscription instanceof PushSubscription);

  // can unregister service worker only if already retistered
  canUnregister = () => Boolean(this.serviceWorkerRegistration);

  // can change state after appinstalled event
  runningAsPWA = () => {
    const manifestDisplay = 'standalone'; //value from manifest.json
    return window.matchMedia(`(display-mode: ${manifestDisplay})`).matches;
  };

  // factory for a singleton instance
  static create = ({
    t,
    deleteUserAllSubscriptions,
    enablePushNotificationEventFromSW,
    notePWAInstalled,
  }) => {
    !pwa &&
      (pwa = new PWA({
        t,
        deleteUserAllSubscriptions,
        enablePushNotificationEventFromSW,
        notePWAInstalled,
      }));
    global.pwa = pwa; //@@disgnostic only
  };
}
