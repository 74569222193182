/**
 * Render a categorized list of available communities.
 * Upon selection by user, take action either "switch", "join", or "welcome"
 *
 * context 1: invoked from CommunitySelectorDialog
 *  - Display all communities
 *      - show memberships duplicated in separate category
 *  - onClick actions
 *      - member: switch
 *      - nonMember: join
 *      - visitor: welcome
 *
 * context 2: invoked from Settings
 *  - Display only non-memberships
 *  - onClick actions
 *      - member: N/A
 *      - nonMember: join
 *      - visitor: N/A
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getCommunities, isMember } from 'lib/api/communityApi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  currentPartition,
  switchCommunity,
  categorizeCommunities,
} from 'lib/api/communityApi';
import { makeStyles } from '@material-ui/core/styles';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import CommunityInfo from 'containers/WelcomeView/components/CommunityInfo';
import { uiActions } from 'store/ui-slice';
import { t, strToVar } from 'lib/translation/trans';

import CommunityTree from './components/CommunityTree';
import CommunityList from './components/CommunityList';
import CommunityRadioForm from './components/CommunityRadioForm';

import { makeArray } from 'lib/utils/utils';

const useStyles = makeStyles(theme => ({
  listRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  listSection: {},
  ul: {
    padding: 0,
  },
  groupTitle: {},
  item: {
    'border': '2px green solid',
    'marginLeft': '4rem',
    'width': `calc(100% - 4rem )`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: `100%`,
    },
    'fontSize': '.5rem',
    '&:not(:last-child)': {
      marginBottom: '.5rem',
    },
  },
  itemText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '.7rem',
    },
  },
}));

export default function CommunitySelector({
  format,
  excludeMemberships,
  onChange,
  onJoined,
}) {
  const [communities, setCommunities] = useState([]);
  const [categorizedCommunities, setCategorizedCommunities] = useState([]);
  const [communitySelected, setCommunitySelected] = useState(null);
  const memberships = useSelector(state => state.user.auth?.user?.memberships);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();

  // conditionally exclude communities for which the user is a member
  const includeCommunity = c =>
    !(excludeMemberships && memberships.includes(c.partition));

  // fetch the communities list
  useEffect(() => {
    // stop player if in progress
    dispatch(uiActions.voicePlayerPause());

    getCommunities()
      .then(items => {
        // extract fields, filter, and sort
        setCommunities(
          items
            .map(item => ({
              partition: item.partition,
              group: item.group,
              gated: item.gated,
              slogan: item.slogan,
              title: item.title,
              params: item.params,
            }))
            .filter(c => includeCommunity(c))
            .sort((a, b) => (a.partition < b.partition ? 1 : -1))
        );
      })
      .catch(err => console.log('getCommunities error:', err));
  }, []);

  // update categororization when memberships change
  useEffect(() => {
    setCategorizedCommunities(
      categorizeCommunities(communities, currentPartition(), memberships)
    );
  }, [communities, currentPartition(), memberships]);

  // a partition is disabled from button click if user is already a member
  const isDisabled = partition => isMember(partition);

  // initiate render of CommunityInfo within a dialog
  const launchJoinDialog = partition => {
    //setValue(partition);
    setTimeout(() => {
      setCommunitySelected(partition);
    }, 500);
  };

  const welcomePageRendered = window.location.href.includes('/welcome');

  // take action when the user selects a community
  // if currently viewing a Welcome page, redirect to the new Welcome page
  // otherwise, take action based on membership  status
  const handleSelectedPartition = partition => {
    //console.log(`isAuthenticated=${isAuthenticated}, partition=${partition}, isMember=${isMember(partition)}`)
    if (welcomePageRendered) {
      onChange && onChange();
      history.push(`/welcome/${partition}`);
    } else if (isMember(partition)) {
      // switch to partition
      switchCommunity(
        communities.find(community => community?.partition === partition)
      );
      onChange && onChange();
    } else if (isAuthenticated) {
      // render 'join' dialog
      launchJoinDialog(partition);
    } else {
      // redirect to Welcome page
      onChange && onChange();
      history.push(`/welcome/${partition}`);
    }
  };

  const handleCommunityInfoExit = args => {
    const partition = args?.partition;
    const action = args?.action;
    setCommunitySelected(null); //close CommunityInfo dialog
    if (partition) {
      switch (action) {
        case 'joined':
          onJoined && onJoined(partition);
          break;
        case 'visit':
          onChange && onChange(); //closeCommunitySelectorDialog
          history.push('/playlist');
          break;
        default:
          break;
      }
    }
  };
  const renderSelector = format => {
    // filter communities
    switch (format) {
      case 'list':
        return (
          <CommunityList
            categorizedCommunities={categorizedCommunities}
            onChange={handleSelectedPartition}
            isDisabled={isDisabled}
          />
        );
      case 'radio':
        return (
          <CommunityRadioForm
            categorizedCommunities={categorizedCommunities}
            onChange={handleSelectedPartition}
            isDisabled={isDisabled}
          />
        );
      case 'tree':
        return (
          <CommunityTree
            communities={communities}
            onChange={handleSelectedPartition}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderSelector(format)}

      <FullScreenDialog
        open={Boolean(communitySelected)}
        title={'Community Information'}
        onClose={handleCommunityInfoExit}
        content={
          <CommunityInfo
            partition={communitySelected}
            onExitRequest={handleCommunityInfoExit}
          />
        }
        zen={true}
      />
    </>
  );
}

CommunitySelector.propTypes = {
  format: PropTypes.oneOf(['list', 'radio', 'tree']), // render as list, set of radio buttons, or a tree
  excludeMemberships: PropTypes.bool, // do not render communities for which user is a member
  onChange: PropTypes.func,
  onJoined: PropTypes.func,
};
CommunitySelector.defaultProps = {
  format: 'form',
  excludeMemberships: false,
};
