import { Android } from 'mdi-material-ui';
import store from 'store';
import { uiActions } from 'store/ui-slice';
import { t } from 'lib/translation/trans';
import { obfuscateDigitString } from './crypto';

export const timeout = duration => {
  const promise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('Done');
    }, duration);
  });
  return promise;
};

export const timeSince = date => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} ${t('years')}`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} ${t('months')}`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} ${t('days')}`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} ${t('hours')}`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} ${t('minutes')}`;
  }
  return `${Math.floor(seconds)} ${t('seconds')}`;
};

// extract selected properties from object
export const pick = (obj, ...keys) =>
  Object.fromEntries(
    keys.filter(key => key in obj).map(key => [key, obj[key]])
  );

// determine if executing under IOS
// (per https://stackoverflow.com/questions/9038625/detect-if-device-is-ios#answer-9039885)
// future: consider using https://www.npmjs.com/package/react-device-detect
export const isIOS = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

// parse navigator and window information
export const deviceInfo = () => {
  const userAgent = navigator.userAgent;
  const { innerWidth, innerHeight } = window;
  const deviceList = [
    { device: 'android', pattern: /Android/i },
    { device: 'webos', pattern: /webOS/i },
    { device: 'iphone', pattern: /iPhone/i },
    { device: 'ipad', pattern: /iPad/i },
    { device: 'ipod', pattern: /iPod/i },
    { device: 'blackberry', pattern: /BlackBerry/i },
    { device: 'winphone', pattern: /Windows Phone/i },
  ];
  const device = deviceList.find(item => userAgent.match(item.pattern))?.device;
  const mobile = Boolean(device);

  const browserList = [
    { browser: 'opera', pattern: /OPR\// },
    { browser: 'edge', pattern: /Edg\// },
    { browser: 'firefox', pattern: /Firefox\// },
    { browser: 'chrome', pattern: /CriOS\// },
    { browser: 'safari', pattern: /Safari\//, antiPattern: /Chrome\// },
    { browser: 'chrome', pattern: /Chrome\// },
  ];

  const os =
    device === 'android'
      ? device
      : userAgent.search('Windows') !== -1
      ? 'windows'
      : userAgent.search('Mac')
      ? mobile
        ? 'ios'
        : 'osx'
      : null;

  const foundBrowser = browserList.find(
    item =>
      userAgent.match(item.pattern) &&
      !(item?.antiPattern && userAgent.match(item.antiPattern))
  );
  const browser = foundBrowser?.browser;

  const descriptor = `${device ? device : 'desktop'}/${os}/${browser}`;
  return { mobile, device, os, browser, descriptor, innerWidth, innerHeight };
};

// invoke service to obtain user's IP address
export const getIpAddress = () => {
  /* slow and unreliable
   const url2 = 'https://api.ipify.org/?format=json';
   return fetch(url2 )
     .then(response => response.json() )
     .then(data => ( console.log("IP address2: ", data?.ip), data?.ip) )
     .catch(err => console.log("getIpAddress error.", err));
 */

  const url1 = 'https://geolocation-db.com/json/';
  return fetch(url1)
    .then(response => response.json())
    .then(data => (console.log('IP address: ', data?.IPv4), data?.IPv4))
    .catch(err => console.log('getIpAddress error.', err));
};

// determine if input string is a valid audio source file
export const srcOk = src => {
  const ok =
    typeof src === 'string' &&
    (src.toLowerCase().includes('.mp3') || src.toLowerCase().includes('blob:'));
  return ok;
};

// flatten an object
// guard against 1st layer duplication of keys
// will NOT flatten an array of objects
//  Example:
//    let obj1 = { id: 0, name: 'joe', sub: { id: 1, test: 'shoo', sub: { id: 2, test: 'jeremiah' } } }
//    console.log("flat:", flattenObj(obj1))
//    flat: {id: 0, name: "joe", subId: 1, subTest: "shoo", subSubId: 2, …}
export const flattenObj = (
  objIn,
  objOut = {},
  parentKey = null,
  grandparentKey = null
) => {
  const isNonArrayObject = item =>
    !Array.isArray(item) && typeof item === 'object';

  for (let key in objIn) {
    const val = objIn[key];
    if (isNonArrayObject(val)) {
      // recurse if value is an object (other than an array)
      flattenObj(val, objOut, key, parentKey);
    } else {
      // flattening is not necessary, but key name collisions may need mitigation
      // first pre-append parent key name
      let resKey = parentKey
        ? parentKey + key.charAt(0).toUpperCase() + key.slice(1)
        : key;
      if (resKey in objOut) {
        //key already exists, additionally preappend grandparent
        resKey = grandparentKey
          ? grandparentKey + resKey.charAt(0).toUpperCase() + resKey.slice(1)
          : resKey;
      }
      objOut[resKey] = Array.isArray(val) && val.length === 1 ? val[0] : val; //flatten arrays of length 1
    }
  }
  return objOut;
};

// place any non-array item into an array
export const makeArray = item =>
  item ? (Array.isArray(item) ? item : [item]) : [];

// add element to array but avoid duplicates
export const dupFreePush = (arr, val) =>
  arr.includes(val) ? arr : [...arr, val];

// get a random integer
export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

// convert string to title case
export const titleCase = str =>
  str ? str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()) : null;

// execute JSON.parse and handle errors
export const jsonParse = inp => {
  if (Boolean(inp)) {
    let out = null;
    try {
      out = JSON.parse(inp);
    } catch (error) {
      console.log('jsonParse failed:', inp);
      return null;
    }
    return out;
  }
  return null;
};

// apply backdrop until input promise is resolved
export const manageBackdrop = (promise, args, message) =>
  new Promise((resolve, reject) => {
    store.dispatch(uiActions.backdropSet(message));
    promise(args)
      .then(rslt => {
        store.dispatch(uiActions.backdropClr());
        resolve(rslt);
      })
      .catch(err => {
        store.dispatch(uiActions.backdropClr());
        reject(err);
      });
  });

// get height of element, including margins
export const outerHeight = el => {
  var height = el.offsetHeight;
  var style = getComputedStyle(el);
  height += parseInt(style.marginTop) + parseInt(style.marginBottom);
  return height;
};

// determine if running as a development client
export const devClient =
  window.location.origin.includes('localhost') ||
  window.location.origin.includes('dev.');

// return a numeric hash of all characters in a string
export const stringHash = s =>
  s ? [...String(s)].reduce((out, c, ix) => out + c.charCodeAt(0), 0) : null;

// return a character string consisting of random digits
// derive from unix time and a random-generated substring
const randomNumberString = len => {
  const l1 = len % 2 ? /*odd*/ (len - 1) / 2 : /*even*/ len / 2;
  const l2 = len - l1;
  return (
    Date.now().toString().slice(-l1) +
    Math.trunc(Math.random() * Number('9'.repeat(l2)))
      .toString()
      .padStart(l2, '0')
  );
};

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const clipboardCapture = text => navigator.clipboard.writeText(text);

// apply override values to the keys in the default object
export const updateObjectKeys = (defaultObj, updates) =>
  typeof updates === 'object' && typeof defaultObj === 'object'
    ? Object.entries(defaultObj).reduce((acc, [key, val]) => {
        return {
          ...acc,
          // apply update if it exists for this key
          [key]: updates[key] ? `${val} ${updates[key]}` : val,
        };
      }, {})
    : defaultObj;

//determine if item looks like a Promise
export const isLikePromise = item => typeof item?.then === 'function';

// determine if object is a DOM element
export const isElement = item =>
  item instanceof Element || item instanceof HTMLDocument;
