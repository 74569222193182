/**
 * SleepNotice
 *
 * Indicates that the session is asleep, and offere button to awake.
 *
 */

import React from 'react';
import { t } from 'lib/translation/trans';

import { Typography, Button, f } from '@material-ui/core';

import { Sleep } from 'mdi-material-ui';

const SleepNotice = () => (
  <div style={{ margin: '2rem', textAlign: 'center' }}>
    <Sleep style={{ margin: '2rem', fontSize: '3rem' }} />
    <Typography variant="h2"> {`${t('Sleeping')}...`} </Typography>
    <Button style={{ margin: '4rem' }} variant="contained" color="secondary">
      {' '}
      {t('Wake_Up')}{' '}
    </Button>
  </div>
);

export default SleepNotice;
