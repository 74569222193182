/**
 * Play an individual message specified by the URL
 *
 * Evaluate and enforce access privilege (see authorize() ).
 * Offer opportunity to remedy authorization (login or code auth).
 * Redirect in authorization failure.
 *
 * Defer cookie requirement for first time visitor.
 *
 */

import React, { useState, useEffect } from 'react';
import MessagePlayer from 'containers/MessagePlayer';
import { getMessage, isPublicType, isSelfAuthored } from 'lib/api/messageApi';
import { userIsSelf } from 'lib/api/userApi';
import { getCommunity, switchCommunity } from 'lib/api/communityApi';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import ButtonStd from 'components/utils/ButtonStd';
import Dialog2 from 'components/Modals/Dialog2';
import DialogStd from 'components/Modals/DialogStd';
import { useHistory } from 'react-router-dom';
import { defaultCommunity } from 'configs/config-hvn';
import { currentPartition } from 'lib/api/communityApi';
import CookieConsentReminderDialog from 'components/layouts/components/CookieConsentReminderDialog';
import { t } from 'lib/translation/trans';
import useInboxConsumer from 'containers/InboxView/hooks/useInboxConsumer';
import OutgoingMessageAccessCodeDialog from './components/OutgoingMessageAccessCodeDialog';

const PlayViewManager = props => {
  const [fetching, setFetching] = useState(true);
  const [exception, setException] = useState({ body: null, actionBtn: null }); //
  const [message, setMessage] = useState(null); // message to be played
  const [permission, setPermission] = useState(false); // assert when user is authorized to listen
  const [autoplay, setAutoplay] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [gettingCode, setGettingCode] = useState(false);
  const [codeValidated, setCodeValidated] = useState(false);
  const [cookieReminderOpen, setCookieReminderOpen] = useState(false);
  const [priorId, setPriorId] = useState(null);
  const user = useSelector(state => state.user);
  const messageMap = useSelector(state => state.dialog.messageMap);
  const regDialog = useSelector(state => state.ui.regDialog);

  const history = useHistory();
  const dispatch = useDispatch();
  const userId = user.auth.user?.id;
  const noteMessageConsumed = useInboxConsumer();

  // outgoing messages have non-member recipients
  // recipient field holds an auth code to be validated
  const expectedCode = message?.recipient;
  const shareMethod = props?.match?.params?.shareMethod;

  // initialize when component mounts
  useEffect(() => {
    const id = props?.match?.params?.id;

    if (id) {
      const msg = messageMap[id];
      if (msg) {
        // specified message is already available
        processMessage(msg);
      } else {
        // must fetch message from server
        getMessage(id)
          .then(msg => processMessage(msg))
          .catch(err =>
            setException({
              body: t('Sorry_cannot_find_that_message'),
            })
          );
      }
    } else {
      // no message id specified
      setException({ body: t('No_Message_Specified') });
    }
  }, []);

  // special hold-off rendering of CookieConsentBar until component dismounts
  // (or until holdoff is otherwise removed )
  useEffect(() => {
    dispatch(uiActions.holdoffCookieConsentBar(true));
    return () => dispatch(uiActions.holdoffCookieConsentBar(false));
  }, []);

  // detect logout
  useEffect(() => {
    // note the initial login status
    if (userId && !priorId) {
      setPriorId(userId);
    }

    if (priorId && !userId) {
      // user has logged out
      console.log('User has logged out');
      setPermission(false);
      history.push('/playlist');
    }
  }, [userId, priorId]);

  // detect successful login via RegistrationManager
  useEffect(() => {
    if (loggingIn && !regDialog.open) {
      // RegistrationManager dialog has closed
      user.isAuthenticated && handleLoginSuccess();
      setLoggingIn(false);
    }
  }, [regDialog.open]);

  // retry once code is validated
  useEffect(() => {
    codeValidated && authorize(message);
  }, [codeValidated]);

  // invoked when component loads
  const processMessage = msg => {
    setFetching(false);
    setMessage(msg);
    if (msg) {
      if (msg?.partition !== currentPartition()) {
        //set the active partition from the message partition
        getCommunity(msg?.partition)
          .then(community => {
            switchCommunity(community || defaultCommunity);
          })
          .catch(err => {
            switchCommunity(defaultCommunity);
          });
      }
      authorize(msg); //cannot yet rely on setMessage transition complete
    } else {
      // getMessage did not return a message
      // (possibly filtered subsequent to fetch )
      setException({ body: t('Sorry_cannot_find_that_message') });
    }
  };

  const authorize = msg => {
    const userIsRecipient = msg => userIsSelf(msg?.recipient);
    const userIsPartyTo = () =>
      userIsRecipient(msg) ||
      isSelfAuthored(msg) ||
      (msg?.type === 'outgoing' && codeValidated);

    /*
     * Render the message if it is public or self-authored;
     * Otherwise render AuthCode form if message is 'outgoing' type and user is not the author;
     * Otherwise render Login screen if user is unauthenticated;
     * Otherwise render the message if user is party to it;
     * Otherwise render the message if type is 'outgoing' and AuthCode is validated;
     * // TODO: check user's partition permission
     * Otherwise redirect the user to playlist.
     */
    console.log(
      `authorize isRecipient=${userIsRecipient(
        msg
      )}, partyTo=${userIsPartyTo()}, isPublic=${isPublicType(
        msg?.type
      )}, isSelf=${isSelfAuthored(msg)}, type=${
        msg?.type
      }, codeValidated=${codeValidated}, authenticated=${
        user.isAuthenticated
      }     `
    );

    if (isPublicType(msg?.type) || isSelfAuthored(msg)) {
      console.log('public or self');
      setPermission(true);
    } else if (msg?.type === 'outgoing') {
      if (codeValidated) {
        setPermission(true);
      } else {
        console.log('need code');
        handleCodeRequirement();
      }
    } else if (!user.isAuthenticated) {
      console.log('need login');
      handleLogin();
    } else if (userIsPartyTo()) {
      console.log('party to');
      setPermission(true);
    } else {
      setException({ body: `${t('Access_denied')}.` });
    }
  };

  // extinguish the exception dialog
  const cancelException = () => setException({ body: null, actionBtn: null });

  const handleLogin = () => {
    dispatch(uiActions.holdoffCookieConsentBar(true));
    setLoggingIn(true);
    dispatch(uiActions.setRegDialog({ open: true, mode: 'login' }));
  };

  // after logging in, re-try handleConfirm
  const handleLoginSuccess = () => {
    setLoggingIn(false);
    dispatch(uiActions.setRegDialog({ open: false, mode: null }));
    cancelException();
    authorize(message); // retry authorization now that caller is logged in
  };

  const handleCodeRequirement = () => {
    console.log('handleCodeRequirement...');
    dispatch(uiActions.holdoffCookieConsentBar(true));
    setGettingCode(true);
  };
  const handleCodeSubmit = code => {
    console.log('handleCodeSubmit');
    setGettingCode(false);
    cancelException();
    console.log(`handleCodeSubmit: code=${code}, expected=${expectedCode}`);
    if (code === expectedCode) {
      setCodeValidated(true);
    } else {
      setException({ body: `${t('Access_denied')}.` });
    }
  };

  const handleExceptionClose = () => {
    //@@except for successful login
    history.push('/playlist');
  };

  const handleRecorderActionEvent = btn =>
    console.log(`handleRecorderActionEvent: ${btn}`);

  // on first player action, obtain cookie consent
  const handlePlayerActionEvent = btn => {
    console.log(`handlePlayerActionEvent: ${btn}`);
    if (btn === 'respondClick') {
      setCookieReminderOpen(true);
    }

    if (btn === 'playClick') {
      // mark the message as consumed if direct message played by recipient
      noteMessageConsumed(message, true);
    }
  };

  return (
    <>
      {permission ? (
        <MessagePlayer
          message={permission && message}
          autoplay={autoplay}
          onRecActionEvt={handleRecorderActionEvent}
          onPlayActionEvt={handlePlayerActionEvent}
          shareMethod={shareMethod}
        />
      ) : null}
      {/*loading overlay */}
      <DialogStd
        showProgress={true}
        open={fetching}
        redux={false}
        showButton={false}
        content="Loading"
      />

      {/* render delayed cookie prompe */}
      <CookieConsentReminderDialog
        open={cookieReminderOpen}
        onClose={() => setCookieReminderOpen(false)}
      />

      {/* code collection dialog */}
      <OutgoingMessageAccessCodeDialog
        open={gettingCode}
        onSubmit={handleCodeSubmit}
      />

      {/* exception message dialog */}
      <Dialog2
        open={Boolean(exception.body)}
        onClose={handleExceptionClose}
        heading={`${t('Problem')} ...`}
        content={exception.body}
        actions={
          <>
            <ButtonStd onClick={handleExceptionClose} label={t('Dismiss')} />
            {exception.actionBtn}
          </>
        }
      />
    </>
  );
};

export default PlayViewManager;
