import { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { counterActions } from 'experiments/ReduxDemo1/store/counter'
import Header from './Header'
import Auth from './Auth'

const Counter = () => {
    const dispatch = useDispatch()
    const counter = useSelector(state => state.counter.counter)    //sets up a subscription
    const showCounter = useSelector(state => state.counter.showCounter)

    const handleIncrement = () => dispatch(counterActions.increment())
    const handleDecrement = () => dispatch(counterActions.decrement())
    const handleIncrease = () => dispatch(counterActions.increase(10))
    const toggleCounterHandler = () => dispatch(counterActions.toggleCounter())

    return (
        <Fragment>
            <Header />
            <hr />
            <Auth />
            <hr />
            <main >
                <h1>Redux Counter</h1>
                {showCounter && <div> == COUNTER Class VALUE= {counter} --</div>}
                <button onClick={handleIncrement} style={{ margin: "1rem" }}>increment</button>
                <button onClick={handleDecrement} style={{ margin: "1rem" }}>decrement</button>
                <button onClick={handleIncrease} style={{ margin: "1rem" }}>increase</button>
                <button onClick={toggleCounterHandler}>Toggle Counter</button>
            </main>
        </Fragment>
    )
}

export default Counter
