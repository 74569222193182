/**
 *
 */
import React, { useEff1ect } from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton, Badge } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  expanderIcon: {
    marginRight: '10px',
  },
  badge: {
    //fontSize: ".5rem",
  },
}));

const ResponseIndicator = ({
  isOpen,
  count,
  onClick,
  includeLabel,
  includeBadge,
}) => {
  const classes = useStyles();

  const handleClick = ev => {
    //prevent clicks from propagating to parent elements
    ev.cancelBubble = true;
    ev.stopPropagation && ev.stopPropagation();
    onClick && onClick();
  };

  const renderExpander = () =>
    isOpen ? (
      <>
        {includeLabel ? <div>Close</div> : null}{' '}
        <ExpandLess classes={{ root: classes.expanderIcon }} />
      </>
    ) : (
      <>
        {includeLabel ? <div>Responses</div> : null}{' '}
        <ExpandMore classes={{ root: classes.expanderIcon }} />
      </>
    );

  return count ? (
    <Button
      onClick={handleClick}
      style={{ minWidth: '0px', padding: '0px 0px' }}
    >
      {includeBadge ? (
        <Badge
          classes={{ badge: classes.badge }}
          badgeContent={count}
          color="primary"
        >
          {' '}
          {renderExpander()}{' '}
        </Badge>
      ) : (
        renderExpander()
      )}
    </Button>
  ) : null;
};

ResponseIndicator.propTypes = {
  count: PropTypes.number,
  isOPen: PropTypes.bool,
  onClick: PropTypes.func,
  incluceLabel: PropTypes.bool,
  includeBadge: PropTypes.bool,
};

ResponseIndicator.defaultProps = {
  includeLabel: true,
  includeBadge: false,
};

export default ResponseIndicator;
