import { createSlice } from '@reduxjs/toolkit'

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [{ id: 1, price: 2, quantity:3, totalPrice:4, name: 'five'}],
        totalQuantity: 0,
    },
    reducers: {
        addItemToCart(state, action) {
            const newItem = action.payload
            const existingItem = state.items.find((item) => (item.id === newItem.id))
            if (!existingItem) {
                state.items.push({ id: newItem.id, price: newItem.price, quantity: 1, totalPrice: newItem.price, name: newItem.title })
            } else {
                existingItem.quantity++;
                existingItem.totalPrice += newItem.price
            }

        },
        removeItemFromCart(state, action) { 
            const id = action.payload
            const existingItem = state.items.find((item) => (item.id === id))
            if (existingItem.quantity === 1 ){
                state.items = state.items.filter((item) => (item.id !== id))
            } else {
                existingItem.quantity--
                existingItem.totalPrice -= existingItem.price
            }
        },
    },
})

export const cartActions = cartSlice.actions
export default cartSlice.reducer
