/**
 * Render a dialog with description of infoBtnList()
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import { Grid, Typography } from '@material-ui/core';
import { actionButtonArr } from './actionButtons';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  typography: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },
}));

export default function InfoActionDialog({
  open,
  onClose,
  infoBtnList,
  headerOverride,
}) {
  const classes = useStyles();

  const handleClose = () => {
    onClose && onClose();
  };

  const heading = (
    <Typography variant="h6" color="secondary">
      {t('Action_Options')}
    </Typography>
  );
  return (
    <ExceptionDialog
      exceptionDisable={true}
      open={open}
      onClose={handleClose}
      heading={heading}
      body={
        <>
          <div>
            <Typography
              classes={{ root: classes.typography }}
              variant="h6"
              color="secondary"
            >
              {headerOverride
                ? headerOverride
                : t('The_following_actions_are_available_for_this_message')}
            </Typography>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Typography
              classes={{ root: classes.typography }}
              variant="body2"
              color="secondary"
            >
              {`(${t('not_all_options_are_available_for_every_message')})`}
            </Typography>
          </div>

          <Typography classes={{ root: classes.typography }} variant={'body2'}>
            <Grid container direction="column" spacing={2}>
              {infoBtnList()
                .filter(b => b.label.length > 0)
                .map(btn => (
                  <Grid item container>
                    <Grid item xs={2} sm={1}>
                      {btn.icon}
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      {btn.label}
                    </Grid>
                    <Grid item xs={7} sm={9}>
                      {btn.description}
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Typography>
        </>
      }
    />
  );
}

InfoActionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  infoBtnList: PropTypes.func,
  headerOverride: PropTypes.string,
};

InfoActionDialog.defaultProps = {
  headerOverride: null,
  infoBtnList: actionButtonArr, // static description of all buttons
};
