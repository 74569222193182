/**
 * Render an indicator if the message is a new inbound DM
 * Also show indicator for a collapsed thread with a hidden new response
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { isDirectedType } from 'lib/api/messageApi';
import { makeStyles } from '@material-ui/core/styles';

// color assignment
const directedHighlight = '156, 39, 176'; // theme.palette.primary.main
const blue = '52, 172, 224';
const color = directedHighlight;

const useStyles = makeStyles(theme => ({
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: `0 0 0 0 rgba(${color}, 0.7)`,
    },
    '70%': { transform: 'scale(1)', boxShadow: `0 0 0 10px rgba(${color}, 0)` },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: `0 0 0 0 rgba(${color}, 0)`,
    },
  },

  'indicator': {
    borderRadius: '50%',
    margin: '10px',
    height: '10px',
    width: '10px',
    transform: 'scale(1)',
    background: `rgba(${color}, 1)`,
    boxShadow: `0 0 0 0 rgba(${color}, 1)`,
    animationDuration: '1s',
    animationFillMode: 'ease',
    animationIterationCount: 'infinite',
    animationName: '$pulse',
  },
}));

const NewInboundMessageIndicator = ({ message, isOpen }) => {
  const { auth } = useSelector(state => state.user);
  const classes = useStyles();

  // determine if the message is a new inbound DM
  const isNew = m =>
    m &&
    isDirectedType(m.type) && //must be directed message
    !Boolean(m?.consumed) && // must not already be consumed
    m.recipient === auth.user?.id; // must be directed to the current user

  // determine if the message is the head of a closed (unexpanded) thread
  // with a new inbound reply
  const hasHiddenNewChild = () =>
    message &&
    isDirectedType(message.type) &&
    !isOpen &&
    Array.isArray(message.responses) &&
    Boolean(message.responses.find(r => isNew(r)));

  const showIndicator = isNew(message) || hasHiddenNewChild();
  return showIndicator ? <div className={classes.indicator}></div> : null;
};
export default NewInboundMessageIndicator;
