/**
 * Render a suggestion of type config.type
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, useMediaQuery } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ButtonStd from 'components/utils/ButtonStd';
import { t } from 'lib/translation/trans';
import ApplianceControls, {
  wildcard,
} from 'components/utils/ApplianceControls';

import { useTheme } from '@material-ui/core/styles';

const Suggestion = ({ config, onOptIn, onDisable, onLater }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleInfoOpen = () => setShowInfo(true);
  const handleInfoClose = () => setShowInfo(false);
  const handleInstructionsClose = () => {
    setShowInstructions(false);
    onLater();
  };

  const handleOptInClick = () => {
    if (config.instruct) {
      setShowInstructions(true);
    } else {
      onOptIn();
    }
  };

  const infoButton = () =>
    config.info ? (
      <ApplianceControls
        groups={false}
        legends={true}
        buttons={[
          {
            label: 'Info',
            icon: <InfoIcon />,
            visible: [wildcard],
            enabled: [wildcard],
            onClick: handleInfoOpen,
          },
        ]}
      />
    ) : null;

  const handleDisable = () => {
    onDisable({ action: 'disable', type: config.type });
  };

  const renderControls = () => {
    const outerSpacing = xs ? 0 : 2;
    const outerJustify = xs ? 'space-between' : 'flex-left';
    const scale = xs ? 'scale(.8)' : 'scale(1)';
    const infoMarginTop = xs ? 20 : 0;
    const infoXs = xs ? 12 : 0;

    return (
      <Grid
        container
        justify={outerJustify}
        alignItems="center"
        spacing={outerSpacing}
      >
        <Grid item>
          <ButtonStd
            onClick={handleOptInClick}
            label={config.approveBtnText || t('Enable')}
            color="secondary"
            style={{ transform: scale }}
          />
        </Grid>
        <Grid item>
          <ButtonStd
            onClick={onLater}
            label={t('Maybe_Later')}
            color="primary"
            style={{ transform: scale }}
          />
        </Grid>
        <Grid item>
          <ButtonStd
            onClick={handleDisable}
            label={t('Stop_Asking')}
            style={{ transform: scale, backgroundColor: 'grey' }}
          />
        </Grid>
        <Grid item xs={infoXs} style={{ marginTop: infoMarginTop }}>
          {infoButton()}
        </Grid>
      </Grid>
    );
  };

  const renderInfo = () => (
    <>
      <div>{config.info}</div>
      <ButtonStd
        onClick={handleInfoClose}
        label="OK"
        color="secondary"
        style={{ marginTop: 30 }}
      />
    </>
  );

  const renderInstructions = () => (
    <>
      <div>{config.instruct}</div>
      <ButtonStd
        onClick={handleInstructionsClose}
        label="Finished"
        color="secondary"
      />
    </>
  );

  return (
    <>
      <Typography style={{ marginBottom: 20 }}>{config.offer}</Typography>
      {showInfo
        ? renderInfo()
        : showInstructions
        ? renderInstructions()
        : renderControls()}
    </>
  );
};

Suggestion.propTypes = {
  config: PropTypes.shape(),
  onOptIn: PropTypes.func,
  onDisable: PropTypes.func,
  onLater: PropTypes.func,
};
Suggestion.defaultProps = {
  config: null,
  onOptIn: () => {},
  onDisable: () => {},
  onLater: () => {},
};

export default Suggestion;
