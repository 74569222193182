/**
 * TEMPORARY: add button to subscribe to Push Notifications
 *
 * Render help information.
 * If dialogContext is supplied, render its content in a full screen dialog.
 *
 * Otherwise, render an accordion of topics, each of which opens to subtopic list
 * Each list item is clickable to either link to a resource (internal or external)
 * or to render HTML content in a full screen dialog.
 *
 * The content is dynamically read from 'sourceDocument' on Drupal server
 *
 * On selection of a subtopic, either redirect to a specified link
 * or render a React element in a full screen Dialog
 *
 * This component is equiped with dynamic overflow that prevents
 * full-screen scrollbar when content overflows.
 */

/* <!-- sourceDocument template specification:
Composition Notes:
- each "panel" can contain a heading, subheading, and one "details" section
- each "details" section contains one or more "item"
- each "item" contains a combination of <a>, <h4>, and "item-content" section elements
- each 'item-content' section contains html to be rendered

Template:
<section class="info-panel">
    <h2>{panel heading}</h2>
    <h3>{panel sub-heading}</h3>
    <section class="info-details">
        <section class="info-item">
            <h4>{optional item heading}</h4>
            <a href="{link}">{optional anchor tag}</a>
            <section class="info-item-content">
                {optional html content}
            </section>
        </section>
    </section>
</section>
--> */

/**
 * //TODO:
 *
 * Settings Item - only show if logged in
 * Support - handle regardless of authentication status
 * Embedded links:
 *	community welcome page
 *	more info
 *	oksayit community general
 * Apply styling

 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@material-ui/core';
import AccordionStd from 'components/utils/AccordionStd';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import InfoActionDialog from 'containers/MessagePlayer/components/PlayerActions/components/InfoActionDialog';
import ButtonStd from 'components/utils/ButtonStd';
import { uiActions } from 'store/ui-slice';
import Copyright from 'components/utils/Copyright';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import { WEB_HOST } from 'configs/config-hvn';
import { currentPartition } from 'lib/api/communityApi';
import HtmlContent from 'lib/classes/HtmlContent';
import { getVersionInfo } from 'lib/utils/versionUtils';
import { t } from 'lib/translation/trans';
import PWAControls from 'components/PWAControls';
import PWAStatus from 'components/PWAStatus';

const useStyles = makeStyles(theme => ({
  fixedSection: {
    marginBottom: '1rem',
  },
  overflow: {
    //height: calculate dynamically,
    overflow: 'auto', //automatically generate scrollbar
  },
  pageContainer: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  detailsContainer: {
    'marginTop': '2rem',
    'padding': '10px 20px',
    'textAlign': 'justify',
    '& h5': {
      marginBottom: 5,
      color: 'white',
    },
    '& ul': {
      paddingLeft: 15,
      marginTop: 5,
    },
    '& li': {
      textAlign: 'left',
      marginBottom: 10,
    },
    '& em': {
      color: 'white',
    },
    '& strong': {
      color: '#4caf50',
    },
    '& a': {
      color: 'DeepSkyBlue',
    },
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...{ ...props, target: '_blank' }} />;
}

const Info = ({ sourceDocument, dialogContext, onDialogClose }) => {
  const { defaultProps, setSrc } = useVoicePlayerHelper({ playerKey: 'Info' });

  const [versionInfo, setVersionInfo] = useState(null);
  const [panels, setPanels] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [nonScrollingSectionHeight, setNonScrollingSectionHeight] =
    useState(null);
  const fixedSectionRef = useRef();
  const classes = useStyles();
  const history = useHistory();
  const totalAvailableHeight = useSelector(
    state => state.ui.layout.mainContentHeight
  );
  const { deviceInfo } = useSelector(state => state.ui);
  const parser = new DOMParser();
  const [showActionInfo, setShowActionInfo] = useState(false);
  const dispatch = useDispatch();

  // measure the fixed component height
  useEffect(() => {
    if (fixedSectionRef.current) {
      // note: margins of children may extend beyond fixedSectionRef
      const computedStyle = getComputedStyle(fixedSectionRef.current);
      const marginTop = parseInt(computedStyle?.marginTop);
      const marginBottom = parseInt(computedStyle?.marginBottom);
      setNonScrollingSectionHeight(
        fixedSectionRef.current.clientHeight + marginTop + marginBottom
      );
    }

    getVersionInfo().then(info => setVersionInfo(info));
  }, []);

  const handleClick = item => {
    setSelectedItem(item);
  };

  const handleFullScreenClose = () => {
    setSelectedItem(null);
    onDialogClose && onDialogClose();
  };

  // content string can contain delimited tokens to be replaced dynamically at render time
  const substitutionTokens = [
    {
      symbol: '%welcomePage%',
      replacement: `<a href="${
        window.location.origin
      }/welcome/${currentPartition()}" >${
        window.location.origin
      }/welcome/${currentPartition()}</a>`,
    },
    {
      symbol: '%supportPage%',
      replacement: `<a href="${window.location.origin}/support" >${window.location.origin}/support</a>`,
    },
    {
      symbol: '%showActionInfo%',
      replacement: `<a href="${
        window.location.origin
      }/welcome/${currentPartition()}" >... More Info</a>`,
    },
  ];
  const htmlContent = new HtmlContent(sourceDocument, substitutionTokens);

  useEffect(() => {
    dispatch(uiActions.backdropSet(' '));
    htmlContent
      .fetch()
      .then(() => {
        // parse the document, decomposing according to the standard template (see above)
        const doc = parser.parseFromString(
          htmlContent.withSubstitutions(),
          'text/html'
        );
        const panelsCollection = doc.getElementsByClassName('info-panel');
        const panels = Array.from(panelsCollection).map(pnl => ({
          //h2: pnl.getElementsByTagName('h2')[0]?.innerHTML,   // this would also get nested children
          h2: pnl.querySelectorAll('.info-panel > h2')[0]?.innerHTML, //only direct children of info-panel
          h3: pnl.querySelectorAll('.info-panel > h3')[0]?.innerHTML,
          items: Array.from(
            pnl
              .getElementsByClassName('info-details')[0]
              .getElementsByClassName('info-item')
          ).map(item => ({
            h4: item.querySelectorAll('.info-item > h4')[0]?.innerHTML,
            a: item.querySelectorAll('.info-item > a')[0]?.innerHTML, //only finds first children
            href: item
              .querySelectorAll('.info-item > a')[0]
              ?.getAttribute('href'),
            content: HtmlContent.html(
              item.getElementsByClassName('info-item-content')[0]?.innerHTML
            ),
          })),
        }));
        setPanels(panels);

        // process dialogContext if supplied
        if (dialogContext) {
          panels.some(p =>
            p.items.some(i => {
              i.h4 === dialogContext && setSelectedItem(i);
              return i.h4 === dialogContext;
            })
          );
        }
        dispatch(uiActions.backdropClr());
      })
      .catch(() => dispatch(uiActions.backdropClr()));
  }, []);

  // provide additional context-sensitive content
  // consider developing a method of embeding a symbol within selectedItem.content
  // presently, only "Public Playlist" needs a button added
  const contextFooter = () =>
    selectedItem?.h4 === 'Public Playlist' ? (
      <div style={{ textAlign: 'center' }}>
        <ButtonStd
          color="secondary"
          onClick={() => setShowActionInfo(true)}
          label="More Info"
        />
      </div>
    ) : null;

  //@@ kludged for now, content not included in Drupal page
  const customContent = item => {
    // for selected items, append Player component
    let src = null;

    const playerProps = {
      ...defaultProps,
      autoplay: false,
      progressStyle: 'standard',
      onNext: null,
      onPrevious: null,
      uiItems: ['hControls'],
    };

    switch (item?.h4) {
      case 'Overview':
        src = `${WEB_HOST}/sites/default/files/prompts/overview.mp3`;
        break;
      case 'Safety and Privacy':
        src = `${WEB_HOST}/sites/default/files/prompts/safety-and-privacy.mp3`;
        break;
      default:
        break;
    }
    //setSrc(src)
    return (
      src && (
        <Grid container justify="center">
          <div style={{ width: 150 }}>
            <VoicePlayer {...playerProps} src={src} />
          </div>
        </Grid>
      )
    );
  };

  const versionReport = () => {
    var report = 'Version...';
    if (versionInfo) {
      const { codeBaseVersion, cachedVersion } = versionInfo;
      //console.log(`codebaseVersion=${codeBaseVersion}, cachedVersion=${cachedVersion}`)

      if (cachedVersion) {
        report = `${cachedVersion}`;
        if (codeBaseVersion !== cachedVersion) {
          report += `  (${t('latest')} = ${codeBaseVersion})`;
        }
      }
    }
    return report;
  };

  // render diagnostic information related to PWA
  const renderPwaDiagnostics = () => (
    <div style={{ marginTop: 20 }}>
      <Typography variant="h4">Temporary Diagnostics:</Typography>
      <Typography>{deviceInfo.descriptor}</Typography>
      {/* <PWAControls /> */}
      <PWAStatus />
    </div>
  );

  return (
    <>
      {/* do not present accordion if dialogContext has been specified in props */}
      {dialogContext ? null : (
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <Typography
              ref={fixedSectionRef}
              variant="h6"
              classes={{ root: classes.fixedSection }}
            >
              OkSayit™ Information
            </Typography>
            {selectedItem ? null : (
              <div
                className={classes.overflow}
                style={{
                  height: totalAvailableHeight - nonScrollingSectionHeight,
                }}
              >
                {/*if scrollbar is necessary, isolate it to this overflow div rather than to the full document */}
                {panels && (
                  <AccordionStd
                    sections={panels.map((panel, pix) => ({
                      key: `panel-${pix}`,
                      heading: (
                        <Typography
                          className={classes.accordionHeading}
                          color="secondary"
                        >
                          {panel?.h2}
                        </Typography>
                      ),
                      subHeading: (
                        <Typography
                          className={classes.accordionSecondaryHeading}
                        >
                          {panel?.h3}
                        </Typography>
                      ),
                      details: (
                        <div className={classes.pageContainer}>
                          <List component="nav">
                            {panel.items.map((item, iix) => {
                              // NOTE: if link is present, html content is ignored

                              // for an external link:
                              if (item.href && item.href.startsWith('https')) {
                                return (
                                  <ListItemLink href={item.href}>
                                    <ListItemText primary={item?.a} />
                                  </ListItemLink>
                                );
                              }
                              // for an internal link:
                              else if (item.href && item.href.startsWith('/')) {
                                return (
                                  <ListItem
                                    button
                                    onClick={() => history.push(item.href)}
                                  >
                                    <ListItemText primary={item?.a} />
                                  </ListItem>
                                );
                              }
                              // for HTML content
                              else if (item.content) {
                                return (
                                  <ListItem
                                    button
                                    onClick={() => handleClick(item)}
                                  >
                                    <ListItemText primary={item.h4} />
                                  </ListItem>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </List>
                        </div>
                      ),
                    }))}
                  />
                )}

                {/* renderPwaDiagnostics() */}
                <div style={{ position: 'fixed', bottom: 10, left: 75 }}>
                  {versionReport()}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      )}
      {/* Display the selected item in full screen dialog  */}
      <FullScreenDialog
        open={Boolean(selectedItem)}
        title={'Information'}
        onClose={handleFullScreenClose}
        content={
          <div className={classes.detailsContainer}>
            <Typography variant="h6" color="textPrimary">
              {selectedItem?.h4}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {selectedItem?.content}
              {contextFooter()}
              {
                customContent(
                  selectedItem
                ) /* kludged for now, content not included in Drupal page */
              }
            </Typography>
            <Copyright />
          </div>
        }
      />

      {/* Present a specialized dialog in response to specialized button click in the contextFooter */}
      <InfoActionDialog
        open={showActionInfo}
        onClose={() => setShowActionInfo(false)}
        headerOverride="While listening to a message..."
      />
    </>
  );
};

Info.propTypes = {
  //title of Drupal Page node that provides content
  sourceDocument: PropTypes.string,

  //for use in rendering a pre-selected item
  dialogContext: PropTypes.string,
  onDialogClose: PropTypes.func,
};

Info.defaultProps = {
  sourceDocument: 'Info -- Information',
  dialogContext: null,
};

export default Info;
