/**
 * Render a list of communities to which user is currently registered
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container } from '@material-ui/core';
import AttributesSelector from './AttributesSelector';
import ProfileMessageManager from './ProfileMessageManager';
import BorderedBlock from 'components/utils/BorderedBlock';
import { switchCommunity } from 'lib/api/communityApi';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  profileUpdateForm: {
    marginTop: '2rem',
  },

  // for BorderedBlock
  wrapperOuter: {
    marginBottom: 15,
  },
}));

export default function MembershipProfileForm(props) {
  const { community, onExit, bypassProfileRecording } = props;
  const classes = useStyles();

  // switch to the recently-joined partition
  const handleAttributesSelectorComplete = joinedPartition => {
    joinedPartition && switchCommunity(community);
    onExit && onExit(joinedPartition);
  };

  return (
    <Container className={classes.profileUpdateForm}>
      <Grid container spacing={1} style={{ marginBottom: 15 }}>
        <Grid item>
          <Typography color="secondary" variant="h6">{`${t(
            'Profile_For'
          )}:`}</Typography>
        </Grid>
        <Grid item>
          <Typography color="secondary" variant="h6">
            {' '}
            {community?.title}{' '}
          </Typography>
        </Grid>
      </Grid>

      {!bypassProfileRecording && (
        <BorderedBlock
          labelTop={t('Community_Profile_Recording')}
          classes={classes}
        >
          <ProfileMessageManager />
        </BorderedBlock>
      )}
      <AttributesSelector
        community={community}
        onComplete={handleAttributesSelectorComplete}
        processNewMember={props.processNewMember}
      />
    </Container>
  );
}

MembershipProfileForm.propTypes = {
  community: PropTypes.object,
  onExit: PropTypes.func,
  bypassProfileRecording: PropTypes.bool,
  processNewMember: PropTypes.func,
};
MembershipProfileForm.defaultProps = {};
