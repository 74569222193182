/**
 * CallTimeIndicator
 * Display call duration, updated every second
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/translation/trans';

const CallTimeIndicator = ({ enable, startTime }) => {
  const [callTimeMs, setCallTimeMs] = useState(0);

  useEffect(() => {
    // update call timer
    const intervalId = setInterval(() => {
      enable && setCallTimeMs(Date.now() - startTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [enable, startTime]);

  const tFormatted = new Date(callTimeMs).toISOString().substr(11, 8);
  const durationMsg = `${t('Call_Time')}: ${tFormatted}`;
  return <span>{durationMsg}</span>;
};

CallTimeIndicator.propTypes = {
  enable: PropTypes.bool,
  startTime: PropTypes.number,
};

export default CallTimeIndicator;
