/**
 * Render a momentary alert above the footer
 * Managed by redux state.ui.alert
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';

const useStyles = makeStyles(theme => ({}));

const AlertStd = ({ disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useSelector(state => state.ui.alert);
  const open = alert.open && !disabled;

  // extinguish any alert requests while component is disabled
  useEffect(() => {
    if (disabled && alert.open) {
      dispatch(uiActions.alertClr());
    }
  }, [disabled, alert.open]);

  const handleClick = () => {
    dispatch(uiActions.alertSet({ message: 'test', severity: 'error' }));
  };

  const handleClose = (event, reason) => {
    // don't close due to a click away from the alert
    if (reason === 'clickaway') {
      return;
    }
    dispatch(uiActions.alertClr());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      style={{ bottom: 70 }}
    >
      {open && (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={alert.severity}
        >
          {alert.message}
        </MuiAlert>
      )}
    </Snackbar>
  );
};

AlertStd.propTypes = {
  disabled: PropTypes.bool,
};
AlertStd.defaultProps = {
  disabled: false,
};
export default AlertStd;
