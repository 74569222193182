/**
 * useSettingsCategory custom hook
 *
 * Make the members list available, reading them from host if necessary
 *
 */

import React from 'react';
import {
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Grid,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  groupTitle: {
    color: theme.palette.secondary.main,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  paper: {
    marginBottom: '20px',
  },
  divider: {
    // intensify dividers for xs
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'rgba(255, 255, 255, .6)',
    },
  },
}));

const useSettingsCategory = ({ open, toggleOpen }) => {
  const classes = useStyles();

  // render a title or item row within a container
  const itemRow = (title, content, titleClass = classes.groupTitle) => (
    <>
      <Grid container alignItems="center">
        {title && (
          <Grid classes={{ root: titleClass }} item xs={12} sm={3}>
            {title}
          </Grid>
        )}
        <Grid item sm={title ? 7 : 12}>
          {content}
        </Grid>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
    </>
  );

  // render a related group of settings with optional nested list
  const categoryContainer = (title, titleContent, nestedItems = []) =>
    nestedItems.length ? (
      /* with collapse */
      <Paper classes={{ root: classes.paper }}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button onClick={() => toggleOpen(title)}>
            {/* hide titleContent when category is expanded */}
            {itemRow(
              <ListItemText
                classes={{ root: classes.groupTitle }}
                primary={title}
              />,
              open === title ? null : titleContent,
              ''
            )}
            {open === title ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open === title} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {nestedItems
                .filter(item => Boolean(item))
                .map((item, ix, arr) => (
                  <div key={item?.title}>
                    <ListItem className={classes.nested}>
                      {itemRow(item?.title, item?.content)}
                    </ListItem>
                    {/*no divider on last row */}
                    {ix < arr.length - 1 ? (
                      <Divider classes={{ root: classes.divider }} />
                    ) : null}
                  </div>
                ))}
            </List>
          </Collapse>
        </List>
      </Paper>
    ) : (
      /* without collapse */
      <Paper classes={{ root: classes.paper }}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem onClick={() => toggleOpen(title)}>
            {itemRow(
              <ListItemText
                classes={{ root: classes.groupTitle }}
                primary={title}
              />,
              titleContent,
              ''
            )}
          </ListItem>
        </List>
      </Paper>
    );
  return categoryContainer;
};
export default useSettingsCategory;
