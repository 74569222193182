/**
 * FlashBall
 * Render a flashing ball
 *
 * Status: hidden, normal, warning, disabled
 *
 * inspired by https://www.npmjs.com/package/@linto-ai/webvoicesdk
 *
 *
 */

import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const defaultSize = 12;

const useStyles = makeStyles(theme => ({
  'ledBox': {
    display: 'block',
    textAlign: 'center',
  },
  '@keyframes blinkRed': {
    'from': {
      backgroundColor: 'rgb(255,0,0)',
    },
    '50%': {
      backgroundColor: 'rgb(170, 0, 0)',
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset rgb(68, 19, 19) 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0',
    },
    'to': {
      backgroundColor: 'rgb(255,0,0)',
    },
  },
  '@keyframes blinkGreen': {
    'from': {
      backgroundColor: 'rgb(0, 255,0)',
    },
    '50%': {
      backgroundColor: 'rgb(0, 170, 0)',
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset rgb(19, 68, 19) 0 -1px 9px, rgba(0, 255, 0, 0.5) 0 2px 0',
    },
    'to': {
      backgroundColor: 'rgb(0,255,0)',
    },
  },
  'ledNone': {
    margin: '0 auto',
    width: defaultSize,
    height: defaultSize,
    opacity: 0,
  },
  'ledRed': {
    margin: '0 auto',
    width: defaultSize,
    height: defaultSize,
    backgroundColor: 'rgb(255,0,0)',
    borderRadius: '50%',
    //boxShadow: "rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px",
    animation: '$blinkRed 0.5s infinite',
  },

  'ledGreen': {
    margin: '0 auto',
    width: defaultSize,
    height: defaultSize,
    backgroundColor: 'rgb(171, 255, 0)',
    borderRadius: '50%',
    //boxShadow: "rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px",
    animation: '$blinkGreen 0.5s infinite',
  },
}));

const FlashBall = ({ status, size, disIcon }) => {
  const classes = useStyles();

  const visible = status !== 'hidden';
  const green = status === 'normal';
  const red = status === 'warning';
  const disabled = visible && !(green || red);

  // turn on the LED when mic becomes enabled, then blink it with talking
  const ledClass = () =>
    visible ? (red ? classes.ledRed : classes.ledGreen) : classes.ledNone;

  return disabled ? (
    <div className={classes.ledBox}>
      <div style={{ maxHeight: size }}>
        {cloneElement(disIcon, {
          style: { opacity: 0.5, height: size, width: size, marginBottom: 3 },
        })}
      </div>
    </div>
  ) : (
    <div className={classes.ledBox}>
      <div className={ledClass()} style={{ height: size, width: size }}></div>
    </div>
  );
};
FlashBall.propTypes = {
  status: PropTypes.oneOf(['hidden', 'normal', 'warning', 'disabled']),
  color: PropTypes.number,
  disIcon: PropTypes.node, //icon override for disabled status
};
FlashBall.defaultProps = {
  status: 'normal',
  size: defaultSize,
  disIcon: <CloseIcon style={{ opacity: 0.5 }} />,
};
export default FlashBall;
