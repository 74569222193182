import React, { useState } from 'react';
import ButtonStd from 'components/utils/ButtonStd';
import Dialog2 from 'components/Modals/Dialog2';
import { t } from 'lib/translation/trans';
import TextFieldStd from 'components/utils/TextFieldStd';

const OutgoingMessageAccessCodeDialog = ({ open, onSubmit }) => {
  const [value, setValue] = useState(null);

  const handleChange = ev => setValue(ev.target.value);
  const handleEnter = () => {
    onSubmit && onSubmit(value);
  };
  return (
    <Dialog2
      open={open}
      onClose={onSubmit}
      heading={`Enter Your Access Code`}
      content={
        <>
          <TextFieldStd
            label="Access Code"
            value={value}
            placeholder={'Code that came with this link'}
            onChange={handleChange}
            onEnter={handleEnter}
          />
          <ButtonStd
            style={{ marginTop: '1rem' }}
            onClick={handleEnter}
            label={t('Submit')}
          />
        </>
      }
    />
  );
};

export default OutgoingMessageAccessCodeDialog;
