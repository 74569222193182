/**
 * ProgressIndicator
 * Display simplified call progress.
 * Layout options: radio (vertical & horizontal), progress bar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  LinearProgress,
  Typography,
  Box,
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green, red, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  // styles for radio buttons
  progressDisplay: {
    marginBottom: 20,
  },
  yellowRadio: {
    'color': yellow[400],
    '&$checked': {
      color: yellow[600],
    },
  },
  greenRadio: {
    'color': green[400],
    '&$checked': {
      color: green[600],
    },
  },
  redRadio: {
    'color': red[400],
    '&$checked': {
      color: red[600],
    },
  },

  // styles for LinearProgress
  bar: {
    width: '100%',
    height: '20px',
    backgroundColor: 'red',
  },

  // linear progress bar
  linRoot: {
    height: 10,
    borderRadius: 5,
  },
  linBase: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  linBar: {
    borderRadius: 5,
  },
  yellowLin: {
    backgroundColor: yellow[400],
  },
  greenLin: {
    backgroundColor: green[400],
  },
  redLin: {
    backgroundColor: red[400],
  },
}));

const ProgressIndicator = ({ progress, layout }) => {
  const classes = useStyles();
  const { langMap: t1 } = useSelector(state => state.lang);

  const progressToDisplay = ['accepted', 'connected'].includes(progress)
    ? progress
    : 'ended';

  // interpreted call progress
  const displayedProgress = [
    {
      mnemonic: 'accepted',
      label: t1.T$_Ringing,
      class: classes.yellowRadio,
      weight: 33,
      color: 'yellow',
    },
    {
      mnemonic: 'connected',
      label: t1.T$_Connected,
      class: classes.greenRadio,
      weight: 67,
      color: 'green',
    },
    {
      mnemonic: 'ended',
      label: t1.T$_Ended,
      class: classes.redRadio,
      weight: 100,
      color: 'red',
    },
  ];

  const displayed = displayedProgress.find(
    p => p.mnemonic === progressToDisplay
  );

  // styled LinearProgress with dynamic class selection
  const LinearProgressWithLabel = ({ value, label }) => {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            variant="determinate"
            value={value}
            classes={{
              root: classes.linRoot,
              colorPrimary: classes.linBase,
              bar: `${classes.linBar} ` + classes[`${displayed.color}Lin`],
            }}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
        </Box>
      </Box>
    );
  };

  // layout picker
  const renderIndicator = () => {
    switch (layout) {
      case 'radio-v':
        // vertical radio buttons
        return (
          <RadioGroup name="progress" value={progressToDisplay} onChange={null}>
            {displayedProgress.map(prog => (
              <FormControlLabel
                value={prog.mnemonic}
                key={prog.mnemonic}
                control={
                  <Radio
                    color="default"
                    classes={{ root: classes[`${prog.color}Radio`] }}
                  />
                }
                label={prog.label}
              />
            ))}
          </RadioGroup>
        );
      case 'bar-h':
        // horizontal progress bar
        return (
          <LinearProgressWithLabel
            value={displayed.weight}
            label={displayed?.label}
          />
        );

      default:
        //horizontal radio buttons used for progress displahy only
        //cover with transparent div to prevent cursor and click
        return (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                zIndex: 100,
                width: '100%',
                height: 75,
              }}
            ></div>
            <RadioGroup
              name="progress"
              value={progressToDisplay}
              onChange={null}
            >
              <Grid
                container
                justify="space-around"
                classes={{ root: classes.progressDisplay }}
              >
                {displayedProgress.map(prog => (
                  <Grid item>
                    <FormControlLabel
                      value={prog.mnemonic}
                      key={prog.mnemonic}
                      control={
                        <Radio
                          color="default"
                          classes={{ root: classes[`${prog.color}Radio`] }}
                        />
                      }
                      label={prog.label}
                      labelPlacement="top"
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </div>
        );
    }
  };

  return renderIndicator();
};
ProgressIndicator.propTypes = {
  layout: PropTypes.oneOf(['radio-v', 'radio-h', 'bar-h']),
};
ProgressIndicator.defaultProps = {
  layout: 'radio-h',
};
export default ProgressIndicator;
