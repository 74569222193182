/**
 * Message Recorder Success Report
 *
 * Report successful result of recording operation:
 *   - Render type-dependent report: a form or alert.
 *   - If form is to be rendered, employ type-dependent form.
 *   - If uncaptured content is available for the clipboard, provide a button to capture.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/translation/trans';
import { isPublicType } from 'lib/api/messageApi';
import SuccessForm from './components/SuccessForm';
import SuccessAlert from './components/SuccessAlert';

const SuccessReport = props => {
  const { message, onExit, bypassForm, clipboardStatus, contentForClipboard } =
    props;

  const type = message?.type;
  const clipboardCaptured = clipboardStatus === 'captured';
  const to = message?.recipientScreenName;

  // only show form if clipboard is enabled (otherwise use alert)
  // then, always show it for outgoing messages
  // and for other messages provided user has not requested it be bypassed
  const showForm =
    clipboardStatus !== 'disabled' &&
    (!bypassForm || message.type === 'outgoing');

  //designate the form type
  const formType = message.type === 'outgoing' ? 'outgoing' : 'standard';

  // content is available but not yet loaded into clipboard
  const mustCaptureClipboard =
    clipboardStatus === 'uncaptured' && contentForClipboard;

  // define report content: lines of text, action buttons
  const content = {
    public: {
      captured: {
        heading: 'Message Posted',
        details: [
          "It's also in your clipboard.",
          'Paste it to social media if you wish.',
        ],
        actions: ['ok', 'hide'],
      },
      uncaptured: {
        heading: 'Message Posted',
        details: [
          'OPTIONAL:',
          'Copy it with the button and paste it to social media.',
        ],
        actions: ['copy', 'hide'],
      },
    },
    outgoing: {
      captured: {
        heading: 'Message Ready',
        details: [
          'Your Message is in the clipboard.',
          `Paste it into email or text it to ${to || 'your friend'}.`,
        ],
        actions: ['ok'],
      },
      uncaptured: {
        heading: 'Ready to Copy',
        details: [
          'Your Message is ready.',
          'Copy it with the button.',
          `Paste it into email or text it to ${to || 'your friend'}.`,
        ],
        actions: ['copy', 'cancel'],
      },
    },
  };

  // be very sure to employ only existing keys
  const selectedContent =
    content[isPublicType(type) ? 'public' : 'outgoing'][
      mustCaptureClipboard ? 'uncaptured' : 'captured'
    ];

  // render alert if form is not enabled
  return showForm ? (
    <SuccessForm
      contentForClipboard={contentForClipboard}
      onExit={onExit}
      content={selectedContent}
    />
  ) : (
    <SuccessAlert
      type={message?.type}
      clipboardCaptured={clipboardCaptured}
      onCompleted={onExit}
    />
  );
};

SuccessReport.propTypes = {
  message: PropTypes.object,
  onExit: PropTypes.func,
  bypassForm: PropTypes.bool,
  clipboardStatus: PropTypes.oneOf('disabled', 'uncaptured', 'captured'),
  contentForClipboard: PropTypes.string,
};

SuccessReport.dafaultProps = {
  onExit: () => {},
  bypassForm: false,
};

export default SuccessReport;
//'disabled', 'uncaptured', 'captured'
