/**
 * This module uploads mp3 recordings to the Drupal server.
 * Procecure is performed using JSON:API
 * Each upload procedure consists of:
 * 1. upload binary mp3 data (create file entity)
 * 2. create Drupal voice_message node referencing the mp3
 * 3. special processing for certain message types (e.g. call processing, user profile)
 *
 * //TODO2: Only permit Respond for logged in user
 * //TODO2: consider moving submitMessage into messageApi.js
 * //TODO2: adjust max upload file size (currently 64k)
 */
import { API_ROOT } from 'configs/config-hvn';
import { fetcher } from 'lib/utils/fetcher';
import {
  isPublicType,
  isTopicType,
  getProfiles,
  isMessageAnnotationRequired,
  messageAnnotation,
} from './messageApi';
import { getPeer } from 'lib/api/userApi';

import store from 'store';
import { dialogActions } from 'store/dialog-slice';
import { userActions } from 'store/user-slice';
import sigServer from './signalingServer';
import { getCallManager } from './webRTCApi';
import { messageListExpunge } from 'lib/api/subrequests';
import { currentPartition } from 'lib/api/communityApi';

const callProc = getCallManager();

const isValidData = data => {
  if (data === null) {
    return false;
  }
  if (
    data?.data === undefined ||
    data?.data === null ||
    data?.data.length === 0
  ) {
    return false;
  }
  return true;
};

const lang = 'en';

// node data is composed with optional file relationship
const buildNodeData = (
  type,
  title,
  parent,
  recipient,
  recipientScreenName,
  fileId /* optional */,
  body
) => {
  const exampleBody =
    '<p><a href="%toContact%" target="_blank">%to% Meetup Contact Link</a></p>';
  const nodeData = {
    data: {
      type: 'node--voice_message',
      attributes: {
        field_partition: currentPartition(),
        field_type: type,
        field_public: isPublicType(type),
        title,
        langcode: lang,
        promote: false,
        sticky: false,
        field_children: [],
      },

      // apply relationship only if fileId is provided
      relationships: fileId
        ? {
            field_file_public: {
              data: {
                type: 'file--file',
                id: fileId,
              },
            },
          }
        : {},
    },
  };

  // apply conditional keys
  const attr = nodeData.data.attributes;
  parent && (attr.field_parent = parent);
  recipient && (attr.field_recipient = recipient);
  recipientScreenName && (attr.field_recip_name = recipientScreenName);
  body &&
    (attr.body = {
      value: body,
      format: 'basic_html',
    });
  return nodeData;
};

// create a message object representing the newly submitted message
const submittedMessage = (msg, fileUrl) => {
  const { screen_name, name, id } = store.getState().user.auth.user;
  const recipient = msg.attributes.field_recipient
    ? msg.attributes.field_recipient
    : null;

  return {
    // there are no fileId or responses in a newly created message
    //fileId: null,
    //responses: [],

    id: msg.id,
    partition: msg.attributes.field_partition,
    fileUrl: fileUrl,
    nid: msg.attributes.drupal_internal__nid,
    screenName: screen_name,
    created: msg.attributes.created,
    title: msg.attributes.title,
    type: msg.attributes.field_type,
    isPublic: isPublicType(msg.attributes.field_type),
    userId: id,
    username: name,
    parent: msg.attributes.field_parent,
    recipient,
  };
};

// delete all prior profiles from the current user
const expungeOldProfiles = profile => {
  getProfiles({
    userId: store.getState().user.auth.user?.id,
    partition: currentPartition(),
  }).then(profiles => {
    // profiles is expected to include the new profile
    // delete all but the new one
    const expiredList = profiles.map(p => p.id).filter(id => id !== profile.id);
    messageListExpunge('voice_message', expiredList);
  });
};

// process and post a new message
// a. upload the audio file
// b. upload the metadata
// c. insert the new message into redux store
// d. push metadata to active peer users
// e. implement call processing protocol if appropriate

export const submitMessage = ({
  recording, // object describing the recording to be submitted
  parentMessage, // the recipient of a directed message, or the target of a response
  proxyToken, // null if recording is from an authenticated user
}) => {
  // only an invite may be submitted without a file
  const submitWithoutFile =
    recording?.type === 'invite' && recording?.blobURL === null;

  const { type, blob, title } = {
    type: null,
    blob: null,
    title: null,
    ...recording,
  };

  //new public messages have no recipient
  const recipient = isPublicType(type)
    ? null
    : { userId: parentMessage?.userId, screenName: parentMessage?.screenName };

  // topics have no parent
  // threads are only 1 layer deep, so responses to responses inherit the grandparent
  const parentMsgId = isTopicType(type)
    ? null
    : isTopicType(parentMessage?.type)
    ? parentMessage?.id
    : parentMessage?.parent;

  // prepare to upload file
  //TODO2: prevent filename collisions
  let fileUrl = '';
  const filename = `${Date.now()}.mp3`;
  const audioPostUrl = `${API_ROOT}/node/voice_message/field_file_public`;

  // optionally skip the posting of an audio file
  const conditionallySubmitFile = () => {
    return submitWithoutFile
      ? Promise.resolve({ data: null })
      : fetcher('audio', 'POST', audioPostUrl, blob, proxyToken, [
          { 'Content-Disposition': `file; filename="${filename}"` },
        ]); //@@fetcher
  };

  return conditionallySubmitFile()
    .then(data => {
      if (submitWithoutFile || isValidData(data)) {
        fileUrl = data?.data?.attributes?.uri?.url;

        //prepare to create Drupal entity
        const entityPostUrl = `${API_ROOT}/node/voice_message`;
        const nodeData = buildNodeData(
          type,
          title,
          parentMsgId,
          recipient?.userId,
          recipient?.screenName,
          data?.data?.id,
          null // body can be added
        );
        return fetcher('jsonapi', 'POST', entityPostUrl, nodeData, proxyToken); //@@fetcher
      } else {
        console.log('Failed data valication after posting audio: ', data);
        throw new Error('Failed data validation after posting audio.');
      }
    })
    .then(data => {
      // immediate update of dialogs in redux store:
      const newMessage = {
        ...submittedMessage(data.data, fileUrl),
        recipientScreenName: parentMessage?.screenName,
      };

      // workaround:
      // Of the following, only dialogActions.insert is required for 'outgoing'. However
      // however the insert interferes with proper success reporting from MessageRecorder.
      // Therefore, the insert will be handled from within MessageRecorder.
      if (type !== 'outgoing') {
        // do not insert outbound 'invite' messages
        if (type !== 'invite') {
          store.dispatch(dialogActions.insert({ message: newMessage }));
        }

        // convey metadata to peers:
        // Always notify for public, but only to active peer for direct newMessage.
        const recipientSocketId = getPeer(newMessage.recipient)?.socketId;
        if (newMessage?.isPublic || recipientSocketId) {
          sigServer.sendNewMessageNotification({
            message: newMessage,
            recipientSocketId,
          });
        }

        //handle call processing related messages
        callProc.handleOutboundMessage(newMessage);

        //special processing for new profile message
        if (type === 'profile') {
          store.dispatch(
            userActions.updateMember({
              userId: newMessage.userId,
              update: { profile: newMessage },
            })
          );
          expungeOldProfiles(newMessage);
        }
      }

      // return selected fields to invoking method
      const { id, attributes } = data.data;
      const { title, field_type } = attributes;
      //console.log(`created newMessage: id=${id}, type=${field_type}, title=${title}`)

      return { id, type, title, newMessage };
    });
};
