/**
 * Animation Demo
 * 
 * 
 */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    wrap: {
        margin: '0px auto',
        width: 486,
    },
    box: {
        width: 160,
        height: 120,
        float: 'left',
        background: '#347fc3',
        border: '1px solid #fff',
        overflow: 'hidden',
    },

    // ref.: https://codepen.io/levineuland/pen/AmwCL
    '@keyframes bounce': {
        '0%': { transform: 'translateY(0)' },
        '20%': { transform: 'translateY(0)' },
        '50%': { transform: 'translateY(0)' },
        '80%': { transform: 'translateY(0)' },
        '100%': { transform: 'translateY(0)' },
        '40%': { transform: 'translateY(-30px)' },
        '60%': { transform: 'translateY(-15px)' },
    },

    '@keyframes rubberBand': {
        '0%': { transform: 'scale(1)' },
        '30%': { transform: 'scaleX(1.25) scaleY(0.75)' },
        '40%': { transform: 'scaleX(0.75) scaleY(1.25)' },
        '60%': { transform: 'scaleX(1.15) scaleY(0.85)' },
        '100%': { transform: 'scale(1)' },
    },

    // ref.: https://stackoverflow.com/questions/58948890/how-to-apply-custom-animation-effect-keyframes-in-materialui-using-makestyles/63546822
    // ref.: https://stackoverflow.com/questions/16344354/how-to-make-blinking-flashing-text-with-css-3
    // ref.: https://stackoverflow.com/questions/58948890/how-to-apply-custom-animation-effect-keyframes-in-materialui-using-makestyles/63546822
    // ref.: https://stackoverflow.com/questions/16344354/how-to-make-blinking-flashing-text-with-css-3
    "@keyframes blinker": {  
        "0%": { opacity: 0, },
    },


    textAnimatedBounce: {
        textAlign: 'center',
        marginTop: '56px',
        color: '#fff',
        fontSize: '1.0em',
        fontFamily: 'sans-serif',
        textTransform: 'uppercase',

        animationDuration: '2.5s',
        animationFillMode: 'both',
        animationIterationCount: 'infinite',
        animationName: '$bounce',

    },
    textAnimatedRubberBand: {
        textAlign: 'center',
        marginTop: '56px',
        color: '#fff',
        fontSize: '1.0em',
        fontFamily: 'sans-serif',
        textTransform: 'uppercase',

        animationDuration: '2.5s',
        animationFillMode: 'both',
        animationIterationCount: 'infinite',
        animationName: '$rubberBand',
    },
    blinkingItem: {
        textAlign: 'center',
        marginTop: '56px',
        color: '#fff',
        fontSize: '1.0em',
        fontFamily: 'sans-serif',
        textTransform: 'uppercase',

        //animation: `$blinker 1s linear infinite`
        animationDuration: '1s',
        animationFillMode: 'linear',
        animationIterationCount: 'infinite',
        animationName: '$blinker',
    },
}));

const AnimationDemo = () => {
    const classes = useStyles()




    return <div className={classes.wrap}>
        <div className={classes.box}>
            <div className={classes.textAnimatedBounce}>Bounce</div>
        </div>
        <div className={classes.box}>
            <div className={classes.textAnimatedRubberBand}>RubberBand</div>
        </div>
        <div className={classes.box}>
            <div className={classes.blinkingItem}>Blink</div>
        </div>
    </div>
}
export default AnimationDemo

