/**
 * Render contextual help
 * - initially render an icon button
 * - on click, render the help content in a dialog.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid, Typography } from '@material-ui/core';
import { Help } from 'mdi-material-ui';
import ExceptionDialog from 'components/Modals/ExceptionDialog';

const useStyles = makeStyles(theme => ({
  helpButton: {
    marginTop: 0,
  },
  helpIcon: {
    fontSize: '.8em',
  },
  description: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },
  description: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
    },
  },
}));

const ContextualHelpButton = ({ heading, items, classes: classesOverride }) => {
  const [open, setOpen] = useState(false);
  const classes = { ...useStyles(), ...classesOverride };

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleClick}
        classes={{ root: classes.helpButton }}
        color="secondary"
      >
        {' '}
        <Help className={classes.helpIcon} />{' '}
      </IconButton>
      <ExceptionDialog
        exceptionDisable={true}
        open={open}
        onClose={handleClose}
        heading={heading}
        body={
          <Grid container direction="column" spacing={2}>
            {items.map(item => (
              <Grid item container>
                <Grid item xs={4} sm={2}>
                  <Typography
                    classes={{ root: classes.label }}
                    variant={'body2'}
                  >
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={9}>
                  <Typography
                    classes={{ root: classes.description }}
                    variant={'body2'}
                  >
                    {item.description}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        }
      />
    </>
  );
};

export default ContextualHelpButton;

ContextualHelpButton.propTypes = {
  context: PropTypes.string, // title of section to be displayed
  classes: PropTypes.object,
};
ContextualHelpButton.defaultProps = {
  classes: null,
};
