/**
 * VoiceActivityDetectionTest
 * 
 */


import React, { useRef } from 'react';
import useState from 'react-usestateref'

import {
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import VadJam3 from 'lib/api/VadApi/VadJam3'


const useStyles = makeStyles((theme) => ({
    button: {
        margin: 10
    }
}))

const VoiceActivityDetectionTest = ({ }) => {
    const [started, setStarted] = useState(false)
    const [talking, setTalking] = useState(false)
    //const [t, setT] = useState(Date.now())
    const [ t_, setT, t] = useState([]) 

    const classes = useStyles()
    const assetRef = useRef({})

    // report ms since last event
    const timer = () => {
        const now = Date.now();
        const t0 = t.current;
        setT(now)
        return now-t0
    }

    const handleButtonStart = () => { startListening().then(vad => assetRef.current.vad = vad); setStarted(true) };
    const handleButtonStop = () => { stopListening(); setStarted(false) };
    const handleTalkStart = () => { console.log(`Talk Start ${timer()}`); setTalking(true);}
    const handleTalkStop = () => { console.log(`Talk Stop ${timer()}`); setTalking(false); }


    const startListening = () => {
        assetRef.current.ctx = new AudioContext();
        return navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
            noiseSuppression: true
        })
            .then(stream => new VadJam3(
                assetRef.current.ctx,
                stream,
                {
                    onVoiceStart: handleTalkStart,
                    onVoiceStop: handleTalkStop,
                }
            ))

    }

    const stopListening = () => {
        assetRef.current.vad.destroy();
        delete assetRef.current.vad;
        if (assetRef.current.ctx) {
            assetRef.current.ctx.close()
                .then(() => { delete assetRef.current.ctx; })
                .catch(e => console.log("ctx.close error:", e))
        }
    }

    return <>
        <h1>VoiceActivityDetectionTest</h1>
        <div>
            <Button classes={{ root: classes.button }} variant="outlined" onClick={handleButtonStart} disabled={started}>Start</Button>
            <Button classes={{ root: classes.button }} variant="outlined" onClick={handleButtonStop} disabled={!started}>Stop</Button>
        </div>
        <div>{talking ? "Talking" : ""}</div>
    </>

}

export default VoiceActivityDetectionTest
