/**
 * Render a message type selector field
 * includes logic to determine list of available types
 *  if types list consists only of 'vmail' and 'comment' use <SwitchStd /> instead of <SelectorStd />
 *
 */

import React, { useEffect } from 'react';
import SelectorStd from 'components/utils/SelectorStd';
import {
  isDirectedType,
  typesAvailableToCreate,
  isSelfAuthored,
} from 'lib/api/messageApi';
import { useSelector } from 'react-redux';
import { isAdministrator } from 'lib/api/userApi';
import { makeArray } from 'lib/utils/utils';
import { t } from 'lib/translation/trans';
import SwitchStd from 'components/utils/SwitchStd';
import VisibilitySelectorBtn from 'containers/PlaylistView/components/PlaylistBrowser/components/VisibilitySelectorBtn';

const MessageTypeSelector = props => {
  const {
    types,
    selected,
    onChange,
    typeOverride,
    parentMessage,
    isAuthenticated,
    willBufferMessage,
    initializeTypes,
    employCommentVmailBinarySwitch,
  } = props;
  // optimization of label presented to user based on message type
  const messageTypeLabel = type => type;

  // use a switch instead of pulldown selector if choosing between 'comment' and 'vmail'.
  const typesArr = makeArray(types);

  const langCode = useSelector(state => state.ui.pSession.langCode);

  // initialize
  useEffect(() => {
    // initialize availableTypes list and initial type
    const availableTypes = [...getUserTypes(), ...getAdminTypes()];
    initializeTypes && initializeTypes(availableTypes);
    onChange && onChange(availableTypes[0]?.type); //first available by default
  }, [langCode]);

  // determine the list of message types available to user
  const getUserTypes = () =>
    // consider list assigned for parent message
    // unless typeOverride has been specified
    (typeOverride
      ? [{ type: typeOverride, label: messageTypeLabel(typeOverride) }]
      : typesAvailableToCreate(parentMessage)
    )
      // no direct message to self
      .filter(
        item => !isDirectedType(item.type) || !isSelfAuthored(parentMessage)
      )

      // no profile available to unAuthenticated user, unless willBufferMessage
      .filter(
        item => isAuthenticated || willBufferMessage || item.type !== 'profile'
      );

  // determine if additional types are available to an administrator
  const getAdminTypes = () =>
    // conditionally add administrative types
    // for now, just check for magic auth.user.name
    // only add these when there is no parent
    // and when there is no thypeOverride
    isAuthenticated &&
    isAdministrator() &&
    !Boolean(parentMessage) &&
    !Boolean(typeOverride)
      ? [{ type: 'notice', label: 'Notification' }]
      : [];

  const messageTypeSelectorProps = {
    name: 'rec-msg-type',
    label: t('Message_Type'),
    items: types.map(item => ({ key: item.type, data: t(item.label) })),
    value: selected,
    onChange: onChange,
    // helpContext: (availableTypesRef.current.length === 1) ? null : "Message Types"
  };

  const messageVisibility = selected === 'comment' ? 'public' : 'directed';
  const handleVisibilityChange = vis => {
    onChange && onChange(vis === 'public' ? 'comment' : 'vmail');
  };

  return employCommentVmailBinarySwitch ? (
    /*
        ? <SwitchStd
            labels={{
                checked: t("direct"),
                unChecked: t('public'),
                border: t("Message_Type"),
            }}
            checked={selected === 'vmail'}
            onChange={checked => onChange(checked ? 'vmail' : 'comment')}
        />
*/

    <VisibilitySelectorBtn
      visibility={messageVisibility}
      onChange={handleVisibilityChange}
    />
  ) : (
    <SelectorStd {...messageTypeSelectorProps} />
  );
};

export default MessageTypeSelector;
