/**
 * Wrapper for Audio()
 * version 2: modify to eliminate use of redux

* Example Invocation:
 * const audio = new AudioObj({playerKey, src, onEvent})
 *
 * Reference:
 * https://www.w3schools.com/JSREF/dom_obj_audio.asp
 *
 */

// inhibit use of system diagnostic logger
// import { dlog } from "lib/api/devApi";

const debug = true;
const dlog = (...args) => {
  debug && console.log(...args);
};

class AudioObj {
  el = null;
  playerKey = 'default';
  onEvent = () => {};
  isPlaying = false;
  canPlay = false; // true upon receipt of canplay event

  autoplayEn = false; // cannot autoplay until this is enabled by explisit play or togglePlay
  autoplay = false;

  setIsPlaying = val => {
    this.isPlaying = val;
    this.reportEvent(val ? 'playing' : 'pausing');
  };

  constructor(args = {}) {
    this.el = new Audio(args?.src); // apply src if specified in args
    args?.onEvent && (this.onEvent = args.onEvent); // map event handler if requested in args
    args?.playerKey && (this.playerKey = args.playerKey);

    this.el.crossOrigin = 'anonymous'; // required for Firefox CORS simulator
    this.el.preload = 'auto';

    //event handlers
    this.el.addEventListener('play', this.onPlay);
    this.el.addEventListener('pause', this.onPause);
    this.el.addEventListener('canplaythrough', this.onCanplaythrough);
    this.el.addEventListener('loadedmetadata', this.onLoadedMetaData);
    this.el.addEventListener('error', this.onError);
    this.el.addEventListener('ended', this.onEnded);
    this.el.addEventListener('canplay', this.onCanplay);

    this.setIsPlaying(false);
  }

  destroy = () => {
    // cleanup
    this.el.pause();
    this.el.removeAttribute('src'); // empty source
    //this.el.load();

    //remove event handlers
    this.el.removeEventListener('play', this.onPlay);
    this.el.removeEventListener('pause', this.onPause);
    this.el.removeEventListener('canplaythrough', this.onCanplaythrough);
    this.el.removeEventListener('loadedmetadata', this.onLoadedMetaData);
    this.el.removeEventListener('error', this.onError);
    this.el.removeEventListener('ended', this.onEnded);
    this.el.removeEventListener('canplay', this.onCanplay);
    this.el = null;
  };

  // report event using callback function
  reportEvent = e => this.onEvent(e, this.playerKey);

  // coordinate the change of src.
  // commence playing immediately if autoplay is asserted
  async setSrc(src, autoplay = false) {
    this.autoplay = autoplay;

    // only process for change of src
    if (this.srcOk(src) && src !== this?.el?.src) {
      // pause playing
      this.isPlaying && this.el.pause();
      await !this.isPlaying;

      // reset audio element
      if (this.el) {
        this.canPlay = false;
        this.el.removeAttribute('src'); // empty source
        //this.el.load(); //reloads the Audio element

        // apply new src
        this.el.src = src;
        if (autoplay) {
          // wait for player to be ready
          await this.canPlay;
          this.play();
        }
      }
    }
  }

  // clear the current src
  clrSrc = () => {
    this.el.removeAttribute('src'); // empty source
  };

  // for development, add logging of all Audio events
  devEnableLogAllEvents = () => {
    this.el.addEventListener('loadedmetadata', () => {
      this.log('AudioEl event: loadedmetadata');
    });
    this.el.addEventListener('pause', () => {
      this.log('AudioEl event: pause');
    });
    this.el.addEventListener('timeupdate', () => {
      this.log('AudioEl event: timeupdate');
    });
    this.el.addEventListener('abort', () => {
      this.log('AudioEl event: abort');
    });
    this.el.addEventListener('canplaythrough', () => {
      this.log('AudioEl event: canplaythrough');
    });
    this.el.addEventListener('cuechange', () => {
      this.log('AudioEl event: cuechange');
    });
    this.el.addEventListener('durationchange', () => {
      this.log('AudioEl event: durationchange');
    });
    this.el.addEventListener('emptied', () => {
      this.log('AudioEl event: emptied');
    });
    this.el.addEventListener('error', () => {
      this.log('AudioEl event: error');
    });
    this.el.addEventListener('loadeddata', () => {
      this.log('AudioEl event: loadeddata');
    });
    this.el.addEventListener('loadstart', () => {
      this.log('AudioEl event: loadstart');
    });
    this.el.addEventListener('play', () => {
      this.log('AudioEl event: play');
    });
    this.el.addEventListener('playing', () => {
      this.log('AudioEl event: playing');
    });
    this.el.addEventListener('progress', () => {
      this.log('AudioEl event: progress');
    });
    this.el.addEventListener('ratechange', () => {
      this.log('AudioEl event: ratechange');
    });
    this.el.addEventListener('seeked', () => {
      this.log('AudioEl event: seeked');
    });
    this.el.addEventListener('seeking', () => {
      this.log('AudioEl event: seeking');
    });
    this.el.addEventListener('stalled', () => {
      this.log('AudioEl event: stalled');
    });
    this.el.addEventListener('suspend', () => {
      this.log('AudioEl event: suspend');
    });
    this.el.addEventListener('volumechange', () => {
      this.log('AudioEl event: volumechange');
    });
    this.el.addEventListener('waiting', () => {
      this.log('AudioEl event: waiting');
    });
  };

  // determine if input string is a valid audio source file
  srcOk = src =>
    typeof src === 'string' &&
    (src.toLowerCase().includes('.mp3') || src.toLowerCase().includes('blob:'));

  // event handlers -------------------------------------------------------------
  onPlay = () => {
    this.setIsPlaying(true);
    this.reportEvent('play');
  };
  onPause = () => {
    this.setIsPlaying(false);
    this.reportEvent('pause');
  };
  onEnded = () => {
    this.setIsPlaying(false);
    this.reportEvent('ended');
  };

  //@@ NOTE: Safari may not provide canplay event, must test
  onCanplay = () => {
    this.canPlay = true;
    this.reportEvent('canplay');
  };

  onLoadedMetaData = () => this.reportEvent('loadedmetadata');

  // NOTE: not always issued by Safari, rely on 'loadedmetadata' instead
  onCanplaythrough = () => {
    //  if (this.autoplay) { this.play() };
    this.reportEvent('canplay');
  };

  onError = () => {
    this.log('AudioEl error.');
    this.reportEvent('error');
  };

  // methods  -------------------------------------------------------------

  play = () => {
    // ignore first autoplay (some browsers require manual initiation)
    if (this.autoplay && !this.autoplayEn) {
      this.autoplayEn = true;
      return null;
    }
    this.autoplayEn = true; // assume manual gesture occurred
    this.setIsPlaying(true);
    return this.el
      .play()
      .then(data => data)
      .catch(err => {
        this.log(`Audio play error: ${err.name}`);
        this.reportEvent(err.name);
      });
  };

  pause = () => {
    this.setIsPlaying(false);
    this.el.pause();
  };

  togglePlay = () => {
    this.autoplayEn = true; // assume manual gesture occurred
    this.isPlaying ? this.pause() : this.play();
  };

  log = text => console.log('AudioObj:', text);
}

export default AudioObj;
