/**
 * Renders a message player and scrollable playlist
 * disabled:  Tabs are employed to select public/directed message sets
 *
 * 20230511 jjs New layout offering public/private selection
 *              * remove liveCall
 */

//  consider moving up to parent:
//      notify redux store when src changes
//      re-render for call processing events
//      handle click event on a message instance
//      handle action event
//      handle recorder exit
//      render liveCall
//      render responder (recorder)

import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { dialogActions } from 'store/dialog-slice';
import { uiActions } from 'store/ui-slice';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import { WEB_HOST } from 'configs/config-hvn';
import { Grid, Chip } from '@material-ui/core';
import TopicCard_v6 from './components/TopicCard_v6'; // includes LiveCall margin button
import Playlist_v1 from './components/Playlist_v1';
import { timeout, isElement } from 'lib/utils/utils';
import VisibilitySelectorTabs from './components/VisibilitySelectorTabs';
import VisibilitySelectorBtn from './components/VisibilitySelectorBtn';
import DMViewSelector from './components/DMViewSelector';
import AddMessageFAB from './components/AddMessageFAB';
import DmNonMemberFAB from './components/DmNonMemberFAB';
import BorderedBlock from 'components/utils/BorderedBlock';
import { t } from 'lib/translation/trans';
import {
  isWithMe,
  killMessages,
  isTopic,
  retrieveMessage,
} from 'lib/api/messageApi';
import InboxView from 'containers/InboxView';
import ButtonStd from 'components/utils/ButtonStd';
import Dialog2 from 'components/Modals/Dialog2';
import useInboxConsumer from 'containers/InboxView/hooks/useInboxConsumer';
import { createPortal } from 'react-dom';
import { useHostedMediaContext } from 'context/hostedMediaContext';

// control format of public/private selector mechanism
const visibilityControl = 'btn'; // "btn", "tabs", null

//NOTE: react-scroll wants  "react": "^16.6.0"
// install with 'npm install --force'
const Scroll = require('react-scroll');
const scroller = Scroll.scroller;

const useStyles = makeStyles(theme => ({
  playerWrapper: {
    margin: '0 auto', //center it within outer container
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    //backgroundColor: '#F2EFE4', //match background of containerMain
    borderRadius: '1rem',
    //padding: '1.5rem',
  },

  playerOuterWrapper: {
    minHeight: 140,
    [`@media (max-height: ${theme.breakpoints.custom.vxsThreshold}px)`]: {
      minHeight: 40,
    },
  },
  inboxChip: {
    backgroundColor: theme.palette.primary.dark,
    marginTop: 12,
    marginBottom: 12,
  },
}));

const PlaylistBrowser = props => {
  // create alias for play() so that additional local processing can be attached
  const {
    isPlaying,
    defaultProps,
    play: _play,
    pause,
    handleAudioEvent,
  } = useVoicePlayerHelper({ playerKey: 'PlaylistBrowser' });
  const { visibility, inboundIdQueue } = useSelector(state => state.dialog);
  const {
    playlist,
    onMessageSelect,
    onMemberInfoRequest,
    onNext,
    onPrevious,
    onEnded,
    classes: classesOverride,
    initialView,
    zen,
    singleThread,
  } = props;
  const classes = { ...useStyles(), ...classesOverride };
  const [payloadHeight, setPayloadHeight] = useState(null);
  const [remainingHeight, setRemainingHeight] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false); //indicate whether Action is in progress
  const [dmView, setDmView] = useState('New');
  const [emptyDialog, setEmptyDialog] = useState(false);
  const playerContainerRef = useRef();
  const fabRef = useRef();
  const visBtnRef = useRef();
  const { messageMap, messageSequence, selectedMessageId, invites } =
    useSelector(state => state.dialog);
  const callProgress = useSelector(state => state.webRTC.call.progress);
  const vxs = useSelector(state => state.ui.vxs); // vertically extra small
  const mainContentHeight = useSelector(
    state => state.ui.layout.mainContentHeight
  );
  const history = useHistory();
  const { autoPlay } = useSelector(state => state.ui.pSession);
  const { isAuthenticated } = useSelector(state => state.user);

  // dynamic classes for child component BorderedBlock
  const classesBorderedBlock = makeStyles({
    wrapperOuter: {
      paddingTop: '10px',
      width: '100%',
      height: singleThread ? null : remainingHeight,
      borderBottom: singleThread ? null : '5px solid red',
    },
    wrapperInner: {
      position: 'relative',
      border: '1px rgba(255, 255, 255, 0.23) solid',
      borderRadius: '4px',
      margin: '0', //previously '0 1rem', changed for use by Recorder
      padding: 16,
    },
  });

  const dispatch = useDispatch();
  const message = messageMap ? messageMap[selectedMessageId] : null;
  const src = message ? `${WEB_HOST}/${message.fileUrl}` : '';
  const noteMessageConsumed = useInboxConsumer();
  const dm = visibility !== 'public';

  // determine if the view is void of messages
  // (depends on whether playlist or Inbox is being rendered)
  const viewingPlaylist = dmView === 'Archive' || visibility === 'public';
  const empty = !Boolean(
    viewingPlaylist ? playlist.length : inboundIdQueue.length
  );

  const ctx = useHostedMediaContext();
  const externalPlayerContainer = ctx.playerContainer;

  // initialize
  //    useEffect(() => {
  //        // if no visibility controls, force 'public' listing
  //        if (!visibilityControl) {
  //            dispatch(dialogActions.setVisibilityFilter('public'))
  //        }
  //    }, [])

  // select initial view based on prop
  useEffect(() => {
    switch (initialView) {
      case 'new':
        setDmView('New');
        dispatch(dialogActions.setVisibilityFilter('directed'));
        break;
      case 'dm':
        setDmView('Archive');
        dispatch(dialogActions.setVisibilityFilter('directed'));
        break;
      default:
        dispatch(dialogActions.setVisibilityFilter('public'));
        break;
    }
  }, [initialView]);

  // select initial view based on prop
  useEffect(() => {
    if (visibility === 'directed' && !isAuthenticated) {
      // require authentication before rendering directed messages
      dispatch(uiActions.holdoffCookieConsentBar(true));
      dispatch(uiActions.setRegDialog({ open: true, mode: 'login' }));
    }
  }, [visibility, isAuthenticated]);

  //calculate component heights
  const playerHeight = playerContainerRef.current?.offsetHeight || 0;
  const fabBtnHeight = fabRef.current?.offsetHeight || 0;

  const visBtnHeight = visBtnRef.current?.offsetHeight || 0;

  useEffect(() => {
    const remainingHeight = mainContentHeight - (playerHeight + visBtnHeight);
    const payloadHeight =
      remainingHeight -
      (fabBtnHeight +
        10 + // outerpaddingTop
        2 + // inner border
        2 * 16); //inner padding
    setPayloadHeight(payloadHeight);
    setRemainingHeight(remainingHeight);
  }, [mainContentHeight, playerHeight, fabBtnHeight]);

  // when selected message changes, scroll it into view
  // ( per https://github.com/fisshy/react-scroll )
  useEffect(() => {
    // delay the scroll (give open family time to close smoothly)
    timeout(500).then(() => scrollToSelectedMessage());
  }, [selectedMessageId, playlist]);

  // re-render for call processing events
  useEffect(() => {
    //console.log(`PlaylistBrowser useEffect: ${callProgress}, userId=${message?.userId}, callable=${callProc.isUserCallable(message?.userId)}` )
  }, [callProgress]);

  // smooth-scroll to selected message
  // ( per https://github.com/fisshy/react-scroll )
  const scrollToSelectedMessage = (options = {}) => {
    const defaults = {
      containerId: 'playlistContainerId',
      smooth: true,
      offset: 0,
      duration: 1500,
      delay: 100,
    };
    try {
      scroller.scrollTo(selectedMessageId, { ...defaults, ...options });
    } catch (error) {
      console.log('scrollTo error:', error);
    }
  };

  // mark message consumed (listende to)
  // mark specified id, or if unspecified mark the currently selected message
  const markAsConsumed = (id = selectedMessageId) => {
    noteMessageConsumed(messageMap[id], false);
  };

  // play the current message and also annotate that it has been consumed
  const play = () => {
    _play();
    ctx?.notePlayStart && ctx.notePlayStart();
    markAsConsumed();
  };

  // handle click event on a message instance
  // (/* BYPASS FOR NOW, actions are all Dialogs so clicks ignored anyway */ ignore clicks while Action is in progress )
  const handleMessageClick = id => {
    if (true || !actionInProgress) {
      if (autoPlay) {
        //automatically play message upon selection

        if (selectedMessageId === id) {
          // message already selected
          isPlaying ? pause() : play();
        } else {
          // a new message has been clicked, select it and (if necessary) commence playing
          onMessageSelect && onMessageSelect(id);
          markAsConsumed(id); // VoicePlayer will automatically start to play
        }
      } else {
        // no automatic play of  message upon selection
        // clicking the previously selected Member has no effect on play
        if (id !== selectedMessageId) {
          // stop playing and await manual action
          if (isPlaying) {
            pause();
          }
          onMessageSelect && onMessageSelect(id);
        }
      }
      dispatch(uiActions.respondTipPing()); // let the respondTip mechanise keep track of message indexing
    }
  };

  const handleActionsEvent = ev => {
    // pause on any event except Play
    ev === 'playClick' ? play() : pause();

    // note whether Action dialog is in progress
    // (all events except those enumerated here are assumed to launch dialog)
    setActionInProgress(
      !['playClick', 'pauseClick', 'dialogClose'].includes(ev)
    );
  };

  const processAudioEvent = (e, key) => {
    handleAudioEvent(e);
    ['ended'].includes(e) && onEnded && onEnded();
  };

  // prescribe props combinations for versatile rendering of Player
  const playerProps = {
    ...defaultProps,
    src,
    autoplay: autoPlay,
    noBrowserViewControl: true,
    progressStyle: 'standard',
    onNext: playlist.length > 0 ? onNext : null,
    onPrevious: playlist.length > 0 ? onPrevious : null,
    uiItems: zen ? ['progress'] : vxs ? ['hControls'] : ['visualizer'],
    ident: 'Playlist',
  };

  const playlistProps = {
    playlist,
    playlistItemComponent: <TopicCard_v6 />,
    onMessageSelect: handleMessageClick,
    onMemberInfoRequest,
    onActionsEvent: handleActionsEvent,
    autoSizerPadding: 0,
    height: payloadHeight,
    isPlaying,
    isAuthenticated,
    singleThread,
    zen,
  };

  // render a message Player in the selected format
  const renderPlayer = () => (
    <Grid container justify="center">
      <div
        ref={playerContainerRef}
        className={zen ? null : classes.playerOuterWrapper}
        style={{ width: vxs ? '30%' : '100%' }}
      >
        {isElement(externalPlayerContainer) ? (
          // render VoicePlayer in portal if external container is provided
          createPortal(
            <VoicePlayer {...playerProps} />,
            externalPlayerContainer
          )
        ) : (
          //otherwise render VoicePlayer normally
          <div className={classes.playerWrapper}>
            <VoicePlayer {...playerProps} />
          </div>
        )}
      </div>
    </Grid>
  );

  // Render a Player and Playlist
  // Playlist provides capability of launching Recorder dialog

  const handleVisibilityChange = value => {
    dispatch(dialogActions.setVisibilityFilter(value));
    //        dispatch(uiActions.setInboxAlert(false)); //extinguish alert if present
  };

  const handleInboxLaunch = () => {
    history.push('inbox');
  };

  const inboxLauncher = () => (
    <Chip
      classes={{ root: classes.inboxChip }}
      label={t('Inbox_View')}
      onClick={handleInboxLaunch}
    />
  );

  const handleDMViewChange = val => setDmView(val);

  // request to remove all messages in this view
  const handleEmptyClick = () => {
    switch (dmView) {
      case 'New':
        // mark all inbound DMS as consumed
        inboundIdQueue.forEach(id => {
          noteMessageConsumed(messageMap[id], false);
        });
        dispatch(dialogActions.initInboxQueue([]));
        break;
      case 'Archive':
        // delete all DMs with the user
        setEmptyDialog(true);
        break;
      default:
        break;
    }
  };

  const handleEmptyConfirm = () => {
    const deathlist = messageSequence.filter(id => isWithMe(messageMap[id]));
    setEmptyDialog(false);
    killMessages(deathlist);
  };

  // in singleThread mode, can only DM to parent
  const topicRecorderFabTargetMessage = () =>
    isTopic(message) ? message : retrieveMessage(message?.parent);

  const renderContextControls = () => (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      ref={fabRef}
    >
      <Grid item>
        {visibility === 'directed' ? (
          <DmNonMemberFAB />
        ) : (
          <AddMessageFAB
            dm={dm}
            respondToMessage={
              singleThread ? topicRecorderFabTargetMessage() : null
            }
          />
        )}
      </Grid>
      <Grid item style={{ visibility: dm ? 'visible' : 'hidden' }}>
        <DMViewSelector view={dmView} setView={handleDMViewChange} />
      </Grid>
      {/* hide the button if list is empty */}
      <Grid item style={{ visibility: dm && !empty ? 'visible' : 'hidden' }}>
        <ButtonStd
          style={{ width: 88 }}
          onClick={handleEmptyClick}
          label={dmView === 'New' ? 'Archive All' : t('Empty')}
          color="primary"
        />
      </Grid>
    </Grid>
  );

  /**
   * render a message player, a means to select among available views, a border, and a list of messages
   * tab or buttons are employed to select between public/directed messages
   * a switch is employed for directed messages to select between new vs. archived messages
   */
  const renderStandardBrowser = () => (
    <>
      {!zen && visibilityControl === 'btn' ? (
        <div style={{ marginBottom: 20 }} ref={visBtnRef}>
          <VisibilitySelectorBtn
            visibility={visibility}
            onChange={handleVisibilityChange}
          />{' '}
        </div>
      ) : null}

      <BorderedBlock
        labelTop={
          singleThread
            ? 'Comments'
            : t(
                visibility === 'public'
                  ? 'Public_Posts'
                  : `${t('Direct_Messages')} (DM's)`
              )
        }
        classes={classesBorderedBlock()}
      >
        {renderContextControls()}
        <div>
          {' '}
          {visibilityControl === 'tabs' ? (
            /* using tabs to select public/diected */
            <VisibilitySelectorTabs
              renderItems={[
                <Playlist_v1 {...playlistProps} />,
                <Playlist_v1 {...playlistProps} />,
              ]}
            />
          ) : /* using buttons and DmView selector  to select public/directed and new/archived */
          viewingPlaylist ? (
            <Playlist_v1 {...playlistProps} />
          ) : (
            <InboxView embed={true} noRedirect />
          )}
        </div>
      </BorderedBlock>
    </>
  );

  return (
    <>
      {renderPlayer()}
      {/* zen ? <Playlist_v1 {...playlistProps} /> : */ renderStandardBrowser()}
      <Dialog2
        open={emptyDialog}
        onClose={() => setEmptyDialog(false)}
        heading={`${t('Delete_Your_Archive')}:`}
        content={
          <div>{`${t('All_Messages_In_This_List')} ${t(
            'will_be_permanently_deleted'
          )}`}</div>
        }
        actions={
          <>
            <ButtonStd
              onClick={() => setEmptyDialog(false)}
              label={t('Cancel')}
            />
            <ButtonStd
              onClick={handleEmptyConfirm}
              label={t('DELETE')}
              color="secondary"
            />
          </>
        }
      />
    </>
  );
};

PlaylistBrowser.propTypes = {
  playlist: PropTypes.arrayOf(PropTypes.object),
  onMessageSelect: PropTypes.func,
  onMemberInfoRequest: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onEnded: PropTypes.func,
  classes: PropTypes.object,
  initialView: PropTypes.oneOf(['new', 'dm', 'public']),
  zen: PropTypes.bool,
  singleThread: PropTypes.bool,
};
PlaylistBrowser.defaultProps = {
  initialView: 'public',
  zen: false,
  singleThread: false,
};

export default PlaylistBrowser;
