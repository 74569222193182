/**
 * Render a form to manage Notifications Settings
 * Support sms and web-push notification modes (channels)
 *
 *
 */

import NotificationsSettingsPush from './components/NotificationsSettingsPush';
import NotificationsSettingsSms from './components/NotificationsSettingsSms';

const NotificationsSettings = ({ displayAs, onSubmit, onLater, channel }) =>
  channel === 'push' ? (
    <NotificationsSettingsPush onSubmit={onSubmit} />
  ) : (
    <NotificationsSettingsSms
      displayAs={displayAs}
      onSubmit={onSubmit}
      onLater={onLater}
    />
  );

NotificationsSettings.defaultProps = {
  channel: 'push',
};

export default NotificationsSettings;
