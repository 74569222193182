/**
 * MembershipSubscriptionForm
 *
 * Register or login the user if necessary,
 * explain the transaction details,
 * and perform either a purchase or sample transaction.
 *
 *
 */

// to get Drupal time:
// https://github.com/GiorgosK/time_service

import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper, IconButton } from '@material-ui/core';
import { catalogItems } from '../config/plans.js';
import { useSelector, useDispatch } from 'react-redux';
import LoginView from 'components/AccountButton/components/LoginView';
import PoweredByPaypal from './PoweredByPaypal';
import ButtonStd from 'components/utils/ButtonStd';
import PaypalButton from './PaypalButton';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
  container: {
    width: '50%',
    padding: '1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const MembershipSubscriptionForm = ({ request, onCancel }) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [newUser, setNewUser] = useState(false);

  const classes = useStyles();
  const purchasePlan = request.plan;
  const sampleRequested = request.button === 'Free Trial';
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.user);
  console.log('MembershipSubscriptionForm request:', request);
  // require user to login or register before proceeding
  useEffect(() => {
    isAuthenticated || setLoggingIn(true);
  }, []);

  // one of these should be asserted, if not commence login procedure
  useEffect(() => {
    if (!isAuthenticated && !loggingIn) {
      setLoggingIn(true);
    }
  }, [isAuthenticated, loggingIn]);

  // if sample is requested, look it up in the catalog
  const samplePlan = sampleRequested
    ? catalogItems.find(i => i.level.key === purchasePlan.level.key && i.sample)
    : null;
  const sku = sampleRequested ? samplePlan?.sku : purchasePlan?.sku;
  const level = purchasePlan.level.key;

  const sampleApproved = null;

  const handleLoginSuccess = ({ newRegistration }) => {
    setNewUser(newRegistration);
    setLoggingIn(false);
  };

  const explain = () => {
    if (level === 'basic') {
      return newUser
        ? 'Congrats new user, you are subscribed in the complimentary "Basic" level membership.'
        : 'Welcome Back, you are currently subscribed in the "Basic" level.';
    } else if (sampleRequested) {
      //evaluate eligibility
      return `Enjoy your  ONE-TIME Free Trial`;
    } else {
      // explain purchase experience and price
      return (
        <div>
          <Typography variant="h6">{`$${purchasePlan.unitPrice} one ${purchasePlan.unit}`}</Typography>
          <Typography variant="body">( NOT a recurring charge )</Typography>
        </div>
      );
    }
  };
  const actions = () => {
    if (sampleRequested) {
      return <ButtonStd color="secondary" label="Start Now" />;
    } else {
      return (
        <>
          <Grid item container justifyContent="center">
            <PaypalButton sku={request?.plan?.sku} />
          </Grid>
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Typography component="div" variant="body">
              Powered by Paypal
            </Typography>
          </div>
        </>
      );
    }
  };

  if (loggingIn) {
    return <LoginView onSuccess={handleLoginSuccess} onCancel={onCancel} />;
  } else if (isAuthenticated) {
    console.log(
      `newUser=${newUser}, level=${level}, sample=${sampleRequested}, sku=${sku}}`
    );
    return (
      <Grid container justifyContent="center">
        <Paper classes={{ root: classes.container }}>
          <Grid item container justifyContent="center">
            {explain()}
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: '1rem' }}
          >
            {actions()}
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ marginTop: '1rem' }}
          >
            <IconButton onClick={onCancel}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Paper>
      </Grid>
    );
  } else {
    return <h1>ERROR!</h1>;
  }
};

export default MembershipSubscriptionForm;

/*
 https://oksayit.com/welcome
 ?PayerID=5SU2R7VZELBRS
 &st=Completed
 &tx=3JA74602RP919123V
 &cc=USD
 &amt=1.00
 &payer_email=jsulmar%40alum.mit.edu
 &payer_id=5SU2R7VZELBRS
 &payer_status=VERIFIED
 &first_name=Joseph
 &last_name=Sulmar
 &address_name=Joseph%20Sulmar
 &address_street=3%20Viles%20Road
 &address_city=Lexington
 &address_state=MA
 &address_country_code=US
 &address_zip=02421
 &residence_country=US
 &txn_id=3JA74602RP919123V
 &mc_currency=USD
 &mc_fee=0.52
 &mc_gross=1.00
 &protection_eligibility=ELIGIBLE
 &payment_fee=0.52
 & payment_gross=1.00
 &payment_status=Completed
 &payment_type=instant
 &handling_amount=0.00
 &shipping=0.00
 &item_name=TestPurchase
 &item_number=test001
 &quantity=1
 &txn_type=web_accept
 &payment_date=2024-01-05T20%3A20%3A08Z
 &receiver_id=75HV2HVJAFT3W
 &notify_version=UNVERSIONED
 &verify_sign=AKCQntToGbe-2cehsdzfY7q53W.lAfTdiCfr5dTTqKFULVyxswwbP59s

 */
