import { OptionControls } from 'hoc/WithOptionControls';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ListIcon from '@material-ui/icons/List';

export const optionControlsBrowserView = new OptionControls({
  heading: 'Browsing Format',
  optionKey: 'browserView',
  options: [
    { label: 'Carousel', icon: <ViewCarouselIcon /> },
    { label: 'Playlist', icon: <ListIcon /> },
  ],
});
