/**
 * VolumeMeterTest
 * ref.: https://github.com/webrtc/samples/tree/gh-pages/src/content/getusermedia/volume
 */


import React, { useEffect, useState } from 'react';
import {
    Button,
} from '@material-ui/core';
import VolumeMeter from 'components/VolumeMeter'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: 10
    }
}))

const VolumeMeterTest = ({ }) => {
    const classes = useStyles()
    const [stream, setStream] = useState(null)
    const [started, setStarted] = useState(false)

    // create a microphone stream
    useEffect(() => {
        try {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            window.audioContext = new AudioContext();

            const constraints = window.constraints = {
                audio: true,
                video: false
            };
    
            //@@ REQUIRES https
            if (navigator.mediaDevices) {
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(handleStreamSuccess)
                    .catch(handleStreamError);
            } else {
                alert('navigator.mediaDevices not supported.');
            }
    


        } catch (e) {
            alert('Web Audio API not supported.');
        }

    }, [])

    function handleStreamSuccess(stream) {
        setStream(stream)
        console.log("handleStreamSuccess.")
    }

    function handleStreamError(error) {
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
    }

    const handleStart = () => setStarted(true)
    const handleStop = () => setStarted(false)

    return <>
        <h1>VolumeMeterTest</h1>
        <VolumeMeter stream={stream} enable={started} />
        <div>
            <Button classes={{ root: classes.button }} variant="outlined" onClick={handleStart} disabled={started}>Start</Button>
            <Button classes={{ root: classes.button }} variant="outlined" onClick={handleStop} disabled={!started}>Stop</Button>
        </div>
    </>

}

export default VolumeMeterTest
