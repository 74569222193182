// fetch Basic Page from Drupal
// optionally perform token substitution
// transform to HTML object

import { fetcher } from 'lib/utils/fetcher';
import { makeArray } from 'lib/utils/utils';

export default class HtmlContent {
  url = null; // the resource URL for a Drupal Basic Page
  body = null; // the body field of the fetched Page
  tokens = [
    // aray of substitutions to be applied
    {
      symbol: '%exampleToken%', // embedded in body
      replacement: `exampleHTML`, // literal to be inserted as replacement into body
    },
  ];

  constructor(pageTitle, tokens, body) {
    this.url = `jsonapi/node/page?filter[title]=${pageTitle}&fields[node--page]=title,body`;
    this.tokens = tokens;
    if (body) {
      this.body = body;
    }
  }

  // if body already set, resolve immediately, otherwose...
  // ... fetch the drupal node, and obtain the body content
  fetch = () => {
    if (this.body) {
      return this.body;
    } else {
      return fetcher('jsonapi', 'GET', this.url).then(data => {
        this.body = makeArray(data?.data)[0]?.attributes?.body?.processed;
        return this.body;
      });
    }
  };

  // iterate over tokens, performing global string replacement on each
  withSubstitutions = () =>
    makeArray(this.tokens).reduce((rslt, token) => {
      const regex = new RegExp(token.symbol, 'g'); // global replacement
      return typeof rslt === 'string'
        ? rslt.replace(regex, token.replacement)
        : rslt;
    }, this.body);

  // convert text to HTML object
  /* consider refactoring to https://github.com/MomenSherif/react-html-string */
  static html = content => (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  );
}
