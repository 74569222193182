/**
 * CallSettings
 * Render status and controls for use while progress==='connected'
 *
 * dev Interim... just render old AudioSettings
 *
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setMicVolume, setSpeakerVolume } from 'lib/api/webRTCApi';
import TalkStatus from './components/TalkStatus';
import RTCQualityReport from './components/RTCQualityReport';
import DeviceManager from './components/DeviceManager';
import { t } from 'lib/translation/trans';

const dev = false; // enable display of development items

const useStyles = makeStyles({});

const CallSettings = ({ enable, localStream, remoteStream }) => {
  const classes = useStyles();
  const persistentSettings = useSelector(
    state => state.ui.pSession.liveCallAudioSettings
  );
  const dispatch = useDispatch();

  const mediaItems = [
    {
      device: 'microphone',
      direction: 'outbound',
      stream: localStream,
      volume: persistentSettings?.micVolume,
      muted: useSelector(state => state.webRTC.muted),
    },
    {
      device: 'speaker',
      direction: 'inbound',
      stream: remoteStream,
      volume: persistentSettings?.speakerVolume,
      muted: useSelector(state => state.webRTC.participants.remote.muted),
      autoMuted: useSelector(state => state.ui.speakerAutoMute),
    },
  ];

  const handleChange = updates => {
    // process the updates
    // (expect one at a time, but handle multiple)
    for (const [key, value] of Object.entries(updates)) {
      switch (key) {
        case 'micVolume':
          setMicVolume(value); // manipulate stream
          break;
        case 'speakerVolume':
          setSpeakerVolume(value);
          break;
        case 'halfDuplex':
          break;
      }
      dispatch(uiActions.setLiveCallAudioSettings(updates)); // remember persistent value
    }
  };

  const renderBox = (name, label) => (
    <FormControlLabel
      style={{ width: '100%' }}
      control={
        <Checkbox
          classes={{ root: classes.checkbox }}
          checked={persistentSettings[name]}
          onChange={e => handleChange({ [e.target.name]: e.target.checked })}
          name={name}
        />
      }
      label={label}
    />
  );

  return enable ? (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      style={{ marginBottom: 20 }}
    >
      {/* microphone and speaker volume  */}
      {mediaItems.map(i => (
        <Grid item key={i.direction}>
          <DeviceManager mediaItem={i} onChange={handleChange} />
        </Grid>
      ))}

      {/* echo suppression */}
      <Grid item>{renderBox('halfDuplex', t('Suppress_echo'))}</Grid>

      {/* Voice Activity Detector table*/}
      {dev && (
        <Grid item>
          {' '}
          <TalkStatus />{' '}
        </Grid>
      )}

      {dev && (
        <Grid item container justify="flex-start">
          {' '}
          <RTCQualityReport />{' '}
        </Grid>
      )}
    </Grid>
  ) : null;
};
export default CallSettings;
