// English phrase translation map

const sp_trans = {
  T$_Country: 'País',
  T$_About_You: 'Acerca de ti',
  T$_Accept: 'Aceptar',
  T$_Accept_all_Terms_and_Conditions:
    'Aceptar todos los términos y condiciones',
  T$_Accept_Call: 'Aceptar llamada',
  T$_Acknowledge: 'Aceptar',
  T$_Advertisement: 'Anuncio',
  T$_Age: 'Edad',
  T$_Age_Range: 'Rango de edad',
  T$_Approaching_maximum_record_time: '¡Acercándose al tiempo récord máximo!',
  T$_Attributes: 'Atributos',
  T$_Authenticating: 'Autenticando...',
  T$_Authentication_Method: 'método de autentificación',
  T$_authentication_Page: 'Página de autenticación',
  T$_bad_sound_quality: 'mala calidad de sonido',
  T$_Beta_Test: 'Prueba Beta',
  T$_blob: 'gota',
  T$_Block: 'Bloquear',
  T$_BLOCK: 'BLOQUEAR',
  T$_boring: 'esto es aburrido',
  T$_Call: 'Llamar',
  T$_Cancel: 'Cancelar',
  T$_Cancel_Call: 'Cancelar llamada',
  T$_Carousel: 'Carrusel',
  T$_character_limit: 'límite de caracteres.',
  T$_checked: 'comprobado',
  T$_Choose_a_screen_name: 'Elige un nombre de pantalla',
  T$_Clear_Filters: 'Borrar filtros',
  T$_close: 'cerca',
  T$_Close: 'Cerca',
  T$_Confirm_Password: 'confirmar Contraseña',
  T$_Congratulations_NEW_MEMBER_You_can_now_proceed_with_your_recording:
    '¡Felicitaciones NUEVO MIEMBRO! Ahora puede continuar con su grabación.',
  T$_Connect: 'Conectar',
  T$_Connected: 'Conectado',
  T$_Contact_and_Support_Request: 'Solicitud de contacto/soporte',
  T$_controls: 'controles',
  T$_Copy_Link: 'Copiar link',
  T$_Create_OkSayit_Password: 'Crear contraseña OkSayit',
  T$_Create_Password: 'Crear contraseña',
  T$_Create_Username: 'Crear nombre de usuario',
  T$_create_User: 'crear usuario',
  T$_Decline: 'Rechazar',
  T$_Decline_Call: 'Rechazar llamada',
  T$_decrement: 'decremento',
  T$_defaults: 'predeterminados',
  T$_Delete: 'Borrar',
  T$_DELETE: 'BORRAR',
  T$_Direct_Message: 'Mensaje directo',
  T$_Dismiss: 'Despedir',
  T$_Email: 'Correo electrónico',
  T$_Enable: 'Activar',
  T$_Ended: 'Terminado',
  T$_Enter_Your_Code: 'Ingrese su código',
  T$_Error: '¡Error!',
  T$_example: 'ejemplo',
  T$_Exit: 'Salida',
  T$_file: 'archivo',
  T$_Filters: 'filtros',
  T$_First_Time_Registration: 'Registro por primera vez',
  T$_Francophone_Anglophone_Conversation_Exchange:
    'Intercambio de conversación français-anglais',
  T$_gender: 'género',
  T$_Gender: 'Género',
  T$_General_Discussion: 'Discusión General',
  T$_getAttributes: 'obtener atributos',
  T$_Get_Invitation: 'Obtener invitación',
  T$_Great_your_phone_is_verified: '¡Genial, tu teléfono está verificado!',
  T$_Hangup: 'Terminar',
  T$_I_agree_to_the_terms_and_conditions:
    'Estoy de acuerdo con los términos y condiciones',
  T$_increase: 'aumentar',
  T$_increment: 'incremento',
  T$_Info: 'Información',
  T$_Initial_Registration: 'Registro inicial',
  T$_Inquiry: 'Una pregunta',
  T$_invitation: 'invitación',
  T$_Invitation: 'Invitación',
  T$_Invitation_Code: 'código de invitación',
  T$_It_should_only_take_a_few_seconds: 'Debería tardar unos segundos.',
  T$_Join: 'Unirse',
  T$_Language: 'Idioma',
  T$_Like: 'Gusta',
  T$_lines: 'líneas',
  T$_Live_Call_Invitation: 'Invitación de llamada en vivo',
  T$_Login: 'Iniciar sesión',
  T$_Login_RegisterMessage_Type: 'Iniciar sesión / Registrarse',
  T$_Login_Now: 'Inicia sesión ahora',
  T$_Logout: 'Cerrar sesión',
  T$_Main_Menu: 'Menú principal',
  T$_Meetup_username: 'Nombre de usuario de Meetup',
  T$_Meetup_Username: 'Nombre de usuario de Meetup',
  T$_message: 'mensaje',
  T$_Message_Notification: 'Notificación de mensajes',
  T$_Message_Type: 'Tipo de mensaje',
  T$_The_recorder_will_start_automatically_next_time:
    'La grabadora se iniciará automáticamente la próxima vez.',
  T$_More: 'Más',
  T$_More_Info: 'Más información',
  T$_My_Profile: 'Mi perfil',
  T$_Native_Language: 'Lengua materna',
  T$_Next: 'Próximo',
  T$_Notification: 'Notificación',
  T$_now: 'ahora',
  T$_OK: 'DE ACUERDO',
  T$_OkSayit_password: 'contraseña de OkSayit',
  T$_open: 'abierto',
  T$_Open: 'Abierto',
  T$_other_inappropriate_content: 'otro contenido inapropiado',
  T$_Partner_Preference: 'Preferencia de socio',
  T$_Password: 'Contraseña',
  T$_Pause: 'Pausa',
  T$_Phone: 'Teléfono',
  T$_Play: 'Jugar',
  T$_Playlist: 'lista de reproducción',
  T$_position: 'posición',
  T$_Previous: 'Anterior',
  T$_Profile_for: 'Perfil para',
  T$_Profile_Recording: 'Grabación de perfil',
  T$_progress: 'progreso',
  T$_Public_Comment: 'Comentario público',
  T$_Public_Post: 'Publicación pública',
  T$_Public_Screen_Name: 'Nombre de pantalla pública',
  T$_Record: 'Registro',
  T$_Recorder_Start: 'Inicio de la grabadora',
  T$_Register: 'Unirse',
  T$_Regrets: 'Remordimientos',
  T$_Regrets_Message: 'Mensaje de arrepentimiento',
  T$_Request: 'Pedido',
  T$_Residency: 'Lugar de residencia',
  T$_Respond: 'Responder',
  T$_Returning_User: 'Usuario recurrente',
  T$_Review: 'Revisar',
  T$_Ringing: 'El sonar',
  T$_rude: 'brusco',
  T$_Screen_Name: 'Nombre de pantalla',
  T$_Select_method: 'Seleccionar método',
  T$_Sending: 'Enviando...',
  T$_Sent_cart_data: 'Enviado datos del carrito!',
  T$_Setting_up_your_account: 'Configurando tu cuenta',
  T$_sexual_content: 'contenido sexual',
  T$_Share: 'Compartir',
  T$_Skill: 'Habilidad',
  T$_Skill_Level: 'Nivel de habilidad',
  T$_Stop: 'Detener',
  T$_Submit: 'Guardar cambios',
  T$_Success: 'Éxito...',
  T$_Suggestion: 'Sugerencia',
  T$_Support_Request: 'Solicitud de soporte',
  T$_Terms_and_Conditions: 'Términos y condiciones',
  T$_test: 'prueba',
  T$_Thank_you_for_regestering_you_are_logged_in:
    'Gracias por registrarte, ¡has iniciado sesión!',
  T$_There_is_a: 'Hay un',
  T$_There_was_a_problem_saving_your_recording:
    'Hubo un problema al guardar su grabación.',
  T$_This_is_an_alert: 'esta es una alerta',
  T$_Tip: 'Consejo',
  T$_title: 'nombre',
  T$_toggle: 'palanca',
  T$_Toggle_Counter: 'Contador de palanca',
  T$_Topic: 'Tema',
  T$_unMark: 'desmarcar',
  T$_User_ID: 'ID de usuario',
  T$_Username: 'Nombre de usuario',
  T$_Validate: 'Validar',
  T$_Visit: 'Visita',
  T$_visualizer: 'visualizador',
  T$_Voice_Profile: 'Perfil de voz',
  T$_We_are_texting_a_code_to: 'Estamos enviando un código de texto a',
  T$_You_are_logged_in: '¡Estás conectado!',
  T$_Your_invitation_is_approved: 'Su invitación está aprobada.',
  T$_Your_Message_is_cancelled: 'Su mensaje está cancelado.',
  T$_Your_name_cannot_include: 'Tu nombre no puede incluir',
  T$_Your_Primary_Language: 'Tu idioma principal',
  T$_Your_Public_Identification: 'Su identificación pública',
  T$_Your_recording_has_been_downloaded: 'Su grabación ha sido descargada.',
  T$_Your_recording_is_cancelled: 'Su grabación está cancelada.',
  T$_Your_recording_is_short: '¡Tu grabación es corta!',
  T$_Your_Voice_Profile_for_this_Community:
    'Su perfil de voz para esta comunidad',
  T$_Subject: 'Sujeto',
  T$_About_Yourself: 'Acerca de ti mismo',
  T$_Message_Title: 'Título del mensaje',
  T$_Enter_a_Headline_about_you: 'Introduce algo de información sobre ti',
  T$_Please_enter_a_Headline_about_you:
    'Introduce algo de información sobre ti',
  T$_Please_enter_a: 'Por favor ingrese un',
  T$_Creating_a_Message: 'Crear un mensaje',
  T$_Direct: 'Mensaje directo',
  T$_Live_Call: 'Llamada en vivo',
  T$_Profile: 'Perfil',
  T$_Message: 'Mensaje',

  T$_Accept_this_invitation_to_talk_live:
    'Acepta esta invitación para hablar en vivo.',
  T$_Bookmark_the_author_of_this_message: 'Marcar el autor de este mensaje.',
  T$_Cancel_this_action: 'Cancele esta acción.',
  T$_Commence_playback: 'Comience la reproducción.',
  T$_Decline_this_invitation_to_talk_live:
    'Rechaza esta invitación para hablar en vivo.',
  T$_Delete_this_message: 'Eliminar este mensaje.',
  T$_End_this_live_conversation: 'Termina esta conversación en vivo.',
  T$_Invite_this_user_to_talk_live: 'Invita a este usuario a hablar en vivo.',
  T$_More_Actions: 'Mas acciones.',
  T$_Obtain_a_link_to_this_message_that_can_be_pasted_to_social_media:
    'Obtenga un enlace a este mensaje que se puede pegar en las redes sociales.',
  T$_Pause_playback: 'Pausa la reproducción.',
  T$_Record_a_voice_response_to_decline_this_invitation_to_talk_live:
    'Grabe una respuesta de voz para rechazar esta invitación para hablar en vivo.',
  T$_Record_a_voice_response_to_this_message:
    'Grabe una respuesta de voz a este mensaje.',
  T$_Send_a_direct_message_to_this_member:
    'Envía un mensaje directo a este miembro.',
  T$_Remove_bookmark: 'Eliminar marcador.',
  T$_Show_this_information_dialog:
    'Mostrar este cuadro de diálogo de información.',
  T$_spacer: 'espaciador',
  T$_Block_all_messages_from_this_person:
    'Bloquea todos los mensajes de esta persona.',

  T$_Comment: 'Comentario',
  T$_Create_Your_Community_Profile_for: 'Cree su perfil comunitario para',
  T$_Public: 'Público',
  T$_Save: 'Ahorrar',
  T$_Send: 'Enviar',
  T$_to: 'a',
  T$_Creating_a: 'Creando un',
  T$_READY: 'LISTO',
  T$_Message_Recorder: 'Grabador de mensajes',
  T$_Download: 'Recibir archivo',
  T$_Please_login_to_save_your_message:
    'Por favor inicie sesión para guardar su mensaje',
  T$_Please_record_at_least: 'Por favor registre al menos',
  T$_sec: 'segundo.',
  T$_cannot_be_created: 'no se puede crear',
  T$_You_must_Login_to_save_this_message:
    'Debe iniciar sesión para guardar este mensaje',
  T$_Problems: 'Problemas',
  T$_Message_Player: 'Reproductor de mensajes',
  T$_Action_Options: 'Opciones de acción',
  T$_The_following_actions_are_available_for_this_message:
    'Las siguientes acciones están disponibles para este mensaje',
  T$_not_all_options_are_available_for_every_message:
    'no todas las opciones están disponibles para todos los mensajes',

  T$_Browse: 'Navegar',
  T$_Discuss: 'Conversar',
  T$_Listen: 'Escuchar',
  T$_Members: 'miembros',
  T$_Settings: 'Ajustes',
  T$_Talk: 'Hablar',

  T$_What_is_your_skill_level_in_the_language_you_wish_to_learn:
    '¿Cuál es su nivel de habilidad en el idioma que desea aprender?',
  T$_What_is_your_age_range: 'Cuál es tu rango de edad?',
  T$_What_is_your_gender: '¿Cuál es su género?',
  T$_No_Preference: 'Sin preferencias',
  T$_profile: 'perfil',
  T$_Advanced: 'Avanzado',
  T$_Intermediate: 'Intermedio',
  T$_Beginner: 'Principiante',
  T$_Over_40: 'Mayores 40',
  T$_Under_40: 'menos de 40',
  T$_Female: 'Femenino',
  T$_Male: 'Masculino',
  T$_Anglophone: 'anglófono',
  T$_Francophone: 'francófono',
  T$_sms: 'SMS',

  T$_password: 'contraseña',
  T$_google: 'Google',
  T$_facebook: 'Facebook',

  T$_My_Memberships: 'Mis membresías',
  T$_Your_Memberships: 'Tus Membresías',
  T$_Profile_For: 'Perfil para',
  T$_Confirm: 'Confirmar',
  T$_Community_Profile_Recording: 'Grabación del perfil de la comunidad',

  T$_Login_with_Google: 'Iniciar sesión con Google',
  T$_Login_with_Facebook: 'Iniciar sesión con Facebook',
  T$_Login_with_Email: 'Iniciar sesión con correo electrónico',
  T$_Login_with_Phone: 'Iniciar sesión con el teléfono',
  T$_Username_Password: 'Nombre de usuario y contraseña',
  T$_Login: 'Acceso',
  T$_Setting_Up: 'Configurando...',
  T$_Awaiting_Google: 'En espera de Google',
  T$_Please_Record: 'por favor grabe',
  T$_Community_Members: 'Miembros de la comunidad',

  T$_from: 'de',
  T$_by: 'por',
  T$_post: 'correo',
  T$_tip: 'consejo',
  T$_ad: 'anuncio',
  T$_notice: 'aviso',
  T$_comment: 'comentario',
  T$_years: 'años',
  T$_months: 'meses',
  T$_days: 'días',
  T$_hours: 'horas',
  T$_minutes: 'minutos',
  T$_seconds: 'segundos',
  T$_ago: 'atrás',
  T$_Delete_This_Message: 'Eliminar este mensaje',
  T$_This_message: 'Este mensaje',
  T$_and_all_of_its_responses: 'y todas sus respuestas',
  T$_will_be_permanently_deleted: 'será eliminado permanentemente!',
  T$_You_cannot_delete_this_message: 'No puedes borrar este mensaje',
  T$_Personal: 'Personal',
  T$_no_messages: 'Ningún mensaje',
  T$_in_this_category: 'en esta categoría',

  T$_Share_This_Message: 'Comparte este mensaje',
  T$_Use_the_button_to_copy_a_link_to_this_message:
    'Use el botón para copiar un enlace a este mensaje',
  T$_Paste_it_to_social_media_or_email:
    'Pégalo en las redes sociales o en el correo electrónico',
  T$_Bookmarking: 'Marcadores',
  T$_Bookmarked_members_will_appear_with_a:
    'Los miembros marcados aparecerán con un',
  T$_Blocking: 'Bloqueo',
  T$_because: 'porque',
  T$_You_will_no_longer_hear_messages_from: 'Ya no escucharás mensajes de',
  T$_Information: 'Información',
  T$_Indicate_that_you_like_the_author_of_this_message:
    'Indica que te gusta el autor de este mensaje',
  T$_Block_all_messages_from_this_author:
    'Bloquear todos los mensajes de este autor',
  T$_Account: 'Cuenta',
  T$_Preferences: 'Preferencias',
  T$_Available_Communities: 'Comunidades Disponibles',
  T$_No_Available_Communities: 'No hay comunidades disponibles',
  T$_Switch: 'Cambiar',
  T$_General_Group: 'Grupo general',
  T$_Language_Group: 'Grupo de idiomas',
  T$_Local_Interest: 'Grupo de interés local',

  T$_Cannot_Bookmark: 'No se puede agregar marcador',
  T$_You_must_be_logged_in_to_set_a_bookmark:
    '¡Debes iniciar sesión para establecer un marcador!',
  T$_You_must_be_logged_in_to_block_a_user:
    'Debes iniciar sesión para bloquear a un usuario',
  T$_This_user_is_already_bookmarked: 'Este usuario ya está marcado',

  T$_You_cannot_apply_a_bookmark_to_yourself:
    'No puedes aplicarte un marcador a ti mismo',
  T$_You_cannot_block_yourself: 'No puedes bloquearte',
  T$_This_user_is_already_blocked: 'Este usuario ya está bloqueado',
  T$_Please_select_the_reason_for_blocking: 'Seleccione el motivo del bloqueo',

  T$_bookmarked: 'marcado',
  T$_blocked: 'obstruido',
  T$_Cannot_Block: 'no se puede bloquear',

  T$_Applying_Bookmark: 'Aplicación de marcador',
  T$_Updating_Blacklist: 'Actualización de la lista negra',

  T$_Top: 'Arriba',
  T$_Bottom: 'Abajo',
  T$_Display_Language: 'Idioma de la pantalla',

  T$_English: 'Inglés',
  T$_Spanish: 'Español',
  T$_French: 'Francés',
  T$_Lithuanian: 'lituano',

  T$_This_device_has_been_logged_out_because_you_have_logged_in_at_another_location:
    'Se ha cerrado la sesión de este dispositivo porque ha iniciado sesión en otra ubicación.',

  T$_Saving_your_message: 'Guardando tu mensaje...',
  T$_Message_Saved: 'Mensaje guardado',

  T$_Saved: 'Salvado',
  T$_Sent: 'Enviado',
  T$_It_will_appear_in_XXX_playlist:
    'Aparecerá en la lista de reproducción de XXX. También puede copiar un enlace y enviarlo por correo electrónico a XXX si lo desea. Simplemente use el botón a continuación para obtener un enlace.',
  T$_It_will_appear_in_the_public_playlist:
    'Aparecerá en la lista de reproducción pública.',
  T$_You_can_also_copy_and_paste_it_to_your_social_media_just_use_the_button_below_to_get_a_link:
    'También puede copiarlo y pegarlo en sus redes sociales, solo use el botón a continuación para obtener un enlace.',

  T$_Your: 'Su',
  T$_has_been: 'ha sido',
  T$_It_will_be_delivered_to_management: 'Se entregará a la gerencia.',

  T$_You_have_blocked_use_of_your_microphone:
    'Ha bloqueado el uso de su micrófono.',
  T$_Your_browser_is_blocking_use_of_your_microphone_Use_your_browser_Settings_to_correct_this_issue:
    'Su navegador está bloqueando el uso de su micrófono. Use la configuración de su navegador para corregir este problema.',
  T$_Please_try_again_Approve_use_of_your_microphone_to_continue:
    'Inténtalo de nuevo. Aprueba el uso de tu micrófono para continuar.',
  T$_There_was_a_problem_obtaining_permission_to_use_your_microphone:
    'Hubo un problema al obtener permiso para usar su micrófono.',
  T$_Processing_Community_membership:
    'Procesando pertenencia a la comunidad...',
  T$_Your_Contact_Info: 'Tu información de contacto',
  T$_Please_Join_This_Community_In_Order_To_Save_Your_Message:
    '¡Únase a esta comunidad para guardar su mensaje!',
  T$_There_is_a: 'Hay un',
  T$_character_limit: 'límite de caracteres',
  T$_auto_start: 'autoencendido',
  T$_Auto_Start: 'Autoencendido',

  T$_Live_Call_invitation_from: 'Invitación de llamada en vivo de',
  T$_You_can_connect_now: 'Puedes conectarte ahora',
  T$_Call_Time: 'Tiempo de llamada',
  T$_PROBLEM: 'PROBLEMA',
  T$_accepted: 'aceptado',
  T$_Calling: 'Comenzando su llamada',
  T$_Invite_from: 'invitar desde',
  T$_Live_Call_Invites: 'Invitaciones de llamadas en vivo',
  T$_Live_Call_with: 'Llamada en vivo con',
  T$_to_accept: 'aceptar…',
  T$_has_accepted_your_call: 'ha aceptado su llamada.',
  T$_Regrets_Message: 'Mensaje de arrepentimiento:',
  T$_declined_your_invitation: 'rechazó su invitación.',
  T$_This_invitation_cannot_go_through_right_now:
    'Esta invitación no se puede procesar en este momento.',
  T$_Your_party_may_no_longer_be_available:
    'Es posible que tu fiesta ya no esté disponible.',
  T$_Invitation_Message: 'Mensaje de invitación:',
  T$_You_have_accepted_this_call: 'Ha aceptado esta llamada.',
  T$_Waiting_for: 'Esperando',
  T$_to_connect: 'para conectar...',
  T$_Your_call_has_ended: 'Su llamada ha terminado.',

  T$_was_calling_you: 'te estaba llamando...',
  T$_This_call_cannot_connect_right_now:
    'Esta llamada no se puede conectar en este momento.',
  T$_Live_Call_Invitations: 'Invitaciones de llamadas en vivo',
  T$_Live_Call_Status: 'Estado de la llamada en vivo',
  T$_This_call_is_cancelled: 'Esta llamada está cancelada...',

  T$_Your_device_disconnected: 'Su dispositivo se desconectó.',
  T$_XXXs_device_disconnected: 'El dispositivo de XXX se desconectó.',

  T$_Live_Call_accepted_by: 'Llamada en vivo aceptada por',
  T$_Live_Call_declined_by: 'Llamada en vivo rechazada por',
  T$_inquiry_to_ad: 'consulta al anuncio',

  T$_You_have_received_a_Live_Call_invitation:
    'Ha recibido una invitación de Live Call.',
  T$_A_new_message_has_arrived_Just_for_you:
    "Ha llegado un nuevo mensaje 'Solo para ti'.",

  T$_Inbox: 'Bandeja de entrada',
  T$_Chat: 'Charlar',
  T$_Less: 'Menos',
  T$_This_Community: 'esta comunidad',

  T$_Adult: 'Adulto',
  T$_Available_to_Registered_Users: 'Disponible para Usuarios Registrados',
  T$_Best_practices_get_the_most_out_of_the_Service:
    'Las mejores prácticas aprovechan al máximo el Servicio',
  T$_Business: 'Negocio',
  T$_Community_Selection: 'Selección de la comunidad',
  T$_Connect_with_like_minded_strangers:
    'Conéctese con extraños de ideas afines',
  T$_Features: 'Características',
  T$_General: 'General',
  T$_Language_Practice_EN_DE: 'Práctica de idiomas - EN / DE',
  T$_Language_Practice_EN_LT: 'Práctica de idiomas - EN / LT',
  T$_Lexington_Residency: 'Residencia de Lexington',
  T$_Lexington_Senior_Meetingplace:
    'Lugar de encuentro para personas mayores de Lexington',
  T$_Local_Interest: 'Interés Local',
  T$_Member_List: 'Lista de miembros',
  T$_Other: 'Otro',
  T$_Overview: 'Descripción general',
  T$_Practice_your_language_skills: 'Practica tus habilidades lingüísticas',
  T$_Recommendations: 'Recomendaciones',
  T$_Resident: 'Residente',
  T$_Senior: 'Sénior',
  T$_To_get_the_most_out_of_the_Service:
    'Para aprovechar al máximo el Servicio',
  T$_Visitor: 'Visitante',
  T$_Welcome_To_OkSayit: 'Bienvenido a OkSayit',

  T$_Youth: 'Juventud',

  T$_Communities: 'Comunidades',

  T$_Inbox_for: 'Bandeja de entrada para',
  T$_Empty: 'Vacío',
  T$_From: 'De',
  T$_Your_Messages: 'Tus mensajes',
  T$_General_Chat_Group: 'Grupo de chat general',
  T$_SMS_Text_Code: 'Código de texto SMS',
  T$_Enter_your_MOBILE_number: 'Ingrese su numero celular',
  T$_Mobile_phones_only:
    'Solo teléfonos móviles. Se pueden aplicar tarifas de mensajes.',
  T$_Enter_the_code_we_sent_to: 'Introduce el código que te enviamos',
  T$_Incorrect_Validation_Code: '¡Código de validación incorrecto!',
  T$_Please_check_the_code_we_sent:
    'Verifique el código que le enviamos e ingréselo aquí cuidadosamente.',

  T$_Logging_in_with_mobile_text: 'Iniciar sesión con texto móvil',
  T$_Your_okSayit_account_verification_code_is:
    'El código de verificación de su cuenta okSayit es',
  T$_is_not_a_valid_phone_number: 'no es un número de teléfono válido!',
  T$_Please_enter_a_valid_phone_number:
    'Por favor ingrese un número de teléfono válido.',
  T$_is_not_a_US_or_Canada_phone_number:
    'no es un número de teléfono de EE. UU. o Canadá.',
  T$_Please_enter_a_US_or_Canada_phone_number:
    'Ingrese un número de teléfono de EE. UU. o Canadá.',
  T$_is_not_a_mobile_phone_in_France: 'no es un teléfono móvil en Francia!',
  T$_Please_enter_a_mobile_number_in_France:
    'Introduce un número de móvil de Francia.',
  T$_About_Me: 'Acerca de mí',
  T$_For_Each_Membership_You_Can_Adjust_Your_Profile:
    'Para Cada Membresía Puede Ajustar Su Perfil',
  T$_Logging_Out: 'Saliendo de tu cuenta...',
  T$_Welcome: 'Bienvenido',
  T$_Update_Your_Profile_Recording: 'Actualice la grabación de su perfil',

  T$_What_age_range_do_you_want_to_speak_with:
    '¿Con qué rango de edad quieres hablar?',
  T$_What_gender_do_you_want_to_speak_with: '¿Con qué género quieres hablar?',
  T$_What_languages_do_you_want_to_speak_with_Check_all_that_apply:
    '¿Con qué idiomas quieres hablar? (Marque todo lo que corresponda)',
  T$_What_residency_do_you_want_to_speak_with_Check_all_that_apply:
    '¿Con qué residencia quieres hablar? (Marque todo lo que corresponda)',
  T$_What_skill_level_do_you_want_to_speak_with_Check_all_that_apply:
    '¿Con qué nivel de habilidad quieres hablar? (Marque todo lo que corresponda)',

  T$_Member_Gender: 'Género del miembro',
  T$_Member_Age_Range: 'Rango de edad de los miembros',
  T$_Member_Language: 'Idioma miembro',
  T$_Member_Native_Language: 'Idioma nativo del miembro',
  T$_Member_Residency: 'Residencia de miembros',
  T$_Member_Skill_in_Second_Language: 'Habilidad de miembro en segundo idioma',
  T$_Mute: 'Silenciar',

  T$_Leave_This_Community: 'Abandonar esta comunidad',
  T$_If_you_leave:
    '¡Si te vas, tu perfil y todas tus grabaciones en esta comunidad serán borradas!',
  T$_Stay: 'Permanecer',
  T$_Leave: 'Dejar',
  T$_Suppress_echo: 'Suprimir eco',
  T$_You_are_calling: 'Llamada en vivo a...',
  T$_is_calling_you: 'te está llamando',
  T$_Hide: 'Esconder',

  T$_You: 'Tú',
  T$_hung_up: 'terminar.',
  T$_cancelled_this_call: 'canceló esta llamada.',
  T$_declined_this_invitation: 'rechazó esta invitación.',

  T$_Text_Invitation: 'Invitación de texto',
  T$_Send_Text_Invitation: 'Enviar invitación de texto',
  T$_Send_Voice_Invitation: 'Enviar invitación de voz',
  T$_Bookmark_this_person: 'Marcar a esta persona',
  T$_public: 'público',
  T$_direct: 'personal',
  T$_foul_language: 'lenguaje soez',
  T$_Public_Posts: 'Mensajes Públicos',

  T$_Member_Profile_Information: 'Información de perfil de miembro',
  T$_language: 'idioma',
  T$_ageRange: 'rango de edad',
  T$_skill: 'habilidad',
  T$_Logging_in_with_Facebook: 'Iniciar sesión con Facebook',
  T$_Facebook_login_not_available: 'Inicio de sesión de Facebook no disponible',
  T$_Facebook_login_cannot_connect:
    'El inicio de sesión de Facebook no puede conectarse',
  T$_Facebook_dialog_closed_by_user:
    'Diálogo de Facebook cerrado por el usuario',
  T$_Facebook_login_failed: 'No se pudo iniciar sesión en Facebook',
  T$_Awaiting_Facebook: 'Esperando facebook',
  T$_Logging_in_with_Google: 'Iniciar sesión con Google',
  T$_Profile_not_available: 'Perfil no disponible',
  T$_No_profile: 'Sin perfil',
  T$_Google_dialog_closed_by_user:
    'Cuadro de diálogo de Google cerrado por el usuario',
  T$_Google_popup_blocked: 'Ventana emergente de Google bloqueada',
  T$_Authentication_Problem: 'Problema de autenticacion',
  T$_Registration: 'Matriculación',
  T$_You_Cant_Learn_To_Speak: '¡No se puede aprender a hablar sin hablar!',

  T$_Authenticating_now: 'Autentificando ahora...',
  T$_Carrier_charges_may_apply: 'Se pueden aplicar cargos.',
  T$_Daily_Limit: 'Límite diario',
  T$_Direct_DM: 'Directo (DM)',
  T$_Direct_Reply: 'Respuesta directa',
  T$_Disable: 'Desactivar',
  T$_Disabled: 'Desactivado',
  T$_Enabled: 'Activado',
  T$_from_your_mobile_phone: 'desde tu teléfono móvil.',
  T$_German: 'Alemán',
  T$_Logging_you_in: 'Iniciando sesión...',
  T$_Messages_will_be_sent_from_the_number:
    'Los mensajes se enviarán desde el número',
  T$_Method: 'Método',
  T$_Mobile_Phone: 'Teléfono móvil',
  T$_Notifications_Settings: 'Configuracion de notificaciones',
  T$_Please_Accept_Cookies_Before_Continuing:
    '¡Acepte las cookies antes de continuar!',
  T$_Please_Select: 'Seleccione',
  T$_Please_Wait: 'Espere por favor',
  T$_Public_Response: 'Respuesta pública',
  T$_Receive_Notifications_When_Messages_Arrive_For_You:
    'Reciba notificaciones cuando le lleguen mensajes',
  T$_Record_a_new_message: 'Grabar un nuevo mensaje.',
  T$_Reply: 'Responder',
  T$_Required: 'Requerido',
  T$_Respond_to_this_message: 'Responde a este mensaje.',
  T$_Sending: 'Enviando',
  T$_Status: 'Estado',
  T$_Stopped: 'Interrumpido',
  T$_Text: 'Texto',
  T$_START: 'COMENZAR',
  T$_To_enable_Notifications: 'Para habilitar las notificaciones',
  T$_Updating_your_preferences: 'Actualizando sus preferencias...',
  T$_When_you_enable_Notifications: 'Cuando habilita las notificaciones:',
  T$_You_can_revoke_approval:
    "Puede revocar la aprobación en cualquier momento utilizando este formulario o respondiendo 'Detener' a cualquier notificación.",
  T$_You_give_approval_for: 'Tú das tu aprobación para',
  T$_to_send_notification_messages_to_your_phone:
    'para enviar mensajes de notificación a su teléfono.',
  T$_Maybe_Later: 'Quizas mas tarde',
  T$_Stop_Asking: 'Para de preguntar',
  T$_Notifications: 'Notificaciones',

  T$_Direct_Messages: 'Mensajes directos',
  T$_Inbox_View: 'Vista de bandeja de entrada',
  T$_Side: 'Lado',
  T$_Recorder: 'Grabadora',
  T$_Player: 'Jugador',
  T$_Retrieving_Messages: 'Recuperando mensajes...',
  T$_You_Must_Login_To_See_Your_Messages:
    'Debe iniciar sesión para ver sus mensajes',
  T$_Dont_Show_Again: 'No volver a mostrar',
  T$_It_is_also_in_your_clipboard: 'También está en tu portapapeles',
  T$_Your_message_will_be_delivered_to_management:
    'Su mensaje será entregado a la gerencia',
  T$_invitation: 'invitación',
  T$_message: 'mensaje',
  T$_Message: 'Mensaje',
  T$_Saved: 'Salvado',
  T$_Sent: 'Enviado',
  T$_Your: 'Su',
  T$_has_been: 'ha sido',
  T$_You_can: 'Puede',
  T$_paste_it: 'pégalo',
  T$_to_email: 'al correo electrónico',
  T$_to_social_media_or_email: 'a las redes sociales o al correo electrónico',
  T$_Sleeping: 'Durmiendo',
  T$_Wake_Up: 'Despertar',
  T$_Delete_Your_Archive: 'Eliminar su archivo',
  T$_All_Messages_In_This_List: 'Todos los mensajes en esta lista',
  T$_New: 'Nuevo',
  T$_Archive: 'Archivo',
  T$_latest: 'el último',
  T$_No_Message_Specified: 'Ningún mensaje especificado',
  T$_Sorry_cannot_find_that_message:
    'Lo siento, no puedo encontrar ese mensaje. Es posible que haya caducado o que se haya eliminado.',
  T$_Access_denied: 'Acceso denegado, este mensaje no va dirigido a ti',
  T$_This_message_is_addressed_to:
    'No has iniciado sesión. Este mensaje está dirigido a',
  T$_If_you_are: 'Si usted es',
  T$_please_login_to_play_it: 'por favor inicia sesión para jugarlo',
  T$_Message_Link_Copied_to_Clipboard:
    'Enlace del mensaje copiado al portapapeles',
  T$_Set_Up: 'Configurar',
  T$_Problem: 'Problema',

  T$_is_busy_cant_speak: 'ocupado y no puedo hablar en este momento.',
  T$_Start_recording_immediately:
    'Comience a grabar inmediatamente cuando se abra Recorder.',
  T$_Start_playback_immediately:
    'Inicie la reproducción de audio inmediatamente cuando se seleccione un elemento.',
  T$_Connect_immediately:
    'Conéctese inmediatamente con la voz cuando alguien acepte su invitación.',

  T$_Not_Available: 'No disponible',
  T$_Subscribed: 'suscrito',
  T$_You_will_be_notified_of_DM:
    'Se le notificará cuando lleguen mensajes directos para usted.',
  T$_By_pressing_next_you_agree:
    'Al presionar SIGUIENTE, aceptas recibir un mensaje de texto único con un código de verificación.',
};

export default sp_trans;
