/**
 * TooltipStd
 * Render a <Tooltip /> using hover trigger for desktop
 * Uses click trigger for mobile, controlled by props.selected if it if provided,
 * otherwise local button click
 *
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, ClickAwayListener, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  // style with white shadowed container
  tooltipStd: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function TooltipStd({ item, selected }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const isMobile = Boolean(useSelector(state => state.ui.deviceInfo.mobile));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  // open tooltip for limited duration
  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => setOpen(false), 3000);
  };

  useEffect(() => {
    // open tooltip when it becomes selected
    selected && handleTooltipOpen();
  }, [selected]);

  // render a controlled tooltip
  // ref.: https://v4.mui.com/components/tooltips/#demo-3tt9ujcnysi
  const tooltipClick = () => (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          classes={{ tooltip: classes.tooltipStd }}
          placement="bottom"
          title={item?.legend}
          arrow
          light
        >
          {typeof selected === 'undefined' ? (
            <Button onClick={handleTooltipOpen}>{item?.icon}</Button>
          ) : (
            item?.icon
          )}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );

  // render ordinary tooltip enabled by hover
  const tooltipHover = () => (
    <Tooltip
      enterTouchDelay={10}
      placement="bottom"
      title={item?.legend}
      arrow
      light
      classes={{ tooltip: classes.tooltipStd }}
    >
      {item?.icon}
    </Tooltip>
  );

  const tooltip = () => (isMobile ? tooltipClick() : tooltipHover());

  return item ? tooltip() : null;
}

TooltipStd.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.node,
  }),
  selected: PropTypes.bool,
};

TooltipStd.defaultProps = {
  selected: undefined,
};
