import { useSelector, useDispatch } from 'react-redux'
import { authActions } from 'experiments/ReduxDemo1/store/auth'
import UserProfile from './UserProfile'


const Auth = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)    //sets up a subscription

  const handleLogin = (event) => { 
    event.preventDefault()
    dispatch(authActions.login()) 
  }

  return (
    <main >
      {isAuthenticated
        ? <section>
          <UserProfile />
        </section>
        : <section>
          <form>
            <div >
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' />
            </div>
            <div >
              <label htmlFor='password'>Password</label>
              <input type='password' id='password' />
            </div>
            <button onClick={handleLogin}>Login</button>
          </form>
        </section>
      }


    </main>
  );
};

export default Auth;
