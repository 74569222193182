/**
 * VolumeMeter
 * Display real time audio track amplitude for the specified stream
 * For outbound track, detect talking in progress
 * For inbound track, support dynamic half-duplex
 * //@@consider researching "Acoustic Echo Cancellation", or AEC
 *
 * ref.: https://github.com/webrtc/samples/tree/gh-pages/src/content/getusermedia/volume
 *
 * vadR used as input reference for sample stream
 */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { localStreamProcessor, remoteStreamProcessor } from 'lib/api/webRTCApi';

const useStyles = makeStyles(theme => ({
  vmMeters: {},
  vmInstant: {},
  vmSlow: {},
  vmIndicator: {
    width: '50%',
  },
  vmLabel: {
    display: 'inline-block',
    fontWeight: 400,
    margin: '0 0.5em 0 0',
    width: '3.5em',
  },
  vmValue: {
    display: 'inline-block',
    marginLeft: 10,
  },
}));

const VolumeMeter = ({
  direction,
  classes: classesOverride,
  instantLabel,
  slowLabel,
}) => {
  const [enable, setEnable] = useState(false);
  const [soundMeterSample, setSoundMeterSample] = useState({
    instant: 0,
    slow: 0,
  });

  // override classes to hide or sthyle certain components
  const classes = { ...useStyles(), ...classesOverride };

  const sampleIntervalRef = useRef(null);
  const talkTimeoutRef = useRef(null);
  const getSampleRef = useRef(null); // sampling function is determined when component mounts
  const outbound = direction === 'outbound';

  // initialize sampling source
  useEffect(() => {
    // which stream processor and which vad type?
    const streamProcessor = outbound
      ? localStreamProcessor
      : remoteStreamProcessor;
    const vad = streamProcessor.getVad('Linto');

    if (vad) {
      getSampleRef.current = vad.getSample;
      setEnable(true);

      // commence sampling of the SoundMeter
      const getSample = getSampleRef.current;
      sampleIntervalRef.current = setInterval(() => {
        const instant = getSample();
        const slow = getSample('slow');
        setSoundMeterSample({ instant, slow });
      }, 200);
    } else {
      console.log(`VolumeMeter ${direction} cannot get samples.`);
    }
  }, []);

  // cleanup upon dismount
  useEffect(() => {
    return () => {
      clearInterval(sampleIntervalRef.current);
      clearTimeout(talkTimeoutRef.current);
    };
  }, []);

  const renderMeter = (label, value, containerClass) => (
    <div className={containerClass}>
      <div className={classes.vmLabel}>{`${label}:`}</div>
      <meter
        className={classes.vmIndicator}
        high="0.25"
        max="1"
        value={value}
      ></meter>
      <div className={classes.vmValue}>{value}</div>
    </div>
  );

  return enable ? (
    <div className={classes.vmMeters}>
      {renderMeter(instantLabel, soundMeterSample.instant, classes.vmInstant)}
      {renderMeter(slowLabel, soundMeterSample.slow, classes.vmSlow)}
    </div>
  ) : null;
};

VolumeMeter.propTypes = {
  direction: PropTypes.oneOf('outbound', 'inbound'),
  stream: PropTypes.object.isRequired,
  classes: PropTypes.object,
  instantLabel: PropTypes.string,
  slowLabel: PropTypes.string,
};
VolumeMeter.defaultProps = {
  classes: {},
  instantLabel: 'Instant',
  slowLabel: 'Slow',
};
export default VolumeMeter;
