/**
 * registration helper utilities
 * see config-example.js for pages data-definition
 */

import { makeArray } from 'lib/utils/utils';
import configCommon from 'configs/communities/config-common';
import configGeneral from 'configs/communities/config-general';
import configLexTalk from 'configs/communities/config-lextalk';
import configLangEnFr1 from 'configs/communities/config-lang-en-fr-1';
import configLangEnDe1 from 'configs/communities/config-lang-en-de-1';
import configLangEnLt1 from 'configs/communities/config-lang-en-lt-1';
import configLangEnSp1 from 'configs/communities/config-lang-en-sp-1';
import { t, rt, strToVar, missingTranslationsCnt } from 'lib/translation/trans';
import { getMemberParams } from 'lib/api/communityApi';

// encapsulate helper functions
export const regHelpers = () => {
  // for debug purposes, read directly from source code
  // It is anticipated that these data will be downloaded from community D8 node

  const communityConfig = partition => {
    // get common configuration keys
    let communityConfig = [];

    // append community-specific keys
    switch (partition) {
      case 'lextalk':
        communityConfig = configLexTalk;
        break;
      case 'lang-en-fr-1':
        communityConfig = configLangEnFr1;
        break;
      case 'lang-en-de-1':
        communityConfig = configLangEnDe1;
        break;
      case 'lang-en-lt-1':
        communityConfig = configLangEnLt1;
        break;
      case 'lang-en-sp-1':
        communityConfig = configLangEnSp1;
        break;
      default:
        communityConfig = configGeneral;
        break;
    }

    // configCommon contains an array of pages common to multiple communities
    // communityConfig provides custom pages as will as other optional keys and overrides
    const combined = {
      ...communityConfig,
      pages: [...configCommon.pages, ...communityConfig.pages],
    };
    return combined;
  };

  // all pages, sections and fields possess a canonically unique key
  // scan all pages and sections to find the requested field
  const getField = (key, pAges) =>
    pAges &&
    pAges.reduce(
      (acc1, p) =>
        p.sections.reduce(
          (acc2, s) =>
            s.fields.reduce((acc3, f) => (f.key === key ? f : acc3), acc2),
          acc1
        ),
      null
    );

  // find a field and return its value
  const getFieldValue = (key, pAges) =>
    pAges &&
    pAges.reduce(
      (acc1, p) =>
        p.sections.reduce(
          (acc2, s) =>
            s.fields.reduce(
              (acc3, f) => (f.key === key ? f.value : acc3),
              acc2
            ),
          acc1
        ),
      null
    );

  // scan all pages for the requested section
  const getSection = (key, pAges) =>
    pAges &&
    pAges
      .reduce((acc, p) => [...acc, ...p.sections], [])
      .find(s => s.key === key);

  // get field values from the specified section
  const getSectionValues = (key, pAges) =>
    getSection(key, pAges).fields.reduce(
      (a, f) =>
        f.value !== null && f.value !== undefined
          ? { ...a, [f.key]: f.value }
          : a,
      {}
    );

  // find an item (page, section, or field) with the specified key
  const getItem = (key, pages) => {
    let found = null;

    const locatePageOrSectionOrField = () =>
      !pages ||
      pages.every(p =>
        p.key === key ? ((found = p), false) : locateSectionOrField(p)
      );
    const locateSectionOrField = p =>
      !p.sections ||
      p.sections.every(s =>
        s.key === key ? ((found = s), false) : locateFieldInSection(s)
      );
    const locateFieldInSection = s =>
      !s.fields ||
      s.fields.every(f => (f.key === key ? ((found = f), false) : true));

    locatePageOrSectionOrField();
    return found;
  };

  //diagnostic scan for missing keys in translation table
  const diagCheckMissingTranslations = pages => {
    // extract a list of all keys, all levels within pages
    const getKeys = pages => {
      const fKeys = s => s.fields.reduce((accf, f) => [f.key, ...accf], []);
      const sKeys = p =>
        p.sections.reduce((accs, s) => [s.key, ...fKeys(s), ...accs], []);
      const allKeys = pages.reduce(
        (accp, p) => [p.key, ...sKeys(p), ...accp],
        []
      );
      return allKeys;
    };

    // prepare a list of all titles
    const getTitles = pages => {
      const allTitles = {};

      // insert a field, remove spaces from key
      const ins = title => {
        title && (allTitles[strToVar(title)] = title);
      };

      const fTitles = s => s.fields && s.fields.forEach(f => ins(f.title));
      const sTitles = p =>
        p.sections &&
        p.sections.forEach(s => {
          ins(s.title);
          fTitles(s.fields);
        });
      pages &&
        pages.forEach(p => {
          ins(p.title);
          sTitles(p);
        });
      return allTitles;
    };

    // prepare a list of all helpers
    const getHelpers = pages => {
      const allHelpers = {};

      // insert a field, remove spaces from key
      const ins = helper => {
        helper && (allHelpers[strToVar(helper)] = helper);
      };

      const fHelpers = s => makeArray(s?.fields).forEach(f => ins(f.helper));
      const sHelpers = p => makeArray(p?.sections).forEach(s => fHelpers(s));
      makeArray(pages).forEach(p => sHelpers(p));
      return allHelpers;
    };

    // prepare a list of all string values
    const getValueStrings = pages => {
      const allValues = {};

      // insert a field value, only process strings, remove spaces from its key
      const ins = value => {
        value &&
          typeof value === 'string' &&
          (allValues[strToVar(value)] = value);
      };

      const processValues = f => f.values && f.values.forEach(v => ins(v));
      const processFields = s =>
        s.fields && s.fields.forEach(f => processValues(f));
      const processSections = p =>
        p.sections &&
        p.sections.forEach(s => {
          processFields(s);
        });
      pages &&
        pages.forEach(p => {
          processSections(p);
        });

      return allValues;
    };

    const translatable = {
      ...getTitles(pages),
      ...getValueStrings(pages),
      ...getHelpers(pages),
    };
    console.log('translatable:', translatable);
    console.log(
      'missing translation cnt:',
      missingTranslationsCnt(translatable)
    );
  };

  // translate pages (keys:  title, helper, value, values )
  const translatePages = pages => {
    //diagnostic scan for missing keys in translation table
    //diagCheckMissingTranslations(pages)

    // translate field values
    const tValues = f => f.values && f.values.map(v => t(v));

    // translate field titles and replace values with translated values
    const tFields = s =>
      s.fields &&
      s.fields.map(f => ({
        ...f,
        title: t(f.title),
        helper: t(f.helper),
        value: t(f.value),
        values: tValues(f),
      }));

    // translate section titles and replace fields with translated fields
    const tSections = p =>
      p.sections &&
      p.sections.map(s => ({ ...s, title: t(s.title), fields: tFields(s) }));

    // translate page titles, and replace sections with translated sections
    const tPages =
      pages &&
      pages.map(p => ({ ...p, title: t(p.title), sections: tSections(p) }));

    return tPages;
  };

  // apply the community-specific overrides, if any
  const applyConfigOverrides = (pages, overrides) => {
    const pagesCopy = pages && JSON.parse(JSON.stringify(pages));

    // for each override, find the item in pages
    // replace any key/values specified in the override
    overrides &&
      overrides.forEach(o => {
        const item = getItem(o.key, pagesCopy);
        if (item) {
          for (const [key, value] of Object.entries(o)) {
            item[key] = value;
          }
        }
      });
    return pagesCopy || pages;
  };

  // apply member's stored parameters as initial values of fields
  const applyMemberParams = (pages, partition, member) => {
    const isMember =
      member && makeArray(member.memberships).includes(partition);
    if (isMember) {
      // get member's current param values
      const params = getMemberParams(partition, member);
      if (params) {
        // apply current values
        const pagesI = pages && JSON.parse(JSON.stringify(pages));
        params &&
          Object.entries(params).forEach(([key, val]) => {
            const field = getField(key, pagesI);
            field && (field.value = val);
          });
        return pagesI;
      }
    }
    // for non-members, return uninitialized values
    return pages;
  };

  return {
    communityConfig,
    getField,
    getFieldValue,
    getSection,
    getSectionValues,
    getItem,
    applyConfigOverrides,

    translatePages,
    applyMemberParams,
  };
};

// Roadmap of Pages, Sections, and Fields presented during registration
export class RegPages {
  original = null; // original loaded structure
  eng = null; // english copy, marked with current errors, selected values
  trans = null; // translation of eng
  options = null;
  refresh = () => {}; // replace with a callback upon instantiation

  constructor({ partition, refreshFn, user }) {
    //console.log(`RegPages constructor partition=${partition}, user:`, user)
    const regUtils = regHelpers();

    this.partition = partition;
    this.refresh = refreshFn; // callback to request React page refresh

    // Array of pages rendered during registration workflow.
    // Each page contains array of Sections, each containing array of Fields.
    // The 'userParams' section specifies community-specific
    // attributes to be specified by the user.
    //
    const { pages: pagesRaw, ...optionsRaw } = JSON.parse(
      JSON.stringify(regUtils.communityConfig(partition))
    );
    this.options = optionsRaw;

    const overrides = this.options?.overrides;
    const pagesWithOverrides = overrides
      ? regUtils.applyConfigOverrides(pagesRaw, overrides)
      : pagesRaw;
    const pagesWithInitializedValues = regUtils.applyMemberParams(
      pagesWithOverrides,
      partition,
      user
    );
    this.original = pagesWithInitializedValues;
    this.eng = this.original && JSON.parse(JSON.stringify(this.original));
    this.translate();
  }

  translate = () => {
    // translate to specified language
    const regUtils = regHelpers();
    if (this.eng) {
      this.trans = JSON.parse(
        JSON.stringify(regUtils.translatePages(this.eng))
      );
      this.refresh && this.refresh();
    }
  };

  // update the field value and error flag in both eng and trans
  setFieldValue = ({ key, value }) => {
    const regUtils = regHelpers();

    //console.log(`pg.setField ${key} = ${value} (${rt(value)})`)

    // update english and translated pages
    // first the english
    const fieldE = regUtils.getField(key, this.eng);
    if (fieldE) {
      const vE = rt(value); // reverse translate
      if (fieldE.value !== vE) {
        fieldE.error = false;
        fieldE.value = vE;

        // now process the translated version
        const fieldT = regUtils.getField(key, this.trans);
        if (fieldT) {
          fieldT.error = false;
          fieldT.value = value;
        }
        this.refresh && this.refresh();
      }
    }
  };

  // update (mutate in place) the specified field attributes
  // do NOT force a refresh
  overrideFieldAttributes = ({ pages, fieldKey, attributeOverrides }) => {
    const regUtils = regHelpers();
    const field = regUtils.getField(fieldKey, pages);
    if (field) {
      Object.entries(field);
      field &&
        Object.entries(field).forEach(([key, val]) => {
          if (typeof attributeOverrides[key] !== 'undefined') {
            field[key] = attributeOverrides[key];
          }
        });
    }
  };

  // determine if this.eng has been updated
  diff = () => JSON.stringify(this.eng) !== JSON.stringify(this.original);

  getPage = pageKey => makeArray(this.trans).find(p => p.key === pageKey);
}
