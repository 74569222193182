/**
 * Render a checkbox field
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormGroup, FormControlLabel, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    border: '1px solid rgba(255, 255, 255, 0.23)',
    borderRadius: 4,
    padding: '1rem',
  },
  fieldError: {
    borderColor: theme.palette.error.main,
  },
  heading: {
    color: theme.palette.secondary.main,
  },
}));

const LogRegFieldCheckbox = ({
  field,
  onFieldChange,
  classes: classesOverride,
}) => {
  const classes = { ...useStyles(), ...classesOverride };

  // apply checked status to selected property in values
  const handleChange = event => {
    const { name, checked } = event.target;
    const newValue = { ...field.value, [name]: checked };
    onFieldChange && onFieldChange(field.key, newValue);
  };

  return field ? (
    <div
      key={field.key}
      className={`${classes.fieldContainer} ${
        field.error ? classes.radioError : ''
      }`}
    >
      <Box classes={{ root: classes.heading }}>Check all that apply</Box>
      <FormGroup>
        {field.values.map(val => (
          <FormControlLabel
            control={
              <Checkbox
                disabled={false}
                checked={field.value[val]}
                onChange={handleChange}
                name={val}
              />
            }
            label={val}
          />
        ))}
      </FormGroup>
    </div>
  ) : null;
};

LogRegFieldCheckbox.propTypes = {
  field: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.object, // {checkbox1: {true}, checkbox2: {false}, ... }
    error: PropTypes.bool,
    required: PropTypes.bool,
  }),

  onFieldChange: PropTypes.func, // onFieldChange(key, value)
};

export default LogRegFieldCheckbox;
