/**
 * Render controls for message filtering and searching
 */

import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterMenu from './components/FilterMenu';
import BorderedBlock from 'components/utils/BorderedBlock';
import FilterMenuButton from './components/FilterMenuButton';
import CloseIcon from '@material-ui/icons/Close';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  wrapperInner: {
    position: 'relative',
    border: '1px rgba(255, 255, 255, 0.23) solid',
    borderRadius: '4px',
    margin: '0',
    padding: '0rem 1rem',
    paddingTop: 5,
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const FilterBar = ({ fields, onFieldChange, onClearFilters }) => {
  const classes = useStyles();

  return (
    <BorderedBlock labelTop={t('Filters')} classes={classes}>
      <Grid container>
        {fields.map((f, ix) => (
          <Grid key={`field${ix}`} item>
            <FilterMenu
              field={f}
              onFieldChange={onFieldChange}
              btnLabel={f.title}
              menuHeading={f.helper}
              filterKey={`field${ix}`}
              helpContent={null}
            />
          </Grid>
        ))}
        <Grid item style={{ marginLeft: '1rem' }}>
          <FilterMenuButton
            field={{ title: t('Clear_Filters'), icon: <CloseIcon /> }}
            onClick={onClearFilters}
          />
        </Grid>
      </Grid>
    </BorderedBlock>
  );
};

export default FilterBar;
