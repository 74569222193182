/**
 * Render contextual help
 * - initially render an icon button
 * - on click, render <Info /> which renders a full page dialog showing contextual help message
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Help } from 'mdi-material-ui';
import InfoIcon from '@material-ui/icons/Info';

import Info from 'components/Info';

const useStyles = makeStyles(theme => ({
  helpButton: {
    marginTop: 0,
  },
  helpIcon: {
    fontSize: '.8em',
  },
}));

const ContextualHelpButton = ({ context, classes: classesOverride }) => {
  const [open, setOpen] = useState(false);
  const classes = { ...useStyles(), ...classesOverride };

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton
        onClick={handleClick}
        classes={{ root: classes.helpButton }}
        color="secondary"
      >
        {' '}
        <Help className={classes.helpIcon} />{' '}
      </IconButton>
      {open && (
        <Info
          dialogContext={context}
          dialogRequest={open}
          onDialogClose={handleClose}
        />
      )}
    </>
  );
};

export default ContextualHelpButton;

ContextualHelpButton.propTypes = {
  context: PropTypes.string, // title of section to be displayed
  classes: PropTypes.object,
};
ContextualHelpButton.defaultProps = {
  classes: null,
};
