/**
 * DeviceManager
 *
 * Render indicators and controls for the specified mediaItem (microphone or speaker)
 * Indicate state of autoMuted flag by overwriting indicators with an alert.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import VolumeMeter from 'components/VolumeMeter';
import VolumeControl from './VolumeControl';
import MuteButton from './MuteButton';

const useStyles = makeStyles(theme => ({
  containerOuter: {
    position: 'relative',
  },
  containerInner: {
    width: 250,
  },
  alert: {
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: 60,
    paddingTop: 10,
  },
  volControl: {
    padding: '0px 20px',
  },
}));

// override default VolumeMeter styles
const useVmStyles = makeStyles(theme => ({
  //vmMeters: { },
  //vmInstant:{ display: 'none' },    //disable instant display
  vmSlow: { display: 'none' }, //disable slow display
  vmIndicator: { width: '100%' },
  vmLabel: { display: 'none' },
  vmValue: { display: 'none', margin: 0 },
}));

export default function DeviceManager({ mediaItem, onChange }) {
  const classes = useStyles();
  const vmClasses = useVmStyles();
  return mediaItem?.stream ? (
    <div className={classes.containerOuter}>
      {mediaItem.autoMuted && (
        <Alert
          classes={{ root: classes.alert }}
          variant="outlined"
          severity="info"
        >
          Suppressing Echo
        </Alert>
      )}
      <Paper
        style={{ visibility: mediaItem.autoMuted ? 'hidden' : 'visible' }}
        elevation={10}
      >
        <Grid
          classes={{ root: classes.containerInner }}
          container
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={2}>
            <MuteButton legends={true} device={mediaItem.device} />
          </Grid>
          <Grid item xs={9}>
            <VolumeMeter
              direction={mediaItem.direction}
              stream={mediaItem.stream}
              classes={vmClasses}
            />
            <div className={classes.volControl}>
              <VolumeControl
                mediaItem={mediaItem}
                disabled={false}
                onChange={onChange}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  ) : null;
}

DeviceManager.propTypes = {
  mediaItem: PropTypes.shape(),
  onChange: PropTypes.func,
};
DeviceManager.defaultProps = {
  mediaItem: PropTypes.shape({
    device: PropTypes.oneOf('microphone', 'speaker'),
    direction: PropTypes.oneOf('outbound', 'inbound'),
    stream: PropTypes.node,
    volume: PropTypes.number,
    muted: PropTypes.bool,
  }),
  onChange: () => {},
};
