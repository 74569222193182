import socketClient from 'socket.io-client';

/**
 * Sigserver implemented at Heroku
 */

//import store from '../../store/store';
//import * as dashboardActions from '../../store/actions/dashboardActions';
//import * as webRTCHandler from './webRTCHandler';
//import * as webRTCGroupCallHandler from '../webRTC/webRTCGroupCallHandler';
import { sigServerConfig } from 'configs/config-hvn';
import store from 'store';
import { dialogActions } from 'store/dialog-slice';
import { userActions, setReciprocalBlacklist } from 'store/user-slice';
import { uiActions } from 'store/ui-slice';
import { webRTCActions } from 'store/webRTC-slice';
import { isBlacklisted, logout } from 'lib/api/userApi';
import { getCallManager } from './webRTCApi';
import { addLocalMessage } from './messageApi';
import { t } from 'lib/translation/trans';
import { activityDetector } from 'lib/classes/ActivityDetector';
import { makeArray, timeout } from 'lib/utils/utils';

const enablePeerLogging = false; //diagnostic only
const enableLiveCallLogging = false; //diagnostic only

const callProc = getCallManager();

const SERVER = `${sigServerConfig.serverUrl}`;

const broadcastEventTypes = {
  ACTIVE_USERS: 'ACTIVE_USERS',
  NEW_PUBLIC_MESSAGE: 'NEW_PUBLIC_MESSAGE',
  DELETE_MESSAGES: 'DELETE_MESSAGES',

  GROUP_CALL_ROOMS: 'GROUP_CALL_ROOMS',
  ADD_PEER: 'ADD_PEER',
  REMOVE_PEER: 'REMOVE_PEER',
};

let socket = null;
let retryInterval = null;
let registrationTimerId = null;

// resolve with existing or new socket
const getSocket = () =>
  socket
    ? Promise.resolve(socket)
    : new Promise((resolve, reject) => {
        sigServer.connect(s =>
          s ? resolve(s) : reject('failed to create socket')
        );
      });

const sigServer = {
  isConnected: () => Boolean(socket?.connected),

  getSocket: getSocket,

  disconnect: () => {
    socket && socket.disconnect();
    socket = null;
  },

  connect: onConnect => {
    socket = socketClient(SERVER);

    /*
                // keep retrying to establish connection
                retryInterval = setInterval(() => {
                    if (!socket.connected) {
                        console.log(`FAILED to obtain socket from ${SERVER} -- retrying...`)
                        socket && socket.disconnect()
                        socket = socketClient(SERVER);
                    }
                }, 5000)
        */

    socket.on('connect_error', () => {
      onConnect && onConnect(null);
      console.log('sigServer connect error!');
      setTimeout(() => {
        socket && socket.connect();
      }, 5000);
    });

    // do not include duplicate or blacklisted users among peers
    socket.on('connection', data => {
      //clearTimeout(retryInterval)
      onConnect && onConnect(socket);
      const activeUsers = data.peers.filter(
        activeUser =>
          activeUser.socketId &&
          activeUser.socketId !== socket.id &&
          !isBlacklisted(activeUser.userId)
      );
      enablePeerLogging && console.log('peers:', activeUsers);
      store.dispatch(userActions.setPeers(activeUsers));
    });

    socket.on('disconnect', function (reason) {
      //console.log(`sigServer disconnected, reason=${reason}!`);
      store.dispatch(userActions.setPeers(null));
      callProc.handleLocalEvent({ type: 'disconnect' });

      // forced disconnect from server was tested, but unused in favor of force-logout
      if (reason === 'io server disconnect') {
        console.log('sigServer killed this duplicate socket!');
        logout(false); //logout but peer has already been unregistered
        store.dispatch(
          uiActions.dialogSet(
            'This device has been logged out because you have logged in at another location.'
          )
        );
      }
    });

    // obtain a dump of the server diagnostic log
    socket.on('text-log-dump', data => {
      const tail = makeArray(data?.log).slice(-1)[0]; //last element in log array
      console.log(`text-log tail:  ${tail?.user}-> ${tail?.text}`);
      store.dispatch(webRTCActions.sigServerLogData(data.log));
    });

    // A new directed message has been sent to this user
    socket.on('new-directed-message', data => {
      console.log('socket.on new-directed-message:', data?.message?.title);
      // But don't process if the author is blacklisted
      const message = data?.message;
      if (!isBlacklisted(message?.userId)) {
        // handle call progress related messages
        if (!callProc.handleInboundMessage(message)) {
          // this message was NOT processed by callProc, handle it here
          addLocalMessage(message, t('A_new_message_has_arrived_Just_for_you'));
        }
      }
    });

    // relayed event: call processing
    socket.on('callproc-event', data => {
      enableLiveCallLogging &&
        console.log('LiveCall on callproc-event: ', data);
      callProc.handleInboundEvent(data?.event);
    });

    // relayed event: blacklist instruction
    socket.on('blacklist-notice', data => {
      const { userToRemove } = data;
      //remove user from local dialogs
      console.log(`blacklist-notice, remove user= ${userToRemove}`);
      store.dispatch(setReciprocalBlacklist({ targetUser: userToRemove }));
    });

    socket.on('broadcast', data => {
      handleBroadcastEvents(data);
    });

    socket.on('webRTC-offer', data => {
      callProc.handleOffer(data);
    });

    socket.on('webRTC-answer', data => {
      callProc.handleAnswer(data);
    });

    socket.on('webRTC-candidate', data => {
      callProc.handleCandidate(data);
    });

    socket.on('force-logout', data => {
      // obsolete, no longer processed by client
    });
  },

  registerSelfAsPeer: label => {
    const { auth, isAuthenticated } = {
      ...{ auth: null, isAuthenticated: null },
      ...store.getState().user,
    };
    const deviceInfo = store.getState().ui.deviceInfo.descriptor;
    const register = () => {
      const { id, screen_name } = {
        ...{ id: null, screen_name: null },
        ...auth.user,
      };
      socket &&
        socket.emit('register-new-user', {
          serviceName: 'hvn',
          username: screen_name,
          userId: id,
          infoId: auth?.user?.infoId,
          deviceInfo, // for diagnostics only
          socketId: socket.id,
        });
    };

    if (isAuthenticated) {
      getSocket()
        .then(socket => {
          register();
          registrationTimerId = setTimeout(register, 5000); //retry in case of failure
        })
        .catch(err => console.log(err));
    }
  },

  unregisterSelfAsPeer: (label, relinquishing = false) => {
    const { auth, isAuthenticated } = store.getState().user;
    store.dispatch(webRTCActions.setCallProgress('noService'));
    if (socket && isAuthenticated) {
      const { id, screen_name } = {
        ...{ id: null, screen_name: null },
        ...auth.user,
      };
      socket &&
        socket.emit('unregister-user', {
          serviceName: 'hvn',
          username: screen_name,
          userId: id,
          infoId: auth?.user?.infoId,
          socketId: socket.id,
          relinquishing,
        });

      // wait for emit to complete (might not be necessary)
      return timeout(500).then(() => {
        sigServer.disconnect();
        return 'unregisterSelfAsPeer success';
      });
    }
  },

  // emitting events to server related to diagnostics ------------------------------------------------

  // submit a text string to the server log
  log: text => {
    socket && socket.emit('text-log', { text });
  },

  // obtain a dump of the server log
  logDump: (reset = false) => {
    socket && socket.emit('text-log-dump', { reset });
  },

  // emitting events to server related to Dialog actions ------------------------------------------------

  // this user has created a new public or directed message
  sendNewMessageNotification: data => {
    console.log('sendNewMessageNotifications:', data);
    socket && socket.emit('new-message', data);
    // sigServer will broadcast NEW_PUBLIC_MESSAGE or emit new-directed-message
  },

  // this user has deleted messages
  sendDeletionsNotification: data => {
    socket && socket.emit('delete-messages', data);
  },

  // notify peer of call processing event
  sendCallProcEvent: data => {
    enableLiveCallLogging &&
      console.log('LiveCall sendCallProcEvent:', data?.event?.type);
    socket && socket.emit('callproc-event', data);
  },

  // place local user on target user's active blacklist
  sendBlacklistNotice: data => {
    socket && socket.emit('blacklist-notice', data);
  },

  // emitting events to server related with direct call ---------------------------------------------------

  sendPreOffer: data => {
    socket && socket.emit('pre-offer', data);
  },

  sendPreOfferAnswer: data => {
    socket && socket.emit('pre-offer-answer', data);
  },

  sendWebRTCOffer: data => {
    socket && socket.emit('webRTC-offer', data);
  },

  sendWebRTCAnswer: data => {
    console.log('sendWebRTCAnswer:');
    socket && socket.emit('webRTC-answer', data);
  },

  sendWebRTCCandidate: data => {
    socket && socket.emit('webRTC-candidate', data);
  },

  sendUserHangedUp: data => {
    enableLiveCallLogging && console.log('LiveCall sendUserHangedUp:', data);
    socket && socket.emit('user-hanged-up', data);
  },

  // emitting events related with group calls

  registerGroupCall: data => {
    socket && socket.emit('group-call-register', data);
  },

  userWantsToJoinGroupCall: data => {
    socket && socket.emit('group-call-join-request', data);
  },

  userLeftGroupCall: data => {
    socket && socket.emit('group-call-user-left', data);
  },

  groupCallClosedByHost: data => {
    socket && socket.emit('group-call-closed-by-host', data);
  },
};

const handleBroadcastEvents = data => {
  const { peers, auth } = store.getState().user;
  const { user } = auth;

  switch (data.event) {
    /*
        case broadcastEventTypes.ACTIVE_USERS:
            const activeUsers = data.activeUsers.filter(activeUser => activeUser.socketId !== socket.id);
            console.log("activeUsers:", activeUsers)
            store.dispatch(userActions.setPeers(activeUsers))
            break;

                case broadcastEventTypes.GROUP_CALL_ROOMS:
                    const groupCallRooms = data.groupCallRooms.filter(room => room.socketId !== socket.id);
                    const activeGroupCallRoomId = webRTCGroupCallHandler.checkActiveGroupCall();

                    if (activeGroupCallRoomId) {
                        const room = groupCallRooms.find(room => room.roomId === activeGroupCallRoomId);
                        if (!room) {
                            webRTCGroupCallHandler.clearGroupData();
                        }
                    }
                      store.dispatch(dashboardActions.setGroupCalls(groupCallRooms));
                      break;
        */

    case broadcastEventTypes.NEW_PUBLIC_MESSAGE:
      // A new topic message has been created by another user
      // But don't process if the author is blacklisted
      if (!isBlacklisted(data?.message.userId)) {
        store.dispatch(dialogActions.insert({ message: data?.message }));
      }
      break;

    case broadcastEventTypes.DELETE_MESSAGES:
      // handle deletion of messages by peer
      const { deathList, originator } = data.data;
      if (originator !== store.getState().user.auth.user?.id) {
        store.dispatch(dialogActions.remove(deathList));
      }
      break;

    case broadcastEventTypes.ADD_PEER:
      const { user: newPeer } = data;
      if (newPeer.userId === user?.id) {
        if (newPeer.socketId === socket.id) {
          // registration confirmed
          enablePeerLogging &&
            console.log(`ADD_PEER registered self: ${newPeer?.socketId}`);
          if (registrationTimerId) {
            // prevent retry
            clearTimeout(registrationTimerId);
            registrationTimerId = null;
          }
          store.dispatch(webRTCActions.setCallProgress('ready'));
        } else {
          enablePeerLogging &&
            console.log(`ADD_PEER duplicate remote: ${newPeer?.socketId}`);

          // User has initiated a remote session.
          // Relinquish control, force this one to sleep.
          activityDetector.sleep({ relinquishing: true });
        }
      } else {
        // handle other user registration
        //avoid duplicates, blacklisted, and do not add self
        if (!isBlacklisted(newPeer.userId)) {
          enablePeerLogging &&
            console.log(
              `ADD_PEER new remote registration: ${newPeer?.username} / ${newPeer?.userId} / ${newPeer?.socketId}`
            );
          const filteredPeers = peers.filter(
            peer => peer.socketId !== newPeer.socketId
          );
          const newPeers = [...filteredPeers, newPeer];
          store.dispatch(userActions.setPeers(newPeers));
        }
      }
      break;
    case broadcastEventTypes.REMOVE_PEER:
      const { user: delPeer } = data;
      enablePeerLogging &&
        console.log(
          `REMOVE_PEER ${delPeer?.username}, socketId=${delPeer?.socketId}`
        );

      delPeer?.userName &&
        console.log('sigServer removed peer:', delPeer?.userName);
      callProc.handleInboundEvent({
        type: 'disconnect',
        userId: delPeer.userId,
      });
      store.dispatch(
        userActions.setPeers(
          peers.filter(peer => peer.socketId !== delPeer.socketId)
        )
      );
      break;
    default:
      break;
  }
};

export default sigServer;
