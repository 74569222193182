/**
 * CallerActions
 *
 * Render controls for caller while awaiting callee action.
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Divider } from '@material-ui/core';
import LiveCallIcon from 'components/LiveCall/components/LiveCallIcon';
import ApplianceControls, {
  wildcard,
} from 'components/utils/ApplianceControls';
import { getCallManager } from 'lib/api/webRTCApi';
import { t } from 'lib/translation/trans';

export default function CallerActions() {
  const calleeScreenName = useSelector(
    state => state.webRTC.participants.remote?.screenName
  );

  const handleCancelCall = () =>
    getCallManager().handleLocalEvent({ type: 'cancel' });

  return (
    <div>
      <Grid container>
        <Grid item>
          <div style={{ display: 'inline', textAlign: 'left' }}>{`${t(
            'You_are_calling'
          )}:`}</div>
          <div
            style={{
              display: 'inline',
              fontSize: '1.5em',
              textAlign: 'left',
              color: '#0096ef',
              fontStyle: 'italic',
            }}
          >
            {' '}
            {`${calleeScreenName}`}{' '}
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid style={{ width: 300, marginTop: 10 }} container>
        <ApplianceControls
          groups={false}
          legends={true}
          buttons={[
            {
              label: t('Cancel Call'),
              icon: <LiveCallIcon context="Cancel" />,
              visible: [wildcard],
              enabled: [wildcard],
              onClick: handleCancelCall,
            },
          ]}
          btnStyleOverride={{ width: '6rem' }} // accommodate long label
        />
      </Grid>
    </div>
  );
}
