/**
 * Player controls, similar to MobileStepper
 *
 * - includes Play/Pause button instead of dots or progress
 * - Play button rendered as FAB
 * - Optional legends for directional buttons
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Grid, IconButton, Typography, Button, Fab } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { t } from 'lib/translation/trans';

const Controls = ({
  isPlaying,
  togglePlay,
  onNext,
  onPrevious,
  legends,
  classes,
  noteWidth,
}) => {
  const widthRef = useRef();

  useEffect(() => {
    noteWidth && noteWidth(widthRef.current.clientWidth);
  }, []);

  const togglePlayDelayed = () => {
    setTimeout(() => togglePlay(), 300);
  };
  return (
    <Grid
      ref={widthRef}
      container
      justify={'space-between'}
      classes={{ root: classes.playerInnerContainer }}
      style={{ padding: '10px' }}
    >
      {/* render visible button if handler was provided */}
      {legends ? (
        <Button size="small" onClick={onPrevious}>
          {onPrevious && (
            <>
              {' '}
              <KeyboardArrowLeft />{' '}
              <Typography variant="caption">{t('Previous')}</Typography>{' '}
            </>
          )}
        </Button>
      ) : (
        <IconButton
          disabled={!Boolean(onPrevious)}
          onClick={onPrevious}
          color="inherit"
        >
          <KeyboardArrowLeft />
        </IconButton>
      )}

      {/* render stateful  play/pause button */}
      {isPlaying ? (
        <IconButton onClick={togglePlay} color="inherit">
          <PauseIcon />
        </IconButton>
      ) : (
        <Fab
          color="inherit"
          onClick={togglePlayDelayed}
          style={{ color: 'red', width: 48, height: 48 }}
        >
          <PlayIcon />{' '}
        </Fab>
      )}

      {/* render visible button if handler was provided */}
      {legends ? (
        <Button size="small" onClick={onNext}>
          {onNext && (
            <>
              {' '}
              <Typography variant="caption">{t('Next')}</Typography>{' '}
              <KeyboardArrowRight />{' '}
            </>
          )}
        </Button>
      ) : (
        <IconButton
          disabled={!Boolean(onNext)}
          onClick={onNext}
          color="inherit"
        >
          <KeyboardArrowRight />
        </IconButton>
      )}
    </Grid>
  );
};

Controls.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  togglePlay: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  legends: PropTypes.bool,
  classes: PropTypes.shape({}),
  noteWidth: PropTypes.func,
};

Controls.defaultProps = {
  legends: true,
};

export default Controls;
