/**
 * Render a fixed notification bar soliciting cookie approval
 * Once cookies are approved, save status as a cookie and in localstorage
 */

import React, { useState } from 'react';
import { Divider } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import Page from 'components/Page';
import Copyright from 'components/utils/Copyright';

const useStyles = makeStyles(theme => ({}));

const CookieConsentBar = () => {
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const cookiesAccepted = Boolean(
    useSelector(state => state.ui.pSession.cookiesAcceptedTime)
  );

  const handleAccept = () => {
    console.log('handleAccept.');
    dispatch(uiActions.noteCookiesAccepted());
  };

  const consentProps = {
    location: 'bottom',
    buttonText: 'Accept All Cookies',
    style: { background: '#ff9800', color: 'black', marginBottom: 80 },
    buttonStyle: { color: '#4e503b', fontSize: '13px' },
    children: <button>test</button>,
    overlay: false,
    buttonStyle: { backgroundColor: 'green' },
    onAccept: handleAccept,
    enableDeclineButton: false,
    debug: false,
  };

  const moreInfoButtonProps = {
    enableDeclineButton: true,
    hideOnDecline: false,
    declineButtonText: 'More Info',
    setDeclineCookie: false,
    declineButtonStyle: { backgroundColor: 'grey' },
    onDecline: () => setMoreInfoOpen(true),
  };

  // configure  the decline button for use as 'more info"
  // workaround: in the event cookie indicates acceptance, but localstorage is not in sync, use debug flag to force presentation of approval.
  return (
    <>
      <CookieConsent
        {...{
          ...consentProps,
          debug: !cookiesAccepted /* , ...moreInfoButtonProps */,
        }}
      >
        <div>
          By using our website, you agree to our{' '}
          <a
            target="_blank"
            href="https://www.termsfeed.com/live/5d16bf4a-fac3-4ef0-a447-109b52929488"
          >
            Terms
          </a>
          ,{' '}
          <a
            target="_blank"
            href="https://www.termsfeed.com/live/c9ad3dff-e9ee-4860-a47a-598229193da7"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            href="https://www.termsfeed.com/live/71376140-1f76-428a-90b4-30835fa5b8bb"
          >
            Cookies Policy
          </a>
          . By clicking “Accept All Cookies”, you agree to the storing of
          cookies on your device to enhance site navigation, analyze site usage,
          and assist in our marketing efforts.
        </div>
        <div style={{ marginTop: 20 }}>
          Website <Copyright />
        </div>
      </CookieConsent>
      <FullScreenDialog
        open={moreInfoOpen}
        title="More Info -- Cookies"
        onClose={() => setMoreInfoOpen(false)}
        content={<Page title={'More Info -- Cookies'} />}
      />
    </>
  );
};

export default CookieConsentBar;
