/**
 * Render a selector for the UI language
 * Use either radio buttons or ToggleButtonGroup
 * Remder as vertoca; or horizontal layout
 */

import React from 'react';
import PropTypes from 'prop-types';

import RadioStd from 'components/utils/RadioStd';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from 'store/ui-slice';

import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const items = [
  { key: 'EN', data: 'EN' },
  { key: 'FR', data: 'FR' },
  { key: 'SP', data: 'SP' },
];

const useStyles = makeStyles(theme => ({
  formStyle: {
    position: 'relative',
  },
  groupStyle: {
    // compensate for left margin on first rendered item
    position: 'relative',
    left: -16,
  },

  toggleButtonGroup: {
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(.8)',
    },
  },
}));

export default function DisplayLanguageSelector(props) {
  const { variant, orientation } = props;
  const currentLangCode = useSelector(state => state.ui.pSession.langCode);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = selection => {
    if (selection !== currentLangCode) {
      dispatch(setLanguage(selection));
    }
  };

  return (
    <>
      {variant === 'radio' && (
        <RadioStd
          name="uiLang"
          classes={classes}
          items={items}
          value={currentLangCode}
          handleChange={selection => handleChange(selection?.target?.value)}
        />
      )}

      {variant === 'toggle' && (
        <ToggleButtonGroup
          classes={{ root: classes.toggleButtonGroup }}
          size="small"
          value={currentLangCode}
          exclusive
          onChange={(ev, selection) => handleChange(selection)}
          orientation={orientation}
        >
          <ToggleButton value="EN">EN</ToggleButton>
          <ToggleButton value="FR">FR</ToggleButton>
          <ToggleButton value="SP">SP</ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  );
}

DisplayLanguageSelector.propTypes = {
  variant: PropTypes.oneOf(['radio', 'toggle']),
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
};
DisplayLanguageSelector.defaultProps = {
  variant: 'radio',
  orientation: 'horizontal',
};
