/**
 * Landing
 * Proposed/experimental landing page for first time visitors
 *

 */
import React, { useEffect, useState, isValidElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import DraftsIcon from '@material-ui/icons/Drafts';
import ShareIcon from '@material-ui/icons/Share';
import ChatIcon from '@material-ui/icons/Chat';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import VoiceRecorder from 'components/VoiceRecorder';
import MessageRecorder from 'containers/MessageRecorder';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { enableOutgoingType } from 'lib/api/messageApi';

import {
  PlayCircleFilled as PlayIcon,
  PauseCircleFilled as PauseIcon,
  CloudUpload as SaveIcon,
  Cancel as CancelIcon,
  Send as SendIcon,
  DoneOutline as DoneIcon,
  GetApp as DownloadIcon,
  ThumbUpAlt as MicApprovedIcon,
  Login as LoginIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  mouth: {
    maxWidth: '100%',
    height: 'auto',
  },
  subHeader: {
    color: theme.palette.secondary.light,
    fontSize: '1.2rem',
  },
}));

const Landing = () => {
  const [selection, setSelection] = useState(null);
  const [action, setAction] = useState(null);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  // hide CommiunityIndicator while this component is rendered
  useEffect(() => {
    dispatch(
      uiActions.layoutComponentHide({
        community: true,
      })
    );
    return () => {
      dispatch(
        uiActions.layoutComponentHide({
          community: false,
        })
      );
    };
  }, []);

  const selections = [
    {
      label: 'Say it and Download',
      info: [
        'A quik and easy voice recorder!',
        'You can record your voice now then download the file to your desktop or device.',
      ],
      icon: <DownloadIcon />,
      action: (
        <VoiceRecorder
          onActionEvent={e => console.log(e)}
          onCancel={handleActionClose}
        />
      ),
    },
    {
      label: 'Say it and Post it',
      info: [
        'Let everyone hear what you have to say!',
        'When you record your voice it will be stored in the OkSayit cloud for others to hear. You will get a link that you can paste to your social media if you wish.',
        'FIRST TIME USERS: You will be asked to login in order to complete this.',
      ],
      icon: <ShareIcon />,
      action: (
        <MessageRecorder
          renderAsAppliance={false}
          onExit={handleActionClose}
          parentMessage={null}
          typeOverride="post"
          onActionEvt={e => console.log(e)}
        />
      ),
    },
    {
      label: 'Say it and Send it',
      info: [
        'Add your voice to an email or text!',
        'When you record your voice it will be stored in the OkSayit cloud and you will get a link that you can email or text to a friend. They will hear your voice when they click on the link.',
        'FIRST TIME USERS: You will be asked to login in order to complete this.',
      ],
      icon: <DraftsIcon />,
      action: (
        <MessageRecorder
          renderAsAppliance={false}
          onExit={handleActionClose}
          parentMessage={null}
          typeOverride="outgoing"
          bannerOverride="Message to Friend"
          onActionEvt={e => console.log(e)}
        />
      ),
    },
    {
      label: 'Chat and Make Friends',
      info: [
        'Feeling bored or lonely?',
        'Listen to messages shared by others, and get in touch with anyone who sounds interesting to you.',
      ],
      icon: <ChatIcon />,
      action: () => history.push('/playlist'),
    },
  ];

  const handleClick = item => setSelection(item);
  const handleInfoClose = () => setSelection(null);

  // selection.action is expected to be a renderable element, or a function
  const handleLaunchAction = () => {
    if (isValidElement(selection?.action)) {
      setAction(selection?.action);
    } else if (typeof selection?.action === 'function') {
      selection.action();
    }
  };

  function handleActionClose() {
    setAction(null);
    setSelection(null);
  }

  const list = () =>
    selections
      .filter(s => enableOutgoingType || s.label !== 'Say it and Send it')
      .map(item => (
        <ListItem button onClick={() => handleClick(item)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItem>
      ));

  // render 'action' if specified, otherwise present Landing page
  return (
    <div style={{ paddingTop: 30 }}>
      {action || (
        <Paper style={{ padding: 20 }}>
          <Typography style={{ textAlign: 'center' }} variant="h4">
            Your Voice-Based Social Network
          </Typography>
          <Grid
            style={{ marginTop: 30 }}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid style={{ width: '25%' }}>
              <img
                src="/images/mouth/lunapic_300x150.png"
                alt="Walking Mouth"
                style={{ maxInlineSize: '100%', blockSize: 'auto' }}
              ></img>
            </Grid>
            <Grid item>{list()}</Grid>
          </Grid>
        </Paper>
      )}

      <ExceptionDialog
        exceptionDisable={true}
        open={selection && !action}
        onClose={handleInfoClose}
        heading={<Typography color="secondary">{selection?.label} </Typography>}
        body={selection?.info}
        actions={[
          {
            label: 'Continue',
            color: 'secondary',
            onClick: handleLaunchAction,
          },
          { label: 'Cancel', onClick: handleInfoClose },
        ]}
      />
    </div>
  );
};

Landing.propTypes = {};
Landing.defaultProps = {};
export default Landing;
