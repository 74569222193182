/**
 * CommunityDescriptionPanels2
 * Revised for invokation from Settings view
 * Requires that user already be authenticated.
 *
 * Render information about a specified community and offer opportunity to join
 *
 */

import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { makeArray } from 'lib/utils/utils';
import AccordionStd from 'components/utils/AccordionStd';
import Copyright from 'components/utils/Copyright';

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: '1rem',
    padding: '8px 36px',
    textAlign: 'justify',
  },
  accordionContainer: {
    marginTop: '1rem',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  detailsContainer: {
    'padding': '0px 20px',
    'textAlign': 'justify',
    '& ul': {
      padding: 0,
    },
    '& li': {
      marginBottom: '1rem',
    },
    '& em': {
      color: 'white',
    },
  },
  joeTemp: {},
}));

// provide welcome page for the specified partition
// if not specified in prop, use value from redux
// if still none specified, use defaultPartition as default

const CommunityDescriptionPanels = ({ community, onActivity }) => {
  const [panels, setPanels] = useState([]);

  const classes = useStyles();
  const parser = new DOMParser();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const [userClicked, setUserClicked] = useState(false); // use has clicked on an item

  // initialize
  useEffect(() => {
    if (!community) {
      return;
    }

    // only proceed if user is authenticated
    // parse and assemble the UI from community node
    // content consists of a body, multiple segments, a voice intro file, and an icon

    setPanels(
      //extract accordion panel components from individual segments
      //of the Drupal Community node
      makeArray(community?.sections).map((section, ix) => {
        const doc = parser.parseFromString(section, 'text/html');
        return {
          ix,
          h2: doc.getElementsByTagName('h2')[0]?.innerHTML,
          h3: doc.getElementsByTagName('h3')[0]?.innerHTML,
          details: doc.getElementsByTagName('section')[0]?.innerHTML,
        };
      })
    );
  }, [community]);

  const handleAccordionChange = () => {
    onActivity && onActivity();
  };

  return (
    <Grid
      classes={{ root: classes.joetemp }}
      container
      direction="column"
      alignItems="center"
    >
      <Grid style={{ marginBottom: '20px' }} item md={8}>
        {community?.body && (
          <Paper classes={{ root: classes.body }}>
            <div dangerouslySetInnerHTML={{ __html: community?.body }} />
          </Paper>
        )}
        {panels.length && (
          <div className={classes.accordionContainer}>
            {
              <AccordionStd
                onChange={handleAccordionChange}
                sections={panels.map((pnl, ix) => ({
                  key: `pnl${ix}_${pnl.h2}`,
                  heading: (
                    <Typography
                      className={classes.accordionHeading}
                      color="secondary"
                    >
                      {pnl.h2}
                    </Typography>
                  ),
                  subHeading: (
                    <Typography className={classes.accordionSecondaryHeading}>
                      {xs ? null : pnl.h3}
                    </Typography>
                  ),
                  details: (
                    <div className={classes.detailsContainer}>
                      {xs && (
                        <p>
                          <Typography variant="body1" color="textPrimary">
                            {' '}
                            {pnl.h3}{' '}
                          </Typography>
                        </p>
                      )}
                      <Typography variant="body2" color="textSecondary">
                        <p dangerouslySetInnerHTML={{ __html: pnl.details }} />
                        {/* consider refactoring to https://github.com/MomenSherif/react-html-string */}
                      </Typography>
                    </div>
                  ),
                }))}
                initialOpen={`pnl0_${panels[0]?.h2}`}
              />
            }
          </div>
        )}
      </Grid>
      <Copyright />
    </Grid>
  );
};

CommunityDescriptionPanels.propTypes = {
  community: PropTypes.object,
  onActivity: PropTypes.func,
};

export default CommunityDescriptionPanels;
