/**
 * Renders buttons to select between publlic/directed message filter
 *
 */

import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  container: {
    //paddingBottom: "20px",
  },
  button: {
    width: '49%',
  },
}));

const VisibilitySelector = ({ visibility, onChange }) => {
  const classes = useStyles();

  const handleClick = value => {
    onChange && onChange(value);
  };

  const renderButton = vis => {
    // brightness percent
    const pct = vis === visibility ? 100 : 50;

    // dim the button for the unselected visibility
    // use primary color to represent public visibility
    return (
      <Button
        size="small"
        variant="contained"
        classes={{ root: classes.button }}
        style={{ filter: `brightness(${pct}%)` }}
        color={vis === 'public' ? 'secondary' : 'primary'}
        onClick={() => handleClick(vis)}
      >
        {vis === 'public' ? t('Public') : t('Direct_DM')}
      </Button>
    );
  };

  return (
    <Grid
      classes={{ root: classes.container }}
      container
      justify="space-between"
    >
      {renderButton('public')}
      {renderButton('directed')}
    </Grid>
  );
};
export default VisibilitySelector;
