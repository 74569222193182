/**
 * Render an individual message player:
 *  - message topic (title, author, etc)
 *  - player controls
 *  - action buttons to provide context sensitive response options
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import PlayerActions from './components/PlayerActions';
import { WEB_HOST } from 'configs/config-hvn';
import { useDispatch } from 'react-redux';
import { dialogActions } from 'store/dialog-slice';
import TopicCard from 'containers/PlaylistView/components/PlaylistBrowser/components/TopicCard_v6';
import BorderedBlock from 'components/utils/BorderedBlock';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'lib/translation/trans';
import {
  isSelfAuthored,
  isUserRecipient,
  isPublicType,
} from 'lib/api/messageApi';
import { makeArray } from 'lib/utils/utils';
import FacebookShareButton from 'components/utils/FacebookShareButton';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
}));

const MessagePlayer = props => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [playerWidth, setPlayerWidth] = useState(400);
  const [showMore, setShowMore] = useState(false);
  const { isAuthenticated, auth } = useSelector(state => state.user);

  const { message, autoplay, onRecActionEvt, onPlayActionEvt, shareMethod } =
    props;

  const { isPlaying, defaultProps, setSrc, togglePlay } = useVoicePlayerHelper({
    playerKey: 'MessagePlayer',
  });

  const myBookmarks = makeArray(
    isAuthenticated && auth.user?.info?.field_bookmarks
  );
  const bookmarked = Boolean(myBookmarks.find(bm => bm === message.userId));

  useEffect(() => {
    dispatch(dialogActions.setPreloadSelectedMessageId(message?.id)); // select this message later, when dialogs are fetched
    setSrc(message ? `${WEB_HOST}/${message.fileUrl}` : '');
  }, [message]);

  const handleActionEvent = action => {
    switch (action) {
      case 'playClick':
      case 'pauseClick':
        togglePlay();
        break;
      case 'moreClick':
        // toggle auxiliary menu
        setShowMore(was => !was);
        break;
      default:
        break;
    }
    onPlayActionEvt && onPlayActionEvt(action);
  };
  const handleMessageClick = () => togglePlay();

  const notePlayerActionsWidth = w => setPlayerWidth(w);

  // Context sensitive Action Options
  const btnList = () => {
    /*
     * for user who is authenticated or who has entered an AuthCode for outgoing message type
     * - all users: play/pause
     * - add 'respond': isPublic || isRegisteredRecipient || isOutGoingType
     * - add bookmarking:  isAuthenticated && !isRegisteredAuthor && !outgoingType
     * - add delete: isRegisteredAuthor || isRegisteredRecipient
     */

    const isRegisteredAuthor = isSelfAuthored(message);
    const isRegisteredRecipient = isUserRecipient(message);
    const isPublic = isPublicType(message.type);
    const isOutgoingType = message.type === 'outgoing';

    const btns = ['play', 'pause']; //default buttons
    if (isPublic || isRegisteredRecipient || isOutgoingType) {
      btns.push('respond');
    }
    if (isPublic) {
      btns.push('share');
    }
    if (isAuthenticated && !isRegisteredAuthor && !isOutgoingType) {
      btns.push('bookmark');
      btns.push('unMark');
      btns.push('blacklist');
    }
    if (isRegisteredAuthor || isRegisteredRecipient) {
      btns.push('delete');
    }
    btns.push('info');
    return btns;
  };

  const renderPlayer = () => (
    <div className={classes.container}>
      <BorderedBlock labelTop={t('Message_Player')} classes={classes}>
        <Grid container direction="column" alignItems="center">
          <Grid style={{ width: '100%' }} item>
            {message && (
              <TopicCard
                message={{ ...message, bookmarked }}
                includeActions={false}
                select={handleMessageClick}
                displayAsRoot={true}
                centered
              />
            )}
          </Grid>
          <Grid style={{ width: '80%' }} item>
            <VoicePlayer
              {...defaultProps}
              uiItems={['progress']}
              autoplay={autoplay}
            />
          </Grid>
          <Grid container justify="center" style={{ width: '100%' }} item>
            <PlayerActions
              legends={true}
              groups={false}
              message={{ ...message, bookmarked }}
              onEvent={handleActionEvent}
              onRecActionEvt={onRecActionEvt}
              btnList={btnList()}
              noteWidth={notePlayerActionsWidth}
              isPlaying={isPlaying}
            />
          </Grid>
          {showMore && (
            <Grid
              container
              justify="center"
              style={{ marginTop: '2rem', width: '100%' }}
              item
            >
              <PlayerActions
                legends={true}
                groups={false}
                message={message}
                onEvent={handleActionEvent}
                onRecActionEvt={onRecActionEvt}
                btnList={['delete', 'bookmark', 'unMark', 'blacklist', 'info']}
                noteWidth={notePlayerActionsWidth}
                isPlaying={isPlaying}
              />
            </Grid>
          )}
        </Grid>
      </BorderedBlock>
    </div>
  );

  return (
    <>
      {renderPlayer()}
      <FacebookShareButton
        url={window.location?.href}
        shareMethod={shareMethod}
      />
    </>
  );
};

MessagePlayer.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    subject: PropTypes.string,
    username: PropTypes.string,
    screenName: PropTypes.string,
    since: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,

  autoplay: PropTypes.bool,

  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  carousel: PropTypes.node,
  noVisualizer: PropTypes.bool,
  noFilterBar: PropTypes.bool,
  onRecActionEvt: PropTypes.func,
  onPlayActionEvt: PropTypes.func,
};

MessagePlayer.defaultProps = {
  autoplay: false,
  noVisualizer: false,
  noFilterBar: true,
};

export default MessagePlayer;
