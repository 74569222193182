/**
 * Live Call
 *
 * User Interface for live calls
 * Rendered for Caller and Callee while call activities are in progress
 *
 * Invitation View (modal):
 * - rendered on button click when multiple invites are active
 * - indicates from whom invites have arrived
 * - provides means of selection
 *
 * Status View (modal):
 * - rendered on button click, upon selection in Invitation View, or upon a priority call progress event
 * - indicates invitation/call status and action options
 * - actions available:
 *  - callee
 *      - close modal, accept, decline, regrets, block
 *  - caller
 *      - close modal, cancel, connect (available upon receipt of acceptance)
 *  - cancel call (caller only, prior to acceptance)
 *
 * Call Progress View (modal):
 * - rendered to callee upon issuing acceptance
 * - rendered to caller upon electing to connect
 * - remains active for both parties until completion of call
 *

 */

/*
Call Progress UI general behavior
state       display
---------   -------
noService   none
ready       none
invited     status button (toggleable with status modal)
declined    status modal, mandatory action
removed     status modal
accepted    callee progress / caller: status modal, mandatory action
connected   progress
hangingUp   progress
ended       none

*/

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import ApplianceControls, {
  wildcard,
} from 'components/utils/ApplianceControls';
import ExceptionDialog from 'components/Modals/ExceptionDialog'; //note usage is Status not Exception
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';
import ButtonStd from 'components/utils/ButtonStd';
import { t } from 'lib/translation/trans';
import { getCallManager } from 'lib/api/webRTCApi';
import CallProgressDialog from './components/CallProgressDialog';
import CallStatus from './components/CallStatus';
import LiveCallIcon from './components/LiveCallIcon';
import { makeArray } from 'lib/utils/utils';

const callProc = getCallManager();

const useStyles = makeStyles(theme => ({}));

const LiveCall = () => {
  // note that callee does not set participants info until call is accepted
  // until then, caller information is only available from the invite message

  const [display, setDisplay] = useState('none'); // display mode: 'none', 'button', 'status', 'progress'
  const [calleeBusy, setCalleeBusy] = useState(false); //callee is busy with an action dialog
  const { participants, call } = useSelector(state => state.webRTC);
  const dispatch = useDispatch();
  const role = participants.local.role || 'callee'; // caller has already initialized participants.local data

  // update remoteParty if user fields change
  const user = useSelector(state => state.user?.auth?.user);
  const remoteParty = {
    type: 'invite',
    userId: participants.remote.userId,
    screenName: participants.remote.screenName,
    bookmarked: makeArray(user?.info?.field_bookmarks).includes(
      participants.remote.userId
    ),
  };

  const callerAutoConnect = useSelector(
    state => state.ui.pSession.autoLiveCall
  );

  // process call progress transitions
  useEffect(() => {
    //console.log(`LiveCall progress = ${call.progress}`);

    // if caller has elected to autoconnect, don't wait for manual action
    const handleCallerAutoConnect = () => {
      // assume caller role and 'accepted' progress
      callerAutoConnect && handleConnectCall();
      return callerAutoConnect ? 'progress' : 'status';
    };

    // what to display based on call progress state
    const displayMode = () => ({
      noService: 'none',
      ready: 'none',
      invited: 'button',
      declined: 'status',
      removed: 'status',
      accepted: role === 'caller' ? handleCallerAutoConnect() : 'progress', // display call progress, awaiting connect from caller
      connected: 'progress',

      // if progress display is already active, keep it
      hungup: display === 'progress' ? 'progress' : 'status',
      cancelled: display === 'progress' ? 'progress' : 'status',
      disconnected: display === 'progress' ? 'progress' : 'status',
      problem: display === 'progress' ? 'progress' : 'status',
    });

    // set the appropriate display mode
    setDisplay(displayMode()[call.progress]);
  }, [call.progress]);

  const handleStatusClose = () => {
    // upon close of status dialog, if in termination state issue a reset
    [
      'hungup',
      'declined',
      'removed',
      'cancelled',
      'disconnected',
      'problem',
    ].includes(call.progress)
      ? handleReset()
      : setDisplay('button');
  };

  const handleCallProgressDialogClose = () => {};

  const handleCancelCall = () => callProc.handleLocalEvent({ type: 'cancel' });

  const handleConnectCall = () => {
    callProc.handleLocalEvent({ type: 'connect' });
    dispatch(uiActions.voicePlayerPause()); //pause playback, if any
  };

  const handleReset = () => callProc.handleLocalEvent({ type: 'reset' });

  const handleCalleeStatusAction = evClick => {
    // events are handled within PlayerActions
    // future use: to handle here...
    // remove 'Click' suffix from each event before processing
    //const ev = evClick.replace('Click', '')
    //callProc.handleLocalEvent({type: ev})

    const value = ['regretsClick', 'blacklistClick'].includes(evClick);
    setCalleeBusy(value);
  };

  // available control buttons during call processing
  const callerActionsSelector = btns => (
    <ApplianceControls
      groups={false}
      legends={true}
      buttons={[
        {
          label: t('Connect'),
          icon: <LiveCallIcon context="Ringing" />,
          visible: [btns.includes('connect') ? wildcard : 'x'],
          enabled: [wildcard],
          onClick: handleConnectCall,
        },
        {
          label: t('Cancel Call'),
          icon: <LiveCallIcon context="Cancel" />,
          visible: [btns.includes('cancel') ? wildcard : 'x'],
          enabled: [wildcard],
          onClick: handleCancelCall,
        },
      ]}
      btnStyleOverride={{ width: '6rem' }} // accommodate long label
    />
  );

  // call progress state dependent:
  // return the controls to be presented to the caller in the footer of the status dialog
  const callerActions = () => {
    let actions = null;
    switch (call.progress) {
      case 'invited':
        actions = callerActionsSelector(['cancel']);
        break;
      case 'accepted':
        actions = callerActionsSelector(['connect', 'cancel']);
        break;
      case 'hungup':
      case 'declined':
      case 'removed':
      case 'cancelled':
      case 'disconnected':
      case 'problem':
      default:
        actions = (
          <ButtonStd
            onClick={handleReset}
            label={t('Close')}
            color="secondary"
            centered
          />
        );
        break;
    }
    return actions;
  };

  // call progress state dependent:
  // return the controls to be presented to the callee in the footer of the status dialog
  const calleeActions = () => {
    let actions = null;
    switch (call.progress) {
      case 'invited':
        // inbound calls require actions supported by <PlayerActions />
        actions = (
          <PlayerActions
            legends={true}
            message={remoteParty}
            onEvent={handleCalleeStatusAction}
            btnList={['accept', 'decline', 'regrets', 'blacklist']}
          />
        );
        break;
      case 'hungup':
      case 'declined':
      case 'removed':
      case 'cancelled':
      case 'disconnected':
      case 'problem':
      default:
        actions = (
          <ButtonStd
            onClick={handleReset}
            label={t('Close')}
            color="secondary"
            centered
          />
        );
        break;
    }
    return actions;
  };

  return display === 'none' ? null : (
    <>
      {/* Status and action options for a particular invite */}
      <ExceptionDialog
        exceptionDisable={true}
        open={display === 'status'}
        onClose={handleStatusClose}
        heading={t('Live Call Status')}
        body={
          <CallStatus
            view={'full'}
            invite={remoteParty}
            calleeBusy={calleeBusy}
          />
        }
        actions={role === 'caller' ? callerActions() : calleeActions()}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />
      <CallProgressDialog
        open={display === 'progress'}
        onClose={handleCallProgressDialogClose}
        message={remoteParty}
      />
    </>
  );
};

export default LiveCall;
