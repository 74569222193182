import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Container,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#303030',
  },
  content: {
    backgroundColor: '#303030',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  containerZen: {
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  open,
  title,
  content,
  onClose,
  zen,
}) {
  const classes = useStyles();

  const wrappedContent = (
    <DialogContent classes={{ root: classes.content }}>
      <Grid container justify="center">
        <Grid item xs={12} md={8}>
          <Paper>{content}</Paper>
        </Grid>
      </Grid>
    </DialogContent>
  );

  const zenContent = (
    <Container classes={{ root: classes.containerZen }}>{content}</Container>
  );

  return (
    <div>
      <Dialog
        classes={{ paper: classes.paper }}
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <HeaderFullScreenDialog title={title} onClose={onClose} />
        {zen ? zenContent : wrappedContent}
      </Dialog>
    </div>
  );
}

FullScreenDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.node,
  onClose: PropTypes.func,
  zen: PropTypes.bool, // render without wrapper layout
};

FullScreenDialog.defaultProps = {
  zen: false,
};

const HeaderFullScreenDialog = ({ title, onClose }) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        {onClose && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        )}

        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>

        {onClose && (
          <Button autoFocus color="inherit" onClick={onClose}>
            {t('Close')}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
