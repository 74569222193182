/**
 * Render an invisible button capable of generating a programmatic click event
 * upon assertion of clickTrigger prop.
 *
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

const Clicker = ({ clickTrigger, onClick }) => {
  const btnRef = useRef();

  // respond to assertion of clickTrigger
  useEffect(() => {
    if (clickTrigger) {
      btnRef.current.click();
    }
  }, [clickTrigger]);

  const handleClick = () => onClick && onClick();

  // invisible button, target of programmatic click event
  return (
    <div style={{ height: 0 }}>
      <Button ref={btnRef} onClick={handleClick}></Button>
    </div>
  );
};

Clicker.propTypes = {
  clickTrigger: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Clicker;
