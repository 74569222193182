/**
 * oAuth token management services
 */

import { fetcher } from './fetcher';

import {
  REFRESH_TOKEN_EXPIRATION_TIME,
  REFRESH_TOKEN_REFRESH_MARGIN,
  OAUTH_CLIENT,
  WEB_ROOT,
} from 'configs/config-hvn';

export const authenticator = (configIn = {}) => {
  // apply custom config if provided
  const defaultConfig = {
    client: OAUTH_CLIENT,
    scope: 'oauth',
    refresh_token_expiration_time: REFRESH_TOKEN_EXPIRATION_TIME,
    refresh_token_refresh_margin: REFRESH_TOKEN_REFRESH_MARGIN,
  };
  const config = { ...defaultConfig, ...configIn };

  const annotateAuthObject = authObject => {
    const created_at = Math.floor(Date.now() / 1000);
    return {
      ...authObject,
      created_at,
      expires_at: created_at + authObject.expires_in,
      refresh_expires_at: created_at + config.refresh_token_expiration_time,
      issuer: WEB_ROOT,
    };
  };

  // post the request for a new token, or a refresh
  const fetchToken = fd =>
    fetcher('token', 'POST', '', fd).then(data => {
      const authObject = annotateAuthObject(data);
      return authObject;
    });
  //.catch(err => console.log("fetchToken error:", err))

  const getToken = ({ username, password }) => {
    if (!username || !password) {
      return Promise.reject('Null username/password.');
    }
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', config.client.id);
    formData.append('client_secret', config.client.secret);
    formData.append('scope', config.scope);
    formData.append('username', username);
    formData.append('password', password);

    // for(var pair of formData.entries()) {
    //    console.log(pair[0]+ ', '+ pair[1]);
    // }
    return fetchToken(formData);
  };

  // Note that the data in the request is different when getting a new token
  // via a refresh_token. grant_type = refresh_token, and do NOT include the
  // scope parameter in the request as it'll cause issues if you do.
  const refreshToken = ({ refresh_token, refresh_expires_at }) => {
    // verify expiration status
    if (
      Date.now() / 1000 >
      refresh_expires_at - config.refresh_token_refresh_margin
    ) {
      return Promise.reject('Expired refresh token');
    }
    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', config.client.id);
    formData.append('client_secret', config.client.secret);
    formData.append('refresh_token', refresh_token);

    return fetchToken(formData);
  };

  // prepare form data for access_token  request
  const fdAccessToken = ({ username, password }) => {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', config.client.id);
    formData.append('client_secret', config.client.secret);
    formData.append('scope', config.scope);
    formData.append('username', username);
    formData.append('password', password);
    return formData;
  };

  // prepare form data for refresh request
  const fdRefreshToken = refresh_token => {
    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', config.client.id);
    formData.append('client_secret', config.client.secret);
    formData.append('refresh_token', refresh_token);
    return formData;
  };

  return {
    getToken,
    refreshToken,

    fdAccessToken,
    fdRefreshToken,
    annotateAuthObject,
  };
};

// check the token status
// 'current', 'refreshable', 'bad'
export const tokenStatus = authObject => {
  if (!authObject) {
    return 'none ';
  }
  if (authObject.issuer !== WEB_ROOT) {
    console.log(`tokenStatus unrecognized issuer: ${authObject.issuer}.`);
    return 'unrecognized issuer';
  }

  const now = Math.floor(Date.now() / 1000); // convert to seconds
  const { access_token, expires_at, refresh_token, refresh_expires_at } =
    authObject;
  const current = access_token && expires_at && now < expires_at;
  const refreshable =
    access_token &&
    refresh_token &&
    refresh_expires_at &&
    now < refresh_expires_at - REFRESH_TOKEN_REFRESH_MARGIN;

  return current ? 'current' : refreshable ? 'refreshable' : 'Not Refreshable';
};
