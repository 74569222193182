/**
 * EchoSuppressor
 * Mute speaker during voice activity on microphone
 * ( refactoring of TalkStatus )
 *
 * Notes:
 *  - monitors local VAD and reports to peer via telemetry channel
 *  - subscribes to remote VAD via telemetry channel
 *  - when enabled, mutes local speaker when user is talking
 *  - ignores any manual mute imposed by external factors
 *    (be sure to disable this entity when manual mute is desired)
 *
 * Custom Hook (optional use):
 *  - useEchoSuppressor
 */

class EchoSuppressor {
  autoMuteEnable = false;
  lsp = null; // local stream processor (microphone)
  rsp = null; // remote stream processor (speaker)
  pdm = null; // peer (telemetry) data manager
  onAutoMuteEvent = () => {}; // callback to report mute transitions

  constructor(
    localStreamProcessor,
    remoteStreamProcessor,
    pdm,
    onAutoMuteEvent
  ) {
    this.lsp = localStreamProcessor;
    this.rsp = remoteStreamProcessor;
    this.pdm = pdm;
    this.onAutoMuteEvent = onAutoMuteEvent;
  }

  destroy = () => {
    this.enableAutoMute(false);
  };

  // note a change in manual mode settings
  // (manual mute overrides and disables automatic muting)
  applyManualControls = ({ micMuted, speakerMuted, echoSuppressOn }) => {
    micMuted !== undefined && (this.manualMicMuted = micMuted);
    speakerMuted !== undefined && (this.manualSpeakerMuted = speakerMuted);
    echoSuppressOn !== undefined &&
      (this.manualEchoSuppressOn = echoSuppressOn);

    if (this.manualMicMuted || this.manualSpeakerMuted) {
      // apply manual override
      this.enableAutoMute(false);
    } else {
      // change enablement if necessry
      if (this.autoMuteEnable !== this.manualEchoSuppressOn) {
        this.enableAutoMute(this.manualEchoSuppressOn);
      }
    }
  };

  enableAutoMute = en => {
    if (en && this.lsp && this.rsp) {
      // monitor local and remote voice activity
      // subscribe to locally generated events from streamProcessors
      // and remotly generated events from PeerDataProcessor
      this.lsp.vadSubscribe(this.handleVadEvent);
      this.rsp.vadSubscribe(this.handleVadEvent);
      this.pdm && this.pdm.subscribe('TalkStatus', 'vad', this.pdmReceive);
      this.autoMuteEnable = true;
    } else {
      this.pdm && this.pdm.unsubscribe('TalkStatus');
      this.rsp.vadUnSubscribe(this.handleVadEvent);
      this.lsp.vadUnSubscribe(this.handleVadEvent);

      // apply manually specified muting
      this.rsp.setMuted(this.manualSpeakerMuted);
      this.lsp.setMuted(this.manualMicMuted);

      this.onAutoMuteEvent(null); //indicate disablement of auto mute
      this.autoMuteEnable = false;
    }
  };

  // handle incoming data from peer reflecting its local vad event
  pdmReceive = data => {
    const { key, label, val } = data;
    this.handleVadEvent(
      'peer', //src,
      key === 'local' ? 'mouth' : 'ear', // pov
      null, //tap,
      label, //vadType
      val
    );
  };

  // handle local or remote vad event
  handleVadEvent = (src, pov, tap, vadType, val, t0) => {
    //console.log(`handleVadEvent src=${src}, pov=${pov}, vadType=${vadType}, val=${val}, autoMuteEnable=${this.autoMuteEnable}.`)

    if (
      this.autoMuteEnable &&
      src === 'streamProcessor' &&
      pov === 'mouth' &&
      vadType === 'Linto'
    ) {
      this.rsp.setMuted(val);
      this.onAutoMuteEvent(val);
    }
  };
}

export default EchoSuppressor;
