//import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
//eliminate warning: findDOMNode is deprecated in StrictMode

//TODO2:  replace unstable_createMuiStrictModeTheme when mui V5 is available
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';

import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

// to change theme programmatically
// https://stackoverflow.com/questions/55846691/react-material-ui-theme-change

export default responsiveFontSizes(
  createMuiTheme({
    // prevent automatic capitalization of button legends
    typography: {
      button: {
        textTransform: 'none',
      },
    },
    palette: {
      type: 'dark',
      primary: {
        main: purple[500],
        veryLight: purple[300],
      },
      secondary: {
        main: green[500],
        veryLight: green[300],
      },

      // invoke inline: style={theme.palette.demo1}
      demo1: {
        backgroundColor: '#E44D69',
        color: '#000',
      },
      // invoke through class
      /**
       * root: {
       *   backgroundColor: theme.palette.demo2.main,
       *   color: theme.palette.demo2.contrastText
       * }
       */
      demo2: {
        main: '#376a99',
        contrastText: '#d0e4f7',
      },
    },
    breakpoints: {
      custom: {
        vxsThreshold: 620, //vertically extra short threshold
      },
    },
  })
);
