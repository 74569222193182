/**
 * Render a text or password field
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({}));

const LogRegFieldText = ({
  field,
  onFieldChange,
  classes: classesOverride,
}) => {
  const classes = { ...useStyles(), ...classesOverride };
  return field ? (
    <TextField
      key={field.key}
      id={field.key}
      color="secondary"
      required={field.required}
      label={field.title}
      type={field.type}
      onChange={
        onFieldChange && (e => onFieldChange(field.key, e.target.value))
      }
      helperText={field.helper}
      variant="outlined"
      value={field.value}
      error={field.error}
    />
  ) : null;
};

LogRegFieldText.propTypes = {
  field: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.node,
    error: PropTypes.bool,
    required: PropTypes.bool,
    type: PropTypes.string,
  }),
  onFieldChange: PropTypes.func, // onFieldChange(key, value)
};

export default LogRegFieldText;
