/**
 * Detect user activity at open browser.
 * Trigger awake/sleep transition event after period of inactivity.
 * Accept subscriptions for event reporting.
 *
 * //@@ consider refactoring with https://github.com/serkanyersen/ifvisible.js
 */

import { SLEEP_LATENCY_MINUTES } from 'configs/config-hvn';

export class ActivityDetector {
  isAwake = null; // the user has been active
  paused = false; // pause event reporting ( don't report sleep in case of inactivity )

  subscriptions = []; // callback list for event reporting
  currentTimeout = null; // handle of the timeout in progress
  lastPingSec = null; // time stamp of last detected activity

  constructor() {
    // detect mouse or touchpad activity
    document.addEventListener('pointerdown', event => {
      //@@ consider forcing sleep if unexpected loss of socket
      this.ping('pointerdown');
    });

    this.ping('constructor');
    this.subscribe(isAwake =>
      console.log(`User ${isAwake ? 'Awake' : 'Asleep'}.`)
    );
  }

  // go to sleep
  sleep = options => {
    this.isAwake && this.noteTransition(0, options);
  };

  // recognize user activity
  ping = label => {
    // retrigger the timeout
    this.currentTimeout && clearTimeout(this.currentTimeout);
    this.currentTimeout = setTimeout(() => {
      this.noteTransition(0); // user fell asleep
    }, SLEEP_LATENCY_MINUTES * 60000);

    this.isAwake || this.noteTransition(1); //user woke up
    this.isAwake = true;
    this.lastPingSec = Math.floor(Date.now() / 1000); // convert to seconds
  };

  // determine how long until sleep
  getTillSleepSec = () => {
    if (this.paused) {
      return SLEEP_LATENCY_MINUTES * 60;
    }
    const nowSec = Math.floor(Date.now() / 1000); // convert to seconds
    const sinceLastPingSec = nowSec - this.lastPingSec;
    const tillSleepSec = this.isAwake
      ? SLEEP_LATENCY_MINUTES * 60 - sinceLastPingSec
      : 0;
    return tillSleepSec || 0;
  };

  // report when user has fallen asleep or woke up
  noteTransition = (isAwake, options = null) => {
    this.isAwake = isAwake;

    // report event to subscribed callbacks
    this.paused ||
      this.subscriptions.forEach(fn => {
        typeof fn === 'function' && fn(isAwake, options);
      });
  };

  // add a callback to the subscription list
  subscribe = fn => {
    if (typeof fn === 'function') {
      this.subscriptions = [...this.subscriptions, fn];
    }
  };

  // unsubscribe from events
  unsubscribe = fn => {
    this.subscriptions = this.subscriptions.filter(s => s !== fn);
  };

  // temporarily discontinue reporting to subscribers
  pause = label => {
    //console.log(`ActivityDetector paused by ${label}.`);
    this.paused = true;
  };

  // resume reporting to subscribers
  resume = label => {
    //console.log(`ActivityDetector resumed by ${label}.`);
    this.paused = false;
    this.ping('resume');
  };

  // cleanup and terminate processing
  destroy = async () => {
    this.paused = true;
    this.subscriptions = [];
    this.currentTimeout && clearTimeout(this.currentTimeout);
  };
}

// allocate a singleton instance
global.ad = new ActivityDetector(); //@@ diagnostic only
export const activityDetector = global.ad;
