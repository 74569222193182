/** A responsive template for an audio appliance (player, recorder, etc.).
 *  Comprising a banner (text or react component), a body (one or more
 *  react components), and an actions menu to manage user actions
 *  related to the components rendered in the body.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import ContextualHelpButton from 'components/utils/ContextualHelpButton';

const useStyles = makeStyles(theme => ({
  hCenter: {
    height: '100%',
  },

  vCenter: {
    //border: "1px solid red",
    height: '100%',
    // restrict width unless viewport is short vertically
    // width: '400px',
    // [`@media (max-height: ${theme.breakpoints.custom.vxsThreshold}px)`]: {
    //     width: '100%',
    // },
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  body: {
    borderRadius: '12px',
    padding: '1rem 0rem',
    marginBottom: '1rem',
  },
  banner: {
    borderRadius: '12px',
    padding: '1rem 0rem',
    marginBottom: '1rem',
  },
  helpButton: {
    margin: 0,
    marginLeft: 10,
    padding: 0,
  },
}));

const ApplianceLayout = ({ banner, body, actions, noWrapBody }) => {
  const classes = useStyles();
  const vxs = useSelector(state => state.ui.vxs);

  // detect virtual keyboard on mobile device
  const vk = useSelector(state => state.ui.deviceInfo.virtualKeyboardActive);

  // prepare banner for rendering
  // if it is a string, format it
  // otherwise just render it
  const bannerPrepared = () =>
    vxs
      ? Boolean(banner?.title && !vk) && (
          <div style={{ marginBottom: '1rem' }}>
            {typeof banner?.title === 'string' ? (
              <Grid container justify="flex-start">
                <Typography variant="h6">{banner.title} </Typography>
                {banner.helpContext && (
                  <ContextualHelpButton
                    context={banner.helpContext}
                    classes={classes}
                  />
                )}
              </Grid>
            ) : (
              banner?.title
            )}
          </div>
        )
      : Boolean(banner?.title && !vk) && (
          <Paper elevation={3} classes={{ root: classes.banner }}>
            {typeof banner?.title === 'string' ? (
              <Grid container justify="center">
                <Typography variant="h6">{banner.title} </Typography>
                {banner.helpContext && (
                  <ContextualHelpButton
                    context={banner.helpContext}
                    classes={classes}
                  />
                )}
              </Grid>
            ) : (
              banner?.title
            )}
          </Paper>
        );

  // prepare body for rendering
  const bodySegments = () =>
    // Make array if body prop is not already
    // Then wrap each element individually
    // (skip null segments)
    (Array.isArray(body) ? body : [body]).map(
      segment =>
        segment && (
          <Paper
            elevation={noWrapBody ? 0 : 3}
            classes={{ root: classes.body }}
            style={noWrapBody ? { backgroundColor: 'transparent' } : null}
          >
            {segment}
          </Paper>
        )
    );

  //for virtual keyboard, eliminate banner and actions sections, and use flex-start
  return (
    <>
      {/* center children horizontally */}
      <Grid container justify="center" classes={{ root: classes.hCenter }}>
        {/* center children vertically */}
        <Grid
          item
          xs={12}
          sm={10}
          md={6}
          container
          direction="column"
          justify={vk ? 'flex-start' : 'center'}
          classes={{ root: classes.vCenter }}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              {bannerPrepared()}
              {bodySegments()}
            </Grid>
            {!vk && (
              <Grid item xs={12} container justify="center">
                {actions}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ApplianceLayout.propTypes = {
  banner: PropTypes.shape({
    title: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
    helpContext: PropTypes.string, // optional title for context sensitive help
  }),
  body: PropTypes.oneOfType([
    PropTypes.node, //can be a single component
    PropTypes.arrayOf(PropTypes.node), //or an array of components
  ]),
  actions: PropTypes.node,
  noWrapBody: PropTypes.bool, // inhibit border and background for body
};

ApplianceLayout.defaultProps = {
  noWrapBody: false,
};

export default ApplianceLayout;
