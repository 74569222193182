/**
 * System configuration constants
 */

import defaultRegistrationConfig from './communities/config-general';

export const brandWebsite = 'OkSayit.com';
export const BRAND = <span>OkSayit &trade;</span>;

/**
 * Select the server
 */

const serverInUse = 'hvn2';

// note: only one should have hostLabel: "production"
// all others should have "dev" within hostLabel
const servers = {
  hvn: {
    webHost: 'https://hvn.hotlynx.com',
    webRoot: 'https://hvn.hotlynx.com',
    apiRoot: '/jsonapi',
    hostLabel: 'dev-geeks',
  },
  hvn1: {
    webHost: 'https://hvn1.hotlynx.com',
    webRoot: 'https://hvn1.hotlynx.com',
    apiRoot: '/jsonapi',
    hostLabel: 'production',
  },
  hvn2: {
    webHost: 'https://hvn2.hotlynx.com',
    webRoot: 'https://hvn2.hotlynx.com',
    apiRoot: '/jsonapi',
    hostLabel: 'dev-hvn2',
  },
  localhost: {
    //PC-4 development
    webHost: 'http://localhost',
    webRoot: `http://localhost/hl/hvn`,
    apiRoot: '/jsonapi',
    hostLabel: 'dev-pc4',
  },
};

export const WEB_HOST = servers[serverInUse]['webHost'];
export const WEB_ROOT = servers[serverInUse]['webRoot'];
export const API_ROOT = servers[serverInUse]['apiRoot'];
export const HOST_LABEL = servers[serverInUse]['hostLabel'];

/*
 * back-end credentials and constants
 */

// administrative credentials
export const OAUTH_CLIENT = {
  id: '755c8af0-010f-486b-b1ce-5d91be96cfa9',
  secret: 'app',
  scope: '',
};
export const REGISTRAR_USER = {
  id: '2017b955-ea01-4b2c-a43d-5d7e367b2603',
  username: 'oauthUser',
  password: 'pw123',
  screenName: 'REGISTRAR',
}; // create new users
export const SUPPORT_USER = {
  id: '4bfdbc24-7f9b-4069-857d-c0ad9c97bbf0',
  username: 'SUPPORT_USER',
  password: '^u7m%ZnYDb8L',
  screenName: 'Support',
}; // receive support requests
export const PROXY_USER = {
  id: '469f5a1e-0297-4265-a350-b2b82240aaa1',
  username: 'PROXY_USER',
  password: 'i5FH&xsA0H3*',
  screenName: 'Visitor',
}; // proxy acct for unauthenticated posts

export const ROLE_OAUTH = '8471c3de-33ef-47bc-ac27-b09b5aeb43b7';
export const NEW_USER_DEFAULT_ROLE_UUID = ROLE_OAUTH;

// back end parameter, not returned by /oauth/token request
export const REFRESH_TOKEN_EXPIRATION_TIME = 1209600; // 2 weeks.
export const REFRESH_TOKEN_REFRESH_MARGIN = 86400; // 1 day.

// beta test requires new users to enter an invitation code
export const betaInvitationCode = '369874';

// twilio credentials
export const twilioAccountSid = 'ACe6a594f973087eb6637f1be041dc92ba';
export const twilioAuthToken = '66ed53971984d429f81f151ea8797d6d';
// derive twilioAuth by Base64 encoding `${twilioAccountSid}:${twilioAuthToken}`, perhaps using https://git.coolaj86.com/coolaj86/btoa.js.git
export const twilioBasicAuth =
  'QUNlNmE1OTRmOTczMDg3ZWI2NjM3ZjFiZTA0MWRjOTJiYTo2NmVkNTM5NzE5ODRkNDI5ZjgxZjE1MWVhODc5N2Q2ZA==';
export const twilioFrom = '+17654444446';
export const twilioFromFriendly = '765-444-4446';
export const notificationPhone1 = '+17564444446';

// Performance parameters
export const maxTitleLength = 50; // max. length of Topic field
export const minScreenNameLength = 3; //minimum length of screen name
export const forbiddenScreenNames = {
  // these names are disallowed
  words: ['joe'],
  substrings: ['admin', 'support', 'oksayit'],
};

// temporary admin credential
// TODO2: develop means to authenticate by role
export const adminCreds = '112218454086208544440'; // Joe's Drupal username
export const adminIdList = [
  '70714013-2802-5786-7000-000000000000', //Joe Facebook
  '11221845-4086-2085-4444-000000000000', //Joe Google
  '85576232-4300-0000-0000-000000000000', //Joe mobile
];

// signaling server configuration
const sigServerLocalPort = 5000;
export const sigServerConfig = {
  localServerPort: sigServerLocalPort,
  serverUrl:
    //  `http://localhost:${sigServerLocalPort}`
    'https://sig-server-1.herokuapp.com',
  // set false to use server's default PeerJs
  useLocalPeerJs: false,

  // enable turn server use
  useTurn: true,
};

// default Community
export const defaultCommunity = {
  partition: 'general',
  gated: false,
  title: 'General Discussion',
  params: defaultRegistrationConfig,
}; // General Discussion partition

// tips control
export const tipsControl = {
  frequency: 10, //no of pings between tips rendering
  maxCnt: 3, //max. number of times tips are displayed
};

// time interval for periodic upload of status to server
export const PERIODIC_STATUS_UPDATE_INTERVAL_MINUTES = 0; // disable if 0

// maximum user inactivity before sleep
export const SLEEP_LATENCY_MINUTES = 10;

// Facebook application
export const facebook_appId = '657148062844581'; //OkSayit app secret: 9b7da6aac30b74f881349f3121d1a320
//export const facebook_appId = '892396135609453'; //OkSayit Dev
