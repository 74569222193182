/**
 * Outgoing Message Success Report
 *
 * Provide instructions for user to deliver a message link
 * to a non-member recipient.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BorderedBlock from 'components/utils/BorderedBlock';
import { List, ListItem, ListItemText, Grid, Button } from '@material-ui/core';
import { enableOutgoingType } from 'lib/api/messageApi';
import { t } from 'lib/translation/trans';
import { htmlClipboard } from '../../../lib/outgoingMessageUtils';
import SuccessReportActions from './SuccessReportActions';

const showClipboard = false;

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    minWidth: 30,
  },
  // for BorderedBlock
  wrapperOuter: {
    marginBottom: 30,
  },
}));

const SuccessForm = props => {
  const { contentForClipboard, onExit, content } = props;
  const classes = useStyles();
  const showForm = enableOutgoingType && contentForClipboard;

  // present html of content that is, or will be in the clipboard
  const renderContentForClipboard = () =>
    contentForClipboard ? (
      <BorderedBlock
        labelTop={`Clipboard`}
        classes={classes}
        borderColor="grey"
      >
        {htmlClipboard(contentForClipboard)}
      </BorderedBlock>
    ) : null;

  const renderMainContent = () => (
    <List dense={true}>
      {content.details.map(item => (
        <ListItem key={item}>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );

  const renderActions = () => (
    <Grid container justifyContent="flex-end">
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={onExit}
      >
        {t('OK')}
      </Button>
    </Grid>
  );

  return showForm ? (
    <>
      {showClipboard ? renderContentForClipboard() : null}
      <BorderedBlock
        labelTop={content.heading}
        classes={{}}
        borderColor="#00ff00"
      >
        {renderMainContent()}
        <SuccessReportActions
          actions={content.actions}
          contentForClipboard={contentForClipboard}
          onExit={onExit}
        />
      </BorderedBlock>
    </>
  ) : null;
};

SuccessForm.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.string),
    actions: PropTypes.arrayOf(PropTypes.string),
  }),
  contentForClipboard: PropTypes.string,
  onExit: PropTypes.func,
};

SuccessForm.dafaultProps = {
  content: {},
  contentForClipboard: '',
  onExit: () => {},
};

export default SuccessForm;
