/**
 * Render a categorized list of communities.
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { currentPartition } from 'lib/api/communityApi';
import { List, ListSubheader, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { t, strToVar } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  listRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  listSection: {},
  ul: {
    padding: 0,
  },
  groupTitle: {},
  item: {
    'border': '2px green solid',
    'marginLeft': '4rem',
    'width': `calc(100% - 4rem )`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: `100%`,
    },
    'fontSize': '.5rem',
    '&:not(:last-child)': {
      marginBottom: '.5rem',
    },
  },
  itemText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '.7rem',
    },
  },
}));

const CommunityList = ({ categorizedCommunities, onChange, isDisabled }) => {
  const classes = useStyles();

  const handleClick = partition => onChange && onChange(partition);

  return (
    <List className={classes.listRoot} subheader={<li />}>
      {categorizedCommunities.map(grp => (
        <li key={`section-${grp.title}`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader
              classes={{ root: classes.groupTitle }}
              disableSticky
            >{`${t(strToVar(grp.title + ' Group'))}`}</ListSubheader>
            {grp.list.map(c => (
              <ListItem
                selected={currentPartition() === c.partition}
                classes={{ root: classes.item }}
                key={c.title}
                button
                disabled={isDisabled(c.partition)}
                onClick={() => handleClick(c.partition)}
              >
                <ListItemText
                  primaryTypographyProps={{
                    classes: { root: classes.itemText },
                  }}
                  primary={c.title}
                />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

CommunityList.propTypes = {
  categorizedCommunities: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  isDisabled: PropTypes.func,
};

export default CommunityList;
