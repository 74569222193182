/**
 * LoginView
 * Provides login/registration sequence inline (not in dialog)
 * ( prior version LoginView_v0 moved to archive )
 *
 *
 */

import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import RegistrationManager from 'containers/RegistrationManager';
import ButtonStd from 'components/utils/ButtonStd';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },
  loginWrapper: {
    width: '50%',
    padding: '1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const LoginView = ({ heading, onSuccess, onCancel }) => {
  const [retryCnt, setRetryCnt] = useState(0);
  const [isNewRegistration, setIsNewRegistration] = useState(false);

  const classes = useStyles();

  const handleLoginExit = result => {
    if (result.status === 'success') {
      onSuccess({ newRegistration: isNewRegistration });
    } else {
      setRetryCnt(c => c + 1);
    }
  };

  // error has occurred and has been acknowledeg by user... trigger retry.
  const handleRegistrationManagerPageTransition = key => {
    key === 'exceptionAck' && setRetryCnt(c => c + 1);
  };

  const handleRegistrationProgress = message => {
    // detect new registration
    message === 'Setting up your account...' && setIsNewRegistration(true);
  };

  const handleRegistrationManagerFieldTransition = (key, value) => {};

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography classes={{ root: classes.title }} variant="h4">
          Please Register or Login
        </Typography>
      </Grid>
      <Paper labelTop={heading} classes={{ root: classes.loginWrapper }}>
        <RegistrationManager
          key={retryCnt}
          renderWithinDialog={false}
          bypassGetAttributes={false}
          zen={false}
          onComplete={handleLoginExit}
          renderRegistrationProgress={handleRegistrationProgress}
          notePage={handleRegistrationManagerPageTransition}
          noteField={handleRegistrationManagerFieldTransition}
        />
      </Paper>
      <Grid item xs={12} container justifyContent="center">
        <ButtonStd centered color="primary" onClick={onCancel} label="Cancel" />
      </Grid>
    </Grid>
  );
};

LoginView.propTypes = {
  heading: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

LoginView.defaultProps = {
  heading: 'Please Register or Login',
  onSuccess: () => {},
  onCancel: () => {},
};

export default LoginView;
