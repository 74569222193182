import React from 'react';
import { Paper, Typography, Divider } from '@material-ui/core';
import { notificationPhone1, brandWebsite } from 'configs/config-hvn';

import { t } from 'lib/translation/trans';

const CtiaNotice = () => {
  return (
    <>
      <Typography variant="body" style={{ fontSize: 'small' }}>
        {' '}
        {t('When_you_enable_Notifications')}{' '}
      </Typography>
      <ol>
        <li style={{ marginBottom: 5, fontSize: 'small' }}>
          {' '}
          {`${t('You_give_approval_for')} ${brandWebsite} ${t(
            'to_send_notification_messages_to_your_phone'
          )}`}
        </li>
        <li style={{ marginBottom: 5, fontSize: 'small' }}>
          {' '}
          {`${t(
            'Messages_will_be_sent_from_the_number'
          )} ${notificationPhone1}.`}{' '}
        </li>
        <li style={{ marginBottom: 5, fontSize: 'small' }}>
          {' '}
          {t('You_can_revoke_approval')}{' '}
        </li>
        <li style={{ marginBottom: 5, fontSize: 'small' }}>
          {' '}
          {t('Carrier_charges_may_apply')}{' '}
        </li>
      </ol>
    </>
  );
};
export default CtiaNotice;
