/**
 * utilities to support special processing of
 * "outgoing" message type.
 *
 * Intended for non-member recipients, message is
 * transmitted by email or SMS to recipient.
 * Text content includes a hyperlink to the Message.
 */

// generate plain text to be sent to recipient
export const getClipboardText = ({ type, from, title, code, linkText }) =>
  type === 'outgoing'
    ? `From ${from}:\n"${title}"\n\nVoice:\n${linkText}\n\nCode: ${code}`
    : `${title}  \n\n${linkText}`;

// convert plain text to html for rendering to sender
export const htmlClipboard = clipboard => {
  const clipArr = clipboard.split('\n');
  return (
    <div>
      {clipArr.map((line, ix) =>
        line.length ? <div key={ix}>{line}</div> : <p key={ix}></p>
      )}
    </div>
  );
};
