import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    //    margin: theme.spacing(3),
  },
}));

export default function ZapperCheckBoxes({ boxes, setBoxes }) {
  const classes = useStyles();
  const { check1, check2, check3 } = boxes;

  const handleChange = event => {
    setBoxes({ ...boxes, [event.target.name]: event.target.checked });
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Remove Everything ??</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={boxes.check1}
                onChange={handleChange}
                name="check1"
              />
            }
            label="Really"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={boxes.check2}
                onChange={handleChange}
                name="check2"
              />
            }
            label="Really Really"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={boxes.check3}
                onChange={handleChange}
                name="check3"
              />
            }
            label="REALLY REALLY REALLY!"
          />
        </FormGroup>
        <FormHelperText>Check all boxes, then press 'Remove'</FormHelperText>
      </FormControl>
    </div>
  );
}
