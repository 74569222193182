/**
 *  Alternative rendering of Player controls
 *  Including play/pause, skip forward/back
 */

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import ApplianceControls, {
  wildcard,
} from 'components/utils/ApplianceControls';
import { t } from 'lib/translation/trans';

const ControlsCompact = ({
  isPlaying,
  togglePlay,
  onNext,
  onPrevious,
  classes,
  noteWidth,
}) => {
  const widthRef = useRef();

  useEffect(() => {
    noteWidth && noteWidth(widthRef.current.clientWidth);
  }, []);

  const togglePlayDelayed = () => {
    setTimeout(() => togglePlay(), 300);
  };

  const controlButtons = () => [
    {
      label: t('Previous'),
      icon: <SkipPreviousRoundedIcon />,
      visible: [Boolean(onPrevious) ? wildcard : 'x'],
      enabled: [wildcard],
      onClick: onPrevious,
    },
    {
      label: t('Pause'),
      icon: <PauseRoundedIcon />,
      visible: [isPlaying ? wildcard : 'x'],
      enabled: [wildcard],
      onClick: togglePlay,
    },
    {
      //disable bookmark button if already bookmarked
      label: t('Play'),
      icon: <PlayArrowRoundedIcon />,
      visible: [isPlaying ? 'x' : wildcard],
      enabled: [wildcard],
      onClick: togglePlay, //@@ togglePlayDelayed??
      //fab: true,
    },
    {
      label: t('Next'),
      icon: <SkipNextRoundedIcon />,
      visible: [Boolean(onNext) ? wildcard : 'x'],
      enabled: [wildcard],
      onClick: onNext,
    },
  ];

  return (
    <div ref={widthRef}>
      <ApplianceControls
        buttons={controlButtons()}
        groups={false}
        legends={false}
      />
    </div>
  );
};

ControlsCompact.propTypes = {
  // used by original Controls
  isPlaying: PropTypes.bool.isRequired,
  togglePlay: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  classes: PropTypes.shape({}),
  noteWidth: PropTypes.func,
};

ControlsCompact.defaultProps = {
  onNext: null,
  onPrevious: null,
};
export default ControlsCompact;
