/**
 * Render a standard accordion with content supplied in props
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    'border': '1px solid rgba(0, 0, 0, .125)',
    'boxShadow': 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    'backgroundColor': 'rgba(0, 0, 0, .03)',
    'borderBottom': '1px solid rgba(0, 0, 0, .125)',
    'marginBottom': -1,
    'minHeight': 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function AccordionStd({ sections, initialOpen, onChange }) {
  const [expanded, setExpanded] = React.useState(initialOpen);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    onChange && onChange(panel);
  };

  return sections && Array.isArray(sections) ? (
    <div style={{ width: '100%' }}>
      {sections.map(section => (
        <Accordion
          key={section.key}
          square
          expanded={expanded === section.key}
          onChange={handleChange(section.key)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {section.heading}
            {section.subHeading}
          </AccordionSummary>
          <AccordionDetails>{section.details}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  ) : null;
}

AccordionStd.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      heading: PropTypes.node,
      subHeading: PropTypes.node,
      details: PropTypes.node,
    })
  ),
  initialOpen: PropTypes.string, // initial open section
  onChange: PropTypes.func,
};
AccordionStd.defaultProps = {
  initialOpen: null,
};
