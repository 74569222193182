/**
 * A wrapper for an Audio object that supports programmatic play
 * after any prior document pointerdown event
 *
 */

// list of audio snippets to be loaded
const urls = ['/audio/ding-1.mp3', '/audio/ding-2.mp3'];

export class Dinger {
  audio = [];
  isLoaded = [];
  playRequestPending = []; // flag to indicate that a play request arrived before isLoaded
  canplayHandlers = []; // methods to be referenced in respective removeEventListener invocations

  constructor() {
    // detect mouse or touchpad activity
    console.log('Dinger construct.');
    document.addEventListener('pointerdown', this.handlePointerDown);

    // prepare a list of canplaythrough event handler functions
    urls.forEach((url, ix) => {
      this.canplayHandlers[ix] = () => this.handleCanplaythrough(ix);
    });
  }

  // user has clicked on something, load audio
  handlePointerDown = event => {
    console.log('Dinger loading.');
    urls.forEach((url, ix) => {
      this.audio[ix] = new Audio(url);
      this.audio[ix].load();
      this.audio[ix].addEventListener(
        'canplaythrough',
        this.canplayHandlers[ix]
      );
    });

    // assure one-time-only execution
    document.removeEventListener('pointerdown', this.handlePointerDown);
  };

  handleCanplaythrough = ix => {
    console.log(`handleCanplaythrough ix=${ix}`);
    this.isLoaded[ix] = true;
    if (this.playRequestPending[ix]) {
      console.log(`Dinger fulfilling ix=${ix}.`);
      this.playRequestPending[ix] = false;
      this.play(ix);
    }
    // assure one-time-only execution for each ix
    this.audio[ix].removeEventListener(
      'canplaythrough',
      this.canplayHandlers[ix]
    );
  };

  play = (ix = 0) => {
    console.log(`Dinger play ix=${ix}.`);
    if (this.isLoaded[ix]) {
      console.log(`Dinger playing ix=${ix}`);
      // file is ready to play
      this.audio[ix] &&
        this.audio[ix].play().catch(e => {
          console.log('dinger play error:', e);
        });
    } else {
      console.log('Dinger pending ix=${ix}.');
      this.playRequestPending = true;
    }
  };
}

// allocate a singleton instance
//export const dinger = new Dinger();

global.dinger = new Dinger(); //@@ diagnostic only
export const dinger = global.dinger;
