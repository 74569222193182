/**
 * Authenticate using Facebook Identity Services
 *
 * 1. Dynamically load and initialize the API
 * 2. Initiate Login (override default behavior of FacebookLogin which renders a button)
 * 3. Report results to parent component.
 *
 *
 * Omissions:
 *  - btnRef.current.click() not tested in all environments
 *  - detect ad blocker
 *  - retry on fail
 *  - retrieval of email address
 *  - concern that facebook login/settings page (on dashboard) presents a message:
 *      "Your app has standard access to public_profile. To use Facebook Login,
 *      switch public_profile to advanced access. Get Advanced Access"
 *
 * References
 *  - dashboard: https://developers.facebook.com/apps/{appId}/dashboard/
 *  - https://github.com/keppelen/react-facebook-login
 *  - https://developers.facebook.com/docs/javascript/reference/v16.0
 *
 *
 *
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'; // this form uses custom button
import { CircularProgress } from '@material-ui/core';
import { t } from 'lib/translation/trans';
import { timeout } from 'lib/utils/utils';
import { facebook_appId } from 'configs/config-hvn';

const redirect_uri = '';

function FacebookAuthenticator({ setHeading, onSuccess, onFail, zen }) {
  const btnRef = useRef();
  const isFbApiLoaded = window.FB && window.FB.login;

  // automatically initiate login sequence
  useEffect(() => {
    setHeading && setHeading(t('Logging_in_with_Facebook'));
    timeout(1000)
      .then(() => {
        if (window.FB) {
          btnRef.current.click();
        } else {
          onFail && onFail(t('Facebook_login_not_available'));
        }
      })
      .catch(err => {
        onFail && onFail(t('Facebook_login_cannot_connect'));
      });
  }, []);

  const handleSuccess = response => {
    if (response.status === 'unknown') {
      onFail && onFail(t('Facebook_dialog_closed_by_user'));
    } else if (response.accessToken) {
      // normalize response
      onSuccess &&
        onSuccess({
          socId: response.id,
          email: response?.email,
          realName: response?.name,
          image: response?.picture?.data?.url,
          Authenticator: 'Facebook',
        });
    } else {
      onFail && onFail(t('Facebook_login_failed'));
    }
  };

  return (
    <>
      <FacebookLogin
        appId={facebook_appId}
        autoLoad={false}
        fields="name,email,picture"
        scope="public_profile"
        callback={handleSuccess}
        render={renderProps => (
          <>
            {/* the button should be invisible and not take up space.  Intended to be programmatically clicked. */}
            <button
              ref={btnRef}
              style={{
                height: 0,
                width: 0,
                margin: 0,
                padding: 0,
                border: 'none',
                position: 'absolute',
              }}
              onClick={renderProps.onClick}
            />
          </>
        )}
      />
      <div style={{ textAlign: 'center' }}>
        {zen || (
          <>
            <div style={{ marginBottom: '.5rem' }}>
              {t('Awaiting_Facebook')}
            </div>
            <CircularProgress color="inherit" />
          </>
        )}
      </div>
    </>
  );
}

FacebookAuthenticator.propTypes = {
  setHeading: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  zen: PropTypes.bool, //simplify rendering
};

export default FacebookAuthenticator;
