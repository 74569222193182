/**
 * User Interface for audio Player
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Visualizer from './components/Visualizer';
import Progress from './components/Progress';
import Controls from './components/Controls';
import ControlsCompact from './components/ControlsCompact';

import WithOptionControls from 'hoc/WithOptionControls';
import { optionControlsBrowserView } from 'hoc/WithOptionControls/lib/optionControlsBrowserView';
import { grey } from '@material-ui/core/colors';
import { settings as visualizerSettings } from './components/Visualizer/visualizerProfiles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  playerUiContainer: {
    justifyContent: 'center',
  },

  hControlsContainer: {
    width: '100%',
    // background: "grey",  //use 'transparent' for theme2
    borderRadius: '20px',
    border: '3px solid #9c27b0',
  },
  hControlsProgress: {
    width: '50%',
    paddingTop: 5,
  },

  playerInnerContainer: {
    overflow: 'hidden',
    width: visualizerSettings.size.std.width,
    [theme.breakpoints.down('xs')]: {
      width: visualizerSettings.size.xs.width,
    },
  },
  pauseButton: {
    padding: 8,
    backgroundColor: 'grey',
  },
}));

// can customize Progress styling
const useProgressStyles = makeStyles({
  sliderCustomize: {
    //paddingTop: 0
  },
});

// render Progress integrated with Visualizer
const useVisualizerProgressStyles = makeStyles(theme => ({
  root: {
    color: 'grey',
    padding: 0,
    position: 'absolute',
    top: 0,
    left: 5,
    width: 290,
    [theme.breakpoints.down('xs')]: {
      width: 190,
    },
  },
  thumb: {
    backgroundColor: theme.palette.secondary.light, //'#fff',
    border: '2px solid currentColor',
  },
  track: {
    // trails the thumb
  },
  rail: {
    //preceeds the thumb
    color: 'transparent',
  },
}));

const PlayerUI = props => {
  const {
    src,
    audioEl,
    isPlaying,
    noBrowserViewControl,
    visualizerPausedContent,
    titleComponent,
    onNext,
    onPrevious,
    togglePlay,
    //makeVisualizerAvailable,
    customComponent,
  } = props;

  // visualizer is NOT supported by Safari
  // fallback to alternative rendering
  const isSafari =
    useSelector(state => state.ui.deviceInfo.browser) === 'safari';
  const notSupported = i => false; // was:  (isSafari && i === 'visualizer')
  const safariFallback = ['controls', 'progress'];
  const uiItems = props.uiItems.reduce(
    (acc, i) => [...acc, ...(notSupported(i) ? safariFallback : [i])],
    []
  );
  const [progressWidth, setProgressWidth] = useState('100%'); // default value can be dynamically updated
  const prettoStyle = uiItems.includes('progressPretto');

  const classes = { ...useStyles(), ...props.classes };
  const progressClasses = useProgressStyles();
  const visualizerProgressClasses = useVisualizerProgressStyles();

  const title = key => titleComponent;

  // visualizer with functional overlays (progress, play/pause )
  const visualizer = key => (
    <div style={{ position: 'relative' }}>
      <Visualizer
        key={key} //@@makes no sense, intention was to dynamically re-render, no longer desired
        audioEl={audioEl}
        src={src}
        noWrap
        paused={!isPlaying}
        classes={classes}
        pausedContent={<div></div>}
      />
      <Progress
        key={src}
        audioEl={audioEl}
        classes={visualizerProgressClasses}
      />
      <div style={{ position: 'absolute', top: 20 }}>
        <Controls
          isPlaying={isPlaying}
          togglePlay={() => {
            togglePlay(!isPlaying);
          }}
          onNext={onNext}
          onPrevious={onPrevious}
          legends={false}
          classes={classes}
        />
      </div>
    </div>
  );

  // render props.customComponent
  const custom = key => customComponent;

  // render a progress bar
  const progress = key => (
    <Grid key={key} item xs={12} container justify="center">
      <div style={{ width: progressWidth }}>
        {' '}
        <Progress
          key={src}
          audioEl={audioEl}
          classes={progressClasses}
          prettoStyle={prettoStyle}
        />
      </div>
    </Grid>
  );

  // render a set of Player controls
  // when rendered, match Progress width to these controls
  const controls = key => (
    <Grid key={key} item xs={12} container justify="center">
      <Controls
        isPlaying={isPlaying}
        togglePlay={() => {
          togglePlay(!isPlaying);
        }}
        onNext={onNext}
        onPrevious={onPrevious}
        classes={classes}
        noteWidth={w => setProgressWidth(0.8 * w)}
      />
    </Grid>
  );

  // render a compact collection of Player controls
  // when rendered, match Progress width to these controls
  const controlsCompact = key => (
    <Grid key={key} item xs={12} container justify="center">
      <ControlsCompact
        isPlaying={isPlaying}
        togglePlay={() => {
          togglePlay(!isPlaying);
        }}
        onNext={onNext}
        onPrevious={onPrevious}
        classes={classes}
        noteWidth={w => setProgressWidth(0.8 * w)}
      />
    </Grid>
  );

  // render controls/progress in horizontal configuration
  const hControls = key => (
    <Card
      elevation={0}
      key={key}
      classes={{ root: classes.hControlsContainer }}
    >
      <Grid container justify="space-around" alignItems="center">
        <div>
          <ControlsCompact
            isPlaying={isPlaying}
            togglePlay={() => {
              togglePlay(!isPlaying);
            }}
            onNext={onNext}
            onPrevious={onPrevious}
            classes={classes}
          />
        </div>
        {
          <div className={classes.hControlsProgress}>
            <Progress key={src} audioEl={audioEl} classes={classes} />
          </div>
        }
      </Grid>
    </Card>
  );

  // the collection of available components that can be included in the UI
  const uiComponents = [
    { label: 'title', render: title },
    { label: 'visualizer', render: visualizer },
    { label: 'progress', render: progress },
    { label: 'progressPretto', render: progress },
    { label: 'controls', render: controls },
    { label: 'controlsCompact', render: controlsCompact },
    { label: 'hControls', render: hControls },
    { label: 'custom', render: custom },
  ];

  // render the collection of components specified in props.uiItems
  const player = () => (
    <Grid classes={{ root: classes.playerUiContainer }} container>
      {uiItems.map(label =>
        uiComponents.find(item => item.label === label)?.render(label)
      )}
    </Grid>
  );
  // render (optionally) with PlaylistView view controls
  return noBrowserViewControl ? (
    player()
  ) : (
    <WithOptionControls controls={optionControlsBrowserView}>
      {player()}{' '}
    </WithOptionControls>
  );
};

PlayerUI.propTypes = {
  // source
  src: PropTypes.string.isRequired,
  audioEl: PropTypes.instanceOf(Audio),
  isPlaying: PropTypes.bool,

  // UI options
  classes: PropTypes.object,
  noBrowserViewControl: PropTypes.bool,
  visualizerPausedContent: PropTypes.node,
  titleComponent: PropTypes.node,
  uiItems: PropTypes.arrayOf(
    PropTypes.oneOf([
      'title',
      'visualizer',
      'progress',
      'progressPretto',
      'controls',
      'controlsCompact',
      'hControls',
      'custom',
    ])
  ),

  // event handlers
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  togglePlay: PropTypes.func,
  makeVisualizerAvailable: PropTypes.func,
  customComponent: PropTypes.node,
};

PlayerUI.defaultProps = {
  noBrowserViewControl: true, //inhibit browser view controls by default
  uiItems: ['title', 'visualizer', 'progress', 'controls'],
  togglePlay: () => {},
};

export default PlayerUI;
