/**
 * Renders annotations related to the message author
 */

import React from 'react';
import PropTypes from 'prop-types';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  //console.log("theme:", theme);
  return {
    container: {
      marginLeft: '1rem',
      display: 'inline',
    },
    icon: {
      width: '.6em',
      height: '.6em',
    },
    red: {
      color: 'red',
    },
    green: {
      color: theme.palette.secondary.light,
    },
  };
});

const AuthorAnnotation = ({ message }) => {
  const classes = useStyles();

  // provide icon if author is bookmarked or blacklisted
  const marked = () =>
    message?.blacklisted ? (
      <ThumbDownIcon className={`${classes.icon}`} />
    ) : message?.bookmarked ? (
      <FavoriteIcon className={`${classes.icon} ${classes.red}`} />
    ) : (
      ''
    );

  return <div className={classes.container}>{marked()}</div>;
};

AuthorAnnotation.propTypes = {
  message: PropTypes.object,
};
export default AuthorAnnotation;
