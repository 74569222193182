/**
 * ProblemReport
 *
 * Render a list of exception messages
 */

import React, { useEffect } from 'react';
import UIfx from 'uifx';
import BorderedBlock from './BorderedBlock';
import ButtonStd from './ButtonStd';
import { makeArray } from 'lib/utils/utils';
import { t } from 'lib/translation/trans';

const uhoh = new UIfx('/audio/uhoh2a.mp3', { volume: 0.1 });

const ProblemReport = ({ exceptions, onExit }) => {
  useEffect(() => {
    uhoh.play();
  }, []);

  const handleClick = () => onExit && onExit();
  return (
    <BorderedBlock labelTop={t('Problems')} classes={{}} borderColor="red">
      <ul>
        {makeArray(exceptions).map((x, ix) => (
          <li key={ix}>{x.msg}</li>
        ))}
      </ul>
      <ButtonStd label={t('OK')} onClick={handleClick} />
    </BorderedBlock>
  );
};

export default ProblemReport;
