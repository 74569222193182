/**
 *  Render RegistrationManager in a dialog
 *
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import Dialog2 from 'components/Modals/Dialog2';
import ButtonStd from 'components/utils/ButtonStd';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import RegistrationManager from '../../RegistrationManager';

//@@ duplicated in RegistrationManager
import {
  regHelpers,
  RegPages,
} from 'containers/RegistrationManager/lib/registrationUtils';
import { t } from 'lib/translation/trans';

// Handle Community Registration and Login workflow
// community sselected by by props, or by redux, or default
const LogRegDialog = () => {
  const { isAuthenticated } = useSelector(state => state.user);
  const { regDialog } = useSelector(state => state.ui);
  const dispatch = useDispatch();
  const fullScreen = true;

  // user requested that dialog be closed.
  // authentication and login may have already been completed
  const handleDialogCancel = () => {
    dispatch(uiActions.setRegDialog({ open: false, mode: null }));
  };

  const renderDialog = () => (
    <Dialog2
      open={regDialog.open}
      onClose={null}
      heading={t('Login')}
      actions={
        /* on Dialog 'Cancel' click, report whether user has already been authenticated */
        <ButtonStd
          color="secondary"
          onClick={handleDialogCancel}
          variant="text"
          label={t('Cancel')}
        />
      }
      content={<RegistrationManager renderWithinDialog={true} />}
    />
  );

  const renderFullScreen = () => (
    <FullScreenDialog
      open={regDialog.open}
      title={`${t('Login')} -- ${t('Registration')}`}
      onClose={handleDialogCancel}
      content={renderDialog()}
    />
  );

  return fullScreen ? renderFullScreen() : renderDialog();
};

export default LogRegDialog;
