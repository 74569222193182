/**
 * Message Recorder state definitions
 * and state-dependent action button behavior definition
 */

import keyMirror from 'keymirror';

import {
  Mic as MicIcon,
  Stop as StopIcon,
  PlayCircleFilled as PlayIcon,
  PauseCircleFilled as PauseIcon,
  //    Save as SaveIcon,
  CloudUpload as SaveIcon,
  Cancel as CancelIcon,
  Send as SendIcon,
  DoneOutline as DoneIcon,
  GetApp as DownloadIcon,
  ThumbUpAlt as MicApprovedIcon,
  Login as LoginIcon,
} from '@material-ui/icons';
import { Login } from 'mdi-material-ui';

// message recorder states
export const MRS = keyMirror({
  stage1: null, // VoiceRecorder actions are in progress
  stage2Anon: null, // gathering metadata from unregistered user
  stage2Auth: null, // completing metadata from registered user
  save: null, // saving message
  success: null, // successful completion
  problem: null, // problem or exception has been detected
  login: null, // anonymous user is logging in
  join: null, // non member in process of joining
  joinProgress: null, // new membership is being processed
});

export const btnStates = [
  { label: MRS.stage1, visible: [], enabled: [] },
  {
    label: MRS.stage2Anon,
    visible: ['Download', 'Save', 'Login', 'Cancel'],
    enabled: ['Download', 'Login', 'Cancel'],
  },
  {
    label: MRS.stage2Auth,
    visible: ['Save', 'Cancel'],
    enabled: ['Save', 'Cancel'],
  },
  { label: MRS.save, visible: ['Cancel'], enabled: ['Cancel'] },
  { label: MRS.success, visible: [], enabled: [] },
  { label: MRS.problem, visible: [], enabled: [] },
  { label: MRS.login, visible: [], enabled: [] },
  { label: MRS.join, visible: [], enabled: [] },
  { label: MRS.joinProgress, visible: [], enabled: [] },
];

// supported button labels and icons
// defined as key:value pairs
export const recIconMap = {
  Download: <DownloadIcon />,
  Save: <SaveIcon applyEnabledStyle={true} />,
  Login: <Login />,
  Cancel: <CancelIcon />,
  Send: <SendIcon />,
};
