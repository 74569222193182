/**
 * CallProgressDialog
 * Renders a modal dialog describing LiveCall progress
 * It is opened when a user asserts that he/she is ready to connect
 * For the callee, this takes place upon accepting an invitation
 * For the caller, it takes place upon asserting connect after receiving an acceptance
 *
 * - renders information
 *      - caller name and attributes
 *      - call duration
 * - renders call status:
 *      - ringing (preparing to complete a connection)
 *      - Connected (live talking)
 *      - Ended (tearing down the connection)
 * - renders live call actions:
 *      - hangup
 *      - bookmark (callee only, can be applied during conversation)
 *      - blacklist (callee only, can be applied during conversation)
 *
 * - Notes
 *      - dialog can only be closed by 'hangup' button
 *      - this implementation uses the "callee-await" protocol (callee awaits caller to connect)
 *
 * punchlist
 * * mute any playback while dialog is open
 * *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog2 from 'components/Modals/Dialog2';
import { useSelector } from 'react-redux';
import { Grid, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AuthorAnnotation from 'containers/MessagePlayer/components/AuthorAnnotation';
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';
import MediaView from 'components/WebRTCDemo/components/MediaView';
import ButtonStd from 'components/utils/ButtonStd';
import { t } from 'lib/translation/trans';
import { getCallManager } from 'lib/api/webRTCApi';
import { localStreamProcessor, remoteStreamProcessor } from 'lib/api/webRTCApi';
import useEchoSuppressor from 'hooks/useEchoSuppressor';
import CallStatus from '../CallStatus';
import CallTimeIndicator from './components/CallTimeIndicator';
import ProgressIndicator from './components/ProgressIndicator';
import CallSettings from './components/CallSettings';

const callProc = getCallManager();

const useStyles = makeStyles(theme => ({}));

export default function CallProgressDialog({ open, onClose, message }) {
  const [expanded, setExpanded] = useState(false);
  const { call } = useSelector(state => state.webRTC);
  const screenName = useSelector(
    state => state.webRTC.participants?.remote?.screenName
  );
  const { langMap: t1 } = useSelector(state => state.lang);

  const classes = useStyles();
  const localStream =
    localStreamProcessor && localStreamProcessor.getOutputStream();
  const remoteStream =
    remoteStreamProcessor && remoteStreamProcessor.getOutputStream();
  const { progress, startTime } = call;

  // invoke useEchoSuppressor to instantiate object, but locally there is no need to reference it.
  /* !! DO NOT REMOVE */ const echoSuppressorObject = useEchoSuppressor(); // !! DO NOT REMOVE

  // handle call progress changes
  useEffect(() => {
    switch (progress) {
      case 'connected':
      case 'hungup': // graceful completion of call
      case 'cancelled':
      case 'disconnected':
      case 'problem':
      case 'noService':
      case 'ready':
      case 'invited':
      case 'declined':
      case 'accepted':
      case 'ended': // reset for next call
      default:
        break;
    }
  }, [progress]);

  const handleReset = () => {
    callProc.handleLocalEvent({ type: 'reset' });
    onClose && onClose();
  };

  // similar processing in CalleeActions::handleCalleeAction
  const handleActionEvent = ev => {
    switch (ev) {
      case 'settingsClick':
        setExpanded(true);
        break; //setExpanded(true); break;
      case 'closeClick':
        setExpanded(false);
        break; //setExpanded(false); break;
      case 'hangupClick':
        callProc.handleLocalEvent({ type: 'hangup' });
        break;
      case 'blacklistConfirm':
        callProc.handleLocalEvent({ type: 'hangup' });
        break;
      default:
        break;
    }
  };

  const playerActions = (
    btnList = ['hangup', 'bookmark', 'unMark', 'blacklist']
  ) => (
    <PlayerActions
      legends={true}
      message={message}
      onEvent={ev => handleActionEvent(ev)}
      btnList={btnList}
    />
  );

  // provide a footer
  const renderCallInfo = () => {
    const style = { marginTop: '1rem', minWidth: '250px', textAlign: 'center' };
    return (
      <>
        <div style={style}>
          {' '}
          <CallTimeIndicator
            enable={progress === 'connected'}
            startTime={startTime}
          />{' '}
        </div>
        <div style={{ textAlign: 'center' }}>
          <CallStatus view={'summary'} />
        </div>
      </>
    );
  };

  const sections = () => ({
    heading: (
      <>
        <span>{`${t1.T$_Live_Call_with} ${screenName}`}</span>
        <AuthorAnnotation debugFlag={true} message={message} />
      </>
    ),
    body: (
      <div>
        <MediaView visible={false} stream={remoteStream} muted={false} />
        <ProgressIndicator progress={progress} />
        {progress === 'connected' && (
          <Collapse in={expanded}>
            <CallSettings
              enable={expanded}
              localStream={localStream}
              remoteStream={remoteStream}
            />
          </Collapse>
        )}
        {renderCallInfo()}
      </div>
    ),
    /* button list depennds on call progress state */
    actions: ['hungup', 'cancelled', 'disconnected', 'problem'].includes(
      progress
    ) ? (
      /* for end of call or failed call */
      <ButtonStd
        onClick={handleReset}
        label={t('Close')}
        color="secondary"
        centered
      />
    ) : (
      <Grid container justify="space-between">
        <Grid item>
          {progress === 'connected'
            ? /* while call is connected, include 'settings' button */
              playerActions([expanded ? 'close' : 'settings'])
            : null}
        </Grid>
        {/* default set of buttons */}
        <Grid item>{playerActions()}</Grid>
      </Grid>
    ),
  });

  return (
    <>
      <Dialog2
        open={open}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={onClose}
        heading={sections().heading}
        content={sections().body}
        actions={sections().actions}
      />
    </>
  );
}

CallProgressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};
