/**
 * Render a categorized tree of communities.
 * Initially show memberships expanded, and categorized communities collapsed.
 *
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { communityGroups } from 'lib/api/communityApi';
import { Divider } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { decorators, Treebeard } from 'react-treebeard';
import { default as treebeardDefaultStyles } from './treebeardDefaultStyles';
import { makeArray } from 'lib/utils/utils';
import { t, trans } from 'lib/translation/trans';
import { currentPartition } from 'lib/api/communityApi';

const useStyles = makeStyles(theme => ({}));

const treebeardStyles = {
  tree: {
    ...treebeardDefaultStyles.tree,
    base: {
      //border: '1px solid grey',
      backgroundColor: 'transparent',
    },
  },
};

const CommunityTree = ({ communities, onChange }) => {
  const [data, setData] = useState(null);
  const memberships = makeArray(
    useSelector(state => state.user?.auth?.user?.memberships)
  );

  // helper methods
  // recursively scan nodes object for an active node
  const findActiveNode = nodes =>
    makeArray(nodes).reduce((out, node) => {
      return out ? out : node?.active ? node : findActiveNode(node?.children);
    }, null);

  // make all nodes inactive
  const clearActiveNodes = nodes =>
    makeArray(nodes).forEach(node => {
      node.active = false;
      clearActiveNodes(node.children);
    });

  // make named node active
  const setActiveName = (nodes, name) =>
    makeArray(nodes).forEach(node => {
      node.active = node.name === name;
      setActiveName(node.children, name);
    });

  // organize the communitits list for display in Treebeard
  // Elements of the communities array contain title and group keys.
  useEffect(() => {
    const communityDecorator = {
      Header: props => {
        return (
          <div style={{ paddingLeft: 10, color: '#0096ef' }}>
            {`${props.node.name}`}
          </div>
        );
      },
    };

    const spacerDecorator = {
      Header: props => {
        return <Divider style={{ margin: '10px 0px' }} />;
      },
    };

    if (Array.isArray(communities)) {
      // node structure spec: https://github.com/storybookjs/react-treebeard#data-attributes
      const currentpartition = currentPartition();

      // identify the current partition
      const isCurrent = c => c.partition === currentpartition;

      // is the authenticated user a member of a given community?
      const isMember = c => memberships.includes(c.partition);

      // create a Treebeard node from the specified community array element
      const cNode = c => ({
        name: trans(c.title),
        active: isCurrent(c),
        partition: c.partition,
        decorators: communityDecorator,
      });

      const children = fn => communities.filter(c => fn(c)).map(c => cNode(c));

      // eliminate nodes containing single child by promoting child
      const promoteLoneChildren = node => {
        const numChildren = makeArray(node?.children).length;
        switch (numChildren) {
          case 0:
            return node; // keep child-less node unchanged
          case 1:
            // replace node with its child
            return promoteLoneChildren(node.children[0]);
          default:
            // keep node but flatten its childrene
            return {
              ...node,
              children: node.children.map(c => promoteLoneChildren(c)),
            };
        }
      };

      // prepare tree containing a branch of all communities
      // and a memberships branch duplicating the communities for which user is a member
      const buildTreeWithMemberships = () => {
        // seed node for building tree memberships and available communities branches
        const seedWithMemberships = {
          name: t('Communities'),
          toggled: true,
          children: [
            {
              name: t('Your_Memberships'),
              toggled: true, // initialize expanded
              children: [],
            },
            {
              name: '', // spacer node
              toggled: false,
              decorators: spacerDecorator,
            },
            {
              name: t('Available_Communities'),
              toggled: false,
              children: [],
            },
          ],
        };

        const memberships = seedWithMemberships.children[0];
        const availableCommunities = seedWithMemberships.children[2];

        // initial template with two children: [memberships, availableCommunities]
        // populate memberships
        memberships.children = children(c => isMember(c));

        // convert list of groups into Treebeard nodes
        // populate the availableCommunities children with the group nodes
        availableCommunities.children = communityGroups(communities)
          .map(g => g.title)
          .map(gn => ({ name: trans(gn), toggled: true, children: [] }));

        // populate each group with its children
        availableCommunities.children.forEach(
          g => (g.children = children(c => trans(c.group) === g.name))
        );
        return { ...seedWithMemberships };
      };

      // prepare tree containing only available communities
      const buildTreeBasic = () => {
        // seed node without memberships section:
        const seedBasic = {
          name: t('Available_Communities'),
          toggled: true, // initialize expanded
          children: [],
        };

        // no memberships, first apply groups as children then populate groups
        // initialize with "General" group toggled
        seedBasic.children = communityGroups(communities)
          .map(g => g.title)
          .map(gn => ({
            name: trans(gn),
            toggled: gn === 'General',
            children: [],
          }));
        seedBasic.children.forEach(
          g => (g.children = children(c => trans(c.group) === g.name))
        );
        return seedBasic.children.length
          ? seedBasic
          : { name: t('No_Available_Communities') };
      };

      // proposed promoteLoneChildren not  yet applied here
      setData(
        communities.filter(c => isMember(c)).length
          ? buildTreeWithMemberships()
          : buildTreeBasic()
      );
    }
  }, [communities]);

  const onToggle = (node, toggled) => {
    /*
                // community nodes can be highlighted (active) and have no children
                // parent nodes can be expanded (toggled) and cannot be highlighted
        */
    if (node.partition) {
      // community node, can be highlighted (active), has no children
      setActiveName(data, node.name);
      onChange && onChange(node.partition);
    } else {
      // parent node, cannot be highlighted but can be expanded
      node?.children && (node.toggled = toggled);
    }
    setData(Object.assign({}, data));
  };

  return data ? (
    <Treebeard data={data} onToggle={onToggle} style={treebeardStyles} />
  ) : null;
};

CommunityTree.propTypes = {
  communities: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};
export default CommunityTree;
