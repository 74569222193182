/**
 * FacebookShareButton
 * Multiple methods, selected by props.shareMethod
 *
 * Notes:
 * - Sharing can be accomplished via Social Plugin or Share Dialogs (2 sub-methods each)
 * - only shareDialogSdk requires window.FB to be loaded
 *
 * Testing::
 * invoke via route: https://oksayit.com/play/:id/:shareMethod?dialogDisplay={iframe | popup}
 *
 * Punchlist:
 * - shareDialogSdk fails with message: "Sorry, something went wrong. We're working on getting this fixed as soon as we can."
 * - theory: cookie problem: https://www.youtube.com/watch?v=-I3JwZ6vd_M
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@material-ui/core';
import { isAdministrator } from 'lib/api/userApi';
import { useLocation } from 'react-router-dom';
import { facebook_appId } from 'configs/config-hvn';

const appId = facebook_appId;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const FacebookShareButton = ({ url, shareMethod, btnStyle, iconBtnLabel }) => {
  const href = encodeURIComponent(url);
  const FB = window.FB;

  let query = useQuery();
  const dialogDisplay = query.get('dialogDisplay') || 'popup'; // iframe, async, popup

  const size = 'large'; // large, small
  const layout = ''; // button_count, box_count, button

  // render a button in the selected style (button or icon)
  const renderButton = (onClick = null) =>
    btnStyle === 'iconButton' ? (
      <div style={{ display: 'inline-block', textAlign: 'center' }}>
        <IconButton onClick={onClick}>
          <img
            style={{ border: '1px solid white' }}
            src="/images/icons/fb_24x24.png"
          />
        </IconButton>
        <div style={{}}>
          <span style={{ color: 'white', backgroundColor: 'transparent' }}>
            {iconBtnLabel}
          </span>
        </div>
      </div>
    ) : (
      <Button onClick={onClick}>
        <div style={{ width: 80 }}>
          <img
            src="/images/facebook/facebook-share-button.svg"
            alt="Share on Facebook"
            style={{ maxInlineSize: '100%', blockSize: 'auto' }}
          ></img>
        </div>

        <img
          src="/images/facebook/facebook-share-button.svg"
          alt="Walking Mouth"
        ></img>
      </Button>
    );

  /**
   * Share Button Plugin using iFrame
   * https://developers.facebook.com/docs/plugins/share-button/
   * on localhost: FB not required, produces error popup
   *
   */
  function shareBtnIframe() {
    console.log('shareBtnIframe');
    const fbUrl = 'https://www.facebook.com/plugins/share_button.php';

    return (
      <>
        <div
          style={{
            display: 'inline-block',
            padding: 10,
            paddingBottom: 5,
            backgroundColor: 'grey',
          }}
        >
          <iframe
            style={{
              backgroundColor: 'white',
              border: '2px solid blue',
              overflow: 'hidden',
            }}
            src={`${fbUrl}?href=${href}&appId=${appId}`}
            width="77"
            height="20"
            scrolling="no"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </>
    );
  }

  /**
   * Share Button Plugin using SDK
   * https://developers.facebook.com/docs/plugins/share-button/
   * on localhost: FB not required, redirects to facebook error page IN SAME TAB
   */
  function shareBtnRedirect() {
    console.log('shareBtnRedirect');
    const fbUrl = 'https://www.facebook.com/sharer/sharer.php';

    return (
      <div class="fb-share-button" data-href={url} data-layout="" data-size="">
        <a
          href={`${fbUrl}?u=${href}&amp;src=sdkpreparse`}
          class="fb-xfbml-parse-ignore"
        >
          {renderButton()}
        </a>
      </div>
    );
  }

  /** Share via redirect Dialog
   *  https://developers.facebook.com/docs/sharing/reference/share-dialog
   *  or programmatically: window.location.replace
   *
   *  on localhost: FB not required, redirects to facebook error page IN NEW TAB
   *
   */
  function shareDialogRedirect() {
    console.log('shareDialogRedirect');
    const fbUrl = 'https://www.facebook.com/dialog/share';
    const display = 'popup';
    const redirectUri = href;
    return (
      <a
        target="_blank"
        href={`${fbUrl}?app_id=${appId}&display=${display}&href=${href}&redirect_uri=${redirectUri}`}
      >
        {renderButton()}
      </a>
    );
  }

  /**
   *  share using Facebook SDK
   *  https://developers.facebook.com/docs/javascript/reference/FB.ui/
   *  https://developers.facebook.com/docs/sharing/reference/share-dialog
   *  https://stackoverflow.com/questions/22377724/facebook-share-via-fb-ui
   */
  const shareDialogSdk = () => {
    console.log('shareDialogSdk display:', dialogDisplay);
    return renderButton(() =>
      FB.ui(
        {
          app_id: appId,
          method: 'share',
          href: href,
          redirect_uri: 'https://oksayit.com/info',
          //display: dialogDisplay, //iframe, popup
          //          hashtag: '#voice',
          //         caption: 'test caption', // maybe not used
          //         description: 'test description', //maybe not used
        },
        function (response) {
          console.log('FB.ui response:', response);
        }
      )
    );
  };

  if (isAdministrator()) {
    switch (shareMethod) {
      case 'shareBtnIframe':
        return shareBtnIframe();
      case 'shareBtnRedirect':
        return shareBtnRedirect();
      case 'shareDialogRedirect':
        return shareDialogRedirect();
      case 'shareDialogSdk':
        return shareDialogSdk();
      default:
        console.log(
          `FacebookShareButton input shareMethod=${shareMethod}, default method...`
        );
        return shareDialogRedirect();
    }
  } else {
    return null;
  }
};

FacebookShareButton.proptypes = {
  shareMethod: PropTypes.oneOf([
    'shareBtnIframe',
    'shareBtnRedirect',
    'shareDialogRedirect',
    'shareDialogSdk',
  ]),
  btnStyle: PropTypes.oneOf(['button', 'iconButton']),
  iconBtnLabel: PropTypes.string,
};

FacebookShareButton.defaultProps = {
  shareMethod: 'shareDialogRedirect',
  btnStyle: 'button',
  iconBtnLabel: 'FB Share',
};

export default FacebookShareButton;
