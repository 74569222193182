/**
 * Provide context for customizing UI
 * while rendering hosted media
 */

import React from 'react';
const HostedMediaContext = React.createContext({
  playerActionsLegends: null,
  playerContainer: null,
  notePlayStart: null,
});

export default HostedMediaContext;

export const useHostedMediaContext = () => React.useContext(HostedMediaContext);
