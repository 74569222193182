/**
 * Configuration template for 'lextalk' partition/community
 *
 */

export default {
  // overrides applied to items contained in config-common
  //    overrides: [
  //        {
  //            key: 'authMethod',
  //            values: [
  //                'sms',
  //            ],
  //        },
  //    ],
  pages: [
    {
      key: 'getAttributes',
      title: 'getAttributes',
      sections: [
        {
          key: 'userParams',
          title: 'About You',
          fields: [
            {
              key: 'gender',
              title: 'Gender',
              value: null,
              error: false,
              helper: 'What is your gender?',
              required: false,
              type: 'radio',
              values: ['Male', 'Female'],
            },
            {
              key: 'ageRange',
              title: 'Age Range',
              value: null,
              error: false,
              helper: 'What is your age range?',
              required: false,
              type: 'radio',
              values: ['Youth', 'Adult', 'Senior'],
            },
            {
              key: 'affiliation',
              title: 'Lexington Residency',
              value: null,
              error: false,
              helper: '',
              required: false,
              type: 'radio',
              values: ['Resident', 'Visitor', 'Business', 'Other'],
            },
          ],
        },
        {
          key: 'voice',
          title: 'Your Voice Profile for this Community',
          fields: [
            {
              key: 'profile',
              title: 'Voice Profile',
              required: false,
              type: 'voice',
              values: ['profile'],
            },
          ],
        },
      ],
    },
    {
      // Note that filter attributes must be formed by adding
      // a standard suffix to the user key names.
      // e.g. user.ageRange is matched against the filter attribute ageRangePref

      key: 'filter',
      title: 'Filters',
      sections: [
        {
          key: 'filterSection',
          title: 'Partner Preference',
          fields: [
            {
              key: 'genderPref',
              title: 'Gender',
              value: 'No Preference',
              error: false,
              helper: 'Member Gender',
              required: false,
              type: 'radio',
              values: ['Male', 'Female', 'No Preference'],
            },
            {
              key: 'ageRangePref',
              title: 'Age',
              value: 'No Preference',
              error: false,
              helper: 'Member Age Range',
              required: false,
              type: 'radio',
              values: ['Youth', 'Adult', 'Senior', 'No Preference'],
            },
            {
              key: 'affiliationPref',
              title: 'Residency',
              value: {
                Resident: false,
                Visitor: false,
                Business: false,
                Other: false,
              },
              error: false,
              helper: 'Member Residency',
              required: false,
              type: 'checkbox',
              values: ['Resident', 'Visitor', 'Business', 'Other'],
            },
          ],
        },
      ],
    },
  ],
};
