/**
 * Render a radio field
 */

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    border: '1px solid rgba(255, 255, 255, 0.23)',
    borderRadius: 4,
    padding: '1rem',
  },
  fieldError: {
    borderColor: theme.palette.error.main,
  },
}));

const LogRegFieldRadio = ({
  field,
  onFieldChange,
  classes: classesOverride,
}) => {
  const classes = { ...useStyles(), ...classesOverride };

  return field ? (
    <div
      key={field.key}
      className={`${classes.fieldContainer} ${
        field.error ? classes.radioError : ''
      }`}
    >
      <FormControl
        required={field.required}
        component="fieldset"
        error={field.error}
      >
        <FormLabel color="secondary" component="legend">
          {field.title}
        </FormLabel>
        <RadioGroup
          row={false}
          name="gender1"
          value={field.value}
          onChange={
            onFieldChange && (e => onFieldChange(field.key, e.target.value))
          }
        >
          {/* render values of radio field */}
          {field.values.map(fVal => (
            <FormControlLabel
              key={field.id}
              value={fVal}
              control={<Radio />}
              label={fVal}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  ) : null;
};

LogRegFieldRadio.propTypes = {
  field: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.node,
    error: PropTypes.bool,
    required: PropTypes.bool,
  }),

  onFieldChange: PropTypes.func, // onFieldChange(key, value)
};

export default LogRegFieldRadio;
