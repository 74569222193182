/**
 * Translation Multi-Lingual support
 *
 * Translates keys contained in en_trans, fr_trans, etc. objects
 *
 * Methods to invoke:
 *
 * 1. via function
 * // recommended with component employs only a few keys for translation
 * import { t } from 'lib/translation/trans'
 * t("translation_key_example")   // WITHOUT 'T$_ prefix
 *
 * 2. via key reference
 * // recommended for component referencing many keys for translation
 * const { langMap: t1 } = useSelector(state => state.lang)
 * t1.T$_Translation_key_example
 *
 *
 *
 * Modules not yet upgraded for multi-lingual
 * 	T&C
 *	LiveCall sequence
 *	Information
 *	MessageRecorder results
 *	Registration Params Lex
 *
 */

// be sure to add new languages to DisplayLanguageSelector
import en_trans from './en_trans';
import fr_trans from './fr_trans';
import sp_trans from './sp_trans';

import store from 'store';
import { makeArray } from 'lib/utils/utils';

// enable showErr to diagnose missing translation kays
// disable showErr to display missing keys
const showErr = false;
const ERR = '??!!';
const onErr = symbol => (showErr && ERR) || symbol;

// const err = symbol => ERR || symbol

//import { uiActions } from 'store/ui-slice'
// store.getState().ui.pSession.langCode
// store.dispatch(uiActions.setLanguage("EN"))

//assemble the available translation maps for itemized text strings
export const transMapList = {
  EN: en_trans,
  FR: fr_trans,
  SP: sp_trans,
};

export const transMap = symbolArr => {
  const t = transMapList[store.getState().ui.pSession.langCode];
  const out = symbolArr.reduce(
    (acc, symbol) => ({ ...acc, [symbol]: t[`T$_${symbol}`] }),
    {}
  );

  //console.log("transMap in, out:", symbolArr, out)
  return out;
};

// translate a single symbolic text string into the language
// specified by ui.pSession.langCode
// WARNING: do not invoke from within a ui reducer, causes getState conflict
export const t = symbol => {
  // prevent problems if invoked before store is initialized
  if (!symbol || !store) {
    return symbol;
  }
  const tr = transMapList[store.getState().ui.pSession.langCode];
  const key = `T$_${strToVar(symbol)}`;
  const txt = tr && tr[key];
  //console.log(`symbol=${strToVar(symbol)}, key=${key}, txt=${txt}`);
  return txt || onErr(symbol);
};

// translate to English an item (string, array, or object key:val pairs)
export const rt = inp => {
  const tr = transMapList[store.getState().ui.pSession.langCode];

  // convert string to english
  const toEng = text => {
    const found =
      tr && makeArray(Object.entries(tr).find(([key, val]) => val === text))[0];
    const rslt = transMapList['EN'][found];
    return rslt || text;
  };

  const type = Array.isArray(inp) ? 'array' : typeof inp;
  switch (type) {
    case 'string':
      return toEng(inp);
    case 'array':
      return inp.map(txt => toEng(txt));
    case 'object':
      return Object.entries(inp).reduce((out, [key, val]) => {
        return { ...out, [toEng(key)]: toEng(val) };
      }, {});
    default:
      return inp;
  }
};

// convert a string to a valid variable name (replace spaces, strip question marks)
export const strToVar = str => {
  return (
    typeof str === 'string' && str.replace(/ /g, '_').replace(/[()?.]/g, '')
  );
};

export const missingTranslationsCnt = obj => {
  const missingTrans = Object.entries(obj).reduce(
    (out, [key, val]) => (t(val) === ERR ? { [key]: val, ...out } : out),
    {}
  );
  const cnt = Object.keys(missingTrans).length;
  if (cnt) {
    console.log(
      'missingTranslation keys:',
      Object.entries(missingTrans).map(([key, val]) => key)
    );
    console.log(
      'missingTranslation values:',
      Object.entries(missingTrans).map(([key, val]) => val)
    );
  }
  return cnt;
};

// translate a text string from EN
// ( less efficient than symbol translation )
export const trans = text => {
  const langCode = store.getState().ui.pSession.langCode;
  if (langCode === 'EN') {
    return text;
  }
  const tr = transMapList[langCode];
  const symbol = makeArray(
    Object.entries(transMapList['EN']).find(([key, val]) => val === text)
  )[0];
  const rslt = tr[symbol];
  // console.log(`trans text=${text}, symbol=${symbol}, rslt=${rslt}`)
  return rslt || text;
};

// reverse translate text string
export const rTrans = text => {
  const langCode = store.getState().ui.pSession.langCode;
  if (langCode === 'EN') {
    return text;
  }
  const tr = transMapList[langCode];
  const symbol = makeArray(
    Object.entries(tr).find(([key, val]) => val === text)
  )[0];
  const rslt = transMapList['EN'][symbol];
  // console.log(`rTrans text=${text}, symbol=${symbol}, rslt=${rslt}`)
  return rslt || text;
};

// (english version)
export const possessive = (item, owner) => `${owner}'s ${item}`;
