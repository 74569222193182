/**
 * Main Menu
 * Determine available menu items and render in selected format
 *
 * Formats
 *   - tabs, footer button array, or vertical List
 *
 * Legends
 *   - none, text, or tooltips, props controlled
 *
 * Verbosity
 *   - Collapse/Expand menu-items
 *
 * Actions
 *   - implemented with history.push(item.route)
 *
 */

import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import FooterMenu from './components/FooterMenu';
import TabsStd from 'components/utils/TabsStd';
import TipsPopover from 'components/utils/TipsPopover';
import AnimationDecoration from 'components/utils/AnimationDecoration';
import { rt } from 'lib/translation/trans';
import { HumanGreeting } from 'mdi-material-ui';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';

//icons
import InfoIcon from '@material-ui/icons/Info';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ChatIcon from '@material-ui/icons/Chat';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  tabIcon: {
    fontSize: '24px',
    height: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  bounceIconWrapper: {
    paddingBottom: 6,
    height: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  tabsMenu: {},
  footerMenu: {},
}));

const MainMenu = forwardRef(
  (
    {
      classes: classesOverride,
      format,
      legendStyle,
      menuVariantOverride,
      langBtnEn,
      onChange,
    },
    fRef
  ) => {
    const classes = { ...useStyles(), ...classesOverride };
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);
    const history = useHistory();
    const micRef = React.useRef();
    const { isAuthenticated, auth } = useSelector(state => state.user);

    const { alert: inboxAlert } = useSelector(state => state.ui.inbox);

    const t1 = useSelector(state => state.lang.langMap);
    const isMobile = Boolean(useSelector(state => state.ui.deviceInfo.mobile));
    const [expandMenu, setExpandMenu] = useState(!isMobile);

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));

    // use expandable menu for xs, but not if rendering as a List
    const expandable = xs && format !== 'List';
    const [expanded, setExpanded] = useState(false);

    // initialize menu value based on path
    useEffect(() => {
      const path = history.location.pathname;
      const ix = getMenu().findIndex(item => `${item.route}` === path);

      if (ix > -1) {
        //exact match found
        setValue(ix);
      } else {
        //no exact match, for root path set menu state to 0
        setValue(path === '/' ? 0 : null);
      }
    }, [history.location.pathname]);

    // a bouncing icon when Inbox had a new message
    const inboxButton = (
      <AnimationDecoration
        action="bounce"
        enable={isAuthenticated && inboxAlert}
      >
        <div className={classes.bounceIconWrapper}>
          <InboxIcon classes={{ root: classes.tabIcon }} />
        </div>
      </AnimationDecoration>
    );

    // items available for use in menu
    const menuItems = [
      {
        key: 'members',
        legend: t1.T$_Members,
        icon: <PeopleIcon classes={{ root: classes.tabIcon }} />,
        route: '/members',
      },
      {
        key: 'chat',
        legend: t1.T$_Chat,
        icon: <ChatIcon classes={{ root: classes.tabIcon }} />,
        route: '/playlist',
      },
      //        { key: "inbox", legend: t1.T$_Inbox, icon: inboxButton, route: '/inbox' },
      {
        key: 'settings',
        legend: t1.T$_Settings,
        icon: <SettingsIcon />,
        route: '/settings',
      },
      { key: 'info', legend: t1.T$_Info, icon: <InfoIcon />, route: '/info' },
      { key: 'less', legend: t1.T$_Less, icon: <CancelIcon /> },
      { key: 'more', legend: t1.T$_More, icon: <MoreHorizIcon /> },
      {
        key: 'welcome',
        legend: t1.T$_Welcome,
        icon: <HumanGreeting />,
        route: '/welcome',
      },
    ];

    // standard groupings of menu items
    const menuSets = {
      unauth: ['chat', 'info', 'welcome'],
      full: ['members', 'chat', /* "inbox", */ 'settings', 'info'],
      expandable: ['members', 'chat', /* "inbox", */ 'more'],
      expanded: ['members', 'chat', /* "inbox", */ 'settings', 'info', 'less'],
    };

    const getMenu = () => {
      // context-sensitive menu set
      const selectedSet = isAuthenticated
        ? expandable
          ? expanded
            ? 'expanded'
            : 'expandable'
          : 'full'
        : 'unauth';

      return (
        menuItems
          .filter(item => menuSets[selectedSet].includes(item.key))

          // enforce security exclusions...
          .filter(
            item =>
              isAuthenticated ||
              !['/settings', '/members'].includes(item?.route)
          )
      );
    };

    const handleMenuChange = value => {
      // launch opt-in advice if conditions are met (once per session, only if user has not opted before)
      dispatch(uiActions.suggestionInvoke('app_install')); // 'app_install', "push_notifications", "sms_notifications"

      // push a route if specified in getMenu
      const item = getMenu()[value];

      // clear inbox alert if inbox button pushed
      if (item.route === '/inbox') {
        dispatch(uiActions.setInboxAlert(false));
      }

      const route = item.route;
      if (route) {
        setValue(value);
        history.push(item.route);
        onChange && onChange();
        return;
      }
      // otherwise, process based on legend
      const legend = rt(item.legend);
      if (['More', 'Less'].includes(legend)) {
        setExpandMenu(legend === 'More');
      }
    };

    const menuClass = format === 'Tabs' ? classes.tabsMenu : classes.footerMenu;

    return (
      <div ref={fRef} id="main-menu" className={menuClass}>
        {format === 'Tabs' && (
          <TabsStd
            legendStyle={legendStyle}
            items={getMenu()}
            value={value}
            onChange={value => handleMenuChange(value)}
          />
        )}
        {format === 'Footer' && (
          <FooterMenu
            items={getMenu()}
            value={value}
            onChange={value => handleMenuChange(value)}
            menuExpanded={expandMenu}
            langBtnEn={langBtnEn}
          />
        )}
        {format === 'List' &&
          getMenu().map((item, ix) => {
            const { legend, icon } = item;
            return (
              <ListItem
                button
                key={ix}
                selected={ix === value}
                onClick={() => handleMenuChange(ix)}
              >
                <ListItemIcon>{icon} </ListItemIcon>
                <ListItemText primary={legend} />
              </ListItem>
            );
          })}
        <TipsPopover anchorEl={micRef.current} tipsKey="recorderMic" />
      </div>
    );
  }
);

MainMenu.propTypes = {
  noteHeight: PropTypes.func, // report component height on rendering
  legendStyle: PropTypes.oneOf(['label', 'tooltips', 'none']),
  format: PropTypes.oneOf(['Tabs', 'Footer', 'List']),
  menuVariantOverride: PropTypes.string,
  onChange: PropTypes.func,
};
MainMenu.defaultProps = {
  legendStyle: 'tooltips',
  menuVariantOverride: null,
  onChange: () => {},
};

export default MainMenu;
