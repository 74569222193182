/**
 * actionBtnInfo
 * Labels, icons, and descriptions of player actions
 * (static data was copied from PlayerAcvtions: actionButtons )
 *
 */

import MicIcon from '@material-ui/icons/Mic';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhoneIcon from '@material-ui/icons/Phone';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import CancelIcon from '@material-ui/icons/Cancel';
import { Help, EyeOffOutline, PhoneHangup } from 'mdi-material-ui';
import LiveCallIcon from 'components/LiveCall/components/LiveCallIcon';

const actionButtonObj = {
  play: {
    label: 'Play',
    icon: <PlayIcon />,
    description: 'Commence playback.',
  },
  pause: {
    label: 'Pause',
    icon: <PauseIcon />,
    description: 'Pause playback.',
  },
  respond: {
    label: 'Respond',
    icon: <MicIcon />,
    description: 'Record a voice response to this message.',
  },
  share: {
    label: 'Share',
    icon: <ShareIcon />,
    description:
      'Obtain a link to this message that can be pasted to social media.',
  },
  bookmark: {
    label: 'Like',
    icon: <FavoriteBorderOutlinedIcon />,
    description: 'Indicate that you like the author of this message.',
  },
  blacklist: {
    label: 'Block',
    icon: <EyeOffOutline />,
    description: 'Block all messages from this author.',
  },
  info: {
    label: 'Info',
    icon: <Help />,
    description: 'Show this information dialog.',
  },
  delete: {
    label: 'Delete',
    icon: <DeleteForeverIcon />,
    description: 'Delete this message.',
  },
  cancel: {
    label: 'Cancel',
    icon: <CancelIcon />,
    description: 'Cancel this action.',
  },

  call: {
    label: 'Call',
    icon: <PhoneIcon />,
    description: 'Invite this user to talk live.',
  },
  regrets: {
    label: 'Regrets',
    icon: <MicIcon />,
    description:
      'Record a voice response to decline this invitation to talk live.',
  },
  accept: {
    label: 'Accept',
    icon: <LiveCallIcon context="Accept" />,
    description: 'Accept this invitation to talk live.',
  },
  decline: {
    label: 'Decline',
    icon: <LiveCallIcon context="Decline" />,
    description: 'Decline this invitation to talk live.',
  },
  hangup: {
    label: 'Hangup',
    icon: <PhoneHangup />,
    description: 'End this live conversation.',
  },
};

export const actionButtonArr = () =>
  Object.entries(actionButtonObj).map(item =>
    (({ label, icon, description }) => ({ label, icon, description }))(item[1])
  );
