/**
 * Render button to add a new topic or response
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ReplyIcon from '@material-ui/icons/Reply';

import MessageRecorderDialog from 'containers/MessagePlayer/components/PlayerActions/components/MessageRecorderDialog';

/*
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';
import MicIcon from '@material-ui/icons/Mic';
*/

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function AddMessageFAB({ respondToMessage, dm }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Fab
          style={{ color: 'white' }}
          onClick={handleDialogOpen}
          color={dm ? 'primary' : 'secondary'}
          aria-label="add"
          size="small"
        >
          {respondToMessage ? <ReplyIcon /> : <AddIcon />}
        </Fab>
        {respondToMessage && (
          <div style={{ display: 'inline' }}>New Comment</div>
        )}
      </div>
      <MessageRecorderDialog
        open={dialogOpen}
        message={respondToMessage}
        onClose={handleDialogClose}
      />
    </>
  );
}

/*
      <Fab variant="extended" size= "small">
        <MicIcon className={classes.extendedIcon} />
        Create
      </Fab>

*/
AddMessageFAB.propTypes = {
  respondToMessage: PropTypes.object,
  dm: PropTypes.bool,
};
AddMessageFAB.defaultProps = {
  dm: false,
  respondToMessage: null,
};
