/**
 * Render a categorized RadioGroup with option for each community.
 *
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { t, strToVar } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({}));

const CommunityList = ({ categorizedCommunities, onChange, isDisabled }) => {
  const [value, setValue] = useState(null);

  const handleClick = partition => onChange && onChange(partition);

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={e => handleClick(e.target.value)}
      >
        <Typography style={{ marginBottom: '1rem' }} variant="h6">
          Select the Community you'd like to join
        </Typography>
        {categorizedCommunities.map(grp => (
          <>
            <FormLabel
              style={{ color: 'white' }}
              component="legend"
            >{`${grp.title} Group`}</FormLabel>
            {grp.list.map(c => (
              <FormControlLabel
                value={c.partition}
                control={
                  <Radio
                    disabled={isDisabled(c.partition)}
                    style={{ marginLeft: 40 }}
                  />
                }
                label={c.title}
              />
            ))}
            <div style={{ marginBottom: 20 }}></div>
          </>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

CommunityList.propTypes = {
  categorizedCommunities: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  isDisabled: PropTypes.func,
};
export default CommunityList;
