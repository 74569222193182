/**
 * Display the title of a community.
 * By default, displays the currently active community
 * but can be overridden by TitleOverride prop.
 *
 * Render as a selector for certaom paths (e.g. '/playlist' )
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CommunitySelectorDialog from 'components/Settings/components/CommunitySelectorDialog';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  bannerContainer: {
    textAlign: 'center',
    padding: '0px 10px',
    [theme.breakpoints.down('xs')]: {
      //            fontSize: ".5rem",
      transform: 'scale(.7)',
    },
  },
  button: {
    width: '100%',
    border: '1px solid yellow',
  },
}));

const CommiunityIndicator = ({
  titleOverride,
  classes: classesOverride,
  enableSelector: enableSelectorOverride,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = { ...useStyles(), ...classesOverride };

  const activeCommunity = useSelector(state => state.ui.pSession.community);
  const history = useHistory();

  // render as selector
  // only render selelector for the playlist view
  const enableSelector =
    enableSelectorOverride ||
    ['/', '/playlist'].includes(history.location.pathname);

  const handleDialogClose = () => setDialogOpen(false);
  const handleDialogOpen = () => setDialogOpen(true);

  // use override if provided
  const title = titleOverride || activeCommunity?.title;

  const banner = () => (
    <>
      <Typography variant="h6" color="secondary">
        {title}
      </Typography>
      {!titleOverride && activeCommunity?.subHeading && (
        <Typography classes={{ root: classes.subHeading }} variant="body1">
          {activeCommunity?.subHeading}
        </Typography>
      )}
    </>
  );

  const bannerWithButton = () => (
    <Button
      classes={{ root: classes.button }}
      onClick={handleDialogOpen}
      className={null}
      endIcon={<ExpandMore />}
    >
      {banner()}
    </Button>
  );

  return activeCommunity?.title ? (
    <>
      <div className={classes.bannerContainer}>
        {enableSelector ? bannerWithButton() : banner()}
      </div>
      <CommunitySelectorDialog
        open={Boolean(dialogOpen)}
        handleDialogClose={handleDialogClose}
        classes={classes}
      />
    </>
  ) : null;
};

CommiunityIndicator.propTypes = {
  titleOverride: PropTypes.string, //override title of current community
  enableSelector: PropTypes.bool,
};
CommiunityIndicator.defaultProps = {
  titleOverride: null,
  enableSelector: false,
};

export default CommiunityIndicator;
