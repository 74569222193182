/**
 * Render a standardized selector
 * - controlled mode
 * - outlined
 * - with optional label in border
 * - relinquish focus when list is closed
 * - for items.length === 1, eliminate expander icon
 * - for null value, simulate a placeholder
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
} from '@material-ui/core';
import BorderedBlock from 'components/utils/BorderedBlock';
import ContextualHelpButton from './ContextualHelpButton';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: 0,
    minWidth: 170,
    width: '100%',
    //height: 36
  },
  selectorBackground: {},

  singleItemWrapper: {
    //        width: visualizerSettings.size.std.width,
    //        [theme.breakpoints.down("xs")]: {
    //            width: visualizerSettings.size.xs.width,
    //          },
    marginTop: '20px',
  },
  helpButtonMultiple: {
    marginTop: 0,
  },
  helpButtonSingle: {
    marginTop: 20, //BorderBlock requires margin for proper alignment
  },
  selectorContainer: {},
  selectRoot: {},
  selectDisplayRoot: {
    paddingTop: 10,
    paddingBottom: 8,
    paddingLeft: 14,
    paddingRight: 32,
    fontSize: '.875rem',
    width: '100%',
  },
  menuItem: {
    fontSize: '.875rem',
  },
}));

export default function SelectorStd(props) {
  const {
    name,
    label,
    placeholder,
    items,
    value,
    onChange,
    helpContext,
    classes: classesOverride,
  } = props;
  const classes = { ...useStyles(), ...classesOverride };

  useEffect(() => {}, []);

  const handleChange = event => {
    onChange && onChange(event.target.value);
  };

  const handleClose = () => {
    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  // The default behavior of <Select /> is to place <InputLabel /> in its border
  // if value is not null, and to use <InputLabel /> as a placeholder
  // if value is null
  /*
        value   label   InputLabel
        null    null    placeholder in data field, no label in border
        null    text    placeholder in data field, no label in border
        text    null    value in data field, no label in border
        text    text    value in data field, label in border

    */
  let smartLabel = placeholder || t('Please_Select');
  if (value) {
    smartLabel = label ? label : null;
  }

  const selectProps = {
    id: `${name}-select`,
    value,
    onChange: handleChange,
    onClose: handleClose,
    //inputProps: {style : {fontSize: ".875rem"} },
    //MenuProps: {style : {fontSize: ".875rem"} },
    SelectDisplayProps: { className: classes.selectDisplayRoot },
    classes: { root: classes.selectRoot },
  };

  // if Select is to be rendered with a label
  const labelId = `${name}-select-label`;
  const selectPropsWithLabel = { ...selectProps, labelId, label: smartLabel };

  /**
   * If only one item, render it without a Select pulldown
   */
  const classesHelpButton = {
    helpButton:
      items.length > 1 ? classes.helpButtonMultiple : classes.helpButtonSingle,
  };

  return (
    <Grid
      classes={{ root: classes.selectorContainer }}
      container
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={helpContext ? 10 : 12}>
        {items.length > 1 ? (
          <FormControl
            variant="outlined"
            classes={{
              root: `${classes.formControl} ${classes.selectorBackground}`,
            }}
          >
            {smartLabel ? (
              <InputLabel id={labelId}>{smartLabel}</InputLabel>
            ) : null}

            <Select {...(smartLabel ? selectPropsWithLabel : selectProps)}>
              {items &&
                items.map(item => (
                  <MenuItem
                    className={classes.menuItem}
                    key={item.key}
                    value={item.key}
                  >
                    {item.data}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          <BorderedBlock
            labelTop={label}
            classes={{ wrapperOuter: classes.singleItemWrapper }}
          >
            <div
              style={{
                fontSize: '1rem',
                fontWeight: 400,
                letterSpacing: '0.00938em',
              }}
            >
              {items[0]?.data}
            </div>
          </BorderedBlock>
        )}
      </Grid>
      {/* render contextual help if helpContext is specified */}
      {helpContext && (
        <Grid item xs={2}>
          <ContextualHelpButton
            context={helpContext}
            classes={classesHelpButton}
          />
        </Grid>
      )}
    </Grid>
  );
}

SelectorStd.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      data: PropTypes.string, // text to display
    })
  ),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.object,
  helpContext: PropTypes.string, // selects the help item to display
  classes: PropTypes.object,
};

SelectorStd.defaultProps = {
  name: 'selector',
  label: null,
  helpContext: null,
};
