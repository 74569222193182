/**
 * Render section or group of fields
 * as part of the login, registration, or profile update process
 * consisting of grouped sections, each with a set of fields
 * also with a submit/continue button
 *
 * Auto-submit option when all required fields are satisfied
 * Immediate auto-submit if no fields are allocated
 *
 * zen: strips Paper container and other verbosity
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { Paper, Typography, Grid, CircularProgress } from '@material-ui/core';
import ConsentForm from 'components/AccountButton/components/LoginView/components/ConsentForm';
import ProfileMessageManager from 'components/Settings/components/ProfileMessageManager';
import LogRegFieldText from './LogRegFieldText';
import LogRegFieldRadio from './LogRegFieldRadio';
import AuthMethodSelector from 'components/AccountButton/components/LoginView/components/AuthMethodSelector';
import Authenticator from 'components/AccountButton/components/LoginView/components/Authenticator';

const useStyles = makeStyles(theme => ({
  section: {
    margin: '1rem 0',
    padding: '1rem',
  },
  sectionHeading: {
    margin: '.5rem 0',
    fontSize: '.8rem',
  },
  // override child default style
  fieldContainer: {
    border: '1px solid rgba(255, 255, 255, 0.23)',
    borderRadius: 4,
    padding: '1rem',
  },
}));

const LogRegSection = ({
  s,
  onFieldChange,
  classes: classesOverride,
  zen,
  noWrap,
}) => {
  const [awaitingConsent, setAwaitingConsent] = useState(true);
  const classes = { ...useStyles(), ...classesOverride };
  const sectionId = heading =>
    heading && `section-${heading.replace(/\s+/g, '-').toLowerCase()}`;
  const auth = useSelector(state => state.user.auth);

  // iterate through page array, but extract field props from fields
  return React.cloneElement(
    true ? <div /> : <Paper style={{ backgroundColor: '#606060' }} />,
    { key: sectionId(s.key), classes: { root: classes.section } },
    [
      !zen && s.title ? (
        <Typography classes={{ root: classes.sectionHeading }} variant="h6">
          {s.title}
        </Typography>
      ) : null,
      <Grid container align="flex-start" spacing={2}>
        {/* render fields of this section */}
        {s.fields &&
          s.fields.map(f => (
            <Grid item style={{ width: '100%' }}>
              {f.type === 'text' && (
                <LogRegFieldText
                  field={f}
                  onFieldChange={onFieldChange}
                  classes={classes}
                />
              )}
              {f.type === 'password' && (
                <LogRegFieldText
                  field={f}
                  onFieldChange={onFieldChange}
                  classes={classes}
                />
              )}
              {f.type === 'radio' && (
                <LogRegFieldRadio
                  field={f}
                  onFieldChange={onFieldChange}
                  classes={classes}
                />
              )}

              {f.type === 'voice' && (
                <div key={f.key} className={classes.fieldContainer}>
                  <ProfileMessageManager onExit={null} vertical={false} />
                </div>
              )}
              {f.type === 'authMethod' && (
                <div
                  key={f.key}
                  className={zen ? null : classes.fieldContainer}
                >
                  {zen || (
                    <div>
                      {f.required && '* '}
                      {f.title}
                    </div>
                  )}
                  <AuthMethodSelector
                    onClick={value => onFieldChange(f.key, value)}
                    methods={f?.values}
                  />
                </div>
              )}
              {f.type === 'authenticate' && (
                <div
                  key={f.key}
                  className={zen ? null : classes.fieldContainer}
                >
                  {zen || ['google', 'facebook'].includes(auth?.method) || (
                    <div style={{ marginBottom: '1rem' }}>
                      {f.required && '* '}
                      {f.title}
                    </div>
                  )}
                  <Authenticator
                    authMethod={auth?.method}
                    onClose={err => onFieldChange(f.key, err)}
                    onSuccess={creds => onFieldChange(f.key, creds)}
                    onFail={err => onFieldChange(f.key, err)}
                    zen={zen}
                  />
                </div>
              )}
              {f.type === 'consent' &&
                (awaitingConsent ? (
                  <ConsentForm
                    onClose={() => onFieldChange(f.key, false)}
                    onSubmit={() => {
                      setAwaitingConsent(false);
                      onFieldChange(f.key, true);
                    }}
                    delay={0}
                  />
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress color="inherit" />
                  </div>
                ))}
            </Grid>
          ))}
      </Grid>,
    ]
  );
};

LogRegSection.propTypes = {
  section: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.node,
        error: PropTypes.bool,
        required: PropTypes.bool,
        type: PropTypes.string,
        validate: PropTypes.string,
      })
    ),
  }),
  onFieldChange: PropTypes.func,
  zen: PropTypes.bool, // render bare bones fields
  noWrap: PropTypes.bool,
};

LogRegSection.defaultProps = {
  zen: false,
  noWrap: false,
};
export default LogRegSection;
