/**
 * Render an animated recording progress indicator
 * with duration indicator overlay.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Zoom, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Mic } from '@material-ui/icons';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  'container': {
    display: 'flex',
  },
  'warn': {
    backgroundColor: 'pink',
  },
  'icon': {
    color: 'red',
  },

  // ref.: https://stackoverflow.com/questions/58948890/how-to-apply-custom-animation-effect-keyframes-in-materialui-using-makestyles/63546822
  // ref.: https://stackoverflow.com/questions/16344354/how-to-make-blinking-flashing-text-with-css-3
  'blinkingItem': {
    animation: `$blinker 1s linear infinite`,
  },
  '@keyframes blinker': {
    '0%': {
      opacity: 0,
    },
  },
}));

const RecorderActivity = ({ isRecording, approvalNeeded, enableWarning }) => {
  const classes = useStyles();
  const [flash, setFlash] = useState(false);
  const [recStartTime, setRecStartTime] = useState(0);

  const toggleFlash = () => {
    setFlash(prev => !prev);
  };

  const durationString = () => {
    if (recStartTime === 0) {
      return '00:00';
    }

    const elapsed = Math.floor((Date.now() - recStartTime) / 1000);
    const minutes = Math.floor(elapsed / 60);
    const sec = elapsed - 60 * minutes;
    const pad = n => ('0' + n).slice(-2);

    return `${pad(minutes)}:${pad(sec)}`;
  };

  const durationOverlay = () => (
    <div style={{ width: '100%', position: 'absolute', bottom: 2 }}>
      <Grid container justify="center" style={{ color: '#0096ef' }}>
        <Typography variant="h6">{durationString()}</Typography>
      </Grid>
    </div>
  );

  const readyOverlay = () => (
    <div style={{ width: '100%', position: 'absolute', bottom: 5 }}>
      <Grid container justify="center" style={{ color: '#0096ef' }}>
        <Typography variant="button">{t('READY')}</Typography>
      </Grid>
    </div>
  );

  const approvalOverlay = () => (
    <div style={{ width: '100%', position: 'absolute', bottom: 2 }}>
      <Grid
        container
        justify="center"
        style={{ fontSize: '.2rem' }}
        classes={{ root: classes.blinkingItem }}
      >
        <div>
          <Typography
            variant="button"
            style={{ color: 'red', fontSize: '.6rem', fontWeight: 'bold' }}
          >
            PLEASE
          </Typography>
        </div>
        <div>
          <Typography
            variant="button"
            style={{ color: 'red', fontSize: '.6rem', fontWeight: 'bold' }}
          >
            ENABLE
          </Typography>
        </div>
      </Grid>
    </div>
  );

  //process change of src
  useEffect(() => {
    let animationTimer = null;

    if (isRecording) {
      setRecStartTime(Date.now());

      //turn on animation and start the blinker
      setFlash(isRecording);
      animationTimer = setInterval(() => {
        toggleFlash();
      }, 1000);

      return () => {
        clearInterval(animationTimer);
        animationTimer = null;
      };
    } else {
      // recording has ceased
      animationTimer && clearInterval(animationTimer);
      animationTimer = null;
      setFlash(false);
    }
  }, [isRecording]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={`${classes.container} ${
          isRecording && enableWarning ? classes.warn : null
        } `}
      >
        <Zoom in={flash}>
          <Mic fontSize="large" classes={{ root: classes.icon }} />
        </Zoom>
        <Zoom in={flash} style={{ transitionDelay: flash ? '500ms' : '0ms' }}>
          <Mic fontSize="large" classes={{ root: classes.icon }} />
        </Zoom>
      </div>
      {isRecording
        ? !flash && durationOverlay()
        : approvalNeeded
        ? approvalOverlay()
        : readyOverlay()}
    </div>
  );
};

RecorderActivity.propTypes = {
  isRecording: PropTypes.bool,
  approvalNeeded: PropTypes.bool,
  enableWarning: PropTypes.bool, //provide a warning to user of impending limit
};

export default RecorderActivity;
