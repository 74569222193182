/**
 * usePushNotificationSuggestion custom hook
 *
 * Support rendering of a suggestion, encourage opt-in for Web-push Notifications
 *
 * Notes:
 * - on ios, user must install PWA before subscribing to push notifications
 *

commit
improve evaluation of deviceInfo
add instructions rendering to <Suggestion /> component
 */

import React from 'react';
import { t } from 'lib/translation/trans';
import useDrupalPushServer from 'hooks/useDrupalPushServer';
import { pwa } from 'lib/classes/PWA';

const usePushNotificationSuggestion = onCompleted => {
  const config = {
    type: 'push_notifications',
    offer: t('Receive_Notifications_When_Messages_Arrive_For_You'), //required, provides offer text
    approveBtnText: null, // optional, overrides default
    info: null, //optional, rendered upon Info button
    instruct: null, //optional, opt-in instructions
  };

  const { requestPushSubscription } = useDrupalPushServer();

  // A suggestion has veen invoked, determine if it can be presented now
  const canRenderSuggestion = () => {
    //  - only if push notifications are available on this device
    //  - not if user has already subscribed on this device

    //@@ on some browsers/devicwes, app must first be installed
    return pwa.canGrantPushPermission(); // push available
  };

  // user wishes to move forward with subscription
  const handleOptIn = () => {
    requestPushSubscription();
    onCompleted();
  };
  // discontinue future rendering of this alert
  const handleDisable = rslt => {
    onCompleted(rslt);
  };

  const handleLater = () => {
    onCompleted();
  };

  return {
    config,
    canRenderSuggestion,
    handleOptIn,
    handleDisable,
    handleLater,
  };
};

export default usePushNotificationSuggestion;
