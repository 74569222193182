import React from 'react';
import PropTypes from 'prop-types';
import Dialog2 from 'components/Modals/Dialog2';
import MessageRecorder from 'containers/MessageRecorder';
import { newMessageParent } from 'lib/api/messageApi';
import { optionControlsBrowserView } from 'hoc/WithOptionControls/lib/optionControlsBrowserView';

// respond to a post, or if message.type is a child, respond to its parent
export default function RegretsActionDialog({ open, onClose, message }) {
  const handleClose = rslt => {
    onClose(rslt?.type === 'regrets' ? `${rslt.type}Confirm` : null);
  };

  const heading = 'Send Regrets';

  return (
    <>
      <Dialog2
        open={open}
        onClose={handleClose}
        heading={heading}
        content={
          <MessageRecorder
            bannerOverride={`Decline a Live Call from ${message.screenName}`}
            renderAsAppliance={false}
            onExit={rslt => handleClose(rslt)}
            parentMessage={newMessageParent(message)}
          />
        }
        actions={null}
      />
    </>
  );
}
RegretsActionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.object,
};

RegretsActionDialog.defaultProps = {
  onClose: () => {},
};
