/**
 * Create ctx with gain control voice activity detection (vad)
 * (multiple vad methods are supported)
 *
 *
 * Safari Distortion Observed:
 * 6 minutes after allocating remoteStreamProcessor, outputStream becomes permanently distorted.
 * ref.:
 * https://github.com/Jam3/ios-safe-audio-context
 * https://stackoverflow.com/questions/54982847/crackles-and-noises-when-playing-ios-web-audio-with-simple-graph
 * https://stackoverflow.com/questions/56270593/audiocontext-decodeaudiodata-seems-to-create-crackling-noise
 * https://github.com/goldfire/howler.js/issues/1141
 * https://github.com/goldfire/howler.js/issues/1141
 *
 * resampling input stream at 48000 seems to resolve Safari distortion issue
 */

import { makeArray, pick } from 'lib/utils/utils';

// VAD methods
import VadRms from 'lib/api/VadApi/VadRms';
import VadJam3 from 'lib/api/VadApi/VadJam3';
import VadHark from 'lib/api/VadApi/VadHark';
import VadLinto from 'lib/api/VadApi/VadLinto';

// peer date manager
import { pdm } from 'lib/api/webRTCApi'; // PeerDataManager

class StreamProcessor {
  // ------------------------------------------------------------------------------

  key = null;
  gain = 1;
  vadList = [];
  options = {};
  defaults = {
    maxGain: 3,
    initialVolume: 50,
    sampleRate: 48000, // resample rate used by the local AudioContext
  };

  constructor(
    key,
    inputStream,
    volumeInit,
    vadList,
    whichStream,
    dlog,
    options
  ) {
    this.key = key;
    this.vadList = vadList;
    this.options = { ...this.defaults, ...options };
    this.whichStream = whichStream;
    this.dlog = dlog;
    this.vadSubscriptions = []; // callback for vad event reporting

    this.setStream(inputStream, volumeInit);
    //console.log(`constructing StreamProcessor ${this.key}`)

    this.vads = [];
    makeArray(vadList).forEach(vadType => {
      switch (vadType) {
        case 'Linto':
          this.vads.push(new VadLinto(this.vadArgs(vadType)));
          break;
        case 'Hark':
          this.vads.push(new VadHark(this.vadArgs(vadType)));
          break;
        case 'Jam3':
          this.vads.push(new VadJam3(this.vadArgs(vadType)));
          break;
        case 'Rms':
          this.vads.push(new VadRms(this.vadArgs(vadType)));
          break;
        default:
          break;
      }
    });
  }

  setStream = (stream, volume) => {
    this.inputStream = stream;
    const ctxTemp = new AudioContext(); // used to identify hardware default sample rate
    this.ctx = new AudioContext(
      this.key === 'local' ? { sampleRate: this.options.sampleRate } : null
    );
    this.gainNode = this.ctx.createGain();
    this.destination = this.ctx.createMediaStreamDestination();
    this.gainNode.connect(this.destination);
    this.outputStream = this.destination.stream;

    // Chrome/Edge workaround #2 for remote stream
    this.audioEl = new Audio();
    this.audioEl.srcObject = stream;
    this.audioEl.muted = true;
    //ref.: https://stackoverflow.com/questions/54514273/webrtc-via-web-audio-api-silent-on-google-chrome
    //ref.: https://www.wowza.com/community/t/no-audio-with-webrtc-demo/49393

    this.srcNode = this.ctx.createMediaStreamSource(stream);
    this.srcNode.connect(this.gainNode);
    this.muted = false;
    this.setVolume(
      typeof volume !== 'undefined' ? volume : this.defaults.initialVolume
    );
    //console.log(`SAMPLE RATE  ${this.key} Default=${ctxTemp.sampleRate}, ctxSampleRate=${this.ctx.sampleRate}`);
  };

  // process vad event
  // - register status with redux
  // - report status to peer
  // -

  setTalking = (label, val) => {
    // initialize timer with onset of talking
    const t0 = Date.now();

    //(label === "Linto") && console.log(`setTalking val=${val}.`);
    StreamProcessor.pdmSend({ key: this.key, label, val });

    // report event to subscribed callbacks
    makeArray(this.vadSubscriptions).forEach(fn => {
      typeof fn === 'function' &&
        fn(
          //src, pov, tap, vadType, val
          'streamProcessor', //source of data
          this.key === 'local' ? 'mouth' : 'ear', // point of view (local processor watches the microphone)
          this.whichStream, // where is the signal tapped input/output?
          label, //type of vad
          val, //talking now?
          t0 //diagnostic time stamp
        );
    });
  };

  // subscribe to receive vad events
  vadSubscribe = fn => {
    if (typeof fn === 'function') {
      this.vadSubscriptions = [...this.vadSubscriptions, fn];
    }
  };

  // unsubscribe from vad events
  vadUnSubscribe = fn => {
    this.vadSubscriptions = makeArray(this.vadSubscriptions).filter(
      s => s !== fn
    );
  };

  // assemble context, stream, and labelled handlers
  vadArgs = vadType => {
    const vadStream = () =>
      this.whichStream === 'inputStream' ? this.inputStream : this.outputStream;
    const vadOptions = () => ({
      onVoiceStart: () => this.setTalking(vadType, true),
      onVoiceStop: () => this.setTalking(vadType, false),
      vadOptions: { dlog: this.dlog },
    });
    return {
      type: vadType,
      context: this.ctx,
      audioStream: vadStream(),
      options: vadOptions(),
      key: `${this.key}-${this.whichStream}`,
    };
  };

  getInputStream = () => this.inputStream;
  getOutputStream = () => this.outputStream;

  getVad = vadType => this.vads.find(v => v.type === vadType);

  setGain = gain => {
    if (this.gainNode && gain >= 0 && gain < 10) {
      this.gainNode.gain.value = gain;
    }
  };

  /**
   *
   * the volume measure lies in the range 0-100
   * AudioContext gainNode accepts 0-infinity with 1 representing unity gain.
   *
   * We will employ a piecewise linear mapping as follows:
   * volume 0-50, gain = .02 * v
   * volume 51-100: gain = 1 + (.02*(maxgain-1)*(v-50))
   */
  setVolume = v => {
    this.volume = v;
    // remember the setting but only actuate change if stream is not muted
    if (!this.muted) {
      const volToGain = v =>
        v < 51
          ? 0.02 * v
          : 2 - this.options.maxGain + 0.02 * v * (this.options.maxGain - 1);
      this.setGain(volToGain(v));
    }
  };

  // mute until further notice
  setMuted = m => {
    this.muted = m;
    getAudioTrack(this.inputStream).enabled = !m;
  };

  // resume after suspend
  resume = () => {
    //console.log(`resuming StreamProcessor ${this.key}-${this.whichStream}`)
    this.ctx.resume();
    this.vads.forEach(v => v.resume());
  };

  // non-destructive pause in processing
  suspend = () => {
    //console.log(`suspending StreamProcessor ${this.key}-${this.whichStream}`)
    this.vads.forEach(v => v.suspend());
    this.ctx.suspend();
  };

  destroy = async () => {
    delete this.vadSubscriptions;
    this.vads.forEach(v => {
      v.destroy();
      delete this[v];
    });

    if (this.ctx) {
      if (this.ctx.state !== 'closed') {
        try {
          await this.ctx.close();
        } catch (e) {
          console.log('error closing ctx:', e);
        }
      } else {
        console.log('this.ctx already closed.');
      }
      this.ctx = null;
    }
  };

  // telemetry exchange services
  // send info to peer
  static pdmSend = data => {
    pdm && pdm.send({ type: 'vad', payload: data });
  };
}
export default StreamProcessor;

//@@ Consider making the following static methods of StreamProcessor
// extract the first audio track from stream
export const getAudioTrack = stream =>
  stream ? makeArray(stream.getAudioTracks())[0] : null;

// update the specified track constraints, and return a Promise with confirmed results
export const updateConstraints = (track, updates) => {
  return track
    .applyConstraints({ ...track.getConstraints(), ...updates })
    .then(() => getAudioTrackInfo(track));
};

// show capabilities, constraints, settings on specified audio track
export const getAudioTrackInfo = (track, label = null) => {
  const keysOfInterest = [
    'autoGainControl',
    'noiseSuppression',
    'echoCancellation',
  ];
  const result = {
    capabilities: pick(getAudioTrackCapabilities(track), ...keysOfInterest),
    constraints: pick(track.getConstraints(), ...keysOfInterest),
    settings: pick(track.getSettings(), ...keysOfInterest),
  };
  label && console.log(`getAudioTrackInfo (${label}):`, result);
  return result;
};

// determine capabilities of the first audio track
export const getAudioTrackCapabilities = track => {
  // firefox workaround,  track.getCapabilities not supported
  const capabilities =
    track && track.getCapabilities
      ? track.getCapabilities() // supported by specific track
      : navigator.mediaDevices.getSupportedConstraints(); // more general, supported by browser

  return {
    autoGainControl: makeArray(capabilities?.autoGainControl)[0],
    echoCancellation: makeArray(capabilities?.echoCancellation)[0],
    noiseSuppression: makeArray(capabilities?.noiseSuppression)[0],
  };
};
