/**
 * Demonstration of host video page with OkSayit comments
 *
 * Omissions:
 * - load video using API
 * - pause youtube play upon OkSayit play
 *
 */

import { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PlaylistView from 'containers/PlaylistView'; //browse set of messages
import HorizontalSplit from 'components/layouts/components/HorizontalSplit';
import useDialogs from 'hooks/useDialogs';
import { useDispatch } from 'react-redux';
import { dialogActions } from 'store/dialog-slice';
import { uiActions } from 'store/ui-slice';
import { makeStyles } from '@material-ui/core/styles';
import { makeArray } from 'lib/utils/utils';
import { retrieveMessage, isPublicType, isTopicType } from 'lib/api/messageApi';
import HostedMediaContext from 'context/hostedMediaContext';
import ReactPlayer from 'react-player/youtube';

const aspect = 9 / 16;

const topicId = '256651a4-ff28-4794-a636-137ae6e323bc';
// const filename = 'public://2023-12/nelly.mp3';

const useStyles = makeStyles(theme => ({
  topSection: {},
  bottomSection: {
    paddingTop: 20,
  },

  titleContainer: {
    textAlign: 'center',
  },
  mediaOuterContainer: {
    marginTop: '2rem',
  },
  mediaInnerContainer: {
    width: 560,
    height: `calc(${500 * aspect}px )`,
    border: '5px ridge white',
    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: `calc(${300 * aspect}px )`,
    },
    color: 'white',
  },
  messagePlayerContainerOuter: {
    textAlign: 'center',
    position: 'relative',
  },
  messagePlayerConteinerInner: {
    width: 280,
    position: 'relative',
    top: 35,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      top: 35,
    },
  },
  playlistContainer: {
    width: '100%',
  },
}));

const NellyMais = () => {
  const [notFound, setNotFound] = useState(false);
  const [mediaPlayControl, setMediaPlayControl] = useState(false);
  const [mediaPlayerReady, setMediaPlayerReady] = useState(false);

  // container to hold UI for VoicePlayer (rendered by child PlaylistBrowser)
  const [playerContainer, setPlayerContainer] = useState(null);

  const { dialogsFetchedFlg, openTopicId, dialogs } = useDialogs();

  const classes = useStyles();
  const dispatch = useDispatch();
  const playerContainerId = 'player-container';

  useEffect(() => {
    if (dialogsFetchedFlg) {
      const message = retrieveMessage(topicId);
      if (isPublicType(message?.type) && isTopicType(message?.type)) {
        dispatch(dialogActions.setSelectedMessage(topicId));

        // if responses exist, open the topic
        if (makeArray(message.responses).length && topicId !== openTopicId) {
          dispatch(dialogActions.toggleOpenTopic(topicId));
        }
      } else {
        // host's message cannot be found
        setNotFound(true);
      }
    }
  }, [dialogsFetchedFlg, dialogs]);

  useEffect(() => {
    setPlayerContainer(document.getElementById(playerContainerId));
  }, []);

  // assure that media and voice players are not talking over each other
  const handleMediaPlayStart = () => {
    setMediaPlayControl(true);
    // if voice is playing, pause it
    dispatch(uiActions.voicePlayerPause());
  };
  const handleVoicePlayStart = () => {
    // if media is playing, pause it
    mediaPlayControl && setMediaPlayControl(false);
  };
  const handleMediaPlayStop = () => setMediaPlayControl(false);
  const handleVoicePlayStop = () => {};

  return (
    <HorizontalSplit
      topItem={
        <Grid container direction="column" alignItems="center">
          <Grid item classes={{ root: classes.titleContainer }}>
            <Typography variant="h6">
              Stop saying "MAIS" in French | Use these alternatives INSTEAD
            </Typography>
          </Grid>
          <Grid item>
            <div className={classes.mediaOuterContainer}>
              <div className={classes.mediaInnerContainer}>
                <ReactPlayer
                  url="https://www.youtube-nocookie.com/embed/DA18kTyC78g?si=phQY1VuoOkvtRmLf&amp;controls=0"
                  controls
                  playing={mediaPlayControl}
                  width="100%"
                  height="100%"
                  onReady={() => setMediaPlayerReady(true)}
                  onStart={() => console.log('ReactPlayer start.')}
                  onPlay={handleMediaPlayStart}
                  onPause={handleMediaPlayStop}
                  onEnded={handleMediaPlayStop}
                  onError={e => {
                    console.log('ReactPlayer error:', e);
                    handleMediaPlayStop();
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item classes={{ root: classes.messagePlayerContainerOuter }}>
            <div
              id="player-container"
              className={classes.messagePlayerConteinerInner}
            ></div>
          </Grid>
        </Grid>
      }
      bottomItem={
        !dialogsFetchedFlg || !mediaPlayerReady || notFound ? null : (
          <Grid container direction="column" alignItems="center">
            <Grid item classes={{ root: classes.playlistContainer }}>
              <HostedMediaContext.Provider
                value={{
                  playerActionsLegends: 'tooltips', //used in PlayerActions
                  playerContainer,
                  notePlayStart: handleVoicePlayStart,
                }}
              >
                <PlaylistView zen={true} singleThreadId={topicId} />
              </HostedMediaContext.Provider>
            </Grid>
          </Grid>
        )
      }
      classes={classes}
    />
  );
};

export default NellyMais;
