/**
 * A container providing scrollbars if content doesn't fit.
 */

import React from 'react';
import { useSelector } from 'react-redux';

const Scroller = ({ children }) => {
  // scrollable height calculation
  const scrollableSectionMaxHeight = useSelector(
    state => state.ui.layout.mainContentHeight
  );

  return (
    <div style={{ overflow: 'auto', maxHeight: scrollableSectionMaxHeight }}>
      {children}
    </div>
  );
};

export default Scroller;
