/**
 * VadLinto
 *
 * Detects voice on audiotrack in the specified audioStream
 * Employs webvoice-vad https://github.com/jsulmar/webvoice-vad
 * (based on WebVoiceSDK https://github.com/linto-ai/WebVoiceSDK#readme)
 *
 *
 */

import { Src, Vad } from 'webvoice-vad'; // customized @linto-ai/webvoicesdk
//const vad  = require('webvoice-vad');

class VadLinto {
  talking = false;
  options = {};
  assets = {};
  instant = 0;
  slow = 0;

  static defaultOptions = {
    vadOptions: {
      numActivations: 10,
      threshold: 0.85,
      timeAfterStop: 800,
      dlog: false, //enable diagnostic logging
    },

    onVoiceStart: function () {
      console.log('vadL talking');
    },
    onVoiceStop: function () {
      console.log('vadL quiet');
    },
    onUpdate: function (val) {},
  };

  constructor({ type, context, audioStream, options = {}, key }) {
    this.key = key;
    this.type = type;

    const vadOptions = {
      ...VadLinto.defaultOptions.vadOptions,
      ...options.vadOptions,
    };
    this.options = { ...VadLinto.defaultOptions, ...options, vadOptions };
    this.src = new Src({
      source: { stream: audioStream, context },
      onAudioFrame: this.handleAudioFrame,
    });
    this.vad = new Vad(this.options.vadOptions);
    this.start(); //@@ warning: async function within constructor
  }

  start = async () => {
    await this.src.start();
    await this.vad.start(this.src);
    this.vad.addEventListener('speakingStatus', this.handleVadEvent);
    this.active = true;
  };

  // invoked each time ScriptProcessorNode input buffer if filled
  handleAudioFrame = buff => {
    var i;
    var sum = 0.0;
    for (i = 0; i < buff.length; ++i) {
      sum += buff[i] * buff[i];
    }
    this.instant = Math.sqrt(sum / buff.length) * 10; // multiplier added
    this.slow = 0.95 * this.slow + 0.05 * this.instant;
  };

  // return instant, slow samples as requested
  // (arr contains one or more: 'instant', 'slow')
  getSample = (type = 'instant') => {
    const sample = type === 'instant' ? this.instant : this.slow;
    return sample ? sample.toFixed(2) : 0;
  };

  handleVadEvent = e => {
    //console.log(`VadLinto handleVadEvent ${this?.key} :`, e.detail)
    e.detail ? this.options.onVoiceStart() : this.options.onVoiceStop();
  };

  resume = () => {
    //console.log(`VadLinto ${this.key} active=${this?.active} resume.`)
    if (!this?.active) {
      this.src.resume();
      this.vad.addEventListener('speakingStatus', this.handleVadEvent);
      this.active = true;
    }
  };

  suspend = () => {
    //console.log(`VadLinto ${this.key}  active=${this?.active} suspend.`)
    if (this?.active) {
      this.src.pause();
      this.vad.removeEventListener('speakingStatus', this.handleVadEvent);
      this.active = false;
    }
  };

  destroy = async () => {
    //console.log(`VadLinto key=${this.key} destroy.`)
    await this.vad.stop();
    await this.src.stop();
    this.vad.removeEventListener('speakingStatus', this.handleVadEvent);
    delete this.vad;
    delete this.src;
  };
}

export default VadLinto;
