import { configureStore } from '@reduxjs/toolkit';

// demoonstration slices
import counterReducer from 'experiments/ReduxDemo1/store/counter';
import authReducer from 'experiments/ReduxDemo1/store/auth';
import ui2Reducer from 'experiments/ReduxDemo2/store/ui-slice';
import cartReducer from 'experiments/ReduxDemo2/store/cart-slice';

// application slices
import userReducer from 'store/user-slice';
import uiReducer from 'store/ui-slice';
import dialogReducer from 'store/dialog-slice';
import webRTCReducer from 'store/webRTC-slice';
import langReducer from 'store/lang-slice';

const store = configureStore({
  reducer: {
    //ReduxDemo2
    ui2: ui2Reducer,
    cart: cartReducer,

    //ReduxDemo1
    counter: counterReducer,
    auth: authReducer,

    //HVN
    user: userReducer,
    ui: uiReducer,
    dialog: dialogReducer,
    webRTC: webRTCReducer,
    lang: langReducer,
  },
});

export default store;
