/**
 * Configuration template for 'lang-en-fr-1' partition/community
 *
 */

export default {
  // overrides applied to items contained in config-common
  //    overrides: [
  //        {
  //            key: 'authMethod',
  //            values: [
  //                'sms'
  //            ],
  //        },
  //    ],
  pages: [
    {
      key: 'getAttributes',
      title: 'getAttributes',
      sections: [
        {
          key: 'userParams',
          title: 'About You',
          fields: [
            {
              key: 'language',
              title: 'Native Language',
              value: null,
              error: false,
              required: true,
              type: 'radio',
              values: ['Francophone', 'Anglophone'],
            },
            {
              key: 'gender',
              title: 'Gender',
              value: null,
              error: false,
              helper: 'What is your gender?',
              required: true,
              type: 'radio',
              values: ['Male', 'Female'],
            },
            {
              key: 'ageRange',
              title: 'Age Range',
              value: null,
              error: false,
              helper: 'What is your age range?',
              required: true,
              type: 'radio',
              values: ['Under 40', 'Over 40'],
            },
            {
              key: 'skill',
              title: 'Skill Level',
              value: null,
              error: false,
              helper:
                'What is your skill level in the language you wish to learn?',
              required: true,
              type: 'radio',
              values: ['Beginner', 'Intermediate', 'Advanced'],
            },
          ],
        },
        {
          key: 'voice',
          title: 'Your Voice Profile for this Community',
          fields: [
            {
              key: 'profile',
              title: 'Voice Profile',
              required: false,
              type: 'voice',
              values: ['profile'],
            },
          ],
        },
      ],
    },
    {
      // Note that filter attributes must be formed by adding
      // a standard suffix to the user key names.
      // e.g. user.ageRange is matched against the filter attribute ageRangePref

      key: 'filter',
      title: 'Filters',
      sections: [
        {
          key: 'filterSection',
          title: 'Partner Preference',
          fields: [
            {
              key: 'languagePref',
              title: 'Language',
              value: { Francophone: false, Anglophone: false },
              error: false,
              helper: 'Member Native Language',
              required: false,
              type: 'checkbox',
              values: ['Francophone', 'Anglophone'],
            },
            {
              key: 'skillPref',
              title: 'Skill',
              value: { Beginner: false, Intermediate: false, Advanced: false },
              error: false,
              helper: 'Member Skill in Second Language',
              required: false,
              type: 'checkbox',
              values: ['Beginner', 'Intermediate', 'Advanced'],
            },
            {
              key: 'genderPref',
              title: 'Gender',
              value: 'No Preference',
              error: false,
              helper: 'Member Gender',
              required: false,
              type: 'radio',
              values: ['Male', 'Female', 'No Preference'],
            },
            {
              key: 'ageRangePref',
              title: 'Age',
              value: 'No Preference',
              error: false,
              helper: 'Member Age Range',
              required: false,
              type: 'radio',
              values: ['Under 40', 'Over 40', 'No Preference'],
            },
          ],
        },
      ],
    },
  ],
};
