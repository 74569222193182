/**
 * persistence services
 *  - pre-append application token to object name
 *  - encrypt/decrypt or stringify/parse objects
 *
 */
import { encrypt, decrypt } from './crypto';
import { makeArray } from 'lib/utils/utils';

const appToken = 'hvn';

const localStore = () => {
  const crypt = true; // enable encryption
  const encode = obj => (crypt ? encrypt(obj) : JSON.stringify(obj));
  const decode = str => (crypt ? decrypt(str) : JSON.parse(str));

  const jsonParse = str => {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return null;
    }
  };

  //TODO2: encrypt all sensitive localstore data
  const setObj = (name, obj) => {
    //console.log("setObj:", {...obj});
    const obj2 = JSON.parse(JSON.stringify(obj));
    localStorage && localStorage.setItem(`${appToken}-${name}`, encode(obj));
  };

  const getObj = name => {
    const val = localStorage && localStorage.getItem(`${appToken}-${name}`);
    const got = decode(val) || jsonParse(val); // decrypts iff val is encrypted
    //console.log("getObj:", got);
    return got;
  };

  const removeObj = name => {
    localStorage && localStorage.removeItem(`${appToken}-${name}`);
  };

  return {
    setObj,
    getObj,
    removeObj,
  };
};

export default localStore;
