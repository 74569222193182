/**
 * Renders tabs to select between publlic/directed message filter
 *
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import TabsStd from 'components/utils/TabsStd';
import { dialogActions } from 'store/dialog-slice';
import { uiActions } from 'store/ui-slice';
import AnimationDecoration from 'components/utils/AnimationDecoration';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  // previously used animation experiment
  '@keyframes blinker': {
    '0%': { opacity: 0 },
  },
  'blinkItem': {
    animation: `$blinker 1s linear infinite`,
  },

  'tabsContainerOuter': {
    height: 58,
    borderBottom: '1px solid grey',
  },
}));

const VisibilitySelectorTabs = ({ renderItems }) => {
  const { alert } = useSelector(state => state.ui.inbox);
  const visibility = useSelector(state => state.dialog.visibility);

  const tabValue = ['public', 'directed'].findIndex(i => i === visibility);

  const classes = useStyles();
  const dispatch = useDispatch();

  //list of tabs to be rendered with respective components to render
  const tabItems = () => [
    { legend: t('Public'), icon: null, components: [renderItems[0]] },
    {
      legend: (
        <AnimationDecoration action="bounce" enable={animateAlert()}>
          {t('Personal')}
        </AnimationDecoration>
      ),
      icon: null,
      components: [renderItems[1]],
    },
  ];

  //only animate an alert while viewing 'Public' tab
  const animateAlert = () => alert && tabValue === 0;

  const selectedContent = () => tabItems()[tabValue].components[0];

  const handleTabClick = value => {
    dispatch(dialogActions.setVisibilityFilter(value ? 'directed' : 'public'));
    //extinguish alert whenever a tab is clicked
    dispatch(uiActions.setInboxAlert(false));
  };
  return (
    <>
      <TabsStd
        classes={classes}
        items={tabItems()}
        value={tabValue}
        onChange={value => handleTabClick(value)}
      />
      {selectedContent()}
    </>
  );
};

export default VisibilitySelectorTabs;
