/**
 * CommunityInfoPlayer
 * Render a player for the infoUrl for the specified community.
 *
 */

import React, { useEffect, forwardRef, useImperativeHandle } from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { WEB_HOST } from 'configs/config-hvn';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import { srcOk } from 'lib/utils/utils';

const CommunityInfoPlayer = forwardRef((props, ref) => {
  const community = props?.community;
  const { defaultProps, setSrc, play, src } = useVoicePlayerHelper({
    playerKey: 'Community',
  });

  const { vxs } = useSelector(state => state.ui);
  const dispatch = useDispatch();

  // initialize
  useEffect(() => {
    const src = `${WEB_HOST}${community?.infoUrl}`;
    if (srcOk(src)) {
      setSrc(src);
    }
  }, [community]);

  // Expose methods to parent
  // ref.: https://dev.to/007_dark_shadow/react-hooks-calling-child-component-method-from-parent-144p
  useImperativeHandle(ref, () => ({
    play,
  }));

  return src ? (
    <div style={{ width: vxs ? '30%' : '100%' }}>
      <VoicePlayer
        {...defaultProps}
        autoplay={false}
        noBrowserViewControl={true}
        progressStyle="standard"
        uiItems={vxs ? ['hControls'] : ['visualizer']}
        onNext={null}
        onPrevious={null}
      />
    </div>
  ) : null;
});

CommunityInfoPlayer.propTypes = {
  community: PropTypes.object,
};

export default CommunityInfoPlayer;
