/**
 * Render a responsive fixed layout with header, menu, and main content area
 * Scrollbars in main content area are prevented from interfering
 * with other components.
 *
 * Components use fixed placement and main content height is dynamically
 * determined by detecting aggregate heights of other components.
 *
 * Standard dialogs and modals are also mounted and
 * conditionally rendered, some of which are controlled by Redux state.

 * Layout5 refactored how components are measured for placement, and
 * eliminated a number of historical features.
 *
 * Layout3 features eliminated:
 *   ProprietaryNoticeModal (obsolete)
 *   BetaTestWelcome (obsolete)
 *   TooltipStd (remnant of experiment)
 *   enableiPhoneCondensedViewTrigger
 *   renderIphoneViewportKludge
 *   renderTestLines
 *
 * Layout3 features moved to other components:
 *   CommunityIndicator ( moved to Header )
 *   MainMenu (tabs) ( moved to TopMenu )
 *   DisplayLanguageSelector ( moved to TopMenu and bottomMenu )
 *
 */

import React, { useState, useEffect, useRef } from 'react';

import { Container, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import Header from 'components/layouts/components/Header';
import debounce from 'lodash.debounce';
import Router from '../components/Router';
import CookieConsentBar from '../components/CookieConsentBar';
import Dlog from 'components/utils/Dlog';
import MainMenu from 'components/MainMenu';
import TopMenu from 'components/MainMenu/components/TopMenu';
import DrawerMenu from 'components/MainMenu/components/DrawerMenu';
import LogRegDialog from 'containers/RegistrationManager/components/LogRegDialog';
import { BackdropStd, DialogStd } from 'components/Modals';
import CommunityIndicator from 'components/layouts/components/CommunityIndicatorV2';
import LiveCall from 'components/LiveCall';
import SuggestionDialog from 'components/SuggestionDialog';
import DevNotice from 'components/utils/DevNotice';
import SleepCountdown from 'components/SleepCountdown';
import Slogan from '../components/Slogan';
import SleepNotice from 'components/SleepNotice';
import { BRAND } from 'configs/config-hvn';

// diagnostics configuration and control -------------------------------------------------------------

// replace main content with auto=generated lines, also with dimension report
const showTestLines = false;

// draw a vertical line the entire height of viewport with bottom pixel marker
const showViewportMarker = false;

// iPhone by default renders a fat top address bar and a bottom menu
// condensed view, triggered by scroll up, shrinks top address and hides bottom menu
// however condensed view is not triggered when all components are fixed
// to invoke condensed view, a transparent scrollable overlay is rendered
//@@ PROBLEM: this feature disables scrolling for children components also
//  const enableiPhoneCondensedViewTrigger = false

//@@ enable language selection with main menu
// (plan to enable this only for language related communities)
const langBtnEn = true;

// 60px==4rem, 48px==3rem
const heights = {
  std: {
    header: 64,
    bottomMenu: 60,
  },
  xs: {
    header: 48,
    bottomMenu: 48,
  },
};

// styles  -------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  topMenu: {
    width: '100%',
    position: 'fixed',
    //marginBottom: 10,
  },
  communitySelector: {
    width: '100%',
    position: 'fixed',
    textAlign: 'center',
    paddingTop: '1rem',
  },
  slogan: {
    width: '100%',
    textAlign: 'center',
    position: 'fixed',
  },
  mainSpacer: {
    width: '100%',
    position: 'fixed',
    height: '1rem',
  },
  mainContent: {
    width: '100%',
    position: 'fixed',
    overflow: 'hidden',
  },
  containerMainInner: {
    position: 'relative',
    padding: '0px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px',
    },
  },
  viewportMarker: {
    backgroundColor: 'red',
    marginLeft: 30,
    width: 2,
    height: '100vh',
    zIndex: 1200,
    borderBottom: '1px solid white',
  },
}));

// style overrides for children components
const useStylesOverrides = makeStyles(theme => ({
  header: {
    width: '100%',
    position: 'fixed',
    top: 0,
    //           marginBottom: 10,
  },
  toolbar: {
    minHeight: heights.std.header,
    [theme.breakpoints.down('xs')]: {
      minHeight: heights.xs.header,
    },
  },
  footerMenu: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: heights.std.bottomMenu,
    [theme.breakpoints.down('xs')]: {
      height: heights.xs.bottomMenu,
    },
    overflow: 'hidden',
    borderTop: `1px solid white`,
    //zIndex: 100, // do not let main content occlude the bottomMenu
  },
}));

const Layout5 = () => {
  const [touchMoveBlocked, setTouchMoveBlocked] = useState(false);
  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);

  const classes = useStyles();
  const classesOverrides = useStylesOverrides();

  const theme = useTheme();
  const dispatch = useDispatch();

  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const vxs = useMediaQuery(
    `(max-height: ${theme.breakpoints.custom.vxsThreshold}px)`
  );

  const { pSession, dlog, holdoffCookieConsentBar } = useSelector(
    state => state.ui
  );
  const communityHide = useSelector(state => state.ui).layout.componentHide
    .community;

  const { menuPosition } = pSession;

  const menuHide = useSelector(state => state.ui).layout.componentHide.menu;
  const showTopMenu = !menuHide && !xs && ['tabs'].includes(menuPosition);
  const showBottomMenu = !menuHide && !xs && ['footer'].includes(menuPosition);
  const showDrawerMenuButton = !menuHide && (xs || menuPosition === 'drawer');
  const userStatus = useSelector(state => state.user.status);
  const showSleepNotice = userStatus === 'asleep';

  // control of visibility and positioning of components
  const [layout, setLayout] = useState({
    header: { en: true, ref: useRef(0), style: null }, // (can include community selector)
    communitySelector: { en: xs, ref: useRef(0), style: null }, // community selector (when not included in header)
    topMenu: { en: false && showTopMenu, ref: useRef(0), style: null }, // dev disabled
    slogan: { en: true, ref: useRef(0), style: null }, // community slogan
    mainSpacer: { en: true, ref: useRef(0), style: null }, // in lieu of top padding in main content
    main: { en: true, ref: useRef(0), style: null },
    sleep: { en: false, ref: useRef(0), style: null },
    bottomMenu: { en: false && showBottomMenu, ref: useRef(0), style: null }, // dev disabled
  });

  // re-render each time viewport changes
  const [viewportChangeCnt, setViewportChangeCnt] = useState(0);
  const reRender = () => setViewportChangeCnt(old => ++old);

  // watch for resizing of window, apply debounce
  useEffect(() => {
    const handleResize = debounce(() => {
      reRender();
      dispatch(uiActions.setViewportDimensions());
    }, 200);

    // execute handler now, and upon change in viewport
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // watch for vertically small viewport
  useEffect(() => {
    // dispatch viewport changes to redux state
    dispatch(uiActions.vxsSetStatus(vxs));
  }, [vxs, dispatch]);

  // evaluate heights and positions of fixed-position elements
  useEffect(() => {
    // note: this does not do a deep copy
    const layoutBuff = { ...layout };

    layoutBuff.topMenu.en = showTopMenu;
    layoutBuff.bottomMenu.en = showBottomMenu;
    const getBottomMargin = el => {
      if (typeof el === 'object') {
        const style = el.currentStyle || window.getComputedStyle(el);
        return parseFloat(style.marginBottom);
      } else {
        return 0;
      }
    };

    // heights are determined from component ref's
    const heights = {
      header: layoutBuff.header.ref?.current?.offsetHeight,
      communitySelector:
        layoutBuff.communitySelector.ref?.current?.offsetHeight,
      topMenu: layoutBuff.topMenu.ref?.current?.offsetHeight,
      slogan: layoutBuff.slogan.ref?.current?.offsetHeight,
      mainSpacer: layoutBuff.mainSpacer.ref?.current?.offsetHeight,
      main: window.innerHeight, // starting value, to be decremented
      bottomMenu: layoutBuff.bottomMenu.ref?.current?.offsetHeight,
    };
    // dynamic placement
    const positions = {};

    Object.entries(heights).reduce((acc, [key, val]) => {
      if (Boolean(val)) {
        // key with value exists...
        // space consumed is offsetHeight plus margin
        const vSpace =
          heights[key] + getBottomMargin(layoutBuff[key].ref.current);

        // decrease "main" height by vSpace
        key !== 'main' && (heights['main'] -= vSpace);

        // place this component, but skip hard-wired components
        if (!['header', 'bottomMenu'].includes(key)) {
          positions[key] = acc;
          layoutBuff[key].style = { top: acc };
        }

        // determine next component's position
        const nxtPos = acc + vSpace;
        return nxtPos;
      } else {
        // no height, make no change
        return acc;
      }
    }, 0);

    // apply final height to "main"
    dispatch(uiActions.setMainContentHeight(heights.main));
    layoutBuff['main'].style.height = heights.main;
    setLayout(layoutBuff);
  }, [
    layout.header.en,
    layout.topMenu.en,
    layout.main.en,
    layout.sleep.en,
    layout.bottomMenu.en,
    layout.header.ref?.current?.offsetHeight,
    layout.topMenu.ref?.current?.offsetHeight,
    layout.bottomMenu.ref?.current?.offsetHeight,
    viewportChangeCnt,
    pSession.community?.partition,
  ]);

  // manage changes to selected components
  useEffect(() => {
    // update the enable flags for affected keys
    setLayout(old => ({
      ...old,
      topMenu: { ...old.topMenu, en: showTopMenu },
      bottomMenu: { ...old.bottomMenu, en: showBottomMenu },
      communitySelector: { ...old.communitySelector, en: xs },

      // replace the main section during sleep periods
      main: { ...old.main, en: !showSleepNotice },
      sleep: { ...old.sleep, en: showSleepNotice },
    }));
  }, [showTopMenu, showBottomMenu, showSleepNotice, xs]);

  // watch for vertically small viewport
  useEffect(() => {
    // dispatch viewport changes to redux state
    dispatch(uiActions.vxsSetStatus(vxs));
  }, [vxs, dispatch]);

  const renderHeader = () => {
    const { ref, style } = layout.header;
    return (
      <Header
        ref={ref}
        classes={classesOverrides}
        title={BRAND}
        heading={communityHide ? null : <CommunityIndicator />}
        forceMenuIcon={false}
        showDrawerMenuButton={showDrawerMenuButton}
        drawerOpen={drawerMenuOpen}
        drawerOpenRequest={() => setDrawerMenuOpen(true)}
      />
    );
  };

  const renderCommunitySelector = () => {
    const { ref, style } = layout.communitySelector;
    return communityHide ? null : (
      <div ref={ref} style={style} className={classes.communitySelector}>
        <CommunityIndicator />
      </div>
    );
  };

  const renderSlogan = () => {
    const { ref, style } = layout.slogan;
    return (
      <div ref={ref} style={style} className={classes.slogan}>
        <Slogan />
      </div>
    );
  };

  const renderMainSpacer = () => {
    const { ref, style } = layout.mainSpacer;
    return <div ref={ref} style={style} className={classes.mainSpacer}></div>;
  };

  const renderMain = () => {
    const { ref, style } = layout.main;
    return (
      <div ref={ref} style={style} className={classes.mainContent}>
        <Container classes={{ root: classes.containerMainInner }}>
          <Router />
        </Container>
      </div>
    );
  };

  const renderSleep = () => {
    const { ref, style } = layout.main;
    return (
      <div ref={ref} style={style} className={classes.mainContent}>
        <Container classes={{ root: classes.containerMainInner }}>
          <SleepNotice />
        </Container>
      </div>
    );
  };

  const renderTopMenu = () => {
    const { ref, style } = layout.topMenu;
    return (
      <div ref={ref} style={style} className={classes.topMenu}>
        <TopMenu langBtnEn={langBtnEn} />
      </div>
    );
  };

  const renderBottomMenu = () => {
    const { ref, style } = layout.bottomMenu;
    return (
      <MainMenu
        ref={ref}
        style={style}
        classes={classesOverrides}
        format="Footer"
        langBtnEn={langBtnEn}
      />
    );
  };

  // render a vertical bar the full viewport height
  // include a bottom marker
  const renderViewportMarker = () => (
    <div style={{ display: 'flex' }}>
      <div className={classes.viewportMarker}>{window.innerHeight}</div>
    </div>
  );

  const renderModals = () => {
    const { betaTestRestriction, community } = pSession;
    const dlogTopPosition = heights[xs ? 'xs' : 'std'].header;

    return (
      <>
        {/* Modals & Dialogs  */}
        {holdoffCookieConsentBar ? null : <CookieConsentBar />}
        <BackdropStd redux={true} />
        <DialogStd redux={true} />
        <LogRegDialog />
        {/* TODO:consider using full screen dialog /> */}
        {dlog.enabled && <Dlog topPosition={dlogTopPosition} />}
        <DrawerMenu
          open={drawerMenuOpen}
          onClose={() => setDrawerMenuOpen(false)}
        />
        <SuggestionDialog />
      </>
    );
  };

  return (
    <>
      {showViewportMarker && renderViewportMarker() /* diagnostic */}
      {layout.header.en && renderHeader()}
      {layout.communitySelector.en && renderCommunitySelector()}
      {layout.topMenu.en && renderTopMenu()}
      {layout.slogan.en && renderSlogan()}
      {layout.mainSpacer.en && renderMainSpacer()}
      {layout.main.en && renderMain()}
      {layout.sleep.en && renderSleep()}
      {layout.bottomMenu.en && renderBottomMenu()}
      <DevNotice />
      <SleepCountdown />
      {renderModals()}
      {/* Don't show LiveCall button, but mount associated dialogs */}
      <div style={{ height: 0 }}>
        <LiveCall />
      </div>
    </>
  );
};

export default Layout5;
