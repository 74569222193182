/**
 * CallStatus
 * Provide status content describing an in-process Live Call
 *
 * views:
 * -  full  - for presentation within StatusDialog
 * -  summary - for single-line presentation within CallProgressDialog
 *
 * Content is dependent on the selected view, call progress state, and user's role
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import { WEB_HOST } from 'configs/config-hvn';
import { t } from 'lib/translation/trans';

const CallStatus = ({ view, invite, calleeBusy }) => {
  const { defaultProps } = useVoicePlayerHelper({ playerKey: 'CallStatus' });

  const { participants, call } = useSelector(state => state.webRTC);

  const remoteScreenName = participants.remote.screenName || invite?.screenName;
  const regretsFileUrl = call?.regretsFileUrl;
  const regretsMessage = `${WEB_HOST}/${regretsFileUrl}`;
  const role = participants.local?.role || 'callee';
  const { langMap: t1 } = useSelector(state => state.lang);
  const whoActed =
    call.initiator === 'local' ? t1.T$_You : participants.remote.screenName;

  const possessiveDisconnectMessage = () =>
    call.initiator === 'local'
      ? t1._Your_device_disconnected
      : t1.T$_XXXs_device_disconnected.replace(
          /XXX/gi,
          participants.remote.screenName
        );

  const renderPlayer = src => (
    <div style={{ width: '40%' }}>
      <VoicePlayer
        {...defaultProps}
        src={src}
        autoplay={true}
        onNext={null}
        onPrevious={null}
        uiItems={['hControls']}
        ident="CallStatus"
      />
    </div>
  );

  // state and role dependent status messages
  // (invoke statusMessages[role][progress])
  const statusMessages = () => {
    return {
      caller: {
        invited: [
          `${t1.T$_Waiting_for} ${remoteScreenName} ${t1.T$_to_accept}`,
        ],
        accepted: [
          `${whoActed} ${t1.T$_has_accepted_your_call}`,
          `${t1.T$_You_can_connect_now}`,
        ],
        hungup: [
          `${t1.T$_Your_call_has_ended}`,
          `${whoActed} ${t1.T$_hung_up}`,
        ],
        declined: regretsFileUrl
          ? [
              `${whoActed} ${t1.T$_declined_your_invitation}`,
              <div
                style={{ textAlign: 'center' }}
              >{`${t1.T$_Regrets_Message}`}</div>,
              <Grid container justify="center">
                {renderPlayer(regretsMessage)}
              </Grid>,
            ]
          : [`${whoActed} ${t1.T$_declined_your_invitation}`],
        removed: [`${whoActed} ${t('is_busy_cant_speak')}`],
        cancelled: [`${whoActed} ${t1.T$_cancelled_this_call}`],
        disconnected: [
          `${t1.T$_This_call_is_cancelled}`,
          possessiveDisconnectMessage(),
        ],
        problem: [
          t1.T$_This_invitation_cannot_go_through_right_now,
          t1.T$_Your_party_may_no_longer_be_available,
        ],
      },
      callee: {
        invited: [
          <div>{`${remoteScreenName} ${t1.T$_is_calling_you}`}</div>,
          <div
            style={{ textAlign: 'center' }}
          >{`${t1.T$_Invitation_Message}`}</div>,
          <Grid container justify="center">
            {renderPlayer(`${WEB_HOST}/${invite?.fileUrl}`)}
          </Grid>,
        ],
        accepted: [
          t1.T$_You_have_accepted_this_call,
          `${t1.T$_Waiting_for} ${participants.remote.screenName} ${t1.T$_to_connect}`,
        ],
        hungup: [t1.T$_Your_call_has_ended, `${whoActed} ${t1.T$_hung_up}`],
        declined: [
          `${remoteScreenName} ${t1.T$_was_calling_you}`,
          `${whoActed} ${t1.T$_declined_this_invitation}`,
        ],
        removed: [
          `${remoteScreenName} ${t1.T$_was_calling_you}`,
          `${whoActed}  ${t('is_busy_cant_speak')}`,
        ],
        cancelled: [`${whoActed} ${t1.T$_cancelled_this_call}`],
        disconnected: [
          t1.T$_This_call_is_cancelled,
          possessiveDisconnectMessage(),
        ],
        problem: [
          `${remoteScreenName} ${t1.T$_was_calling_you}`,
          t1.T$_This_call_cannot_connect_right_now,
        ],
      },
    };
  };

  // look up the multi-line status, dependent on role and call progress
  const fullStatus = () => {
    const msgArray = statusMessages()[role][call.progress];
    return Array.isArray(msgArray) ? (
      <div>
        {msgArray.map(line => (
          <div>{line}</div>
        ))}
      </div>
    ) : null;
  };

  // extract the last line of the full status
  const summaryStatus = () => {
    const full = statusMessages()[role][call.progress];
    return Array.isArray(full) && full.length ? full.slice(-1)[0] : null;
  };

  return view === 'full' ? fullStatus() : summaryStatus();
};

export default CallStatus;
CallStatus.propTypes = {
  view: PropTypes.string, // 'brief', 'full', 'summary'
  calleeBusy: PropTypes.bool,
  regretsMessage: PropTypes.string, //path to audio file
};
CallStatus.defaultProps = {
  view: 'full',
  calleeBusy: false,
  regretsMessage: null,
};
