/**
 * useAppInstallSuggestion custom hook
 *
 * Manage rendering of a suggestion that encourages user to install the PWA
 *
 * Insall PWA programmatically using button if possible, otherwise issue instructions
 *
 * Browser/os constraints:
 * - 1. ((chrome | edge) && !ios) provide beforeinstallpromptEvent enabling programmatic installation.
 * - 2. (ios) installation via browser settings
 * - 3. (safari && osx) installation NOT possible, use bookmark as work around
 * - 4. (others) installation NOT possible, use bookmark as work around
 *
 * Notes:
 * - avoid launching reminder if it is not appropriate (if not supported or PWA already installed)
 * - the main thread cannot directly detect if PWA is installed
 *    - employ persistent flag (pwaInstalled) set by PWA to indicate it has been installed
 *    - when pwaInstalled  is set, no further recommendations will be issued.
 *    - if PWA is uninstalled by user, the pwaInstalled remains set
 *    - TBD, a method to detect if PWA is uninstalled
 *
 * Punchlist:
 *   - chrome/safari: A "Chrome Apps" window will open, however the App  will also start.  The app and browser windows may cover up ....
 *   - iphone/safari: "Sorry, but you will have to login again...
 *   - implement translations
 */

import React from 'react';
import { t } from 'lib/translation/trans';
import { useSelector } from 'react-redux';
import { pwa } from 'lib/classes/PWA';
import { Typography } from '@material-ui/core';

const useAppInstallSuggestion = onCompleted => {
  const { pSession, deviceInfo } = useSelector(state => state.ui);
  const noRecordOfInstallation = !pSession.pwaInstalled;
  const { browser, os, mobile, descriptor } = deviceInfo;
  // determine strategy for managing the reminder
  // prettier-ignore
  const strategy =
      // programmatic, using a button
      ['chrome', 'edge'].includes(browser) && os !== 'ios'
      ? 'button'

      // manual, providing instructions for browser settings
      : os === 'ios'
      ? 'manual'

      // create browser bookmark with specific instructions
      : os === 'osx' && browser === 'safari'
      ? 'bookmark1'

      // create browser bookmark with general instructions
      : 'bookmark2';

  const config = {
    type: 'app_install',
    offer: offerText(), //required, provides offer text
    approveBtnText: approveBtnText(), // optional, overrides default
    info: infoItem(), //optional, rendered upon Info button
    instruct: instructItem(), //optional, opt-in instructions
  };

  // A suggestion has been invoked, determine if it can be presented
  const canRenderSuggestion = () => {
    // ref.: https://web.dev/articles/install-criteria

    const rslt =
      pwa.isActivated() && // service worker must be activated
      noRecordOfInstallation && // PWA should not already be installed
      !pwa.runningAsPWA() && // must be running in browser, not PWA right now
      // for button strategy, must await beforeinstallpromptEvent
      (strategy === 'button' ? Boolean(pwa.beforeinstallpromptEvent) : true);

    // prettier-ignore
    //console.log(`canRenderSuggestion: rslt=${rslt}, isActivated=${pwa.isActivated()}, noRecordOfInstallation=${noRecordOfInstallation}, tabs=${!pwa.runningAsPWA()}, strategy=${strategy}, event=${Boolean(pwa.beforeinstallpromptEvent)}`);
    return rslt;
  };

  // render the suggestion
  function offerText() {
    // all ios, and safari/osx employ "bookmark" terminology
    if (os === 'ios' || (os === 'osx' && browser === 'safari')) {
      return 'Add OkSayit bookmark to your homescreen.';
    }
    return 'Install the OkSayit App for more features and convenience.';
  }

  // override default text for the "enable" button
  function approveBtnText() {
    return os === 'ios' || (os === 'osx' && browser === 'safari')
      ? 'Bookmark'
      : 'Install';
  }

  // render an explanation of the feature
  function infoItem() {
    if (os === 'ios' || (os === 'osx' && browser === 'safari')) {
      return 'A bookmark simplifies launch, keeps you logged-in and unlocks special features and notifications capability.';
    } else if (mobile) {
      //android
      return 'Installing OkSayit will make it available on your home screen. This will also unlock special features and notifications capability.';
    } else {
      // windows || chrome/osx
      return 'Installing OkSayit on your desktop enables convenient launch. This will also unlock special features and notifications capability.';
    }
  }

  // render instructions if necessary
  function instructItem() {
    if (strategy === 'button') {
      // no instruction needed, programmatic install is possible
      return null;
    }
    const instructions = [];
    if (os === 'ios') {
      instructions.push("Tap the browser's 'share' icon.");
      instructions.push('Scroll down the list of options.');
      instructions.push("Tap 'Add to Home Screen'.");
      instructions.push("Finish by clicking 'Add'.");
    } else {
      const select = mobile ? 'Tap' : 'Click';
      switch (browser) {
        case 'chrome':
          // not expected to reach here since chrome employs 'button' strategy
          instructions.push(`${select} the browser's 'three dots' icon.`);
          instructions.push(
            `Scroll down and select '${
              mobile ? 'Install App' : 'Install OkSayit Voice Network'
            }'.`
          );
          instructions.push("Select 'Install' in the dialog.");
          instructions.push(
            "The 'Chrome Apps' dialog will open. Drag the OkSayit item to your desktop."
          );
          break;
        case 'edge':
          // not expected to reach here since edge employs 'button' strategy
          instructions.push(`${select} the browser's 'three dots' icon.`);
          instructions.push("Scroll down and select 'Apps'.");
          instructions.push("Select 'Install OkSayit Voice Network'.");
          instructions.push("Select 'Install' in the dialog.");
          instructions.push("Check the box for 'Create Desktop Shortcut.");
          instructions.push("Select 'Allow' in the dialog");

          break;
        case 'safari':
          // safari on desktop cannot install PWA
          instructions.push(
            "Select 'Bookmarks' from the menu at the top of the display."
          );
          instructions.push("Choose 'Add Bookmark' from the list.");
          instructions.push("Again, select 'Bookmarks' from the top menu.");
          instructions.push("Choose 'Edit Bookmarks'.");
          instructions.push("Locate 'OkSayit' on the list.");
          instructions.push("Drag 'OkSayit' to the desktop.");
          break;
      }
    }

    if (instructions.length === 0) {
      return null;
    }
    return (
      <>
        <Typography variant="h4">Instructions:</Typography>
        <ol>
          {instructions.map(i => (
            <li>{i}</li>
          ))}
        </ol>
      </>
    );
  }

  // user wishes to move forward with subscription
  const handleOptIn = () => {
    if (pwa?.beforeinstallpromptEvent) {
      pwa.install();
    }
    onCompleted();
  };

  // discontinue future rendering of this alert
  const handleDisable = rslt => {
    onCompleted(rslt);
  };

  const handleLater = () => {
    onCompleted();
  };

  return {
    config,
    canRenderSuggestion,
    handleOptIn,
    handleDisable,
    handleLater,
  };
};

export default useAppInstallSuggestion;
