/**
 * Render a form as part of the login, registration, or profile update process
 * consisting of grouped sections, each with a set of fields
 * also with a submit/continue button
 *
 * Auto-submit option when all required fields are satisfied
 * Immediate auto-submit if no fields are allocated
 *
 * zen: strips Paper container and other verbosity
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import {} from '@material-ui/core';
import ButtonStd from 'components/utils/ButtonStd';
import LogRegSection from './LogRegSection';
import { makeArray } from 'lib/utils/utils';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  pageContainer: {},

  section: {
    margin: '1rem 0',
    padding: '1rem',
  },
}));

const LogRegPage = ({
  page,
  onFieldChange,
  onSubmit,
  classes: classesOverride,
  zen,
  refresh,
}) => {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const classes = { ...useStyles(), ...classesOverride };
  const { regDialog } = useSelector(state => state.ui);
  const bypassVoiceAttributes = regDialog?.bypassVoiceAttributes; //skip voice attribute collection

  // check the status of the fields
  useEffect(() => {
    const satisfied = areFieldRequirementsSatisfied();
    satisfied && (isAutoSubmitMode() || !fieldCnt()) && onSubmit && onSubmit(); //process auto-submit
    setEnableSubmit(satisfied); // enable the submit button

    //  console.log(`page ${page?.key} cnt=${fieldCnt()} text=${pageContainsTextField()} satisfied=${satisfied}}`)
  }, [page, refresh]);

  // check if all required fields have been populated
  const areFieldRequirementsSatisfied = () =>
    makeArray(page?.sections).reduce((acc1, section) => {
      const sectionOk = section.fields.reduce((acc2, field) => {
        const fieldOk = Boolean(!field?.required || field?.value);
        return acc2 && fieldOk;
      }, true);
      return acc1 && sectionOk;
    }, true);

  const fieldCnt = () =>
    makeArray(page?.sections).reduce(
      (out, section) => out + makeArray(section?.fields).length,
      0
    );

  // check all fields, determine if any use text or password fields
  const pageContainsTextField = () =>
    makeArray(page?.sections).reduce(
      (out, section) => out || fieldsContainTextField(section),
      false
    );

  const fieldsContainTextField = section =>
    makeArray(section?.fields).reduce(
      (out, field) => out || ['text', 'password'].includes(field.type),
      false
    );

  const isAutoSubmitMode = () => page?.auto && !pageContainsTextField();

  // iterate through page array, but extract field props from fields
  return (
    <div className={classes.pageContainer}>
      {/* render sections */}
      {page?.sections &&
        page.sections.map(
          s =>
            !(bypassVoiceAttributes && s.key === 'voice') && (
              <LogRegSection s={s} onFieldChange={onFieldChange} zen={zen} />
            )
        )}
      {/* page will automatically be submitted when all required fields are entered */}
      {page?.auto || (
        <div style={{ marginTop: 10 }}>
          <ButtonStd
            onClick={onSubmit}
            label={t('Next')}
            color="secondary"
            disabled={!enableSubmit}
          />
        </div>
      )}
    </div>
  );
};

LogRegPage.propTypes = {
  page: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            title: PropTypes.string,
            value: PropTypes.node,
            error: PropTypes.bool,
            required: PropTypes.bool,
            type: PropTypes.string,
            validate: PropTypes.string,
          })
        ),
      })
    ),
  }),

  onFieldChange: PropTypes.func,
  onSubmit: PropTypes.func,
  zen: PropTypes.bool, // render bare bones fields
};

LogRegPage.defaultProps = {
  zen: false,
};
export default LogRegPage;
