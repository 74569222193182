/**
 * Configuration template for 'general' partition/community
 *
 */

export default {
  // overrides applied to items contained in config-common
  overrides: [
    {
      key: 'authMethod',
      values: [
        'sms',
        'google',
        'facebook',
        // 'password',     //only valid with gated community
      ],
    },
  ],

  pages: [
    {
      key: 'getAttributes',
      title: 'getAttributes',
      sections: [
        {
          key: 'userParams',
          title: 'About You',
          fields: [
            {
              key: 'gender',
              title: 'Gender',
              value: null,
              error: false,
              helper: 'What is your gender?',
              required: false,
              type: 'radio',
              values: ['Male', 'Female'],
            },
            {
              key: 'language',
              title: 'Your Primary Language',
              value: 'English',
              error: false,
              required: false,
              type: 'radio',
              values: ['English', 'Spanish', 'French', 'Lithuanian'],
            },
          ],
        },
        {
          key: 'voice',
          title: 'Your Voice Profile for this Community',
          fields: [
            {
              key: 'profile',
              title: 'Voice Profile',
              required: false,
              type: 'voice',
              values: ['profile'],
            },
          ],
        },
      ],
    },
    {
      // Note that filter attributes must be formed by adding
      // a standard suffix to the user key names.
      // e.g. user.ageRange is matched against the filter attribute ageRangePref

      key: 'filter',
      title: 'Filters',
      sections: [
        {
          key: 'filterSection',
          title: 'Partner Preference',
          fields: [
            {
              key: 'genderPref',
              title: 'Gender',
              value: 'No Preference',
              error: false,
              helper: 'Member Gender',
              required: false,
              type: 'radio',
              values: ['Male', 'Female', 'No Preference'],
            },
            {
              key: 'languagePref',
              title: 'Language',
              value: {
                English: true,
                Spanish: true,
                French: true,
                Lithuanian: true,
              },
              error: false,
              helper: 'Member Language',
              required: false,
              type: 'checkbox',
              values: ['English', 'Spanish', 'French', 'Lithuanian'],
            },
          ],
        },
      ],
    },
  ],
};
