import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import DisplayLanguageSelector from 'components/Settings/components/DisplayLanguageSelector';

// for xs:
//  root.minWidth: 40
//  hide lang when expanded

const useStyles = makeStyles(theme => ({
  bottomNav: {
    background: 'grey',
    // width: '100%',
  },
  root: {
    'color': 'white',
    [theme.breakpoints.down('xs')]: {
      minWidth: 40,
    },
    '&$selected': {
      color: 'lightgreen',
    },
  },
  /* Styles applied to the root element if selected. */
  selected: {},
  footer: {
    backgroundColor: '#424242',
  },

  langBtn: {
    paddingLeft: 24,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8,
    },
  },
  // hide langBtn when menu is expanded
  langBtnMenuExpanded: {
    paddingLeft: 24,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const FooterMenu = ({
  classesFooter,
  items,
  value,
  onChange,
  langBtnEn,
  menuExpanded,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const micRef = React.useRef();

  const handleChange = (event, newValue) => {
    // history.push(appliance.route)
    onChange && onChange(newValue);
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const actionCreator = (label, value, icon) => (
    <BottomNavigationAction
      key={value}
      classes={{ root: classes.root, selected: classes.selected }}
      label={label}
      value={value}
      icon={icon}
    />
  );

  // hide DisplayLanguageSelector when xs and menu is expanded
  const showLangBtn = langBtnEn && !(xs && menuExpanded);

  return (
    <Grid
      classes={{ root: classes.footer }}
      container
      justify={showLangBtn ? 'space-between' : 'flex-end'}
      alignItems="center"
    >
      <Grid item classes={{ root: classes.langBtn }}>
        {showLangBtn && <DisplayLanguageSelector variant="toggle" />}
      </Grid>
      <Grid item>
        <BottomNavigation
          value={value}
          onChange={handleChange}
          classes={{ root: classesFooter }}
        >
          {items.map((item, ix) => {
            const { legend, icon } = item;
            return actionCreator(legend, ix, icon);
          })}
        </BottomNavigation>
      </Grid>
    </Grid>
  );
};

FooterMenu.propTypes = {
  classesFooter: PropTypes.string.isRequired,
};

FooterMenu.propTypes = {
  classesFooter: PropTypes.object,
  items: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
  menuExpanded: PropTypes.bool,
  langBtnEn: PropTypes.bool, // show DisplayLanguageSelector
};

FooterMenu.defaultProps = {
  langBtnEn: false,
};

export default FooterMenu;
