/**
 * Alerts.js
 *
 * Encapsulate various specific Alerts and prevent concurrent opening
 * Pause ActivityDetector while LiveCallAlertOpen
 *
 */

import React, { useState, useCallback, memo } from 'react';
import LiveCallAlert from 'components/LiveCall/components/LiveCallAlert';
import InboxAlert from 'containers/InboxView/components/InboxAlert';
import Alert from 'components/Modals/Alert';
import { activityDetector } from 'lib/classes/ActivityDetector';
import { useSelector } from 'react-redux';

const Alerts = () => {
  const [liveCallAlertOpen, setLiveCallAlertOpen] = useState(false);
  const [inboxAlertOpen, setInboxAlertOpen] = useState(false);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  // useCallback to avoid unnecessary re-rendering as transitions may be disrupted
  const handleLiveCallAlertStatusChange = useCallback(isOpen => {
    setLiveCallAlertOpen(isOpen);

    //       if (isOpen){
    //           activityDetector.pause() // do not report inactivity event
    //       } else {
    //           isAuthenticated && activityDetector.resume();  // resume only if user is logged in
    //       }
  }, []);
  const handleInboxAlertStatusChange = useCallback(isOpen => {
    setInboxAlertOpen(isOpen);
  }, []);

  // prioritize and prevent concurrent alerts
  return (
    <>
      <LiveCallAlert onStatusChange={handleLiveCallAlertStatusChange} />
      <InboxAlert
        disabled={liveCallAlertOpen}
        onStatusChange={handleInboxAlertStatusChange}
      />
      <Alert disabled={liveCallAlertOpen || inboxAlertOpen} />
    </>
  );
};
export default memo(Alerts);
