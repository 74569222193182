/**
 * fetcher
 *
 *  a wrapper to siimplify use of fetch()
 *  - preconfigures headers and url's for common request types
 *  - stringifies message body objects
 *  - automatically appliles user or admin Authorization token when available
 *  - obtains and/or refreshes hvn oauth token in background
 *  - detects and throws status errors
 *
 *    Example:
 *    fetcher("jsonapi", 'POST', 'node/public_dialog', data, token)
 *
 *
  Development Checklist:
  - save socAuthUser and method to user account

 * ref.:  https://markmichon.com/automatic-retries-with-fetch
 *        https://javascript.info/promise-error-handling#rethrowing


Fetcher upgrade
 - handle cases
        if !id fetchAnonymous
        if !token getToken fetchAuthenticated
        if tokenStatus==='current' fetchAuthenticated
        if reauthStatus === 'current' reauthToken fetchAuthenticated
        if failedReauth getToken fetchAuthenticated

*/

import {
  WEB_ROOT,
  twilioAccountSid,
  twilioBasicAuth,
} from 'configs/config-hvn';

import store from 'store';
import { userActions } from 'store/user-slice';
import { uiActions } from 'store/ui-slice';
import { authenticator } from 'lib/utils/authenticator';
import { stringHash } from 'lib/utils/utils';

/**
 * Perform fetch with structured endpoint and options.
 * Retry and recover from stale oauth token.
 *
 * @param {*} type - must be one of reqType
 * @param {*} method - a valid HTTP method
 * @param {*} url - a fullpath or a suffix to the
 * @param {*} body - optional body, text, form data, or js objectf
 * @param {*} token - optional override to the stored token
 * @param {*} headers - optional headers
 */
export const fetcher = (
  type,
  method,
  url,
  body,
  token = null,
  headers = [],
  retries = 2,
  t0 = timestamp()
) => {
  // validate the type
  if (!reqType[type]) {
    console.log(`fetcher ${t0} unrecognized type=${type}:`, reqType[type]);
    return Promise.reject(`fetcher unrecognized type: ${type}`);
  }

  const _url = getFullUrl(url, type);

  // assemble options from function args and reqType template
  const _options = {
    method,

    // no body for GET, DELETE requests
    body: ['GET', 'DELETE'].includes(method) ? null : stringify(body), //no change to blob or formData

    // apply headers from template (others to be added later)
    headers: new Headers(reqType[type].hdrs),
  };

  // apply additional headers if any
  if (headers.length > 0) {
    headers.map(header => {
      const temp = Object.entries(header);
      _options.headers.append(temp[0][0], temp[0][1]);
    });
  }

  // apply user's oauth credentials by default.
  // override if token is supplied in arguments.
  // However:
  //   Do NOT apply a token to type==='token' requests.
  //   Also, do NOT add a token if reqType[type].hdrs.Authorization exists.
  const { authObject } = getUserCreds();
  const _token =
    type !== 'token' &&
    !Boolean(reqType[type].hdrs?.Authorization) &&
    (token || authObject?.access_token);
  _token && _options.headers.append('Authorization', `Bearer ${_token}`);

  // This is an anonymous request if no token provided and not requesting a token.
  const anon = type !== 'token' && !_token;

  // request employs user's oauth token
  //const isUserAuthRequest = ['jsonapi', 'audio'].includes(type) && Boolean(authObject?.access_token);
  const isUserAuthRequest = _token && _token === authObject?.access_token;

  // create label used for diagnostic logging
  // include annotation for anonymous, user, or override-oauth credentials ('A', 'U', "T" respectively)
  const logLabel = `fetcher(${t0}${
    isUserAuthRequest ? 'U' : _token || type === 'token' ? 'T' : 'A'
  }):`;

  // try to rectify token rejection (either access or refresh token)
  const repairToken = (mode = 'refresh') => {
    // refresh the token, or re-authenticate
    const { username, password, authObject } = getUserCreds();

    // prepare FormData body for credentials request
    const fd =
      mode === 'refresh'
        ? authenticator().fdRefreshToken(authObject.refresh_token)
        : authenticator().fdAccessToken({ username, password });

    // request credentials
    return fetcher('token', 'POST', '', fd, null, [], 1)
      .catch(err => {
        console.log(`fetcher status=${err.status} -- ${mode} request failed.`);
        throw err;
      })
      .then(newAuthObject => {
        // check for valid authObject
        if (newAuthObject.token_type === 'Bearer') {
          return newAuthObject;
        }
        throw 'bad token returned.';
      });
  };

  //console.log(`${logLabel} tp1 type=${type}, method=${method}, url=${url.split('?')[0]}... , token=${Boolean(token)}`);

  // recursive fetch, recover from stale token
  //diagnosticLog(_url, _options);
  return fetch(_url, _options)
    .then(response => {
      if (response.ok) {
        return _options.method === 'DELETE' ? response.status : response.json();
      } else if (response.status === 401 && retries > 0) {
        // token unauthorized or expired
        // try to recover
        if (isUserAuthRequest) {
          // for failed authenticated user request, refresh the token
          return repairToken('refresh')
            .catch(e => {
              // refresh failed, try to re-authenticate
              return repairToken('authenticate');
            })
            .then(newAuthObject =>
              installAuthObject({ authObject: newAuthObject })
            )
            .then(
              () => (
                console.log('token repaired, retrying original request...'),
                fetcher(type, method, url, body, null, headers, retries - 1, t0)
              )
            );
        }
      }
      // throw an error if 401 response cannot be repaired
      return response.json().then(errData => {
        const detail = errData && errData.errors && errData.errors[0].detail;
        throw { status: response.status, data: detail };
      });
    })
    .catch(error => {
      //@@tbd : detect catastrophic security violations and log user out

      // do not log expired token errors
      if (error.status !== 401 || type !== 'token') {
        console.log(`${logLabel} error:`, error);
      }
      throw error;
    });
};

// supported request types
const reqType = {
  // for general JSON:API requests
  jsonapi: {
    hdrs: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    url: 'jsonapi',
  },
  // for posting audio to JSON:API
  audio: {
    hdrs: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/octet-stream',
    }, //Content-Disposition must be added
    url: 'jsonapi',
  },
  // for requesting and refreshing oAuth2 tokans
  token: {
    hdrs: { Accept: 'application/json' },
    url: 'oauth/token',
  },
  sms: {
    hdrs: {
      Accept: 'application/vnd.api+json',
      Authorization: `Basic ${twilioBasicAuth}`,
    },
    url: `https://api.twilio.com/2010-04-01/Accounts/${twilioAccountSid}/Messages.json`,
  },
};

// stringify objects, but NOT FormData or Blob
const stringify = data =>
  typeof data === 'object' &&
  !(data instanceof FormData) &&
  !(data instanceof Blob)
    ? JSON.stringify(data)
    : data;

//@@move this to authenticator.js
const socToD8Creds = socId => {
  const creds = {
    password: socId,
    username: socId,
  };
  return creds;
};

// get user's credentials
const getUserCreds = () => {
  const auth = store.getState().user.auth;
  const authObject = auth?.authObject;
  const userId = auth?.user?.id;
  const username = auth?.user?.name;
  const { password } = socToD8Creds(username);
  const creds = { userId, username, password, authObject, auth };
  return creds;
};

// save an authObject to user-slice and psession
const installAuthObject = ({ authObject }) => {
  console.log(
    'installAuthObject access_token:',
    stringHash(authObject?.access_token)
  );
  store.dispatch(userActions.updateUserAuth({ authObject }));
  store.dispatch(uiActions.updatePauth({ authObject }));
};

// flush credentials locally, skip server transactions
const logoutLocal = () => {
  store.dispatch(uiActions.updatePauth({ authObject: {}, user: {} }));
  //sigServer.unregisterSelfAsPeer("logout");
  store.dispatch(userActions.logout());
};

const timestamp = () => {
  const t = new Date();
  return 1000 * t + t.getMilliseconds();
};

// if fullpath specified, use it as is
// otherwise build base + prefix + url
// remove leading '/' from supplied url if present
const getFullUrl = (url, type) => {
  //console.log(`getFullUrl url=${url}, type=${type}`);
  const isFullpath = text => text.startsWith('http');
  const withPrefix = (prefix, text) =>
    text.startsWith(prefix) ? text : `${prefix}/${text}`;
  const stripSlash = text => (text.startsWith('/') ? text.substring(1) : text);

  return (
    (isFullpath(url) && url) ||
    (isFullpath(reqType[type].url) && reqType[type].url) ||
    `${WEB_ROOT}/${withPrefix(reqType[type].url, stripSlash(url))}`
  );
};

// prepare the fetch args for a token request (full or refresh)
const tokenArgs = (refresh = false) => {
  const { username, password, authObject } = getUserCreds();
  const formData = refresh
    ? authenticator().fdRefreshToken(authObject.refresh_token)
    : authenticator().fdAccessToken({ username, password });
  return [
    getFullUrl(reqType['token'].url, 'token'),
    {
      method: 'POST',
      body: formData,
      headers: new Headers(reqType['token'].hdrs),
    },
  ];
};

const diagnosticLog = (url, options) => {
  console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvvvv');
  console.log('fetcher url', url);
  console.log('fetcher options:', options);
  if (options.headers instanceof Headers) {
    for (var pair of options.headers.entries()) {
      console.log('header: ' + pair[0] + ': ' + pair[1]);
    }
  }
  if (options.body instanceof FormData) {
    for (var pair2 of options.body.entries()) {
      console.log('formData: ' + pair2[0] + ', ' + pair2[1]);
    }
  }
  console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
};

// extract the grant_tuype from the FormData object
const grantType = fd => (fd instanceof FormData ? fd.get('grant_type') : null);
