/**
 * multi-modal playlist: Playlist, Carousel, Clubhouse
 * Load dialogs if necessary,
 * render appropriate playlist view per state.ui.pSession.browserView
 *
 * Need to simplify configurability options and incorporate some services
 * that presently reside at lowe3r level:
 *      notify redux store when src changes
 *      re-render for call processing events
 *      handle click event on a message instance
 *      handle action event
 *      handle recorder exit
 *      render liveCall
 *      render responder (recorder)

 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import useEvent from '@react-hook/event';

import { dialogActions } from 'store/dialog-slice';
import { uiActions } from 'store/ui-slice';
import PlaylistBrowser from './components/PlaylistBrowser';
import CarouselBrowser from './components/CarouselBrowser';
//import Carousel2 from './components/Carousel2'

import {
  fetchDialogs,
  isTopic,
  nextMsg,
  findMessage,
} from 'lib/api/messageApi';
import ApplianceLayout from 'components/layouts/components/ApplianceLayout';
import { makeArray } from 'lib/utils/utils';
import MemberInfoDialog from './components/MemberInfoDialog';
import useMembers from 'hooks/useMembers';
import { t } from 'lib/translation/trans';

const PlaylistView = props => {
  const [playlist, setPlaylist] = useState([]); // list of messages being browsed
  const [playlistIndex, setPlaylistIndex] = useState(-1); // current index within playlist
  const [memberToDisplay, setMemberToDisplay] = useState(null); // when truthy, render informative dialog about member
  const dispatch = useDispatch();
  const browserView = useSelector(state => state.ui.pSession.browserView); // retrieve persistent view

  // prepare playlist filtered by visibility prop, otherwise by visibility setting
  const visibility = useSelector(state => state.dialog.visibility);

  // get the list of members
  const membersObj = useMembers();
  const { members } = membersObj;

  const singleThreadId = props.singleThreadId;

  // the dialogs array contains an ordered list of posts, each containing an array of its respective responses
  // messageMap provides key:value lookup for id:message
  const {
    dialogsFetchedFlg,
    dialogs,
    messageMap,
    openTopicId,
    selectedMessageId,
    preloadSelectedMessageId,
  } = useSelector(state => state.dialog);

  // initialize visibility setting if specified in props
  useEffect(() => {
    if (['public', 'directed'].includes(props.initialVisibility)) {
      dispatch(dialogActions.setVisibilityFilter(props.initialVisibility));
    }
  }, []);

  // configure Tips to be rendered while this component is mounted
  useEffect(() => {
    dispatch(
      uiActions.tipsControlSetKeys([
        ['responseMic', 'back', t('Respond_to_this_message')],
        ['recorderMic', 'down', t('Record_a_new_message')],
      ])
    );
    return () =>
      dispatch(uiActions.tipsControlClearKeys(['responseMic', 'recorderMic']));
  }, []);

  useEffect(() => {
    // load dialogs ONLY if they have not already been loaded
    if (!dialogsFetchedFlg) {
      fetchDialogs();
      //      dispatch(fetchDialogs())
    }
  }, [dispatch, dialogsFetchedFlg]);

  //@@experimental fetchDialogs on each change of dialogVisibilityFilter
  // reload dialogs when visibility filter changes
  //  useEffect(() => {
  //    dispatch(fetchDialogs({visibility: dialogVisibilityFilter}))
  //  }, [dialogVisibilityFilter])

  /**
   * Set up the playlist.
   *
   * single visibility (include public or directed only)
   *  - mode 1:  include all topics, or single thread if topic is open
   *  - mode 2:  include all messages
   *  - mode 3:  include all topics, also include whole thread if a topic is open
   *  - mode 4:  include only the selectedMessage thread
   */
  const mode = singleThreadId ? 4 : 2;
  useEffect(() => {
    const visibilityPublic = visibility === 'public';

    // visibility filter
    //@@ consider also screening for partition here
    const isVisible = msg => msg.isPublic === visibilityPublic;

    // diagnostic log
    const diagnosticLog = list => {
      console.log('\nPlaylist Diagnostic:');
      const log = (msg, label) =>
        console.log(
          `${label}${msg.id.substring(0, 4)} ${msg.title.substring(0, 11)} (${
            msg.screenName
          } ${
            msg.recipientScreenName ? '-->' + msg.recipientScreenName : ''
          }) ${
            msg.parent ? '[parent: ' + msg.parent.substring(0, 4) + ']' : ''
          }`
        );

      list.forEach(msg => {
        log(msg, '    ');
        Array.isArray(msg.responses) &&
          msg.responses.forEach(rsp => log(rsp, '        rsp '));
      });
    };

    // set the playlist and derive current index from selectedMessageId
    const initializePlaylist = unfilteredList => {
      const list = unfilteredList.filter(
        msg => isVisible(msg) && msg.type !== 'profile'
      );
      //diagnosticLog(list)

      setPlaylist(list);
      setPlaylistIndex(list.findIndex(item => item.id === selectedMessageId));

      // if not already set, load the preload value
      // if no preload value, leave selectedMessageId null
      if (selectedMessageId === null) {
        if (preloadSelectedMessageId === null) {
          dispatch(dialogActions.setSelectedMessage(list[0]?.id));
        } else if (list.find(item => item.id === preloadSelectedMessageId)) {
          dispatch(dialogActions.setSelectedMessage(preloadSelectedMessageId));
          dispatch(dialogActions.setPreloadSelectedMessageId(null));
        }
      }
    };

    switch (mode) {
      case 4:
        // selected topic and its responses (single thread)
        if (singleThreadId) {
          //          const message = messageMap[selectedMessageId];
          const message = dialogs.find(d => d.id === singleThreadId);
          const topic = message && isTopic(message) ? message : null;
          if (topic) {
            // include only topic and its responses
            initializePlaylist([topic, ...makeArray(topic?.responses)]);
          }
        }
        break;
      case 3:
        // include all topics and responses of the open topic (if any)
        initializePlaylist(
          dialogs.reduce((a, topic) => {
            a.push(topic, ...(openTopicId === topic.id ? topic.responses : []));
            return a;
          }, [])
        );
        break;
      case 2:
        // all topics and responses
        initializePlaylist(
          dialogs.reduce((a, topic) => {
            a.push(topic, ...makeArray(topic?.responses));
            return a;
          }, [])
        );
        break;
      case 1:
        // all topics or selected topic and its responses (single thread)
        if (openTopicId) {
          // make sure openTopicId is indeed a topic
          const message = messageMap[openTopicId];
          const topic = message && isTopic(message) ? message : null;
          if (topic) {
            // include only topic and its responses
            initializePlaylist([topic, ...makeArray(topic?.responses)]);
            return;
          }
        } else {
          initializePlaylist(dialogs);
        }
        break;
      default:
        break;
    }
  }, [openTopicId, dialogs, visibility]); //changes that trigger rerender of playlist

  // update index whenever a new message is selected
  useEffect(() => {
    setPlaylistIndex(playlist.findIndex(item => item.id === selectedMessageId));
  }, [selectedMessageId]);

  // keyboard event handler
  // select next/previous message (left/right) or scroll playlist window view (up/down)
  useEvent(document, 'keydown', event => handleKeydown(event.keyCode));
  const handleKeydown = code => {
    switch (code) {
      case 38:
        handlePreviousRequest();
        break; //up
      case 40:
        handleNextRequest();
        break; //down
      case 39:
        handleNextRequest();
        break; //right
      case 37:
        handlePreviousRequest();
        break; //left
      default:
        break;
    }
  };

  // select next/previous message in playlist
  const handlePreviousRequest = () => {
    /* dispatch */ nextMsg({ directionReverse: true });
  };
  const handleNextRequest = () => {
    /*dispatch*/ nextMsg();
  };

  // select the specified message. if it is a topic toggle its open status
  const handleMessageSelect = id => {
    dispatch(dialogActions.setSelectedMessage(id));
    dispatch(uiActions.respondTipPing()); // let the respondTip mechanise keep track of message indexing
  };

  // user has requested information about a member
  const onMemberInfoRequest = id => {
    setMemberToDisplay(makeArray(members).find(m => m.id === id));
  };

  const handleEnded = () => {
    // used for annotating TopicCards that have played to completion
    dispatch(dialogActions.noteMessagePlayedThisSession(selectedMessageId));
  };

  //TODO2: remove unwanted items from browse display
  const playlistFilter = () => playlist.filter(isAudio => isAudio.fileUrl);

  //PlaylistBrowser wraps itself in ApplianceLayout
  const browserLayout = () => {
    switch (browserView) {
      case 'Carousel':
        //        return <Carousel2
        //          playlist={playlist}
        //          onPrevious={handlePreviousRequest}
        //          onNext={handleNextRequest}
        //          onEnded={handleEnded}
        //        /> ;
        return (
          <ApplianceLayout
            banner={{ title: 'Carousel PlaylistView' }}
            body={
              <CarouselBrowser
                playlist={playlist}
                onPrevious={handlePreviousRequest}
                onNext={handleNextRequest}
                onEnded={handleEnded}
              />
            }
            actions={null}
          />
        );
      case 'Playlist':
      default:
        return (
          <PlaylistBrowser
            playlist={playlist}
            playlistIndex={playlistIndex}
            onMessageSelect={handleMessageSelect}
            onMemberInfoRequest={onMemberInfoRequest}
            onPrevious={
              /* playlistIndex === 0 ? null : */ handlePreviousRequest
            }
            onNext={
              /* ( playlistIndex === playlist?.length - 1) ? null : */ handleNextRequest
            }
            onEnded={handleEnded}
            classes={props.classes}
            initialView={props.initialView}
            zen={props.zen}
            singleThread={Boolean(singleThreadId)}
          />
        );
    }
  };

  return (
    <>
      {dialogsFetchedFlg ? browserLayout() : null}
      <MemberInfoDialog
        open={Boolean(memberToDisplay)}
        member={memberToDisplay}
        onClose={() => setMemberToDisplay(null)}
      ></MemberInfoDialog>
    </>
  );
};

export default PlaylistView;

PlaylistView.propTypes = {
  // pass to children:
  classes: PropTypes.object, //classes to be passed to children
  initialVisibility: PropTypes.oneOf(['public', 'directed']),
  initialView: PropTypes.oneOf(['new', 'dm', 'public']),
  zen: PropTypes.bool,
  singleThreadId: PropTypes.string,
};

PlaylistView.defaultProps = {
  zen: false,
  singleThreadId: null,
};
