/**
 * Render a <Radio /> in standardized format
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { makeArray } from 'lib/utils/utils';

const useStyles = makeStyles(theme => ({}));

export default function RadioStd(props) {
  const { name, items, value, handleChange, classes: classesOverride } = props;
  const classes = { ...useStyles(), ...classesOverride };
  return value === undefined ? null : (
    <FormControl classes={{ root: classes.formStyle }} component="fieldset">
      <RadioGroup
        classes={{ root: classes.groupStyle }}
        row
        aria-label="position"
        name={name}
        value={value}
        onChange={handleChange}
      >
        {makeArray(items).map((item, ix) => (
          <FormControlLabel
            style={ix === 0 ? { marginLeft: 0 } : null}
            key={`${name}-${item.key}`}
            value={item.key}
            control={
              <Radio color="primary" disabled={!Boolean(handleChange)} />
            }
            label={item.data}
            labelPlacement="start"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioStd.propTypes = {
  name: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      data: PropTypes.string, // text to display
    })
  ),
  value: PropTypes.string,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
};
