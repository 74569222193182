/**
 * Render a container with border and labels
 * Labels overlap the border in the  corners
 *
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapperOuter: {
    paddingTop: '10px',
    width: '100%',
  },
  wrapperInner: {
    position: 'relative',
    border: '1px rgba(255, 255, 255, 0.23) solid',
    borderRadius: '4px',
    margin: '0', //previously '0 1rem', changed for use by Recorder
    padding: '1rem',
  },
  labelTop: {
    position: 'absolute',
    left: '10px',
    top: '-8px',
    color: 'rgba(255, 255, 255, 0.7)',
    lineHeight: 1,

    paddingLeft: '5px',
    paddingRight: '5px',

    // to add border to labelTop:
    //with border
    //paddingTop: '5px',
    //paddingBottom: '5px',
    //border: "1px rgba(255, 255, 255, 0.23) solid",
    //borderRadius: "4px",
  },
  labelBottom: {
    position: 'absolute',
    left: '10px',
    bottom: '-6px',
    color: 'rgba(255, 255, 255, 0.7)',
    lineHeight: 1,

    paddingLeft: '5px',
    paddingRight: '5px',
  },

  labelTopRight: {
    position: 'absolute',
    right: '10px',
    top: '-8px',
    color: 'rgba(255, 255, 255, 0.7)',
    lineHeight: 1,

    paddingLeft: '5px',
    paddingRight: '5px',
  },
  labelBottomRight: {
    position: 'absolute',
    right: '10px',
    bottom: '-15px', //TODO2: make label positions programmable
    color: 'rgba(255, 255, 255, 0.7)',
    lineHeight: 1,

    paddingLeft: '5px',
    paddingRight: '5px',
  },
  blockContent: {},
}));

const BorderedBlock = props => {
  const [backgroundColor, setBackgroundColor] = useState('rgba(0, 0, 0, 0)');
  const wrapperRef = React.createRef();

  const { labelTop, labelBottom, labelTopRight, labelBottomRight, children } =
    props;
  const classes = { ...useStyles(), ...props.classes };

  // find background color of wrapper by searching through parents
  // the background must be explicitly applied to the label in order
  // for it to overlay the container's border
  useEffect(() => {
    let el = wrapperRef.current;
    let color = null;

    // kick out if an el has non-zero backgroundColor
    do {
      color = getComputedStyle(el).getPropertyValue('background-color');
      el = el.parentElement;
    } while (color === 'rgba(0, 0, 0, 0)' && el.parentElement);

    setBackgroundColor(color);
    /*

                 if (el) {
                    for ( i=0; i < 10; i++) {
                        const backgroundColor = getComputedStyle(el).getPropertyValue("background-color")
                        console.log(`${i} type=${el.nodeName}- ${backgroundColor}:`, el)
                        if (el instanceof HTMLBodyElement) {return}
                        el = el.parentElement
                    }
                }
        */
  }, [wrapperRef]);

  return (
    <>
      <div className={classes.wrapperOuter}>
        <div
          ref={wrapperRef}
          className={classes.wrapperInner}
          style={{
            borderColor: props.borderColor ? props.borderColor : undefined,
          }}
        >
          {labelTop && (
            <div
              className={classes.labelTop}
              style={{
                backgroundColor: backgroundColor,
                color: props.borderColor ? props.borderColor : undefined,
                borderRadius: '4px',
              }}
            >
              {labelTop}
            </div>
          )}
          {labelBottom && (
            <div
              className={classes.labelBottom}
              style={{ backgroundColor: backgroundColor }}
            >
              {labelBottom}
            </div>
          )}
          {labelTopRight && (
            <div
              className={classes.labelTopRight}
              style={{ backgroundColor: backgroundColor }}
            >
              {labelTopRight}
            </div>
          )}
          {labelBottomRight && (
            <div
              className={classes.labelBottomRight}
              style={{ backgroundColor: backgroundColor }}
            >
              {labelBottomRight}
            </div>
          )}
          <div className={classes.blockContent}>{children}</div>
        </div>
      </div>
    </>
  );
};

BorderedBlock.propTypes = {
  labelTop: PropTypes.string,
  labelBottom: PropTypes.string,
  labelTopRight: PropTypes.string,
  labelBottomRight: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  // class/style overrides should be generalized
  borderColor: PropTypes.string, // override top label color
};

export default BorderedBlock;
