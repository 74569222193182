/**
 * seekMicApproval
 * attempts to obtain microphone permission
 */

/**
 *
 * @returns {stream, code, name}
 * codes:
 *  0- approved (obtained stream)
 *  1- NotAllowedError (user declined mic. request)
 *  2- NotAllowedErrorQuick (user previously/permanently declined mic. request)
 *  3- NotAllowedErrorDismissed (user closed permission dialog without disposition)
 *  4- NotFoundError (no enabled microphone found)
 */

// try to use newer navigator.mediaDevices promise, but fallback if necessary
const getUserMedia =
  navigator.mediaDevices && navigator?.mediaDevices?.getUserMedia
    ? function (constraints) {
        return navigator?.mediaDevices?.getUserMedia(constraints);
      }
    : function (constraints) {
        const oldGetUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        if (!oldGetUserMedia) {
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser')
          );
        }
        return new Promise(function (resolve, reject) {
          oldGetUserMedia.call(navigator, constraints, resolve, reject);
        });
      };

export const seekMicApproval = () => {
  const promise = new Promise(resolve => {
    const t0 = new Date();
    getUserMedia({ video: false, audio: true })
      .then(stream => resolve({ stream, code: 0, name: 'approved' }))
      .catch(err => {
        const { code, name, message } = err;
        console.log('seekMicApproval err:', err);
        const delay = new Date() - t0;
        if (message.indexOf('dismissed') > -1) {
          // the user dismissed the approval request
          resolve({ stream: null, code: 3, name: 'NotAllowedErrorDismissed' });
        } else if (delay < 200) {
          // decline came back quickly, most likely a permanent block existed
          resolve({ stream: null, code: 2, name: 'NotAllowedErrorQuick' });
        } else if (['NotReadableError', 'NotFoundError'].includes(name)) {
          resolve({ stream: null, code: 4, name });
        }
        resolve({ stream: null, code: 1, name });
      });
  });
  return promise;
};
