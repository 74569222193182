/**
 * Visualizer component provides a dynamic display
 * representing audio playback sound.
 * However it is mounted by default at a fixed,
 * non-responsive width.
 *
 * NOTE: appears to induce CORS exception on Safari browsers!
 *
 * Responsive behavior is achieved by placing it in a
 * parent container that is truncated horizontally
 * as needed.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AudioSpectrum from 'react-audio-spectrum';
import { profiles } from './visualizerProfiles';

const settings = {
  size: {
    std: { height: 100, width: 300 },
    xs: { height: 100, width: 200 },
  },

  defaultProfileIx: 4, // visualization profile parameter sets
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '10px',
  },
  visualizerContainer: {
    position: 'relative', //enable absolute positioning of children
    overflow: 'hidden',
    border: '1px grey solid',
    width: settings.size.std.width,
    height: settings.size.std.height,
    // Truncate width for xs
    [theme.breakpoints.down('xs')]: {
      width: settings.size.xs.width,
    },

    // hide visualizer when viewport is short (vertically)
    // Note that use of <Hidden /> would interrupt in-process play
    [`@media (max-height: ${theme.breakpoints.custom.vxsThreshold}px)`]: {
      width: 0,
      height: 0,
    },
    //future      transform: 'scale(1)',
  },
}));

const Visualizer = props => {
  const { paused, pausedContent, noWrap, src, propClasses, propOptions } =
    props;
  const classes = { ...useStyles(), ...propClasses };

  //allocate new Audio() if not available in props
  //const audioEl = props.audioEl ? props.audioEl : new Audio()
  //const [audioEl, setAudioEl] = useState(props.audioEl ? props.audioEl : new Audio())
  const audioEl = props?.audioEl;
  const [profileIx, setProfileIx] = useState(settings.defaultProfileIx);

  // when src changes, assign a visualization profile
  useEffect(() => {
    // random pick
    const pick = Math.floor(Math.random() * profiles.length);
    setProfileIx(pick);
  }, [src]);

  const standardConfig = {
    height: settings.size.std.height,
    width: settings.size.std.width, //conditionally truncated by visualizationContainer
    id: 'visualizer-canvas',
    audioEle: audioEl,
  };

  const whenPaused = () =>
    Boolean(pausedContent) ? (
      <Box
        style={{
          position: 'absolute',
          top: `"${settings.height * 0.4}px"`,
          visibility: paused ? 'visible' : 'hidden',
        }}
      >
        {' '}
        {pausedContent}{' '}
      </Box>
    ) : (
      <Typography
        variant="h4"
        style={{
          position: 'absolute',
          top: `"${settings.height * 0.4}px"`,
          visibility: paused ? 'visible' : 'hidden',
        }}
      >
        Paused
      </Typography>
    );

  // Use <Grid /> to center content.
  // Indicate paused audio with overlay <Typography />
  // Place <AudioSpectrum /> in responsive, truncating <div />
  const content = () => (
    <Grid container justify="center">
      <Grid
        container
        justify="center"
        alignItems="center"
        classes={{ root: classes.visualizerContainer }}
      >
        {paused && whenPaused()}
        {audioEl?.src && (
          <AudioSpectrum
            {...{ ...standardConfig, ...propOptions, ...profiles[profileIx] }}
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    // Place content within <Paper /> unless noWrap prop is given
    noWrap ? (
      content()
    ) : (
      <Paper classes={{ root: classes.paper }}>{content(classes)} </Paper>
    )
  );
};

Visualizer.propTypes = {
  audioEl: PropTypes.instanceOf(Audio), // allocated locally if not provided
  src: PropTypes.string, // used to pick visualizer profile, not needed by AudioSpectrum
  noWrap: PropTypes.bool, // inhibit use of <Paper /> wrapper
  paused: PropTypes.bool,
  pausedContent: PropTypes.node, //content to be presented when audio is paused
  propClasses: PropTypes.object,
  propOptions: PropTypes.object,
};

Visualizer.defaultProps = {
  propClasses: {},
  propOptions: {},
};
export default Visualizer;
