/**
 * Utilities to support community and partition management
 *
 */

/*
  In order to add community field:
  add field to the following:           invokers:
    getCommunitiesUrl                   getCommunities
    parseCommunities                    getCommunities
*/

import store from 'store';
import {
  assemble,
  getCommunitiesUrl,
  getCommunitiesWithMediaUrl,
} from 'lib/api/jsonApi';
import { makeArray, jsonParse } from 'lib/utils/utils';
import { fetcher } from 'lib/utils/fetcher';
import { uiActions } from 'store/ui-slice';
import { userActions } from 'store/user-slice';
import { dialogActions } from 'store/dialog-slice';
import { getHvnUsers } from 'lib/api/userApi';
import {
  getUserMessagesInPartitionUrl,
  getPublicResponserMessagesInPartitionUrl,
} from 'lib/api/jsonApi';
import { patchHvnUser } from 'lib/api/userApi';
import { parseMessages, killMessages } from 'lib/api/messageApi';
import { t } from 'lib/translation/trans';

// report the currently active partition
export const currentPartition = () =>
  store.getState().ui.pSession.community?.partition;

// report the currently active community title
export const currentCommunity = () => store.getState().ui.pSession.community;

// is the active community a gated one?
export const isGatedCommunity = () =>
  store.getState().ui.pSession.community?.gated;

// is the user an authenticated community member?
// if no partition specified, check the current community?
export const isMember = partition => {
  const memberships = store.getState().user.auth?.user?.memberships;
  return memberships && memberships.includes(partition || currentPartition());
};

// filter messages outside of current partition
const partitionFilter = dialogs => {
  // exclude responses outside of current partition
  const filterResponses = responses =>
    Array.isArray(responses)
      ? responses.filter(r => r.partition === currentPartition())
      : [];

  //check topics
  const topics = dialogs.filter(
    topic => topic.partition === currentPartition()
  );
  // check responses
  return topics.map(topic => ({
    ...topic,
    responses: filterResponses(topic?.responses),
  }));
};

const parseCommunities = nodes => {
  // correlate data with included
  const assembled = assemble(nodes, [
    'field_voice_intro',
    'field_community_logo',
  ]);

  // flatten array field_community_welcome_sections
  assembled.forEach(node => {
    if (node.field_community_welcome_sections) {
      node.sections = makeArray(node.field_community_welcome_sections).map(
        sect => sect.processed
      );
    }
  });

  const out = assembled.map(node => ({
    id: node?.id,
    title: node?.title,
    slogan: node?.field_slogan,
    weight: node?.field_weight,
    partition: node?.field_partition,
    group: node?.field_community_group,
    body: node?.bodyProcessed,
    sections: node?.sections,
    infoUrl: node?.uriUrl,
    logoUrl: node?.attributesUriUrl,
    gated: node?.field_gated,
    params: jsonParse(node?.field_json_params),
  }));
  return out;
};

// get community data, use cached if available and if !withMedia
// get community associated with partition if provided, otherwise get all
// always resolve to an array
export const getCommunities = (partition = null, withMedia = false) => {
  const { communities } = store.getState().ui;
  //console.log(`getCommunities partition=${partition}, withMedia=${withMedia}, cache available=${communities.length}`);
  if (communities.length > 0 && !withMedia) {
    console.log('getCommunities using cache.');
    return Promise.resolve(
      partition
        ? [communities.find(c => c.partition === partition)]
        : communities
    );
  } else {
    //need to fetch from server
    const communityUrl = withMedia
      ? getCommunitiesWithMediaUrl
      : getCommunitiesUrl;
    const url = partition
      ? `${communityUrl()}&filter[field_partition]=${partition}`
      : communityUrl();
    return fetcher('jsonapi', 'GET', url).then(data => {
      const communities = makeArray(parseCommunities(data));
      // save to cache unless fetching withMedia
      withMedia || store.dispatch(uiActions.setCommunities(communities));
      return communities;
    });
  }
};

// resolve a community object
export const getCommunity = (partition, withMedia = false) =>
  getCommunities(partition, withMedia).then(communities =>
    communities.length ? communities[0] : null
  );

// switch to a new community context
export const switchCommunity = newCommunity => {
  if (newCommunity?.partition !== currentPartition()) {
    // clear dialogs and members lists
    store.dispatch(uiActions.setCommunity(newCommunity));
    store.dispatch(userActions.setMembers([]));
    store.dispatch(dialogActions.clear());
  }
};

// get users who are members of the current community
export const getCommunityMembers = () => {
  return getHvnUsers([
    {
      tag: 'partition',
      path: 'field_memberships',
      val: currentPartition(),
      op: 'CONTAINS',
    },
  ]);
  /*
    `?filter[partition][condition][path]=field_memberships
    &filter[partition][condition][operator]=CONTAINS
    &filter[partition][condition][value]=${partition}
    `
    */
};

/*
    community-specific persistent parameters

    Notes:
    affected parameters: screenName, Profile recording, attributes
    solicit from new member
    allow member to update items (update of screenName not presently supported)
    solicit persistent filter parameters from user while viewing member list
    apply filter parameters while viewing member list
    data structures
        community.params -
            holds specification of registration workflow including attributes
            including filterSection describing available filter parameters
        user.params - holds user-applied parametere, including community-specific params
            user.params (historical) - holds a parameter object, not providing community-specific support
            user.params.partitionParams - holds an array of community-specific objects
                user.params.partitionParams.partition
                user.params.partitionParams.params

*/

// parameters were initially stored in member.params (not partition specific)
// later, each partition stored params in member.params.partitionParams[...partitionObjects]
//
//
//  data structure with user object, local designation
//      user.params = {                 uparams        (user object params)
//          partitionParams: [          pparams        (partitions params)
//              {                       paranmsObj[0], thisParamsObj     (specific partiton params, specific for current partition)
//                  partition,
//                  params:{}           params
//              },
//              {                   \
//                  partition,      |
//                  params:{}       >   oparamsArr     (array of paramsObj for other partitions)
//              },                  |
//              ...                 /
//          ],
//          ...,                        nonPartitionParams    (non partition-specific params)
//      }
//
export const getMemberParams = (partition, member) => {
  // initialize params key field if necessary
  const initializedMember = {
    ...member,
    params: member?.params || { partitionParams: [{ partition, params: {} }] },
  };
  const {
    params: { partitionParams, ...nonPartitionParams },
  } = initializedMember;
  const { params } = {
    params: null,
    ...(Array.isArray(partitionParams) &&
      partitionParams.find(po => po.partition === partition)),
  };
  return params;
};

// place delimiter between attributes
export const memberAnnotation = (partition, member) => {
  // validate member and partition
  if (
    !partition ||
    !member ||
    !Array.isArray(member.memberships) ||
    !member.memberships.includes(partition)
  ) {
    return '';
  }
  const params = getMemberParams(partition, member);
  if (typeof params !== 'object') {
    return '';
  }

  // compose string of delimited param values
  const annotation = Object.entries(params).reduce((acc, [key, val]) => {
    if (typeof val === 'string' && val.length > 0) {
      return `${acc} -- ${t(val)}`;
    } else {
      // no height, make no change
      return acc;
    }
  }, '');
  return annotation;
};

export const updatedAggregateUserParams = (
  partition,
  member,
  newThisPartitionParams
) => {
  // initialize params key field if necessary
  const initializedMember = {
    ...member,
    params: member?.params || { partitionParams: [{ partition, params: {} }] },
  };

  const {
    params: { partitionParams, ...nonPartitionParams },
    ...otherMemberKeys
  } = initializedMember;
  const foreignPartitionsArr = makeArray(
    Array.isArray(partitionParams) &&
      partitionParams.filter(p => p.partition !== partition)
  );
  const updatedMember = {
    ...otherMemberKeys,
    params: {
      ...nonPartitionParams,
      partitionParams: [
        ...foreignPartitionsArr,
        {
          partition,
          params: {
            ...getMemberParams(partition, member),
            ...newThisPartitionParams,
          },
        },
      ],
    },
  };
  return updatedMember.params;
};

// prepare a list of groups represented in the communitieis array
export const communityGroups = communities =>
  communities.reduce(
    (acc, c) =>
      acc.find(g => g?.title === c.group)
        ? acc
        : [...acc, { title: c.group, list: [] }].sort((a, b) =>
            a.title < b.title ? -1 : 1
          ),
    []
  );

// prepare list of communities organized into groups
export const categorizeCommunities = (
  communities,
  currentPartition,
  memberships
) => {
  // scan communities, de-dup and order groups
  const groups = communityGroups(communities);

  communities.forEach(c => {
    const { group, partition, title } = c;
    const g = groups.find(g => g.title === c.group);
    g.list.push({
      group,
      partition,
      title,
      member: makeArray(memberships).includes(c.partition),
      current: c.partition === currentPartition,
    });
  });

  return groups;
};

// annotate communities with membership and current selection flags
// sort the list on title
export const annotateCommunities = (
  communities,
  currentPartition,
  memberships
) =>
  communities
    .map(c => ({
      ...c,
      isMember: memberships && memberships.includes(c.partition),
      current: c.partition === currentPartition,
    }))
    .sort((a, b) => (a.title < b.title ? -1 : 1));

// return an array of arrays, categorizing communities by the specified algorithm
// discriminatorFn returns an integer index of the bucket into which a community is to be placed
export const buckets = (communities, discriminatorFn) => {
  const buckets = [];
  if (Array.isArray(communities) && typeof (discriminatorFn === 'function')) {
    communities.forEach(c => {
      const ix = discriminatorFn(c);
      if (Array.isArray(buckets[ix])) {
        //category is already represented
        buckets[ix].push(c);
      } else {
        //first one of this category
        buckets[ix] = [c];
      }
    });
  }
  return buckets;
};

/* buckets test
        const test = c => {
            const rslt = c.isMember ? 1 : 0;
            return rslt
        }
        const b = buckets(annotateCommunities(communities, currentPartition(), user?.memberships) , test)
*/

// remove the user from the specified community
// (reverse of processNewUser )
export const removeUserFromCommunity = (user, partitionToQuit) => {
  // patchUser: expunge user's memberships list and partition params
  const partitionParams = user.params.partitionParams.filter(
    pp => pp.partition !== partitionToQuit
  );
  const params = { ...user.params, partitionParams };
  const field_memberships = user.memberships.filter(m => m !== partitionToQuit);

  // remove all of user's messages in partition, and responses thereto
  return Promise.all([
    fetcher(
      'jsonapi',
      'GET',
      getUserMessagesInPartitionUrl({
        userId: user.id,
        partition: partitionToQuit,
      })
    ).then(data => parseMessages(data)),
    fetcher(
      'jsonapi',
      'GET',
      getPublicResponserMessagesInPartitionUrl({ partition: partitionToQuit })
    ).then(data => parseMessages(data)),
    patchHvnUser(user, { field_memberships, params }),
  ])
    .then(([userMessages, responseMessages, patchRslt]) => [
      userMessages.map(m => m.id),
      responseMessages.map(m => m.id),
    ])
    .then(([userIds, responseIds]) => [
      ...userIds,
      ...responseIds.filter(m => userIds.includes(m.parent)).map(m => m.id),
    ])
    .then(deathlist => killMessages(deathlist))
    .then(rslt => {
      // remove user from members list (local and on peers)
      const members = store.getState().user.members;
      store.dispatch(
        userActions.setMembers(members.filter(m => m.id !== user.id))
      );
      return { status: 'success' };
    });
};
