/**
 * Voice Recorder state definitions
 * and state-dependent action button behavior definition
 */

import {
  Mic as MicIcon,
  Stop as StopIcon,
  PlayCircleFilled as PlayIcon,
  PauseCircleFilled as PauseIcon,
  Cancel as CancelIcon,
  //    DoneOutline as OKIcon,
  //    Done as OKIcon,
  CloudUpload as SaveIcon,
  Send as SendIcon,
  GetApp as DownloadIcon,
  ThumbUpAlt as MicApprovedIcon,
} from '@material-ui/icons';
import { CheckBold as OKIcon } from 'mdi-material-ui';

// Enumeration of VoiceRecorder processing states
export const vrs = {
  APPROVAL_NEEDED: 'ApprovalNeeded',
  APPROVAL_PENDING: 'ApprovalPending',
  READY: 'Ready', //ready to record
  READY_NOCANCEL: 'ReadyNoCancel', //ready to record (disable 'cancel' button)
  COMMENCING: 'Commencing',
  RECORDING: 'Recording',
  FINISHING: 'Finishing',
  RECORDED: 'Recorded',
  PROBLEM: 'Problem',
  PLAYING: 'Playing',
  DOWNLOADING: 'Downloading',
  RE_RECORD: 'ReRecord', //flush recording and prep to create a new one

  // to support MessageRecorder
  HIDE_ACTIONS: 'HideActions', //recording operations finished, post-processing and saving

  //Unused by VoiceRecorder
  APPROVAL_WITHHELD: 'ApprovalWitheld',
  SAVING: 'Saving',
  SUCCESS: 'Success',
};

// state-dependent button visibility and enablement
export const recStates = [
  {
    label: vrs.APPROVAL_NEEDED,
    visible: ['Enable', 'Review', 'Download', 'Cancel'],
    enabled: ['Enable', 'Cancel'],
  },
  {
    label: vrs.APPROVAL_PENDING,
    visible: ['Enable', 'Review', 'Download', 'Cancel'],
    enabled: ['Cancel'],
  },
  {
    label: vrs.READY,
    visible: ['Record', 'Review', 'Download', 'Cancel'],
    enabled: ['Record', 'Cancel'],
  },
  {
    label: vrs.READY_NOCANCEL,
    visible: ['Record', 'Review', 'Download', 'Cancel'],
    enabled: ['Record'],
  }, //button not enabled
  {
    label: vrs.COMMENCING,
    visible: ['Record', 'Review', 'Download', 'Cancel'],
    enabled: ['Cancel'],
  },
  {
    label: vrs.RECORDING,
    visible: ['Stop', 'Review', 'Download', 'Cancel'],
    enabled: ['Stop', 'Cancel'],
  },
  {
    label: vrs.FINISHING,
    visible: ['Stop', 'Review', 'Download', 'Cancel'],
    enabled: ['Cancel'],
  },
  {
    label: vrs.RECORDED,
    visible: ['Record', 'Review', 'Download', 'Cancel'],
    enabled: ['Record', 'Review', 'Download', 'Cancel'],
  },
  {
    label: vrs.RE_RECORD,
    visible: ['Record', 'Review', 'Download', 'Cancel'],
    enabled: [],
  },
  {
    label: vrs.PLAYING,
    visible: ['Record', 'Pause', 'Download', 'Cancel'],
    enabled: ['Pause', 'Cancel'],
  },
  {
    label: vrs.DOWNLOADING,
    visible: ['Record', 'Pause', 'Download', 'Cancel'],
    enabled: ['Cancel'],
  },
  // to support MessageRecorder
  { label: vrs.HIDE_ACTIONS, visible: [''], enabled: [''] },
  // unused by VoiceRecorder
  {
    label: vrs.APPROVAL_WITHHELD,
    visible: ['Enable', 'Review', 'Download', 'Cancel'],
    enabled: ['Cancel'],
  },
];

// supported button labels and icons
// defined as key:value pairs
export const recIconMap = {
  Enable: <MicApprovedIcon />,
  Record: <MicIcon />,
  Stop: <StopIcon />,
  Review: <PlayIcon />,
  Pause: <PauseIcon />,
  OK: <OKIcon applyEnabledStyle={true} />,
  Download: <DownloadIcon />,
  Cancel: <CancelIcon />,
  Save: <SaveIcon />,
  Send: <SendIcon />,
};
