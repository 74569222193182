/**
 * InboxAlert
 * Provide a visual notification of priority Inbox activity
 *
 * Opens upon arrival of inbound message, and upon application launch if inbox is not empty
 *
 * Disable opening if:
 *   -- Inbox already being viewed
 *   -- Community changed with messages in inbox
 *
 * Delay opening for:
 *  -- recording in progress                                        useSelector(state => state.ui.recorder.busy)
 *  -- livecall in progress                                         callProc.callActivitiesInProgress()
 *
 * Close automatically:
 *  -- if Inbox is empty
 */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, IconButton, Button, Grid } from '@material-ui/core';

import InboxView from 'containers/InboxView';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FullScreenDialog from 'components/Modals/FullScreenDialog';
import { uiActions } from 'store/ui-slice';
import { t } from 'lib/translation/trans';
import SnackbarAlert from 'components/utils/SnackbarAlert';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    margin: '0px 100px',
    border: '5px solid ',
    borderColor: theme.palette.primary.dark,
    borderRadius: 10,
  },
}));

const InboxAlert = ({ disabled, onStatusChange }) => {
  const [mode, setMode] = useState('drawer');
  const { inbox } = useSelector(state => state.ui);
  const { inboundIdQueue, messageMap } = useSelector(state => state.dialog);
  const callProgress = useSelector(state => state.webRTC.call.progress);
  const recorderBusy = useSelector(state => state.ui.recorder.busy);

  const classes = useStyles();
  const dispatch = useDispatch();
  const liveCallBusy = !['noService', 'ready'].includes(callProgress);
  const deferAlerts = liveCallBusy || recorderBusy;
  const empty = inboundIdQueue.length === 0;
  const message = messageMap[inbox.mostRecentId];

  const openDrawer = inbox.alert && mode === 'drawer';
  const openFullScreen = inbox.alert && mode === 'full';
  const openSnackbar = inbox.alert && mode === 'snackbar';

  // detect onset of alert
  useEffect(() => {
    const open = inbox.alert && !empty && !disabled && !liveCallBusy;
    setMode(open ? 'snackbar' : null);
    onStatusChange(open); // lift-up status change
  }, [inbox.alert, empty, disabled, liveCallBusy]);

  // close when inboundIdQueue becomes empty
  useEffect(() => {
    if (empty) {
      dispatch(uiActions.setInboxAlert(false));
    }
  }, [empty]);

  // defer alerts when priority activities are underway
  useEffect(() => {
    deferAlerts
      ? dispatch(uiActions.deferInboxAlerts())
      : dispatch(uiActions.resumeInboxAlerts(inboundIdQueue));
  }, [deferAlerts]);

  const handleFullOpen = () => {
    setMode('full');
  };

  const handleClose = useCallback(
    () => dispatch(uiActions.setInboxAlert(false)),
    []
  );

  const renderControls = (
    <>
      <IconButton onClick={handleClose}>
        <ExpandMoreIcon />
      </IconButton>
      <IconButton onClick={handleFullOpen}>
        <ExpandLessIcon />
      </IconButton>
    </>
  );

  const alertContent = (
    <Button
      style={{ width: 200 }}
      variant="outlined"
      onClick={handleFullOpen}
      children={
        <div style={{ width: '100%', textAlign: 'left' }}>
          <div>
            <span style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {`${t('From')}:`}{' '}
            </span>{' '}
            <span style={{ color: '#0096ef', fontStyle: 'italic' }}>
              {`${message?.screenName}`}{' '}
            </span>
          </div>
          <div> {`"${message?.title}"`} </div>
        </div>
      }
    />
  );

  // useCallback function to prevent re-rendering transition in SnackbarAlert
  const snackbarContent = useCallback(
    () => (openSnackbar ? alertContent : null),
    [openSnackbar, message]
  );

  return (
    <>
      <Drawer
        open={openDrawer}
        PaperProps={{ classes: { root: classes.drawerPaper } }}
        variant="persistent"
        anchor="bottom"
      >
        <div style={{ margin: '0 10px 10px 10px' }}>
          {openDrawer && (
            <InboxView limit={1} renderControls={renderControls} />
          )}
        </div>
      </Drawer>

      <FullScreenDialog
        open={openFullScreen}
        title={t('Your_Messages')}
        onClose={handleClose}
        content={<InboxView autoPlay={true} noRedirect />}
        zen={true}
      />
      {/* eliminated transition because of re-rendering anomalies  */}
      <SnackbarAlert content={snackbarContent()} onClose={handleClose} />
    </>
  );
};

InboxAlert.propTypes = {
  disabled: PropTypes.bool,
  onStatusChange: PropTypes.func,
};

InboxAlert.defaultProps = {
  disabled: false,
  onStatusChange: () => {},
};

export default InboxAlert;
