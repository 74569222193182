/**
 * Render a radio or checklist form for selecting filter criteria.
 * Obtain initial value from redux store, and save results to redux store and localstorage.
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Button, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FilterMenu as FilterMenuIcon } from 'mdi-material-ui';

// Original design here: https://github.com/siriwatknp/mui-treasury/issues/777
const minWidth = 140;

const useStyles = makeStyles(theme => ({
  button: {
    //textTransform: 'capitalize',
    'paddingTop': 8,
    'paddingBottom': 8,
    '& > span': {
      fontSize: '0.9em',
    },
  },
  icon: {
    width: '15px',
    height: '15px',
    marginRight: '5px',
  },
}));

const FilterMenuButton = ({ field, onClick }) => {
  const classes = useStyles();

  const icon = React.cloneElement(field?.icon || <FilterMenuIcon />, {
    classes: { root: classes.icon },
  });

  return (
    <Button
      className={classes.button}
      onClick={e => {
        onClick(e);
      }}
    >
      {icon}
      <Typography variant="caption">{field?.title}</Typography>
    </Button>
  );
};

FilterMenuButton.propTypes = {
  field: PropTypes.object,
  onClick: PropTypes.func,
};
FilterMenuButton.defaultProps = {};

export default FilterMenuButton;
