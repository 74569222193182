/**
 * this version attempts recursion to perfect bad or missing token.
 *  when attempting to recover from expired refresh token, transactions are successful but processing here gets tangled up.
 *
 *
 * messageFilters
 * extract items of interest from the dialogs array
 *
 */

/**
 *
 * Blacklist Filtering
 */

// determine if a message is blacklisted
const isBlacklisted = msg => Boolean(msg.blacklisted);

// eliminate blacklisted messages from input array
const excludeBlacklist = (messages = []) =>
  Array.isArray(messages) ? messages.filter(msg => !isBlacklisted(msg)) : [];

// remove blacklisted posts and responses from dialog array
export const dialogsExcludeBlacklist = (dialogs = []) =>
  excludeBlacklist(dialogs).map(post => ({
    ...post,
    responses: excludeBlacklist(post.responses),
  }));

/**
 * Defective Audio Filtering
 *
 */

// determine if a message has good audio file
export const isGoodAudioFile = msg =>
  msg.fileId && msg.fileUrl && msg.fileUrl.toLowerCase().includes('.mp3');

// eliminate blacklisted messages from input array
const excludeBadAudioFile = (messages = []) =>
  Array.isArray(messages) ? messages.filter(msg => isGoodAudioFile(msg)) : [];

// remove blacklisted posts and responses from dialog array
export const dialogsExcludeBadAudioFile = (dialogs = []) =>
  excludeBadAudioFile(dialogs).map(post => ({
    ...post,
    responses: excludeBadAudioFile(post.responses),
  }));

// Remove invite messages
export const dialogsExcludeInvites = (messages = []) =>
  Array.isArray(messages) ? messages.filter(msg => msg.type !== 'invite') : [];
