/**
 * useInboxConsumer custom hook
 *
 * Applies 'consumed' status to the specified message.
 * Updates the hvn server, the local dialogs list, and the inboundIdQueue
 * Optionally, defers expunging from inbountIdQueue until component dismounts.
 *
 */

import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dialogActions } from 'store/dialog-slice';
import { patchVoiceMessage, isDirectedType } from 'lib/api/messageApi';

const useInboxConsumer = () => {
  const [consumedThisSession, setConsumedThisSession] = useState([]);
  const { auth } = useSelector(state => state.user);
  const { dialogsFetchedFlg } = useSelector(state => state.dialog);
  const dispatch = useDispatch();
  const userId = auth?.user?.id;

  // create a ref so that state can be read from within a callback
  const consumedThisSessionRef = useRef();
  consumedThisSessionRef.current = consumedThisSession;

  useEffect(() => {
    // upon dismount of component
    return () => {
      // remove consumed messages from inboundIdQueue
      if (consumedThisSessionRef.current.length) {
        dispatch(
          dialogActions.removeFromInboxQueue(consumedThisSessionRef.current)
        );
      }
    };
  }, []);

  // mark the specified message as consumed if conditions are met
  const noteMessageConsumed = (message, deferDequeue) => {
    if (
      message?.id && //message exists
      isDirectedType(message.type) && //only process directed message
      !message.consumed && // must not already be consumed
      message.recipient === userId // must be directed to the current user
    ) {
      // update server
      patchVoiceMessage(message.id, { field_consumed: true }).catch(err =>
        console.log('noteMessageConsumed patch error:', err)
      );

      if (dialogsFetchedFlg) {
        // update dialog list
        dispatch(dialogActions.noteDMConsumed(message.id));

        // update inbox queue
        if (deferDequeue) {
          // remove from queue when component dismounts
          setConsumedThisSession(list => [...list, message.id]);
        } else {
          // remove the message from queue now
          dispatch(dialogActions.removeFromInboxQueue(message.id));
        }
      }
    }
  };

  return noteMessageConsumed;
};

export default useInboxConsumer;
