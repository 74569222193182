/**
 * subrequests.js
 * Demonstration of subrequests
 * using Drupal subrequests module https://www.drupal.org/project/subrequests
 *
 * BUG
 * duplicate responses, requires patch or disable of page cache to resolve
 *   https://www.drupal.org/project/subrequests/issues/3049395
 *   https://www.drupal.org/project/subrequests/issues/3061907
 *   https://www.drupal.org/project/drupal/issues/3050383
 *
 * consider:
 *   Disabling the page cache in services fixes the issue.
 *   $settings['cache']['bins']['page'] = 'cache.backend.null';
 *
 * however:
 *  'delete' actions seem to work since no response body is involved
 */

import { WEB_HOST } from 'configs/config-hvn';
import { makeArray } from 'lib/utils/utils';
import store from 'store';
import { deleteMessageById } from 'lib/api/messageApi';

const baseURL = WEB_HOST;
const endpoint = `${baseURL}/subrequests`;

// extract boundary from multipart content-type header
const parseBoundary = contentType => {
  const ix1 = contentType.indexOf('boundary="') + 10;
  const ix2 = contentType.indexOf('"', ix1);
  return contentType.substring(ix1, ix2);
};

// separate the parts and parse into objects
const parseParts = (text, boundary) => {
  const parts = text
    .split(`--${boundary}`)
    .map(part => part.substring(part.indexOf('{"jsonapi":')))
    .filter(str => str.indexOf('jsonapi') > -1);
  const parsed = parts.map(part => JSON.parse(part));
  return parsed;
};

// execute the subrequest
const subrequestExecute = params => {
  let boundary = '';
  return fetch(endpoint, params)
    .then(response => {
      const contentType = response.headers.get('content-type');
      boundary = parseBoundary(contentType);
      return response.text();
    })
    .then(text => {
      const items = parseParts(text, boundary);
      return items;
    })
    .catch(err => console.log('subrequestDemo error: ', err));
};

// override params.body and invoke subrequestExecute()
export const messageListExpunge = (type, idList) => {
  // as of 20220511 messageListExpunge was observed to invoke CORS error
  // @@ investigate CORS error
  return messageListExpungeWorkaround(type, idList);

  if (Array.isArray(idList) && idList.length > 0) {
    let i = 0;

    // build a sequential roadmap, do not execute all requests in parallel
    const roadmap = idList.map(id => {
      const item = {
        requestId: `req-${++i}`,
        uri: `/jsonapi/node/${type}/${id}`,
        action: 'delete',
        headers: {
          Accept: 'application/json',
        },
      };
      i > 1 && (item.waitfor = `req-${i - 1}`);
      return item;
    });

    const params = {
      method: 'post',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        //Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjZhN2E5MTQxYTM2ZmVkNDg5ZDc5MzE5NWZjZmEyODZlNTcwNzhhMGQxOTBkNjMzY2U5MGUwMDlhYTM3ZjViYjU1MjliMjU1Y2I4YjQ0OGRhIn0.eyJhdWQiOiI3NTVjOGFmMC0wMTBmLTQ4NmItYjFjZS01ZDkxYmU5NmNmYTkiLCJqdGkiOiI2YTdhOTE0MWEzNmZlZDQ4OWQ3OTMxOTVmY2ZhMjg2ZTU3MDc4YTBkMTkwZDYzM2NlOTBlMDA5YWEzN2Y1YmI1NTI5YjI1NWNiOGI0NDhkYSIsImlhdCI6MTYzNTI4MDk4MywibmJmIjoxNjM1MjgwOTgzLCJleHAiOjE2MzUzNjczODMsInN1YiI6IjM1MiIsInNjb3BlcyI6WyJvYXV0aCIsImF1dGhlbnRpY2F0ZWQiXX0.oa0T-9BzBWAt37jEJHA1xiX9Rj61RCOzlBQi8mkxA9iSZtzYfV-UodOkb9bLRzfBxEW9uA8ThFRZ_U4scw3e0_HZkIj8WBXHvhZRJAmditKGdtiW1yW56X64GsGNl4wfyoMMUNncK7D2iH7AjKRYspG0alnAegVConVfN_oHQpLUCvaCTkuIJ_EPQlvchAYNo_45AL7j8zSnpYqMfAFyhToxZCLPUrzXFfekeMnknQfWiGLBNJFAnRg5p-WznLgLkME7KeZ4OovV8Q9Q9hw_45zISEex8GBps51CuhDd4euYnA8Im9gYYqR_dyu-8UStJaMsJzaTQDNp7ZP1uqwqQQ`
      }),
      body: JSON.stringify(roadmap),
    };

    // apply auth token if available
    const token = store.getState().user.auth.authObject?.access_token;
    token && params.headers.append('Authorization', `Bearer ${token}`);

    return subrequestExecute(params).then(items =>
      makeArray(items).map(item => {})
    );
  }
};

const messageListExpungeWorkaround = (type, idList) => {
  return Promise.all(idList.map(id => deleteMessageById(id))).then(
    rslt => rslt
  );
};
