/**
 * VadStatusTable
 *
 * Render a 2 dimensional table showing Voice Activity local/remote and mouth/ear
 * If multiple VAD types are active, represent each type by its first letter,
 * and display status of all vads.
 *
 * The handleChange event handler is lifted up to parent.
 *
 */

import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { makeArray } from 'lib/utils/utils';
import TableStd from 'components/utils/TableStd';
import FlashBall from 'components/utils/FlashBall';

const VadStatusTable = forwardRef((props, ref) => {
  const { config, disabledCells } = props;

  const [columns, setColumns] = useState([]); //column labels
  const [rows, setRows] = useState([]); // row labels
  const [currentlyDisabled, setCurrentlyDisabled] = useState([]);

  // list of recognized vadTypes is assembled dynamically
  const vadTypes = useRef([]);
  const multiVads = vadTypes.current.filter(t => t !== '*').length > 1; // ignore '*' type.

  // lists of z-cell contents (visible indicators) in each grid location
  const [visible, setVisible] = useState(null);

  const tableRef = useRef();

  // initialize table cells and labels
  useEffect(() => {
    const { columns, rows } = config;
    let cells = [];

    if (Array.isArray(columns) && Array.isArray(rows)) {
      setColumns(columns);
      setRows(rows);

      // create list of designations for each cell in the table
      columns.forEach((xItem, ixx) => {
        rows.forEach((yItem, ixy) => {
          cells.push(`${ixx}${ixy}`);
        });
      });

      // initialize visible table with empty lists
      const visible = {};
      cells.forEach(c => (visible[c] = []));
      setVisible(visible);
    }
  }, []);

  // apply disabled indicator to specified list of cells
  useEffect(() => {
    const setCellContent = (cell, status) => {
      tableRef?.current?.setCellContent &&
        tableRef.current.setCellContent(cell, <FlashBall status={status} />);
    };
    //console.log("disabledCells:", disabledCells)
    const newDisabled = makeArray(disabledCells);
    currentlyDisabled.forEach(cell => setCellContent(cell, 'hidden'));
    makeArray(disabledCells).forEach(cell => setCellContent(cell, 'disabled'));
    setCurrentlyDisabled(newDisabled);
  }, [disabledCells]);

  //useEffect(() => { console.log("visible local mouth::", visible?.LM) }, [visible])

  /**
   *
   * 3-character string specifying the cell and vadType
   * vadType is the first char of the vad's type mnemonic (must be unique)
   */
  const handleChange = (xyz, val) => {
    if (typeof xyz === 'string' && xyz.length === 3) {
      const x = xyz.charAt(0);
      const y = xyz.charAt(1);
      const vadType = xyz.charAt(2); // '*' indicates auto-mute event
      const disableNotification = vadType === '*';
      const gridKey = `${x}${y}`;

      // on first occurrance of a new type, add it to the list but prevent duplication
      if (!vadTypes.current.includes(vadType)) {
        vadTypes.current = [...vadTypes.current, vadType];
      }
      //disableNotification && console.log("disableNotification");
      if (!multiVads) {
        // if only one vad type, no need to distinguish in the cell
        const flashBallStatus = val
          ? disableNotification
            ? 'disable'
            : 'normal'
          : 'hidden';
        // (gridKey === "00") && console.log("gridKey 00:", flashBallStatus);
        tableRef?.current?.setCellContent &&
          tableRef.current.setCellContent(
            gridKey,
            <FlashBall status={flashBallStatus} />
          );
      } else {
        // indicate which of the known vad types are currently enabled
        const visibleTypes = makeArray(visible[gridKey]);
        const vadTypeAlreadyVisible = visibleTypes.includes(vadType);
        if (vadTypeAlreadyVisible && val) {
          // no change
          return;
        }
        // change visibilty of vadType
        const visibleInCell = val
          ? [...visibleTypes, vadType] // add to list
          : visibleTypes.filter(x => x !== vadType); //remove from list

        // update gridKey cell
        tableRef?.current?.setCellContent &&
          tableRef?.current?.setCellContent(gridKey, listToHtml(visibleInCell));

        // remember which types are visible
        setVisible(v => ({
          ...v,
          [gridKey]: visibleInCell,
        }));
      }
    }
  };

  // Expose method to parent
  useImperativeHandle(ref, () => ({
    handleChange,
  }));

  // convert vadTypes items to a formatted html representation, hiding items not included in input list
  const listToHtml = list =>
    vadTypes.current.map(z => (
      <span
        key={z}
        style={{ margin: '0px 5px', opacity: list.includes(z) ? 1 : 0 }}
      >
        {z}
      </span>
    ));

  return <TableStd config={config} ref={tableRef} />;
});

VadStatusTable.propTypes = {
  config: PropTypes.object,
  disabledCells: PropTypes.arrayOf(PropTypes.string),
};
VadStatusTable.defaultProps = {
  config: {
    x: ['Local', 'Remote'], // column labels
    y: ['Mouth', 'Ear'], // row labels
  },
  disabledCells: [],
};

export default VadStatusTable;
