/**
 * Render ClipboardActions in a dialog
 */

import Dialog2 from 'components/Modals/Dialog2';
import ClipboardActions from './components/ClipboardActions';

export default function ClipboardDialog({
  heading,
  body,
  cancelLabel,
  open,
  payload,
  onCancel,
  onCopy,
}) {
  return (
    <>
      <Dialog2
        open={open}
        onClose={onCancel}
        heading={heading}
        content={<div>{body}</div>}
        actions={
          <ClipboardActions
            link={payload}
            onExit={onCancel}
            onCopy={onCopy}
            cancelLabel={cancelLabel}
          />
        }
      />
    </>
  );
}

ClipboardDialog.defaultProps = {
  heading: 'Share Your Message',
  body: 'Use the button to copy a link to this message. Paste it into email or text it to your friend.',
  cancelLabel: 'Cancel',
};
