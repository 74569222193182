/**
 * Recorder component provides audiovcapture and mp3 encoding.
 * Controls must be provided by its parent.
 *
 * ReactMicGold provides visualization however it
 * is mounted by default at a fixed, non-responsive width.
 * Responsive behavior is achieved by placing it in a
 * parent container that is truncated horizontally
 * as needed.
 *
 * Microphone Approval Detection
 * react-mic-gold issues it's own call to getUserMedia and
 * it will fail to commence recording if microphone approval is not obtained.
 * Approval status event is reported to parent via noteMicApprovedStatus prop.
 * To make use of this, the parent asserts isRequestingPermission briefly and awaits
 * the noteApprovalStatus response before following up with its normal
 * isRecording assertion.
 *
 * Sample Rate Detection
 * It seems that react-mic-gold does not
 * properly configure sample rate, so we must detect the default and provide
 * actual microphone sample rate to react-mic-gold prop
 * otherwise tonal distortion of voice is observed
 *
 * NOTE: pause functionality is not presently employed.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RecorderActivity from '../RecorderActivity';
import { ReactMicGold } from 'react-mic-gold';
import { timeout } from 'lib/utils/utils';
import { useSelector } from 'react-redux';

const settings = {
  recorderType: 'react-mic-gold',
  size: {
    std: { width: 300 },
    xs: { width: 200 },
  },
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '10px',
  },
  visualizerContainer: {
    position: 'relative', //enable absolute positioning of children
    // width and truncation managed by parent
  },
}));

const AudioRecorder = props => {
  const {
    isRecording,
    isRequestingPermission,
    height,
    onStartRecording,
    onStopRecording,
    noteMicApprovedStatus,
    onRecorderRendered,
    approvalNeeded,
    enableWarning,
  } = props;

  const classes = useStyles();
  const [onStartOneShot, setOnStartOneShot] = useState(false); //used to debounce onStart event
  const micSampleRate = useSelector(state => state.ui.recorder.micSampleRate);

  // report failure to obtain microphone permission
  const handleOnBlock = () => noteMicApprovedStatus(false);

  // interpret and report the onStart event
  // event represents either the onset of recording, or a transient onset indicating microphone approval.
  const handleOnStart = () =>
    isRequestingPermission ? noteMicApprovedStatus(true) : onStartRecording();

  // activate 500 ms onStart one-shot
  const triggerOnStartOneShot = () => {
    if (!onStartOneShot) {
      setOnStartOneShot(true);
      timeout(500).then(() => setOnStartOneShot(false));
    }
  };
  //debounce double onStart occurrances
  useEffect(() => {
    //(executes only on change of onStartOneShot)
    if (onStartOneShot) {
      handleOnStart();
    }
  }, [onStartOneShot]);

  const recorderProps = {
    record: isRecording || isRequestingPermission, // Set to true to begin recording
    //pause:  false,      // defaults -> false

    onStop: blobObject => onStopRecording(blobObject),
    //onData: null,       // optional - called when chunk of audio data is available
    //onSave: null,
    onBlock: handleOnBlock,
    onStart: triggerOnStartOneShot, //appears to fire twice after onset of isRecording
    height: height,
    width: settings.size.std.width, //conditionally truncated by responsive container

    //className: ',',             // provide css class name
    //visualSetting: "sinewave",  // defaults -> "sinewave".  Other option is "frequencyBars"
    strokeColor: '#0096ef', // sinewave or frequency bar color
    backgroundColor: '#212121', // background color

    mimeType: 'audio/mp3', // defaults -> "audio/webm".  Set to "audio/wav" for WAV or "audio/mp3" for MP3 audio format
    sampleRate: micSampleRate, // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000
    channelCount: 1, // defaults -> 2 (stereo).  Specify 1 for mono.
    //timeSlice: 4000,        // defaults -> 4000 milliseconds.  The interval at which captured audio is returned to onData callback
    //bitRate: 128000,        // defaults -> 128000 (128kbps).
    //echoCancellation:  false, // defaults -> false
    //autoGainControl:  false,  // defaults -> false
    //noiseSuppression:  false, // defaults -> false
  };

  useEffect(() => {
    console.log(`micSampleRate = ${micSampleRate}`);
    onRecorderRendered && onRecorderRendered(); //advise parent that component has rendered
  }, []);

  // ReactMicGold includes non-responsive visualization.
  // Place it in a responsive, truncating <div />
  // Use <Grid /> to center content.
  const content = () => (
    <Grid container justify="center">
      <Grid
        container
        justify="center"
        alignItems="center"
        classes={{ root: classes.visualizerContainer }}
      >
        <div
          style={{
            position: 'absolute',
            top: 12,
            zIndex: 1,
            border: '1px solid black',
            borderRadius: '8px',
            background: 'white',
          }}
        >
          <RecorderActivity
            isRecording={isRecording}
            approvalNeeded={approvalNeeded}
            enableWarning={enableWarning}
          />
        </div>
        <ReactMicGold {...recorderProps} />
      </Grid>
    </Grid>
  );
  return content();
};

AudioRecorder.propTypes = {
  isRecording: PropTypes.bool,
  isRequestingPermission: PropTypes.bool,
  height: PropTypes.number,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func.isRequired,
  onRecorderRendered: PropTypes.func,
  noteMicApprovedStatus: PropTypes.func,
  approvalNeeded: PropTypes.bool,
  enableWarning: PropTypes.bool, // warn user of approaching time limit
};

export default AudioRecorder;
