/**
 * Render a Switch with optional border
 */

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Switch } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';

import BorderedBlock from 'components/utils/BorderedBlock';

const useStyles = makeStyles(theme => ({
  singleItemWrapper: {
    marginTop: '20px',
  },
  switchBase: {
    'color': purple[300],
    '&$checked': {
      color: purple[500],
    },
    '&$checked + $track': {
      backgroundColor: purple[500],
    },
  },
}));

export default function SwitchStd({ labels, checked, onChange, zen }) {
  const classes = useStyles();

  const handleSwitchChange = e => onChange(e.target.checked);

  const switchProps = () => {
    const sp = {
      classes: { switchBase: classes.switchBase },
      onChange: handleSwitchChange,
    };
    if (checked !== undefined) {
      sp.checked = checked;
    }
    return sp;
  };

  const renderSwitch = () => (
    <Grid
      container
      style={{ width: '100%' }}
      justify="center"
      alignItems="center"
    >
      <Grid item>{labels?.unChecked}</Grid>
      <Grid item>
        {' '}
        <Switch {...switchProps()} />
      </Grid>
      <Grid item>{labels?.checked}</Grid>
    </Grid>
  );

  return zen ? (
    renderSwitch()
  ) : (
    <BorderedBlock
      labelTop={labels?.border}
      classes={{ wrapperOuter: classes.singleItemWrapper }}
    >
      {renderSwitch()}
    </BorderedBlock>
  );
}

SwitchStd.propTypes = {
  labels: PropTypes.shape({
    checked: PropTypes.string,
    unChecked: PropTypes.string,
    border: PropTypes.string,
  }),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  zen: PropTypes.bool,
};

SwitchStd.defaultProps = {
  zen: false,
  onChange: () => {},
};
