/**
 * Render a representation for the user's profile message
 *  - getter:   getActiveProfile( userId )
 *  - setter:   <PlayerActions />
 *
 * two layout options of action buttons are supported
 *  - vertical: include 'info' and 'cancel' options
 *  - horizontal: include player progress bar
 *
 *  uses recordingBufferContext to obtain profile message blobURL
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';
import { getActiveProfile } from 'lib/api/messageApi';
import { WEB_HOST } from 'configs/config-hvn';
import { Grid } from '@material-ui/core';
import ContextualHelpButton from 'components/utils/ContextualHelpButton';
import { useRecordingBufferContext } from 'context/recordingBufferContext';
import { t } from 'lib/translation/trans';

export default function ProfileMessageManager({
  onExit,
  vertical,
  actionsStyle,
}) {
  // support for VoicePlayer
  const { isPlaying, defaultProps, setSrc, play, pause, src } =
    useVoicePlayerHelper({ playerKey: 'ProfileMessageManager' });

  const [profileMsg, setProfileMsg] = useState(null);
  const userId = useSelector(state => state.user.auth.user?.id);
  const recordingBufferContext = useRecordingBufferContext();
  const blobURL = recordingBufferContext?.recording?.blobURL;
  const noteUserProfile = profileMsg => {
    setProfileMsg(profileMsg);
    setSrc(profileMsg ? `${WEB_HOST}/${profileMsg?.fileUrl}` : null);
  };
  const handleActionEvent = action => {
    switch (action) {
      case 'dialogClose':
        // a profile recording has been made, either by an authenticated user
        // or a user with registration in progress
        // in either case it is desired to obtain a src so that it can be played
        if (userId) {
          //@@ upgrade PlayerActions to return id of new profile
          // until then, re-read new profile
          getActiveProfile(userId).then(activeProfile => {
            if (activeProfile && profileMsg?.id !== activeProfile?.id) {
              noteUserProfile(activeProfile);
              onExit && onExit(activeProfile); // if ProfileMessageManager is rendered in a dialog, close the dialog
            }
          });
        } else {
          setSrc(blobURL ? blobURL : null);
        }

        break;
      case 'playClick':
        play();
        break;
      case 'pauseClick':
        pause();
        break;
      case 'cancelClick':
        onExit && onExit(null);
      default:
        break;
    }
  };
  // fetch user's active profile
  useEffect(() => {
    // if no user is specified, do not look for profile
    userId &&
      getActiveProfile(userId).then(activeProfile =>
        noteUserProfile(activeProfile)
      );
  }, []);

  useEffect(() => {
    setSrc(blobURL ? blobURL : null);
  }, [blobURL]);

  // Display help information
  const handleInfoClick = () => {};

  // render button(s) with self-contained handlers
  const actions = actionList => (
    <PlayerActions
      legends={false}
      message={{}}
      onEvent={handleActionEvent}
      btnList={actionList}
      btnStyleOverride={{ transform: 'scale(.8)', marginRight: 10 }}
      respondTypeOverride="profile"
      disableFab={true}
      isPlaying={isPlaying}
    />
  );

  // render an action button with label
  const actionItem = (btnList, label) => (
    <Grid item container spacing={2} alignItems="center">
      <Grid item xs={2}>
        {' '}
        {actions(btnList)}{' '}
      </Grid>
      <Grid item> {label} </Grid>
    </Grid>
  );

  // prescribe props combinations for versatile rendering of Player
  const playerProps = {
    ...defaultProps,
    uiItems: ['progress'],
  };

  // render vertical layout
  // one action per row, each with inline label
  // include "info" and 'cancel' options
  // render Info option in left column and list of action options in right column
  const vLayout = () => (
    <>
      <Grid container alignItems="center" spacing={3}>
        {/* information  */}
        <Grid item xs={3}>
          <ContextualHelpButton context="Profile Messages" />
        </Grid>
        <Grid item xs={8} container direction="column" alignItems="flex-start">
          {/* Play/Pause button only if src exists  */}
          {src ? actionItem(['play', 'pause'], 'Play Your Profile') : null}

          {/* button to create or update a profile */}
          {actionItem(
            ['profile'],
            `${profileMsg ? 'Update' : 'Record'} Your Profile`
          )}

          {/* decline to record now  */}
          {actionItem(['cancel'], 'Not Now')}
        </Grid>
      </Grid>
      <VoicePlayer {...playerProps} />
    </>
  );

  // render horizontal action layout with playback progress bar
  const hLayout = () => (
    <Grid container alignItems="center">
      <Grid item>
        {actions(src ? ['profile', 'play', 'pause'] : ['profile'])}
      </Grid>
      <Grid item>
        {Boolean(src) ? (
          <div style={{ marginLeft: 10, width: 50, display: 'inline-block' }}>
            <VoicePlayer {...playerProps} />
          </div>
        ) : (
          <div>{t('Please_Record')}</div>
        )}
      </Grid>
    </Grid>
  );

  return vertical ? (
    vLayout()
  ) : (
    <Grid container alignItems="center">
      <Grid item style={actionsStyle}>
        {actions(src ? ['profile', 'play', 'pause'] : ['profile'])}
      </Grid>
      <Grid item>
        {Boolean(src) ? (
          <div style={{ marginLeft: 10, width: 50, display: 'inline-block' }}>
            <VoicePlayer {...playerProps} />
          </div>
        ) : (
          <div>{t('Please_Record')}</div>
        )}
      </Grid>
    </Grid>
  );
}

ProfileMessageManager.propTypes = {
  vertical: PropTypes.bool,
  onExit: PropTypes.func,
  actionsStyle: PropTypes.object,
};
ProfileMessageManager.defaultProps = {
  vertical: false,
  actionsStyle: {},
};
