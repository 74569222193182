/**
 * Render a group of IconButtons with optional labels.
 * Can be rendered as MUI SpeedDial if specified by props.btnLayout
 *
 * Props include a buttons array, and optional expansion button array,
 * if expansion buttons are present, a more/less button is automatically provided
 * to control the visibility of the expansion buttons.
 *
 * Each button contains a label, icon, click handler, and optional
 *  - fab to enable rendering as a <Fab />
 *  - ref to be attached to the button
 *
 * Props also include one of two methods for determining
 * which buttons are visible and enabled.
 *
 * Method 1:
 * Props include an applianceState (scaler)
 * Each button includes a visibiity and an enablement list
 * indicating for which applianceStates the button
 * is active and visible respectively.
 *
 * Method 2:
 * Props include a map in place of a scaler appliance State.
 * The map lists which buttons are active and visible at the
 * present time.
 *
 */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Typography, Grid, IconButton, Fab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { makeArray } from 'lib/utils/utils';
import { t } from 'lib/translation/trans';
import ControlsSpeedDial from './components/ControlsSpeedDial';

const useStyles = makeStyles(theme => ({
  benContainerWithLabels: {
    width: '4rem',
  },
  btn: {
    height: '40px',
  },
  benContainerNoLabels: {
    width: '2rem',
    padding: 0,
  },
}));
const marginTop = 0; // previously "20px"

const ConditionalTooltip = ({ enableTooltip, title, children }) =>
  enableTooltip ? <Tooltip title={title}>{children}</Tooltip> : children;

export const wildcard = '*';
export default function ApplianceControls(props) {
  const {
    buttons,
    statusSpec,
    //legends,   referenced props.legends
    groups,
    justify,
    btnStyleOverride,
    btnLayout,
    noteSpeedDialOpen,
  } = props;
  const [legendsStyle, setLegendsStyle] = useState('none');
  const classes = useStyles();
  const { vxs, applianceLegendsOverride } = useSelector(state => state.ui);

  // determine legend style to use
  // derive from props, but respond to redux override
  useEffect(() => {
    if (applianceLegendsOverride) {
      setLegendsStyle(applianceLegendsOverride);
    } else {
      if (typeof props.legends === 'boolean') {
        setLegendsStyle(props.legends ? 'labels' : 'none');
      } else {
        setLegendsStyle(props.legends);
      }
    }
  }, [applianceLegendsOverride, props.legends]);

  //decode the state of the button from  from  statusSpec
  //(either a string, or mapping object)
  const getBtnState = btn => {
    if (typeof statusSpec === 'object') {
      //decode the state of the button from  map
      const map = statusSpec;

      // is the btn?.label on the map.visible list?
      const isVisible = () =>
        makeArray(map?.visible).find(state => state === btn?.label);
      const isEnabled = () =>
        makeArray(map?.enabled).find(state => state === btn?.label);

      return isVisible(btn)
        ? isEnabled(btn)
          ? 'enabled'
          : 'visible'
        : 'hidden';
    }

    if (typeof statusSpec === 'string') {
      //decode the state of the button from  applianceState
      const applianceState = statusSpec;

      // evaluate btn permission array against applianceState
      // Grant if applianceState or if wildcard is in the array
      // Unless a negation for applianceState is present
      const isGranted = permissions =>
        permissions &&
        permissions.reduce(
          (acc, val) =>
            [wildcard, applianceState, `-${applianceState}`].includes(val) //should acc change?
              ? `-${applianceState}` !== val //if negation decline, otherwise grant
              : acc, //otherwise, preserve the acc
          false
        );

      // visible if wildcard or applianceState is on this button's list
      // unless negated by `-${applianceState}`
      let btnState = isGranted(btn?.visible) ? 'visible' : 'hidden';

      if (btnState === 'visible') {
        // enabled if wildcard or applianceState is on this button's list
        // unless negated by `-${applianceState}`
        btnState = isGranted(btn?.enabled) ? 'enabled' : 'visible';
      }
      return btnState;
    }

    return 'hidden'; //indeterminate
  };

  //apply status to button array
  const statefulButtons = () =>
    buttons.map(btn => ({ ...btn, status: getBtnState(btn) }));

  //apply pair-wise grouping
  const buttonGroups = () => {
    const grps = [];
    let grp = null;
    statefulButtons()
      .filter(btn => btn?.status !== 'hidden')
      .map((btn, ix) => {
        grp = Math.floor(ix / 2); // 2 in each grp

        // create group if it does not exist
        typeof grps[grp] === 'undefined' && (grps[grp] = []);

        //push the btn into its grp
        grps[grp].push(btn);
        return null;
      });
    return grps;
  };

  const handleBtnClick = (ev, handler) => {
    //prevent clicks from propagating to parent elements
    ev.cancelBubble = true;
    ev.stopPropagation && ev.stopPropagation();
    handler && handler(ev);
  };

  // remove focus from all elements to make sure click events will take place
  const handleActionsHover = () => document.activeElement.blur();

  const renderLabel = btn => btn?.label && (t(btn.label) || btn.label);

  // btn?.status: 'hidden', 'visible', 'enabled'
  const renderButton = ({ btn, ix }) =>
    btn?.status !== 'hidden' && (
      <Grid
        style={btnStyleOverride}
        key={ix}
        item
        container
        direction="column"
        alignItems="center"
        classes={{
          root:
            legendsStyle === 'labels'
              ? classes.benContainerWithLabels
              : classes.benContainerNoLabels,
        }}
      >
        {btn?.fab ? (
          <Fab
            size="small"
            color="inherit"
            onClick={ev => handleBtnClick(ev, btn?.onClick)}
            disabled={btn?.status !== 'enabled'}
            style={{ color: 'red' }}
          >
            {btn?.icon}{' '}
          </Fab>
        ) : (
          <ConditionalTooltip
            enableTooltip={legendsStyle === 'tooltips'}
            title={renderLabel(btn)}
          >
            <IconButton
              ref={btn?.ref}
              classes={{ root: classes.btn }}
              style={{ padding: 0 }}
              color="inherit"
              onClick={ev => handleBtnClick(ev, btn?.onClick)}
              disabled={btn?.status !== 'enabled'}
            >
              {btn?.icon}
            </IconButton>
          </ConditionalTooltip>
        )}
        {legendsStyle === 'labels' && (
          <Typography style={{ textAlign: 'center' }} variant="caption">
            {renderLabel(btn)}
          </Typography>
        )}
      </Grid>
    );
  // render button groups
  // place each group in a <Grid item>
  const renderGroups = () =>
    buttonGroups().map(grp => (
      <Grid item container justify="center">
        {grp.map((btn, ix) => renderButton({ btn, ix }))}
      </Grid>
    ));

  return btnLayout === 'horizontal' ? (
    <Grid
      container
      justify={justify}
      style={{ marginTop: marginTop }}
      onMouseEnter={() => handleActionsHover()}
    >
      {
        //wrap buttons into grouped pairs for vxs
        vxs && groups
          ? renderGroups()
          : statefulButtons().map((btn, ix) => renderButton({ btn, ix }))
      }
    </Grid>
  ) : (
    <ControlsSpeedDial
      buttons={statefulButtons()}
      noteOpen={noteSpeedDialOpen}
      direction={btnLayout === 'speedDialUp' ? 'up' : 'down'}
    />
  );
}

ApplianceControls.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      visible: PropTypes.arrayOf(PropTypes.oneOf(['x', wildcard])),
      enabled: PropTypes.arrayOf(PropTypes.oneOf(['x', wildcard])),
      onclick: PropTypes.func,
      fab: PropTypes.bool,
      ref: PropTypes.shape({ current: PropTypes.any }),
    })
  ).isRequired,
  statusSpec: PropTypes.oneOfType([
    PropTypes.string, // a scaler applianceState
    PropTypes.object, // a map object
  ]).isRequired,

  legends: PropTypes.oneOfType([
    //control rendering of legends
    PropTypes.bool, //deprecated, recommend use equivalent string value
    PropTypes.oneOf(['none', 'labels', 'tooltips']),
  ]),
  groups: PropTypes.bool, //enable vxs control of groups
  justify: PropTypes.string, // flex-justification of container
  btnStyleOverride: PropTypes.object,
  btnLayout: PropTypes.oneOf(['horizontal', 'speedDialUp', 'speedDialDown']),
  noteSpeedDialOpen: PropTypes.func, // SpeedDial reports its open/close status
};

ApplianceControls.defaultProps = {
  groups: true, //dynamic shift to groups is enabled
  statusSpec: 'dummy',
  justify: 'center',
  btnStyleOverride: {},
  btnLayout: 'horizontal',
};
