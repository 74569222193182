/**
 * LiveCallIcon
 * Render an icon indicating an available Action related to LiveCall progress
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { PhoneClassic, PhoneClassicOff } from 'mdi-material-ui';
import AnimationDecoration from 'components/utils/AnimationDecoration';

const useStyles = makeStyles(theme => ({
  Invite: { color: theme.palette.success.main },
  Busy: {},
  Accept: { color: theme.palette.success.main },
  Decline: { color: 'red' },
  Cancel: { color: 'red' },
  Ringing: { color: theme.palette.success.main },
}));

const LiveCallIcon = ({ context, size }) => {
  const classes = useStyles();

  const showNegative = ['Decline', 'Cancel'].includes(context);
  const enableAnimation = ['Ringing'].includes(context);

  return (
    <div
      style={{
        display: 'inline-block',
        height: 1.25 * size,
        width: 1.25 * size,
      }}
    >
      <AnimationDecoration action="wobble" enable={enableAnimation}>
        {showNegative ? (
          <PhoneClassicOff classes={{ root: classes[context] }} />
        ) : (
          <PhoneClassic
            style={{ height: size, width: size }}
            classes={{ root: classes[context] }}
          />
        )}
      </AnimationDecoration>
    </div>
  );
};

LiveCallIcon.propTypes = {
  context: PropTypes.oneOf([
    'Invite',
    'Busy',
    'Accept',
    'Decline',
    'Cancel',
    'Ringing',
  ]),
  size: PropTypes.number,
};
LiveCallIcon.defaultProps = {
  context: 'Invite',
  size: 24,
};

export default LiveCallIcon;
