import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button } from '@material-ui/core';

import sigServer from 'lib/api/signalingServer';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';

const useStyles = makeStyles(theme => {
  return {
    main: {
      //background: 'grey'
    },
  };
});

const SigServerLog = () => {
  const classes = useStyles();
  const logData = useSelector(state => state.webRTC.sigServer.logData);
  const t0 = Array.isArray(logData) && logData.length && logData[0].ts;

  const zeroPad = (num, places) => String(num).padStart(places, '0');
  const msToSec = ms => `${ms.slice(0, 4)}.${ms.slice(4)}`;

  useEffect(() => {
    sigServer.logDump(); //obtain current log data
  }, []);

  return (
    <>
      <Button onClick={() => sigServer.logDump(false)}>Refresh</Button>
      <Button onClick={() => sigServer.logDump(true)}>Reset</Button>

      <Paper classes={{ root: classes.main }}>
        <h1>Signaling Server Log</h1>
        <table>
          {logData &&
            logData.map((data, ix) => (
              <tr key={ix}>
                <td>{`${msToSec(zeroPad(data.ts - t0, 8))} ${
                  data.user ? data.user : data.socketId
                } `}</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td> {`${data.text}`} </td>
              </tr>
            ))}
        </table>
      </Paper>
    </>
  );
};

export default SigServerLog;
