import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

/**
 *  development tools
 */

const useStyles = makeStyles(theme => {
  return {
    main: {},
  };
});

const Diagnostic = ({ lineCnt = 10 }) => {
  const classes = useStyles();
  const { innerWidth, innerHeight } = useSelector(state => state.ui.deviceInfo);

  return (
    <Paper classes={{ root: classes.main }}>
      <Typography variant="h4">Diagnostic </Typography>
      <div>innerWidth= {innerWidth} </div>
      <div>innerHeight= {innerHeight} </div>
    </Paper>
  );
};

export default Diagnostic;
