/**
 * Utility Class for VoiceRecorder and MessageRecorder
 * Create props for <ApplianceControls /> component.
 *
 */

import React from 'react';
import { makeArray } from 'lib/utils/utils';

export default class ActionsControls {
  states = []; // list of objects, each containing state label, list of visible buttons, list of enabled buttons
  iconMap = {}; // key:icon pairs, one for each button
  onClick = () => {}; // event handler callback

  // default list of buttons:
  // (must be a subset of recIconMap keys )
  btnList = [];

  constructor(states, btnList, iconMap, onClick) {
    this.states = states;
    this.iconMap = iconMap;
    this.onClick = onClick;
    this.btnList = btnList;
  }

  // if the specified button is enabled, apply styling
  applyIconStyle = (btn, icon, statusSpec) => {
    const isBtnEnabled = makeArray(statusSpec?.enabled).includes(btn);
    return isBtnEnabled && icon?.props?.applyEnabledStyle
      ? React.cloneElement(icon, { style: { color: 'green' } })
      : icon;
  };

  // assemble array of action button objects
  // reassigning labels to each specified button
  btnListObjects = (btnReassignments, statusSpec) => {
    const btns = makeArray(this.btnList)
      .map(btn =>
        btnReassignments && btnReassignments[btn] ? btnReassignments[btn] : btn
      ) // relabel if specified in props
      .map(btn => ({
        // create objects per requirements of ApplianceControls
        label: btn,
        icon: this.applyIconStyle(btn, this.iconMap[btn], statusSpec),
        onClick: () => this.onClick(btn),
        fab: btn === 'Record',
      }));
    return btns;
  };

  // relabel zero or more buttons within recStates
  applyReassignments = btnReassignments =>
    btnReassignments
      ? this.states.map(item => {
          return {
            ...item,
            visible: item?.visible.map(x =>
              btnReassignments[x] ? btnReassignments[x] : x
            ),
            enabled: item?.enabled.map(x =>
              btnReassignments[x] ? btnReassignments[x] : x
            ),
          };
        })
      : this.states;

  // find the list of enabled, visible buttons for the specified state
  // reassign label and icon to any buttons in btnReassignments
  visibleEnabledList = (state, btnReassignments) => {
    return this.applyReassignments(btnReassignments).find(
      item => item.label === state
    );
  };

  // intended public method
  // create props for ApplianceColntrols component
  actionsSpec = (state, btnReassignments) => {
    const statusSpec = this.visibleEnabledList(state, btnReassignments);
    const spec = {
      statusSpec,
      buttons: this.btnListObjects(btnReassignments, statusSpec),
    };
    return spec;
  };
}
