/**
 * PlayerActions
 * (refactor of MessagePlayer/components/PlayerActions)
 *
 * Action buttons and dialogs for message player.
 *
 * Refactor Objectives
 *  - improve context sensitivity
 *  - add specialized buttons
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ApplianceControls, {
  wildcard,
} from 'components/utils/ApplianceControls';
import TipsPopover from 'components/utils/TipsPopover';
import { useSelector } from 'react-redux';
import { isPublicType, isSelfAuthored } from 'lib/api/messageApi';
import { useDispatch } from 'react-redux';
import { clearBookmark } from 'store/user-slice';
import { t } from 'lib/translation/trans';
import useLiveCallMonitor from 'hooks/useLiveCallMonitor';
import { Help, EyeOffOutline, PhoneHangup } from 'mdi-material-ui';

//dialogs
import MessageRecorderDialog from './components/MessageRecorderDialog';
import ShareActionDialog from './components/ShareActionDialog';
import BookmarkActionDialog from './components/BookmarkActionDialog';
import InfoActionDialog from './components/InfoActionDialog';
import DeleteActionDialog from './components/DeleteActionDialog';
import RegretsActionDialog from './components/RegretsActionDialog';
import { getCallManager } from 'lib/api/webRTCApi';

//icons
import MicIcon from '@material-ui/icons/Mic';
import ReplyIcon from '@material-ui/icons/Reply';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertical from '@material-ui/icons/MoreVert';
import { HeartRemoveOutline } from 'mdi-material-ui';
import LiveCallIcon from 'components/LiveCall/components/LiveCallIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import { CogOff } from 'mdi-material-ui';
import { useHostedMediaContext } from 'context/hostedMediaContext';

const callProc = getCallManager();

const PlayerActions = props => {
  const {
    btnList,
    groups,
    legends,
    message,
    onEvent,
    onRecActionEvt,
    justify,
    btnStyleOverride,
    noteWidth,
    respondTypeOverride,
    disableFab,
    actionDescriptionOverrides,
    btnLabelOverrides,
    isPlaying,
    disabled,
    btnLayout,
    noteSpeedDialOpen,
  } = props;

  const [action, setAction] = useState(null);
  const [btnLists, setBtnLists] = useState([[]]); // list of button lists (for separated rendering)
  const micRef = useRef();
  const itemRef = useRef();
  const dispatch = useDispatch();

  const { isCallable, canCallNow, isRinging } = useLiveCallMonitor({
    remoteUserId: message?.userId,
  });

  //    if ( message?.userId === '11221845-4086-2085-4444-000000000000'){
  //        console.log(`PlayerActions message?.userId=${message?.userId}, isCallable=${isCallable}, canCallNow=${canCallNow}, isRinging=${isRinging} `);
  //    }

  const t1 = useSelector(state => state.lang.langMap);
  const user = useSelector(state => state.user);
  const canRecordProfile =
    user.isAuthenticated || respondTypeOverride === 'profile';

  // upon first actuation of 'respond', disable future tips popups

  const handleDialogClose = e => {
    setAction(null);
    onEvent && onEvent(e || 'dialogClose');
  };

  const handleActionClick = action => {
    // bookmark removal is performed here
    if (action === 'unMark') {
      //console.log(`removing bookmark for ${message?.userId} (${message?.screenName})`)
      dispatch(clearBookmark({ targetUser: message?.userId }));
    }
    setAction(action);
    onEvent && onEvent(`${action}Click`);
  };

  // double check if callee is still callable
  // if not, report the exception and re-render PlayerActions without 'call' button
  const handleCallActionClick = () => {
    canCallNow
      ? handleActionClick('call')
      : callProc.exception(
          `${message?.screenName} is not available to receive calls now.`
        );
  };

  // determine attributes of author & message
  const type = message?.type;
  const directed = !isPublicType(type);
  const selfAuthor = isSelfAuthored(message);
  const isMessageCallable = [
    'profile',
    'post',
    'comment',
    'vmail',
    'vmailm',
  ].includes(type);
  const isResponsePermitted = !selfAuthor || !directed; //no DM to self permitted
  const isResponseEnabled = !selfAuthor || message?.type !== 'profile'; // disable record button, if enabled, for responding to own profile
  const ctx = useHostedMediaContext();

  // measure and report component width
  useEffect(() => {
    noteWidth && noteWidth(itemRef.current?.clientWidth);
  }, []);

  // separate btnList into sub-arrays at null entries
  //@@ note that this feature perhaps duplicates 'spacer' button feature
  useEffect(() => {
    setBtnLists(
      btnList.reduce(
        (out, item) => {
          item
            ? out[out.length - 1].push(item) // append to existing sub-array
            : out.push([]); //create new sub-array
          return out;
        },
        [[]]
      )
    );
  }, [btnList]);

  /**
     * Buttons that control actions upon a message
     *
     * apply rules:
     * - disallow all if props.message is falsy
     * - cannot call, bookmark, blacklist self
     * - can only delete self-authored or directed messages
     * - cannot share directed message
     * - cannot call an invite
     * - cannot accept or decline, except for an invite
     * - cannot call an inactive user
     * - cannot bookmark already bookmarked

     */

  // apply a symbol to assert or prevent
  const assert = flg => [flg ? wildcard : 'x'];

  // standard props passed to all action dialogs
  const dialogProps = () => ({
    open: true,
    onClose: handleDialogClose,
    message,
  });
  const actionButtons = {
    //---------------------------------------
    // play/pause controls:
    // to use these, include in props.btnList and use redux mode for <Player />
    play: {
      label: 'Play',
      icon: <PlayIcon />,
      visible: assert(!isPlaying),
      enabled: assert(true),
      onClick: () => handleActionClick('play'),
      description: t1.T$_Commence_playback,
      fab: !disableFab, //render this as a <Fab />
    },
    pause: {
      label: 'Pause',
      icon: <PauseIcon />,
      visible: assert(isPlaying),
      enabled: assert(true),
      onClick: () => handleActionClick('pause'),
      description: t1.T$_Pause_playback,
    },

    //---------------------------------------
    // actions to general playable messages:
    respond: {
      label: 'Respond',
      icon: <ReplyIcon ref={micRef} />,
      visible: assert(isResponsePermitted),
      enabled: assert(isResponseEnabled),
      onClick: () => handleActionClick('respond'),
      //@@ NOTE: dialog can be ovrrriden by useResponderDialog in PlaylistBrowser
      dialog: (
        <MessageRecorderDialog
          {...{
            ...dialogProps(),
            typeOverride: respondTypeOverride,
            onActionEvt: onRecActionEvt,
          }}
        />
      ), //launches recorder
      description: t1.T$_Record_a_voice_response_to_this_message,
    },
    // synonym for 'respond'
    send: {
      label: 'Send',
      icon: <MicIcon ref={micRef} />,
      visible: assert(isResponsePermitted),
      enabled: assert(isResponseEnabled),
      onClick: () => handleActionClick('respond'),
      //@@ NOTE: dialog can be ovrrriden by useResponderDialog in PlaylistBrowser
      dialog: (
        <MessageRecorderDialog
          {...{
            ...dialogProps(),
            typeOverride: respondTypeOverride,
            onActionEvt: onRecActionEvt,
          }}
        />
      ), //launches recorder
      description: t1.T$_Send_a_direct_message_to_this_member,
    },
    profile: {
      label: 'Profile',
      icon: <MicIcon ref={micRef} />,
      visible: assert(canRecordProfile),
      enabled: assert(canRecordProfile),
      onClick: () => handleActionClick('profile'),
      //@@ NOTE: dialog can be ovrrriden by useResponderDialog in PlaylistBrowser
      dialog: (
        <MessageRecorderDialog
          {...{
            ...dialogProps(),
            message: {},
            bannerOverride: t('Update_Your_Profile_Recording'),
            typeOverride: respondTypeOverride,
            onActionEvt: onRecActionEvt,
          }}
        />
      ), //launches recorder
      description: 'Update your voice profile',
    },

    share: {
      label: 'Share',
      icon: <ShareIcon />,
      visible: assert(!directed),
      enabled: assert(true),
      onClick: () => handleActionClick('share'),
      dialog: <ShareActionDialog {...dialogProps()} />,
      description:
        t1.T$_Obtain_a_link_to_this_message_that_can_be_pasted_to_social_media,
    },
    bookmark: {
      //disable bookmark button if already bookmarked
      label: 'Like',
      icon: <FavoriteBorderOutlinedIcon />,
      visible: assert(!selfAuthor && !message?.bookmarked),
      enabled: assert(true),
      onClick: () => handleActionClick('bookmark'),
      dialog: (
        <BookmarkActionDialog {...{ ...dialogProps(), isPositiveMark: true }} />
      ),
      description: t1.T$_Bookmark_the_author_of_this_message,
    },
    unMark: {
      //remove bookmark
      label: 'unMark',
      icon: <HeartRemoveOutline />,
      visible: assert(!selfAuthor && message?.bookmarked),
      enabled: assert(true),
      onClick: () => handleActionClick('unMark'),
      dialog: null,
      description: t1.T$_Remove_bookmark,
    },
    blacklist: {
      label: 'Block',
      icon: <EyeOffOutline />,
      visible: assert(!selfAuthor),
      enabled: assert(true),
      onClick: () => handleActionClick('blacklist'),
      dialog: (
        <BookmarkActionDialog
          {...{ ...dialogProps(), isPositiveMark: false }}
        />
      ),
      description: t1.T$_Block_all_messages_from_this_person,
    },
    info: {
      label: 'Info',
      icon: <Help />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('info'),
      dialog: <InfoActionDialog {...{ ...dialogProps(), infoBtnList }} />,
      description: t1.T$_Show_this_information_dialog,
    },
    delete: {
      label: 'Delete',
      icon: <DeleteForeverIcon />,
      visible: assert(directed || selfAuthor),
      enabled: assert(true),
      onClick: () => handleActionClick('delete'),
      dialog: <DeleteActionDialog {...dialogProps()} />,
      description: t1.T$_Delete_this_message,
    },
    cancel: {
      label: 'Cancel',
      icon: <CancelIcon />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('cancel'),
      dialog: null,
      description: t1.T$_Cancel_this_action,
    },
    // provide a blanker spacer
    spacer: {
      label: '',
      icon: null,
      visible: assert(true),
      enabled: assert(false),
      onClick: null,
      dialog: null,
      description: t1.T$_spacer,
    },
    more: {
      label: 'More',
      icon: <MoreVertical />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('more'),
      dialog: null,
      description: t1.T$_More_Actions,
    },

    //---------------------------------------
    // actions related to call processing with delayed call progress ramifications
    // (these launch the recorder, and delivery of recordings impact call processing)
    call: {
      label: 'Call',
      icon: (
        <LiveCallIcon
          context={isRinging ? 'Ringing' : canCallNow ? 'Invite' : 'Busy'}
        />
      ),
      visible: assert(isCallable || isRinging),
      enabled: assert(canCallNow),
      onClick: handleCallActionClick,
      dialog: (
        <MessageRecorderDialog
          {...{
            ...dialogProps(),
            typeOverride: 'invite',
            onActionEvt: onRecActionEvt,
          }}
        />
      ), //launches recorder
      description: t1.T$_Invite_this_user_to_talk_live,
      fab: false, //render this as a <Fab />
    },
    regrets: {
      label: 'Regrets',
      icon: <ReplyIcon />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('regrets'),
      dialog: <RegretsActionDialog {...dialogProps()} />, //launches recorder
      description:
        t1.T$_Record_a_voice_response_to_decline_this_invitation_to_talk_live,
    },
    //---------------------------------------
    // actions related to call processing with immediate call progress ramifications
    accept: {
      label: 'Accept',
      icon: <LiveCallIcon context="Accept" />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('accept'),
      dialog: null,
      description: t1.T$_Accept_this_invitation_to_talk_live,
    },
    decline: {
      label: 'Decline',
      icon: <LiveCallIcon context="Decline" />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('decline'),
      dialog: null,
      description: t1.T$_Decline_this_invitation_to_talk_live,
    },
    hangup: {
      label: 'Hangup',
      icon: <PhoneHangup />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('hangup'),
      dialog: null,
      description: t1.T$_End_this_live_conversation,
    },
    settings: {
      label: 'Settings',
      icon: <SettingsIcon />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('settings'),
      dialog: null,
    },
    close: {
      label: 'Hide',
      icon: <CogOff />,
      visible: assert(true),
      enabled: assert(true),
      onClick: () => handleActionClick('close'),
      dialog: null,
    },
  };

  // prepare visible button list to be passed to <InfoActionDialog />
  /*
        deprecated
        const visibleButtons = btnList.map(btn => actionButtons[btn]).filter(btn => btn?.visible[0] === "*")
        function infoBtnList() { return Object.entries(visibleButtons).map(item => (({ label, icon, description }) => ({ label, icon, description }))(item[1])) }
    */

  function infoBtnList() {
    // add 'call' button to infoBtnList if user is callable
    return (
      [isCallable ? 'call' : null, ...btnList]
        .filter(b => typeof b === 'string')
        .map(key => ({ ...actionButtons[key], key })) // add btnList value as additional key to btn
        .filter(btn => btn?.visible[0] === '*')

        // apply description overrides if specified:
        .map(btn => {
          const description = actionDescriptionOverrides
            ? actionDescriptionOverrides[btn?.key]
            : btn?.description;
          const label = t(btn?.label);
          return description
            ? { ...btn, label, description }
            : { ...btn, label };
        })
    );
  }

  // apply label and enablement overrides if specified
  const btnOverrides = (key, btn) => {
    const override = btnLabelOverrides && btnLabelOverrides[key];
    return {
      ...{ ...btn, label: override || btn?.label },
      enabled: disabled ? assert(false) : btn?.enabled,
    };
  };

  return (
    <div ref={itemRef}>
      {/* render a separated ApplianceControls component for each group of buttons */}
      <Grid container spacing={2}>
        {btnLists.map((btnList, ix) => (
          <Grid key={ix} item>
            <ApplianceControls
              buttons={btnList.map(btn =>
                btnOverrides(btn, actionButtons[btn])
              )}
              groups={false}
              legends={ctx.playerActionsLegends || legends}
              justify={justify}
              btnStyleOverride={btnStyleOverride}
              btnLayout={btnLayout}
              noteSpeedDialOpen={noteSpeedDialOpen}
            />
          </Grid>
        ))}
      </Grid>
      {message?.selected && (
        <TipsPopover anchorEl={micRef.current} tipsKey="responseMic" />
      )}

      {/* launch appropriate dialog if action has been asserted */}
      {message &&
        Boolean(action) &&
        (Boolean(actionButtons[action].dialog)
          ? actionButtons[action].dialog
          : null)}
    </div>
  );
};

PlayerActions.propTypes = {
  // button management
  btnList: PropTypes.arrayOf(PropTypes.string),
  groups: PropTypes.bool, //wrap buttons into grouped pairs
  legends: PropTypes.bool, //enables legends on icon buttons
  disabled: PropTypes.bool, //disable all buttons
  justify: PropTypes.string, // flex-justification of button container
  btnStyleOverride: PropTypes.object, //override the default button styling
  respondTypeOverride: PropTypes.string, //force recording type for 'respond' action
  disableFab: PropTypes.bool, // disable rendering of Play button as FAB
  btnLayout: PropTypes.string,

  //events
  onEvent: PropTypes.func,
  onRecActionEvt: PropTypes.func,
  useResponderDialog: PropTypes.bool,
  noteWidth: PropTypes.func, // report this component width to parent
  isPlaying: PropTypes.bool, // required if 'play', 'pause' buttons are visible

  // actuation resources
  message: PropTypes.object, //message to be acted on
  actionDescriptionOverrides: PropTypes.object, // replace rendered descriptions of buttons
  btnLabelOverrides: PropTypes.object, // replace rendered labels for buttons
  noteSpeedDialOpen: PropTypes.func, //
};

PlayerActions.defaultProps = {
  // a null entry produces a separation between groups of buttons
  btnList: [
    'respond',
    null,
    'share',
    'delete',
    'bookmark',
    'unMark',
    'blacklist',
    'info',
  ],
  legends: true,
  disabled: false,
  useResponderDialog: true, //false to disable dialog for responder
  justify: 'center',
  btnStyleOverride: {},
  respondTypeOverride: null,
  disableFab: false,
  actionDescriptionOverrides: null,
  btnLabelOverrides: null,
  btnLayout: 'horizontal',
};
export default PlayerActions;
