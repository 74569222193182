/**
 * RecorderTextField
 * Renders a form for collecting new message information.
 * Visually emphasizes (blinking red title) when field is required
 *
 * //TODO: consider replacing this with TextFieldStd
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { maxTitleLength } from 'configs/config-hvn';
import useEvent from '@react-hook/event';

const useStyles = makeStyles(theme => ({
  'normal': {
    '& .MuiFormLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
  'blinkingRed': {
    '& .MuiFormLabel-root': {
      color: 'red', // or black
      fontWeight: 'bold',
      animation: '$blink 2s steps(1, end) infinite',
    },
  },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
}));

const RecorderTextField = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // select default set for form vs display
  const defaults = {
    label: 'Topic',
    placeholder: 'Please enter a topic',

    required: true,
    fullWidth: true,
    //margin: "normal",
    InputLabelProps: { shrink: true },
    InputProps: {},
    variant: 'outlined',
    style: { margin: 0, width: '100%' },
    //value: '',
    onChange: null,
    inputProps: { maxlength: maxTitleLength },
    onFocus: () => dispatch(uiActions.noteFieldFocus(true)),
    onBlur: () => dispatch(uiActions.noteFieldFocus(false)),
  };

  useEffect(() => {
    // be sure to clear focus flag on dismount
    return () => {
      dispatch(uiActions.noteFieldFocus(false));
    };
  }, []);

  // keyboard event handler
  // blur field upon 'enter'
  useEvent(document, 'keydown', event => handleKeydown(event.keyCode));
  const handleKeydown = code => {
    switch (code) {
      case 13:
        document.activeElement.blur();
        props.onEnter && props.onEnter();
        break;
      default:
        break;
    }
  };

  // this field requires a value
  const required =
    typeof props.required !== 'undefined' ? props.required : defaults.required;

  // input field is empty
  const empty = !Boolean(props.value.length);

  const labelProps = () => ({
    label: `${props.label}${required && empty ? ' (Required)' : ''}`,
    className: required && empty ? classes.blinkingRed : classes.normal,
  });

  return (
    <Grid container justify="center">
      <TextField
        className={classes.root}
        {...{
          ...defaults,
          ...props,
          ...labelProps(),
        }}
      />
    </Grid>
  );
};

RecorderTextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
};

export default RecorderTextField;
