import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { uiActions } from 'experiments/ReduxDemo2/store/ui-slice'
import Notification from 'experiments/Notification'

// flag to to throttle first execution of useEffect
let isInitial = true

const FirebaseDemo = () => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch()
  const notification = useSelector(state => state.ui.notification)

  // send http request whenever cart changes
  useEffect(() => {

    if (isInitial) {
      isInitial= false
      return  //block continuation on initial execution
    }

    const sendCartData = async () => {
      dispatch(uiActions.showNotification({
        status: 'pending', title: 'Sending...', message: 'Sending cart data!'
      }));

      const response = await fetch('https://hotlynx-development-default-rtdb.firebaseio.com//cart.json', {
        method: 'PUT',
        body: JSON.stringify(cart),
      });
      if (!response.ok) {
        throw new Error('Sending cart data fzailed.')
      }
      // don't care about response   const responseData = await response.json()

      dispatch(uiActions.showNotification({
        status: 'success', title: 'Success...', message: 'Sent cart data!'
      }));
    }

    // function returns a promies, so we can catch errors
    sendCartData().catch((error) => {
      dispatch(uiActions.showNotification({
        status: 'error', title: 'Error!', message: 'Sending cart data failed!'
      }));
    })

  }, [dispatch. cart]);

  return (<>
    { notification && <Notification status={notification.status} title={notification.title} message={notification.message} /> }
    <h1>FirebaseDemo</h1>
    </>
    )
}


export default FirebaseDemo
