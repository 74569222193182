/**
 * Render a standard Tabs component with items supplied in props
 * Render with labels or tooltips per props.legendStyle
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import TooltipStd from 'components/utils/TooltipStd';

const useStyles = makeStyles(theme => ({
  tabsContainerInner: {},
  tabsContainerOuter: {},
  noIndicator: {
    //display: "none",
  },
  tab: {
    minWidth: 48,
  },
}));

export default function TabsStd({
  items,
  legendStyle,
  classes: classesOverride,
  value,
  onChange,
}) {
  const [selectedItem, setSelectedItem] = useState(null);

  const classes = { ...useStyles(), ...classesOverride };

  const handleChange = (event, newValue) => {
    setSelectedItem(items[newValue]);
    onChange && onChange(newValue);
  };

  return (
    <div className={classes.tabsContainerOuter}>
      <div
        className={`${classes.tabsContainerInner} ${classes.tabsContainerInner} `}
      >
        <AppBar elevation={0} position="static" color="transparent">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            centered
            aria-label="full width tabs example"
            classes={{ indicator: classes.noIndicator }}
          >
            {items.map((item, index) => (
              <Tab
                classes={{ root: classes.tab }}
                key={item.legend}
                label={legendStyle === 'label' ? item.legend : null}
                icon={
                  legendStyle === 'tooltips' ? (
                    <TooltipStd
                      item={item}
                      selected={item.legend === selectedItem?.legend}
                    />
                  ) : (
                    item.icon
                  )
                }
              />
            ))}
          </Tabs>
        </AppBar>
      </div>
    </div>
  );
}

TabsStd.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.number,
  onChange: PropTypes.func,
  classes: PropTypes.object,
  legendStyle: PropTypes.string, // 'label', 'tooltips', 'none'
};

TabsStd.defaultProps = {
  legendStyle: 'label',
};
