/**
 * VadJam3
 *
 * Detects voice on audiotrack in the specified stream,
 * Based on https://github.com/Jam3/voice-activity-detection
 *
 * ToDO:
 * - ignore first onVoiceStop if it precedes first onVoiceStart
 * - holdoff acting on short utterances, await continuation
 *
 */

import VAD from 'voice-activity-detection';

class VadJam3 {
  jam3 = null;
  options = null;

  // from voice-activity-detection sources
  static defaults1 = {
    fftSize: 1024,
    bufferLen: 1024,
    smoothingTimeConstant: 0.2,
    minCaptureFreq: 85, // in Hz
    maxCaptureFreq: 255, // in Hz
    noiseCaptureDuration: 1000, // in ms
    minNoiseLevel: 0.3, // from 0 to 1
    maxNoiseLevel: 0.7, // from 0 to 1
    avgNoiseMultiplier: 1.2,
    onVoiceStart: function () {
      console.log('jam3 talking');
    },
    onVoiceStop: function () {
      console.log('jam3 quiet');
    },
    onUpdate: function (val) {},
  };

  // from voice-activity-emitter sources
  static defaults2 = {
    fftSize: 512,
    bufferLen: 512,
    smoothingTimeConstant: 0.2,
    minCaptureFreq: 85, // in Hz
    maxCaptureFreq: 255, // in Hz
    noiseCaptureDuration: 1000, // in ms
    minNoiseLevel: 0.6, // from 0 to 1
    maxNoiseLevel: 0.9, // from 0 to 1
    avgNoiseMultiplier: 1.2,
    onVoiceStart: function () {
      console.log('jam3 talking');
    },
    onVoiceStop: function () {
      console.log('jam3 stopped');
    },
    onUpdate: function (val) {},
  };

  constructor({ type, context, audioStream, options = {}, key }) {
    this.key = key;
    this.type = type;
    this.options = { ...VadJam3.defaults2, ...options };
    this.jam3 = VAD(context, audioStream, this.options);
  }

  resume = () => this.jam3 && this.jam3.connect();
  suspend = () => {
    this.jam3 && this.jam3.disconnect();
    this.options.onVoiceStop();
  };
  destroy = () => {
    this.suspend();
    this.jam3 && this.jam3.destroy();
  };
}

export default VadJam3;
