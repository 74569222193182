/**
 * Demonstration of host landing page
 *
 *
 */

import { Grid, Paper, List, ListItem, ListItemText } from '@material-ui/core';
import HorizontalSplit from 'components/layouts/components/HorizontalSplit';
import { makeStyles } from '@material-ui/core/styles';
import BorderedBlock from 'components/utils/BorderedBlock';
import { useHistory } from 'react-router-dom';

const Nelly = () => {
  const history = useHistory();

  return (
    <HorizontalSplit
      topItem={
        <Grid container direction="column" alignItems="center">
          <Grid item style={{ textAlign: 'center' }}>
            <img src="/images/nelly/nelly2.png" />
          </Grid>
        </Grid>
      }
      bottomItem={
        <Grid container direction="column" alignItems="center">
          <Grid item style={{ marginTop: '2rem' }}>
            <Paper style={{ maxWidth: 600, padding: 20 }} elevation={6}>
              <p>Salut toi !</p>
              <p>
                Je m'appelle Nelly, professeur de français certifiée.
                J'accompagne des centaines d'élèves dans leur apprentissage du
                français : français débutant, intermédiaire ou avancé. Quel que
                soit l'objectif : français du tourisme, études universitaires,
                passage des examens DELF et DALF...
              </p>

              <p>
                Sur ma chaîne OkSayit, vous trouverez des conversations
                interactives auxquelles vous pourrez participer et pratiquer à
                votre niveau. On ne peut pas apprendre à parler sans parler !
              </p>

              <p>J'espère que mon contenu vous aidera à progresser.</p>

              <p>À bientôt,</p>

              <p>Nelly</p>
            </Paper>
          </Grid>
          <Grid item style={{ maxWidth: 600, marginTop: 20 }}>
            <BorderedBlock labelTop="Video Discussions">
              <div style={{ width: 600, height: 0 }}></div>

              <List component="nav" aria-label="secondary mailbox folders">
                <ListItem
                  button
                  onClick={() => history.push('/host/nelly/mais')}
                >
                  <ListItemText
                    primary="1. Mais"
                    secondary='Stop saying "MAIS" in French | Use these alternatives INSTEAD'
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => history.push('/host/nelly/mais')}
                >
                  <ListItemText
                    primary="2. Copy of Mais"
                    secondary='Stop saying "MAIS" in French | Use these alternatives INSTEAD'
                  />
                </ListItem>
              </List>
            </BorderedBlock>
          </Grid>
        </Grid>
      }
    />
  );
};

export default Nelly;
