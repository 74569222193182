/**
 * Language translation support
 * Provides a langMap with translations of pre-allocated symbols to the current active language
 *
 * Usage Recommendation:
 *      import { useSelector } from 'react-redux'
 *      const t = useSelector(state => state.lang.langMap)
 *      t.Example_phrase
 *
 */

import { createSlice } from '@reduxjs/toolkit';
import { transMapList } from 'lib/translation/trans';

const initialState = {
  langCode: 'EN',
  langMap: transMapList['EN'],
};

const langSlice = createSlice({
  name: 'lang',
  initialState: initialState,
  reducers: {
    // set the language-specific symbol map
    setLangMap(state, action) {
      state.langCode = action.payload;
      state.langMap = transMapList[action.payload];
    },
  },
});

export const langActions = langSlice.actions;
export default langSlice.reducer;
