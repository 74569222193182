import React, { useEffect, useState } from 'react';

import MediaView from './components/MediaView';
import { getCallManager } from 'lib/api/webRTCApi.js';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'store/user-slice';
import { webRTCActions } from 'store/webRTC-slice';
import sigServer from 'lib/api/signalingServer';

const callProc = getCallManager().webRTCDemo;

const Demo = props => {
  const { demoUser } = props;
  const remoteUser = demoUser === 'caller' ? 'callee' : 'caller';

  //const calleeUser = activeUsers.find(u => u.username === 'callee')
  const dispatch = useDispatch();

  const [localStream, setLocalStream] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [peer, setPeer] = useState(null);
  const [open, setOpen] = useState(false);

  const peers = useSelector(state => state.user.peers);
  const remoteStream = useSelector(
    state => state.webRTC.participants.remote.stream
  );

  //initialize
  useEffect(() => {
    callProc
      .getMicStream()
      .then(stream => {
        setLocalStream(stream);
        dispatch(
          webRTCActions.setParticipantInfo({
            party: 'local',
            info: {
              role: demoUser,
              stream: stream,
              micEnabled: true,
              userId: demoUser,
              screenName: demoUser,
              username: demoUser,
            },
          })
        );
        dispatch(
          webRTCActions.setParticipantInfo({
            party: 'remote',
            info: {
              userId: remoteUser,
              screenName: remoteUser,
            },
          })
        );
        return Promise.resolve(stream);
      })
      .then(() => callProc.getTurnServers())
      .then(() => Promise.resolve(callProc.createPeerConnection()))
      .then(() => {
        dispatch(
          userActions.loginDemo({ userId: demoUser, screenName: demoUser })
        );
        sigServer.registerSelfAsPeer('WebRTCDemo');
        console.log('WebRTCDemo initialized OK.');
        setInitialized(true);
        return Promise.resolve('WebRTCDemo initialized OK');
      })
      .catch(err => console.log('WebRTCDemo initialization error:', err));
  }, []);

  // detect registration of remote user
  useEffect(() => {
    if (initialized) {
      const peer =
        peers && peers.find(p => ['caller', 'callee'].includes(p.userId));
      console.log(`WebRTCDemo remote peer= ${peer?.userId}`);

      // if role is caller, send an offer
      if (demoUser === 'caller' && peer?.userId === 'callee') {
        callProc.sendOffer();
      }
      setPeer(peer);
    }
  }, [peers, initialized]);

  var windowFeatures = 'top, left, width=500, height=500';

  return (
    <>
      <div>DEMO</div>
      {/* echo cancellation: https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings/echoCancellation  */}
      <div>
        local
        <MediaView stream={localStream} muted={true} />
      </div>
      <div>
        remote
        <MediaView stream={remoteStream} muted={false} />
      </div>

      {demoUser === 'callee' ? (
        <button
          style={{ marginTop: 40 }}
          onClick={() => {
            window.open(
              `${window.location.origin}/#/caller`,
              'caller',
              windowFeatures
            );
          }}
        >
          Launch Caller
        </button>
      ) : null}
    </>
  );
};

export default Demo;
