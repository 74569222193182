
const UserProfile = () => {
  return (
    <main >
      <h2>My User Profile</h2>
    </main>
  );
};

export default UserProfile;
