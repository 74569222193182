/**
 * Tools to check Application version
 *
 *  compare version version info in meta.json with package.json
 */

// obtain the cached program version
import packageJson from '../../../package.json';

// read the codebase version from meta file (not cached)
export const getVersionInfo = () =>
  fetch('../../meta.json')
    .then(response => response.json())
    .then(meta => ({
      codeBaseVersion: meta.version,
      cachedVersion: packageJson.version,
      conflict: meta.version !== packageJson.version,
    }));

// resolve version conflict by reloading or redirecting to version-specific URL
export const versionResolve = () => {
  return getVersionInfo().then(
    ({ codeBaseVersion, cachedVersion, conflict }) => {
      console.log(
        `versionResolve codebase=${codeBaseVersion}, cached=${cachedVersion}`
      );
      if (conflict) {
        window.confirm(`Will upgrade to latest ${codeBaseVersion}.`);
        // hard reload
        window.location.reload(true); // maybe not working on all browsers
        console.log('Reloading to obtain latest release.');
        //@@ consider appending version suffix and redirecting if reload doesnt work for all browsers
        //    window.location.replace
        //    window.location.href

        //@@ consider emulating keyboard hard reload key combinations
        /*
                    KEY COMBINATIONS
                    -------------------
                    windows
                        chrome      ctrl+F5, ctrl+reload
                        edge
                        firefox     ctrl+F5, ctrl+shift+R
                    mac
                        safari      option+cmd+E
                        chrome      cmd+shift+R, shift+reload
                        edge
                        firefox     cmd+shift+R, cmd+reload


                    KEY
                    -------------------
                    windows
                        ctrl        event.ctrlKey
                        F5
                        reload
                        shift       event.shiftKey
                        R
                    mac
                        option      event.altKey
                        cmd         event.metaKey
                        E
                        shift       event.shiftKey
                        R
                        reload
    // played around with the following but could not initiate a refresh

    // manual test
    window.addEventListener("keydown", (event) => {
        const e = {
            altKey: event.altKey,
            ctrlKey: event.ctrlKey,
            metaKey: event.metaKey,
            shiftKey: event.shiftKey,
            charcode: event.charcode,
            code:   event.code,
            key:    event.key,
            keyCode: event.keyCode
        }
        console.log(e);
    });

    // manual test
    document.dispatchEvent(
    new KeyboardEvent("keydown", {
        key: "v",
        ctrlKey: true,
        bubbles: true,
        metaKey: true
    })
);

                    references
                    https://fabricdigital.co.nz/blog/how-to-hard-refresh-your-browser-and-clear-cache
                    https://erikmartinjordan.com/simulate-multiple-keypress-javascript
                    https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
                    https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes/
                    https://eastmanreference.com/complete-list-of-applescript-key-codes
                    https://unixpapa.com/js/key.html
                */
      }

      //version is
      return Promise.resolve(codeBaseVersion);
    }
  );
};
