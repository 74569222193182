/**
 * Render a member's identification and attributes
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { getCallManager } from 'lib/api/webRTCApi';
import { t } from 'lib/translation/trans';
import AuthorAnnotation from 'containers/MessagePlayer/components/AuthorAnnotation';
import { timeSince } from 'lib/utils/utils';
import CalleeActions from 'components/LiveCall/components/LiveCallAlert/components/CalleeActions';
import { fromProxyUser } from 'lib/api/messageApi';
const callProc = getCallManager();

const useStyles = makeStyles(theme => ({
  icon: {
    width: '.6em',
    height: '.6em',
    marginLeft: '.6em',
  },
  red: {
    color: 'red',
  },
}));

const Message = ({ message, selected, onSelect, onActionEvent, isPlaying }) => {
  const classes = useStyles();
  const bookmarked = message?.bookmarked;
  // handle click on one of the action buttons
  const handleActionEvent = evClick => {
    onActionEvent && onActionEvent(message?.id, evClick);
  };

  // user has clicked on thie Message
  const handleItemClick = event => onSelect && onSelect(message);

  // set up the Actions for the current message
  const messageActions = () => {
    const actionsProps = {
      disabled: !selected,
      isPlaying,
      groups: false,
      legends: false,
      message: { ...message, selected },
      onEvent: handleActionEvent,
      justify: 'flex-start',
      btnStyleOverride: { transform: 'scale(.8)' },
      btnList: fromProxyUser(message)
        ? ['play', 'pause', null, 'respond', 'delete', 'info']
        : [
            'play',
            'pause',
            null,
            'call',
            'respond',
            'share',
            'delete',
            'bookmark',
            'unMark',
            'blacklist',
            'info',
          ],
      //disableFab: true,
    };
    if (message?.type === 'invite') {
      actionsProps.btnList = ['accept', 'decline', 'regrets', 'blacklist'];
      actionsProps.legends = true;
    }
    return <PlayerActions {...actionsProps} />;
  };

  const renderUser = () => (
    <div>
      {`${t('From')}:`} {message?.screenName}{' '}
      <AuthorAnnotation message={message} />{' '}
      {` -- (${timeSince(new Date(message?.created))} ${t('ago')})`}
    </div>
  );

  return (
    <ListItem button selected={selected} onClick={handleItemClick}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <ListItemText
            primary={<div style={{ color: '#0096ef' }}>{message?.title}</div>}
            secondary={renderUser()}
          />
        </Grid>
        {selected && (
          <Grid item>
            {message?.type === 'invite' ? (
              <CalleeActions zen={true} />
            ) : (
              messageActions()
            )}
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

Message.propTypes = {
  message: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onActionEvent: PropTypes.func,
  isPlaying: PropTypes.bool,
};
export default Message;
