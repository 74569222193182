/**
 * Render a form to manage web-push Notifications Settings
 *
 * Web Push differs from SMS as follows:
 * - only attribute supported is enable/disable
 * - state is not stored in user record, rather it is maintained by the service worker
 * - enablement does NOT require actuation of "Submit" button
 * - does NOT support invocation from NotificationsSuggestion
 *
 * Outstanding:
 * - standardize common functionality with NotificationsSettingsSMS
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Chip,
  FormControl,
  FormLabel,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import RadioStd from 'components/utils/RadioStd';
import { t } from 'lib/translation/trans';
import { pwa } from 'lib/classes/PWA';
import useDrupalPushServer from 'hooks/useDrupalPushServer';

const useStyles = makeStyles(theme => ({
  table: {},
  controls: {
    width: '25%',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'block',
    },
  },
}));

const useSelectorStyles = makeStyles(theme => ({
  formControl: {
    margin: 0,
    minWidth: 0,
    width: 120,
    //height: 36
  },
}));

const NotificationsSettings = ({ onSubmit }) => {
  /* state */
  const [originalSettings, setOriginalSettings] = useState({});
  const [editedSettings, setEditedSettings] = useState({});
  const [showForm, setShowForm] = useState(true); // render form initially
  /* end state */

  const classes = useStyles();
  const dispatch = useDispatch();
  const submitDisabled = () => false; // button is always enabled with visible

  const showFullForm = Boolean(editedSettings.consent === '+');
  const valuesChanged =
    JSON.stringify(originalSettings) !== JSON.stringify(editedSettings);
  const showButtons = valuesChanged && showForm;

  const { requestPushSubscription } = useDrupalPushServer();

  // initialize from pwa state
  useEffect(() => {
    let userNotifications = {};
    // if subscription exists, 'consent' key is enabled
    userNotifications.consent = pwa.pushSubscription ? '+' : '-';
    setOriginalSettings(userNotifications);
    setEditedSettings(userNotifications);
  }, [pwa.pushSubscription]);

  // Handlers -----------------------------------------------------------------------------------

  // update the settings only if they have changed since the originalSettings
  const handleSubmit = () => {
    processUpdate(originalSettings, editedSettings);
    //      .then(rslt => console.log('handleSubmit success:', rslt))
    //      .catch(e => console.log('handleSubmit error:', e))
  };

  // update the settings only if they have changed since the originalSettings
  const processUpdate = (original, edited) => {
    // only take action if a change was made
    let action = Promise.resolve('no change');
    if (JSON.stringify(original) !== JSON.stringify(edited)) {
      dispatch(uiActions.backdropSet(t('Please_Wait')));
      const { consent } = edited;
      const enable = consent === '+';
      action = enable ? requestPushSubscription : pwa.pushUnsubscribe;
    }
    return action()
      .then(rslt => {
        exit();
        return rslt;
      })
      .catch(err => {
        console.log('processUpdate error:', err);
        exit();
        return err;
      });
  };

  const exit = () => {
    dispatch(uiActions.backdropClr());
    setShowForm(false); //stop rendering the full form
    onSubmit && onSubmit();
  };

  const handleCancel = () => {
    setShowForm(false);
    setEditedSettings(originalSettings);
  };

  // when enabling consent, bypass the submit button act immediately
  const handleScratchpadUpdate = update => {
    if (update.consent === '+' && originalSettings.consent === '-') {
      // user selected "Enable", perform auto Submit
      processUpdate(originalSettings, update).catch(e =>
        console.log('enable notifications  error:', e)
      );
    } else {
      setEditedSettings(old => ({ ...old, ...update }));
    }
  };

  // Form Elements  -----------------------------------------------------------------------------------

  const consentSelector = (
    <FormControl>
      <FormLabel component="legend">
        {t('Receive_Notifications_When_Messages_Arrive_For_You')}{' '}
      </FormLabel>

      <RadioStd
        name="consent"
        classes={classes}
        items={[
          { key: '+', data: t('Enable') },
          { key: '-', data: t('Disable') },
        ]}
        value={editedSettings?.consent}
        handleChange={el =>
          handleScratchpadUpdate({ consent: el.target.value })
        }
      />
    </FormControl>
  );

  const settingsTable = () => (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {getRows().map(row => (
            <TableRow key={row.name}>
              <TableCell
                style={{ width: '25%' }}
                align="left"
                component="th"
                scope="row"
              >
                {row.name}{' '}
              </TableCell>
              <TableCell
                style={{ paddingLeft: 0, position: 'relative' }}
                align="left"
              >
                {' '}
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Helpers  -----------------------------------------------------------------------------------

  // transform raw field contents into display label values
  const label = {
    '0': t('Disabled'),
    '+': t('Enabled'),
    '-': t('Disabled'),
    'x': t('Stopped'),
    'sms': t('Text'),
    'EN': t('English'),
    'FR': t('French'),
    'SP': t('Spanish'),
    'DE': t('German'),
    'LT': t('Lithuanian'),
  };

  const statusIndicator = () => <>{label[editedSettings?.consent]} </>;

  // deliver table cell contents, either for display of settings or for update
  const createData = (name, value, action) => ({ name, value, action });

  // rows can be generated as status or as form for updating
  const rows = {
    settings: [createData(t('Status'), statusIndicator())],
    form: [createData(`${t('Status')} `, <>{consentSelector}</>)],
  };

  // render 'Status' row, or all rows
  const getRows = () =>
    rows[showForm ? 'form' : 'settings'].filter(
      (r, ix) => ix === 0 || showFullForm
    );

  const headerButtons = () => (
    <div
      style={{
        position: 'relative',
        visibility: showButtons ? 'visible' : 'hidden',
      }}
      className={classes.controls}
    >
      <Chip
        style={{ marginRight: 16 }}
        size="small"
        label={t('Submit')}
        onClick={handleSubmit}
        color="secondary"
        disabled={submitDisabled()}
      />
      <Chip
        size="small"
        label={t('Cancel')}
        onClick={handleCancel}
        color="primary"
      />
    </div>
  );

  return (
    <div style={{ position: 'relative' }}>
      {headerButtons()}
      {settingsTable()}
    </div>
  );
};

NotificationsSettings.propTypes = {
  onSubmit: PropTypes.func,
};

NotificationsSettings.defaultProps = {};

export default NotificationsSettings;
