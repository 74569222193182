/**
 * Render Tip popover for 'respond' microphone
 * (open only once after specified number of pings )
 *
 * Prior to mounting this component a tipsKey must be created as follows:
 *  dispatch(uiActions.tipsControlSetKeys([ ['exampleKey', 'back', 'Example Text.'] ]))
 *
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import TipsPopoverLayout from './components/TipsPopoverLayout';

const TipsPopover = ({ anchorEl, tipsKey }) => {
  const dispatch = useDispatch();
  const { tipsControl } = useSelector(state => state.ui);
  const params = tipsControl.keys[tipsKey] || tipsControl.keys['default'];

  return (
    <TipsPopoverLayout
      open={
        Boolean(anchorEl) && tipsControl.respondTipOpen && !tipsControl.holdoff
      }
      onClose={() => dispatch(uiActions.respondTipClose())}
      anchorEl={anchorEl}
      arrow={params.arrow}
      text={params.text}
    />
  );
};

TipsPopover.propTypes = {
  anchorEl: PropTypes.element,
  tipsKey: PropTypes.string,
};

TipsPopover.defaultProps = {};
export default TipsPopover;
