/**
 * MemberInfoDialog
 * Render a dialog with public information about a member
 *
 */

import React from 'react';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import BorderedBlock from 'components/utils/BorderedBlock';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import { WEB_HOST } from 'configs/config-hvn';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  table: {
    maxWidth: 300,
  },
  heading: {
    backgroundColor: theme.palette.secondary.dark,
  },
  paramVal: {
    color: theme.palette.secondary.main,
  },
}));

const MemberInfoDialog = ({ open, member, onClose }) => {
  const classes = useStyles();
  const { defaultProps } = useVoicePlayerHelper({ playerKey: 'CallStatus' });

  const renderPlayer = src => (
    <VoicePlayer
      {...defaultProps}
      src={src}
      autoplay={false}
      onNext={null}
      onPrevious={null}
      uiItems={['hControls']}
      ident="MemberInfoDialog"
    />
  );

  // render the member's parameters in a table
  const userProfileInformation = () => {
    const params = member?.params;
    const profile = member?.profile;

    //@@ consider upgrading the translation to employ labels from the community.params
    // see RegPages.translate method (employed by MembershipView)
    const tRow = (key, val) => (
      <TableRow key={key}>
        <TableCell component="th" scope="row">
          <Typography className={classes.paramVal}>{t(val)}</Typography>
        </TableCell>
        <TableCell align="right">{`(${t(key)})`}</TableCell>
      </TableRow>
    );

    return (
      <BorderedBlock labelTop={member?.screen_name} classes={{}}>
        <Grid
          id="joetest"
          container
          justify="center"
          style={{ width: '100% ' }}
        >
          <Grid item>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  {params &&
                    Object.entries(params).map(([key, val]) => tRow(key, val))}
                  {profile &&
                    tRow(
                      'Profile',
                      renderPlayer(`${WEB_HOST}/${profile?.fileUrl}`)
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </BorderedBlock>
    );
  };

  return (
    <ExceptionDialog
      exceptionDisable={true}
      open={open}
      onClose={onClose}
      heading={t('Member_Profile_Information')}
      body={userProfileInformation()}
    />
  );
};

export default MemberInfoDialog;
