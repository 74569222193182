import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

/**
 * Implement a MUI button with uniform styling
 * including left-margin for all children except the firtst
 */

const useStyles = makeStyles(() => ({
  btn: {
    'textTransform': 'none', // eliminate default that forces capitalization
    'marginLeft': '20px',
    '&:first-child': {
      marginLeft: '0px',
    },
  },
  btnCentered: {
    textTransform: 'none', // eliminate default that forces capitalization
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const ButtonStd = props => {
  const { label, onClick, centered, ...other } = props;
  const classes = useStyles();

  const handleClick = ev => {
    //prevent clicks from propagating to parent elements
    ev.cancelBubble = true;
    ev.stopPropagation && ev.stopPropagation();
    onClick && onClick(ev);
  };

  return (
    <Button
      className={centered ? classes.btnCentered : classes.btn}
      variant="contained"
      size="small"
      onClick={handleClick}
      {...other}
    >
      {label}
    </Button>
  );
};

ButtonStd.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.object,
  centered: PropTypes.bool,
};

ButtonStd.defaultProps = {
  disabled: false,
  color: 'primary',
  style: {},
  centered: false,
};

export default ButtonStd;
