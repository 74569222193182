/**
 *  Render a WelcomeView page for a particular segregated Community
 *  NOTE: partition is a string serving as a key value for the Community
 *
 *  Content is derived from a Community node on the Server
 *  Open a dialog for login/registration if respective props are asserted
 */

import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';
import { getCommunity, switchCommunity, isMember } from 'lib/api/communityApi';
import { DialogStd } from 'components/Modals';
import { WEB_HOST } from 'configs/config-hvn';
import { Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import ButtonStd from 'components/utils/ButtonStd';
import { defaultCommunity } from 'configs/config-hvn';
import { makeArray, srcOk } from 'lib/utils/utils';
import AccordionStd from 'components/utils/AccordionStd';
import Copyright from 'components/utils/Copyright';
import { t } from 'lib/translation/trans';

const useStyles = makeStyles(theme => ({
  nonScrollingContainer: {
    paddingTop: '1rem',
  },
  actions: {
    //marginTop: '1rem',
  },
  accordionContainer: {
    marginTop: '1rem',
  },
  body: {
    marginTop: '1rem',
    padding: '8px 36px',
    textAlign: 'justify',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  detailsContainer: {
    'padding': '0px 20px',
    'textAlign': 'justify',
    '& ul': {
      padding: 0,
    },
    '& li': {
      marginBottom: '1rem',
    },
    '& em': {
      color: 'white',
    },

    'borderBottom': `1px solid ${theme.palette.secondary.main}`,
    'borderTop': `1px solid ${theme.palette.secondary.main}`,
  },
  byLineRoot: {
    fontStyle: 'italic',
  },
}));

const usePlayerStyles = makeStyles(theme => ({
  playerContainer: {
    width: 200,
    //            background: 'lightgrey',
    position: 'absolute',
    top: -8,
  },
  playerUiContainer: {
    justifyContent: 'left',
  },
  hControlsContainer: {
    width: '100%',
    // background: "grey",  //use 'transparent' for theme2
    borderRadius: '20px',
    border: '3px solid #9c27b0',
  },
  hControlsProgress: {
    width: '50%',
    paddingTop: 5,
  },
}));
//  <div id="joe" style={{ width: "100%", justifyContent: "left"}} >

// provide welcome page for the specified partition
// if not specified in prop, use value from redux
// if still none specified, use defaultPartition as default

const WelcomeView = ({ partition, login, register }) => {
  const { isPlaying, defaultProps, setSrc, play, pause, togglePlay, src } =
    useVoicePlayerHelper({ playerKey: 'Welcome' });

  const [welcome, setWelcome] = useState(null);
  const { isAuthenticated } = useSelector(state => state.user);
  const { vxs, pSession, layout, regDialog } = useSelector(state => state.ui);
  const reduxPartition = pSession.community?.partition;

  // scrollable height calculation
  const nonScrollingSectionRef = useRef(null);
  const totalAvailableHeight = layout.mainContentHeight;
  const [nonScrollingSectionHeight, setNonScrollingSectionHeight] = useState(0);
  const scrollableSectionMaxHeight =
    totalAvailableHeight - nonScrollingSectionHeight;

  const classes = useStyles();
  const playerClasses = usePlayerStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const parser = new DOMParser();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const [clickCnt, setClickCnt] = useState(0); // count of user click events

  // INITIALIZE

  useEffect(() => {
    // hide distracting main menu when not logged in
    // cleanup when component dismounts
    if (!isAuthenticated) {
      dispatch(uiActions.layoutComponentHide({ menu: true }));
    }

    // fetch the community information
    // use partition from props, if none use redux, if none use defaultPartition
    const assignedPartition =
      partition || reduxPartition || defaultCommunity?.partition;
    //console.log(`assignedPartition = ${assignedPartition}`)
    getCommunity(`${assignedPartition}`, 'withMedia')
      .then(community => {
        if (community) {
          const { partition, title, params, body, sections, infoUrl, logoUrl } =
            community;

          // switch to the specified community
          switchCommunity(community);

          // prepare to play the community intro greeting
          const src = `${WEB_HOST}${infoUrl}`;
          setSrc(srcOk(src) ? src : null);

          // when invoked from /login or /register routes the Welcome page is rendered in a dialog
          // and login/registration dialog is overlaid. In this event, suppress auto play.
          if (login || register) {
            dispatch(
              uiActions.setRegDialog({
                open: true,
                mode: login ? 'login' : 'register',
              })
            );
          }

          // content consists of a body, multiple segments, a voice intro file, and an icon
          // parse and assemble from community node
          setWelcome({
            partition,
            title,
            body,
            infoUrl,
            logoUrl,

            //extract accordion panel components from individual segments
            //of the Drupal Community node
            panels: makeArray(sections).map((section, ix) => {
              const doc = parser.parseFromString(section, 'text/html');
              return {
                ix,
                h2: doc.getElementsByTagName('h2')[0]?.innerHTML,
                h3: doc.getElementsByTagName('h3')[0]?.innerHTML,
                details: doc.getElementsByTagName('section')[0]?.innerHTML,
              };
            }),
          });

          // This component renders with an upper non-scrolling section
          // that is protected from scrollbar,  and a lower section
          // that presenta a localized scrollbar if the content warrants.
          // Measure the height of the non-scrolling components
          setNonScrollingSectionHeight(
            nonScrollingSectionRef?.current?.clientHeight
          );
        }
      })
      .catch(err => {
        console.log('getCommunity error:', err);
        history.replace('/');
      });

    return () => {
      // when dismounting, close registration dialog if open
      dispatch(uiActions.setRegDialog({ open: false, mode: null }));

      // restore main menu
      dispatch(uiActions.layoutComponentHide({ menu: false }));
    };
  }, [window.location.href]); //detect if history.push("/welcome/NewValue") has occurred

  // if RegistrationManager was launched by this component
  // detect and handle close of RegistrationManager
  useEffect(() => {
    if (!regDialog.open) {
      // RegistrationManager dialog has closed
      isAuthenticated && handleLoginSuccess();
    }
  }, [regDialog.open]);

  //    // load audio upon closing of registration dialog
  //    // (it may not have been loaded during initialization)
  //    useEffect(() => {
  //        !regDialog.open && src && dispatch(uiActions.setPlayerSrc(src))
  //    }, [regDialog.open])

  // upon authentication, restore main menu
  // (it may have been suppressed during initialization)
  useEffect(() => {
    dispatch(uiActions.layoutComponentHide({ menu: !isAuthenticated }));
  }, [isAuthenticated]);

  // recalibrate scrolling section height when vxs changes
  useEffect(() => {
    setNonScrollingSectionHeight(nonScrollingSectionRef?.current?.clientHeight); // size of the non-scrolling components
  }, [vxs]);

  // for any user click, set the "welcomed" flag
  // visit the site by navigating to /playlist
  const handleGoToPlaylist = () => {
    dispatch(uiActions.setWelcomed()); //affects routing on next visit
    history.push('/playlist');
  };

  // navigate to /members
  const handleGoToMembers = () => {
    dispatch(uiActions.setWelcomed()); //affects routing on next visit
    history.push('/members');
  };

  // launch the login dialog
  const handleJoin = () => {
    pause();
    dispatch(uiActions.setWelcomed()); // affects routing on next visit
    dispatch(uiActions.setRegDialog({ open: true, mode: 'register' }));
  };

  const handleLoginSuccess = () => null; //no-op for now

  // on first accordion click, start Player
  const handleAccordionChange = () => {
    // do not auto-start playing
    //if (!clickCnt) {
    //    play()
    // }
    setClickCnt(cnt => ++cnt);
  };

  const renderFullPlayer = () => (
    <VoicePlayer
      {...defaultProps}
      autoplay={false}
      noBrowserViewControl={true}
      progressStyle="standard"
      uiItems={vxs ? ['hControls'] : ['visualizer']}
      onNext={null}
      onPrevious={null}
    />
  );

  const renderBriefPlayer = () => (
    <div style={{ width: '100%', position: 'relative' }}>
      <div className={playerClasses.playerContainer}>
        <VoicePlayer
          {...defaultProps}
          uiItems={['hControls']}
          onNext={null}
          onPrevious={null}
          classes={playerClasses}
        />
      </div>
    </div>
  );

  // render a byline for language groups, toggle on accordion clicks
  const byLine = () =>
    pSession?.community?.partition &&
    pSession.community.partition.includes('lang') ? (
      // hard wired byline for language communities
      <div style={{ marginBottom: 16 }}>
        {clickCnt % 2 ? (
          <div style={{ color: 'orange', textAlign: 'center' }}>
            <Typography classes={{ root: classes.byLineRoot }}>
              Have You Been Stuck in the Wrong Media ?
            </Typography>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Typography classes={{ root: classes.byLineRoot }}>
              You Can't Learn to Speak Without Speaking !
            </Typography>
          </div>
        )}
      </div>
    ) : (
      // for other communities
      <div dangerouslySetInnerHTML={{ __html: welcome.body }} />
    );

  // show player for first panel, only if there is no h3
  const showPlayer = pnl => src && pnl.ix === 0 && !Boolean(pnl.h3);

  return (
    <>
      {welcome && (
        <>
          <div ref={nonScrollingSectionRef} style={{ paddingBottom: 10 }}>
            <Grid container justify="center">
              <Grid
                classes={{ root: classes.nonScrollingContainer }}
                item
                xs={12}
                md={6}
              >
                {false ? byLine() : null /* disable */}
                <Grid
                  classes={{ root: classes.actions }}
                  item
                  container
                  justify="center"
                  spacing={1}
                >
                  {isMember(partition) ? (
                    <>
                      <Grid item>
                        <ButtonStd
                          onClick={handleGoToMembers}
                          label={t('Member List')}
                          color="secondary"
                          centered
                        />{' '}
                      </Grid>
                      <Grid item>
                        <ButtonStd
                          onClick={handleGoToPlaylist}
                          label={t('Chat')}
                          color="secondary"
                          centered
                        />{' '}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item>
                        <ButtonStd
                          onClick={handleGoToPlaylist}
                          label={t('Visit')}
                          color="secondary"
                          centered
                        />{' '}
                      </Grid>
                      <Grid item>
                        <ButtonStd
                          onClick={handleJoin}
                          label={t('Join')}
                          color="secondary"
                          centered
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div
            style={{ overflow: 'auto', maxHeight: scrollableSectionMaxHeight }}
          >
            <Grid container direction="column" alignItems="center">
              <Grid style={{ marginBottom: '20px' }} item md={8}>
                <div className={classes.accordionContainer}>
                  {
                    <AccordionStd
                      onChange={handleAccordionChange}
                      sections={welcome.panels.map((pnl, ix) => ({
                        key: `pnl${ix}_${pnl.h2}`,
                        heading: (
                          <Typography
                            className={classes.accordionHeading}
                            color="secondary"
                          >
                            {pnl.h2}
                          </Typography>
                        ),
                        subHeading: showPlayer(pnl) ? (
                          renderBriefPlayer()
                        ) : (
                          <Typography
                            className={classes.accordionSecondaryHeading}
                          >
                            {xs ? null : pnl.h3}
                          </Typography>
                        ),
                        details: (
                          <div className={classes.detailsContainer}>
                            {xs && (
                              <p>
                                <Typography variant="body1" color="textPrimary">
                                  {' '}
                                  {pnl.h3}{' '}
                                </Typography>
                              </p>
                            )}
                            <Typography variant="body2" color="textSecondary">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: pnl.details,
                                }}
                              />
                              {/* consider refactoring to https://github.com/MomenSherif/react-html-string */}
                            </Typography>
                          </div>
                        ),
                      }))}
                      initialOpen={null /* `pnl0_${welcome.panels[0]?.h2}` */}
                    />
                  }
                </div>
              </Grid>
              <Copyright />
            </Grid>
          </div>
        </>
      )}
      <DialogStd
        showProgress={true}
        open={!welcome}
        redux={false}
        showButton={false}
        content={`${t('Please_Wait')}...`}
      />
    </>
  );
};

WelcomeView.propTypes = {
  partition: PropTypes.string, // optional specification of community key
  login: PropTypes.bool, // render with login dialog open
  register: PropTypes.bool, // render with registration dialog open
};

export default WelcomeView;
