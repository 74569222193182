/**
 * Render Button capable of copying text to clipboard
 * Copies raw CF_TEXT format to clipboard, no formatting
 *
 */

import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { t } from 'lib/translation/trans';

const ClipboardButton = ({ text, onClick, label, icon }) => {
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => onClick('success'))
      .catch(e => {
        console.log('ClipboardButton error:', e);
        onClick(e);
      });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <IconButton onClick={handleCopyClick}>{icon}</IconButton>
      <div>{label}</div>
    </div>
  );
};

ClipboardButton.defaultProps = {
  text: '',
  onClick: () => {},
  label: t('Copy_Link'),
  icon: <FileCopyIcon color="secondary" />,
};

export default ClipboardButton;
