/**
 * useMembers custom hook
 *
 * Make the members list available, reading them from host if necessary
 *
 */

import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  isMember,
  getCommunityMembers,
  getMemberParams,
  currentPartition,
} from 'lib/api/communityApi';
import { getProfiles, fetchDialogs, retrieveMessage } from 'lib/api/messageApi';
import { userActions } from 'store/user-slice';

const useMembers = () => {
  const [progressFlag, setProgressFlag] = useState(false);
  const stateUser = useSelector(state => state.user);
  const { members } = stateUser;
  const { pSession } = useSelector(state => state.ui);
  const { community } = pSession;
  const { partition } = community;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMember()) {
      // load members ONLY if they have not already been loaded
      if (members.length === 0) {
        // members not yet fetched
        setProgressFlag(true);
        //console.log("fetching member data.");
        getCommunityMembers()
          // apply partition-specific params to member object
          .then(members =>
            members.map(m => ({ ...m, params: getMemberParams(partition, m) }))
          )
          .then(members => appendProfileMessages(members))
          .then(members => {
            if (Array.isArray(members)) {
              dispatch(userActions.setMembers(members));
            }
            setProgressFlag(false);
          })
          .catch(err => {
            console.log('member params error:', err);
            setProgressFlag(false);
          });
      } else {
        // member list already retrieved
      }
    } else {
      // user is not authorized to access members data
    }
  }, [partition, stateUser]);

  // fetch profiles and append to member objects
  const appendProfileMessages = members => {
    let p;
    return getProfiles({ partition: currentPartition() }).then(profiles =>
      members.map(m =>
        (p = profiles.find(p => p.userId === m?.id)) ? { ...m, profile: p } : m
      )
    );
  };
  return { members, progressFlag };
};

export default useMembers;
