/**
 * TipsPopover configuration using Forward arrow
 *
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export const useStylesForwardArrow = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  container: {
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  popoverPosition: {},
  popoverPaper: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    borderRadius: '30px',
    width: '10%',
    [theme.breakpoints.down('md')]: {
      width: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
}));

export const configForwardArrow = classes => ({
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
  arrow: 'forward',
  icon: <ArrowForwardIcon style={{ marginLeft: 10 }} />,
  iconPlacement: 'after',
  outerContainerProps: {
    container: true,
    classes: { root: classes.container },
    justify: 'flex-end',
    alignItems: 'center',
  },
  innerContainerProps: {
    item: true,
    classes: { root: classes.content },
    xs: 9,
  },
});
