/**
 * Authorization tests
 * 
 */

import React, { useState, useEffect } from 'react';
import { REGISTRAR_USER } from 'configs/config-hvn'
import { useSelector } from 'react-redux'

import {
    TextareaAutosize,
    Grid,
    Button,
} from '@material-ui/core'

import { authenticator } from 'lib/utils/authenticator'
import { fetcher } from 'lib/utils/fetcher'



const TestOAuth = () => {
    const [authObject, setAuthObject] = useState(null)
    const [result, setResult] = useState(null)
    const { getToken, refreshToken } = authenticator()
    const authInit = useSelector(state => state.user.auth)

    useEffect(() => {
        // component did mount
        if (authInit && authInit.hvn){
            setAuthObject(authInit.hvn)
            setResult("initialized from localStorage.")
        }
    }, [authInit]);

  

    const requestToken = () => {
        setResult("-- in process --")
        getToken(REGISTRAR_USER)
            .then((authObject) => { setResult("getToken Success"); setAuthObject(authObject) })
            .catch((err) => {setResult("requestToken Fail") })
    }

    const requesRefresh = () => {
        setResult("-- refresh in process --")
        refreshToken(authObject)
            .then((authObject) => {setResult("requestRefresh Success"); setAuthObject(authObject) })
            .catch((err) => { setResult("requestRefresh Fail") })
    }


    const createPublicDialog = () => {
        const title = `test title ${Date()}`
        const data = `{
            "data": {
                "type": "node--public_dialog",
                "attributes": {
                    "title": "${title}"
                },
                "relationships": {
                    "field_public_post": {
                        "data": {
                            "type": "file--file",
                            "id": "784aafbc-1cb1-4886-88fb-0ef56587e6b3",
                            "meta": {
                                "display": true,
                                "description": "July 7 description"
                            }
                        }
                    }
                }
            }
        }
        `

        fetcher("jsonapi", 'POST', 'node/public_dialog', data)
            .then(() => { setResult("Create public_dialog Success") })
            .catch((err) => {setResult("Create public_dialog  Fail") })

    }

    const getPublicDialogs = () => {
        fetcher("jsonapi", 'GET', 'node/public_dialog')
            .then((data) => { setResult("getPublicDialogs Success") })
            .catch((err) => { setResult("getPublicDialogs  Fail") })
    }

    return <>
        <h1>token</h1>
        <div>access_token expires_at {authObject && authObject.expires_at}</div>
        <Grid container justify="flex-start" alignItems="center" spacing={5}>
            <Grid item>
                <TextareaAutosize
                    rowsMax={4}
                    aria-label="maximum height"
                    defaultValue={authObject && authObject.access_token}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={requestToken}>Get Example Token</Button>
            </Grid>
        </Grid>
        <div>refresh_token expires_at {authObject && authObject.refresh_expires_at}</div>
        <Grid container justify="flex-start" alignItems="center" spacing={5}>
            <Grid item>
                <TextareaAutosize
                    rowsMax={4}
                    aria-label="maximum height"
                    defaultValue={authObject && authObject.refresh_token}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={requesRefresh}>Refresh</Button>
            </Grid>
        </Grid>
        <Button variant="contained" color="secondary" onClick={createPublicDialog}>Create public_dialog</Button>
        <Button variant="contained" color="secondary" onClick={getPublicDialogs}>Get public_dialogs</Button>
        <div><h2>{result}</h2></div>
    </>
}
export default TestOAuth

