/**
 * Render a list of buttons representing available authentication methods.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/user-slice';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TextsmsIcon from '@material-ui/icons/Textsms';
import { AccountKey } from 'mdi-material-ui';
import {
  createButton,
  GoogleLoginButton,
  FacebookLoginButton,
} from 'react-social-login-buttons';
import { t, rt } from 'lib/translation/trans';

const AuthMethodSelector = ({ onClick, methods }) => {
  const dispatch = useDispatch();

  const handleSelection = method => {
    // report to redux store
    dispatch(userActions.setAuthMethod(method));

    // report to parent ( deprecated )
    onClick && onClick(method);
  };

  const EmailLoginButton = createButton({
    text: t('Login_with_Email'),
    icon: () => <MailOutlineIcon style={{ width: '26px' }} />,
    style: { background: '#7500f7' },
    activeStyle: { background: '#293e69' },
  });

  const SMSLoginButton = createButton({
    text: t('SMS_Text_Code'),
    icon: () => <TextsmsIcon style={{ width: '26px' }} />,
    style: { background: '#329FFD' },
    activeStyle: { background: '#0089FF' },
  });

  const PasswordLoginButton = createButton({
    text: t('Username_Password'),
    icon: () => <AccountKey style={{ width: '26px' }} />,
    style: { background: '#458f48' },
    activeStyle: { background: '#356b37' },
  });

  return (
    <div>
      {methods
        .map(m => rt(m))
        .map(method => (
          <>
            {method === 'sms' && (
              <SMSLoginButton
                key={method}
                onClick={() => handleSelection(method)}
              />
            )}
            {method === 'google' && (
              <GoogleLoginButton
                key={method}
                onClick={() => handleSelection(method)}
              >
                {t('Login_with_Google')}
              </GoogleLoginButton>
            )}
            {method === 'facebook' && (
              <FacebookLoginButton
                key={method}
                onClick={() => handleSelection(method)}
              >
                {t('Login_with_Facebook')}
              </FacebookLoginButton>
            )}
            {method === 'email' && (
              <EmailLoginButton
                key={method}
                onClick={() => handleSelection(method)}
              />
            )}
            {method === 'password' && (
              <PasswordLoginButton
                key={method}
                onClick={() => handleSelection(method)}
              />
            )}
          </>
        ))}
    </div>
  );
};

AuthMethodSelector.propTypes = {
  onClick: PropTypes.func,
  methods: PropTypes.arrayOf(PropTypes.string), //methods to be offered
};

AuthMethodSelector.defaultProps = {
  methods: ['sms', 'google', 'facebook' /* 'facebook', 'email', 'password' */],
};

export default AuthMethodSelector;
