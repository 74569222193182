/**
 * StorageTest
 */

import React, { useState, useEffect } from 'react';
import localStore from 'lib/utils/localStore';
import Cookies from 'js-cookie'

function StorageTest() {
  const [cookie, setCookie] = useState(null);
  const [storage, setStorage] = useState(null);

  const name = "hvn-test";

  useEffect(() => {
    loadStorage();
    loadCookie();
  }, []);

  const loadStorage = () => setStorage(localStore().getObj(name));
  const handleStorageClick = () => {
    localStore().setObj(name, storage ? (storage + "b") : "a");
    loadStorage();
  };

  const loadCookie = () => {
    const _cookie = Cookies.get(name);
    console.log("read cookie:", _cookie);
    setCookie(_cookie)
  };
  const handleCookieClick = () => {
    Cookies.set(name, cookie ? (cookie + "c") : "d"  );
    loadCookie();
  };

  return <>
    <h1>StorageTest</h1>
    <p>Storage: {storage ? storage : "NULL"} </p>
    <p>Cookie: {cookie ? cookie : "NULL"} </p>
    <button style={{backgroundColor: 'grey'}} onClick={handleStorageClick}>SET STORAGE</button>
    <button style={{backgroundColor: 'tan'}} onClick={handleCookieClick}>SET COOKIE</button>

  </>;
}

export default StorageTest;
