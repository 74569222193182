/**
 * Progress component renders a real time representation
 * of the progress while playing a media element.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sliderRoot: {
    color: '#0096ef', // lightBlue
    color: 'white',
  },
  // consider  raising this into classesOverride
  sliderCustomize: {},
}));

// ref: https://codesandbox.io/s/h0esn
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/slider/CustomizedSlider.js
const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    'height': 24,
    'width': 24,
    'backgroundColor': '#fff',
    'border': '2px solid currentColor',
    'marginTop': -8,
    'marginLeft': -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const AudioProgress = ({ audioEl, classes: classesOverride, prettoStyle }) => {
  const classes = { ...useStyles(), ...classesOverride };

  // state
  const [currentTime, setCurrentTime] = useState(0);
  const [maxTime, setMaxTime] = useState(100); //initial placeholder value
  const [timeSliderStep, setTimeSliderStep] = useState(1); // scale slider step based on audio max time
  const [suspendTimeUpdates, setSuspendTimeUpdates] = useState(false); // while manually manipulating slider, suspend auto updates

  const src = audioEl?.src;

  const onLoadedMetaData = () => {
    const { duration } = audioEl;
    const step = duration / 25;
    setMaxTime(duration);
    setTimeSliderStep(step);
  };

  const onTimeUpdate = () => {
    if (!suspendTimeUpdates) {
      setCurrentTime(audioEl.currentTime);
    }
  };

  useEffect(() => {
    if (audioEl) {
      // component did mount
      audioEl.addEventListener('loadedmetadata', onLoadedMetaData);
      audioEl.addEventListener('timeupdate', onTimeUpdate);

      //cleanup
      // per https://stackoverflow.com/questions/3258587/how-to-properly-unload-destroy-a-video-element
      return () => {
        audioEl.removeEventListener('loadedmetadata', onLoadedMetaData);
        audioEl.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, [audioEl]);

  useEffect(() => {
    // reset progress when src changes
    if (!src) {
      setCurrentTime(0);
    }
  }, [src]);

  //TODO2:  globally eliminate console warnings for useEffect

  const handleSliderTimeChange = (event, newValue) => {
    setSuspendTimeUpdates(true);
    setCurrentTime(newValue);
  };
  const handleSliderTimeChangeCommitted = (event, newValue) => {
    audioEl.currentTime = newValue;
    setSuspendTimeUpdates(false);
    setCurrentTime(newValue);
  };

  const sliderProps = {
    value: currentTime,
    max: maxTime,
    step: timeSliderStep || 1,
    onChange: handleSliderTimeChange,
    onChangeCommitted: handleSliderTimeChangeCommitted,
  };

  return prettoStyle ? (
    <div style={{ padding: '0 15px' }} className={classes.playerInnerContainer}>
      {' '}
      <PrettoSlider {...sliderProps} />{' '}
    </div>
  ) : (
    <Slider
      {...{ ...sliderProps }}
      classes={{ root: `${classes.sliderRoot} ${classes.sliderCustomize}` }}
    />
  );
};

AudioProgress.propTypes = {
  //inputs
  audioEl: PropTypes.instanceOf(Audio).isRequired,
  classes: PropTypes.shape({}),
  prettoStyle: PropTypes.bool, //enable 'pretto' style slider
};

AudioProgress.defaultProps = {
  classes: { playerInnerContainer: {} },
  prettoStyle: false, //enable use of PrettoSlider
};
export default AudioProgress;
