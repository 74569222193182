/**
 */

import React from 'react';
import CommunitySelector from './components/CommunitySelector';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import { t } from 'lib/translation/trans';

const selectorFormat = 'tree'; // list, radio, tree
// for demonstration, apply buttons to the dialog
const actions = [
  {
    label: 'Visit',
    onClick: null,
  },
  {
    label: 'Info',
    onClick: null,
  },
  {
    label: 'Join',
    onClick: null,
  },
];

const CommunitySelectorDialog = ({ open, handleDialogClose, classes }) => {
  return (
    <ExceptionDialog
      exceptionDisable={true}
      open={open}
      onClose={handleDialogClose}
      heading={t('Community_Selection')}
      body={
        <CommunitySelector
          format={selectorFormat}
          onChange={handleDialogClose}
          onJoined={handleDialogClose}
        />
      }
      actions={null /*actions */}
    />
  );
};

CommunitySelectorDialog.propTypes = {};
CommunitySelectorDialog.defaultProps = {};
export default CommunitySelectorDialog;
