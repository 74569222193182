import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

const Slogan = () => {
  const { langMap: t1 } = useSelector(state => state.lang);
  const { community } = useSelector(state => state.ui.pSession);
  const slogan = community?.slogan;
  const translatable = String(slogan).startsWith('T$_');

  return slogan ? (
    <div style={{ paddingTop: '10px', color: 'orange', textAlign: 'center' }}>
      <Typography style={{ fontStyle: 'italic' }}>
        {translatable ? t1[slogan] : slogan}
      </Typography>
    </div>
  ) : null;
};
export default Slogan;
