/**
 * Standardized Textfield
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Grid, TextField } from '@material-ui/core';
import useEvent from '@react-hook/event';
import { useDispatch } from 'react-redux';
import { uiActions } from 'store/ui-slice';

const TextFieldStd = props => {
  const dispatch = useDispatch();

  // select default set for form vs display
  const defaults = {
    label: null,
    placeholder: 'Please enter',
    required: true,
    fullWidth: true,
    //margin: "normal",
    InputLabelProps: { shrink: true },
    InputProps: {},
    variant: 'outlined',
    style: { margin: 0, width: '100%' },
    onChange: null,
    helperText: null,
    onFocus: () => dispatch(uiActions.noteFieldFocus(true)),
    onBlur: () => dispatch(uiActions.noteFieldFocus(false)),
  };

  // keyboard event handler
  // blur field upon 'enter'
  useEvent(document, 'keydown', event => handleKeydown(event.keyCode));
  const handleKeydown = code => {
    switch (code) {
      case 13:
        document.activeElement.blur();
        props.onEnter && props.onEnter();
        break;
      default:
        break;
    }
  };

  return (
    <Grid container justify="center" className="joe2">
      <TextField
        {...{
          ...defaults,
          ...props,
          label: (
            <span
              style={{ color: 'rgba(255, 255, 255, 0.7)' }}
            >{`${props.label}`}</span>
          ),
        }}
      />
    </Grid>
  );
};

TextFieldStd.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func, // on keyboard "enter" event
};

export default TextFieldStd;
