/**
 * PeerDataManager
 * class to manage data channel communications via RTCPeerConnection
 *
 */

class PeerDataManager {
  peerConnection = null;
  outboundDataChannel = null;
  inboundDataChannel = null;
  subscriptions = [];

  constructor(peerConnection, label = 'telemetry') {
    this.peerConnection = peerConnection;

    // handle open event for inbound data channel
    this.peerConnection.ondatachannel = event => {
      this.inboundDataChannel = event.channel;

      this.inboundDataChannel.onopen = event => {
        //console.log(`dataChannel "${event.target.label}" ${this.inboundDataChannel ? this.inboundDataChannel.readyState : "not ready"} for receiving.`);
      };

      // handle inbound message
      this.inboundDataChannel.onmessage = event => {
        const packet = event?.data && JSON.parse(event.data);
        const type = packet?.type;
        const payload = packet?.payload;
        // dispatch to subscribed callbacks
        this.subscriptions.forEach(item => {
          if (item.type === type && item.callback) {
            item.callback(payload);
          }
        });
      };
    };

    this.outboundDataChannel = this.peerConnection.createDataChannel(label);

    // handle open event for outbound data channel
    this.outboundDataChannel.onopen = event => {
      console.log(
        `dataChannel "${event.target.label}" ${
          this.outboundDataChannel
            ? this.outboundDataChannel.readyState
            : 'not ready'
        } for sending.`
      );
    };
  }

  destroy = () => {
    this.outboundDataChannel && this.outboundDataChannel.close();
    this.inboundDataChannel && this.inboundDataChannel.close();
    this.outboundDataChannel = null;
    this.inboundDataChannel = null;
    this.subscriptions = [];
    this.peerConnection = null;
  };

  send = packet => {
    const ready = this.outboundDataChannel?.readyState === 'open';
    ready
      ? this.outboundDataChannel &&
        this.outboundDataChannel.send(JSON.stringify(packet))
      : console.log(
          `peerSendData cannot send, readyState=${this.outboundDataChannel?.readyState}`
        );
  };

  //@@ prevents duplicates
  subscribe = (key, type, callback) => {
    this.unsubscribe(key);
    this.subscriptions.push({ key, type, callback });
  };

  unsubscribe = key => {
    this.subscriptions = this.subscriptions.filter(item => item?.key !== key);
  };
}

export default PeerDataManager;
