import { createSlice} from '@reduxjs/toolkit'



const initialAuthState = {
    isAuthenticated: false
}
const authSlice = createSlice({
    name: 'auth',
    initialState:initialAuthState,
    reducers : {
        login(state) {state.isAuthenticated=true},
        logout(state) {state.isAuthenticated=false},
    }
})


// action creators are provided by createSlice
// there is no need to define list text action "types"
export const authActions = authSlice.actions

export default authSlice.reducer


