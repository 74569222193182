/**
 * useDrupalPushServer custom hook
 *
 * Request permissions from local browser,
 * Upload the granted subscription to the Drupal server.
 *
 */
import { useSelector } from 'react-redux';
import { pwa } from 'lib/classes/PWA';
import { fetcher } from 'lib/utils/fetcher';

const postSubscriptionToServer = subscription => {
  const { userId, userName, descriptor, ...token } = subscription;
  if (!userId || !token) {
    return Promise.resolve({ ok: false });
  }

  const subscriptionData = {
    data: {
      type: 'node--push_subscription',
      attributes: {
        title: userName, // placeholder for required field
        field_channel: 'dm',
        field_user_id: userId,
        field_descriptor: descriptor,
        field_json_token: JSON.stringify(token),
      },
    },
  };

  //@@ TBD remove all obsolete subscriptions for this user
  const cleanup = () =>
    new Promise((resolve, reject) => {
      resolve(true);
    });

  // upload a new subscription for this user/platform/browser to the server
  return cleanup()
    .then(() =>
      fetcher('jsonapi', 'POST', '/node/push_subscription', subscriptionData)
    )
    .then(data => {
      console.log('postSubscriptionToServer:', data);
      return { ok: true };
    })
    .catch(err => {
      console.log('postSubscriptionToServer error:', err);
      return { ok: false, err };
    });
};

const useDrupalPushServer = () => {
  const descriptor = useSelector(state => state.ui.deviceInfo.descriptor);
  const userId = useSelector(state => state.user.auth?.user?.id);
  const userName = useSelector(state => state.user.auth?.user?.name);

  const requestPushSubscription = () =>
    pwa.requestPushPermission({
      userId,
      userName,
      descriptor,
      uploadPushSubscription: postSubscriptionToServer, // callback to subscribe with server
    });
  return {
    requestPushSubscription,
  };
};

export default useDrupalPushServer;
