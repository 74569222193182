/**
 * useLiveCallMonitor custom hook
 *
 * Real time monitor of remote user availibility for a LiveCall.
 *
 */

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeArray } from 'lib/utils/utils';

const useLiveCallMonitor = ({ remoteUserId }) => {
  const [isCallable, setIsCallable] = useState(null); // remote user can be called
  const [canCallNow, setCanCallNow] = useState(null); // local user can initiate a call to remoteUser now.
  const [isRinging, setIsRinging] = useState(null); //outbound call invitation is pending

  const {
    isAuthenticated: localIsAuthenticated,
    peers,
    auth: { user: localUser },
  } = useSelector(state => state.user);
  const { call, participants, invites } = useSelector(state => state.webRTC);

  const localCallProgress = call.progress;
  const remoteParticipantUserId = participants.remote.userId;
  const localUserId = localUser?.id;
  const blacklist = makeArray(localUser?.info?.field_blacklist);
  const peer = makeArray(peers).find(peer => peer.userId === remoteUserId);
  const remoteIsActive = Boolean(peer);
  const remoteIsSelf = peer && peer.userId === localUserId;
  const remoteIsBlocked = blacklist.includes(remoteUserId);

  const inviteFromRemoteParty = invites.find(
    invite => invite.userId === remoteUserId
  );

  useEffect(() => {
    const isCallableUpdate =
      localIsAuthenticated &&
      remoteIsActive &&
      !remoteIsSelf &&
      !remoteIsBlocked;
    isCallableUpdate !== isCallable && setIsCallable(isCallableUpdate);

    const canCallNowUpdate =
      isCallableUpdate &&
      localCallProgress === 'ready' &&
      !inviteFromRemoteParty;
    canCallNowUpdate !== canCallNow && setCanCallNow(canCallNowUpdate);
  }, [
    remoteUserId,
    localIsAuthenticated,
    remoteIsActive,
    remoteIsSelf,
    remoteIsBlocked,
    localCallProgress,
    inviteFromRemoteParty,
  ]);

  useEffect(() => {
    const isRingingUpdate =
      localCallProgress === 'invited' &&
      remoteUserId === remoteParticipantUserId &&
      participants.local.role === 'caller';
    isRingingUpdate !== isRinging && setIsRinging(isRingingUpdate);
  }, [remoteUserId, remoteParticipantUserId, localCallProgress]);

  //    if (remoteUserId === '11221845-4086-2085-4444-000000000000'){
  //        console.log(`useLiveCallMonitor remoteUserId=${remoteUserId},  localCallProgress=${localCallProgress}, isCallable=${isCallable}, canCallNow=${canCallNow}, isRinging=${isRinging} `);
  //    }

  return {
    isCallable, // authenticated, remote active, not self, not blocked
    canCallNow, // isCallable, call progress ready
    isRinging,
  };
};

export default useLiveCallMonitor;
