/**
 * Render an embedded audio element with controls
 */

import React, { useState, useEffect, useRef } from 'react';
import corsTestUtils from './corsTestUtils'

// ref.: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/audio
export default function AudioElementPlayer({ src, logCallback }) {
    const fileAudioRef = useRef()
    const utils = corsTestUtils({ logCallback })

    // initialize and cleanup Audio() objects
    useEffect(() => {
        utils.audioElInit(fileAudioRef.current, "fileAudioEmbed")
        fileAudioRef.current.addEventListener('play', onPlay)
        return (() => {
            utils.audioElCleanup(fileAudioRef.current)
            fileAudioRef?.current && fileAudioRef.current.removeEventListener('play', onPlay)
        })
    }, [])


    const onPlay = () => {
        utils.log(`AudioElementPlayer playing ${fileAudioRef.current.src}`)
    }

    // apply new src attribute
    useEffect(() => {
        src && (fileAudioRef.current.src = src)
        //src && (fileAudioRef.current.src = 'https://hvn.hotlynx.com//sites/default/files/2022-05/1653853806754.mp3')
        utils.log("AudioElementPlayer:", src)
    }, [src])

    // render using <audio /> tag
    return <audio ref={fileAudioRef} controls />
}

