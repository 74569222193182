/**
 * LiveCallInitiatorButton
 * Action button to initiate a LiveCall to a specified user.
 *
 */

import React from 'react';
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';
import useLiveCallMonitor from 'hooks/useLiveCallMonitor';

const LiveCallInitiatorButton = ({ message }) => {
  const { isCallable, canCallNow, isRinging } = useLiveCallMonitor({
    remoteUserId: message.userId,
  });
  const actionsProps = {
    message,
    btnList: ['call'],
    legends: false,
    justify: 'flex-start',
    disabled: !canCallNow,
  };

  return isCallable ? <PlayerActions {...actionsProps} /> : null;
};
export default LiveCallInitiatorButton;
