/**
 * Send a DM to a non-member
 * message.type 'outgoing'
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Grid } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';
import MessageRecorderDialog from 'containers/MessagePlayer/components/PlayerActions/components/MessageRecorderDialog';

/*
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';
import MicIcon from '@material-ui/icons/Mic';
*/

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function DmNonMemberFAB() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Fab
            style={{ color: 'white' }}
            onClick={handleDialogOpen}
            color={'primary'}
            aria-label="add"
            size="small"
          >
            <MicIcon />
          </Fab>
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          Non
          <br />
          Member
        </Grid>
      </Grid>
      <MessageRecorderDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        typeOverride="outgoing"
        bannerOverride="Message to Non-Member"
      />
    </>
  );
}
/*
      <Fab variant="extended" size= "small">
        <MicIcon className={classes.extendedIcon} />
        Create
      </Fab>

*/
DmNonMemberFAB.propTypes = {
  dm: PropTypes.bool,
};
DmNonMemberFAB.defaultProps = {
  dm: false,
};
