/**
 * SnackbarAlert
 * Renders an alert with specified content whthin a Snackbar
 *
 * Omissions:
 * - fails to dismount when transition is employed, however it's size becomes zero when content is null
 * - position is fixed, so multiple concurrent instances will overwrite. Should be a singleton
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Slide } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  snackbar: {
    bottom: 70,
  },
  snackbarClosed: {
    width: 0,
    height: 0,
  },
  alert: {
    backgroundColor: '#303030',
  },
}));

const SnackbarAlert = ({ content, onClose }) => {
  const classes = useStyles();
  const open = Boolean(content);

  return (
    <Snackbar
      open={open}
      classes={{ root: classes.snackbar }}
      TransitionComponent={props => <Slide {...props} />}
      transitionDuration={1000}
      TransitionProps={{
        direction: 'right',
        mountOnEnter: true,
        dismountOnExit: true,
      }}
    >
      <MuiAlert
        classes={{ root: classes.alert }}
        icon={false}
        elevation={6}
        variant="outlined"
        severity="info"
        onClose={onClose}
      >
        {content}
      </MuiAlert>
    </Snackbar>
  );
};
SnackbarAlert.propTypes = {
  content: PropTypes.node,
  onClose: PropTypes.func,
};
Snackbar.defalutProps = {
  content: null,
  onClose: null,
};
export default memo(SnackbarAlert);
