// English phrase translation map

const fr_trans = {
  T$_Country: 'Pays',
  T$_About_You: 'À propos de vous',
  T$_Accept: 'Acceptez',
  T$_Accept_all_Terms_and_Conditions: 'Acceptez tous les termes et conditions',
  T$_Accept_Call: "Accepter l'appel",
  T$_Acknowledge: 'Acceptez',
  T$_Advertisement: 'Publicité',
  T$_Age: 'Âge',
  T$_Age_Range: "Tranche d'âge",
  T$_Approaching_maximum_record_time: 'Approchant du temps record maximal !',
  T$_Attributes: 'Les attributs',
  T$_Authenticating: 'Authentifiant …',
  T$_Authentication_Method: "Méthode d'authentification",
  T$_authentication_Page: "Page d'authentification",
  T$_bad_sound_quality: 'Qualité de son mauvaise',
  T$_Beta_Test: 'Test beta',
  T$_blob: 'blob',
  T$_Block: 'Bloc',
  T$_BLOCK: 'BLOC',
  T$_boring: 'ennuyeuse',
  T$_Call: 'Appel',
  T$_Cancel: 'Annuler',
  T$_Cancel_Call: "Annuler l'appel",
  T$_Carousel: 'Carrousel',
  T$_character_limit: 'limite de caractères.',
  T$_checked: 'vérifié',
  T$_Choose_a_screen_name: "Choisissez un nom d'écran",
  T$_Clear_Filters: 'Effacer les filtres',
  T$_close: 'proche',
  T$_Close: 'Fermer',
  T$_Confirm_Password: 'Confirmez le mot de passe',
  T$_Congratulations_NEW_MEMBER:
    ' Félicitations NOUVEAU MEMBRE ! Vous pouvez maintenant créer votre message.',
  T$_Connect: 'Connecter',
  T$_Connected: 'Connecté',
  T$_Contact_and_Support_Request: 'Demander le Soutien',
  T$_controls: 'les contrôles',
  T$_Copy_Link: 'Copier le lien',
  T$_Create_OkSayit_Password: 'Créer le mot de passe OkSayit',
  T$_Create_Password: 'Créer un mot de passe',
  T$_Create_Username: "Créer le nom d'utilisateur",
  T$_create_User: "Créer l'utilisateur",
  T$_Decline: 'Refuser',
  T$_Decline_Call: "Refuser l'appel",
  T$_decrement: 'décrémenter',
  T$_defaults: 'valeurs par défaut',
  T$_Delete: 'Supprimer',
  T$_DELETE: 'SUPPRIMER',
  T$_Direct_Message: 'Message direct',
  T$_Dismiss: 'Rejeter',
  T$_Email: 'E-mail',
  T$_Enable: 'Activer',
  T$_Ended: 'Terminé',
  T$_Enter_Your_Code: 'Entrez votre code',
  T$_Error: 'Erreur!',
  T$_example: 'Exemple',
  T$_Exit: 'Sortir',
  T$_file: 'fichier',
  T$_Filters: 'Les filtres',
  T$_First_Time_Registration: 'Première inscription',
  T$_Francophone_Anglophone_Conversation_Exchange:
    'Échange de conversation français-anglais',
  T$_gender: 'sexe',
  T$_Gender: 'Sexe',
  T$_getAttributes: 'obtenir des attributs',
  T$_Get_Invitation: 'Obtenir une invitation',
  T$_Great_your_phone_is_verified: 'Super, votre téléphone est vérifié !',
  T$_Hangup: 'Raccrocher',
  T$_I_agree_to_the_terms_and_conditions: "J'accepte les termes et conditions",
  T$_increase: 'augmenter',
  T$_increment: 'incrément',
  T$_Info: 'Info',
  T$_Initial_Registration: 'Inscription initiale',
  T$_Inquiry: 'enquête',
  T$_invitation: 'invitation',
  T$_Invitation: 'Invitation',
  T$_Invitation_Code: "code d'invitation",
  T$_It_should_only_take_a_few_seconds:
    'Cela ne devrait prendre que quelques secondes.',
  T$_Join: 'Rejoindre',
  T$_Language: 'Langue',
  T$_Like: "j'aime",
  T$_lines: 'Les lignes',
  T$_Live_Call_Invitation: 'Invitation à un appel en direct',
  T$_Login: 'Connecter ',
  T$_Login_RegisterMessage_Type: "Connecter / S'inscrire",
  T$_Login_Now: 'Connectez maintenant',
  T$_Logout: 'Se déconnecter',
  T$_Main_Menu: 'Menu principal',
  T$_Meetup_username: "Nom d'utilisateur Meetup",
  T$_Meetup_Username: "Nom d'utilisateur Meetup",
  T$_message: 'message',
  T$_Message_Notification: 'Avis de messages',
  T$_Message_Type: 'Type de message',
  T$_The_recorder_will_start_automatically_next_time:
    "L'enregistreur démarrera automatiquement la prochaine fois.",
  T$_More: 'Plus',
  T$_More_Info: "Plus d'informations",
  T$_My_Profile: 'Mon profil',
  T$_Native_Language: 'Langue maternelle',
  T$_Next: 'Prochain',
  T$_Notification: 'Notification',
  T$_now: 'à présent',
  T$_OK: "D'accord",
  T$_OkSayit_password: 'OkSayit mot de passe',
  T$_open: 'ouvert',
  T$_Open: 'Ouvert',
  T$_other_inappropriate_content: 'autre contenu inapproprié',
  T$_Partner_Preference: 'Préférence de partenaire',
  T$_Password: 'Mot de passe',
  T$_Pause: 'Pause',
  T$_Phone: 'Téléphoner',
  T$_Play: 'Joue',
  T$_Playlist: 'Playlist',
  T$_position: 'position',
  T$_Previous: 'Précédent',
  T$_Profile_for: 'Profil pour',
  T$_Profile_Recording: 'Enregistrement vocal de profil',
  T$_progress: 'le progrès',
  T$_Public_Comment: 'Commentaire public',
  T$_Public_Post: 'Publication publique',
  T$_Public_Screen_Name: 'Pseudonyme public',
  T$_Record: 'Enregistreur',
  T$_Recorder_Start: "Démarrage de l'enregistreur",
  T$_Register: "S'inscrire",
  T$_Regrets: 'Regrets',
  T$_Regrets_Message: 'Message de regret',
  T$_Request: 'Demande',
  T$_Residency: 'Résidence',
  T$_Respond: 'Répondre',
  T$_Returning_User: 'Utilisateur retour',
  T$_Review: 'Examen',
  T$_Ringing: 'Préparer',
  T$_rude: 'Impoli',
  T$_Screen_Name: "Nom d'écran",
  T$_Select_method: 'Sélectionnez la méthode',
  T$_Sending: 'Envoi en cours...',
  T$_Sent_cart_data: 'Données de panier envoyées !',
  T$_Setting_up_your_account: 'Configuration de votre compte',
  T$_sexual_content: 'contenu sexuel',
  T$_Share: 'Partage',
  T$_Skill: 'Compétence',
  T$_Skill_Level: 'Niveau de compétence',
  T$_Stop: 'Arrêt',
  T$_Submit: 'Soumettre',
  T$_Success: 'Succès...',
  T$_Suggestion: 'Suggestion',
  T$_Support_Request: 'Demande de soutien',
  T$_Terms_and_Conditions: 'Termes et conditions',
  T$_test: 'test',
  T$_Thank_you_for_regestering_you_are_logged_in:
    'Je vous remerci pour votre inscription, vous êtes connecté !',
  T$_There_is_a: 'Il y a un',
  T$_There_was_a_problem_saving_your_recording:
    'Un problème est survenu lors de la sauvegarde de votre enregistrement.',
  T$_This_is_an_alert: 'Ceci est une alerte ',
  T$_Tip: 'Conseil',
  T$_title: 'Titre',
  T$_toggle: 'basculer',
  T$_Toggle_Counter: 'Basculer le compteur',
  T$_Topic: 'Sujet',
  T$_unMark: 'décocher',
  T$_User_ID: "Identifiant d'utilisateur",
  T$_Username: "Nom d'utilisateur",
  T$_Validate: 'Valider',
  T$_Visit: 'Visiter',
  T$_visualizer: 'visualiseur',
  T$_Voice_Profile: 'Profil vocal',
  T$_We_are_texting_a_code_to: 'Nous envoyons un code par SMS à',
  T$_You_are_logged_in: 'Vous êtes connecté !',
  T$_Your_invitation_is_approved: 'Votre invitation est approuvée.',
  T$_Your_Message_is_cancelled: 'Votre message est annulé.',
  T$_Your_name_cannot_include: 'Votre nom ne peut pas inclure',
  T$_Your_Primary_Language: 'Votre langue principale',
  T$_Your_Public_Identification: 'Votre identification publique',
  T$_Your_recording_has_been_downloaded:
    'Votre enregistrement a été télécharger',
  T$_Your_recording_is_cancelled: 'Votre enregistrement est annulé.',
  T$_Your_recording_is_short: 'Votre enregistrement vocal est court!',
  T$_Your_Voice_Profile_for_this_Community:
    'Votre profil vocal pour cette communauté',
  T$_Subject: 'Sujet',
  T$_About_Yourself: 'À propos de toi',
  T$_Message_Title: 'Titre du message',
  T$_Enter_a_Headline_about_you: 'Saisissez un titre vous concernant',
  T$_Please_enter_a_Headline_about_you:
    'Veuillez saisir un titre vous concernant',
  T$_Please_enter_a: 'Veuillez entrer un',
  T$_Creating_a_Message: 'Créer un message vocal',
  T$_Direct: 'Direct',
  T$_Live_Call: 'Appel en direct',
  T$_Profile: 'Profil',
  T$_Message: 'Message',

  T$_Accept_this_invitation_to_talk_live:
    'Acceptez cette invitation à parler en direct.',
  T$_Bookmark_the_author_of_this_message:
    "Ajoutez l'auteur de ce message à vos favoris.",
  T$_Cancel_this_action: 'Annulez cette action.',
  T$_Commence_playback: 'Commencer Jouer ',
  T$_Decline_this_invitation_to_talk_live:
    'Refuser cette invitation à parler en direct.',
  T$_Delete_this_message: 'Supprimez ce message.',
  T$_End_this_live_conversation: 'Mettez fin à cette conversation en direct.',
  T$_Invite_this_user_to_talk_live:
    'Invitez cet utilisateur à parler en direct.',
  T$_More_Actions: "Plus d'actions.",
  T$_Obtain_a_link_to_this_message_that_can_be_pasted_to_social_media:
    'Obtenez un lien vers ce message qui peut être collé sur les réseaux sociaux.',
  T$_Pause_playback: 'Interrompre la lecture.',
  T$_Record_a_voice_response_to_decline_this_invitation_to_talk_live:
    'Enregistrez une réponse vocale pour décliner cette invitation à parler en direct.',
  T$_Record_a_voice_response_to_this_message:
    'Enregistrez une réponse vocale à ce message.',
  T$_Remove_bookmark: 'Supprimer le signet.',
  T$_Show_this_information_dialog:
    "Afficher cette boîte de dialogue d'informations.",
  T$_spacer: 'entretoise',
  T$_Block_all_messages_from_this_person:
    'Bloquer tous les messages de cette personne.',

  T$_Comment: 'Commentaire',
  T$_Create_Your_Community_Profile_for: 'Créez votre profil communautaire pour',
  T$_Public: 'Publique',
  T$_Save: 'gardez ',
  T$_Send: 'Envoyer',
  T$_to: 'à',
  T$_Creating_a: 'Créer un',
  T$_READY: 'PRÊT',
  T$_Message_Recorder: 'Enregistreur de messages',
  T$_Download: 'Télécharger',
  T$_Please_login_to_save_your_message:
    'Veuillez vous connecter pour enregistrer votre message',
  T$_Please_record_at_least: 'Veuillez enregistrer au moins',
  T$_sec: 'secondes',
  T$_cannot_be_created: 'ne peut pas être créé',
  T$_You_must_Login_to_save_this_message:
    'Vous devez vous connecter pour enregistrer ce message',
  T$_Problems: 'Problèmes',
  T$_Message_Player: 'Écouteur de messages',

  T$_Action_Options: 'Les actions',
  T$_The_following_actions_are_available_for_this_message:
    'Les actions suivantes sont disponibles pour ce message',
  T$_not_all_options_are_available_for_every_message:
    'toutes les options ne sont pas disponibles pour chaque message',
  T$_Browse: 'Parcourir',
  T$_Discuss: 'Discuter',
  T$_Listen: 'Ecoutez',
  T$_Members: 'Membres',
  T$_Settings: 'Contrôles',
  T$_Talk: 'Parler',

  T$_What_is_your_skill_level_in_the_language_you_wish_to_learn:
    'Quel est votre niveau de compétence dans la langue que vous souhaitez apprendre ?',
  T$_What_is_your_age_range: "Quelle est votre classe d'âge?",
  T$_What_is_your_gender: 'Quel est votre sexe ?',
  T$_No_Preference: 'Pas de préférence',
  T$_profile: 'profil',
  T$_Advanced: 'Avancé',
  T$_Intermediate: 'Intermédiaire',
  T$_Beginner: 'Débutant',
  T$_Over_40: 'Plus de 40',
  T$_Under_40: 'Moins de 40 ans',
  T$_Female: 'Féminin',
  T$_Male: 'Masculin',
  T$_Anglophone: 'anglophone',
  T$_Francophone: 'francophone',
  T$_sms: 'SMS',
  T$_password: 'mot de passe',
  T$_google: 'google',
  T$_facebook: 'facebook',
  T$_My_Memberships: 'Mes adhésions',
  T$_Your_Memberships: 'Vos adhésions',
  T$_Profile_For: 'Profil pour',
  T$_Confirm: 'Confirmer',
  T$_Community_Profile_Recording: 'Enregistrement vocal du profil',

  T$_Login_with_Google: "S'identifier avec Google",
  T$_Login_with_Facebook: "S'identifier avec Facebook",
  T$_Login_with_Email: "S'identifier avec e-mail",
  T$_Login_with_Phone: "S'identifier avec le téléphone",
  T$_Username_Password: 'Identifiant Mot de passe',
  T$_Login: "S'identifier",
  T$_Setting_Up: 'Mise en place...',
  T$_Awaiting_Google: 'En attente de Google',
  T$_Please_Record: 'Veuillez créer',
  T$_Community_Members: 'Membres de la communauté',

  T$_from: 'de',
  T$_by: 'par',
  T$_post: 'message',
  T$_tip: 'conseil',
  T$_ad: 'publicité',
  T$_notice: 'notification',
  T$_comment: 'commentaire',
  T$_years: 'années',
  T$_months: 'mois',
  T$_days: 'journées',
  T$_hours: 'heures',
  T$_minutes: 'minutes',
  T$_seconds: 'secondes',
  T$_ago: 'il y a',
  T$_Delete_This_Message: 'Supprimer ce message',
  T$_This_message: 'Ce message',
  T$_and_all_of_its_responses: 'et toutes ses réponses ',
  T$_will_be_permanently_deleted: 'seront définitivement supprimées!',
  T$_You_cannot_delete_this_message: 'Vous ne pouvez pas supprimer ce message',
  T$_Personal: 'Personnel',
  T$_no_messages: 'aucun message',
  T$_in_this_category: 'dans cette catégorie',

  T$_Share_This_Message: 'Partager ce message',
  T$_Use_the_button_to_copy_a_link_to_this_message:
    'Utilisez le bouton pour copier un lien vers ce message',
  T$_Paste_it_to_social_media_or_email:
    'Collez-le sur les réseaux sociaux ou par e-mail',
  T$_Bookmarking: 'Marquer comme favori',
  T$_Bookmarked_members_will_appear_with_a:
    'Les membres favoris apparaîtront avec un',
  T$_Blocking: 'Blocage',
  T$_because: 'car',
  T$_You_will_no_longer_hear_messages_from:
    "Vous n'entendrez plus les messages de",
  T$_Information: 'Informations',
  T$_Indicate_that_you_like_the_author_of_this_message:
    "Indiquez que vous aimez l'auteur de ce message",
  T$_Block_all_messages_from_this_author:
    'Bloquer tous les messages de cet auteur',
  T$_Account: 'Compte',
  T$_Preferences: 'Préférences',
  T$_Available_Communities: 'Communautés disponibles',
  T$_Switch: 'Changer',
  T$_General_Group: 'Groupe général',
  T$_Language_Group: 'Groupe linguistique',
  T$_Local_Interest_Group: "Groupe d'intérêt local",
  T$_Cannot_Bookmark: "Le signet n'est pas possible",
  T$_You_must_be_logged_in_to_set_a_bookmark:
    'Vous devez être connecté pour créer un signet !',
  T$_You_must_be_logged_in_to_block_a_user:
    'Vous devez être connecté pour bloquer un utilisateur',
  T$_This_user_is_already_bookmarked:
    "Cet utilisateur est déjà marqué d'un signet",
  T$_You_cannot_apply_a_bookmark_to_yourself:
    'No puedes aplicarte un marcador a ti mismo',
  T$_You_cannot_block_yourself: 'No puedes bloquearte',
  T$_This_user_is_already_blocked: 'Este usuario ya está bloqueado',
  T$_Please_select_the_reason_for_blocking:
    'Seleccione el motivo del bloqueGroupo',

  T$_bookmarked: 'marcado',
  T$_blocked: 'obstruido',
  T$_Cannot_Block: 'Impossible de bloquer',
  T$_Applying_Bookmark: 'Le signet est appliqué',
  T$_Updating_Blacklist: 'La liste noire est mise à jour',
  T$_Top: 'Haut',
  T$_Bottom: 'Fond',
  T$_Display_Language: "Langue d'affichage",
  T$_English: 'Anglais',
  T$_Spanish: 'Espagnol',
  T$_French: 'Français',
  T$_Lithuanian: 'lituanien',
  T$_What_gender_do_you_want_to_speak_with:
    'Avec quel sexe voulez-vous parler ?',

  T$_This_device_has_been_logged_out_because_you_have_logged_in_at_another_location:
    'Cet appareil a été déconnecté car vous vous êtes connecté à un autre endroit.',
  T$_Saving_your_message: 'Téléchargement de votre message...',
  T$_Message_Saved: 'Message téléchargé dans le cloud',
  T$_Saved: 'téléchargé dans le cloud',
  T$_Sent: 'Expédié',

  T$_It_will_appear_in_XXX_playlist:
    "Il apparaîtra dans la liste de lecture de XXX. Vous pouvez également copier un lien et l'envoyer par e-mail à XXX si vous le souhaitez --- Utilisez simplement le bouton ci-dessous pour obtenir un lien.",

  T$_It_will_appear_in_the_public_playlist:
    'Il apparaîtra dans la liste de lecture publique. ',
  T$_You_can_also_copy_and_paste_it_to_your_social_media_just_use_the_button_below_to_get_a_link:
    'Vous pouvez également le copier et le coller sur vos réseaux sociaux, utilisez simplement le bouton ci-dessous pour obtenir un lien.',

  T$_Your: 'Votre',
  T$_has_been: 'a été',
  //    T$_has_been: "ha sido",
  T$_It_will_be_delivered_to_management: 'Il sera remis à la direction.',

  T$_You_have_blocked_use_of_your_microphone:
    "Vous avez bloqué l'utilisation de votre microphone.",
  T$_Your_browser_is_blocking_use_of_your_microphone_Use_your_browser_Settings_to_correct_this_issue:
    "Votre navigateur bloque l'utilisation de votre microphone. Utilisez les paramètres de votre navigateur pour corriger ce problème.",
  T$_Please_try_again_Approve_use_of_your_microphone_to_continue:
    "Veuillez réessayer. Approuvez l'utilisation de votre microphone pour continuer.",
  T$_There_was_a_problem_obtaining_permission_to_use_your_microphone:
    "Un problème est survenu lors de l'obtention de l'autorisation d'utiliser votre microphone.",
  T$_Processing_Community_membership:
    "Traitement de l'adhésion à la communauté...",
  T$_Your_Contact_Info: 'Vos coordonnées',
  T$_Please_Join_This_Community_In_Order_To_Save_Your_Message:
    'Veuillez rejoindre cette communauté afin de sauvegarder votre message !',
  T$_There_is_a: 'Il y a une limite de',
  T$_character_limit: 'caractères',
  T$_auto_start: 'démarrage automatique',
  T$_Auto_Start: 'Démarrage automatique',
  T$_Live_Call_invitation_from: 'Invitation à un Appel en direct de',
  T$_You_can_connect_now: "Vous pouvez connecter l'appel maintenant",
  T$_Call_Time: "Temps d'appel",
  T$_PROBLEM: 'PROBLÈME',
  T$_accepted: 'accepté',
  T$_Calling: 'Passer un appel',
  T$_Invite_from: 'Inviter de',
  T$_Live_Call_Invites: 'Invitations à des appels en direct',
  T$_Live_Call_with: 'Appel en direct avec',
  T$_to_accept: 'accepte...',
  T$_has_accepted_your_call: 'a accepté votre appel.',
  T$_Regrets_Message: 'Message de regret :',
  T$_declined_your_invitation: 'décliné votre invitation.',
  T$_This_invitation_cannot_go_through_right_now:
    'Cette invitation ne peut pas être acceptée pour le moment.',
  T$_Your_party_may_no_longer_be_available:
    "Votre personne n'est peut-être plus disponible.",
  T$_Invitation_Message: "Message d'invitation :",
  T$_You_have_accepted_this_call: 'Vous avez accepté cet appel.',
  T$_Waiting_for: 'Attendant que ',
  T$_to_connect: 'se connecte...',
  T$_Your_call_has_ended: 'Votre appel est terminé.',
  T$_was_calling_you: "essayé de t'appeler...",
  T$_This_call_cannot_connect_right_now:
    'Cet appel ne peut pas se connecter pour le moment.',
  T$_Live_Call_Invitations: 'Invitations à des appels en direct',
  T$_Live_Call_Status: "Statut d'appel en direct",
  T$_This_call_is_cancelled: 'Cet appel est annulé...',

  T$_Your_device_disconnected: "Votre appareil s'est déconnecté.",
  T$_XXXs_device_disconnected: "L'appareil de XXX s'est déconnecté.",
  T$_Live_Call_accepted_by: 'Appel en direct accepté par',
  T$_Live_Call_declined_by: 'Appel en direct refusé par',
  T$_inquiry_to_ad: "réponse à l'annonce",
  T$_You_have_received_a_Live_Call_invitation:
    'Vous avez reçu une invitation à un appel en direct.',
  T$_A_new_message_has_arrived_Just_for_you:
    'Un nouveau message est arrivé "Juste pour vous".',
  T$_Inbox: 'Entrant',
  T$_Chat: 'Discuter',
  T$_Less: 'Moins ',
  T$_This_Community: 'Cette communauté',

  T$_Adult: 'Adulte',
  T$_Available_to_Registered_Users:
    'Disponible pour les utilisateurs enregistrés',
  T$_Best_practices_get_the_most_out_of_the_Service:
    'Les meilleures pratiques permettent de tirer le meilleur parti du service',
  T$_Business: 'Entreprise',
  T$_Community_Selection: 'Sélection communautaire',
  T$_Connect_with_like_minded_strangers:
    'Connectez-vous avec des étrangers partageant les mêmes idées',
  T$_Features: 'Fonctionnalités',
  T$_General: 'Général',
  T$_Language_Practice_EN_DE: 'Pratique de la langue - EN / DE',
  T$_Language_Practice_EN_LT: 'Pratique de la langue - EN / LT',
  T$_Lexington_Residency: 'Résidence Lexington',
  T$_Lexington_Senior_Meetingplace:
    'Lieu de rencontre des seniors de Lexington',
  T$_Local_Interest: 'Intérêt local',
  T$_Member_List: 'Liste des membres',
  T$_Other: 'Autre',
  T$_Overview: 'Sommaire',
  T$_Practice_your_language_skills: 'Pratiquez vos compétences linguistiques',
  T$_Recommendations: 'Recommandations',
  T$_Resident: 'Résident',
  T$_Senior: 'Senior',
  T$_To_get_the_most_out_of_the_Service:
    'Pour tirer le meilleur parti du service',
  T$_Visitor: 'Visiteur',
  T$_Welcome_To_OkSayit: 'Bienvenue sur OkSayit',
  T$_Youth: 'Jeunesse',

  T$_Communities: 'Communautés',
  T$_Inbox_for: 'Boîte de réception pour',
  T$_Empty: 'Vide',
  T$_From: 'De',
  T$_Your_Messages: 'Tes messages',
  T$_General_Chat_Group: 'Groupe de discuter général',
  T$_SMS_Text_Code: 'Obtenir le SMS',
  T$_Enter_your_MOBILE_number: 'Entrez votre numéro de mobile',
  T$_Mobile_phones_only:
    "Téléphones portables uniquement.Des frais de téléphonie mobile peuvent s'appliquer.",
  T$_Enter_the_code_we_sent_to: 'Entrez le code que nous avons envoyé à',
  T$_Incorrect_Validation_Code: 'Code incorrect !',
  T$_Please_check_the_code_we_sent:
    'Veuillez vérifier le code que nous avons envoyé et entrez-le ici soigneusement.',
  T$_Logging_in_with_mobile_text: 'Se connecter avec le texte mobile',
  T$_Your_okSayit_account_verification_code_is:
    'Votre code de vérification de compte okSayit est',
  T$_is_not_a_valid_phone_number: "n'est pas un numéro de téléphone valide !",
  T$_Please_enter_a_valid_phone_number:
    "S'il vous plaît entrer un numéro de téléphone valide.",
  T$_is_not_a_US_or_Canada_phone_number:
    "n'est pas un numéro de téléphone américain ou canadien !",
  T$_Please_enter_a_US_or_Canada_phone_number:
    'Veuillez saisir un numéro de téléphone américain ou canadien.',
  T$_is_not_a_mobile_phone_in_France:
    "n'est pas un téléphone portable en France !",
  T$_Please_enter_a_mobile_number_in_France:
    'Veuillez entrer un numéro de portable en France.',
  T$_About_Me: 'À propos de moi',
  T$_For_Each_Membership_You_Can_Adjust_Your_Profile:
    'Pour chaque adhésion, vous pouvez ajuster votre profil',
  T$_Logging_Out: 'Déconnecter...',
  T$_Welcome: 'Bienvenue',
  T$_Update_Your_Profile_Recording: 'Mettez à jour votre message de profil',

  // retired helpers
  T$_What_age_range_do_you_want_to_speak_with:
    "À quelle tranche d'âge souhaitez-vous parler ?",
  T$_What_skill_level_do_you_want_to_speak_with_Check_all_that_apply:
    'Avec quel niveau de compétence voulez-vous parler ? (Cochez les cases appropriées)',
  T$_What_languages_do_you_want_to_speak_with_Check_all_that_apply:
    'Dans quelles langues voulez-vous parler ? (Cochez toutes les cases)',
  T$_What_residency_do_you_want_to_speak_with_Check_all_that_apply:
    'Avec quel quartier voulez-vous parler ? (Cochez toutes les cases)',

  T$_Member_Gender: 'Sexe du membre',
  T$_Member_Age_Range: "Tranche d'âge des membres",
  T$_Member_Language: 'Langue du membre',
  T$_Member_Native_Language: 'Langue maternelle du membre',
  T$_Member_Residency: 'Résidence du membre',
  T$_Member_Skill_in_Second_Language:
    'Compétence des membres en langue seconde',
  T$_Mute: 'Désactiver',
  T$_Send_a_direct_message_to_this_member:
    'Envoyez un message direct à ce membre.',
  T$_No_Available_Communities: 'Aucune communauté disponible',

  T$_Leave_This_Community: 'Quitter cette communauté',
  T$_If_you_leave:
    'Si vous partez, votre profil et tous vos messages dans cette communauté seront effacés !',
  T$_Stay: 'Restez',
  T$_Leave: 'Partir',
  T$_Suppress_echo: 'Echo unterdrücken',
  T$_You_are_calling: 'Vous appelez',
  T$_is_calling_you: 'vous appelle',
  T$_Hide: 'Masquer',
  T$_You: 'Vous',
  T$_hung_up: 'avez raccroché.',
  T$_cancelled_this_call: 'avez annulé cet appel.',
  T$_declined_this_invitation: 'avez décliné cette invitation.',
  T$_Text_Invitation: 'Invitation par texte',
  T$_Send_Text_Invitation: 'Envoyer invitation par texte',
  T$_Send_Voice_Invitation: 'Envoyer avec la voix',
  T$_Bookmark_this_person: 'Ajoutez cette personne à vos favoris',
  T$_public: 'public',
  T$_direct: 'direct',
  T$_foul_language: 'langage grossier',
  T$_Public_Posts: 'Messages publics',

  T$_Member_Profile_Information: 'Informations sur le profil du membre',
  T$_language: 'langue',
  T$_ageRange: "Tranche d'âge",
  T$_skill: 'compétence',
  T$_Logging_in_with_Facebook: 'Se connecter avec Facebook',
  T$_Facebook_login_not_available: 'Connexion Facebook non disponible',
  T$_Facebook_login_cannot_connect:
    'La connexion Facebook ne peut pas se connecter',
  T$_Facebook_dialog_closed_by_user:
    "Boîte de dialogue Facebook fermée par l'utilisateur",
  T$_Facebook_login_failed: 'La connexion Facebook a échoué',
  T$_Awaiting_Facebook: 'En attente Facebook',
  T$_Logging_in_with_Google: 'Se connecter avec Google',
  T$_Profile_not_available: 'Profil non disponible',
  T$_No_profile: 'Aucun profil',
  T$_Google_dialog_closed_by_user:
    "Boîte de dialogue Google fermée par l'utilisateur",
  T$_Google_popup_blocked: 'Popup Google bloqué',
  T$_Authentication_Problem: "Problème d'authentification",
  T$_Registration: 'Inscription',
  T$_You_Cant_Learn_To_Speak:
    'Vous ne pouvez pas apprendre à parler sans parler !',

  T$_Authenticating_now: 'Authentification maintenant...',
  T$_Carrier_charges_may_apply: "Des frais de téléphone peuvent s'appliquer.",
  T$_Daily_Limit: 'Limite journalière',
  T$_Direct_DM: 'Directe (DM)',
  T$_Direct_Reply: 'Réponse directe',
  T$_Disable: 'Désactiver',
  T$_Disabled: 'Désactivé',
  T$_Enabled: 'Activé',
  T$_from_your_mobile_phone: 'depuis votre téléphone portable.',
  T$_German: 'Allemand',
  T$_Logging_you_in: 'Vous connecter...',
  T$_Messages_will_be_sent_from_the_number:
    'Les messages seront envoyés à partir du numéro',
  T$_Method: 'Méthode',
  T$_Mobile_Phone: 'Téléphone mobile',
  T$_Notifications_Settings: 'Paramètres de notifications',
  T$_Please_Accept_Cookies_Before_Continuing:
    'Veuillez accepter les cookies avant de continuer !',
  T$_Please_Select: 'Veuillez sélectionner',
  T$_Please_Wait: "S'il vous plaît, attendez",
  T$_Public_Response: 'Réponse publique',
  T$_Receive_Notifications_When_Messages_Arrive_For_You:
    'Recevez des notifications lorsque des messages arrivent pour vous',
  T$_Record_a_new_message: 'Enregistrez un nouveau message.',
  T$_Reply: 'Répondre',
  T$_Required: 'Requis',
  T$_Respond_to_this_message: 'Répondez à ce message.',
  T$_Sending: 'Envoi en cours',
  T$_Status: 'Statut',
  T$_Stopped: 'Arrêté',
  T$_Text: 'Texte',
  T$_START: 'COMMENCER',
  T$_To_enable_Notifications: 'Pour activer les notifications',
  T$_Updating_your_preferences: 'Mise à jour de vos préférences...',
  T$_When_you_enable_Notifications: 'Lorsque vous activez les notifications :',
  T$_You_can_revoke_approval:
    "Vous pouvez révoquer l'approbation à tout moment en utilisant ce formulaire ou en répondant 'Stop' à toute notification.",
  T$_You_give_approval_for: 'Vous donnez votre accord pour',
  T$_to_send_notification_messages_to_your_phone:
    'pour envoyer des messages de notification à votre téléphone.',
  T$_Maybe_Later: 'Peut-être plus tard',
  T$_Stop_Asking: 'Arrête de demander',
  T$_Notifications: 'Notifications',

  T$_Direct_Messages: 'Messages directs',
  T$_Inbox_View: 'Affichage de la boîte de réception',
  T$_Side: 'Côté',
  T$_Recorder: 'Enregistreur',
  T$_Player: 'Joueur',
  T$_Retrieving_Messages: 'Récupération des messages...',
  T$_You_Must_Login_To_See_Your_Messages:
    'Vous devez vous connecter pour voir vos messages',
  T$_Dont_Show_Again: 'Ne plus afficher',
  T$_It_is_also_in_your_clipboard: 'Il est également dans votre presse-papiers',
  T$_Your_message_will_be_delivered_to_management:
    'Votre message sera transmis à la direction',
  T$_invitation: 'invitation',
  T$_message: 'message',
  T$_Message: 'Message',
  T$_Saved: 'Enregistré',
  T$_Sent: 'Envoyé',
  T$_Your: 'Ton',
  T$_has_been: 'a été',
  T$_You_can: 'Tu peux',
  T$_paste_it: 'collez-le',
  T$_to_email: 'dans le courrier électronique',
  T$_to_social_media_or_email: 'aux réseaux sociaux ou par e-mail',
  T$_Sleeping: 'Dormant',
  T$_Wake_Up: 'Réveillez-vous',
  T$_Delete_Your_Archive: 'Supprimer vos archives',
  T$_All_Messages_In_This_List: 'Tous les messages de cette liste',
  T$_New: 'Nouveau',
  T$_Archive: 'Archive',
  T$_latest: 'le plus récent',
  T$_No_Message_Specified: 'Aucun message spécifié',
  T$_Sorry_cannot_find_that_message:
    'Désolé, je ne trouve pas ce message. Il a peut-être expiré ou il a peut-être été supprimé.',
  T$_Access_denied: 'Accès refusé, ce message ne vous est pas adressé',
  T$_This_message_is_addressed_to:
    "Vous n'êtes pas connecté. Ce message est adressé à",
  T$_If_you_are: 'Si vous êtes',
  T$_please_login_to_play_it: 'veuillez vous connecter pour y jouer',
  T$_Message_Link_Copied_to_Clipboard:
    'Lien de message copié dans le presse-papiers',
  T$_Set_Up: 'Configurer',
  T$_Problem: 'Problème',

  T$_is_busy_cant_speak: 'est occupé et ne peut pas parler maintenant.',
  T$_Start_recording_immediately:
    "Commencez l'enregistrement immédiatement lorsque l'enregistreur est ouvert.",
  T$_Start_playback_immediately:
    "Démarrez la lecture audio immédiatement lorsqu'un élément est sélectionné.",
  T$_Connect_immediately:
    "Connectez-vous immédiatement à la voix lorsqu'une personne accepte votre invitation.",
  T$_Not_Available: 'Pas disponible',
  T$_Subscribed: 'souscrit',
  T$_You_will_be_notified_of_DM:
    'Vous serez averti lorsque des messages directs arriveront pour vous.',
  T$_By_pressing_next_you_agree:
    'En appuyant sur SUIVANT, vous acceptez de recevoir un message texte unique avec le code de vérification',
};

export default fr_trans;
