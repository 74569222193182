/**
 * Configuration common to various partitions
 *
 * Invoke function 'generateJson' to create data for community field_json_params
 * Presently, such field must be manually pasted into D8 node
 *
 */

const communityRegistrationConfig = {
  // these items are redundant with other community fields
  //    partition: 'lextalk',
  //    title: 'Lexington Seniors Meetingplace',
  //    subHeading: 'Registration and Login',
  //    instructions: '( fields marked with * are required )',
  pages: [
    {
      key: 'getAuthMethod',
      title: 'selectMethod Page',
      auto: true,
      sections: [
        {
          key: 'selectMethodSection',
          title: 'Authentication Method',
          fields: [
            {
              key: 'authMethod',
              title: 'Select method',
              value: null,
              error: false,
              required: true,
              type: 'authMethod',
              values: [
                'sms',
                'google',
                'facebook',
                // 'facebook',
                // 'password',     //only valid with gated community
              ],
            },
          ],
        },
      ],
    },
    {
      key: 'authenticate',
      title: 'authentication Page',
      auto: true,
      sections: [
        {
          key: 'authenticateSection',
          title: 'Authenticating..',
          fields: [
            {
              key: 'authField',
              title: 'Authenticating...',
              value: null,
              error: false,
              required: true,
              type: 'authenticate',
            },
          ],
        },
      ],
    },
    {
      key: 'screenNamePage',
      title: 'screenName Page',
      auto: false,
      sections: [
        {
          key: 'registeringSect',
          title: 'Your Public Identification',
          fields: [
            {
              key: 'screenName',
              title: 'Public Screen Name',
              value: null,
              error: false,
              required: true,
              type: 'text',
              validate: true,
            },
          ],
        },
      ],
    },
    {
      key: 'getInvitation',
      title: 'getInvitation',
      auto: false,
      sections: [
        {
          key: 'getInvitationSect',
          title: 'getInvitationSect',
          fields: [
            {
              key: 'invitation',
              title: 'Invitation Code',
              value: null,
              error: false,
              required: true,
              type: 'text',
              validate: true,
            },
          ],
        },
      ],
    },
    {
      key: 'optionalGetUpdates',
      title: 'optionalGetUpdates',
      sections: [
        {
          key: 'optionalGetUpdatesSect',
          title: 'optionalGetUpdatesSect',
          fields: [],
        },
      ],
    },
    {
      key: 'getTermsConsent',
      title: 'Terms and Conditions',
      auto: true,
      sections: [
        {
          key: 'consenting',
          title: 'Approve Terms and Conditions',
          fields: [
            {
              key: 'accept',
              title: 'Accept all Terms & Conditions',
              required: true,
              type: 'consent',
              validate: true,
            },
          ],
        },
      ],
    },
    {
      key: 'getCredsLogin',
      title: 'Login',
      sections: [
        {
          key: 'getLoginUPW',
          title: 'Returning User',
          fields: [
            {
              key: 'username',
              title: 'Username',
              value: null,
              error: false,
              required: true,
              type: 'text',
              validate: 'username',
            },
            {
              key: 'password',
              title: 'Password',
              value: null,
              error: false,
              required: true,
              type: 'password',
              validate: 'password1',
            },
          ],
        },
      ],
    },

    {
      key: 'getCredsRegister',
      title: 'Register',
      sections: [
        {
          key: 'getRegInvitation',
          title: 'Initial Registration',
          fields: [
            {
              key: 'regInvitation',
              title: 'Invitation Code',
              helper: null,
              value: null,
              error: false,
              required: true,
              type: 'text',
              validate: true,
            },
          ],
        },
        {
          key: 'getRegUPW',
          title: null,
          fields: [
            {
              key: 'username0',
              title: 'Create Username',
              value: null,
              error: false,
              required: true,
              type: 'text',
              validate: 'username',
            },
            {
              key: 'password0a',
              title: 'Create Password',
              value: null,
              error: false,
              required: true,
              type: 'password',
            },
            {
              key: 'password0b',
              title: 'Confirm Password',
              value: null,
              error: false,
              required: true,
              type: 'password',
              validate: 'password',
            },
          ],
        },
      ],
    },

    /*
        mandatory keys supplied by community-specific params
                    {
                        key: 'getAttributes',
                    },
                    {
                        key: 'filter',
                    },
        */
  ],
};

// log the configuration data stringified representation
export const generateJson = partition => {
  if (partition === communityRegistrationConfig.partition) {
    console.log(
      `generateJson for ${communityRegistrationConfig.partition}:`,
      communityRegistrationConfig
    );
    console.log('json:', JSON.stringify(communityRegistrationConfig));
  } else {
    console.log(`generateJson cannot process for partition ${partition}.`);
  }
};

export default communityRegistrationConfig;
