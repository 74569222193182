/**
 * A playlist manager, that renders an indexing message player.
 * Can render various presentations e.g. carousel, optional visualizer, playlist, etc.
 *
 * //TODO2: upgrade carousel playlist to handle responses
 * //TODO2: cleanse component heirarchy for CarouselBrowser
 * Browser1
 *  MessagePlayer
 *      Recorder
 *          ApplianceLayout
 *
 *      ApplianceLayout
 *      PlaylistView
 *          Playlist1
 *          Playlist2
 *      Player
 *          Handler
 *          Visualizer
 *          Progress
 *          Controls
 *      MessageTopic
 *      Actions
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from './components/Carousel';
import MessagePlayer from 'containers/MessagePlayer';
import { useSelector } from 'react-redux';

const CarouselBrowser = ({ playlist, onNext, onPrevious, onEnded }) => {
  // index into active playlist
  // do not confuse with state.dialog.selectedMessageIndex
  const [ix, setIx] = useState(0);

  // the playlist array contains an ordered list of messages
  // a topic message can contain an array of its respective responses
  const { selectedMessageId } = useSelector(state => state.dialog);

  useEffect(() => {
    // update the local ix whenever selectedMessageId changes
    if (playlist.length > 0) {
      const newIx = playlist.findIndex(msg => msg.id === selectedMessageId);
      newIx > -1 && setIx(newIx);
    }
  }, [playlist, selectedMessageId]);

  const swipableBanner = (
    <Carousel
      ix={ix}
      messages={playlist}
      onNext={onNext}
      onPrevious={onPrevious}
      onEnded={onEnded}
    />
  );

  return (
    <>
      {/* provide swipable carousel for the banner */}
      {
        <MessagePlayer
          carousel={swipableBanner}
          message={playlist[ix]}
          onNext={onNext}
          onPrevious={onPrevious}
          noFilterBar={false}
          noBrowserViewControl={false}
          autoplay="true"
        />
      }
    </>
  );
};

CarouselBrowser.propTypes = {
  playlist: PropTypes.arrayOf(PropTypes.object),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onEnded: PropTypes.func,
};

export default CarouselBrowser;
