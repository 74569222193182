/**
 *
 * //TODO: refactor to eliminate redundant code
 *
 */

import React, { useState } from 'react';

import TextFieldStd from 'components/utils/TextFieldStd';
import ButtonStd from 'components/utils/ButtonStd';
import ExceptionDialog from 'components/Modals/ExceptionDialog';
import { Typography, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ApplianceLayout from 'components/layouts/components/ApplianceLayout';
import RadioStd from 'components/utils/RadioStd';
import BorderedBlock from 'components/utils/BorderedBlock';
import MessageRecorder from 'containers/MessageRecorder';
import { useHistory } from 'react-router-dom';
import { t } from 'lib/translation/trans';

// validation tools
import { phone as phoneAttributes } from 'phone';
const emailValidator = require('email-validator');

const SupportView = ({ onSuccess, onFail, onClose }) => {
  const [contactType, setContactType] = useState('email'); //which type of contact information
  const [phone, setPhone] = useState(''); //phone entered on form
  const [email, setEmail] = useState(''); //phone entered on form
  const [emailFieldEn, setEmailFieldEn] = useState(false);
  const [contact, setContact] = useState(null);

  const [e164, setE164] = useState(''); //normalized phone in E.164 format
  const [phoneFieldEn, setPhoneFieldEn] = useState(true); // enable phone field and button
  const [valCode, setValCode] = useState(''); //validation code entered on form
  const [valCodeSecret, setValCodeSecret] = useState(''); // secret validation code to match up
  const [valCodeFieldEn, setValCodeFieldEn] = useState(false); //enable valCode field and button
  const [exception, setException] = useState(null); //exception description object
  const user = useSelector(state => state.user);
  const history = useHistory();
  // detect virtual keyboard on mobile device
  const vk = useSelector(state => state.ui.deviceInfo.virtualKeyboardActive);

  // --- Helpers ------------------------------------------------------------------------------

  // convert e164 phone format from +1xxxxxxxxxx to (xxx) xxx-xxxx
  const humanReadable = e_164 =>
    `(${e_164.slice(2, 5)}) ${e_164.slice(5, 8)}-${e_164.slice(8, 12)}`;

  // --- Validation ------------------------------------------------------------------------------

  // return null if no error
  const phoneValidationError = () => {
    //default error object
    const errObj = {
      heading: `"${phone}" ${t('is_not_a_valid_phone_number')}`,
      body: 'Please enter a US or Canada phone nunmber.',
      actions: null,
      onClose: () => {
        setException(null);
        setPhone('');
      },
    };

    const { isValid, phoneNumber, countryIso2 } = phoneAttributes(phone);

    const invalid = () => !isValid;
    const badCountry = () => !['US', 'CA'].includes(countryIso2);
    //TODO2: add better phone number country check
    // e.g. '441' (Bermuda) is reported as US

    if (invalid()) {
      //verify user is authenticated
      // use default errObj
    } else if (badCountry()) {
      errObj.heading = `"${phone}" ${t('is_not_a_US_or_Canada_phone_number')}`;
    } else {
      setE164(phoneNumber); // save standardized format-- NOTE this triggers send SMS
      setPhone(humanReadable(phoneNumber)); //clean up format of the phone field
      return null; // all OK
    }
    return errObj;
  };

  // return null if no error
  const emailValidationError = () => {
    // default error object
    const errObj = {
      heading: `"${email}" is not a valid address !`,
      body: 'Please re-enter your email address carefully.',
      actions: null,
      onClose: () => {
        setException(null);
        setEmail('');
      },
    };

    return emailValidator.validate(email) ? null : errObj;
  };

  // --- Event Handlers ------------------------------------------------------------------------------

  const handlePhoneSubmit = () => {
    const errObj = phoneValidationError();

    if (!Boolean(errObj)) {
      setContact(phone);
    } else {
      // failed validation, generate a notice
      console.log(`validation exception: errObj`);
      setException(errObj);
    }
  };

  const handleEmailSubmit = () => {
    const errObj = emailValidationError();
    if (!Boolean(errObj)) {
      setContact(email);
    } else {
      // failed validation, generate a notice
      console.log(`validation exception: errObj`);
      setException(errObj);
    }
  };

  // after completion or cancellation, re-route to playlist
  const handleRecorderExit = () => history.push('/playlist');

  // --- Rendering ------------------------------------------------------------------------------
  const contactTypeField = () => (
    <RadioStd
      name="contactType"
      items={[
        { key: 'email', data: 'Email' },
        { key: 'phone', data: 'Mobile Phone' },
      ]}
      value={contactType}
      handleChange={ev => setContactType(ev.target.value)}
    />
  );

  const phoneField = () => (
    <>
      <TextFieldStd
        label={t('Phone')}
        value={phone}
        placeholder="XXX-XXX-XXXX"
        helperText={t('Enter_your_MOBILE_number')}
        onChange={ev => setPhone(ev.target.value)}
        disabled={valCodeFieldEn}
        onEnter={handlePhoneSubmit}
      />
      <div style={{ margin: '.5rem 1rem' }}>
        <Typography variant="caption"> {t('Mobile_phones_only')}</Typography>
      </div>
      <ButtonStd
        label={t('Next')}
        onClick={handlePhoneSubmit}
        color="secondary"
        disabled={valCodeFieldEn}
      />
    </>
  );

  const emailField = () => (
    <>
      <TextFieldStd
        label="Email"
        value={email}
        placeholder={'valid email address'}
        helperText={`Enter your valid email address`}
        onChange={ev => setEmail(ev.target.value)}
        onEnter={handleEmailSubmit}
      />
      <ButtonStd
        label={t('Next')}
        onClick={handleEmailSubmit}
        color="secondary"
        disabled={valCodeFieldEn}
      />
    </>
  );

  // for unauthenticated users, gather contact information then render the Recorder
  return (
    <>
      {contact || user.isAuthenticated ? (
        /* have contact info already */
        <MessageRecorder
          unregisteredUserContactInfo={contact}
          typeOverride="support"
          bannerOverride="Record Your Support Request"
          parentMessage={{}}
          onExit={handleRecorderExit}
        />
      ) : (
        /* need to get contact info */
        <ApplianceLayout
          banner={{ title: 'Contact / Support Request' }}
          noWrapBody={true}
          body={
            <Grid container direction="column" spacing={2}>
              {/* hide items when virtual keyboard is active */}
              {!vk && (
                <>
                  <Grid item>
                    Please provide your email or mobile phone number so that we
                    can respond properly to your support request:{' '}
                  </Grid>
                  <Grid item>
                    <BorderedBlock>{contactTypeField()}</BorderedBlock>
                  </Grid>
                </>
              )}
              <Grid item>
                {contactType === 'phone' && phoneField()}
                {contactType === 'email' && emailField()}
              </Grid>
            </Grid>
          }
          actions={<></>}
        />
      )}
      <ExceptionDialog
        open={Boolean(exception)}
        onClose={exception && exception.onClose}
        heading={exception && exception.heading}
        body={exception && exception.body}
        actions={exception && exception.actions}
      />
    </>
  );
};

export default SupportView;
