/**
 * Application entry point
 * - invokes thunks to initialize the redux state
 * - renders Layout after initialization is completed
 *
 * //TODO2: verify drupal host is expunging unused files
 */

import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initializeUser } from 'store/user-slice';
import { initiatePeriodicAwakeRefresh } from 'lib/api/userApi';
import { uiActions, setLanguage } from 'store/ui-slice';
import { DialogStd } from 'components/Modals';
import Layout from 'components/layouts/Layout5';

import { deviceInfo, getIpAddress } from 'lib/utils/utils';
import { cleanupUserInvites } from 'lib/api/messageApi';
import { useHistory } from 'react-router-dom';
import Alerts from 'components/layouts/components/Alerts';
import { activityDetector } from 'lib/classes/ActivityDetector';
import { Dinger } from 'lib/classes/Dinger'; //DO NOT REMOVE!!  unused here, but must be loaded
import { processUserSleepTransition } from 'lib/api/userApi';
import { versionResolve } from 'lib/utils/versionUtils';
import UIfx from 'uifx';
import { t } from 'lib/translation/trans';

import PWA, { pwa } from 'lib/classes/PWA'; // load singleton
import {
  deleteUserAllSubscriptions,
  enablePushNotificationEventFromSW,
  notePWAInstalled,
} from 'lib/api/webPushApi';

PWA.create({
  t,
  deleteUserAllSubscriptions,
  enablePushNotificationEventFromSW,
  notePWAInstalled,
});

var initializationTimer = null;

const App = () => {
  const [version, setVersion] = useState(null);

  //    // disable console.log for production client
  //    if (!devClient){
  //        console.log = function() {};
  //    }

  const [initializedStatus, setInitializedStatus] = useState('waiting');
  const history = useHistory();
  const dispatch = useDispatch();
  const { initialized: userInitialized, isAuthenticated } = useSelector(
    state => state.user
  );
  const uiInitialized = useSelector(state => state.ui.pSession.initialized);
  const isAuthenticatedRef = useRef();
  isAuthenticatedRef.current = useSelector(state => state.user.isAuthenticated);

  const { browserIsHidden } = useSelector(state => state.ui);

  // detect the debug path and bypass user initialization
  const debug = window.location.pathname === '/debug';

  //confirm latest version of app
  useEffect(() => {
    // try to bypass cache when new codebase is deployed
    versionResolve().then(v => setVersion(v));
  }, []);

  // initialize store once codebase version is determined
  useEffect(() => {
    if (version) {
      console.log(`running ${version}`);
      initializeStore();
    }
  }, [version]);

  // audible tone when browser hidden status changes
  useEffect(() => {
    //console.log(`browser is ${browserIsHidden ? 'hidden' : 'visible'}.`);
    const hide = new UIfx('/audio/shutdown.mp3');
    const unhide = new UIfx('/audio/powerup.mp3');
    // browserIsHidden ? hide.play() : unhide.play();
    if (!browserIsHidden) {
      // browser has become unhidden...
      // check status of PWA
      dispatch(
        uiActions.pwaSetSessionStatus({
          progress: 'update',
          runningAsPWA: pwa.runningAsPWA(),
        })
      );

      //@@ also check peer registration status
    }
  }, [browserIsHidden]);

  // wait for pSession before initializing user
  useEffect(() => {
    if (debug) {
      // bypass user initialization and route directly to /debug page
      setInitializedStatus('success');
      clearInterval(initializationTimer);
    } else {
      uiInitialized && dispatch(initializeUser());
    }
  }, [debug, uiInitialized]);

  // detect initialization success
  useEffect(() => {
    if (uiInitialized && userInitialized) {
      clearInterval(initializationTimer);
      setInitializedStatus('success');
      cleanupUserInvites(); // remove prior stranded invite messages

      // monitor user sleep/awake status
      activityDetector.subscribe((isAwake, options) => {
        // convey user sleep/awake transitions to store and server
        processUserSleepTransition(isAwake, options);
      });

      //@@ consider using sigserver to push info instead
      initiatePeriodicAwakeRefresh();

      // note whether session started as installed PWA
      dispatch(
        uiActions.pwaSetSessionStatus({
          progress: 'initialize',
          runningAsPWA: pwa.runningAsPWA(),
        })
      );
    }
  }, [uiInitialized, userInitialized]);

  // initialize redux store
  const initializeStore = () => {
    setInitializedStatus('waiting');
    dispatch(uiActions.initPsession());
    dispatch(uiActions.setInitialPathname(history.location.pathname));
    dispatch(uiActions.initDeviceInfo(deviceInfo()));
    dispatch(setLanguage(/* initialize from pSession */));

    // watch for hide/unhide of browser window
    document.addEventListener(
      'visibilitychange',
      function () {
        dispatch(uiActions.setBrowserIsHidden(document.hidden));
      },
      false
    );

    // wait for initialization to complete
    initializationTimer = setInterval(
      () => setInitializedStatus('fail'),
      10000
    );

    getIpAddress().then(ip => dispatch(uiActions.setIpAddress(ip)));
  };

  const handleRetry = () => window.location.reload();

  // await redux store initialization before rendering content
  return initializedStatus === 'success' ? (
    <>
      <Alerts />
      <Layout />
    </>
  ) : initializedStatus === 'waiting' ? (
    <DialogStd
      showProgress={true}
      open="true"
      redux={false}
      showButton={false}
      content="Initializing"
    />
  ) : (
    <DialogStd
      open="true"
      content="Problem Initializing"
      btnLabel="Retry"
      onClose={handleRetry}
    />
  );
};
export default App;
