/**
 * Exception Dialog, accepts
 *   heading        //string
 *   body           //string or array of strings
 *   actions        // component or buttonSped(s)
 *
 * Current Usage:
 *   BetaTestWelcome
 *   LoginView
 *   RegistrationForm
 *   SMSAuthenticator
 *   Recorder (2 places, 1 erroneous)
 *   MicPermissionDialog
 *
 * (temporarily employed also as a SaveSuccessDialog by Recorder)
 *
 * Accepts a Component, or button specs. to be placed in the 'actions' footer
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ButtonStd from 'components/utils/ButtonStd';
import UIfx from 'uifx';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, exceptionDisable, ...other } = props;
  const options = {
    variant: 'h6',
  };
  exceptionDisable || (options.color = 'error');

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography {...options}>{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ExceptionDialog(props) {
  const {
    open,
    onClose,
    heading,
    body,
    actions,
    exceptionDisable,

    disableBackdropClick,
    disableEscapeKeyDown,
  } = props;

  // some props can either be single item or arrays.  Convert to arrays
  const bodyArr = Array.isArray(body) ? body : body ? [body] : [];
  const actionsArr = Array.isArray(actions)
    ? actions
    : actions
    ? [actions]
    : [];

  useEffect(() => {
    if (open && !exceptionDisable) {
      const uhoh = new UIfx('/audio/uhoh2a.mp3', { volume: 0.1 });
      uhoh.play();
    }
  }, [open]);

  const handleClose = () => {
    onClose && onClose();
  };
  return (
    <div>
      <Dialog
        fullWidth
        onClose={handleClose}
        onClick={e => e.stopPropagation()}
        open={open}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <DialogTitle onClose={handleClose} exceptionDisable={exceptionDisable}>
          {' '}
          {heading}{' '}
        </DialogTitle>
        <DialogContent dividers>
          {bodyArr.map((line, ix) => (
            <Typography style={{ marginBottom: '2rem' }} key={ix} gutterBottom>
              {line}
            </Typography>
          ))}
        </DialogContent>
        {actionsArr.length > 0 && (
          <DialogActions>
            {actionsArr.map((action, ix) => {
              // render the action explicitly if it is a React element,
              // othrwise render a button with specified props
              return React.isValidElement(action) ? (
                action
              ) : Boolean(action.label) ? (
                <ButtonStd
                  key={ix}
                  onClick={action.onClick}
                  label={action.label}
                  color={action.color}
                />
              ) : null;
            })}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

ExceptionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,

  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,

  heading: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),

  // actions can be a Component, or element,
  // or zero or more button attribute specifications
  actions: PropTypes.oneOfType([
    PropTypes.shape({
      element: PropTypes.elementType,
    }),
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
  ]),
  exceptionDisable: PropTypes.bool, //bypass exception styling (temporary)
};

ExceptionDialog.defaultProps = {
  heading: 'Problem...',
  body: 'An unknown error has occurred\u00a0!',
  actions: [],
  exceptionDisable: false,

  disableBackdropClick: false,
  disableEscapeKeyDown: false,
};
