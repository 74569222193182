/**
 * CalleeActions
 *
 * Render contents of LiveCallAlert for recipient of invite ("callee")
 * Handle cases of single and multiple pending
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Divider } from '@material-ui/core';
import { WEB_HOST } from 'configs/config-hvn';
import PlayerActions from 'containers/MessagePlayer/components/PlayerActions';
import VoicePlayer, { useVoicePlayerHelper } from 'components/VoicePlayer';
import { t } from 'lib/translation/trans';
import { getCallManager } from 'lib/api/webRTCApi';
import sigServer from 'lib/api/signalingServer';
import { getPeer } from 'lib/api/userApi';
import InviteSelector from './InviteSelector';
import { srcOk } from 'lib/utils/utils';

const callProc = getCallManager();

// render the content for callee's alert
export default function CalleeActions({ zen }) {
  const [userBusy, setUserBusy] = useState(false); //user is busy in external dialog
  const { invites, selectedInviteId } = useSelector(state => state.webRTC);
  const multipleInvites = useSelector(state => state.webRTC.invites.length) > 1;
  const userId = useSelector(state => state.user.auth.user?.id); // local user

  const invite = invites.find(i => i.userId === selectedInviteId);
  const callerScreenName = invite?.screenName;
  const { defaultProps, pause } = useVoicePlayerHelper({
    playerKey: 'LiveCallAlert',
  });
  const inviteSrc = `${WEB_HOST}/${invite?.fileUrl}`;

  // notify peer of local event
  const notifyPeer = type =>
    sigServer.sendCallProcEvent({
      event: { type, userId },
      recipientSocketId: getPeer(invite?.userId)?.socketId,
    });

  // similar processing in CallProgressDialog::handleActionEvent
  const handleCalleeAction = ev => {
    pause(); // pause playing, if in progress

    // certain events launch dialogs, must await disposition
    setUserBusy(['regretsClick', 'blacklistClick'].includes(ev));

    if (['acceptClick'].includes(ev)) {
      // initiate call processing
      callProc.handleLocalEvent({ type: 'accept', data: invite });
    }
    if (['declineClick', 'regretsConfirm', 'blacklistConfirm'].includes(ev)) {
      notifyPeer('decline');
    }

    if (
      [
        'declineClick',
        'acceptClick',
        'regretsConfirm',
        'blacklistConfirm',
      ].includes(ev)
    ) {
      callProc.expungeStrandedInvites(invite?.userId);
    }
  };

  if (zen) {
    return (
      <PlayerActions
        legends={true}
        message={{ ...invite, type: 'invite' }}
        onEvent={handleCalleeAction}
        btnList={['accept', 'decline', 'regrets', 'blacklist']}
        disabled={userBusy}
      />
    );
  }

  return (
    <div>
      <Grid container>
        {multipleInvites ? (
          <Grid xs={12} item>
            <InviteSelector />
          </Grid>
        ) : (
          <Grid item>
            <div
              style={{
                display: 'inline',
                fontSize: '1.5em',
                textAlign: 'left',
                color: '#0096ef',
                fontStyle: 'italic',
              }}
            >
              {' '}
              {`${callerScreenName}`}{' '}
            </div>
            <div style={{ display: 'inline', textAlign: 'left' }}>{` ... ${t(
              'is_calling_you'
            )}`}</div>
          </Grid>
        )}
      </Grid>
      <Divider />
      <Grid style={{ marginTop: 10 }} container>
        <Grid
          style={{ width: 60, visibility: userBusy ? 'hidden' : 'visible' }}
          item
        >
          {srcOk(inviteSrc) && (
            <>
              <VoicePlayer
                {...defaultProps}
                src={inviteSrc}
                autoplay={true}
                uiItems={['controlsCompact']}
                ident="LiveCallAlert"
              />
              <div>{t('Invitation')}</div>
            </>
          )}
        </Grid>
        <Grid item>
          <PlayerActions
            legends={true}
            message={{ ...invite, type: 'invite' }}
            onEvent={handleCalleeAction}
            btnList={['accept', 'decline', 'regrets', 'blacklist']}
            disabled={userBusy}
          />
        </Grid>
      </Grid>
    </div>
  );
}

CalleeActions.propTypes = {
  zen: PropTypes.bool, // use minimalist rendering
};
CalleeActions.defaultProps = {
  zen: false,
};
