/**
 * A Message Recorder
 * Manages the creation and upload of a drupal voice_message node
 *
 * Employs VoiceRecorder to create a recording, then takes control
 * of UI components to complete the metadata steps and storage of D8 node.
 *
 * Note:
 * handleRecorderActionClick is passed as a callback to the ActionControls object.
 * since this method invokes a far-reaching set of methods, to assure that references
 * within the callback tree have access to real-time state, all state in this component
 * is read therough reference.current.
 *

   LiveCall Invitation
   * if 'invite' message type is pre-selected, offer text or voice invitation options

 * DEFER
    - Contextual help, rendered as question marks
    - harden validation of title including max length
    - consider using react context for prop processNewMember
    - pause player upon recorder mount
    - implement questionairre reminders

 * Features
 * --------------------------------
 *  Settings:
 *      √ auto-start option
 *
 *  Stages:
 *      √ - stage1: capture, acquire voice blob
 *      √ - stage2: metadata, annotate and save message with blob
 *      √ - enableTwoStage: (prop) simplifies stage1 and defers clutter until after capture is complete
 *
 *  User Categories:
 *      √ - anonymous:
 *          - can record and download voice
 *          - can save support request
 *          - can buffer profile for later processing
 *          - must login to save other types
 *      √ - community member:
 *          - can create and save any available type of recording
 *      √ - non member (registered with system but not community)
 *          - prompted to join community to save message
 *
 *  Action Buttons:
 *      √ Cancel
 *      √ stage1: mic-Enable, Record, Stop, Review, Pause, Download/Save/Send, Cancel
 *      √ stage2: Download, Save/Send, Login, Cancel
 *      √ - button visibility and enablement controled by recState
 *
 *  Restrictions
 *      √ must be logged in to save a message (except Support request, delayed submission)
 *      √ recording duration must be within min/max limits
 *      √ context dependent available message types
 *      √ inline join of open community (upon save of first message)
 *      √ invitation required to join gated community
 *      √ metadata
 * validation
 *
 *  Metadata Form Fields:
 *      √ Message Type Selector
 *      √ Message Title Field
 *      √ - ( author applied automatically)
 *      √ Message title automatically annotated for anonymous support request
 *
 *  UI features
 *      √ Indicate message type being recorded
 *      √ hide type selector if only one type available
 *      √ hide Title Field if devaultTitle (based on type, parent name)
 *      √ enunciate a beep to commence recording
 *      √ dynamic label on Title Field (based on message type)
 *      √ for unregistered user creating support, display user contact
 *      √ virtual keyboard detection and layout accommodation
 *      √ remove Recorder from main menu when VoiceRecorder is mounted
 *      √ hide Login button if user is already logged in
 *      √ - user notifications
 *          √ - transient alert upon cancel
 *          √ - dismissible report upon validation failure
 *          √ - dismissible confirmation with link-copy option for certain message types
 *          √ - transient alert upon success for certain message types
 *
 *      √ Optimized label for Save/Send button
 *      Contextual help, rendered as question marks
 *      √ Dynamically optimized banner text
 *      pause player upon recorder mount
 *      √ - auth/membership enforcement
 *          - if auth required, disable 'Save' button
 *          - if join required, divert user upon 'Save' button
 *      √ provide unobtrusive advice to avoid exceptions.
 *      √ warning of impending max recording duration
 *      √ apply auto title if appropriate type
 *
 * Deferred Submission
 *      √ use react context to buffer recording for later submission
 *
 * Embedded Login support
 *      √ Launch Login/Registration Dialog
 *      √ Bypass Profile sollicitation by login script
 *
 *
 * Community related Features
 *      √ require join of gated community
 *      √ integrated community join (bypass join questionairre)
        - implement questionairre reminders
 *
 * Send Action
 *      √ - use proxy token for unauthenticated requests
 *      √ - compose args for submit method
 *      √ - handle results of submit
 *      √ - if buffering, save locally, else pass to promise submit
 *      √ - handle failure
 *
 * Termination
 *  √ - on success, cancel:  prepare for next recording or exit
 *  √ - provide dynamic success message based on admin, selectedType
 *  √ - provide dynamic success alert
 *
 * Admin Features
 *  √ - automatically add admin-only message types to selector
 *
 *  'outgoing' message support
 *    - DM from authenticated user to unauthenticated user
 *    - access code and recipient name applied to message fields
 *    - alternate success report: OutgoingMessageSuccessForm
 *    - clipboard messge created and presented in success report
 *    - additional editable name fields presented: toField, fromField
 *    - annotate parent fields userId: linkCode, screenName: toField
 *
 * Clipboard:
 *   Upon successful messageSubmit, for some message types (public
 *   and outgoing) a link to
 *   the new message is to be placed in the clipboard. This requires a
 *   button click event, and for some browsers the Clicker simulator
 *   is successful.  When the simulated click fails, a button
 *   is provided requiring one additional manual click to accomplish
 *   clipboard capture.
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Popover,
  IconButton,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import BorderedBlock from 'components/utils/BorderedBlock';
import VoiceRecorder from 'components/VoiceRecorder';
import MessageTitleInput from './components/MessageTitleInput';
import { uiActions } from 'store/ui-slice';
import { dialogActions } from 'store/dialog-slice';
import {
  isToAdmin,
  defaultTitle,
  isPublicType,
  msgUrl,
  enableOutgoingType,
  deleteMessageById,
} from 'lib/api/messageApi';
import { isMember, currentCommunity } from 'lib/api/communityApi';
import { useHistory } from 'react-router-dom';
import { timeout, makeArray, getRandomInt } from 'lib/utils/utils';
import { maxTitleLength, SUPPORT_USER } from 'configs/config-hvn';
import { submitMessage } from 'lib/api/submitMessage';
import { useRecordingBufferContext } from 'context/recordingBufferContext';
import UIfx from 'uifx';
import { getProxyToken } from 'lib/api/userApi';
import SuccessReport from './components/SuccessReport';
import ProblemReport from 'components/utils/ProblemReport';
import ActionControls from 'components/VoiceRecorder/lib/ActionControlsClass';
import { btnStates, recIconMap, MRS } from './lib/mrecConfig';
import MessageTypeSelector from './components/MessageTypeSelector';
import useState from 'react-usestateref';
import RegistrationManager from 'containers/RegistrationManager';
import { patchHvnUser } from 'lib/api/userApi';
import ButtonStd from 'components/utils/ButtonStd';
import CloseIcon from '@material-ui/icons/Close';
import AutoStartControl from 'components/Settings/components/AutoStartControl';
import SettingsIcon from '@material-ui/icons/Settings';
import { t } from 'lib/translation/trans';
import Clicker from 'components/utils/Clicker';
import InviteModeSelector from 'components/LiveCall/components/InviteModeSelector';

// "nonmember" users who have not joined a community
import NonMemberActions from 'components/VoiceRecorder/components/NonMemberActions';

import RecorderTextField from 'components/VoiceRecorder/components/RecorderTextField';
import { getClipboardText } from './lib/outgoingMessageUtils';

const defaultRecorderSettings = {
  recorderType: 'react-mic-gold',
  size: {
    std: { width: 300 },
    xs: { width: 250 },
    height: 60,
  },
  duration: {
    minRecordingSec: 5,
    maxRecordingSec: 60, // maximum duration of a recording
    maxRecordingWarningSec: 5, // warning trigger prior to impending limit
  },
  // fixed filename for use in download to local device
  downloadFilename: 'OkSayit.mp3',
};

const useStyles = makeStyles(theme => ({
  outerWrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  itemRow: {
    width: defaultRecorderSettings.size.std.width,
    [theme.breakpoints.down('xs')]: {
      width: defaultRecorderSettings.size.xs.width,
    },
    marginTop: '20px',
  },
  progress: {
    marginTop: '2rem',
    height: '10rem',
  },
  // hide element by scaling to zero size
  makeTiny: {
    transform: 'scale(0)',
  },
  closeLoginButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  recorderOptionsButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  closeInviteSelector: {
    position: 'absolute',
    right: 0,
    bottom: -50,
    color: theme.palette.grey[500],
  },
}));

const uhoh = new UIfx('/audio/uhoh2a.mp3', { volume: 0.1 });

// Wrapper for individual UI component
const Item = ({ visible, children }) => {
  const classes = useStyles();
  return visible ? (
    <Grid item classes={{ root: classes.itemRow }}>
      {children}
    </Grid>
  ) : null;
};

const MessageRecorder = props => {
  const {
    typeOverride,
    unregisteredUserContactInfo,
    bannerOverride,
    onExit,

    // pass to children
    readyNoCancel, //VoiceRecorder uses alternate READY state
    onActionEvt,
  } = props;

  // states --------------------------------------------------------------------
  // special measures needed to permit callback functions to access current state
  // per: https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  // per.: https://medium.com/programming-essentials/how-to-access-the-state-in-settimeout-inside-a-react-function-component-39a9f031c76f

  // using: https://www.npmjs.com/package/react-usestateref
  const [recState_, setRecState_, recState] = useState([MRS.stage1]); // allocated as an array to support push/pop
  const [recording_, setRecording, recording] = useState({
    blob: null,
    blobURL: null,
    durationSec: 0,
  });
  const [title_, setTitle, title] = useState(''); // title to be applied to message
  const [toField_, setToField, toField] = useState('');
  const [fromField, setFromField] = useState('');
  const [clipboardText_, setClipboardText, clipboardText] = useState(null);
  const [linkCode] = useState(getRandomInt(10000, 99999).toString());
  const fromScreenName = useSelector(
    state => state.user?.auth?.user?.screen_name
  );

  const [availableTypes_, setAvailableTypes, availableTypes] = useState([]); // the available message types that can be recorded
  const [selectedType_, setSelectedType, selectedType] = useState(null); // the type of message being recorded
  const [recorderKey_, setRecorderKey, recorderKey] = useState(0); // increment to re-render the VoiceRecorder
  const [parentMessage_, setParentMessage, parentMessage] = useState(
    props.parentMessage
  );

  const [adviceItems_, setAdvice, adviceItems] = useState([]);
  const [validationExceptions_, setValidationExceptions, validationExceptions] =
    useState(null);

  const [successResult_, setSuccessResult, successResult] = useState(null);
  const [actionControls] = useState(
    new ActionControls(
      btnStates,
      ['Download', 'Save', 'Login', 'Cancel'], //authenticated users only see Save, Cancel
      recIconMap,
      handleRecorderActionClick //WARNING: all state referenced directly or indirectly by this callback must use (ref).current
    )
  );

  const [registrationMessage_, setRegistrationMessage, registrationMessage] =
    useState(null);

  const setRecState = state => {
    setRecState_([state]);
  }; // save as array of 1
  const getRecState = () => recState.current[0]; // return first array value

  // add new state to beginning of array
  const pushRecState = state => setRecState_(oldArray => [state, ...oldArray]);

  // if a state has been pushed, return to prior state
  const popRecState = () =>
    setRecState_(oldArray =>
      oldArray.length > 1 ? oldArray.slice(1) : oldArray
    );

  // NOTE: as in local states, current values of these may not be available within callback functions
  const isAuthenticatedRef = useRef();
  isAuthenticatedRef.current = useSelector(state => state.user.isAuthenticated);
  const isAuthenticated = isAuthenticatedRef.current;

  const menuVariant = useSelector(state => state.ui.layout.menuVariant);

  const vk = useSelector(state => state.ui.deviceInfo.virtualKeyboardActive); // detect virtual keyboard on mobile device
  const { pSession, layout } = useSelector(state => state.ui);
  const { autoRecord, bypassMessageRecorderSuccessForm } = pSession;

  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null);

  // management of invite override conditions:  offer text/voice invitation options
  const [voiceInviteSelected, setVoiceInviteSelected] = useState(false);
  const inviteOverride = props.typeOverride === 'invite';
  const showInviteModeSelector = inviteOverride && !voiceInviteSelected;

  const [clipboardStatus_, setClipboardStatus, clipboardStatus] =
    useState('disabled'); //'disabled', 'uncaptured', 'captured'

  /* Diagnostic ------------------------*/
  /*
  const states = {
    recState_,
    recording_,
    title_,
    toField_,
    fromField,
    clipboardText_,
    linkCode,
    availableTypes_,
    selectedType_,
    recorderKey_,
    parentMessage_,
    adviceItems_,
    validationExceptions_,
    successResult_,
    actionControls,
    registrationMessage_,
    voiceInviteSelected,
  };
  console.log('props:', props);
  console.log('states:', states);
*/
  // END states --------------------------------------------------------------------

  const linkText = msgUrl(successResult.current);

  // designate text to be stored into clipboard
  useEffect(() => {
    if (clipboardStatus.current !== 'disabled' && linkText) {
      setClipboardText(
        getClipboardText({
          type: selectedType.current,
          from: fromField,
          title: title.current,
          code: linkCode,
          linkText,
        })
      );
    }
  }, [
    clipboardStatus.current,
    linkText,
    fromField,
    title.current,
    linkCode,
    selectedType.current,
  ]);

  // waive minimum recording duration for 'reply' type message
  const minRecordingSec =
    selectedType.current === 'reply'
      ? 0
      : defaultRecorderSettings.duration.minRecordingSec;

  // re-render VoiceRecorder when selectedType is initialized
  useEffect(() => {
    setRecorderKey(old => old + 1); //re-render the VoiceRecorder
  }, [selectedType_ === null]);

  // dynamic styling for recorder Actions
  const useActionClasses = makeStyles(theme => ({
    blockContent: {
      backgroundColor: isPublicType(selectedType.current)
        ? theme.palette.secondary.dark
        : theme.palette.primary.main,
      padding: '10px 0px 10px 0px',
      borderRadius: '4px',
    },
  }));

  const classes = useStyles();
  const actionClasses = useActionClasses();
  const ctx = useRecordingBufferContext();
  const dispatch = useDispatch();
  const history = useHistory();

  // scrollable height calculation
  const totalAvailableHeight = layout.mainContentHeight;
  const scrollableSectionMaxHeight = totalAvailableHeight;

  // NOT a community member
  const isAuthenticatedNonMember = () => isAuthenticated && !isMember();

  const userMustJoinCommunity = () =>
    selectedType.current !== 'support' && isAuthenticatedNonMember();
  // persistent values, not to be re-initialized on each render
  const priorIsAuthenticated = useRef();
  const downloadRef = useRef();
  const originalMenuVariantRef = useRef();
  const enableDeltaAuthProcessing = useRef(false);
  const recorderRef = useRef();

  // permitted unauthenticated messages:
  // unauthenticated user is creating a 'support' request type
  const unauthenticatedSupportRequest =
    selectedType.current === 'support' && !isAuthenticated;

  // unauthenticated user can respond to 'outgoing' messages
  const unauthenticatedReply =
    selectedType.current === 'reply' &&
    parentMessage.current.type === 'outgoing';

  // use 2-stage metadata gathering if forced by props
  // or for unauth users except when creating 'profile' or 'support' message
  const enableTwoStage =
    props.enableTwoStage ||
    !(
      isAuthenticated ||
      ['profile', 'support'].includes(selectedType.current) ||
      unauthenticatedReply
    );

  const stage1 = getRecState() === MRS.stage1;
  const stage2 = [MRS.stage2Anon, MRS.stage2Auth].includes(getRecState());
  const stage12 = stage1 || stage2;

  // items to be rendered in popup by renderOptionsButton
  const optionsArr = [<AutoStartControl />];

  // add/remove advice item, avoid duplicates
  const addAdvice = item =>
    setAdvice(oldItems => [
      ...oldItems.filter(oi => oi.key !== item.key),
      item,
    ]);
  const removeAdvice = key =>
    setAdvice(oldItems => oldItems.filter(oi => oi.key !== key));

  /*
        // Render generalized Recorder help
        // But if typeOverride is present, and specialized help is available, render specialized
        helpContext = () => {
            switch (
                props.typeOverride) {
                case null:
                    // no typeOverride, use generic help
                    return 'Recorder';
                case 'invite':
                    return "Live Calls";
                case 'support':
                    return "Support / Contact";
                default:
                    // unsupported typeOverride
                    return null;
            }
        }
    */
  /*

    const t = transMap([
        "Comment",
        "Direct",
        "Inquiry",
        "Message",
        "OK",
        "Profile",
        "Public",
        "Regrets",
        "Respond",
        "Save",
        "Send",
        "Subject",
        "to",
        "Live_Call",
        "About_Yourself",
        "Create_Your_Community_Profile_for",
        "Creating_a_Message",
        "Enter_a_Headline_about_you",
        "Message_Title",
        "Please_enter_a_Headline_about_you",
        "Please_enter_a",
        "Creating_a",

                msg: `Please record at least ${defaultRecorderSettings.duration.minRecordingSec} sec.` })
                msg: generateTitleExceptionMsg() })
                msg: `Message type "${selectedType.current}" cannot be created !` })
                msg: "You must Login to save this message !" })
                msg: "Please login to save your message." })
                msg: "There was a problem saving your recording." }])

                message: `There is a ${maxTitleLength} character limit.`, severity: "warning" }))
                message: "Congratulations NEW MEMBER! You can now proceed with your recording.", severity: "success" }))
                message: `Your Message is cancelled.`, severity: "warning" }))
                message: `Your recording has been downloaded.`, severity: "success" }))
                message: "There was a problem saving your recording.", severity: "error" }))


        "Message to Support"


    ])
    */

  // the current language and translation map
  const { langCode, langMap: t1 } = useSelector(state => state.lang);

  //initialize
  useEffect(() => {
    //@@ squelch playing that might be in progress

    // be sure focus flag is not stuck upon mount
    dispatch(uiActions.noteFieldFocus(false));

    return () => {
      /* unsquelch playback */
    };
  }, []);

  // temporrily adjust main menu
  useEffect(() => {
    originalMenuVariantRef.current = menuVariant;
    //disable-- dispatch(uiActions.setMenuVariant("RecordViewManager"))
    return () => {
      //disable-- dispatch(uiActions.setMenuVariant(originalMenuVariantRef.current))
    };
  }, []);

  // handle authentication status change
  useEffect(() => {
    if (enableDeltaAuthProcessing.current) {
      const recState = getRecState();
      if (isAuthenticated) {
        // if user logged in either with the Header Login button or the embedded login button
        if ([MRS.stage2Anon, MRS.login].includes(recState)) {
          setRecState(MRS.stage2Auth);
        }
      } else {
        // user logged out, cancel recording session
        reset('not authenticated');
      }
    }
    enableDeltaAuthProcessing.current = true;
  }, [isAuthenticated]);

  // manage recState-dependent advice
  useEffect(() => {
    switch (recState.current[0]) {
      case MRS.stage2Anon:
        // initialize advice upon entering stage2Anon state
        addAdvice({ key: 'login', msg: exceptionMsg('login') });
        if (title.current.length === 0) {
          addAdvice({ key: 'title', msg: exceptionMsg('title') });
        }
        break;
      default:
        removeAdvice('login');
        break;
    }
  }, [recState.current[0]]);

  //translate exceptions and advice when langCode changes
  useEffect(() => {
    setValidationExceptions(old =>
      old ? old.map(({ key }) => ({ key, msg: exceptionMsg(key) })) : null
    );
    setAdvice(old =>
      old ? old.map(({ key }) => ({ key, msg: exceptionMsg(key) })) : null
    );
  }, [langCode]);

  // initialize field value
  useEffect(() => {
    setFromField(fromScreenName);
  }, [fromScreenName]);

  useEffect(() => {
    props.toOverride && setToField(props.toOverride);
    props.titleOverride && setTitle(props.titleOverride);
  }, [props.toOverride, props.titleOverride]);

  const showTitleInput = stage2 || (stage1 && !enableTwoStage);
  const showToFieldInput =
    showTitleInput && selectedType.current === 'outgoing';
  const showFromFieldInput = showToFieldInput;

  //**** dynamic verbiage and labels **************************************************************

  // optimize the label to be used for the message Title field
  const titleLabel = () => {
    switch (selectedType.current) {
      case 'comment':
        return t1.T$_Comment;
      case 'support':
        return t1.T$_Subject;
      case 'profile':
        return t1.T$_About_Yourself;
      default:
        return inviteOverride && !voiceInviteSelected
          ? t1.T$_Text_Invitation
          : t1.T$_Message_Title;
    }
  };

  // use generic placeholder except for profile message
  const titlePlaceholder = () =>
    selectedType.current === 'profile'
      ? t1.T$_Enter_a_Headline_about_you
      : undefined;

  // banner describing what will be recorded
  const recorderBannerText = () => {
    if (selectedType.current === null) {
      return t1.T$_Creating_a_Message;
    }
    const bannerPreamble = () => {
      switch (selectedType.current) {
        case 'comment':
          return t1.T$_Public_Response;
        case 'vmail':
          return t1.T$_Sending;
        case 'reply':
          return t1.T$_Reply;
        case 'invite':
          return t1.T$_Live_Call;
        case 'regrets':
          return t1.T$_Regrets;
        case 'inquiry':
          return t1.T$_Inquiry;
        case 'profile':
          return t1.T$_Profile;
        case 'support':
          return t1.T$_Message;
        default:
          break;
      }
      return t1.T$_Respond;
    };

    if (Boolean(bannerOverride)) {
      // use specified banner
      return bannerOverride;
    }

    if (Boolean(parentMessage.current?.id)) {
      // banner for response or directed message
      return `${bannerPreamble()} ${t1.T$_to} ${
        parentMessage.current?.screenName
      }`;
    }

    if (selectedType.current === 'profile') {
      return (
        <div style={{ textAlign: 'center' }}>
          {t1.T$_Create_Your_Community_Profile_for}
          <div style={{ color: '#4caf50' }}>{`${
            currentCommunity()?.title
          }`}</div>
        </div>
      );
    }

    // use generic banner text
    const caps = str => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '');
    return `${t1.T$_Creating_a} ${
      isPublicType(selectedType.current) ? t1.T$_Public + ' ' : ''
    }${caps(selectedType.current)}`;
  };

  // by default, VoiceRecorder provides a 'Download' action button.  When invoked by
  // MessageRecorder, this button is overridden with a context-specific replacement:
  //  'OK' when a second stage of metadata gathering is required
  //  'Save' for public message type when no 2nd stage is needed
  //  'Send' for directed message when no 2nd stage is needed
  const stage1Reassign = () => ({
    Download: useOkButton
      ? t('OK')
      : isPublicType(selectedType.current)
      ? 'Save'
      : 'Send',
  });

  // after stage1, reassign Save button for directed message types
  const reassignSave = () =>
    isPublicType(selectedType.current) ? null : { Save: 'Send' };

  // compose an exception message for the specified key
  const exceptionMsg = key => {
    switch (key) {
      case 'duration':
        return `${t1.T$_Please_record_at_least} ${minRecordingSec} ${t1.T$_sec}.`;
      case 'type':
        return `${t1.T$_Message_type} "${selectedType.current}" ${t1.T$_cannot_be_created} !`;
      case 'auth':
        return `${t1.T$_You_must_Login_to_save_this_message} !`;
      case 'auth':
        return `${t1.T$_You_must_Login_to_save_this_message} !`;
      case 'login':
        return `${t1.T$_Please_login_to_save_your_message} !`;
      case 'title':
        // use generic exception except for profile message
        return selectedType.current === 'profile'
          ? t1.T$_Please_enter_a_Headline_about_you + '\u00a0!'
          : `${t1.T$_Please_enter_a} ${titleLabel()}\u00a0!`;
      default:
        return null;
    }
  };

  //**** validation **************************************************************

  // context specifies whether a recording is to be submitted to the server
  // or buffered on client
  //static contextType = RecordingBufferContext;
  const willBufferMessage = ctx && ctx.recBufferEnable;

  // return null if all validations pass
  // otherwise, an array of exception messages
  const validate = withoutFile => {
    const durationOk = () => recording.current.durationSec >= minRecordingSec;
    const titleOk = () =>
      title.current.length > 0 && title.current.length <= maxTitleLength;
    const typeOk = () =>
      availableTypes.current
        .filter(t => t.type !== 'outgoing' || enableOutgoingType)
        .find(item => item.type === selectedType.current);
    const authOk = () =>
      isAuthenticatedRef.current ||
      unauthenticatedReply ||
      selectedType.current === 'support' ||
      (selectedType.current === 'profile' && ctx?.recBufferEnable);
    // const membershipOk = () => isMember() || (selectedType.current === 'support')

    const exceptions = [];
    withoutFile ||
      durationOk() ||
      exceptions.push({ key: 'duration', msg: exceptionMsg('duration') });
    titleOk() || exceptions.push({ key: 'title', msg: exceptionMsg('title') });
    typeOk() || exceptions.push({ key: 'type', msg: exceptionMsg('type') });
    authOk() || exceptions.push({ key: 'auth', msg: exceptionMsg('auth') });

    // handled by state processing, not as an exception
    // membershipOk() || exceptions.push({ key: 'membership', msg: "You must join this community to save this message !" })
    return exceptions;
  };

  //****  event handlers (not direct action button handlers ) **************************************************************

  // update title field
  const handleTitleChange = val => {
    setTitle(val);

    if (val.length) {
      removeAdvice('title');
    } else {
      addAdvice({ key: 'title', msg: exceptionMsg('title') });
    }

    //NOTE: underlying RecorderTextField limits at maxTitleLength so we fire alert AT the limit
    if (val.length >= maxTitleLength) {
      dispatch(
        uiActions.alertSet({
          message: `${t1.T$_There_is_a} ${maxTitleLength} ${t1.T$_character_limit}.`,
          severity: 'warning',
        })
      );
    }
  };

  const handleToFieldChange = ev => {
    setToField(ev.target.value);
  };
  const handleFromFieldChange = ev => {
    setFromField(ev.target.value);
  };

  // callback prop for MessageTypeSelector to enable initialization
  const handleInitializeTypes = types => setAvailableTypes(types);

  // apply title field if the newType carries a default
  const manageDefaultTitle = newType => {
    // remove prior default title (if any)
    // apply new default title (if any)
    // but do not modify prior user input
    const newDefaultTitle = defaultTitle(
      newType,
      parentMessage.current,
      maxTitleLength
    );
    const oldDefaultTitle = defaultTitle(
      selectedType.current,
      parentMessage.current,
      maxTitleLength
    );

    const noUserInput =
      title.current.length === 0 || title.current === oldDefaultTitle;

    // leave user input unchanged, otherwise apply or clear default title
    if (noUserInput) {
      if (newDefaultTitle) {
        setTitle(newDefaultTitle);
      } else {
        setTitle('');
      }
    }
  };

  // apply changes to message type and other dependent fieldsd
  const handleTypeUpdate = value => {
    if (
      // if changing selection to 'support' redirect now.
      // but DON't redirect during initialization.
      selectedType.current != null &&
      !isAuthenticated &&
      value === 'support'
    ) {
      // redirect for a support request
      history.push('/support');
    } else if (selectedType.current === value) {
      // no change
    } else {
      // update or initialization required
      manageDefaultTitle(value);
      setSelectedType(value);

      // type-dependent clipboard status
      setClipboardStatus(
        isPublicType(value) || value === 'outgoing' ? 'uncaptured' : 'disabled'
      );

      // if message is to admin, apply SUPPORT_USER info
      setParentMessage(
        isToAdmin(value)
          ? { userId: SUPPORT_USER.id, screenName: SUPPORT_USER.screenName }
          : props.parentMessage
      );
    }
    // always relinquish focus
    document.activeElement.blur();
  };

  // save new recording to local recState
  const handleRecordingCaptured = recording => {
    setRecording(recording);
  };

  // wrap up after success report was rendered or acknowledged
  const handleSuccessExit = () => {
    reset(successResult.current);
    // if the form is presented, potentially invoke a suggestion upon exiting.
    if (!isPublicType(selectedType.current)) {
      dispatch(uiActions.suggestionInvoke('push_notifications')); // 'app_install', "push_notifications", "sms_notifications"
    }
  };

  // user electd to cancel this message during success report
  const handleSuccessCancel = () => {
    //delete the successResult.current message
    deleteMessageById(successResult.current?.id)
      .then(() => {
        console.log(`Message "${successResult.current?.title}" deleted.`);
        reset('cancel during success report.');
      })
      .catch(e => {
        reset('cancel during success report.');
      });
  };

  const handleExit = rslt => {
    onExit && onExit(rslt);
  };

  const reset = result => {
    setRecState(MRS.stage1);
    setRecording({ blob: null, blobURL: null, durationSec: 0 });
    setTitle('');
    setValidationExceptions(null);
    setAdvice([]);

    setRecorderKey(old => old + 1); //re-render the VoiceRecorder
    setSuccessResult(null);
    // do not clear availableTypes, selectedType, or parentMessage
    handleExit(result);
  };

  const handleExceptionsExit = () => {
    setValidationExceptions(null);
    // restore context
    //popRecState()

    // restart workflow
    setRecState_([MRS.stage1]);
  };

  const handleNonMemberAction = action => {
    switch (action) {
      case 'Joined':
        //@@ Never invoked
        handleSaveClick();
        dispatch(
          uiActions.alertSet({
            message: t1.T$_Congratulations_NEW_MEMBER,
            severity: 'success',
          })
        );
        break;
      case 'Cancel':
        popRecState();
        break;
      default:
        break;
    }
  };

  // member has completed membership form, update user account
  const processNewMember = (user, updates) => {
    setRecState(MRS.joinProgress);
    patchHvnUser(user, updates)
      .then(() => handleSaveClick())
      .catch(err => {
        console.log('processNewMember error:', err);
      });
  };

  // Note that RegistrationManager is performing an async procedure
  const handleRegistrationProgress = message => {
    setRegistrationMessage(message);
  };

  // observe page transitions by the RegistrationManager
  const handleRegistrationManagerPageTransition = key => {
    if (key === 'exceptionAck') {
      // an exception was handled within RegistrationManager, cancel now
      handleLoginCancel();
    }
  };

  // Observe field transitions within RegistrationManager
  const handleRegistrationManagerFieldTransition = (key, value) => {
    if (key === 'authMethod' && value === 'google') {
      // Google Dialog is active, render progress indication
      // note that zen mode has extinguished the default progress provided by RegistrationManager
      handleRegistrationProgress('Google is awaiting your login...');
    } else {
      handleRegistrationProgress(null);
    }
  };

  //****  Actions button handlers **************************************************************

  function handleRecorderActionClick(button) {
    if (button === 'OK') {
      handleOKClick();
    } else if (button === 'Cancel') {
      handleCancelClick();
    } else if (button === 'Download') {
      handleDownloadClick();
    } else if (button === 'Save') {
      handleSaveClick();
    } else if (button === 'Login') {
      handleLoginClick();
    } else if (button === 'Send') {
      handleSaveClick();
    }
    onActionEvt && onActionEvt(button);
  }

  const handleOKClick = () =>
    setRecState(isAuthenticated ? MRS.stage2Auth : MRS.stage2Anon);

  const handleCancelClick = () => {
    dispatch(
      uiActions.alertSet({
        message: t1.T$_Your_Message_is_cancelled,
        severity: 'warning',
      })
    );
    reset('cancel click');
  };

  const handleLoginCancel = () => {
    popRecState();
    setRegistrationMessage(null);
  };

  const handleLoginClick = () => {
    pushRecState(MRS.login);
  };

  const handleLoginformCancel = () => {
    setRegistrationMessage(null);
    popRecState();
  };

  const handleLoginExit = result => {
    if (result?.status === 'success') {
      // useEffect will promote user stage2
      popRecState();
    } else {
      // return to prior state
      popRecState();
    }
  };

  // save message to a file on local device
  const handleDownloadClick = () => {
    setRecState(MRS.save);
    downloadRef.current && downloadRef.current.click();
    timeout(1000).then(() => {
      dispatch(
        uiActions.alertSet({
          message: t1.T$_Your_recording_has_been_downloaded,
          severity: 'success',
        })
      );
      reset('download timeout');
    });
  };

  // save message to server, or buffer it for later save
  // message can be created without a file attached (e.g. for a text-only invite)
  const handleSaveClick = withoutFile => {
    // do not loose context, must restore context in handleExceptionsExit
    const exceptions = validate(withoutFile);
    if (exceptions.length > 0) {
      setValidationExceptions(exceptions);
      pushRecState(MRS.problem);
      return;
    }

    if (userMustJoinCommunity()) {
      pushRecState(MRS.join);
      return;
    }

    // continue with save operation
    setRecState(MRS.save);

    if (unauthenticatedSupportRequest || unauthenticatedReply) {
      // anonymous user, proxy credentials are required
      getProxyToken(true)
        .then(proxyToken => submitMessage(submitArgs(proxyToken)))
        .then(result => handleSubmitSuccess(result))
        .catch(err => handleSubmitError(err));
    } else if (!isAuthenticated && ctx?.recBufferEnable) {
      // buffer these args if ctx is active
      ctx.bufferMessage(submitArgs());
      handleMessageBufferSuccess();
    } else {
      // normal submit
      submitMessage(submitArgs())
        .then(result => handleSubmitSuccess(result))
        .catch(err => handleSubmitError(err));
    }

    function submitArgs(proxyToken) {
      // annotate title with contact info for unauthenticated support request
      const annotatedTitle = unauthenticatedSupportRequest
        ? `${unregisteredUserContactInfo}: ${title.current}`
        : title.current;

      const args = {
        recording: {
          type: selectedType.current,
          title: annotatedTitle,
          blob: recording.current.blob,
          blobURL: recording.current.blobURL,
          duration: recording.current.durationSec,
        },
        parentMessage:
          selectedType.current === 'outgoing'
            ? { userId: linkCode, screenName: toField.current }
            : parentMessage.current,
      };
      // apply proxyToken if one is provided
      proxyToken && (args.proxyToken = proxyToken);
      return args;
    }

    function handleMessageBufferSuccess() {
      reset('message buffer success');
    }

    // the new message has been uploaded to the server
    function handleSubmitSuccess(result) {
      // workaround: perform this insert normally implemented in submitMessage
      if (selectedType.current === 'outgoing') {
        dispatch(dialogActions.insert({ message: result?.newMessage }));
      }

      setSuccessResult(result);
      // if clipboard is disabled, transition to success state now.
      // Othewise await clipboard results.
      if (clipboardStatus.current === 'disabled') {
        setRecState(MRS.success);
        if (bypassMessageRecorderSuccessForm) {
          // if the form is not presented, potentially invoke a suggestion now.
          if (!isPublicType(selectedType.current)) {
            dispatch(uiActions.suggestionInvoke('push_notifications')); // 'app_install', "push_notifications", "sms_notifications"
          }
        }
      }
    }

    function handleSubmitError(err) {
      uhoh.play();
      setValidationExceptions([
        { key: 'save', msg: t1.T$_There_was_a_problem_saving_your_recording },
      ]);
      pushRecState(MRS.problem);
      dispatch(
        uiActions.alertSet({
          message: t1.T$_There_was_a_problem_saving_your_recording,
          severity: 'error',
        })
      );
      setVoiceInviteSelected(false); // be sure we're not stuck with only "send with txt" option

      // when alert is clears
      //setRecState(MRS.ready)
    }
  };

  const handleInviteModeSelected = mode => {
    switch (mode) {
      case 'text':
        // create an invite without an attached voice message
        handleSaveClick('withoutFile');
        break;
      case 'voice':
        // initiate recording of a voice invite
        setVoiceInviteSelected(true);
        recorderRef.current.requestStartRecording();
        break;
      default:
        handleExit();
        break;
    }
  };

  //****  rendering **************************************************************

  const adviceReport = () => (
    <>
      <Paper
        style={{ backgroundColor: /*'#ff9800'*/ '#2196f3', padding: '3px' }}
      >
        <ul style={{ margin: 0, paddingInlineStart: '20px' }}>
          {makeArray(adviceItems.current).map((x, ix) => {
            return (
              <li style={{ marginBottom: '.4rem' }} key={ix}>
                {x.msg}
              </li>
            );
          })}
        </ul>
      </Paper>
    </>
  );

  // detect binary choice between 'vmail' and 'comment'
  const employCommentVmailBinarySwitch = () => {
    const types = availableTypes.current;
    return (
      types.length === 2 &&
      types.find(t => t.type === 'comment') &&
      types.find(t => t.type === 'vmail')
    );
  };

  // determine which UI items should be rendered

  const isVisible = {
    // make room for virtual keyboard
    // with vk active, everything is hidden except titleInput and vkButton

    // hide banner while logging in or joining community
    banner:
      !vk &&
      !inviteOverride &&
      ![MRS.login, MRS.join, MRS.joinProgress].includes(getRecState()),

    // typeSelector
    // needed to initialize availableTypes and to select from multiple availableTypes.
    // rendered in stage2, and conditionaly also in stage 2
    typeSelector:
      !vk && availableTypes.current.length !== 1 && (stage1 || stage2),

    // title can be delayed until stage2
    toInput: showToFieldInput,
    fromInput: showFromFieldInput,
    titleInput: showTitleInput,

    // advice section warns of possible forthcoming exception
    advice: !vk && (stage1 || stage2) && adviceItems.current.length,

    contactInfo:
      !vk && unregisteredUserContactInfo && selectedType.current === 'support',

    successReport: getRecState() === MRS.success,
    problemReport: getRecState() === MRS.problem,
    progress: getRecState() === MRS.save,
    joinForm: getRecState() === MRS.join, // (selectedType.current !== 'support') && isAuthenticatedNonMember,
    joinProgress: getRecState() === MRS.joinProgress,
    loginProgress: Boolean(registrationMessage.current),
    loginForm: getRecState() === MRS.login,
    visualization: stage1 && !vk && !showInviteModeSelector,
    options:
      !vk &&
      !inviteOverride &&
      optionsArr.length > 0 &&
      ![MRS.login, MRS.success].includes(getRecState()),
    voiceRecorderActions: !vk && !showInviteModeSelector && stage12,
    inviteModeSelector: showInviteModeSelector,
    vkButton: vk,
  };

  const renderProgress = msg => (
    <div style={{ textAlign: 'center' }}>
      <div>{msg}</div>
      <CircularProgress classes={{ root: classes.progress }} />
    </div>
  );

  // render options button with popover
  const renderOptionsButton = () => {
    const handleOpenClick = event => setOptionsAnchorEl(event.currentTarget);
    const handleCloseClick = () => setOptionsAnchorEl(null);
    const handleChange = () => timeout(1000).then(() => handleCloseClick());
    const open = Boolean(optionsAnchorEl);
    const id = open ? 'options-popover' : undefined;
    return (
      <div style={{ width: '100%' }}>
        <IconButton
          className={classes.recorderOptionsButton}
          onClick={handleOpenClick}
        >
          <SettingsIcon />
        </IconButton>
        <Popover
          style={{}}
          id={id}
          open={open}
          anchorEl={optionsAnchorEl}
          onClose={handleCloseClick}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: 10, paddingLeft: 30 }}>
            {optionsArr.map((option, ix) => (
              <div key={ix}>
                {/* apply onChange prop to each option component */}
                {React.cloneElement(option, { onChange: handleChange })}
              </div>
            ))}
          </div>
        </Popover>
      </div>
    );
  };

  // available keys and components for dynamic visibility during recorder sequence
  const uiItems = {
    problemReport: (
      <ProblemReport
        exceptions={validationExceptions.current}
        onExit={handleExceptionsExit}
      />
    ),
    progress: renderProgress(t1.T$_Saving_your_message),
    joinProgress: renderProgress(t1.T$_Processing_Community_membership),
    loginProgress: renderProgress(registrationMessage.current),
    fromInput: (
      <RecorderTextField
        label={'From'}
        value={fromField}
        placeholder="Sender"
        onChange={handleFromFieldChange}
        maxLength={maxTitleLength}
        required={false}
      />
    ),
    toInput: (
      <RecorderTextField
        label="To"
        value={toField.current}
        placeholder="Recipient Name"
        onChange={handleToFieldChange}
        maxLength={maxTitleLength}
        required={false}
      />
    ),
    titleInput: (
      <MessageTitleInput
        label={titleLabel()}
        value={title.current}
        placeholder={titlePlaceholder()}
        onChange={handleTitleChange}
        maxLength={maxTitleLength}
      />
    ),
    typeSelector: (
      <MessageTypeSelector
        types={availableTypes.current}
        selected={selectedType.current}
        typeOverride={typeOverride}
        parentMessage={parentMessage.current}
        isAuthenticated={isAuthenticated}
        willBufferMessage={willBufferMessage}
        onChange={handleTypeUpdate}
        initializeTypes={handleInitializeTypes}
        employCommentVmailBinarySwitch={employCommentVmailBinarySwitch()}
      />
    ),
    contactInfo: (
      <BorderedBlock labelTop={t1.T$_Your_Contact_Info} classes={{}}>
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            letterSpacing: '0.00938em',
          }}
        >
          {unregisteredUserContactInfo}
        </div>
      </BorderedBlock>
    ),
    options: renderOptionsButton(),
    successReport: (
      <SuccessReport
        message={successResult.current?.newMessage}
        onExit={handleSuccessExit}
        bypassForm={bypassMessageRecorderSuccessForm}
        clipboardStatus={clipboardStatus.current}
        contentForClipboard={clipboardText.current}
      />
    ),

    joinForm: (
      <div>
        <Typography style={{ marginBottom: 10 }} variant="body1">
          {t1.T$_Please_Join_This_Community_In_Order_To_Save_Your_Message}
        </Typography>
        <Typography
          style={{ marginBottom: 10 }}
          variant="body1"
          color="secondary"
        >{`${currentCommunity()?.title}`}</Typography>
        <hr />
        <NonMemberActions
          onClick={handleNonMemberAction}
          processNewMember={processNewMember}
        />
      </div>
    ),

    // hide content without dismounting when loginProgress is visible
    loginForm: (
      <>
        <div
          className={Boolean(isVisible.loginProgress) ? classes.makeTiny : null}
        >
          <RegistrationManager
            renderWithinDialog={false}
            bypassGetAttributes={true}
            onComplete={handleLoginExit}
            renderRegistrationProgress={handleRegistrationProgress}
            notePage={handleRegistrationManagerPageTransition}
            noteField={handleRegistrationManagerFieldTransition}
            zen={true}
          />
        </div>
        <IconButton
          className={classes.closeLoginButton}
          onClick={handleLoginformCancel}
        >
          <CloseIcon />
        </IconButton>
      </>
    ),
    advice: adviceReport(),
    vkButton: (
      <div style={{ marginTop: '.5rem' }}>
        <ButtonStd label={t1.T$_OK} onClick={null} color="secondary" />
      </div>
    ),
  };

  // Visible UI components
  const visibleItems = () =>
    Object.keys(uiItems).map(i => (
      <Item visible={isVisible[i]}>{uiItems[i]}</Item>
    ));

  // during CAPTURING recState, either render OK button or Save/Send button
  // OK button invokes rendering of a second-stage UI during METADATA recState
  // OK is employed for unauth users except when creating 'profile' or 'support' message
  const useOkButton = enableTwoStage;
  const nullActions = { statusSpec: null, buttons: [] };
  const recorderProps = () => ({
    actionBlockTitle:
      employCommentVmailBinarySwitch() || !isVisible.voiceRecorderActions
        ? null
        : isPublicType(selectedType.current)
        ? t('Public')
        : t('Direct_DM'),

    key: recorderKey.current, // update to re-render
    ref: recorderRef,
    onCancel: handleCancelClick,
    applianceTitle: inviteOverride
      ? recorderBannerText()
      : t1.T$_Message_Recorder,

    bannerText: isVisible.banner ? recorderBannerText() : null,

    // adjust default settings
    settings: {
      ...defaultRecorderSettings,
      duration: { ...defaultRecorderSettings.duration, minRecordingSec },
    },

    onActionEvt: handleRecorderActionClick,
    onRecordingCaptured: handleRecordingCaptured,
    hideVisualization: !isVisible.visualization,

    // in stage1, re-assign the default 'Download' button
    reAssignButtons: stage1Reassign(),

    // after stage1, replace VoiceRecorder default action buttons
    actionsSpecOverride: isVisible.voiceRecorderActions
      ? !stage1 && actionControls.actionsSpec(getRecState(), reassignSave())
      : nullActions,
    autoStart: autoRecord,
    readyNoCancel,
    customBody: isVisible.inviteModeSelector ? (
      <InviteModeSelector onClick={handleInviteModeSelected} />
    ) : null,
    actionClasses: actionClasses,
  });

  // render recorder, UI components, and action controls

  // attempt to load the clipboard
  const handleClipboardLoad = () => {
    navigator.clipboard
      .writeText(clipboardText.current)
      .then(() => {
        setClipboardStatus('captured');
        setRecState(MRS.success);
      })
      .catch(e => {
        console.log('clipboard error:', e);
        setRecState(MRS.success);
      });
  };

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'auto',
        maxHeight: scrollableSectionMaxHeight,
      }}
    >
      <VoiceRecorder {...recorderProps()}>{visibleItems()}</VoiceRecorder>

      {/* render an anchor tag capable of saving the recorded blob to a local file */}
      <a
        ref={downloadRef}
        href={recording.current.blobURL}
        download={defaultRecorderSettings.downloadFilename}
      >
        {' '}
      </a>

      {/* render a hidden button configured to save message URL to clipboard */}
      <Clicker
        clickTrigger={Boolean(clipboardText.current)}
        onClick={handleClipboardLoad}
      />
    </div>
  );
};
MessageRecorder.propTypes = {
  typeOverride: PropTypes.string, // if specified, overrides the default types available for creation
  unregisteredUserContactInfo: PropTypes.string,
  parentMessage: PropTypes.object,
  bannerOverride: PropTypes.oneOfType([
    // if specified, overrides RecorderBanner
    PropTypes.string,
    PropTypes.node,
  ]),
  readyNoCancel: PropTypes.bool, // disable 'Cancel' while 'Ready'
  onExit: PropTypes.func,
  enableTwoStage: PropTypes.bool, // if title field is needed, delay rendering until METADATA recState
  onActionEvt: PropTypes.func,
  toOverride: PropTypes.string,
  titleOverride: PropTypes.string,
};

MessageRecorder.defaultProps = {
  typeOverride: null,
  readyNoCancel: false,
  enableTwoStage: false,
};

export default MessageRecorder;
