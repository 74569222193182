import { createSlice } from '@reduxjs/toolkit';
import sigServer from 'lib/api/signalingServer';
import { setMicMuted, setSpeakerMuted } from 'lib/api/webRTCApi';
import { activityDetector } from 'lib/classes/ActivityDetector';

const initialState = {
  call: {
    progress: 'noService' /**
                                    * noService
                                    * ready         // user is ready to call or be called
                                    * invited       // caller invites callee
                                    * accepted      // callee agrees to talk
                                    * connected     // parties are talking

                                    * hungup        // a party ended the connection
                                    * declined      // callee declined the call
                                    * removed   // remote party has become unavailable for a call
                                    * cancelled     // caller cancelled the call
                                    * disconnected  // disconnect form sigServer
                                    * problem       // an error or problem
                                    */,

    startTime: null, // ms.
    regretsFileUrl: null, // a recording associated with a 'regrets' event
    initiator: null, // initiator of the most recent progress transition
  },
  participants: {
    local: {
      role: null, // null, 'caller', 'callee'
      micEnabled: false,
      userId: null,
      screenName: null,
      username: null,
      inviteId: null, //caller's invite message id
    },
    remote: {
      stream: null,
      muted: false, // local speaker is manually muted
      userId: null,
      screenName: null,
      username: null,
    },
  },

  // real time list of inbound invites, may change during a call
  invites: [], // list of inbound LiveCall invites
  selectedInviteId: null, //used by LiveCallAlert only to manage multiple

  stream: null, // persistent local microphone stream
  muted: false, // local microphone is manually muted
  turnServers: null, // persistent TURN server list
  peerConnectionReady: false, // indicates that a peerCannection has been established

  sigServer: {
    logData: [], //log data from the signaling server
  },
};

// call processing state transition map
// wildcard supported for initiator, oldState
const transitionMap = [
  { initiator: '*', event: 'invite', oldState: 'ready', newState: 'invited' },
  {
    initiator: '*',
    event: 'connect',
    oldState: 'accepted',
    newState: 'connected',
  },

  { initiator: '*', event: 'accept', oldState: 'ready', newState: 'accepted' }, // callee
  {
    initiator: '*',
    event: 'accept',
    oldState: 'invited',
    newState: 'accepted',
  }, // caller

  {
    initiator: '*',
    event: 'decline',
    oldState: 'invited',
    newState: 'declined',
  },
  {
    initiator: '*',
    event: 'regrets',
    oldState: 'invited',
    newState: 'declined',
  },
  { initiator: '*', event: 'remove', oldState: '*', newState: 'removed' },

  { initiator: '*', event: 'cancel', oldState: 'ready', newState: 'ready' },
  { initiator: '*', event: 'cancel', oldState: '*', newState: 'cancelled' },

  { initiator: '*', event: 'hangup', oldState: '*', newState: 'hungup' },
  {
    initiator: '*',
    event: 'disconnect',
    oldState: 'ready',
    newState: 'noService',
  },
  {
    initiator: '*',
    event: 'disconnect',
    oldState: '*',
    newState: 'disconnected',
  },
  { initiator: '*', event: 'exception', oldState: '*', newState: 'problem' },
  { initiator: '*', event: 'reset', oldState: '*', newState: 'ready' },
];

const terminationDescription = (event, party) => {
  const byWho =
    party === 'local' ? 'you' : party === 'remote' ? 'your party' : '';
  switch (event) {
    case 'cancel':
      return `cancelled by ${byWho}`;
    case 'decline':
      return `declined by ${byWho}`;
    case 'regrets':
      return `declined by ${byWho}`;
    case 'hangup':
      return `${byWho} hung up`;
    case 'disconnect':
      return `${byWho} disconnected`;
    default:
      return `ended unexpectedly`;
  }
};

const webRTCSlice = createSlice({
  name: 'webRTC',
  initialState: initialState,
  reducers: {
    sigServerLogData(state, action) {
      const logData = action.payload;
      state.sigServer.logData = logData;
    },
    // force a call progress state
    // used to override the transitionMap
    setCallProgress(state, action) {
      state.call.progress = action.payload;
      // at end of call, clear participant data
      if (action.payload === 'ended') {
        state.participants = initialState.participants;
      }
    },

    transition(state, action) {
      const { event, initiator, data } = {
        event: null,
        initiator: '*',
        data: '',
        ...action.payload,
      };
      const oldState = state.call.progress;

      if (event === 'regrets' && data.length > 0) {
        // regrets event carries a message file with it
        state.call.regretsFileUrl = data;
      }

      // do not transition from 'noService' (use setCallProgress instead)
      if (state.call.progress === 'noService') {
        return;
      }

      // look up the new state
      // match the initiator (or use wildcard if no initiator is specified),
      // match the current event,
      // and match the old state ( or accept a wildcard old state)
      const newState = transitionMap.find(
        item =>
          ['*', initiator].includes(item.initiator) &&
          item.event === event &&
          ['*', state.call.progress].includes(item.oldState)
      )?.newState;

      // if transition was not found, do nothing
      if (newState) {
        //console.log(`call-progress transiton: ev=${event}-- ${state.call.progress} -> ${newState}`)
        state.call = {
          ...state.call,
          progress: newState,
          initiator,
        };

        if (newState === 'connected') {
          state.call.startTime = Date.now();
        }

        // pause the activity detector during LiveCall sequence
        [/*'invited', 'accepted', */ 'connected'].includes(newState)
          ? activityDetector.pause('transition')
          : activityDetector.resume('transition');
      } else {
        //console.log(`call-progress transiton: ev=${event}-- ${state.call.progress} -> (no change)`)
      }
    },

    clearCallProgress(state, action) {
      //console.log(`call-progress cleared`)
      state.participants = initialState.participants;
      state.call = {
        ...initialState.call,
        progress: sigServer.isConnected() ? 'ready' : 'noService',
      };
    },
    setStream(state, action) {
      state.stream = action.payload;
      state.muted = false;
    },

    // manual mute/unmute
    toggleMic(state, action) {
      const wasMuted = state.muted;
      setMicMuted(!wasMuted); // manipulate the local stream
      state.muted = !wasMuted; // update the store
    },
    toggleSpeaker(state, action) {
      const wasMuted = state.participants.remote.muted;
      setSpeakerMuted(!wasMuted);
      state.participants.remote.muted = !wasMuted;
    },

    setTurnServers(state, action) {
      state.turnServers = action.payload;
    },
    setPeerConnectionReady(state, action) {
      state.peerConnectionReady = action.payload;
    },
    setParticipantInfo(state, action) {
      // if null, clear all participants information
      let newParticipants = initialState.participants;
      if (action.payload === null) {
        state.participants = initialState.participants;
      } else {
        const { party, info } = action.payload;
        newParticipants = { ...state.participants[party], ...info };
        state.participants[party] = newParticipants;
      }
    },

    enqueueInvite(state, action) {
      const invite = action.payload; //{ userId, screenName}
      const callerId = invite?.userId;
      if (callerId) {
        // if this is the first invite, select it automatically
        if (state.invites.length === 0) {
          state.selectedInviteId = callerId;
        }
        // add invite but assure no duplicates from same caller
        state.invites = [
          ...state.invites.filter(i => i.userId !== callerId),
          invite,
        ];
      }
    },

    removeInvite(state, action) {
      const callerId = action.payload;
      if (callerId) {
        const remainingInvites = state.invites.filter(
          i => i.userId !== callerId
        );
        //console.log(`removeInvite callerId=${callerId}, state.selectedInviteId=${state.selectedInviteId}, remainingLength=${remainingInvites.length}, selectedRemoved=${state.selectedMessageId === callerId}`);

        // automatic update of selected invite
        if (remainingInvites.length === 1) {
          // select sole remaining invite automatically
          state.selectedInviteId = remainingInvites[0].userId;
        } else {
          // if the selected invite was removed, clear the selection
          if (state.selectedInviteId === callerId) {
            state.selectedInviteId = null;
          }
        }
        state.invites = remainingInvites;
      }
    },
    selectInvite(state, action) {
      const id = action.payload;
      if (state.invites.find(i => i.userId === id)) {
        state.selectedInviteId = id;
      }
    },

    clearInvites(state) {
      state.invites = initialState.invites;
      state.selectedInviteId = initialState.selectedInviteId;
    },
  },
});

export const webRTCActions = webRTCSlice.actions;
export default webRTCSlice.reducer;
